import React, { forwardRef } from 'react';

import { caloTheme } from 'assets/images/theme/calo';
import { FormikErrors } from 'formik';
import { UpdateFoodReqWithIndex } from 'lib/interfaces';

import { UpdateFoodReq } from '@calo/dashboard-types';
import { Divider, Stack, Typography } from '@mui/material';

import { InputMUI } from '../MUI';

interface PortioningNotesProps {
  values: UpdateFoodReq;
  errors: FormikErrors<UpdateFoodReqWithIndex>;
  isDisabled: boolean;
  handleChange: (e: React.ChangeEvent<any>) => void;
}

const PortioningNotes = forwardRef<HTMLDivElement, PortioningNotesProps>(
  ({ errors, values, isDisabled, handleChange }: PortioningNotesProps, ref) => {
    return (
      <Stack
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        ref={ref}
        sx={{ backgroundColor: caloTheme.palette.white, borderRadius: '8px', boxShadow: 2 }}
      >
        <Typography
          sx={{
            fontSize: '24px',
            fontWeight: 400,
            lineHeight: '150%',
            padding: '16px'
          }}
        >
          Portioning Notes
        </Typography>
        <Divider sx={{ color: caloTheme.palette.divider, height: '1px', width: '100%' }} />
        <Stack direction="row" alignItems="flex-start" sx={{ padding: '24px 16px 16px 16px', width: '100%', gap: '16px' }}>
          <InputMUI
            label="Portioning Steps"
            name="portioningURL"
            placeholder="Add Link"
            value={values.portioningURL}
            debounce
            InputLabelProps={{
              shrink: true
            }}
            onChange={handleChange}
            error={!!errors.portioningURL}
            disabled={isDisabled}
            inputProps={{ style: { borderRadius: 8 } }}
            sx={{ width: '100%' }}
            rows={1}
            multiline
          />
          <InputMUI
            label="Assembly Steps"
            name="metadata.assemblyURL"
            placeholder="Add Link"
            value={values.metadata?.assemblyURL}
            sx={{ width: '100%' }}
            debounce
            InputLabelProps={{
              shrink: true
            }}
            onChange={handleChange}
            error={!!errors.assemblyURL}
            disabled={isDisabled}
            inputProps={{ style: { borderRadius: 8 } }}
            rows={1}
            multiline
          />
          <InputMUI
            label="Note"
            name="notes"
            placeholder="Add Note"
            value={values.notes}
            debounce
            InputLabelProps={{
              shrink: true
            }}
            onChange={handleChange}
            error={!!errors.notes}
            disabled={isDisabled}
            sx={{ width: '100%' }}
            inputProps={{ style: { borderRadius: 8 } }}
            rows={1}
            multiline
          />
        </Stack>
      </Stack>
    );
  }
);

export default PortioningNotes;
