import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

import { Button, IconButton, Stack, Typography } from '@mui/material-v6';

import { Permission } from '@calo/dashboard-types';
import CloseIcon from '@mui/icons-material/Close';
import { caloThemeV2 } from 'assets/themev2';
import { format } from 'date-fns';
import { getMenuTagsFromFoodList } from 'lib/helpers';
import { useUserRoles } from 'lib/hooks';
import { Menu, MenuFood } from 'lib/interfaces';
import { round } from 'lodash-es';
import { toast } from 'react-toastify';
import { WeeklyMenusDatePicker } from '..';
import { generateMenuSheets } from '../../ExportUtils';
import { findDailyAverageCostPerMenu, findMenusWithFoodCost } from '../../helpers';
import { MenuListFilters } from '../../types';
import AddMealsOptions from './AddMealsOptions';

export type WeeklyMenuActionsCardProps = {
  filters: MenuListFilters;
  weeklyMenuList: Menu[];
  isLoading: boolean;
  disabled?: boolean;
  action?: 'create' | 'edit';
  weeklyAverageCost: number;
  selectedWeek: number;
  isEditable: boolean;
  selectedYear: number;
  foodToReplace?: { food: MenuFood };
  setWeeklyMenuList: React.Dispatch<React.SetStateAction<Menu[]>>;
  setFoodToReplace: (value?: { food: MenuFood }) => void;
  setIsEditable: React.Dispatch<React.SetStateAction<boolean>>;
  handleUpdateWeeklyMenus: () => void;
  openPhoneModal: () => void;
  setSelectedWeek: (newWeek: number) => void;
  setSelectedYear: (v: number) => void;
  updateWeeklyMenuList: (newMenuList: Menu[]) => void;
  discardEditsHandler: () => void;
};

const WeeklyMenuActionsCard = ({
  action = 'edit',
  filters,
  disabled,
  weeklyMenuList,
  weeklyAverageCost,
  isLoading,
  isEditable,
  selectedWeek,
  foodToReplace,
  setFoodToReplace,
  setSelectedYear,
  updateWeeklyMenuList,
  setWeeklyMenuList,
  handleUpdateWeeklyMenus,
  openPhoneModal,
  setIsEditable,
  setSelectedWeek,
  discardEditsHandler
}: WeeklyMenuActionsCardProps) => {
  const userRoles = useUserRoles();

  const exportMenuHandler = async () => {
    if (!weeklyMenuList) {
      return;
    }
    const workbook = new ExcelJS.Workbook();
    const menusWithFoodCostObject = findMenusWithFoodCost(weeklyMenuList, filters.kitchen);
    generateMenuSheets(weeklyMenuList, filters.kitchen, weeklyAverageCost, workbook, true, menusWithFoodCostObject);

    for (const menu of weeklyMenuList) {
      const dailyAverageCost = findDailyAverageCostPerMenu(menusWithFoodCostObject, menu.id);
      generateMenuSheets([menu], filters.kitchen, dailyAverageCost, workbook);
    }

    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(blob, 'weekly-menu-stats.xlsx');
  };

  const handleAddingMealForSpecificDay = (meals: MenuFood[], day: Date) => {
    const menuIndex = weeklyMenuList.findIndex((menu) => menu.id === format(day, 'yyyy-MM-dd'));

    if (menuIndex < 0) {
      console.error('cannot find menu to add a meal');
      toast('Menu not found for adding meals', { type: 'error' });
      return;
    }

    const updatedMenuList = [...weeklyMenuList];
    const menuDay = day.getTime();

    const updatedFood = [...updatedMenuList[menuIndex].food, ...meals.map((food) => ({ ...food, new: true }))];
    const updatedTags = getMenuTagsFromFoodList((updatedFood as any[]) ?? [], menuDay);

    updatedMenuList[menuIndex] = {
      ...updatedMenuList[menuIndex],
      food: updatedFood,
      tags: updatedTags
    };
    setWeeklyMenuList(updatedMenuList);
  };

  const doesWeeklyMenusHaveFood = weeklyMenuList.flatMap((menu) => menu.food).length > 0;

  const userPermission =
    action === 'edit' ? userRoles.includes(Permission.UPDATE_MENU) : userRoles.includes(Permission.CREATE_MENU);

  const isSaveDisabled = action === 'create' || isEditable ? !userPermission : false;

  return (
    <>
      <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', paddingY: 2 }}>
        <Stack sx={{ flexDirection: 'row', alignItems: 'center', gap: 2 }}>
          {action === 'edit' && (
            <WeeklyMenusDatePicker
              disabled={isEditable || disabled}
              selectedWeek={selectedWeek}
              setSelectedYear={setSelectedYear}
              setSelectedWeek={setSelectedWeek}
            />
          )}
          {doesWeeklyMenusHaveFood && (
            <Typography sx={{ fontSize: '20px', fontWeight: 600 }}>Avg. weekly cost: {round(weeklyAverageCost, 2)}%</Typography>
          )}
        </Stack>
        <Stack sx={{ flexDirection: 'row', gap: 2, alignItems: 'center' }}>
          {doesWeeklyMenusHaveFood && (
            <>
              {action === 'edit' && (
                <Button
                  variant="outlined"
                  color="inherit"
                  size="large"
                  onClick={exportMenuHandler}
                  disabled={isLoading || disabled || weeklyMenuList.length === 0}
                >
                  Download
                </Button>
              )}
              <Button
                variant="outlined"
                color="inherit"
                size="large"
                onClick={openPhoneModal}
                disabled={isLoading || disabled || weeklyMenuList.length === 0}
              >
                Preview
              </Button>
            </>
          )}

          {isEditable && (
            <AddMealsOptions
              filters={filters}
              disabled={disabled}
              foodToReplace={foodToReplace}
              handleReplaceFood={() => {}}
              weeklyMenuList={weeklyMenuList}
              handleFoodChanges={handleAddingMealForSpecificDay}
              setFoodToReplace={setFoodToReplace}
              updateWeeklyMenuList={updateWeeklyMenuList}
            />
          )}

          {doesWeeklyMenusHaveFood && (
            <Button
              variant="outlined"
              color="inherit"
              size="large"
              disabled={isSaveDisabled || isLoading || disabled || weeklyMenuList.length === 0}
              onClick={() => (isEditable ? handleUpdateWeeklyMenus() : setIsEditable(true))}
              sx={{
                height: '42px',
                paddingX: '22px',
                fontSize: '15px',
                fontWeight: 500,
                color: caloThemeV2.palette.text.primary
              }}
            >
              {action === 'create' ? 'Save' : isEditable ? 'Save' : 'Edit'}
            </Button>
          )}
          {isEditable && action === 'edit' && (
            <IconButton onClick={discardEditsHandler} disabled={disabled}>
              <CloseIcon />
            </IconButton>
          )}
        </Stack>
      </Stack>
    </>
  );
};

export default WeeklyMenuActionsCard;
