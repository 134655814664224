import { PrototypeAction } from 'lib/enums';
import { toast, ToastContent } from 'react-toastify';

interface ToastSuccessMsgsProps {
  action: string | ToastContent;
  status: number;
}
export const ToastSuccessMsgs = ({ status, action }: ToastSuccessMsgsProps) => {
  if (status >= 200 && status <= 299) {
    toast(action, { type: 'success', autoClose: 2000 });
  }
};

export const getPrototypeActionSuccessMessage = (action: PrototypeAction, type: 'Food' | 'Component' | 'Ingredient'): string => {
  const messages: Record<PrototypeAction, string> = {
    [PrototypeAction.create]: `${type} created successfully`,
    [PrototypeAction.pend]: `${type} is now ready for approval`,
    [PrototypeAction.comment]: 'Comment added successfully',
    [PrototypeAction.submitForNpdApproval]: `${type} is sent for NPD approval`,
    [PrototypeAction.submitForOpsApproval]: `${type} is sent for Food Ops approval`,
    [PrototypeAction.requestChanges]: `Change Requested`,
    [PrototypeAction.approve]: `${type} approved and released successfully`
  };

  return messages[action] || '';
};
