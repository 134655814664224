import { useEffect, useRef, useState } from 'react';

import { format, getYear } from 'date-fns';
import { uniqBy } from 'lodash-es';
import { useMutation } from 'react-query';
import { RouteComponentProps, generatePath } from 'react-router';

import { Brand, Country, Kitchen, MenuTag } from '@calo/types';
import { DialogActions, DialogContent, DialogTitle, List, ListItem, ListItemText } from '@mui/material';

import { Box, Button, Card, Stack } from '@mui/material-v6';
import { createMenu } from 'actions';
import { MenuTable, ModalRef } from 'components';
import Popup from 'components/Popup';
import { MenuPresentationType, Routes } from 'lib/enums';
import { getMenuTagsFromFoodList, getWeekDates, mealTracking, selectCountry } from 'lib/helpers';
import { useUserKitchens } from 'lib/hooks';
import { Food, Menu, MenuFood } from 'lib/interfaces';
import {
  MenuPageHeaderCard,
  MenuPresentationField,
  PhoneMenuPreview,
  WeeklyMenuActionsCard,
  WeeklyMenuListCards
} from '../components';

import DailyMenuActionsCard from './DailyMenuActionsCard';
import FilterCriteriaCard from './FilterCriteriaCard';
import { styles } from './styles';
import { useMenuFilters } from './useMenuFilters';
import useMenuForm from './useMenuForm';

type NewMenuProps = RouteComponentProps;

const NewMenu = ({ history }: NewMenuProps) => {
  const userKitchens: Kitchen[] = useUserKitchens();
  const deletedFoodRef = useRef<ModalRef>();

  const [deletedFoodNames, setDeletedFoodNames] = useState<string>('');

  const [filters, setFilters] = useMenuFilters();
  const [isPhoneModalOpen, setIsPhoneModalOpen] = useState(false);
  const [selectedYear, setSelectedYear] = useState<number>(getYear(new Date()));
  const [isAddMealsPopupOpen, setIsAddMealsPopupOpen] = useState(false);
  const [weeklyAverageCost, setWeeklyAverageCost] = useState(0);
  const [foodToReplace, setFoodToReplace] = useState<{ food: MenuFood; menuId?: string }>();

  const initialWeeklyMenuList: Menu[] = getWeekDates(filters.selectedWeek, filters.selectedYear).map((day) => ({
    id: format(day, 'yyyy-MM-dd'),
    food: [],
    day: format(day, 'yyyy-MM-dd'),
    country: filters.country ?? Country.BH,
    brand: filters.brand ?? Brand.CALO,
    kitchen: filters.kitchen ?? Kitchen.BH1,
    draft: mealTracking as any
  }));
  const [weeklyMenuList, setWeeklyMenuList] = useState<Menu[]>(initialWeeklyMenuList);
  const [selectedWeeklyMenu, setSelectedWeeklyMenu] = useState<Menu>();

  const { mutateAsync: createMutation, isLoading: isCreateMenuLoading } = useMutation(createMenu);

  useEffect(() => {
    if (weeklyMenuList.length === 0) {
      setWeeklyMenuList(initialWeeklyMenuList);
    }
  }, [weeklyMenuList]);

  useEffect(() => {
    setFieldValue('day', filters.selectedDay);
    setFieldValue('id', filters.selectedDay);
  }, [filters.selectedDay]);

  const onSubmit = async (menu: Omit<Menu, 'id'>) => {
    await createMutation(
      {
        day: menu.day,
        food: menu.food.map((food) => food.id),
        country: menu.country,
        tags: menu.tags as MenuTag[],
        brand: menu.brand,
        kitchen: menu.kitchen,
        draft: menu.draft as any
      },
      {
        onSuccess: (data) => {
          const path = generatePath(Routes.menu, { brand: data.brand, kitchen: data.kitchen, id: data.id });
          history.push(path);
        }
      }
    );
  };

  const { values, setFieldValue } = useMenuForm(onSubmit, filters);

  useEffect(() => {
    const path = generatePath(Routes.newMenu, {
      country: values.country,
      kitchen: values.kitchen,
      type: filters.menuPresentation,
      day: filters.menuPresentation === MenuPresentationType.daily ? values.day : filters.selectedWeek
    });
    history.replace(path);
  }, [values.country, values.kitchen, filters.menuPresentation, values.day]);

  const handleRemoveFood = (removedIds: string[]) => {
    const menuTime = new Date(values.day).getTime();
    const updatedFood = values.food.filter((f) => !removedIds.includes(f.id));
    const updatedTags = getMenuTagsFromFoodList(updatedFood as Food[], menuTime);

    setFieldValue('tags', updatedTags);
    setFieldValue('food', updatedFood);
  };

  useEffect(() => {
    if (!values.kitchen.includes(values.country)) {
      selectCountry({
        value: values.country,
        kitchens: userKitchens,
        setFieldValue
      });
    }
  }, [values.country]);

  useEffect(() => {
    setFieldValue('tags', []);
    setFieldValue('food', []);
    setWeeklyMenuList([]);
  }, [values.country, values.kitchen, filters.menuPresentation, filters.selectedWeek]);

  useEffect(() => {
    setFieldValue('tags', getMenuTagsFromFoodList(values.food as Food[], new Date(values.day).getTime()));
  }, [values.day]);

  const handleCreateWeeklyMenus = async () => {
    if (weeklyMenuList) {
      try {
        const updatePromises: Promise<void>[] = weeklyMenuList.reduce<Promise<void>[]>((promises, menu) => {
          if (menu.food.length > 0) {
            promises.push(onSubmit(menu));
          }
          return promises;
        }, []);
        const results = await Promise.allSettled(updatePromises);

        if (results.every((result) => result.status === 'rejected')) {
          throw new Error('Failed to create weekly menu');
        }

        const path = generatePath(Routes.menuList, {
          brand: filters.brand,
          kitchen: filters.kitchen,
          country: filters.country,
          menuPresentation: MenuPresentationType.weekly,
          day: filters.selectedWeek
        });
        history.push(path);
      } catch (error) {
        console.error('Failed to update weekly menus:', error);
      }
    }
  };

  const updateWeeklyMenuList = (newMenuList: Menu[]) => {
    const filteredMenuList = newMenuList.map((menu) => ({
      ...menu,
      food: menu.food.filter((food) => !food.deletedAt) // Exclude deleted food
    }));

    const menusWithDeletedFood = newMenuList.filter((menu) => menu.food?.some((food) => food.deletedAt));
    if (menusWithDeletedFood.length > 0) {
      const updatedNames = uniqBy(
        menusWithDeletedFood.flatMap((menu) => menu.food.filter((food) => food.deletedAt)),
        (food) => food.name.en
      )
        .map((food) => food.name.en)
        .join(', ');
      setDeletedFoodNames(updatedNames);
      deletedFoodRef.current?.open();
    }
    setWeeklyMenuList(filteredMenuList);
  };

  const isDailyMenuEmpty = values.food.length === 0;
  const isWeeklyMenuEmpty = weeklyMenuList.flatMap((menu) => menu.food).length === 0;
  const isMenuEmpty = filters.menuPresentation === MenuPresentationType.daily ? isDailyMenuEmpty : isWeeklyMenuEmpty;

  return (
    <Stack sx={{ marginLeft: 2 }}>
      <MenuPageHeaderCard
        country={filters.country}
        kitchen={filters.kitchen}
        menuPresentation={filters.menuPresentation}
        create
      />
      <Stack sx={{ alignSelf: 'flex-end' }}>
        <MenuPresentationField
          disabled={isCreateMenuLoading}
          menuPresentation={filters.menuPresentation}
          setMenuPresentation={setFilters.setMenuPresentation}
        />
      </Stack>
      {filters.menuPresentation === MenuPresentationType.daily && (
        <FilterCriteriaCard
          day={values.day}
          country={values.country}
          kitchen={values.kitchen}
          menuPresentation={MenuPresentationType.daily}
          disabled={isCreateMenuLoading}
          setDay={(day: Date) => setFieldValue('day', format(day, 'yyyy-MM-dd'))}
          setKitchen={(kitchen: Kitchen) => setFieldValue('kitchen', kitchen)}
          setCountry={(country: Country) => setFieldValue('country', country)}
        />
      )}
      {filters.menuPresentation === MenuPresentationType.weekly && (
        <FilterCriteriaCard
          country={values.country}
          kitchen={values.kitchen}
          menuPresentation={MenuPresentationType.weekly}
          disabled={isCreateMenuLoading}
          selectedWeek={filters.selectedWeek}
          setSelectedWeek={setFilters.setWeek}
          setSelectedYear={setFilters.setYear}
          setKitchen={(kitchen: Kitchen) => setFieldValue('kitchen', kitchen)}
          setCountry={(country: Country) => setFieldValue('country', country)}
        />
      )}
      <Card
        variant="outlined"
        sx={{
          width: 'full',
          border: 'none',
          backgroundColor: isMenuEmpty ? 'transparent' : 'white',
          marginBottom: '16px',
          borderRadius: '8px',
          paddingBottom: '4px',
          marginTop: 1,
          padding: 2,
          minHeight: '100vh'
        }}
      >
        {filters.menuPresentation === MenuPresentationType.daily && (
          <DailyMenuActionsCard
            menu={values}
            foodToReplace={foodToReplace}
            disabled={isCreateMenuLoading}
            setFieldValue={setFieldValue}
            setFoodToReplace={setFoodToReplace}
          />
        )}

        {filters.menuPresentation === MenuPresentationType.weekly && (
          <WeeklyMenuActionsCard
            action="create"
            filters={filters}
            isLoading={false}
            isEditable={true}
            selectedYear={selectedYear}
            foodToReplace={foodToReplace}
            weeklyMenuList={weeklyMenuList}
            disabled={isCreateMenuLoading}
            weeklyAverageCost={weeklyAverageCost}
            selectedWeek={filters.selectedWeek}
            openPhoneModal={() => setIsPhoneModalOpen(true)}
            setIsEditable={() => {}}
            discardEditsHandler={() => {}}
            setFoodToReplace={setFoodToReplace}
            handleUpdateWeeklyMenus={handleCreateWeeklyMenus}
            setWeeklyMenuList={setWeeklyMenuList}
            setSelectedWeek={setFilters.setWeek}
            setSelectedYear={setSelectedYear}
            updateWeeklyMenuList={updateWeeklyMenuList}
          />
        )}
        <PhoneMenuPreview
          weeklyMenuList={weeklyMenuList}
          open={isPhoneModalOpen}
          closePhoneModal={() => setIsPhoneModalOpen(false)}
        />
        {filters.menuPresentation === MenuPresentationType.daily && values.food.length > 0 && (
          <Box sx={styles.boxContent}>
            <MenuTable
              brand={values.brand}
              kitchen={values.kitchen}
              foodLabel={values.tags}
              selectedDate={values.day}
              foodList={values.food}
              setFoodToReplace={setFoodToReplace}
              removeFood={(removedIds) => handleRemoveFood(removedIds)}
              draftTrack={values.draft}
              isMealsLoading={false}
            />
          </Box>
        )}
        {filters.menuPresentation === MenuPresentationType.weekly && (
          <>
            <WeeklyMenuListCards
              filters={filters}
              foodIds={weeklyMenuList.flatMap((menu) => menu.food.map((f) => f.id))}
              isEditable={true}
              selectedWeek={filters.selectedWeek}
              selectedYear={selectedYear}
              weeklyMenuList={weeklyMenuList}
              isLoading={false}
              selectedMenu={selectedWeeklyMenu}
              isAddMealsPopupOpen={isAddMealsPopupOpen}
              setWeeklyMenuList={setWeeklyMenuList}
              setSelectedMenu={setSelectedWeeklyMenu}
              setWeeklyAverageCost={setWeeklyAverageCost}
              setIsAddMealsPopupOpen={setIsAddMealsPopupOpen}
              openAddMealsPopup={() => setIsAddMealsPopupOpen(true)}
            />
            <PhoneMenuPreview
              weeklyMenuList={weeklyMenuList}
              open={isPhoneModalOpen}
              closePhoneModal={() => setIsPhoneModalOpen(false)}
            />

            {/* for weekly */}
            <Popup maxWidth="sm" fullWidth closeIcon onClose={() => deletedFoodRef.current?.close()} ref={deletedFoodRef}>
              <DialogTitle sx={styles.dialogTitle}>
                The following food have been deleted and will be removed from the menu
              </DialogTitle>
              <DialogContent>
                <Stack sx={styles.stackPadding}>
                  <List>
                    {deletedFoodNames.split(', ').map((name, index) => (
                      <ListItem key={index} sx={styles.listItem}>
                        <ListItemText primary={name} />
                      </ListItem>
                    ))}
                  </List>
                </Stack>
              </DialogContent>
              <DialogActions>
                <Button sx={styles.button} onClick={() => deletedFoodRef.current?.close()} disabled={false}>
                  Done
                </Button>
              </DialogActions>
            </Popup>
          </>
        )}
      </Card>
    </Stack>
  );
};

export default NewMenu;
