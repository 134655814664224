import { colors, ToggleButton, ToggleButtonGroup } from '@mui/material-v6';
import React, { useState } from 'react';
import { getSizeOptions } from '../../helpers';

interface SizeSelectorProps {
  sizeOptions: string[];
  selectedSize: string;
  onSizeChange: (newSize: string) => void;
}

const SizeSelector = ({ sizeOptions, selectedSize, onSizeChange }: SizeSelectorProps) => {
  const [size, setSize] = useState(selectedSize);

  const handleSizeChange = (event: React.MouseEvent<HTMLElement>, newSize: string) => {
    if (newSize !== null) {
      setSize(newSize);
      onSizeChange(newSize);
    }
  };

  return (
    <ToggleButtonGroup
      value={size}
      exclusive
      onChange={handleSizeChange}
      aria-label="Size selector"
      sx={{
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '8px',
        overflow: 'hidden'
      }}
    >
      {sizeOptions.map((option) => (
        <ToggleButton
          key={option}
          value={option}
          aria-label={option}
          sx={{
            textTransform: 'uppercase',
            borderColor: colors.grey[900],
            color: colors.grey[900],
            fontSize: '14px',
            fontWeight: 600,
            lineHeight: '24px',
            '&.Mui-selected': {
              backgroundColor: colors.grey[300],
              borderColor: colors.grey[900]
            }
          }}
        >
          {getSizeOptions(option)}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};

export default SizeSelector;
