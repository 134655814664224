import { FoodFilters, Permission } from '@calo/dashboard-types';
import { Brand, Kitchen } from '@calo/types';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CreateIcon from '@mui/icons-material/Create';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Breadcrumbs,
  Button,
  InputAdornment,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import {
  addPrototypeFoodAction,
  approvePrototypeStage1Food,
  approvePrototypeStage2Food,
  getList,
  getListWithParams,
  toggleUISettings
} from 'actions/index';
import { caloTheme } from 'assets/images/theme/calo';
import CaloLoader from 'components/CaloLoader';
import { ModalRef } from 'components/Modal';
import { InputMUI } from 'components/MUI';
import { FoodStatus, PrototypeAction, Routes } from 'lib/enums';
import { resolveCountryFromKitchen } from 'lib/helpers';
import { determineFoodAction } from 'lib/helpers/playgroundUtils';
import { useUserKitchens, useUserRoles } from 'lib/hooks';
import { Food, PrototypeFood, SortingFood } from 'lib/interfaces';
import { useEffect, useRef, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import { ChangeRequestDialog } from 'views/ChefPlayground/Shared/ChangeRequestDialog/ChangeRequestDialog';
import { MealApprovalDialog } from 'views/ChefPlayground/Shared/MealApprovalDialog/MealApprovalDialog';
import FoodCommentsPopup from '../../Shared/FoodCommentsPopup';
import FoodRow from './FoodRow';
import Settings from './Settings';

const styles = {
  breadcrumbs: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '150%'
  },
  breadcrumbActive: {
    color: caloTheme.palette.textPrimary
  },
  breadcrumbInactive: {
    color: caloTheme.palette.textSecondary
  },
  title: {
    fontSize: '34px',
    fontWeight: '400',
    lineHeight: '125%',
    color: caloTheme.palette.textPrimary
  },
  filterButton: {
    padding: '8px 22px',
    textTransform: 'none',
    color: caloTheme.palette.neutral900,
    fontSize: '15px',
    fontWeight: 500,
    lineHeight: '125%',
    ':hover': {
      backgroundColor: 'transparent'
    },
    border: `1px solid ${caloTheme.palette.textPrimary}`,
    borderRadius: '4px'
  },
  searchInput: {
    width: '90%'
  },
  searchInputProps: {
    height: '40px',
    fontSize: '16px',
    backgroundColor: 'white'
  },
  createButton: {
    padding: '8px 22px',
    textTransform: 'none',
    color: caloTheme.palette.neutral900,
    fontSize: '15px',
    fontWeight: 500,
    lineHeight: '125%',
    ':hover': {
      backgroundColor: 'transparent'
    },
    border: `1px solid ${caloTheme.palette.textPrimary}`,
    borderRadius: '4px'
  },
  tableHead: {
    backgroundColor: caloTheme.palette.grey200,
    border: 0
  },
  tableHeadText: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '24px'
  },
  tableCellMeal: {
    borderRadius: '8px 0 0 0',
    border: 0,
    width: '20%'
  },
  tableCellChef: {
    width: '20%'
  },
  tableCellStatus: {
    width: '20%'
  },
  tableCellComments: {
    width: '30%'
  },
  tableCellActions: {
    width: '10%',
    borderRadius: '0 8px 0 0'
  },
  stack: {
    borderRadius: '16px',
    py: '16px'
  },
  stackHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  filterBox: {
    display: 'flex',
    alignItems: 'center',
    gap: '24px'
  },
  tableContainer: {
    width: '100%',
    mt: '24px',
    boxShadow: 'none'
  }
};

const chefPlaygroundFoodlistBreadCurmb = [
  <Typography variant="body1" key={1} sx={{ ...styles.breadcrumbInactive, ...styles.breadcrumbs }}>
    Chef's Playground
  </Typography>,
  <Typography variant="body1" key={2} sx={{ ...styles.breadcrumbActive, ...styles.breadcrumbs }}>
    Meals
  </Typography>
];

interface PaginatedFoodList {
  data: Food[];
  meta: {
    limit: number;
    total: number;
  };
}

const FoodList = () => {
  const history = useHistory();
  const location = useLocation();
  const userKitchen = useUserKitchens();
  const commentsPopupRef = useRef<ModalRef>();
  const searchParams = new URLSearchParams(location.search);
  const dispatch = useDispatch();

  const roles = useUserRoles();
  const [page, setPage] = useState(0);

  const sort: SortingFood = {
    orderBy: 'updatedAt',
    orderMode: 'desc'
  };

  const [selectedFood, setSelectedFood] = useState<PrototypeFood | undefined>();
  const [prototypeComponents, setPrototypeComponents] = useState<{ id: string; name: string; type: string }[]>([]);

  const [mealApprovalDialogOpen, setMealApprovalDialogOpen] = useState(false);
  const [changeRequestDialogOpen, setChangeRequestDialogOpen] = useState(false);

  const handleMealApprovalDialogOpen = (food: PrototypeFood) => {
    setSelectedFood(food);
    setMealApprovalDialogOpen(true);
  };

  const handleMealApprovalDialogClose = () => {
    setMealApprovalDialogOpen(false);
  };

  const handleChangeRequestDialogOpen = (food: PrototypeFood) => {
    setSelectedFood(food);
    setChangeRequestDialogOpen(true);
  };

  const handleChangeRequestDialogClose = () => {
    setChangeRequestDialogOpen(false);
  };

  const commentClickHandler = (food: Food) => {
    setSelectedFood(food);
    commentsPopupRef.current?.open();
  };

  const [foodList, setFoodList] = useState<PrototypeFood[]>([]);

  const [filters, setFilters] = useState<FoodFilters>({
    country: resolveCountryFromKitchen((userKitchen && userKitchen[0]) || Kitchen.BH1),
    brand: Brand.CALO,
    kitchen: (userKitchen && userKitchen[0]) || Kitchen.BH1,
    isDeleted: false,
    status: '',
    ...JSON.parse(searchParams.get('filters') || `{}`)
  });

  const {
    mutate: createAction,
    mutateAsync: createActionAsync,
    isLoading: actionLoading
  } = useMutation(addPrototypeFoodAction, {
    onSuccess: () => {
      refetch();
      handleMealApprovalDialogClose();
    }
  });

  const { mutate: approveFoodStage1, isLoading: approveStage1Loading } = useMutation(approvePrototypeStage1Food, {
    onSuccess: () => {
      handleMealApprovalDialogClose();
      refetch();
    }
  });

  const { mutate: approveFoodStage2, isLoading: approveStage2Loading } = useMutation(approvePrototypeStage2Food, {
    onSuccess: () => {
      handleMealApprovalDialogClose();
      refetch();
    }
  });

  const { isLoading: selectedFoodLoading, isFetching: selectedFoodFetching } = useQuery<string, Error, Food[]>(
    [`food/prototype/slug/${selectedFood?.slug}`],
    getList,
    {
      onSuccess: (data: Food[]) => {
        if (data && data.length > 0) {
          const prioritySizes = ['M', 'S', 'L'];
          const foodWithPreferredSize = data.find((food) => prioritySizes.includes(food.size.toUpperCase()));
          const selectedFood = foodWithPreferredSize ?? data[0];
          setPrototypeComponents(
            selectedFood.components
              ?.filter((component) => component.prototype)
              .map((cmp) => ({ id: cmp.id, name: cmp.name?.en ?? '', type: 'component' })) || []
          );
        }
      }
    }
  );

  const handleMealApprovalSubmit = () => {
    if (selectedFood) {
      if (selectedFood.status === FoodStatus.awaitingNPDApproval) {
        approveFoodStage1({ id: selectedFood.id, slug: selectedFood.slug });
      } else if (selectedFood.status === FoodStatus.awaitingOpsApproval) {
        approveFoodStage2({ id: selectedFood.id, slug: selectedFood.slug });
      } else {
        const action = determineFoodAction(selectedFood.status);

        createAction(
          {
            action,
            id: selectedFood.id,
            slug: selectedFood.slug
          },
          {
            onSuccess: () => {
              handleMealApprovalDialogClose();
              refetch();
            }
          }
        );
      }
    }
  };

  const handleChangeRequestSubmit = async (comment: string) => {
    if (selectedFood && comment) {
      await createActionAsync(
        {
          action: PrototypeAction.requestChanges,
          message: comment,
          id: selectedFood.id,
          slug: selectedFood.slug
        },
        {
          onSuccess: () => {
            handleChangeRequestDialogClose();
            refetch();
          }
        }
      );
    }
  };

  const { data, isLoading, refetch } = useQuery<any, Error, PaginatedFoodList>(
    [
      'food/prototype',
      {
        filters: {
          brand: filters.brand,
          country: filters.country,
          kitchen: filters.kitchen,
          foodType: filters.foodType,
          dietType: filters.dietType,
          name: filters.name,
          componentId: filters.componentId ? filters.componentId : undefined,
          ingredientId: filters.ingredientId ? filters.ingredientId : undefined,
          foodTags: filters.foodTags,
          sizes: filters.sizes,
          status: filters.status,
          isDeleted: false,
          prototype: true
        },
        sort,
        page,
        limit: 50
      }
    ],
    getListWithParams,
    {
      suspense: false,
      enabled: roles.includes(Permission.VIEW_PROTOTYPE_FOOD_LIST),
      onSuccess: () => {
        searchParams.set('filters', JSON.stringify(filters));
        history.push({
          pathname: location.pathname,
          search: searchParams.toString()
        });
      }
    }
  );

  useEffect(() => {
    if (!data?.data) {
      return;
    }

    const list = data.data.filter((f) => {
      let res = true;
      if (filters.dietType) {
        res = res && (f.tags || []).includes(filters.dietType);
      }
      if (filters.foodType) {
        res = res && f.type.includes(Array.isArray(filters.foodType) ? filters.foodType[0] : filters.foodType);
      }

      return res;
    });

    setFoodList(list);
  }, [data]);

  useEffect(() => {
    setPage(0);
  }, [filters]);

  const closeCommentsPopup = () => {
    commentsPopupRef.current?.close();
  };

  const addCommentSuccessHandler = (data: Food) => {
    setSelectedFood(data);
    setFoodList((prev) => {
      const foodIndex = prev.findIndex((food) => food.id === data.id);
      return [...prev.slice(0, foodIndex), data, ...prev.slice(foodIndex + 1)];
    });
  };

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
        <Breadcrumbs separator={<ChevronRightIcon sx={{ fontSize: '15px' }} />} aria-label="breadcrumb">
          {chefPlaygroundFoodlistBreadCurmb}
        </Breadcrumbs>
        <Typography variant="h4" sx={styles.title}>
          Meals
        </Typography>
      </Box>
      <Stack sx={{ borderRadius: '16px', py: '16px' }}>
        <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '24px' }}>
            <Button
              onClick={() => dispatch(toggleUISettings())}
              startIcon={<FilterAltIcon style={{ fontSize: '24px' }} />}
              sx={styles.filterButton}
            >
              FILTER
            </Button>
            <InputMUI
              data-test="nameInput"
              type="text"
              placeholder={'Search'}
              value={filters.name}
              onChange={(e) => setFilters({ ...filters, name: e.target.value })}
              debounce
              sx={styles.searchInput}
              inputProps={{
                style: styles.searchInputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon fontSize="medium" />
                  </InputAdornment>
                )
              }}
            />
          </Box>
          <Button
            onClick={() => history.push(Routes.playgroundNewFood)}
            disabled={!roles.includes(Permission.CREATE_PROTOTYPE_FOOD)}
            startIcon={<CreateIcon style={{ fontSize: '24px' }} />}
            sx={styles.createButton}
          >
            CREATE MEAL
          </Button>
        </Stack>
        {isLoading ? (
          <CaloLoader />
        ) : (
          <Paper sx={styles.tableContainer}>
            <TableContainer>
              <Table sx={{ width: '100%' }}>
                <TableHead sx={styles.tableHead}>
                  <TableRow>
                    <TableCell sx={{ ...styles.tableHeadText, ...styles.tableCellMeal }}>Meal</TableCell>
                    <TableCell
                      sx={{
                        ...styles.tableHeadText,
                        ...styles.tableCellChef
                      }}
                    >
                      Chef
                    </TableCell>
                    <TableCell sx={{ ...styles.tableHeadText, ...styles.tableCellStatus }}>Status</TableCell>
                    <TableCell
                      sx={{
                        ...styles.tableHeadText,
                        ...styles.tableCellComments
                      }}
                    >
                      Comments
                    </TableCell>
                    <TableCell
                      sx={{
                        ...styles.tableHeadText,
                        ...styles.tableCellActions
                      }}
                    >
                      Share
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {foodList.map((food) => {
                    return (
                      <FoodRow
                        key={food.id}
                        food={food}
                        commentClickHandler={commentClickHandler}
                        changeRequestClickHandler={handleChangeRequestDialogOpen}
                        handleMealApprovalDialogOpen={handleMealApprovalDialogOpen}
                      />
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        )}
      </Stack>
      <FoodCommentsPopup
        selectedFood={selectedFood}
        ref={commentsPopupRef}
        successHandler={addCommentSuccessHandler}
        closeCommentsPopup={closeCommentsPopup}
      />
      <Settings onFilter={setFilters} filters={filters} />
      <MealApprovalDialog
        open={mealApprovalDialogOpen}
        handleClose={handleMealApprovalDialogClose}
        handleSubmit={handleMealApprovalSubmit}
        isPositiveButtonLoading={actionLoading || approveStage1Loading || approveStage2Loading}
        isLoading={selectedFoodLoading || selectedFoodFetching}
        prototypeItems={prototypeComponents}
        status={selectedFood?.status}
        type="meal"
      />
      <ChangeRequestDialog
        open={changeRequestDialogOpen}
        title="Add comments for changes"
        handleClose={handleChangeRequestDialogClose}
        handleSubmit={handleChangeRequestSubmit}
        isPositiveButtonLoading={actionLoading}
      />
    </>
  );
};

export default FoodList;
