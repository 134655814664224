import { forwardRef, useMemo } from 'react';

import { startCase, uniq } from 'lodash-es';

import { Permission } from '@calo/dashboard-types';
import { Ingredient, IngredientCategory } from '@calo/types';
import { Chip, Divider, Stack, Tooltip, Typography } from '@mui/material';
import { caloTheme } from 'assets/images/theme/calo';
import { useUserRoles } from 'lib/hooks';
import { FoodComponentWithQuantity } from 'lib/interfaces';
import CaloLoader from '../CaloLoader';
import { processComponentIngredients } from './helpers';

interface IngredientsProps {
  ingredients: any[];
  isLoading: boolean;
  components: FoodComponentWithQuantity[];
}

const Ingredients = forwardRef<HTMLDivElement, IngredientsProps>(({ components, isLoading }: IngredientsProps, ref) => {
  const roles = useUserRoles();

  const processedIngredientsMemo: Ingredient[] = useMemo(() => {
    const allIngredients = processComponentIngredients(components);

    return allIngredients.map((i: any) => ({
      ...i,
      category: i.category?.filter((c) => c !== IngredientCategory.other)
    }));
  }, [components]);

  const uniqAllergens = uniq(processedIngredientsMemo.flatMap((i) => i.category));

  return (
    <>
      {roles.includes(Permission.VIEW_INGREDIENT) && (
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          ref={ref}
          sx={{ backgroundColor: caloTheme.palette.white, borderRadius: '8px', boxShadow: 2 }}
        >
          <Typography
            sx={{
              fontSize: '24px',
              fontWeight: 400,
              lineHeight: '150%',
              padding: '16px'
            }}
          >
            Ingredients
          </Typography>
          <Divider sx={{ color: caloTheme.palette.divider, height: '1px', width: '100%' }} />
          <Stack direction="column" justifyContent="flex-start" alignItems="flex-start" sx={{ padding: '24px 16px 16px 16px' }}>
            <Stack direction="row" flexWrap="wrap" sx={{ m: 2, fontWeight: 600 }}>
              {isLoading ? (
                <CaloLoader />
              ) : (
                processedIngredientsMemo.map((ing) => (
                  <Tooltip
                    key={ing.id}
                    title={ing.category.sort().join(', ')}
                    placement="top"
                    arrow
                    hidden={ing.category?.length === 0}
                  >
                    <Chip
                      label={ing.name.en}
                      sx={{
                        color: ing.category?.length === 0 ? caloTheme.palette.neutral700 : caloTheme.palette.red,
                        backgroundColor:
                          ing.category?.length === 0 ? caloTheme.palette.neutral100 : caloTheme.palette.secondaryPink100,
                        fontSize: '14px',
                        my: '8px',
                        mx: '4px'
                      }}
                    />
                  </Tooltip>
                ))
              )}
            </Stack>
            {uniqAllergens.length > 0 && (
              <Typography sx={{ fontSize: '16px', lineHeight: '20px', m: 2 }}>
                Warning: Includes {uniqAllergens.map(startCase).sort().join(', ')}.
              </Typography>
            )}
          </Stack>
        </Stack>
      )}
    </>
  );
});

export default Ingredients;
