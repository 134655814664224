import { startCase } from 'lodash';

import { CustomComponentSection, CustomMealCategory, FoodComponentType } from '@calo/types';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Checkbox, FormControlLabel, IconButton, MenuItem, TableCell, TableRow, TextField } from '@mui/material-v6';

import { InputMUI } from '../..';
import { caloTheme } from '../../../assets/images/theme/calo';
import { handleCategoryText } from './helpers';
import { styles } from './styles';

interface CustomSectionRowProps {
  section: CustomComponentSection;
  isValid: boolean;
  isDisabled?: boolean;
  updateQuantity: (category: CustomMealCategory, quantity: number) => void;
  removeSection: (category: CustomMealCategory) => void;
  toggleSectionAvailability: (category: CustomMealCategory) => void;
  selectTag: (category: CustomMealCategory, tag: FoodComponentType) => void;
}

const CustomSectionRow = ({
  section,
  isValid,
  isDisabled,
  selectTag,
  updateQuantity,
  removeSection,
  toggleSectionAvailability
}: CustomSectionRowProps) => {
  return (
    <TableRow>
      <TableCell>
        <IconButton data-test={`removeSectionButton#${section.category}`} onClick={() => removeSection(section.category)}>
          <DeleteOutlineIcon sx={{ color: caloTheme.palette.neutral900 }} />
        </IconButton>
      </TableCell>
      <TableCell sx={{ ...styles.tableCell, ...styles.tableCellText }}>
        {startCase(handleCategoryText(section.category))}
      </TableCell>
      <TableCell sx={styles.tableCell}>
        <InputMUI
          data-test={`sectionQuantityInput#${section.category}`}
          type="number"
          value={section.quantity}
          onChange={(e) => updateQuantity(section.category, Number(e.target.value))}
          min={0}
          step="any"
          debounce
          disabled={isDisabled}
          error={!isValid}
          sx={{
            width: '100%'
          }}
          inputProps={{
            style: {
              fontSize: '16px'
            }
          }}
        />
      </TableCell>
      <TableCell sx={styles.tableCell}>
        <TextField
          data-test={`sectionTagInput#${section.category}`}
          select
          label="Tag"
          value={section.tag ? section.tag : ''}
          SelectProps={{
            multiple: false,
            value: section.tag ? section.tag : '',
            onChange: (e) => selectTag(section.category, e.target.value as FoodComponentType),
            sx: {
              '.MuiSelect-select': {
                alignContent: 'center'
              }
            }
          }}
          sx={{ width: '100%' }}
          disabled={isDisabled}
        >
          {Object.values(FoodComponentType).map((prepOrder) => (
            <MenuItem key={prepOrder} value={prepOrder}>
              {prepOrder}
            </MenuItem>
          ))}
        </TextField>
      </TableCell>
      <TableCell sx={styles.tableCell}>
        <FormControlLabel
          control={
            <Checkbox
              data-test={`availabilityCheckbox#${section.category}`}
              name="availability"
              checked={section.availability}
              onChange={() => toggleSectionAvailability(section.category)}
              disabled={isDisabled}
            />
          }
          label=""
        />
      </TableCell>
    </TableRow>
  );
};

export default CustomSectionRow;
