import { Kitchen } from '@calo/types';
import { Card, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import { caloTheme } from 'assets/images/theme/calo';
import RetailMenuTableRow from './RetailMenuTableRow';

interface AddonsProps {
  retailMenu: any;
  toggleMenuItemVisibility: (itemId: string) => void;
  onEditClick: (itemId?: string) => void;
  onDeleteClick: (itemId: string) => Promise<void>;
  kitchen: Kitchen;
}

const RetailMenuRow = ({ retailMenu, toggleMenuItemVisibility, onEditClick, onDeleteClick, kitchen }: AddonsProps) => {
  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      border: 'none'
    },
    [`&.${tableCellClasses.body}`]: {
      border: 'none',
      justifyContent: 'space-between'
    },
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 'bold',
    lineHeight: '24px', // 171.429%
    letterSpacing: '0.17px'
  }));

  return (
    <Card
      variant="outlined"
      sx={{ border: 'none', borderRadius: '8px', paddingBottom: '4px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)' }}
    >
      <Table
        sx={{
          minHeight: '120px',
          overflow: 'auto',
          width: '100%',
          [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
            flexDirection: 'column'
          }
        }}
      >
        <TableHead
          sx={{
            backgroundColor: '#EEE',
            color: 'black',
            flexWrap: 0,
            justifyContent: 'space-between',
            width: '100%'
          }}
        >
          <TableRow
            sx={{
              backgroundColor: '#EEE',
              color: 'black',
              width: '100%',
              flexWrap: 0,
              justifyContent: 'space-between'
            }}
          >
            <StyledTableCell>Name</StyledTableCell>
            <StyledTableCell>Food&nbsp;Category</StyledTableCell>
            <StyledTableCell>Retail&nbsp;Price</StyledTableCell>
            <StyledTableCell>Size</StyledTableCell>
            <StyledTableCell>Start&nbsp;Date</StyledTableCell>
            <StyledTableCell>End&nbsp;Date</StyledTableCell>
            <StyledTableCell>Status</StyledTableCell>
            <StyledTableCell>Swap&nbsp;Available</StyledTableCell>
            <StyledTableCell>Actions</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {retailMenu.map((foodItem: any, index: number) => (
            <RetailMenuTableRow
              key={index + foodItem.id}
              foodItem={foodItem}
              kitchen={kitchen}
              toggleMenuItemVisibility={() => toggleMenuItemVisibility(foodItem.id)}
              onEditClick={onEditClick}
              onDeleteClick={() => onDeleteClick(foodItem.id)}
            />
          ))}
        </TableBody>
      </Table>
    </Card>
  );
};

export default RetailMenuRow;
