import { Button } from '@mui/material-v6';
import { caloThemeV2 } from 'assets/themev2';
import { ModalRef } from 'components/Modal';
import Popup from 'components/Popup';
import { getWeek } from 'date-fns';
import { MenuPresentationType, Routes } from 'lib/enums';
import { useRef, useState } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { CountryKitchenForm } from 'views/Menu/MainMenu/components';
import CalenderDialog from 'views/Menu/MainMenu/components/CalendarDialog/CalendarDialog';

interface CreateWeeklyMenuActionsProps {
  disabled?: boolean;
}

const defaultParams = { country: '', kitchen: '', day: getWeek(new Date()) as any };

const CreateWeeklyMenuActions = ({ disabled }: CreateWeeklyMenuActionsProps) => {
  const history = useHistory();
  const countryKitchenFormPopupRef = useRef<ModalRef>();

  const [isNext, setIsNext] = useState(false);
  const [params, setParams] = useState(defaultParams);
  const [selectedWeek, setSelectedWeek] = useState(getWeek(new Date(), { weekStartsOn: 0 }) + 1);
  const [selectedDate, setSelectedDate] = useState(new Date());

  const handleCalendarDone = (weekNumber: number) => {
    const path = generatePath(Routes.newMenu, {
      kitchen: params.kitchen,
      country: params.country,
      type: MenuPresentationType.weekly,
      day: weekNumber
    });
    history.push(path);
  };

  const closeHandler = () => {
    setParams(defaultParams);
    setIsNext(false);
    countryKitchenFormPopupRef.current?.close();
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        size="large"
        disabled={disabled}
        sx={{ color: caloThemeV2.palette.primary.contrastText }}
        onClick={() => {
          countryKitchenFormPopupRef.current?.open();
        }}
      >
        Create Menu
      </Button>
      <Popup maxWidth="sm" closeIcon onClose={closeHandler} ref={countryKitchenFormPopupRef}>
        {!isNext && (
          <CountryKitchenForm params={params} setIsNext={setIsNext} setParams={setParams} closeHandler={closeHandler} />
        )}
        {isNext && (
          <CalenderDialog
            isOpen={isNext}
            create={true}
            selectedDate={selectedDate}
            selectedWeek={selectedWeek}
            disablePreviousWeeks={true}
            confirmationText="Select"
            onDone={handleCalendarDone}
            setSelectedDate={setSelectedDate}
            setSelectedWeek={setSelectedWeek}
            onClose={() => closeHandler()}
          />
        )}
      </Popup>
    </>
  );
};

export default CreateWeeklyMenuActions;
