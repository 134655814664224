import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Alert, SxProps, Theme, Typography } from '@mui/material';

const styles = {
  disclaimerText: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '150%',
    display: 'inline'
  },
  disclaimerTextBold: {
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '150%',
    display: 'inline'
  }
};

const Disclaimer = ({ type, sx }: { type: 'meal' | 'component' | 'ingredient'; sx?: SxProps<Theme> }) => {
  return (
    <Alert
      color="info"
      iconMapping={{
        success: <InfoOutlinedIcon fontSize="inherit" />
      }}
      sx={{
        padding: '6px 16px',
        ...sx
      }}
    >
      <Typography sx={styles.disclaimerText}>
        This section is a playground.&nbsp;
        <Typography sx={styles.disclaimerTextBold}>
          Any {type} you create here do not get created on the actual food database.&nbsp;
        </Typography>
        You can use this area to play around & experiment with different recipes & combinations. Once you’re done, you can send
        your creations for approval to publish them on the menu.
      </Typography>
    </Alert>
  );
};

export default Disclaimer;
