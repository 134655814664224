import { Button, Stack, Typography } from '@mui/material-v6';
import { DateCalendar, LocalizationProvider } from '@mui/x-date-pickers-v6';
import { AdapterDayjs } from '@mui/x-date-pickers-v6/AdapterDayjs';
import { caloThemeV2 } from 'assets/themev2';
import { format } from 'date-fns';
import dayjs from 'dayjs';
import { CreateParams } from 'views/Menu/MainMenu/types';

interface DateFormProps {
  continueHandler: any;
  params: CreateParams;
  closeHandler: () => void;
  setParams: React.Dispatch<React.SetStateAction<CreateParams>>;
}

const DateForm = ({ continueHandler, params, setParams, closeHandler }: DateFormProps) => {
  return (
    <>
      <Typography sx={{ fontSize: '20px', fontWeight: 600 }}>Select Date</Typography>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateCalendar
          value={dayjs(params.day)}
          onChange={(day: dayjs.Dayjs) => {
            setParams((prev) => ({
              ...prev,
              day: format(day.toDate(), 'yyyy-MM-dd')
            }));
          }}
        />
      </LocalizationProvider>
      <Stack sx={{ marginTop: '16px', flexDirection: 'row', gap: 2, alignItems: 'center', alignSelf: 'flex-end' }}>
        <Button variant="text" disabled={!params.country || !params.kitchen} onClick={continueHandler}>
          Continue
        </Button>
        <Button variant="text" onClick={closeHandler} sx={{ color: caloThemeV2.palette.text.primary }}>
          Cancel
        </Button>
      </Stack>
    </>
  );
};

export default DateForm;
