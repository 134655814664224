import { Box, Typography } from '@mui/material';

import { CaloLoader, MenuTable } from 'components';
import { getMenuTagsFromFoodList } from 'lib/helpers';
import { Food, Menu, MenuFood } from 'lib/interfaces';
import Container from './Container';
import { styles } from './styles';

export interface DayMenuProps {
  isLoading: boolean;
  selectedMenu: Menu | undefined;
  isEditable: boolean;
  menuList: Menu[];
  setFoodToReplace: React.Dispatch<React.SetStateAction<{ food: MenuFood; menuId?: string } | undefined>>;
  setMenuList: React.Dispatch<React.SetStateAction<Menu[]>>;
}

const DayMenuCard = ({ isLoading, selectedMenu, isEditable, menuList, setFoodToReplace, setMenuList }: DayMenuProps) => {
  if (isLoading) {
    return <CaloLoader />;
  }

  if (!selectedMenu) {
    return (
      <Container>
        <Typography sx={styles.noMenuText}>No Menu For this Day</Typography>
      </Container>
    );
  }

  const handleRemoveFood = (ids: string[]) => {
    const menuIndex = menuList.findIndex((menu) => menu.id === selectedMenu.id);
    const updatedMenuList = [...menuList];
    const menuTime = new Date(updatedMenuList[menuIndex].day).getTime();
    const updatedFood = updatedMenuList[menuIndex].food.filter((f) => !ids.includes(f.id));
    const updatedTags = getMenuTagsFromFoodList(updatedFood as Food[], menuTime);

    updatedMenuList[menuIndex] = {
      ...updatedMenuList[menuIndex],
      food: updatedFood,
      tags: updatedTags
    };
    setMenuList(updatedMenuList);
  };

  return (
    <Box sx={styles.menuTable}>
      <MenuTable
        key={selectedMenu.id}
        selectedDate={selectedMenu.id}
        isEditable={isEditable}
        kitchen={selectedMenu?.kitchen}
        brand={selectedMenu?.brand}
        foodList={selectedMenu?.food}
        foodLabel={selectedMenu?.tags}
        setFoodToReplace={setFoodToReplace}
        removeFood={handleRemoveFood}
        draftTrack={selectedMenu.draft}
      />
    </Box>
  );
};

export default DayMenuCard;
