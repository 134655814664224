import { Brand, Country, Kitchen } from '@calo/types';
import { MenuItem, Stack, TextField } from '@mui/material-v6';
import { toggleUISettings } from 'actions';
import { SideDrawer } from 'components';
import { getAccessibleCountries } from 'lib';
import { useUserKitchens } from 'lib/hooks';
import { AppState } from 'lib/interfaces';
import { useDispatch, useSelector } from 'react-redux';
import { MenuPathUpdateFunctions } from '../../types';

interface MenuFilter {
  country: Country;
  brand: Brand;
  kitchen: Kitchen;
}

interface SettingsProps {
  onFilter: MenuPathUpdateFunctions;
  filters: MenuFilter;
}

const Settings = ({ filters, onFilter }: SettingsProps) => {
  const { settingsVisible } = useSelector((state: AppState) => ({
    settingsVisible: state.ui.settings
  }));

  const userKitchens: string[] = useUserKitchens();
  const dispatch = useDispatch();

  return (
    <SideDrawer visible={settingsVisible} onClose={() => dispatch(toggleUISettings())}>
      <section className="section">
        <h5 className="title is-5">Filters</h5>
        <Stack sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
          <TextField
            sx={{
              width: '100%'
            }}
            select
            label="Country"
            defaultValue={filters.country}
            value={filters.country}
            onChange={(data) => onFilter.setKitchen(userKitchens.find((k) => k.includes(data.target.value)) as Kitchen)}
          >
            {getAccessibleCountries(userKitchens).map((c) => (
              <MenuItem key={c} value={c}>
                {Country[c]}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            sx={{
              width: '100%'
            }}
            select
            label="Kitchen"
            value={filters.kitchen}
            onChange={(data) => onFilter.setKitchen(data.target.value as Kitchen)}
          >
            {userKitchens
              .filter((r) => r.includes(filters.country || Country.BH))
              .map((kitchen) => (
                <MenuItem key={kitchen} value={kitchen}>
                  {kitchen}
                </MenuItem>
              ))}
          </TextField>
        </Stack>
      </section>
    </SideDrawer>
  );
};

export default Settings;
