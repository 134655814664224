import { Button, Stack, Typography } from '@mui/material-v6';
import { caloThemeV2 } from 'assets/themev2';
import { ModalRef } from 'components/Modal/Modal';

interface DailyPublishProps {
  selectedMenuDay: string;
  isGenerateMenuLoading: boolean;
  generateMenuHandler: () => void;
  publishMenuRef: React.MutableRefObject<ModalRef | undefined>;
}

const DailyPublish = ({ selectedMenuDay, isGenerateMenuLoading, generateMenuHandler, publishMenuRef }: DailyPublishProps) => {
  return (
    <Stack sx={{ width: '506px' }}>
      <Typography sx={{ fontSize: '20px', fontWeight: 500 }}>Confirm menu publication?</Typography>
      <Typography sx={{ marginTop: 2, fontSize: '16px' }}>
        Are you sure you want to publish this menu for {selectedMenuDay}?{' '}
      </Typography>
      <Typography sx={{ fontSize: '16px' }}>Once published, it will be visible to all users.</Typography>

      <Stack sx={{ flexDirection: 'row', gap: 2, alignItems: 'center', alignSelf: 'flex-end', marginTop: 4 }}>
        <Button variant="outlined" disabled={isGenerateMenuLoading} onClick={generateMenuHandler}>
          Publish
        </Button>
        <Button
          variant="text"
          onClick={() => publishMenuRef.current?.close()}
          disabled={isGenerateMenuLoading}
          sx={{
            color: caloThemeV2.palette.text.primary
          }}
        >
          Cancel
        </Button>
      </Stack>
    </Stack>
  );
};

export default DailyPublish;
