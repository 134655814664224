import { caloTheme } from 'assets/images/theme/calo';

export const styles = {
  breadcrumbs: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '150%'
  },
  breadcrumbActive: {
    color: caloTheme.palette.textPrimary
  },
  breadcrumbInactive: {
    color: caloTheme.palette.textSecondary
  },
  title: {
    fontSize: '34px',
    fontWeight: '400',
    lineHeight: '125%',
    color: caloTheme.palette.textPrimary
  },
  filterButton: {
    padding: '8px 22px',
    textTransform: 'none',
    color: caloTheme.palette.neutral900,
    fontSize: '15px',
    fontWeight: 500,
    lineHeight: '125%',
    ':hover': {
      backgroundColor: 'transparent'
    },
    border: `1px solid ${caloTheme.palette.textPrimary}`,
    borderRadius: '4px'
  },
  searchInput: {
    width: '90%'
  },
  searchInputProps: {
    height: '40px',
    fontSize: '16px',
    backgroundColor: 'white'
  },
  createButton: {
    padding: '8px 22px',
    textTransform: 'uppercase',
    color: caloTheme.palette.neutral900,
    fontSize: '15px',
    fontWeight: 500,
    lineHeight: '125%',
    ':hover': {
      backgroundColor: 'transparent'
    },
    border: `1px solid ${caloTheme.palette.textPrimary}`,
    borderRadius: '4px'
  },
  tableHead: {
    backgroundColor: caloTheme.palette.grey200,
    border: 0
  },
  tableHeadText: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '24px'
  },
  tableCellMeal: {
    borderRadius: '8px 0 0 0',
    border: 0,
    width: '20%'
  },
  tableCellChef: {
    width: '20%'
  },
  tableCellStatus: {
    width: '20%'
  },
  tableCellComments: {
    width: '30%'
  },
  tableCellActions: {
    width: '10%',
    borderRadius: '0 8px 0 0'
  },
  stack: {
    borderRadius: '16px',
    py: '16px'
  },
  stackHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  filterBox: {
    display: 'flex',
    alignItems: 'center',
    gap: '24px'
  },
  tableContainer: {
    width: '100%',
    mt: '24px',
    boxShadow: 'none'
  }
};
