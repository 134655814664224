import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { Button } from '@mui/material-v6';

const FilterButton = ({
  onClick,
  disabled = false,
  isActive = false
}: {
  isActive?: boolean;
  disabled?: boolean;
  onClick: () => void;
}) => {
  return (
    <Button
      variant="outlined"
      color={isActive ? 'primary' : 'inherit'}
      startIcon={<FilterAltIcon fontSize="small" />}
      onClick={onClick}
      disabled={disabled}
      sx={{ height: '42px', paddingX: '22px', fontSize: '15px', fontWeight: 500 }}
    >
      Filter
    </Button>
  );
};

export default FilterButton;
