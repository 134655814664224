import { Brand, Country, RetailBranch } from '@calo/types';

export interface RetailFilter {
  country: Country;
  brand: Brand;
  branch: RetailBranch;
  status?: 'Active' | 'Disabled';
  foodCategory?: string[];
  size?: string;
  startDate?: Date;
  endDate?: Date;
}

export enum PickUpStatus {
  CREATED = 'CREATED',
  READY_FOR_PICK_UP = 'READY_FOR_PICK_UP',
  PICKED_UP = 'PICKED_UP',
  CANCELLED = 'CANCELLED',
  BARISTA_EN_ROUTE = 'BARISTA_EN_ROUTE'
}

export enum OrderItemType {
  ADDON = 'ADDON',
  ADDON_SUBSCRIPTION = 'ADDON_SUBSCRIPTION',
  CAKE = 'CAKE',
  FLOWERS = 'FLOWERS',
  FOOD = 'FOOD',
  FOOD_SUBSCRIPTION = 'FOOD_SUBSCRIPTION',
  LOYALTY = 'LOYALTY',
  MERCHANDISE = 'MERCHANDISE',
  OTHER = 'OTHER',
  RETAIL = 'RETAIL'
}

export enum PickUpType {
  SELF_PICKUP = 'SELF_PICKUP',
  CAR_HOP = 'CAR_HOP'
}
