import { ProcessingStage } from '@calo/dashboard-types';
import { FoodStatus, PrototypeAction } from 'lib/enums';

export const determineComponentOrIngredientAction = (
  stage: ProcessingStage
): Exclude<PrototypeAction, PrototypeAction.comment | PrototypeAction.requestChanges> => {
  switch (stage) {
    case ProcessingStage.draft:
      return PrototypeAction.submitForNpdApproval;
    case ProcessingStage.awaitingNPDApproval:
      return PrototypeAction.submitForOpsApproval;
    default:
      return PrototypeAction.approve;
  }
};

export const determineFoodAction = (
  stage: FoodStatus
): Exclude<PrototypeAction, PrototypeAction.comment | PrototypeAction.requestChanges> => {
  switch (stage) {
    case FoodStatus.draft:
      return PrototypeAction.submitForNpdApproval;
    case FoodStatus.awaitingNPDApproval:
      return PrototypeAction.submitForOpsApproval;
    default:
      return PrototypeAction.approve;
  }
};
