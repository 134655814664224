import { ProcessingStage } from '@calo/dashboard-types';
import { Button, Chip, Typography } from '@mui/material';
import { foodStatuslabelColorMapping, foodStatuslabelMapping } from 'lib/constants';
import { FoodStatus } from 'lib/enums';
import { getPrototypeActionApprovalButtonText } from 'lib/helpers/getPrototypeActionApprovalButtonText';
import { startCase } from 'lodash-es';
import { ApprovalActionMenu } from '../ApprovalActionMenu/ApprovalActionMenu';

const styles = {
  buttonText: {
    fontSize: '13px',
    fontWeight: 500,
    lineHeight: '125%',
    textTransform: 'uppercase'
  }
};

interface FoodRowStatusBoxProps<T> {
  item: T;
  status?: FoodStatus | ProcessingStage;
  handleMealApprovalDialogOpen: (item: T) => void;
  commentClickHandler: (item: T) => void;
  hasPermission: (status: FoodStatus | ProcessingStage) => boolean;
}

export const FoodRowStatusBox = <T,>({
  item,
  status,
  commentClickHandler,
  handleMealApprovalDialogOpen,
  hasPermission
}: FoodRowStatusBoxProps<T>) => {
  const renderChip = () => (
    <Chip
      label={startCase(foodStatuslabelMapping[status || FoodStatus.draft])}
      color={foodStatuslabelColorMapping[status || FoodStatus.draft]}
      size="medium"
      variant="outlined"
    />
  );

  const renderApprovalActionMenu = () => (
    <ApprovalActionMenu
      item={item}
      type="meal"
      actionButtonText={getPrototypeActionApprovalButtonText(status)}
      commentClickHandler={commentClickHandler}
      handleApprove={() => handleMealApprovalDialogOpen(item)}
    />
  );

  switch (status) {
    case FoodStatus.draft:
      return hasPermission(status) ? (
        <Button sx={{ px: 0 }} onClick={() => handleMealApprovalDialogOpen(item)}>
          <Typography sx={styles.buttonText}>Send for 1st Approval</Typography>
        </Button>
      ) : (
        renderChip()
      );

    case FoodStatus.awaitingNPDApproval:
    case FoodStatus.awaitingOpsApproval:
      return hasPermission(status) ? renderApprovalActionMenu() : renderChip();

    case FoodStatus.approved:
      return renderChip();

    default:
      return <Chip label="Unknown Status" color="default" size="medium" variant="outlined" />;
  }
};
