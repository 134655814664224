import { Permission, UpdateMenuReq } from '@calo/dashboard-types';
import { FoodType } from '@calo/types';
import CloseIcon from '@mui/icons-material/Close';
import { Button, IconButton, Stack, Typography } from '@mui/material-v6';
import { getMenu, updateMenu } from 'actions/menu';
import { caloThemeV2 } from 'assets/themev2';
import { format, subDays } from 'date-fns';
import { formatNumber, handleMealCost, updateFoodTags } from 'lib/helpers';
import { useUserRoles } from 'lib/hooks';
import { Food, Menu, MenuFood } from 'lib/interfaces';
import { ceil, sortBy, uniq, uniqBy } from 'lodash-es';
import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { AddMealsOptionsDailyMenu, DailyMenuDatePicker } from '../../components';
interface FoodToReplace {
  food: MenuFood;
}

interface DailyMenuActionsCardProps {
  menuList: Menu[];
  foodList: Food[];
  foodToReplace?: FoodToReplace;
  selectedDay: string;
  selectedMenu?: Menu;
  isEditable: boolean;
  discardEditsHandler: () => void;
  setOriginalMenuList: React.Dispatch<React.SetStateAction<Menu[]>>;
  setMenuList: React.Dispatch<React.SetStateAction<Menu[]>>;
  setIsEditable: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedDay: (newDate: string) => void;
  setFoodToReplace: React.Dispatch<React.SetStateAction<FoodToReplace | undefined>>;
}

const DailyMenuActionsCard = ({
  selectedDay,
  menuList,
  selectedMenu,
  foodList,
  isEditable,
  foodToReplace,
  setOriginalMenuList,
  setFoodToReplace,
  setSelectedDay,
  setIsEditable,
  setMenuList,
  discardEditsHandler
}: DailyMenuActionsCardProps) => {
  const { mutateAsync: updateMutation, isLoading: isUpdateMenuLoading } = useMutation(updateMenu);

  const userRoles = useUserRoles();
  const [menuCost, setMenuCost] = useState<number>(0);
  const [duplicateMenuDate, setDuplicateMenuDate] = useState<Date>(subDays(new Date(), 1));
  const [deletedDuplicatedMenuFood, setDeletedDuplicatedMenuFood] = useState<string[]>([]);

  const filteredList = foodList.filter((food) => ['XS', 'S', 'M', 'L'].includes(food.size));
  const sortedList = sortBy(filteredList, (f) => `${f.name.en}-${f.size}`);
  const groupByName = uniqBy(sortedList, 'name.en');

  useEffect(() => {
    const calculateMenuCost = () => {
      let cost = 0;
      let numberOfMeals = 0;
      for (const food of groupByName) {
        if (
          food.type.includes(FoodType.breakfast) ||
          food.type.includes(FoodType.lunch) ||
          food.type.includes(FoodType.dinner) ||
          food.type.includes(FoodType.snack)
        ) {
          const foodCost = handleMealCost(food.name.en, sortedList, food.kitchen);
          cost += foodCost;
          numberOfMeals += 1;
        }
      }
      setMenuCost(ceil(+formatNumber(cost) / numberOfMeals));
    };
    calculateMenuCost();
  }, [groupByName, sortedList]);

  const handleSave = (action: 'save' | 'edit') => {
    if (action === 'edit') {
      setIsEditable((prev) => !prev);
      return;
    }

    if (!selectedMenu) {
      toast('No menu selected', { type: 'error' });
      return;
    }

    onSubmit({
      day: selectedMenu.id,
      food: selectedMenu.food.map((food) => food.id),
      tags: selectedMenu.tags as any,
      draft: selectedMenu.draft as any
    });
  };

  const shouldDisableDate = (day: Date) => {
    const formattedDate = format(new Date(day).getTime(), 'yyyy-MM-dd');
    return !menuList?.some((menu) => formattedDate === menu.id);
  };

  const onSubmit = async (values: UpdateMenuReq) => {
    await updateMutation(
      {
        ...values,
        id: selectedMenu?.id ?? '',
        mKitchen: selectedMenu!.kitchen,
        mBrand: selectedMenu!.brand
      } as any,
      {
        onSuccess: (data: Menu) => {
          if (!data) {
            return;
          }
          const menuIndex = menuList.findIndex((menu) => menu.id === data.id);

          if (menuIndex < 0) {
            return;
          }

          const updatedMenuList = [...menuList];
          updatedMenuList[menuIndex] = {
            ...updatedMenuList[menuIndex],
            ...data
          };
          setIsEditable((prev) => !prev);
          setMenuList(updatedMenuList);
          setOriginalMenuList(updatedMenuList);
        }
      }
    );
  };

  const { refetch: fetchDuplicateMenu, isLoading: isDuplicateMenuLoading } = useQuery<any, Error, Menu>(
    [`menu/${format(duplicateMenuDate, 'yyyy-MM-dd')}`, { brand: selectedMenu?.brand, kitchen: selectedMenu?.kitchen }],
    getMenu,
    {
      enabled: false,
      onSuccess: (data) => {
        const menuIndex = menuList.findIndex((menu) => menu.id === selectedMenu?.id);
        if (menuIndex < 0) {
          return;
        }
        const updatedMenuList = [...menuList];
        const foodList = data.food.filter((food) => !food.deletedAt);
        const deletedFood = data.food.filter((food) => food.deletedAt);
        updatedMenuList[menuIndex] = {
          ...updatedMenuList[menuIndex],
          food: foodList,
          draft: data.draft,
          tags: data.tags
        };
        toast('Menu duplicated successfully', { type: 'success' });
        setDeletedDuplicatedMenuFood(uniq(deletedFood.map((food) => food.name.en)));
        setMenuList(updatedMenuList);
      }
    }
  );

  const handleReplaceFood = (selectedFoodSizes: Food[]) => {
    if (!selectedMenu) {
      return;
    }

    if (!foodToReplace) {
      toast('No food to replace with', { type: 'error' });
      return;
    }
    const menuTime = new Date(selectedMenu.day).getTime();
    const updatedTags = updateFoodTags(selectedFoodSizes, selectedMenu.tags, menuTime);
    const filteredFood = selectedMenu.food.filter((f) => f.name.en !== foodToReplace.food.name.en);
    const updatedFood = [...filteredFood, ...selectedFoodSizes.map((food) => ({ ...food, new: true }))];

    const menuIndex = menuList.findIndex((menu) => menu.id === selectedMenu.id);

    if (menuIndex < 0) {
      console.error('menu does not exist');
    }

    const updatedMenuList = [...menuList];
    updatedMenuList[menuIndex] = {
      ...updatedMenuList[menuIndex],
      food: updatedFood,
      tags: updatedTags
    };

    setMenuList(updatedMenuList);
  };

  const handleAddFood = (selectedFoodSizes: Food[]) => {
    if (!selectedMenu) {
      return;
    }
    const menuTime = new Date(selectedMenu.day).getTime();
    const updatedTags = updateFoodTags(selectedFoodSizes, selectedMenu.tags, menuTime);
    const updatedFood = [...selectedMenu.food, ...selectedFoodSizes.map((f) => ({ ...f, new: true }))];
    const menuIndex = menuList.findIndex((menu) => menu.id === selectedMenu.id);

    if (menuIndex < 0) {
      toast('Cannot add food, menu does not exist', { type: 'error' });
      console.error('menu does not exist');
      return;
    }

    const updatedMenuList = [...menuList];
    updatedMenuList[menuIndex] = {
      ...updatedMenuList[menuIndex],
      food: uniqBy(updatedFood, 'id'),
      tags: updatedTags
    };

    setMenuList(updatedMenuList);
  };

  return (
    <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', paddingY: 2 }}>
      <Stack sx={{ flexDirection: 'row', gap: 2, alignItems: 'center' }}>
        <DailyMenuDatePicker
          disabled={isEditable}
          selectedDay={selectedDay}
          setSelectedDay={(day) => setSelectedDay(format(day, 'yyyy-MM-dd'))}
          shouldDisableDate={shouldDisableDate}
        />
        {selectedMenu && (
          <>
            <Typography
              sx={{ fontSize: '20px', color: caloThemeV2.palette.text.primary, fontWeight: 600 }}
            >{`${uniqBy(selectedMenu.food, 'name.en').length} Meals`}</Typography>
            <Typography
              sx={{ fontSize: '20px', color: caloThemeV2.palette.text.primary, fontWeight: 600 }}
            >{`Daily Menu Cost: ${menuCost || 0}%`}</Typography>
          </>
        )}
      </Stack>
      <Stack sx={{ flexDirection: 'row', gap: 2, alignItems: 'center' }}>
        {selectedMenu && isEditable && (
          <AddMealsOptionsDailyMenu
            selectedMenu={selectedMenu}
            foodToReplace={foodToReplace}
            disabled={isUpdateMenuLoading || isDuplicateMenuLoading}
            isDuplicateMenuLoading={isDuplicateMenuLoading}
            duplicateMenuDate={duplicateMenuDate}
            deletedDuplicatedMenuFood={deletedDuplicatedMenuFood}
            setDeletedDuplicatedMenuFood={setDeletedDuplicatedMenuFood}
            handleReplaceFood={handleReplaceFood}
            fetchDuplicateMenu={fetchDuplicateMenu}
            handleFoodChanges={handleAddFood}
            setFoodToReplace={setFoodToReplace}
            setDuplicateMenuDate={setDuplicateMenuDate}
          />
        )}
        <Button
          variant="outlined"
          color="inherit"
          size="large"
          disabled={
            !selectedMenu ||
            isUpdateMenuLoading ||
            isDuplicateMenuLoading ||
            (isEditable ? !userRoles.includes(Permission.UPDATE_MENU) : false)
          }
          onClick={selectedMenu ? () => handleSave(isEditable ? 'save' : 'edit') : undefined}
          sx={{
            height: '42px',
            paddingX: '22px',
            fontSize: '15px',
            fontWeight: 500,
            color: caloThemeV2.palette.text.primary
          }}
        >
          {isEditable ? 'Save' : 'Edit'}
        </Button>
        {isEditable && (
          <IconButton onClick={discardEditsHandler} disabled={!selectedMenu || isUpdateMenuLoading || isDuplicateMenuLoading}>
            <CloseIcon />
          </IconButton>
        )}
      </Stack>
    </Stack>
  );
};

export default DailyMenuActionsCard;
