import { ProcessingStage } from '@calo/dashboard-types';
import LoadingButton from '@mui/lab/LoadingButton';
import { Button, Stack, Typography, Box, Card, IconButton } from '@mui/material-v6';
import {
  addPrototypeComponentAction,
  approveStage1PrototypeComponent,
  approveStage2PrototypeComponent,
  deleteFoodComponent
} from 'actions';
import DeleteConfirmationPopup from 'components/DeleteConfirmationPopup';
import RouteBreadCrumb from 'components/RouteBreadCrumb';
import { ComponentTabs, FormOperation, Permission, PrototypeAction, Routes } from 'lib/enums';
import { resolveCountry } from 'lib/helpers';
import { getPrototypeActionApprovalButtonText } from 'lib/helpers/getPrototypeActionApprovalButtonText';
import { determineComponentOrIngredientAction } from 'lib/helpers/playgroundUtils';
import { useUserRoles } from 'lib/hooks';
import { BreadcrumbItem, FoodComponent, PrototypeFoodComponent } from 'lib/interfaces';
import { RefObject, useEffect, useRef, useState } from 'react';
import { useMutation } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';
import { ApprovalActionMenu } from 'views/ChefPlayground/Shared/ApprovalActionMenu/ApprovalActionMenu';
import { ChangeRequestDialog } from 'views/ChefPlayground/Shared/ChangeRequestDialog/ChangeRequestDialog';
import { MealApprovalDialog } from 'views/ChefPlayground/Shared/MealApprovalDialog/MealApprovalDialog';
import ComponentNameAndUsage from './ComponentNameAndUsage';
import { styles } from './styles';
import TabsComponent from 'views/ChefPlayground/FoodPlayground/ExactFood/FoodForm/StickyHeader/TabsComponent';
import ActionMenu from './ActionMenu';
import DeleteIcon from '@mui/icons-material/Delete';

const generateBreadcrumbItems = (pathname: string, foodComponentName?: string): BreadcrumbItem[] => {
  const segments = pathname.split('/').filter(Boolean); // Removes empty segments

  return segments[0] === 'food-components'
    ? [
        { text: 'Food' },
        { text: 'Components', url: Routes.foodComponentList },
        { text: segments[segments.length - 1] === 'new' ? 'Create Component' : foodComponentName || '' }
      ]
    : segments[0] === 'chef-playground'
      ? [
          { text: "Chef's Playground" },
          { text: 'Components', url: Routes.playgroundComponentList },
          { text: segments[segments.length - 1] === 'new' ? 'New Component' : foodComponentName || '' }
        ]
      : [];
};

type ComponentHeaderCardProps = {
  isValid: boolean;
  isSubmitting: boolean;
  isEdit: boolean;
  setShowSyncComponentPopup?: (showSyncComponentPopup: boolean) => void;
  handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
  isDisabled?: boolean;
  operation: FormOperation;
  foodComponent?: PrototypeFoodComponent;
  filteredFoodUsed?: any[];
  componentFoodList?: any[];
  isFoodLoading?: boolean;
  refetch?: () => void;
  sectionRefs?: RefObject<HTMLDivElement>[];
  dirty?: boolean;
};

const ComponentHeaderCard = ({
  isValid,
  isSubmitting,
  isEdit,
  handleSubmit,
  isDisabled,
  operation,
  foodComponent,
  setShowSyncComponentPopup,
  filteredFoodUsed = [],
  componentFoodList = [],
  isFoodLoading = false,
  refetch,
  sectionRefs,
  dirty
}: ComponentHeaderCardProps) => {
  const userRoles = useUserRoles();
  const history = useHistory();
  const location = useLocation();
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false);

  const [mealApprovalDialogOpen, setMealApprovalDialogOpen] = useState(false);
  const [changeRequestDialogOpen, setChangeRequestDialogOpen] = useState(false);

  const isChefPlayground = location.pathname.includes('chef-playground');
  const isNewComponentPage = location.pathname.includes('/new');

  const isNewlyCreated = foodComponent?.stage === ProcessingStage.draft;
  const isPendingFirstStageApproval = foodComponent?.stage === ProcessingStage.awaitingNPDApproval;
  const isPendingSecondStageApproval = foodComponent?.stage === ProcessingStage.awaitingOpsApproval;
  const canApproveStage1 = isPendingFirstStageApproval && userRoles.includes(Permission.APPROVE_STAGE_1_PROTOTYPE_FOOD_COMPONENT);
  const canApproveStage2 =
    isPendingSecondStageApproval && userRoles.includes(Permission.APPROVE_STAGE_2_PROTOTYPE_FOOD_COMPONENT);

  const shouldShowApprovalActionMenu = canApproveStage1 || canApproveStage2;
  const breadcrumbItems = generateBreadcrumbItems(location.pathname, foodComponent?.name?.en);

  const [tab, setTab] = useState(0);
  const isTabClickRef = useRef(false);

  const { mutateAsync: deleteMutation, isLoading: deleteLoading } = useMutation(deleteFoodComponent);

  const {
    mutate: createAction,
    mutateAsync: createActionAsync,
    isLoading: actionLoading
  } = useMutation(addPrototypeComponentAction, {
    onSuccess: () => {
      refetch?.();
    }
  });

  const { mutate: approveComponentStage1, isLoading: approveStage1Loading } = useMutation(approveStage1PrototypeComponent, {
    onSuccess: () => {
      toggleMealApprovalDialog();
      refetch?.();
    }
  });

  const { mutate: approveComponentStage2, isLoading: approveStage2Loading } = useMutation(approveStage2PrototypeComponent, {
    onSuccess: () => {
      toggleMealApprovalDialog();
      refetch?.();
    }
  });

  const handleDeleteComponent = async () => {
    if (operation === FormOperation.update && foodComponent) {
      await deleteMutation({ id: foodComponent.id });
      history.push(Routes.foodComponentList);
    }
  };

  const handleClosePopup = () => {
    setIsDeleteConfirmationOpen(false);
  };

  const toggleMealApprovalDialog = () => {
    setMealApprovalDialogOpen((prev) => !prev);
  };

  const toggleChangeRequestDialog = () => {
    setChangeRequestDialogOpen((prev) => !prev);
  };

  const handleFoodComponentApprovalSubmit = () => {
    if (foodComponent && foodComponent.stage) {
      if (foodComponent.stage === ProcessingStage.awaitingNPDApproval) {
        approveComponentStage1(foodComponent.id);
      } else if (foodComponent.stage === ProcessingStage.awaitingOpsApproval) {
        approveComponentStage2(foodComponent.id);
      } else {
        const action = determineComponentOrIngredientAction(foodComponent.stage);

        createAction(
          {
            action,
            id: foodComponent.id
          },
          {
            onSuccess: () => {
              toggleMealApprovalDialog();
              refetch?.();
            }
          }
        );
      }
    }
  };

  const handleChangeRequestSubmit = async (comment: string) => {
    if (foodComponent && comment) {
      await createActionAsync(
        {
          action: PrototypeAction.requestChanges,
          message: comment,
          id: foodComponent.id
        },
        {
          onSuccess: () => {
            toggleChangeRequestDialog();
            refetch?.();
          }
        }
      );
    }
  };

  const renderPrototypeActions = () => (
    <>
      {isNewlyCreated && (
        <Button
          variant="outlined"
          disabled={!foodComponent || actionLoading || !userRoles.includes(Permission.CREATE_ACTION_PROTOTYPE_COMPONENT)}
          sx={styles.buttonOutlined}
          onClick={toggleMealApprovalDialog}
        >
          Send for 1st approval
        </Button>
      )}
      <IconButton
        disabled={isDisabled || isFoodLoading}
        sx={styles.deleteButtonStyles}
        onClick={() => setIsDeleteConfirmationOpen(true)}
      >
        <DeleteIcon sx={{ color: 'red' }} />
      </IconButton>
      {shouldShowApprovalActionMenu && (
        <ApprovalActionMenu
          type="component"
          buttonVariant={'outlined'}
          buttonSx={styles.buttonOutlined}
          item={foodComponent}
          actionButtonText={getPrototypeActionApprovalButtonText(foodComponent.stage)}
          handleApprove={toggleMealApprovalDialog}
          commentClickHandler={toggleChangeRequestDialog}
        />
      )}
    </>
  );

  const getPrototypeItems = () => {
    const ingredients =
      foodComponent?.ingredients
        ?.filter((fc) => fc.prototype)
        .map((ing) => ({ id: ing.id, name: ing.name.en, type: 'ingredient' })) || [];
    const childComponents =
      foodComponent?.childComponents
        ?.filter((fc) => fc.prototype)
        .map((cc) => ({ id: cc.id, name: cc.name.en, type: 'component' })) || [];
    return [...ingredients, ...childComponents];
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    if (sectionRefs) {
      setTab(newValue);
      isTabClickRef.current = true;
      sectionRefs[newValue].current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  useEffect(() => {
    if (!sectionRefs) {
      return;
    }
    const observer = new IntersectionObserver(
      (entries) => {
        if (isTabClickRef.current) {
          isTabClickRef.current = false;
          return;
        }
        const visibleSection = entries.reduce(
          (max, entry) => (entry.intersectionRatio > max.intersectionRatio ? entry : max),
          entries[0]
        );
        const index = sectionRefs.findIndex((ref) => ref.current === visibleSection.target);
        if (index !== -1) {
          setTab(index);
        }
      },
      {
        rootMargin: '-50% 0px -50% 0px'
      }
    );
    for (const ref of sectionRefs) {
      if (ref.current) {
        observer.observe(ref.current);
      }
    }

    return () => {
      observer.disconnect();
    };
  }, [sectionRefs]);

  const getTabLabels = () => {
    return isNewComponentPage
      ? Object.values(ComponentTabs).map((componentTab) => componentTab)
      : Object.values(ComponentTabs).filter((componentTab) => componentTab !== ComponentTabs.region);
  };

  const getDisabledState = () => {
    if (operation === FormOperation.update && !isChefPlayground) {
      if (dirty && isValid && !isEdit) {
        return false;
      }
      return true;
    } else {
      return isDisabled || !isValid || isSubmitting || isEdit;
    }
  };

  return (
    <>
      <Card sx={isChefPlayground ? styles.normalHeader : styles.stickyHeader}>
        <Stack
          sx={{
            ...styles.headerStackStyles,
            padding: isChefPlayground ? 0 : '0px 32px'
          }}
        >
          <RouteBreadCrumb items={breadcrumbItems} />
          {operation === FormOperation.update && (
            <Typography variant="h5" sx={styles.headerTextStyles}>
              {resolveCountry(foodComponent?.country)} / {foodComponent?.kitchen}
            </Typography>
          )}
        </Stack>
        <Stack sx={{ ...styles.mainStackStyles, padding: isChefPlayground ? 0 : '0px 32px' }}>
          {operation === FormOperation.update && (
            <ComponentNameAndUsage
              foodComponent={foodComponent as FoodComponent | undefined}
              filteredFoodUsed={filteredFoodUsed}
              isDisabled={!!isDisabled}
            />
          )}
          {operation === FormOperation.create && <Typography sx={styles.createHeaderStyles}>New Component</Typography>}
          <Stack sx={styles.actionButtonStackStyles}>
            {operation === FormOperation.update && !isChefPlayground && (
              <ActionMenu
                handleUpdate={() => setShowSyncComponentPopup?.(true)}
                handleDelete={() => setIsDeleteConfirmationOpen(true)}
                isDeleteDisabled={isDisabled || isFoodLoading}
                isUpdateDisabled={isDisabled ?? false}
              />
            )}
            {operation === FormOperation.update && isChefPlayground && renderPrototypeActions()}
            <LoadingButton
              loading={isSubmitting}
              data-test="addComponentButton"
              variant="contained"
              color="primary"
              size="large"
              sx={styles.saveButtonStyles}
              disabled={getDisabledState()}
              onClick={() => handleSubmit()}
            >
              {operation === FormOperation.create ? 'create' : 'Save'}
            </LoadingButton>
          </Stack>
        </Stack>
        {!isChefPlayground && (
          <Box mt={'24px'}>
            <TabsComponent tab={tab} handleTabChange={handleTabChange} tabLabels={getTabLabels()} />
          </Box>
        )}
      </Card>
      {operation === FormOperation.update && (
        <DeleteConfirmationPopup
          type="component"
          isDeleteConfirmationOpen={isDeleteConfirmationOpen}
          typeUsage={
            componentFoodList.map((meal) => ({
              name: meal.name.en,
              id: meal.id
            })) || []
          }
          isLoading={isFoodLoading}
          onClose={handleClosePopup}
          onDelete={handleDeleteComponent}
          isActionButtonLoading={deleteLoading}
        />
      )}
      <MealApprovalDialog
        open={mealApprovalDialogOpen}
        handleClose={toggleMealApprovalDialog}
        handleSubmit={handleFoodComponentApprovalSubmit}
        isPositiveButtonLoading={actionLoading || approveStage1Loading || approveStage2Loading}
        prototypeItems={getPrototypeItems()}
        status={foodComponent?.stage}
        type="component"
      />
      <ChangeRequestDialog
        open={changeRequestDialogOpen}
        title="Add comments for changes"
        handleClose={toggleChangeRequestDialog}
        handleSubmit={handleChangeRequestSubmit}
        isPositiveButtonLoading={actionLoading}
      />
    </>
  );
};

export default ComponentHeaderCard;
