import InputThemeProvider from 'components/MUI/InputThemeProvider';
import { FormikErrors } from 'formik';
import { getAccessibleCountries, selectCountry } from 'lib/helpers';
import { getKitchenOptions } from 'lib/helpers/kitchenUtils';
import { useUserKitchens } from 'lib/hooks';
import { BaseOmit, FoodComponent } from 'lib/interfaces';

import { Country, Kitchen } from '@calo/types';
import { Card, Divider, Stack, Typography, Box } from '@mui/material-v6';
import { styles } from './styles';
import SelectMUI2 from 'components/MUI/SelectMUI2';
import { forwardRef } from 'react';

interface ComponentRegionCardProps {
  values: Omit<FoodComponent, BaseOmit>;
  errors: FormikErrors<Omit<FoodComponent, BaseOmit>>;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<FormikErrors<Omit<FoodComponent, BaseOmit>>> | Promise<any>;
}

const ComponentRegionCard = forwardRef<HTMLDivElement, ComponentRegionCardProps>(
  ({ values, errors, setFieldValue }: ComponentRegionCardProps, ref) => {
    const userKitchens: Kitchen[] = useUserKitchens();

    return (
      <Card variant="outlined" sx={styles.card} ref={ref}>
        <Typography sx={styles.title}>Region</Typography>
        <Divider sx={styles.divider} />
        <InputThemeProvider>
          <Stack sx={styles.inputContainer}>
            <Stack direction={'row'} justifyContent={'space-between'} gap={'24px'}>
              <Box flexGrow={1}>
                <SelectMUI2
                  data-test="foodComponentCountrySelect"
                  label="Country"
                  value={values.country}
                  onChange={(value) =>
                    selectCountry({
                      value: value as Country,
                      kitchens: userKitchens,
                      setFieldValue
                    })
                  }
                  options={getAccessibleCountries(userKitchens).map((type) => ({
                    value: type,
                    label: type
                  }))}
                />
              </Box>
              <Box flexGrow={1}>
                <SelectMUI2
                  data-test="foodComponentKitchenSelect"
                  label="Kitchen"
                  value={values.kitchen}
                  onChange={(value) => setFieldValue('kitchen', value as string)}
                  options={getKitchenOptions(userKitchens, values.country).filter((option) => option.label !== 'Any')}
                  error={!!errors.kitchen}
                />
              </Box>
            </Stack>
          </Stack>
        </InputThemeProvider>
      </Card>
    );
  }
);

export default ComponentRegionCard;
