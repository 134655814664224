import { Box, Typography } from '@mui/material';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import { Pagination as MUIPagination, PaginationItem } from '@mui/material-v6';

interface PaginationProps {
  total: number;
  limit: number;
  page: number;
  isHidden?: boolean;
  onChange: (page: number) => void;
  notification?: boolean;
}

const Pagination = ({ total, limit, page, onChange, isHidden, notification }: PaginationProps) => {
  const totalPages = Math.ceil(total / limit) || 1;

  return (
    <Box
      sx={{
        display: isHidden ? 'none' : 'block',
        zIndex: 10,
        padding: '8px',
        backgroundColor: notification ? 'transparent' : 'background.paper',
        borderRadius: '4px'
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: notification ? 'center' : 'space-between', alignItems: 'center' }}>
        <MUIPagination
          renderItem={(item) => (
            <PaginationItem
              slots={{
                first: SkipPreviousIcon,
                last: SkipNextIcon
              }}
              {...item}
            />
          )}
          page={page + 1}
          count={totalPages}
          showFirstButton
          showLastButton
          onChange={(e, page) => onChange(page - 1)}
        />
        {!notification && (
          <Box>
            <Typography variant="body2" color="text.secondary">
              Total: {total}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Pagination;
