import Disclaimer from 'components/Disclaimer';
import CostInformationCard from 'components/MealCostInformationCard/CostInformationCard';
import { ModalRef } from 'components/Modal';
import {
  Components,
  Information,
  Ingredients,
  MealInformation,
  NutritionalInformation,
  PackagingInformation,
  PortioningNotes
} from 'components/Sections';
import { useUserRoles } from 'lib/hooks';
import { BaseOmit, Food, FoodComponent, PrototypeFood } from 'lib/interfaces';
import { keyBy, round, sumBy, uniqBy } from 'lodash-es';
import { RefObject, useEffect, useMemo, useRef, useState } from 'react';
import ActivityLogCard from 'views/ChefPlayground/Shared/ActivityLogCard';
import FoodCommentsPopup from 'views/ChefPlayground/Shared/FoodCommentsPopup';

import { MenuFoodComponent, Permission, UpdateFoodReq } from '@calo/dashboard-types';
import { Dictionary } from '@calo/types';
import { Stack } from '@mui/material';

import { FoodStatus } from 'lib/enums';
import { findAutoCalculatedMacros, findAutoCalculatedMicros } from 'views/Food/helpers';
import { displayMessageForMacrosLimits } from '../helpers';
import SizeSelector from './SizeSelector';
import StickyHeader from './StickyHeader';
import useFoodForm from './useFoodForm';

interface FoodFormProps {
  refetch: any;
  food: Food;
  name: string;
  sizeOptions: string[];
  selectedFood: PrototypeFood;
  allSizesFood: Food[];
  filteredFoodUsed: any;
  foodWithAllSizes: Food[];
  usedOnMenuButtonColor: string[];
  foodComponentsFromSearch: FoodComponent[];
  setName: (value: string) => void;
  setSelectedFood: React.Dispatch<React.SetStateAction<PrototypeFood | undefined>>;
  setFoodWithAllSizes: (food: Food[]) => void;
  onSubmit: (value: Omit<UpdateFoodReq, BaseOmit>) => Promise<void>;
}

const FoodForm = ({
  onSubmit,
  food,
  refetch,
  foodComponentsFromSearch,
  setName,
  name,
  allSizesFood,
  filteredFoodUsed,
  selectedFood,
  foodWithAllSizes,
  usedOnMenuButtonColor,
  setSelectedFood,
  setFoodWithAllSizes,
  sizeOptions
}: FoodFormProps) => {
  const roles = useUserRoles();
  const isUpdateDisabled = (food.prototype && food.status !== FoodStatus.draft) || false;

  const isDisabled = !roles.includes(Permission.UPDATE_PROTOTYPE_FOOD) || isUpdateDisabled;
  const commentsPopupRef = useRef<ModalRef>();

  const [packageCost, setPackageCost] = useState(0);

  const {
    handleSubmit,
    values,
    handleChange,
    handleBlur,
    isSubmitting,
    isValid,
    dirty,
    setFieldValue,
    setValues,
    resetForm,
    errors
  } = useFoodForm(food, onSubmit);

  const keyedComponents = keyBy(values.components, 'id') as any as Dictionary<FoodComponent>;
  const childComponents = (selectedFood.components ?? []).flatMap(
    (comp: any) => comp.childComponents ?? []
  ) as any as FoodComponent[];
  const keyedChildComponents = useMemo(() => keyBy(childComponents, 'id'), [childComponents]);

  const sectionRefs: RefObject<HTMLDivElement>[] = [
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null)
  ];

  useEffect(() => resetForm, [food]);

  useEffect(() => {
    const ingredients = (values.components ?? []).flatMap((comp) => comp.ingredients) ?? [];
    const childIngredients = (values.components ?? []).flatMap((comp) =>
      (comp.childComponents ?? []).flatMap((childComp: any) => childComp.ingredients)
    );
    const allIngredients = [...ingredients, ...childIngredients];
    setFieldValue('ingredients', uniqBy(allIngredients, 'id'));
  }, [values.components]);

  const handleComponentsChange = async (componentRows: MenuFoodComponent[]) => {
    const newComponentRows = componentRows.map((r) => (isNaN(r.quantity) ? { ...r, id: r.id, quantity: 0 } : r));

    setValues({
      ...values,
      components: newComponentRows,
      macros: values.withManualMacros ? values.macros : findAutoCalculatedMacros(newComponentRows),
      micronutrients: values.withManualMacros ? values.micronutrients : findAutoCalculatedMicros(newComponentRows)
    });
  };

  const getTotalCost = () => {
    const componentCosts = values.components?.map(
      (r) => ((values.components ?? []).find((k) => k.id === r.id)?.cost || 0) * r.quantity
    );
    const cC = round(
      sumBy(componentCosts, (fk) => fk || 0),
      3
    );
    return (packageCost + cC).toFixed(3);
  };

  const handleAutoCalculate = (value: boolean) => {
    if (value) {
      setValues({
        ...values,
        withManualMacros: value
      });
    } else {
      setValues({
        ...values,
        macros: findAutoCalculatedMacros(values.components ?? []),
        micronutrients: findAutoCalculatedMicros(values.components ?? []),
        withManualMacros: value
      });
    }
  };

  const closeCommentsPopup = () => {
    commentsPopupRef.current?.close();
  };

  const addCommentSuccessHandler = (data: Food) => {
    setSelectedFood((prev) => ({ ...prev, prototypeActions: data.prototypeActions }) as Food);
    refetch();
  };

  const handleSizeChange = (newSize: string) => {
    const newSelectedFood = allSizesFood.find((food) => food.size === newSize);
    if (newSelectedFood) {
      setSelectedFood(newSelectedFood);
    }
  };

  return (
    <Stack direction="column" justifyContent="space-between" alignItems="stretch" spacing={2}>
      <StickyHeader
        refetch={refetch}
        dirty={dirty}
        values={values}
        isValid={isValid}
        isDisabled={isDisabled}
        sectionRefs={sectionRefs}
        isSubmitting={isSubmitting}
        handleSubmit={handleSubmit}
        selectedFood={selectedFood}
        setSelectedFood={setSelectedFood}
        filteredFoodUsed={filteredFoodUsed}
        foodWithAllSizes={foodWithAllSizes}
        setFoodWithAllSizes={setFoodWithAllSizes}
        usedOnMenuButtonColor={usedOnMenuButtonColor}
      />
      <SizeSelector sizeOptions={sizeOptions} selectedSize={selectedFood?.size} onSizeChange={handleSizeChange} />
      <Disclaimer type="meal" sx={{ top: '210px' }} />
      <MealInformation
        values={values}
        errors={errors}
        food={food}
        isDisabled={isDisabled}
        roles={roles}
        showOwner={true}
        setFieldValue={setFieldValue}
        ref={sectionRefs[0]}
      />
      <Components
        values={values}
        isDisabled={isDisabled}
        foodComponents={foodComponentsFromSearch}
        isLoading={false}
        setFieldValue={setFieldValue}
        handleComponentsChange={handleComponentsChange}
        ref={sectionRefs[1]}
        setName={setName}
        name={name}
      />
      <NutritionalInformation
        values={values}
        setFieldValue={setFieldValue}
        errors={errors}
        handleBlur={handleBlur}
        handleChange={handleChange}
        microNutrientsAuto={values.withManualMacros || false}
        handleAutoCalculate={handleAutoCalculate}
        displayMessageForMacrosLimits={(macrosProp: 'carbs' | 'cal' | 'protein' | 'fat') =>
          values.macros ? displayMessageForMacrosLimits(allSizesFood, food, values.macros, macrosProp) : ''
        }
        ref={sectionRefs[2]}
      />
      <Information
        values={values}
        setFieldValue={setFieldValue}
        errors={errors}
        food={food}
        isDisabled={isDisabled}
        allSizesFood={allSizesFood}
      />
      <Ingredients
        ingredients={values.ingredients ?? []}
        isLoading={false}
        ref={sectionRefs[3]}
        components={(values.components as any) ?? []}
      />
      <CostInformationCard
        values={values}
        setFieldValue={setFieldValue}
        getTotalCost={getTotalCost}
        packagingCost={packageCost}
        allComponents={keyedComponents}
        foodWithAllSizes={foodWithAllSizes}
        childComponent={keyedChildComponents}
        errors={errors}
      />
      <PortioningNotes values={values} errors={errors} handleChange={handleChange} isDisabled={isDisabled} ref={sectionRefs[5]} />
      <PackagingInformation
        values={values}
        setFieldValue={setFieldValue}
        setPackageCost={(r) => setPackageCost(r)}
        food={food}
        isDisabled={isDisabled}
        ref={sectionRefs[4]}
        roles={roles}
      />
      <ActivityLogCard
        actionLogs={food.prototypeActions ?? []}
        type="meal"
        addCommentClickHandler={() => commentsPopupRef.current?.open()}
      />
      <FoodCommentsPopup
        selectedFood={food}
        ref={commentsPopupRef}
        successHandler={addCommentSuccessHandler}
        closeCommentsPopup={closeCommentsPopup}
      />
    </Stack>
  );
};

export default FoodForm;
