import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Button, MenuItem } from '@mui/material-v6';
import Menu, { MenuProps } from '@mui/material-v6/Menu';
import { alpha, styled } from '@mui/material-v6/styles';
import { caloThemeV2 } from 'assets/themev2';
import { MouseEvent, useState } from 'react';

interface AddMealsButtonProps {
  disabled?: boolean;
  addMealsByFiltersHandler: () => void;
  duplicateMenuHandler: () => void;
}

const AddMealsButton = ({ disabled, addMealsByFiltersHandler, duplicateMenuHandler }: AddMealsButtonProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="outlined"
        disableElevation
        disabled={disabled}
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        sx={{
          color: caloThemeV2.palette.text.primary,
          borderColor: caloThemeV2.palette.text.primary,
          height: '42px',
          width: '156px',
          fontSize: '15px',
          fontWeight: 500
        }}
      >
        Add Meals
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button'
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            addMealsByFiltersHandler();
            handleClose();
          }}
          disableRipple
        >
          Select from meals
        </MenuItem>
        <MenuItem
          onClick={() => {
            duplicateMenuHandler();
            handleClose();
          }}
          disableRipple
        >
          Duplicate menu
        </MenuItem>
      </StyledMenu>
    </>
  );
};

export default AddMealsButton;

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right'
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: 'rgb(55, 65, 81)',
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0'
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5)
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity)
      }
    },
    ...theme.applyStyles('dark', {
      color: theme.palette.grey[300]
    })
  }
}));
