import DateFnsAdapter from '@date-io/date-fns';
import { TextField, TextFieldProps } from '@mui/material';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker as DP } from '@mui/x-date-pickers/DatePicker';

interface DatePickerProps {
  date?: Date | null;
  label: string;
  disabled?: boolean;
  onChange?: (date: Date | null | undefined, keyboardInputValue?: string | undefined) => void;
}

const DatePicker = ({ label, date, onChange, disabled }: DatePickerProps) => {
  return (
    <LocalizationProvider dateAdapter={DateFnsAdapter} sx={{ width: '100%' }}>
      <DP
        value={date}
        label={label}
        disabled={disabled}
        onChange={onChange ?? function (_) {}}
        renderInput={function (props: TextFieldProps): React.ReactElement {
          return (
            <TextField
              {...props}
              style={{
                width: '100%',
                borderRadius: 12
              }}
            />
          );
        }}
      />
    </LocalizationProvider>
  );
};

export default DatePicker;
