import ComponentCostInfo from 'components/ComponentCostInfo';
import InputThemeProvider from 'components/MUI/InputThemeProvider';
import { FormikErrors } from 'formik';
import { getWeightedAverage, handleMealSellingPrice } from 'lib/helpers';
import { BaseOmit, Food, FoodComponent, UpdateFoodReqWithIndex } from 'lib/interfaces';
import { round } from 'lodash-es';
import { memo, useEffect, useMemo, useState } from 'react';
import {
  calculateWAC,
  getComponentsTotalCost,
  getMealTotalCost,
  MealCostInfo,
  showMealCostInfoHandler
} from 'views/Food/helpers';

import { UpdateFoodReq } from '@calo/dashboard-types';
import { Dictionary } from '@calo/types';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Card, Divider, IconButton, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import { caloTheme } from 'assets/images/theme/calo';

interface CostInformationProps {
  values: Omit<UpdateFoodReq, BaseOmit>;
  setFieldValue: (field: string, value: any) => void;
  getTotalCost: () => string;
  packagingCost: number;
  foodWithAllSizes?: Food[];
  allComponents: Dictionary<FoodComponent>;
  childComponent: Dictionary<FoodComponent>;
  errors: FormikErrors<UpdateFoodReqWithIndex>;
}

const CostInformationCard = memo(
  ({
    values,
    getTotalCost,
    packagingCost,
    childComponent,
    allComponents,
    setFieldValue,
    foodWithAllSizes = []
  }: CostInformationProps) => {
    const [showCostInfo, setShowCostInfo] = useState(false);
    const [costType, setCostType] = useState(MealCostInfo.NONE);
    const [mealPercentages, setMealPercentages] = useState({ value: '0', text: '' });

    useEffect(() => {
      infoPercentageInfo();
    }, [values]);

    const infoPercentageInfo = () => {
      let result = 'This cost is a percentage of how much of our food cost accounts for the selling price.,';
      const filteredFoodSizes = foodWithAllSizes.filter((food) => ['S', 'M', 'L'].includes(food.size));
      const modifiedFoodSizes = filteredFoodSizes.map((food) => {
        const modifiedComponents = (food.components ?? []).map((component) => {
          const fullComponentData = allComponents[component.id];
          return {
            ...fullComponentData,
            quantity: component.quantity
          };
        });
        return {
          ...food,
          components: modifiedComponents
        };
      });

      if (modifiedFoodSizes.length === 3 && modifiedFoodSizes.some((meal) => meal.size === values.size)) {
        result += calculateWAC(
          modifiedFoodSizes,
          handleMealSellingPrice,
          values,
          allComponents,
          childComponent,
          setMealPercentages,
          getWeightedAverage
        );
      } else if (modifiedFoodSizes.length > 0 && modifiedFoodSizes.some((meal) => meal.size === values.size)) {
        result += calculateWAC(
          modifiedFoodSizes,
          handleMealSellingPrice,
          values,
          allComponents,
          childComponent,
          setMealPercentages
        );
      } else {
        result += calculateWAC(
          modifiedFoodSizes,
          handleMealSellingPrice,
          values,
          allComponents,
          childComponent,
          setMealPercentages
        );
      }
      return result;
    };

    const openCostInfoHandler = (costInfo: MealCostInfo) => {
      setShowCostInfo(true);
      setCostType(costInfo);
    };

    const handlePositiveNumbers = (field: string, value: number) => {
      if (value >= 0) {
        setFieldValue(field, value);
      }
    };

    const response =
      costType === MealCostInfo.PERCENTAGE
        ? mealPercentages.text
        : showMealCostInfoHandler(costType, values, allComponents, childComponent, packagingCost);

    const costInfoText = () => {
      if (!values) return;
      return typeof response === 'object' ? response.info : response;
    };
    const price = useMemo(() => {
      return ['S', 'M', 'L'].includes(values.size!)
        ? round(handleMealSellingPrice(values.kitchen!, values.type, values.tags ?? [], values.type?.[0]!), 6)
        : (values.price ?? 0);
    }, [values.size, values.kitchen, values.type, values.tags, values.price]);

    useEffect(() => {
      if (!values.b2bPrice) {
        setFieldValue('b2bPrice', price * 0.75);
      }
    }, [values]);

    return (
      <Card
        variant="outlined"
        sx={{
          marginTop: '16px',
          border: 'none',
          borderRadius: '8px',
          boxShadow: 2
        }}
      >
        <Typography sx={{ fontSize: '24px', fontWeight: 400, lineHeight: '150%', padding: '16px' }}>Cost Information</Typography>
        <Divider sx={{ color: caloTheme.palette.divider, height: '1px', width: '100%' }} />
        <InputThemeProvider>
          <Stack
            sx={{
              display: 'flex',
              flexDirection: 'column',
              padding: '24px 16px 16px 16px'
            }}
          >
            <Stack sx={{ display: 'flex', flexDirection: 'column', gap: '2px' }}>
              <Stack
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  gap: 2
                }}
              >
                <TextField
                  label="Currency"
                  value={values.currency}
                  name="cost"
                  type="text"
                  sx={{ my: 1, zIndex: 0, width: '100%' }}
                  disabled
                />
                <Stack sx={{ width: '100%' }}>
                  <TextField
                    label="Selling Price"
                    value={
                      ['S', 'M', 'L'].includes(values.size!)
                        ? round(handleMealSellingPrice(values.kitchen!, values.type, values.tags ?? [], values.type?.[0]!), 6)
                        : values.price
                    }
                    name="price"
                    type="number"
                    disabled={['S', 'M', 'L'].includes(values.size!)}
                    sx={{ my: 1, width: '100%' }}
                    onChange={(price) => handlePositiveNumbers('price', +price.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={() => openCostInfoHandler(MealCostInfo.SELLINGPRICE)}>
                            <InfoOutlinedIcon fontSize="small" />
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </Stack>
                <Stack sx={{ width: '100%' }}>
                  <TextField
                    label="B2B Price"
                    value={values.b2bPrice ?? price * 0.75}
                    name="b2bPrice"
                    type="number"
                    sx={{ my: 1, width: '100%' }}
                    onChange={(price) => handlePositiveNumbers('b2bPrice', +price.target.value)}
                  />
                </Stack>
                <TextField
                  label="Total Meal Cost percentage (Precoro)"
                  value={mealPercentages.value}
                  name="totalMealCostPercentagePrecoro"
                  disabled
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => openCostInfoHandler(MealCostInfo.PERCENTAGE)}>
                          <InfoOutlinedIcon fontSize="small" />
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                  sx={{ my: 1, width: '100%' }}
                />
                <TextField
                  label="Total Meal Cost (Precoro)"
                  value={round(getMealTotalCost(values, allComponents, childComponent, packagingCost), 6)}
                  name="totalMealCostPrecoro"
                  type="number"
                  disabled
                  sx={{ my: 1, width: '100%' }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => openCostInfoHandler(MealCostInfo.PRECORO)}>
                          <InfoOutlinedIcon fontSize="small" />
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
                <TextField
                  label="Total Meal Cost (Recipe Sheet)"
                  value={getTotalCost()}
                  name="totalMealCostRecipeSheet"
                  disabled
                  sx={{ my: 1, width: '100%' }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => openCostInfoHandler(MealCostInfo.RECIPE)}>
                          <InfoOutlinedIcon fontSize="small" />
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
                <TextField
                  label="Total Component Cost (Precoro)"
                  value={round(getComponentsTotalCost(values, allComponents, childComponent), 6)}
                  name="totalComponentCostPrecoro"
                  disabled
                  sx={{ my: 1, width: '100%' }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => openCostInfoHandler(MealCostInfo.COMPONENT)}>
                          <InfoOutlinedIcon fontSize="small" />
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              </Stack>
              {showCostInfo && (
                <ComponentCostInfo
                  costInfo={costInfoText() ?? ''}
                  marginTop="8px"
                  marginBottom="0px"
                  handleClose={() => {
                    setShowCostInfo(false);
                  }}
                />
              )}
            </Stack>
          </Stack>
        </InputThemeProvider>
      </Card>
    );
  }
);
export default CostInformationCard;
