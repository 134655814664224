import { caloThemeV2 } from 'assets/themev2';

export const styles = {
  card: {
    width: 'full',
    border: 'none',
    borderRadius: '8px',
    boxShadow: 2,
    [caloThemeV2.breakpoints.down(caloThemeV2.breakpoints.values.lg)]: {
      flexDirection: 'column'
    }
  },
  title: { fontSize: '24px', fontWeight: 400, lineHeight: '150%', padding: '16px' },
  divider: { color: caloThemeV2.palette.divider, height: '1px', width: '100%' },
  inputContainer: {
    margin: '24px 16px',
    gap: '24px'
  }
};
