import { FormikErrors } from 'formik';

import { IngredientCategory } from '@calo/types';
import { Box, Card, Divider, Typography } from '@mui/material';

import SelectMUI from 'components/MUI/SelectMUI';
import { foodInformationFormMultiSelectCustomStyles } from 'lib/componentStyles';
import { IngredientFormData } from 'lib/interfaces';
import { caloTheme } from 'assets/images/theme/calo';

interface AllergenCategoryCardProps {
  values: IngredientFormData;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<FormikErrors<IngredientFormData>> | Promise<void>;
  isDisabled?: boolean;
}

const AllergenCategoryCard = ({ values, setFieldValue, isDisabled }: AllergenCategoryCardProps) => {
  return (
    <Card
      variant="outlined"
      sx={{
        marginTop: '16px',
        border: 'none',
        borderRadius: '8px',
        boxShadow: 2
      }}
    >
      <Typography variant="h5" sx={{ fontSize: '24px', fontWeight: 400, lineHeight: '150%', padding: '16px' }}>
        Allergen Category
      </Typography>
      <Divider sx={{ color: caloTheme.palette.divider, height: '1px', width: '100%' }} />
      <Box sx={{ padding: '24px 16px 16px 16px' }}>
        <SelectMUI
          isDisabled={isDisabled}
          label="Category"
          value={values.category}
          isMulti
          onChange={(data: any) =>
            setFieldValue(
              'category',
              (data as any[]).map((row) => row.value)
            )
          }
          options={Object.values(IngredientCategory).map((k) => ({
            value: k,
            label: IngredientCategory[k]
          }))}
          customStyles={foodInformationFormMultiSelectCustomStyles()}
        />
      </Box>
    </Card>
  );
};

export default AllergenCategoryCard;
