import { useMemo, useState } from 'react';

import { getListWithParams, toggleUISettings } from 'actions';
import { Input, Select, Select2, SideDrawer } from 'components';
import { getAccessibleCountries, getKitchenOptions, handleSearch } from 'lib/helpers';
import { useUserKitchens } from 'lib/hooks';
import { AppState, Ingredient, Options } from 'lib/interfaces';
import { capitalize, isNull, omit, sortBy } from 'lodash';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';

import { FoodComponentFilters, FoodComponentStatus, ProcessingStage } from '@calo/dashboard-types';
import { Brand, Country, CustomMealCategory, Kitchen } from '@calo/types';
import { FoodStatusFilterLabels } from 'lib/constants';

interface SettingsProps {
  onFilter: (values: FoodComponentFilters) => any;
  filters: FoodComponentFilters;
}

const Settings = ({ filters, onFilter }: SettingsProps) => {
  const { settingsVisible } = useSelector((state: AppState) => ({
    settingsVisible: state.ui.settings
  }));

  const userKitchens: string[] = useUserKitchens();
  const dispatch = useDispatch();
  const [allIngredients, setAllIngredients] = useState<Ingredient[]>([]);
  const [filterIngredients, setFilterIngredients] = useState<string>();

  const { isLoading: ingLoading } = useQuery<any, Error, { data: Ingredient[] }>(
    [
      'ingredients',
      {
        filters: {
          country: filters.country,
          brand: filters.brand || Brand.CALO,
          kitchen: filters.kitchen || Kitchen.BH1,
          name: filterIngredients || undefined,
          ids: !filterIngredients && filters.ingredientId ? filters.ingredientId : undefined,
          stage: ProcessingStage.approved
        }
      }
    ],
    getListWithParams,
    {
      enabled: !!filterIngredients || !filters.ingredientId,
      onSuccess: (allIngredientList) => {
        setAllIngredients(allIngredientList?.data ?? []);
      }
    }
  );

  const { isLoading: prototypeIngLoading } = useQuery<any, Error, { data: Ingredient[] }>(
    [
      'ingredients/prototype',
      {
        filters: {
          country: filters.country,
          brand: filters.brand || Brand.CALO,
          kitchen: filters.kitchen || Kitchen.BH1,
          name: filterIngredients || undefined,
          ids: !filterIngredients && filters.ingredientId ? filters.ingredientId : undefined
        }
      }
    ],
    getListWithParams,
    {
      enabled: !!filterIngredients || !filters.ingredientId,
      onSuccess: (allIngredientList) => {
        setAllIngredients((prev) => [...prev, ...(allIngredientList?.data ?? [])]);
      }
    }
  );

  const ingredientOptions: Options<Ingredient>[] = useMemo(
    () =>
      sortBy(allIngredients, (ingredient) => `${ingredient.name.en}`).map((ingredient) => ({
        value: ingredient.id,
        data: { ...ingredient },
        label: `${ingredient.name.en} (${ingredient.internalName || ''})`
      })),
    [allIngredients]
  );

  return (
    <SideDrawer visible={settingsVisible} onClose={() => dispatch(toggleUISettings())}>
      <section className="section">
        <h5 className="title is-5">Filters</h5>
        <Select2
          data-test="countrySelect"
          label="Country"
          value={filters.country}
          onChange={(data) =>
            onFilter({
              ...filters,
              country: data.target.value === 'all' ? undefined : (data.target.value as Country),
              kitchen:
                data.target.value === 'all' ? undefined : (userKitchens.find((k) => k.includes(data.target.value)) as Kitchen)
            })
          }
          options={[
            { value: 'all', label: 'All' },
            ...getAccessibleCountries(userKitchens).map((c) => ({
              value: c,
              label: Country[c]
            }))
          ]}
        />
        <Select2
          data-test="kitchenSelect"
          label="Kitchen"
          value={filters.kitchen}
          onChange={(data) => onFilter({ ...filters, kitchen: data.target.value as Kitchen })}
          options={getKitchenOptions(userKitchens, filters.country!)}
          disabled={userKitchens.filter((r) => r.includes(filters.country!)).length === 0}
        />
        <Input
          data-test="nameInput"
          label="Name"
          value={filters.name}
          onChange={(e) => onFilter({ ...filters, name: e.target.value })}
          debounce
        />

        <Select
          data-test="ingredientSelect"
          label="Ingredient"
          isClearable
          value={filters.ingredientId}
          options={ingredientOptions}
          isLoading={ingLoading || prototypeIngLoading}
          onChange={(ingredient: any) => {
            if (isNull(ingredient)) {
              onFilter({ ...filters, ingredientId: undefined });
            } else {
              onFilter({ ...filters, ingredientId: ingredient.data.id });
            }
          }}
          onInputChange={(data: string, action: any) => handleSearch({ text: data, action, name: setFilterIngredients })}
        />

        <Select
          data-test="customMealCategorySelect"
          label="Custom Meal Category"
          value={filters.customMealCategory}
          options={[
            {
              value: undefined,
              label: 'Any'
            },
            ...Object.keys(CustomMealCategory).map((value) => ({
              value,
              label: capitalize(value)
            }))
          ]}
          onChange={(customMealCategory: any) => {
            onFilter({ ...filters, customMealCategory: customMealCategory.value });
          }}
        />

        <Select
          data-test="recipeStatusSelect"
          label="Recipe Status"
          value={filters.status}
          onChange={(status: any) => onFilter({ ...filters, status: status.value })}
          options={[
            {
              value: undefined,
              label: 'Any'
            },
            ...Object.entries(FoodComponentStatus).map(([key, value]) => ({
              value: key,
              label: value
            }))
          ]}
        />
        <Select
          label="Status"
          value={filters.stage}
          onChange={(v: any) =>
            onFilter({
              ...omit(filters, ['stage']),
              ...(v.value && {
                stage: v.value
              })
            })
          }
          options={[
            {
              value: '',
              label: 'Any'
            },
            ...Object.values(ProcessingStage)
              .filter((stage) => stage !== ProcessingStage.approved)
              .map((ft) => ({
                value: ft,
                label: FoodStatusFilterLabels[ft]
              }))
          ]}
        />
      </section>
    </SideDrawer>
  );
};
export default Settings;
