import DateFnsAdapter from '@date-io/date-fns';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, TextFieldProps } from '@mui/material';
import { Stack } from '@mui/material-v6';
import { LocalizationProvider, PickersDay, StaticDatePicker, pickersDayClasses } from '@mui/x-date-pickers';
import { caloTheme } from 'assets/images/theme/calo';
import { caloThemeV2 } from 'assets/themev2';
import InputThemeProvider from 'components/MUI/InputThemeProvider';
import { format, getWeekYear, startOfWeek } from 'date-fns';
import { findStartAndEndDateForWeek } from 'lib';
import PaperContent from '../WeeklyMenuActionsCard/PaperContent';

interface CalenderDialogProps {
  isOpen: boolean;
  create?: boolean;
  disabled?: boolean;
  onClose: () => void;
  selectedDate: Date;
  selectedWeek: number;
  disablePreviousWeeks?: boolean;
  disableFutureWeeks?: boolean;
  confirmationText?: string;
  setSelectedDate: (date: Date) => void;
  setSelectedWeek: React.Dispatch<React.SetStateAction<number>>;
  onDone: (weekNumber: number, year: number) => void;
}

const CalenderDialog = ({
  isOpen,
  onClose,
  create,
  disabled,
  selectedDate,
  selectedWeek,
  confirmationText,
  disablePreviousWeeks,
  disableFutureWeeks,
  setSelectedDate,
  setSelectedWeek,
  onDone
}: CalenderDialogProps) => {
  const handleRenderDateInput = (params: TextFieldProps) => {
    const { inputProps, ...rest } = params;
    const { startOfWeekDate, endOfWeekDate } = findStartAndEndDateForWeek(selectedWeek);
    const updatedInputProps = {
      ...inputProps,
      value: `Week ${selectedWeek} (${format(startOfWeekDate, 'dd/MM')} - ${format(endOfWeekDate, 'dd/MM')})`
    };

    return (
      <InputThemeProvider>
        <TextField
          {...rest}
          inputProps={updatedInputProps}
          sx={{
            mr: 2,
            width: '20%',
            minWidth: '240px',
            [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
              mt: 6,
              width: '100%',
              display: 'flex',
              borderRadius: '8px',
              paddingLeft: '30px',
              flexDirection: 'column',
              justifyContent: 'center'
            }
          }}
          error={false}
        />
      </InputThemeProvider>
    );
  };

  const handleRenderDay = (day: Date, DayComponentProps: any) => {
    const { startOfWeekDate, endOfWeekDate } = findStartAndEndDateForWeek(selectedWeek);
    if (
      format(day, 'yyyy-MM-dd') < format(startOfWeekDate, 'yyyy-MM-dd') ||
      format(day, 'yyyy-MM-dd') > format(endOfWeekDate, 'yyyy-MM-dd')
    ) {
      return <PickersDay sx={{ backgroundColor: 'none' }} {...DayComponentProps} />;
    }
    return (
      <PickersDay
        {...DayComponentProps}
        onClick={() => null}
        sx={{
          color: 'black',
          backgroundColor: caloTheme.palette.primary100,
          ':hover': { color: 'black', backgroundColor: caloTheme.palette.neutral100 },
          [`&&.${pickersDayClasses.selected}`]: {
            border: 1,
            color: 'black',
            backgroundColor: caloTheme.palette.neutral100,
            borderColor: caloTheme.palette.primary600
          }
        }}
      />
    );
  };

  const handleDoneClick = () => {
    const year = getWeekYear(startOfWeek(new Date(selectedDate), { weekStartsOn: 0 }));
    onDone(selectedWeek, year);
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>{create ? 'Select Week for Menu Creation' : 'Select Week'}</DialogTitle>
      <DialogContent>
        <LocalizationProvider dateAdapter={DateFnsAdapter}>
          <StaticDatePicker
            components={{
              // eslint-disable-next-line react/no-multi-comp
              PaperContent: (props) => (
                <PaperContent
                  {...props}
                  disablePreviousWeeks={disablePreviousWeeks}
                  disableFutureWeeks={disableFutureWeeks}
                  selectedDate={selectedDate}
                  selectedWeek={selectedWeek}
                  setSelectedWeek={setSelectedWeek}
                  setSelectedYear={() => {}}
                />
              )
            }}
            label="Week"
            onChange={() => {}}
            value={selectedDate}
            displayStaticWrapperAs="desktop"
            renderInput={handleRenderDateInput}
            onMonthChange={(event) => setSelectedDate(new Date(event))}
            renderDay={(day, _value, DayComponentProps) => handleRenderDay(new Date(day), DayComponentProps)}
          />
        </LocalizationProvider>
      </DialogContent>

      <DialogActions style={{ width: '100%', justifyContent: 'flex-end' }}>
        <Stack sx={{ flexDirection: 'row', gap: 2, alignItems: 'center', marginRight: 2 }}>
          <Button variant="text" disabled={disabled} onClick={handleDoneClick}>
            {confirmationText ?? 'Duplicate'}
          </Button>
          <Button
            variant="text"
            onClick={onClose}
            disabled={disabled}
            sx={{
              color: caloThemeV2.palette.text.primary
            }}
          >
            Cancel
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default CalenderDialog;
