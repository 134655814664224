import { Card, Divider, Stack, Typography } from '@mui/material';
import { ReactNode } from 'react';

export interface InformationCardProps {
  heading: string;
  children: ReactNode;
}

export default function InformationCard({ heading, children }: InformationCardProps) {
  return (
    <Card>
      <Typography
        sx={{
          p: 2,
          fontSize: '24px',
          fontWeight: 400
        }}
      >
        {heading}
      </Typography>
      <Divider />
      <Stack sx={{ p: 2 }}>{children}</Stack>
    </Card>
  );
}
