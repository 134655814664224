// CustomPaperContent.tsx
import { Box, Button, Stack, Typography } from '@mui/material';
import { caloTheme } from 'assets/images/theme/calo';
import { eachWeekOfInterval, endOfMonth, getWeek, getWeekYear, startOfMonth } from 'date-fns';
import React from 'react';

interface CustomPaperContentProps {
  selectedDate: Date;
  selectedWeek: number;
  disablePreviousWeeks?: boolean;
  disableFutureWeeks?: boolean;
  setSelectedWeek: (week: number) => void;
  setSelectedYear: (year: number) => void;
  children: React.ReactNode;
}

const PaperContent = ({
  selectedDate,
  selectedWeek,
  disablePreviousWeeks,
  disableFutureWeeks,
  setSelectedWeek,
  setSelectedYear,
  children
}: CustomPaperContentProps) => {
  const startOfMonthDate = startOfMonth(selectedDate);
  const endOfMonthDate = endOfMonth(selectedDate);
  const startDaysOfweeksInMonth = eachWeekOfInterval({ start: startOfMonthDate, end: endOfMonthDate }, { weekStartsOn: 0 });

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
      <Stack
        sx={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '20px',
          alignItems: 'center',
          marginLeft: '10px',
          gap: '3px'
        }}
      >
        <Typography sx={{ marginBottom: '14px' }}>Week</Typography>
        <Typography sx={{ marginBottom: '8px' }}>#</Typography>
        {startDaysOfweeksInMonth.map((weekStartDate) => {
          const week = getWeek(weekStartDate);
          const weekYear = getWeekYear(weekStartDate);
          const isDisabled = disablePreviousWeeks
            ? weekStartDate < new Date()
            : disableFutureWeeks
              ? weekStartDate > new Date()
              : false;
          return (
            <Button
              key={weekStartDate.toString()}
              disabled={isDisabled}
              onClick={() => {
                setSelectedWeek(week);
                setSelectedYear(weekYear);
              }}
              sx={{
                fontSize: '13px',
                fontWeight: 600,
                color: week === selectedWeek ? 'white' : 'black',
                minWidth: '35px',
                borderRadius: '20px',
                backgroundColor: week === selectedWeek ? caloTheme.palette.primary500 : 'white',
                ':hover': { color: 'white', backgroundColor: caloTheme.palette.primary500 }
              }}
            >
              {week}
            </Button>
          );
        })}
      </Stack>
      {children}
    </Box>
  );
};

export default PaperContent;
