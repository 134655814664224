export const styles = {
  dialogContainer: {
    '& .MuiDialog-paper': {
      width: '30.4%',
      maxWidth: '640px'
    }
  },
  dialogTitle: {
    fontSize: '20px',
    fontWeight: 500,
    lineHeight: '150%'
  },
  dialogDescription: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '150%'
  },
  dialogContent: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '150%'
  }
};
