import { CreateFoodReq, FoodStatus } from '@calo/dashboard-types';
import { Country } from '@calo/types';
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Breadcrumbs, IconButton, Stack, Typography } from '@mui/material';
import { caloTheme } from 'assets/images/theme/calo';
import { FormikErrors } from 'formik';
import { Routes } from 'lib/enums';
import { resolveCountry } from 'lib/helpers';
import { Link } from 'react-router-dom';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const styles = {
  breadcrumbText: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '150%',
    color: caloTheme.palette.textSecondary
  },
  breadcrumbActiveText: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '150%',
    color: caloTheme.palette.textPrimary
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  countryText: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '150%',
    color: caloTheme.palette.textPrimary
  },
  mealNameText: {
    fontSize: '34px',
    fontWeight: 400,
    lineHeight: '125%'
  },
  actionButtonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '12px'
  },
  deleteIcon: {
    fontSize: '18px',
    color: caloTheme.palette.red
  },
  deleteButton: {
    padding: '10px',
    textTransform: 'none',
    fontSize: '6px',
    color: caloTheme.palette.errorMain,
    border: `1px solid ${caloTheme.palette.errorMain}`,
    borderRadius: '4px'
  },
  createButton: {
    padding: '8px 22px',
    fontSize: '15px',
    fontWeight: 500,
    lineHeight: '125%',
    color: caloTheme.palette.neutral900,
    textTransform: 'uppercase',
    ':hover': {
      backgroundColor: 'transparent'
    },
    border: `1px solid ${caloTheme.palette.textPrimary}`,
    borderRadius: '4px'
  }
};

const chefPlaygroundNewFoodBreadCurmb = [
  <Typography key={1} sx={styles.breadcrumbText}>
    Chef's Playground
  </Typography>,
  <Link to={Routes.playgroundFoodList} key={2}>
    <Typography
      sx={{
        ...styles.breadcrumbText,
        '&:hover': {
          textDecoration: 'underline',
          color: caloTheme.palette.textPrimary
        }
      }}
    >
      Meals
    </Typography>
  </Link>,
  <Typography key={3} sx={styles.breadcrumbActiveText}>
    Create meal
  </Typography>
];

interface MealCreationHeaderProps {
  isSubmitting: boolean;
  handleSubmit: any;
  disableSubmit: boolean; //!dirty || !isValid || isSubmitting
  values: Omit<CreateFoodReq, 'id'>;
  setValues: (
    values: React.SetStateAction<Omit<CreateFoodReq, 'id'>>,
    shouldValidate?: boolean | undefined
  ) => Promise<void> | Promise<FormikErrors<Omit<CreateFoodReq, 'id'>>>;
}

const MealCreationHeader = ({ isSubmitting, disableSubmit, setValues, values, handleSubmit }: MealCreationHeaderProps) => {
  return (
    <Stack>
      <Box sx={styles.headerContainer}>
        <Breadcrumbs separator={<ChevronRightIcon sx={{ fontSize: '15px' }} />} aria-label="breadcrumb">
          {chefPlaygroundNewFoodBreadCurmb}
        </Breadcrumbs>
        <Typography key={1} sx={styles.countryText}>
          {resolveCountry(values.country || Country.BH)}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mt: '8px' }}>
        <Typography sx={styles.mealNameText}>{values.name.en || 'New Meal'}</Typography>
        <Box sx={styles.actionButtonsContainer}>
          <IconButton sx={styles.deleteButton}>
            <Link to={Routes.playgroundFoodList}>
              <DeleteIcon sx={styles.deleteIcon} />
            </Link>
          </IconButton>
          <LoadingButton
            variant="outlined"
            type="submit"
            loading={isSubmitting}
            disabled={disableSubmit}
            onClick={() => {
              setValues({
                ...values,
                status: FoodStatus.draft
              });
              handleSubmit();
            }}
            startIcon={<CreateIcon style={{ fontSize: '24px' }} />}
            sx={styles.createButton}
          >
            create meal
          </LoadingButton>
        </Box>
      </Box>
    </Stack>
  );
};

export default MealCreationHeader;
