import { Add, Delete, Remove } from '@mui/icons-material';
import { Box, Button, Divider, IconButton, ListItemText, Typography } from '@mui/material-v6';
import { caloTheme } from 'assets/images/theme/calo';
import { retailMenuDrinkItems } from 'lib/constants';
import React, { useState } from 'react';

interface EditOrderDialogProps {
  order: any;
  onClose: () => void;
  handleEditOrder: ({ items, orderId, orderNo }: { items: any[]; orderId: string; orderNo: string }) => void;
}

export const EditOrderDialog: React.FC<EditOrderDialogProps> = ({ order, onClose, handleEditOrder }) => {
  const [items, setItems] = useState(order.items);

  const updateItemQuantity = (index: number, amount: number) => {
    setItems((prevItems) =>
      prevItems.map((item, i) =>
        i === index
          ? {
              ...item,
              quantity: Math.max(0, Math.min(item.quantity + amount, order.items[index].quantity))
            }
          : item
      )
    );
  };

  const deleteItem = (index: number) => {
    setItems((prevItems) => prevItems.map((item, i) => (i === index ? { ...item, quantity: -1 } : item)));
  };

  const handleUpdateOrder = () => {
    handleEditOrder({ items, orderId: order.id, orderNo: order.orderId });
  };

  return (
    <Box>
      <Typography variant="h6" component="h2">
        Change order details
      </Typography>
      <Typography variant="body2" color="textSecondary" mb={3}>
        These changes will be reflected on the customer app.
      </Typography>
      <Box>
        <Typography variant="subtitle2">Ordered items</Typography>
        {items.map((item, index) => {
          if (item.quantity === -1) return null;
          const isDrinkCategory = item.category ? retailMenuDrinkItems[item.category] : true;
          const itemSize = isDrinkCategory && item.size ? ` (${item.size})` : '';
          return (
            <React.Fragment key={item.itemId}>
              <Box display="flex" alignItems="center" justifyContent="space-between" my={1}>
                <Box display="flex" alignItems="center" width="100%">
                  <IconButton onClick={() => deleteItem(index)}>
                    <Delete />
                  </IconButton>
                  <ListItemText primary={item.name.en + itemSize} secondary={item.notes} />
                </Box>

                <Box display="flex" alignItems="center">
                  <IconButton size="small" onClick={() => updateItemQuantity(index, -1)} disabled={item.quantity <= 0}>
                    <Remove />
                  </IconButton>
                  <Typography variant="body2" mx={1}>
                    {item.quantity}
                  </Typography>
                  <IconButton
                    size="small"
                    onClick={() => updateItemQuantity(index, 1)}
                    disabled={item.quantity >= order.items[index].quantity}
                  >
                    <Add />
                  </IconButton>
                </Box>
              </Box>
              {index < items.length - 1 && <Divider />}
            </React.Fragment>
          );
        })}
      </Box>
      <Box mt={3} display="flex" justifyContent="flex-end" gap={2}>
        <Button sx={{ color: caloTheme.palette.grey[600] }} onClick={onClose}>
          Go Back
        </Button>
        <Button
          variant="outlined"
          color="error"
          onClick={handleUpdateOrder}
          disabled={items.filter((item) => item.quantity > 0).length === 0}
        >
          Update Order
        </Button>
      </Box>
    </Box>
  );
};
