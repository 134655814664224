import { useEffect, useMemo, useState } from 'react';

import { FormikErrors } from 'formik';
import { compact, keyBy } from 'lodash';
import { useQuery } from 'react-query';

import AddIcon from '@mui/icons-material/Add';
import { Box, Button, Card, Divider, Stack, Typography } from '@mui/material';

import { caloTheme } from 'assets/images/theme/calo';
import IngredientPickerMUI from 'components/MUI/IngredientPickerMUI';
import { ProcessingStage } from 'lib/calo-dashboard-types';
import { calculatePurchasingCost } from 'lib/helpers';
import { BaseOmit, FoodComponent, FoodComponentWithQuantity, Ingredient, IngredientHeaderItem } from 'lib/interfaces';
import { findNumberOfIngredientHeaders } from 'views/Food/ExactFoodComponent/helpers';
import { getListWithParams } from '../../../actions';

interface IngredientListProps {
  values: Omit<FoodComponent, BaseOmit>;
  prototype?: boolean;
  childComponents: FoodComponent[];
  structuredIngredients: IngredientHeaderItem[];
  setValues: (
    values: React.SetStateAction<Omit<FoodComponent, BaseOmit>>,
    shouldValidate?: boolean | undefined
  ) => Promise<void> | Promise<any>;
  setStructuredIngredients: React.Dispatch<React.SetStateAction<IngredientHeaderItem[]>>;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<void> | Promise<FormikErrors<Omit<FoodComponent, BaseOmit>>>;
}

const IngredientListCard = ({
  values,
  prototype,
  childComponents,
  structuredIngredients,
  setFieldValue,
  setValues,
  setStructuredIngredients
}: IngredientListProps) => {
  const [ingredientName, setIngredientName] = useState('');

  const { data: ingData } = useQuery<any, Error, { data: Ingredient[] }>(
    [
      'ingredients',
      {
        filters: {
          name: ingredientName,
          country: values.country,
          brand: values.brand,
          kitchen: values.kitchen,
          stage: ProcessingStage.approved
        }
      }
    ],
    getListWithParams,
    {
      enabled: !!ingredientName,
      keepPreviousData: true
    }
  );

  const { data: prototypeIngData } = useQuery<any, Error, { data: Ingredient[] }>(
    [
      'ingredients/prototype',
      { filters: { name: ingredientName, country: values.country, brand: values.brand, kitchen: values.kitchen } }
    ],
    getListWithParams,
    {
      enabled: !!prototype && !!ingredientName,
      keepPreviousData: true
    }
  );

  const newIngredient = useMemo(
    () => keyBy([...(ingData?.data ?? []), ...(prototypeIngData?.data ?? [])], 'id'),
    [ingData, prototypeIngData]
  );
  const existingIngredient = useMemo(() => keyBy(values?.ingredients, 'id'), [values?.ingredients]);

  const handleIngredientsChange = (rows: Ingredient[]) => {
    const childrenWithQuantity: FoodComponentWithQuantity[] = childComponents.map((child) => ({
      ...child,
      quantity: (values.childComponents ?? []).find((cc) => cc.id === child.id)?.quantity ?? 0
    }));
    setValues({
      ...values,
      ingredients: rows,
      purchasingCost: +calculatePurchasingCost(rows.length > 0 ? rows : [], childrenWithQuantity, values.cookedRawFactor || 1)
    });
  };

  const addHeaderClickHandler = () => {
    const numberOfHeaders = findNumberOfIngredientHeaders(structuredIngredients);
    setStructuredIngredients((prev) => [{ type: 'header', header: `Header  ${numberOfHeaders + 1}` }, ...prev]);
  };

  useEffect(() => {
    const ingredients = compact(structuredIngredients.map((ing) => ing.type === 'ingredient' && ing.ingredient));

    const childrenWithQuantity: FoodComponentWithQuantity[] = childComponents.map((child) => ({
      ...child,
      quantity: (values.childComponents ?? []).find((cc) => cc.id === child.id)?.quantity ?? 0
    }));
    setFieldValue(
      'purchasingCost',
      (values.weight ?? 1) *
        calculatePurchasingCost(ingredients.length > 0 ? ingredients : [], childrenWithQuantity, values.cookedRawFactor || 1)
    );
  }, [structuredIngredients, values.cookedRawFactor, values.childComponents, values.weight, childComponents]);

  return (
    <Card
      variant="outlined"
      sx={{
        marginTop: '16px',
        border: 'none',
        borderRadius: '8px',
        boxShadow: 2,
        overflow: 'visible'
      }}
    >
      <Stack
        sx={{
          padding: '16px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <Typography sx={{ fontSize: '24px', fontWeight: 400, lineHeight: '150%' }}>Ingredients</Typography>
        <Button
          onClick={addHeaderClickHandler}
          startIcon={<AddIcon />}
          sx={{
            color: caloTheme.palette.primary500,
            fontSize: '16px',
            fontWeight: 600,
            textTransform: 'none'
          }}
        >
          Add Header
        </Button>
      </Stack>
      <Divider sx={{ color: caloTheme.palette.divider, height: '1px', width: '100%' }} />
      <Box sx={{ borderColor: 'white', padding: '24px 16px 16px 16px' }}>
        <IngredientPickerMUI
          isDisabled={false}
          ingredients={values.ingredients}
          structuredIngredients={structuredIngredients}
          setStructuredIngredients={setStructuredIngredients}
          list={[...(ingData?.data ?? []), ...(prototypeIngData?.data ?? [])]}
          newIngredient={newIngredient}
          ingredientName={ingredientName}
          setFieldValue={setFieldValue}
          onChange={handleIngredientsChange}
          setIngredientName={setIngredientName}
          existingIngredient={existingIngredient}
        />
      </Box>
    </Card>
  );
};

export default IngredientListCard;
