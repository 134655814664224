import { caloTheme } from 'assets/images/theme/calo';

export const styles = {
  card: {
    marginTop: '16px',
    border: 'none',
    borderRadius: '8px',
    boxShadow: 2
  },
  title: {
    fontSize: '24px',
    fontWeight: 400,
    lineHeight: '150%',
    padding: '16px'
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '24px 16px 16px 16px'
  },
  columnContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '2px'
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: 2
  },
  textField: {
    my: 1,
    width: '100%'
  },
  textFieldContainer: {
    width: '100%'
  },
  inputLabel: {
    mb: 1,
    fontSize: '12px',
    fontWeight: 400,
    ml: 1,
    mt: '-4px'
  },
  ingredientChip: {
    color: 'red',
    backgroundColor: 'lightpink',
    fontSize: '14px',
    mb: '15px'
  },
  inputProps: {
    style: { borderRadius: 8 }
  },
  inputStyle: {
    borderRadius: 8
  },
  ingredientChips: {
    fontWeight: 600
  },
  divider: {
    color: caloTheme.palette.divider,
    height: '1px',
    width: '100%'
  }
};
