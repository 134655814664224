import client from 'lib/client';
import { ToastSuccessMsgs } from 'lib/helpers';
import { toast } from 'react-toastify';
import orderDetailChangedToast from 'views/Retail/RetailOrderList/components/OrderDetailChangedToast';
import { PickUpStatus } from 'views/Retail/types';

export const updateRetailOrderStatus = async (pickUpId: string, status: PickUpStatus, reason?: string) => {
  const order = await client.post(`/retail/order/update-status`, { pickUpId, status, reason });
  ToastSuccessMsgs({ status: order.status, action: 'Order status updated' });
  return order.data;
};

export const updateRetailOrderItems = async ({ orderId, items, orderNo }: { orderId: string; items: any[]; orderNo: string }) => {
  const loadingToastId = toast.loading(`Updating order items for ${orderNo}...`, { autoClose: false });
  const order = await client.post(`/retail/order/update-items`, { orderId, items });
  toast.dismiss(loadingToastId);
  orderDetailChangedToast({ orderNo });
  return order.data;
};
