import { UpdateMenuReq } from '@calo/dashboard-types';
import { Button, Card, DialogActions, DialogContent, DialogTitle, List, ListItem, ListItemText, Stack } from '@mui/material';
import { getListWithParams } from 'actions/index';
import { generateMenu, updateMenu } from 'actions/menu';
import { ModalRef } from 'components/Modal';
import Popup from 'components/Popup';
import { getYear } from 'date-fns';
import { MenuPresentationType } from 'lib/enums';
import { Menu, MenuFood } from 'lib/interfaces';
import queryClient from 'lib/queryClient';
import { isEqual, uniqBy } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { PhoneMenuPreview, WeeklyMenuActionsCard, WeeklyMenuListCards } from '../components';
import MenuPageHeaderCard from '../components/MenuPageHeaderCard';
import DailyMenuActionsCard from './DailyMenuActionsCard';
import DailyMenuCard from './DailyMenuCard';
import { useMenuFilters, useWeeklyMenu } from './MenuHooks';
import MenuPageActions from './MenuPageActions';
import Settings from './Settings';
import { styles } from './styles';

const MenuList = () => {
  const deletedFoodRef = useRef<ModalRef>();

  const [menuList, setMenuList] = useState<Menu[]>([]);
  const [originalMenuList, setOriginalMenuList] = useState<Menu[]>([]);
  const [isPhoneModalOpen, setIsPhoneModalOpen] = useState(false);
  const [filters, setFilters] = useMenuFilters();
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [isAddMealsPopupOpen, setIsAddMealsPopupOpen] = useState(false);
  const [selectedYear, setSelectedYear] = useState<number>(getYear(new Date()));
  const [deletedFoodNames, setDeletedFoodNames] = useState<string>('');
  const [foodToReplace, setFoodToReplace] = useState<{ food: MenuFood }>();
  const [selectedWeeklyMenu, setSelectedWeeklyMenu] = useState<Menu>();

  const { mutateAsync: generateMutation, isLoading: isGenerateMenuLoading } = useMutation(generateMenu);

  const {
    weeklyMenuList,
    isWeeklyMenuLoading,
    foodIds,
    originalWeeklyMenuData,
    setOriginalWeeklyMenuData,
    weeklyAverageCost,
    setWeeklyAverageCost,
    setWeeklyMenuList
  } = useWeeklyMenu(filters, filters.selectedWeek, filters.selectedYear);

  const updateWeeklyMenuList = (newMenuList: Menu[]) => {
    const filteredMenuList = newMenuList.map((menu) => ({
      ...menu,
      food: menu.food.filter((food) => !food.deletedAt) // Exclude deleted food
    }));

    const menusWithDeletedFood = newMenuList.filter((menu) => menu.food?.some((food) => food.deletedAt));
    if (menusWithDeletedFood.length > 0) {
      const updatedNames = uniqBy(
        menusWithDeletedFood.flatMap((menu) => menu.food.filter((food) => food.deletedAt)),
        (food) => food.name.en
      )
        .map((food) => food.name.en)
        .join(', ');
      setDeletedFoodNames(updatedNames);
      deletedFoodRef.current?.open();
    }
    setWeeklyMenuList(filteredMenuList);
  };

  const openPhoneModal = () => {
    setIsPhoneModalOpen(true);
  };

  const closePhoneModal = () => {
    setIsPhoneModalOpen(false);
  };

  const { data, isFetching: isDailyMenuListLoading } = useQuery<any, Error, Menu[]>(
    [
      'menu',
      {
        brand: filters.brand,
        kitchen: filters.kitchen || undefined
      }
    ],
    getListWithParams,
    {
      onSuccess: (data) => {
        setMenuList(data);
        setOriginalMenuList(data);
        for (const row of data || []) {
          queryClient.setQueryData(['menu', row.id], row);
        }
      }
    }
  );

  useEffect(() => {
    setMenuList(data ?? []);
    setOriginalMenuList(data ?? []);
  }, [data]);

  const { mutateAsync: updateMutation, isLoading: isUpdateWeeklyMenuLoading } = useMutation(updateMenu);

  const onSubmitWeeklyMenu = async (values: Menu) => {
    const updatedMenu: UpdateMenuReq = {
      day: values.id,
      food: values.food?.map((food) => food.id) || [],
      country: values.country,
      tags: (values.tags as any) || [],
      brand: values.brand,
      kitchen: values.kitchen,
      generatedAt: values.generatedAt || undefined
    };
    await updateMutation(
      { ...updatedMenu, id: values.id, mBrand: values.brand, mKitchen: values.kitchen, draft: values.draft as any },
      {
        onSuccess: (data) => {
          const updatedOriginals = originalWeeklyMenuData.map((menu) => (menu.id === data.id ? data : menu));
          setOriginalWeeklyMenuData(updatedOriginals);
          setWeeklyMenuList((prev) => prev.map((menu) => (menu.id === data.id ? data : menu)));
        }
      }
    );
  };

  const handleUpdateWeeklyMenus = async () => {
    if (weeklyMenuList) {
      try {
        const updatePromises: Promise<void>[] = weeklyMenuList.reduce<Promise<void>[]>((promises, menu) => {
          const originalMenu = originalWeeklyMenuData.find((original) => original.id === menu.id);
          if (!isEqual(menu.food?.map((f) => f.id).sort(), originalMenu?.food?.map((f) => f.id).sort())) {
            promises.push(onSubmitWeeklyMenu(menu));
          }
          return promises;
        }, []);
        await Promise.all(updatePromises);
        setIsEditable(false);
      } catch (error) {
        console.error('Failed to update weekly menus:', error);
      }
    }
    setIsEditable(false);
  };

  useEffect(() => {
    setIsEditable(false);
  }, [filters.selectedWeek, selectedYear, filters.menuPresentation]);

  useEffect(() => {
    setWeeklyMenuList([]);
  }, [filters.kitchen]);

  const selectedMenu = menuList?.find((menu) => menu.id === filters.selectedDay);

  const discardDailyMenuEditsHandler = () => {
    setMenuList(originalMenuList);
    setIsEditable(false);
  };

  const discardWeeklyMenuEditsHandler = () => {
    setWeeklyMenuList(originalWeeklyMenuData);
    setIsEditable(false);
  };

  const handleGenerateMenu = () => {
    if (!selectedMenu) {
      toast('No menu selected to generate', { type: 'error' });
      return;
    }
    generateMutation(selectedMenu, {
      onError: (error: any) => {
        if (error.response.status && error.response.status === 504) {
          toast('It is taking too much time, please report to the engineering team', {
            type: 'error'
          });
        }
      }
    });
  };

  const handleGenerateWeeklyMenu = async () => {
    if (!weeklyMenuList || weeklyMenuList.length === 0) {
      toast('No weekly menus available to generate', { type: 'error' });
      return;
    }
    for (const menu of weeklyMenuList) {
      try {
        await generateMutation(menu);
        toast.success(`Menu for ${menu.day} generated successfully`);
      } catch {
        toast.error(`Failed to generate menu for ${menu.day}`);
      }
    }
  };

  const generatedMenuTime =
    (filters.menuPresentation === MenuPresentationType.weekly ? weeklyMenuList[0]?.generatedAt : selectedMenu?.generatedAt) ?? '';
  return (
    <Stack sx={{ marginLeft: 2 }}>
      <MenuPageHeaderCard country={filters.country} kitchen={filters.kitchen} menuPresentation={filters.menuPresentation} />
      <MenuPageActions
        disabled={isEditable || isUpdateWeeklyMenuLoading || isGenerateMenuLoading}
        menuPresentation={filters.menuPresentation}
        isGenerateMenuLoading={isGenerateMenuLoading}
        selectedMenuDay={selectedMenu?.day!}
        generatedAt={generatedMenuTime}
        isEditable={isEditable}
        weeklyMenuDatesList={weeklyMenuList.map((menu) => menu.day) ?? []}
        generateMenuHandler={handleGenerateMenu}
        generateWeeklyMenuHandler={handleGenerateWeeklyMenu}
        setMenuPresentation={setFilters.setMenuPresentation}
      />
      <Card
        variant="outlined"
        sx={{
          border: 'none',
          mb: 2,
          marginTop: 4,
          borderRadius: '8px',
          paddingBottom: '4px',
          padding: 2,
          minHeight: '100vh'
        }}
      >
        {filters.menuPresentation === MenuPresentationType.daily && (
          <DailyMenuActionsCard
            menuList={menuList ?? []}
            foodToReplace={foodToReplace}
            selectedMenu={selectedMenu}
            selectedDay={filters.selectedDay}
            foodList={selectedMenu?.food ?? ([] as any)}
            isEditable={isEditable}
            setMenuList={setMenuList}
            setOriginalMenuList={setOriginalMenuList}
            discardEditsHandler={discardDailyMenuEditsHandler}
            setIsEditable={setIsEditable}
            setFoodToReplace={setFoodToReplace}
            setSelectedDay={setFilters.setDay}
          />
        )}
        {filters.menuPresentation === MenuPresentationType.weekly && (
          <WeeklyMenuActionsCard
            filters={filters}
            isLoading={isDailyMenuListLoading}
            isEditable={isEditable}
            selectedYear={selectedYear}
            foodToReplace={foodToReplace}
            weeklyMenuList={weeklyMenuList}
            disabled={isUpdateWeeklyMenuLoading}
            weeklyAverageCost={weeklyAverageCost}
            selectedWeek={filters.selectedWeek}
            openPhoneModal={openPhoneModal}
            setIsEditable={setIsEditable}
            discardEditsHandler={discardWeeklyMenuEditsHandler}
            setFoodToReplace={setFoodToReplace}
            handleUpdateWeeklyMenus={handleUpdateWeeklyMenus}
            setWeeklyMenuList={setWeeklyMenuList}
            setSelectedWeek={setFilters.setWeek}
            setSelectedYear={setSelectedYear}
            updateWeeklyMenuList={updateWeeklyMenuList}
          />
        )}
        {filters.menuPresentation === MenuPresentationType.daily && (
          <DailyMenuCard
            isLoading={isDailyMenuListLoading}
            selectedMenu={selectedMenu}
            isEditable={isEditable}
            menuList={menuList}
            setFoodToReplace={setFoodToReplace}
            setMenuList={setMenuList}
          />
        )}
        {filters.menuPresentation === MenuPresentationType.weekly && (
          <>
            <WeeklyMenuListCards
              filters={filters}
              foodIds={foodIds}
              isEditable={isEditable}
              selectedWeek={filters.selectedWeek}
              selectedYear={selectedYear}
              weeklyMenuList={weeklyMenuList}
              isLoading={isWeeklyMenuLoading}
              selectedMenu={selectedWeeklyMenu}
              isAddMealsPopupOpen={isAddMealsPopupOpen}
              setWeeklyMenuList={setWeeklyMenuList}
              setSelectedMenu={setSelectedWeeklyMenu}
              setWeeklyAverageCost={setWeeklyAverageCost}
              setIsAddMealsPopupOpen={setIsAddMealsPopupOpen}
              openAddMealsPopup={() => setIsAddMealsPopupOpen(true)}
            />
            <PhoneMenuPreview weeklyMenuList={weeklyMenuList} open={isPhoneModalOpen} closePhoneModal={closePhoneModal} />
            <Popup maxWidth="sm" fullWidth closeIcon onClose={() => deletedFoodRef.current?.close()} ref={deletedFoodRef}>
              <DialogTitle sx={styles.dialogTitle}>
                The following food have been deleted and will be removed from the menu
              </DialogTitle>
              <DialogContent>
                <Stack sx={styles.stackPadding}>
                  <List>
                    {deletedFoodNames.split(', ').map((name, index) => (
                      <ListItem key={index} sx={styles.listItem}>
                        <ListItemText primary={name} />
                      </ListItem>
                    ))}
                  </List>
                </Stack>
              </DialogContent>
              <DialogActions>
                <Button sx={styles.button} onClick={() => deletedFoodRef.current?.close()} disabled={isDailyMenuListLoading}>
                  Done
                </Button>
              </DialogActions>
            </Popup>
          </>
        )}
      </Card>
      <Settings onFilter={setFilters} filters={filters} />
    </Stack>
  );
};

export default MenuList;
