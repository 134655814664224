import { Kitchen } from '@calo/types';
import { Box, Typography } from '@mui/material-v6';
import { getKitchenShortCut } from 'lib/helpers';
import { styles } from './styles';

interface SuccessBoxProps {
  componentName: string;
  kitchens: Kitchen[];
}

const SuccessBox = ({ componentName, kitchens }: SuccessBoxProps) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Typography sx={styles.validationResultText}>
        The component " {componentName} " has been synced to{' '}
        {kitchens.map((kitchen) => `"${getKitchenShortCut(kitchen)}"`).join(' and ')}, successfully.
      </Typography>
    </Box>
  );
};

export default SuccessBox;
