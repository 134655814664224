import { getListWithParams, getRecord } from 'actions';
import { getTotalPoints } from 'actions/loyalty';
import { caloTheme } from 'assets/images/theme/calo';
import { CaloLoader } from 'components';
import { isSameMonth } from 'date-fns';
import { format } from 'date-fns/fp';
import { SubscriptionPages } from 'lib/enums';
import { useUserRoles } from 'lib/hooks';
import { Delivery, PaginatedDeliveries } from 'lib/interfaces';
import { uniqBy } from 'lodash-es';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useLocation, useParams } from 'react-router-dom';

import { Permission } from '@calo/dashboard-types';
import { Subscription } from '@calo/types';
import { Card } from '@mui/material';

import DeliveriesCard from './DeliveriesCard';
import FeedbackCard from './FeedbackCard';
import LogsCard from './LogsCard';
import LoyaltyCard from './LoyaltyCard';
import PlanCard from './PlanCard';
import ReferralCard from './ReferralCard';
import SubscriptionCard from './SubscriptionCard';
import SubscriptionTab from './SubscriptionTab';
import TransactionsCard from './TransactionsCard';
import UserInformationCard from './UserInformationCard';
import WalletCard from './WalletCard';

const ExactSubscription2 = () => {
  const today = new Date(Date.now());
  const { id } = useParams<{ id: string }>();
  const [selectedDate, setSelectedDate] = useState<Date>(today);
  const [newDate, setNewDate] = useState<Date>(today);
  const [selectedDelivery, setSelectedDelivery] = useState<string>();
  const [selectedPage, setSelectedPage] = useState<SubscriptionPages>(SubscriptionPages.userInfo);
  const roles = useUserRoles();
  const [totalLoyaltyPoints, setTotalLoyalyPoints] = useState({ amount: 0 });
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const selectedTabQuery = searchParams.get('tab');
  const selectedDeliveryDayQuery = searchParams.get('delivery');
  const [subscriptionDeliveries, setSubscriptionDeliveries] = useState<Delivery[]>([]);
  const [selectedDeliveryView, setSelectedDeliveryView] = useState('list');
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);

  const { data, refetch: refetchSubscription } = useQuery(['subscriptions', id], getRecord, {
    suspense: true
  });
  const subscription = data as Subscription & {
    remainingDays: number;
    expectedLastDeliveryDay: string;
    ratings: any[];
    magicPrice?: boolean;
    isClubMember: boolean;
  };

  const { isLoading, refetch } = useQuery<any, Error, PaginatedDeliveries>(
    [
      `subscriptions/${subscription.id}/deliveries`,
      {
        page: selectedDeliveryView === 'list' ? page : 0,
        limit: 31,
        day: selectedDeliveryView === 'list' ? '' : format('yyyy-MM-dd')(newDate)
      }
    ],
    getListWithParams,
    {
      suspense: false,
      keepPreviousData: false,
      onSuccess: (data) => {
        if (data && data.data) {
          setSubscriptionDeliveries((old) => uniqBy([...old, ...data.data], 'id'));
          setTotal(data.meta.total);
        }
      }
    }
  );

  useEffect(() => {
    setSelectedDelivery(undefined);
    const allDeliveryData = subscriptionDeliveries?.find((del) => del.day === format('yyyy-MM-dd')(selectedDate));
    if (allDeliveryData) {
      setSelectedDelivery(allDeliveryData.id);
    }
    if (!isSameMonth(selectedDate, newDate)) {
      setNewDate(selectedDate);
    }
  }, [subscriptionDeliveries, selectedDate, id]);

  useEffect(() => {
    setPage(0);
    setSubscriptionDeliveries([]);
    const getTotalLoyaltyPoints = async (id: string) => {
      const { data } = await getTotalPoints(id);
      setTotalLoyalyPoints({ amount: data.amount });
    };
    if (roles.includes(Permission.VIEW_LOYALTY_POINTS)) {
      getTotalLoyaltyPoints(id);
    }
  }, [id]);

  useEffect(() => {
    if (
      selectedTabQuery &&
      selectedTabQuery !== selectedPage &&
      Object.values(SubscriptionPages).includes(selectedTabQuery as SubscriptionPages)
    ) {
      setSelectedPage(selectedTabQuery as SubscriptionPages);
    }
    if (selectedDeliveryDayQuery && selectedDeliveryDayQuery !== selectedDelivery) {
      setSelectedDate(new Date(selectedDeliveryDayQuery));
      setNewDate(new Date(selectedDeliveryDayQuery));
    }
  }, [selectedTabQuery, selectedDeliveryDayQuery, id]);

  useEffect(() => {
    refetch();
  }, [selectedDeliveryView]);

  return (
    <>
      <Card
        variant="outlined"
        sx={{
          width: 'full',
          mb: '14px',
          border: 'none',
          borderRadius: '8px',
          paddingBottom: '4px',
          [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
            flexDirection: 'column'
          }
        }}
      >
        <SubscriptionCard
          key={`${subscription.id}-subCard`}
          subscription={subscription}
          loyaltyPoints={totalLoyaltyPoints}
          refetchSubscription={refetchSubscription}
        />
        <SubscriptionTab
          key={`${subscription.id}-subTab`}
          selectedPage={selectedPage}
          setSelectedPage={(value) => setSelectedPage(value)}
        />
      </Card>

      {selectedPage === SubscriptionPages.userInfo && (
        <UserInformationCard
          key={`${subscription.id}-userInfoCard`}
          subscription={subscription}
          refetchSubscription={refetchSubscription}
        />
      )}
      {selectedPage === SubscriptionPages.plan && (
        <PlanCard
          subscription={subscription}
          refetchAllDeliveries={() => refetch()}
          refetchSubscription={refetchSubscription}
          setSelectedDate={(date) => {
            setSelectedDate(date);
            setSelectedPage(SubscriptionPages.deliveries);
          }}
          key={`${subscription.id}-planCard`}
        />
      )}
      {selectedPage === SubscriptionPages.deliveries &&
        (isLoading && subscriptionDeliveries.length === 0 ? (
          <CaloLoader />
        ) : (
          <DeliveriesCard
            subscription={subscription}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            selectedDelivery={selectedDelivery!}
            refetchAllDeliveries={() => refetch()}
            setSelectedDelivery={setSelectedDelivery}
            key={`${subscription.id}-DeliveriesCard`}
            allDeliveries={subscriptionDeliveries || []}
            refetchSubscription={refetchSubscription}
            setSubscriptionDeliveries={setSubscriptionDeliveries}
            selectedDeliveryView={selectedDeliveryView}
            setSelectedDeliveryView={setSelectedDeliveryView}
            page={page}
            setPage={setPage}
            total={total}
            isLoading={isLoading}
          />
        ))}

      {selectedPage === SubscriptionPages.logs && <LogsCard key={`${subscription.id}-logsCard`} subscription={subscription} />}

      {selectedPage === SubscriptionPages.wallet && (
        <WalletCard key={`${subscription.id}-walletCard`} subscription={subscription} />
      )}

      {selectedPage === SubscriptionPages.transactions && (
        <TransactionsCard key={`${subscription.id}-transactionsCard`} subscription={subscription} />
      )}

      {selectedPage === SubscriptionPages.feedback && (
        <FeedbackCard key={`${subscription.id}-feedback`} subscription={subscription} />
      )}

      {selectedPage === SubscriptionPages.loyalty && (
        <LoyaltyCard key={`${subscription.id}-loyalty`} subscription={subscription} />
      )}

      {selectedPage === SubscriptionPages.referral && (
        <ReferralCard key={`${subscription.id}-referral`} subscription={subscription} />
      )}
    </>
  );
};
export default ExactSubscription2;
