import { Permission } from '@calo/dashboard-types';
import { Brand, FoodDietType, Kitchen } from '@calo/types';
import FoodDietTypeTags from 'components/FoodDietTypeTags';
import { dateOfWeek } from 'lib';
import { Routes } from 'lib/enums';
import { useUserRoles } from 'lib/hooks';
import { sortBy } from 'lodash';
import { Link, generatePath } from 'react-router-dom';
interface AddonsProps {
  addons: any;
}

const AddonsRow = ({ addons }: AddonsProps) => {
  const path = generatePath(Routes.addons, {
    country: addons.country,
    brand: addons.brand || Brand.CALO,
    id: addons.id,
    kitchen: addons.kitchen || Kitchen.BH1
  });
  const roles = useUserRoles();
  const sortedList = sortBy(addons.food, 'priority');

  return (
    <div className="card has-table has-table-container-upper-radius ">
      <header className="card-header bg-black w-full">
        <>
          <p className="card-header-title text-white">
            {roles.includes(Permission.VIEW_ADDONS) ? (
              <Link to={path}>{`${dateOfWeek({ week: addons.week, year: addons.year })} / ${addons.country}`}</Link>
            ) : (
              `${dateOfWeek({ week: addons.week, year: addons.year })} / ${addons.country}`
            )}
          </p>
          {addons.brand === Brand.MEALO ? (
            <p className="text-bold text-yellow-500 float-right mr-3 my-4 w-12">mealo</p>
          ) : (
            <p className=" text-bold text-green-500 float-right mr-3 my-4 w-12">CALO</p>
          )}
        </>
      </header>
      <div className="table-container">
        <table className="table mt-4 is-fullwidth is-striped is-hoverable is-sortable is-fullwidth">
          <thead>
            <tr>
              <th>Name</th>
              <th>Food Type</th>
              <th>Size</th>
              <th>DietType</th>
              <th>Labels</th>
              <th>priority</th>
            </tr>
          </thead>
          <tbody>
            {sortedList.map((f: any) => (
              <tr key={f.id}>
                <td>
                  <div key={f.id} className="mb-4">
                    {f.name.en}
                  </div>
                </td>
                <td>
                  <div key={f.id} className="mb-4">
                    {f.type.join(', ')}
                  </div>
                </td>
                <td>
                  <div key={f.id} className="mb-4">
                    {f.size}
                  </div>
                </td>
                <td>
                  <div key={f.id} className="mb-2 w-15 flex">
                    {f.tags?.map((r: FoodDietType) => <FoodDietTypeTags key={r} tag={r} fullName />)}
                  </div>
                </td>
                <td>
                  <div key={f.id} className="mb-4">
                    {addons.tags?.find((item: any) => item.foodId === f.id)?.value?.join(', ')}
                  </div>
                </td>
                <td>
                  <div key={f.id} className="mb-4">
                    {f.priority}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AddonsRow;
