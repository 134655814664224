import { Button, Typography } from '@mui/material';
import { caloTheme } from 'assets/images/theme/calo';
import { PaymentStatus as PaymentStatusEnum } from 'lib/enums';
import { convertOrderStatus } from '../../helpers';

interface PaymentStatusProps {
  status: string;
}

export default function PaymentStatus({ status }: PaymentStatusProps) {
  const paymentStatus = convertOrderStatus(status);
  return paymentStatus === PaymentStatusEnum.all ? (
    <></>
  ) : (
    <Button
      variant="outlined"
      sx={{
        borderRadius: '25px',
        border: `1px solid ${
          paymentStatus === PaymentStatusEnum.paid ? caloTheme.palette.primary.main : caloTheme.palette.neutral300
        }`,
        textTransform: 'capitalize',
        width: '70px'
      }}
    >
      <Typography
        variant={'button'}
        sx={{
          fontSize: '14px',
          fontWeight: 600,
          color: paymentStatus === PaymentStatusEnum.paid ? caloTheme.palette.primary.main : caloTheme.palette.neutral900,
          fontFamily: caloTheme.typography.fontFamily,
          textTransform: 'capitalize'
        }}
      >
        {convertOrderStatus(status)}
      </Typography>
    </Button>
  );
}
