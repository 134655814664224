import { FoodType } from '@calo/types';
import { StarSharp } from '@mui/icons-material';
import { Box, Button, Stack, Typography, colors } from '@mui/material-v6';
import { caloTheme } from 'assets/images/theme/calo';
import { caloThemeV2 } from 'assets/themev2';
import { Food } from 'lib/interfaces';
import React from 'react';
import FoodDietTypeTags from '../FoodDietTypeTags';
import { styles } from './styles';

interface FoodItemCardProps {
  food: Food;
  isLast: boolean;
  isSelected?: boolean;
  lastFoodElementRef: React.RefObject<HTMLDivElement>;
  handleAction: (food: Food) => void;
  handleCalculateMealCost: (food: Food) => number;
  isLoading: boolean;
}

const FoodItemCard = ({
  food,
  isLast,
  isSelected,
  lastFoodElementRef,
  handleAction,
  handleCalculateMealCost,
  isLoading
}: FoodItemCardProps) => {
  return (
    <Box
      key={food.id}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        paddingRight: '12px',
        borderBottomWidth: '1px',
        paddingBottom: '16px',
        gap: 2,
        paddingTop: '16px',
        borderColor: caloThemeV2.palette.divider
      }}
    >
      <Stack ref={isLast ? lastFoodElementRef : null} sx={{ width: '100%' }}>
        <Stack
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Typography sx={{ fontSize: '16px', fontWeight: 500, width: '45%' }}>{`${food.name.en}`}</Typography>
          <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '55%' }}>
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: '14px',
                color: handleCalculateMealCost(food) > 26 ? caloTheme.palette.red : caloTheme.palette.black
              }}
              variant="h6"
            >
              {handleCalculateMealCost(food)}%
            </Typography>

            <Stack sx={{ width: '15%', flexDirection: 'row', alignItems: 'center' }}>
              <StarSharp sx={{ width: '16px', color: colors.amber[500] }} />
              <Typography sx={{ ml: '6px', mt: '-2px', fontSize: '14px', fontWeight: 400 }}>
                {!!food.totalRating && !!food.numberOfRatings ? (food.totalRating / food.numberOfRatings).toFixed(2) : 0}(
                {food.numberOfRatings ? food.numberOfRatings : 0})
              </Typography>
            </Stack>
            <Typography variant="h6" sx={styles.mealTypeStyle}>
              {food.type.map((r) => r !== FoodType.dinner && (r.includes(FoodType.lunch) ? 'Lunch & Dinner' : r))}
            </Typography>
            <Box sx={{ ...styles.foodTagsStyle, display: 'flex', flexWrap: 'nowrap', flexDirection: 'row', width: '20%' }}>
              {food.tags.map((tag) => (
                <FoodDietTypeTags key={tag} tag={tag} />
              ))}
            </Box>
          </Stack>
        </Stack>
      </Stack>
      <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          {food && (
            <Stack sx={{ ...styles.stackTagOption, flexWrap: 'wrap' }}>
              {food.foodTags?.map((tag) =>
                tag.value?.map((tagOption) => (
                  <Typography key={tagOption} sx={styles.searchTermStyle}>
                    {tagOption}
                  </Typography>
                ))
              )}
            </Stack>
          )}
          {food.usedOnMenu && food.usedOnMenu.length > 0 && (
            <Stack sx={styles.lastUsedContainer}>
              <Typography variant="h1" sx={styles.lastUsedText}>
                Last Used: {food.usedOnMenu[food.usedOnMenu.length - 1].date}
              </Typography>
            </Stack>
          )}
        </Box>
        <Button
          variant="outlined"
          color={isSelected ? 'primary' : 'inherit'}
          disabled={isLoading}
          sx={{ width: '102px', fontSize: '16px' }}
          onClick={() => handleAction(food)}
        >
          {isSelected ? 'Selected' : 'Select'}
        </Button>
      </Stack>
    </Box>
  );
};

export default FoodItemCard;
