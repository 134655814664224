import { forwardRef, useEffect, useState } from 'react';

import { omit } from 'lodash-es';

import { Brand, Country, Kitchen as KitchenType } from '@calo/types';

import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Button, Divider, IconButton, Stack, Typography } from '@mui/material-v6';
import { createPrototypeFood } from 'actions';
import { Select } from 'components';
import Popup from 'components/Popup';
import { customFoodTypeStyles } from 'lib/componentStyles';
import { getAvailableMealSizes } from 'lib/helpers';
import { Food } from 'lib/interfaces';
import { styles } from './styles';

interface NewSizeModalProps {
  selectedFood: Food;
  foodWithAllSizes: Food[];
  setSelectedFood: (food: Food) => void;
  setFoodWithAllSizes: (food: Food[]) => void;
  close: () => void;
  refetch: any;
}

const NewSizeModal = forwardRef(
  ({ selectedFood, foodWithAllSizes, setSelectedFood, setFoodWithAllSizes, close, refetch }: NewSizeModalProps, ref) => {
    const [inputSize, setInputSize] = useState('');
    const [selectedSize, setSelectedSize] = useState('');
    const [isDuplicate, setIsDuplicate] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
      const sizeExists = foodWithAllSizes.some((food) => food.size === selectedSize.toUpperCase());
      setIsDuplicate(sizeExists);
    }, [selectedSize, foodWithAllSizes]);

    const handleDuplicatedFood = async () => {
      setIsLoading(true);
      try {
        if (isDuplicate) return;
        const duplicateFood = omit(selectedFood, [
          'id',
          'createdAt',
          'dataType',
          'updatedAt',
          'lowercaseName',
          'slug',
          'fhk',
          'numberOfRatings',
          'totalRating',
          'usedOnMenu',
          'lastUsedOnMenu',
          'periodicAggregatedRatings',
          'totalNumberOfFavorites',
          'totalNumberOfBlocks',
          'ingredients',
          'pCost',
          'purchasingCost'
        ]) as Food;
        duplicateFood.size = selectedSize.toUpperCase();
        duplicateFood.prototypeActions = duplicateFood.prototypeActions ?? [];
        duplicateFood.brand = duplicateFood.brand ? duplicateFood.brand : Brand.CALO;
        duplicateFood.country = duplicateFood.country ? duplicateFood.country : Country.BH;
        duplicateFood.kitchen = duplicateFood.kitchen ? duplicateFood.kitchen : KitchenType.BH1;
        duplicateFood.components = duplicateFood.components?.map((comp) => ({ id: comp.id, quantity: comp.quantity })) ?? [];
        const response = await createPrototypeFood(duplicateFood as any);
        if (response) {
          setFoodWithAllSizes([...foodWithAllSizes, response]);
          setSelectedFood(response);
          setSelectedSize('');
          close();
          refetch();
        }
      } catch (error) {
        console.error('Error creating duplicate food:', error);
      } finally {
        setIsLoading(false);
      }
    };

    return (
      <Popup ref={ref} fullWidth onClose={close} padding={0}>
        <Stack sx={styles.modelContainer}>
          <Typography variant="h6" sx={{ fontSize: '20px', fontWeight: 500 }}>
            Create new meal size
          </Typography>
          <IconButton onClick={close}>
            <CloseIcon />
          </IconButton>
        </Stack>

        <Divider sx={{ marginBottom: 2 }} />

        <Box sx={{ px: 2, marginBottom: 2 }}>
          <Select
            label=""
            value={selectedSize}
            placeholder="Select meal size"
            onInputChange={(d: any) => {
              if (d) {
                setInputSize(d);
              }
            }}
            onChange={(data: any) => setSelectedSize(data.value)}
            options={getAvailableMealSizes(['XS', 'S', 'M', 'L', 'XL', inputSize], foodWithAllSizes).map((type) => ({
              value: type.toUpperCase(),
              label: type
            }))}
            customStyles={customFoodTypeStyles}
          />
        </Box>

        <Divider sx={{ marginBottom: 2 }} />

        <Stack direction="row" justifyContent="flex-end" spacing={2} sx={{ px: 2, marginBottom: 2 }}>
          <LoadingButton
            loading={isLoading}
            variant="outlined"
            sx={styles.newSizeModalButton}
            onClick={handleDuplicatedFood}
            disabled={!selectedSize || isDuplicate}
          >
            Create
          </LoadingButton>
          <Button
            onClick={() => {
              close();
              setIsLoading(false);
            }}
            variant="text"
            sx={styles.newSizeModalCloseButton}
          >
            Close
          </Button>
        </Stack>
      </Popup>
    );
  }
);

export default NewSizeModal;
