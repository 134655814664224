/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import { caloTheme } from 'assets/images/theme/calo';

const styles = {
  dialogContainer: {
    '& .MuiDialog-paper': {
      width: '44.3%',
      maxWidth: '640px'
    }
  },
  dialogTitle: {
    fontSize: '20px',
    fontWeight: 500,
    lineHeight: '150%'
  },
  dialogContent: {
    overflow: 'visible'
  },
  commentTextField: {
    width: '100%'
  }
};

interface ChangeRequestDialogProps {
  open: boolean;
  handleClose: () => void;
  handleSubmit: (comment: string) => Promise<void>;
  isPositiveButtonLoading?: boolean;
  title: string;
}

export const ChangeRequestDialog = ({
  open,
  handleClose,
  handleSubmit,
  isPositiveButtonLoading,
  title
}: ChangeRequestDialogProps) => {
  const [comment, setComment] = useState('');

  const onSubmit = async () => {
    await handleSubmit(comment);
    setComment('');
  };

  return (
    <Dialog
      sx={styles.dialogContainer}
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle sx={styles.dialogTitle}>{title}</DialogTitle>
      <DialogContent sx={styles.dialogContent}>
        <TextField
          label="Comment"
          type="text"
          name="comment"
          onChange={(e) => setComment(e.target.value)}
          value={comment}
          disabled={isPositiveButtonLoading}
          sx={styles.commentTextField}
          InputProps={{
            style: { borderRadius: 8 }
          }}
        />
      </DialogContent>
      <DialogActions>
        <LoadingButton
          variant="outlined"
          onClick={onSubmit}
          autoFocus
          loading={isPositiveButtonLoading}
          disabled={isPositiveButtonLoading || !comment}
        >
          SUBMIT
        </LoadingButton>
        <Button sx={{ color: caloTheme.palette.black }} onClick={handleClose}>
          CANCEL
        </Button>
      </DialogActions>
    </Dialog>
  );
};
