import { forwardRef } from 'react';

import { Permission, UpdateFoodReq } from '@calo/dashboard-types';
import { Box, Checkbox, Divider, FormControlLabel, FormGroup, Stack, Typography } from '@mui/material';

import { caloTheme } from 'assets/images/theme/calo';
import { Food } from 'lib/interfaces';
import AllPackagesMUI from '../MUI/AllPackagesMUI';

interface PackagingInformationProps {
  roles: any;
  values: UpdateFoodReq;
  isDisabled: boolean;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  setPackageCost: (r: number) => void;
  food: Food;
}

const PackagingInformation = forwardRef<HTMLDivElement, PackagingInformationProps>(
  ({ values, roles, isDisabled, food, setFieldValue, setPackageCost }: PackagingInformationProps, ref) => {
    return (
      <>
        {roles.includes(Permission.VIEW_FOOD_PACKAGE_LIST) && (
          <Stack
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            ref={ref}
            sx={{ backgroundColor: caloTheme.palette.white, borderRadius: '8px', boxShadow: 2 }}
          >
            <Typography
              sx={{
                fontSize: '24px',
                fontWeight: 400,
                lineHeight: '150%',
                padding: '16px'
              }}
            >
              Packaging Information
            </Typography>
            <Divider sx={{ color: caloTheme.palette.divider, height: '1px', width: '100%' }} />
            <Box sx={{ padding: '24px 16px 16px 16px', width: '100%' }}>
              <AllPackagesMUI
                values={values}
                setFieldValue={setFieldValue}
                setPackageCost={(r) => setPackageCost(r)}
                country={food.country}
                brand={food.brand}
                isDisabled={isDisabled}
                kitchen={food.kitchen}
              />
              <Typography
                sx={{
                  m: 2,
                  color: caloTheme.palette.neutral900,
                  textTransform: 'capitalize',
                  fontSize: '16px',
                  fontWeight: 600,
                  lineHeight: '23px'
                }}
              >
                Exclude from printing
              </Typography>

              <FormGroup aria-label="position" row>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.metadata?.excludeFrontLabel}
                      onChange={(c, v) => setFieldValue('metadata.excludeFrontLabel', v)}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  }
                  label="Front Label"
                  labelPlacement="top"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.metadata?.excludeBackLabel}
                      onChange={(c, v) => setFieldValue('metadata.excludeBackLabel', v)}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  }
                  label="Back Label"
                  labelPlacement="top"
                />
              </FormGroup>
            </Box>
          </Stack>
        )}
      </>
    );
  }
);

export default PackagingInformation;
