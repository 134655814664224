import { RetailBranch } from '@calo/types';

export const cancellationReasons = {
  OUT_OF_STOCK: 'Items are out of stock',
  CANCELLED_BY_CUSTOMER: 'Customer asked to cancel'
};

export const baristaEmailMapping = {
  'calocafe-ksa01@calo.app': [RetailBranch.BOXAT],
  'cafebh01@calo.app': [RetailBranch.SEEF],
  'h.alwi@calo.app': [RetailBranch.SEEF]
};

export const notificationActions = {
  NEW_RETAIL_ORDER: 'new-retail-order',
  CUSTOMER_ARRIVED: 'customer-arrived'
};
