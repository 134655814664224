import { createBusinessAccountDelivery, deleteBusinessAccountDelivery, getListWithParams, postRequest } from 'actions';
import { caloTheme } from 'assets/images/theme/calo';
import { CaloLoader, Icon, InputMUI, ModalRef, Pagination } from 'components';
import Popup from 'components/Popup';
import { addDays } from 'date-fns';
import { format } from 'date-fns/fp';
import { checkCountryCurrency } from 'lib';
import { disableDates } from 'lib/helpers/disableDates';
import { BusinessAccount, Food, Options, PaginatedBusinessAccountDeliveries } from 'lib/interfaces';
import { orderBy, sortBy, startCase, uniq, uniqBy } from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters, useMutation, useQuery } from 'react-query';

import { AddressService } from '@calo/services';
import { Kitchen } from '@calo/types';
import DateFnsAdapter from '@date-io/date-fns';
import SearchIcon from '@mui/icons-material/Search';
import TuneIcon from '@mui/icons-material/Tune';
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  styled,
  tableCellClasses
} from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';

import { DeliveryTime, FoodStatus } from 'lib/enums';
import AccountDeliveriesRow from './AccountDeliveriesRow';

interface AccountDeliveiresCardProprs {
  account: BusinessAccount;
  refetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<unknown, unknown>>;
}

export interface SelectedMealData {
  name: string;
  id: string;
  data: Food;
  size: string;
  numberOfMeals: number;
}
const AccountDeliveiresCard = ({ account, refetch }: AccountDeliveiresCardProprs) => {
  const addDeliveryRef = useRef<ModalRef>();
  const [page, setPage] = useState(0);

  const {
    data: userOrders,
    isLoading,
    refetch: ordersRefetch
  } = useQuery<any, Error, PaginatedBusinessAccountDeliveries>(
    [
      'business/deliveries',
      {
        page,
        filters: {
          kitchen: account.deliveryAddresses[0].kitchen ? account.deliveryAddresses[0].kitchen : undefined,
          userId: account.id
        },
        limit: 20
      }
    ],
    getListWithParams
  );

  const [newDeliveryData, setNewDeliveryData] = useState<any>({
    location: {},
    date: '',
    time: account.deliveryTime,
    meals: []
  });
  const [searchMealName, setSearchMealName] = useState<string>();
  const [searchMealNameList, setSearchMealNameList] = useState<string>();

  const [sortingOption, setSortingOption] = useState<string>('name');
  const [searchBarOpen, setSearchBarOpen] = useState<boolean>(false);

  const [selectedMealsData, setSelectedMealsData] = useState<SelectedMealData[]>([]);
  const [allSelectedMealOptions, setAllSelectedMealOptions] = useState<Food[]>([]);
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const { mutateAsync: createMutation } = useMutation(createBusinessAccountDelivery);
  const [existDeliveryDay, setExistDeliveryDay] = useState<string[]>(uniq([]));

  useEffect(() => {
    if (userOrders) {
      userOrders.data.map((del) => setExistDeliveryDay((old) => [...old, del.date]));
    }
  }, [userOrders]);

  const handleCreateOrder = async () => {
    const itemsValues = selectedMealsData.map((meal) => ({
      type: 'food',
      itemId: meal.id,
      count: meal.numberOfMeals,
      isSkipped: false,
      itemCost: 0
    }));
    const values = {
      date: newDeliveryData.date,
      country: account.deliveryAddresses[0].country,
      kitchen: account.deliveryAddresses[0].kitchen,
      brand: account.brand,
      time: newDeliveryData.time,
      status: 'confirmed',
      currency: checkCountryCurrency(account.deliveryAddresses[0].country),
      userId: account.id,
      deliveryAddressId: account.deliveryAddresses[0].id,
      orders: [
        {
          withCutlery: false,
          items: itemsValues
        }
      ],
      cost: 0,
      totalAmount: 0,
      paidAmount: 0,
      isCharity: false
    };
    await createMutation({
      values
    });
    addDeliveryRef.current?.close();
    setSelectedMealsData([]);
    setAllSelectedMealOptions([]);
    setSearchBarOpen(false);
    refetch();
    ordersRefetch();
  };

  const { data: foodList, isLoading: foodLoading } = useQuery<any, Error, { data: Food[] }>(
    [
      'food/list',
      {
        filters: {
          name: searchMealName ? searchMealName : undefined,
          country: account.deliveryAddresses[0].country,
          brand: account.brand,
          isDeleted: false,
          kitchen: account.deliveryAddresses[0].kitchen || Kitchen.BH1,
          status: FoodStatus.approved
        },
        sort: {
          orderBy: 'name',
          orderMode: 'asc'
        },
        page: 0,
        limit: 30
      }
    ],
    postRequest,
    {
      suspense: false,
      enabled: !!searchMealName,
      keepPreviousData: true
    }
  );

  const sortedFoodList = sortBy(
    (foodList?.data || []).filter((f) => !f.deletedAt),
    (f) => `${f.name.en}`
  );
  const mealOptions = uniqBy(
    useMemo<Options<Food>[]>(
      () =>
        sortedFoodList
          .map((food) => ({
            name: food.name.en,
            value: food.id,
            data: food,
            label: `${food.name.en} ${food.size && `(${food.size})`}`
          }))
          .filter((meal) => !selectedMealsData.find((ex) => ex.id === meal.value)),
      [foodList, selectedMealsData]
    ),
    'name'
  );

  const handleSelectedMealChanges = (selectedMeal: SelectedMealData, value: string, field: string) => {
    if (field === 'size') {
      const newMealId = allSelectedMealOptions.find((meal) => meal.name.en === selectedMeal.name && value === meal.size);
      const updatedMeals = selectedMealsData.map((meal) =>
        meal.id === selectedMeal.id ? { ...meal, id: newMealId!.id, size: newMealId!.size } : meal
      );
      setSelectedMealsData(updatedMeals);
    } else if (field === 'number') {
      const updatedMeals = selectedMealsData.map((meal) =>
        meal.id === selectedMeal.id ? { ...meal, numberOfMeals: +value } : meal
      );
      setSelectedMealsData(updatedMeals);
    }
  };

  const handleSelectedOption = (value: Options<Food>) => {
    const allOptions = sortedFoodList.filter((meal) => meal.name.en === value.name);
    if (!allSelectedMealOptions?.find((meal) => meal.name.en === value.name)) {
      setAllSelectedMealOptions(uniqBy([...allSelectedMealOptions, ...allOptions], 'name'));
      setSearchMealName('');
    }
    setSelectedMealsData([
      ...selectedMealsData,
      { name: value.name!, id: value.value, data: value.data, size: value.data.size, numberOfMeals: 1 }
    ]);
    setSearchMealName('');
  };

  const handleSortingOptionChange = (event: string) => {
    setSortingOption(event);
    setSelectedMealsData(() => orderBy(selectedMealsData, sortingOption, 'desc'));
    setAnchorEl(null);
  };

  const handleSearchChange = (event: string) => {
    setSearchMealNameList(event);
  };

  const deleteDelivery = async (id: string) => {
    await deleteBusinessAccountDelivery(id);
    await ordersRefetch();
  };

  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      border: 'none'
    },
    [`&.${tableCellClasses.body}`]: {
      border: 'none'
    }
  }));

  return (
    <Card
      variant="outlined"
      sx={{
        border: 'none',
        borderRadius: '8px',
        paddingBottom: '4px',
        [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
          flexDirection: 'column'
        }
      }}
    >
      <Box overflow="auto" width="100%" sx={{ padding: 2 }}>
        <Stack flexDirection={'row'} justifyContent="space-between" sx={{ marginY: 1 }}>
          <Typography
            variant="h6"
            sx={{
              pb: 2,
              fontFamily: caloTheme.typography.fontFamily,
              fontWeight: 600,
              lineHeight: '23px'
            }}
          >
            Deliveries
          </Typography>
          <Button
            variant="outlined"
            data-test="addDeliveryButton"
            sx={{
              cursor: 'pointer',
              my: 'auto',
              '&:hover': {
                border: 2,
                fontWeight: 600,
                fontSize: '14px',
                borderRadius: '8px',
                backgroundColor: caloTheme.palette.primary100,
                borderColor: caloTheme.palette.primary600
              },
              border: 2,
              fontWeight: 600,
              fontSize: '14px',
              borderRadius: '8px',
              color: caloTheme.palette.primary500,
              borderColor: caloTheme.palette.primary500,
              [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                justifyItems: 'center',
                margin: 'auto',
                marginTop: 4,
                width: 'auto'
              }
            }}
            startIcon={'+'}
            onClick={() => addDeliveryRef.current?.open()}
          >
            Add Delivery
          </Button>
        </Stack>
        {isLoading ? (
          <CaloLoader />
        ) : (
          <>
            <Table
              sx={{
                marginY: '4px',
                minHeight: '120px',
                overflow: 'auto',
                width: '100%',
                [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                  flexDirection: 'column'
                }
              }}
            >
              <TableHead
                style={{ borderRadius: '8px' }}
                sx={{
                  backgroundColor: caloTheme.palette.neutral50,
                  color: 'black',
                  flexWrap: 0,
                  justifyContent: 'space-between',
                  width: '100%',
                  borderRadius: '8px'
                }}
              >
                <TableRow
                  sx={{
                    backgroundColor: caloTheme.palette.neutral50,
                    color: 'black',
                    width: '100%',
                    flexWrap: 0,
                    justifyContent: 'space-between'
                  }}
                >
                  <StyledTableCell style={{ borderTopLeftRadius: '8px', borderBottomLeftRadius: '8px' }}>
                    Delivery Date
                  </StyledTableCell>
                  <StyledTableCell style={{ textAlign: 'center' }} sx={{ maxWidth: '64px', minWidth: '64px' }}>
                    Meals
                  </StyledTableCell>
                  <StyledTableCell sx={{ maxWidth: '239px', minWidth: '239px' }}>Email</StyledTableCell>
                  <StyledTableCell>Address</StyledTableCell>
                  <StyledTableCell>Delivery Time</StyledTableCell>
                  <StyledTableCell>Status</StyledTableCell>
                  <StyledTableCell style={{ borderTopRightRadius: '8px', borderBottomRightRadius: '8px' }}>
                    Action
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              {userOrders?.data && userOrders.data.length === 0 ? (
                <TableRow>
                  <StyledTableCell colSpan={6}>
                    <Typography
                      sx={{
                        mt: 6,
                        textAlign: 'center',
                        fontSize: '24px',
                        color: caloTheme.palette.neutral400
                      }}
                    >
                      No Deliveries
                    </Typography>
                  </StyledTableCell>
                </TableRow>
              ) : (
                <TableBody>
                  {userOrders?.data &&
                    userOrders.data.map((accountDelivery) => (
                      <AccountDeliveriesRow
                        account={account}
                        refetch={ordersRefetch}
                        key={accountDelivery.id}
                        accountDelivery={accountDelivery}
                        deleteDelivery={deleteDelivery}
                        existDeliveryDay={existDeliveryDay}
                      />
                    ))}
                </TableBody>
              )}
            </Table>
            {userOrders && (
              <Pagination
                isHidden={userOrders.meta.total === 0}
                limit={userOrders.meta.limit}
                total={userOrders.meta.total || 0}
                page={page}
                onChange={setPage}
              />
            )}
          </>
        )}
      </Box>
      <Popup
        maxWidth="lg"
        fullWidth
        title="Create Delivery"
        ref={addDeliveryRef}
        onClose={() => {
          addDeliveryRef.current?.close();
          setSelectedMealsData([]);
          setAllSelectedMealOptions([]);
          setSearchBarOpen(false);
        }}
      >
        <Container>
          <Box sx={{ my: 2 }}>
            <Stack display="flex" flexDirection={'row'} justifyContent={'space-between'}>
              <TextField
                select
                label="Location"
                name="Location"
                data-test="locationSelect"
                placeholder="select a location"
                sx={{ minWidth: '31%', mr: 2 }}
                value={newDeliveryData.location}
                id="new-account-delivery-location"
                onChange={(data: any) => setNewDeliveryData({ ...newDeliveryData, location: data.target.value })}
                InputProps={{ inputProps: { style: { borderRadius: 8 } }, style: { borderRadius: 8 } }}
              >
                {account.deliveryAddresses.map((deliveryAddress) => {
                  const displayableAddress = AddressService.display(deliveryAddress);
                  return (
                    <MenuItem value={deliveryAddress.id} key={deliveryAddress.id} data-test={`${displayableAddress}MenuItem`}>
                      {displayableAddress}
                    </MenuItem>
                  );
                })}
              </TextField>

              <LocalizationProvider dateAdapter={DateFnsAdapter} sx={{ mb: '4px', mx: 2 }}>
                <DesktopDatePicker
                  label="Delivery Date"
                  inputFormat="dd-MM-yyyy"
                  InputProps={{
                    id: 'deliveryDatePickerInput'
                  }}
                  value={newDeliveryData.date}
                  shouldDisableDate={disableDates(new Date(), addDays(new Date(), 1))}
                  renderInput={(params) => <TextField {...params} sx={{ mx: 2, borderRadius: '8px' }} />}
                  onChange={(date) => {
                    setNewDeliveryData({
                      ...newDeliveryData,
                      date: date?.toString() === 'Invalid Date' ? '' : format('yyyy-MM-dd')(+date!)
                    });
                  }}
                />
              </LocalizationProvider>

              <TextField
                select
                name="time"
                label="Delivery Time"
                sx={{ minWidth: '31%' }}
                id="new-account-delivery-time"
                data-test="deliveryTimeSelect"
                value={newDeliveryData.time}
                InputProps={{ inputProps: { style: { borderRadius: 8 } }, style: { borderRadius: 8 } }}
                onChange={(data: any) => setNewDeliveryData({ ...newDeliveryData, time: data.target.value })}
              >
                {Object.values(DeliveryTime).map((time) => (
                  <MenuItem value={time} data-test={`${time}MenuItem`} key={time}>
                    {startCase(time)}
                  </MenuItem>
                ))}
              </TextField>
            </Stack>

            <Stack display="flex" flexDirection={'row'} justifyContent={'space-between'} sx={{ mt: 2 }}>
              <Autocomplete
                options={mealOptions || []}
                loading={foodLoading}
                loadingText={'Loading....'}
                inputValue={searchMealName}
                getOptionLabel={(option) => option.label || ''}
                style={{ borderRadius: 8, width: '100%' }}
                value={newDeliveryData.meals}
                onInputChange={(event, value) => setSearchMealName(value)}
                onChange={(x, value) => handleSelectedOption(value)}
                ListboxProps={{
                  id: 'addMealListBox'
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Add Meals"
                    variant="outlined"
                    data-test="addMealTextFieldInput"
                    placeholder="Type to search..."
                    inputProps={{
                      ...params.inputProps,
                      placeholder: 'Search for a Meal to Add'
                    }}
                  />
                )}
              />
            </Stack>
          </Box>

          {selectedMealsData.length > 0 && (
            <Box maxHeight={'27rem'} sx={{ overflowY: 'auto', overflowX: 'hidden', my: 2 }}>
              <Table
                sx={{
                  marginY: '4px',
                  minHeight: '120px',
                  overflow: 'auto',
                  width: '100%',
                  [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                    flexDirection: 'column'
                  }
                }}
              >
                <TableHead
                  style={{ borderRadius: '8px' }}
                  sx={{
                    backgroundColor: caloTheme.palette.neutral50,
                    color: 'black',
                    flexWrap: 'nowrap',
                    justifyContent: 'space-between',
                    borderRadius: '8px'
                  }}
                >
                  <TableRow
                    sx={{
                      backgroundColor: caloTheme.palette.neutral50,
                      color: 'black',
                      width: '100%',
                      flexWrap: 'nowrap',
                      justifyContent: 'space-between'
                    }}
                  >
                    <StyledTableCell
                      style={{ width: '50%', justifyContent: 'start', borderTopLeftRadius: '8px', borderBottomLeftRadius: '8px' }}
                    >
                      Meals
                    </StyledTableCell>
                    <StyledTableCell style={{ maxWidth: '100px', minWidth: '100px' }} />
                    <StyledTableCell colSpan={2} style={{ maxWidth: '520px', minWidth: '520px' }}>
                      {searchBarOpen && (
                        <InputMUI
                          debounce
                          placeholder={'Type Search'}
                          sx={{ border: 0 }}
                          value={searchMealNameList}
                          inputProps={{
                            inputProps: {
                              style: {
                                borderRadius: '240px',
                                color: caloTheme.palette.neutral500,
                                fontSize: '14px',
                                lineHeight: '17px'
                              }
                            },
                            style: {
                              borderColor: 'white',
                              border: 1,
                              borderRadius: '240px',
                              width: '521px',
                              height: '32px',
                              backgroundColor: 'white'
                            }
                          }}
                          onChange={(v) =>
                            v.target.value.length > 0 ? handleSearchChange(v.target.value) : setSearchMealNameList(undefined)
                          }
                        />
                      )}
                    </StyledTableCell>
                    <TableCell
                      style={{
                        width: '200px',
                        borderTopRightRadius: '8px',
                        borderBottomRightRadius: '8px',
                        border: 'none',
                        textAlign: 'end'
                      }}
                    >
                      <SearchIcon sx={{ cursor: 'pointer' }} onClick={() => setSearchBarOpen(!searchBarOpen)} />
                      <IconButton sx={{ cursor: 'pointer' }} size="small" onClick={(event) => setAnchorEl(event.currentTarget)}>
                        <TuneIcon
                          id="sortOption"
                          aria-label="sortOption"
                          sx={{
                            cursor: 'pointer',
                            transform: 'rotate(90deg)'
                          }}
                        />
                      </IconButton>
                      <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={() => setAnchorEl(null)}
                        sx={{
                          width: 'auto'
                        }}
                        MenuListProps={{
                          'aria-labelledby': 'sortOption'
                        }}
                      >
                        <MenuItem sx={{ fontWeight: 600 }} onClick={() => handleSortingOptionChange('name')} value="name">
                          Alphabetical Order{' '}
                        </MenuItem>
                        <MenuItem
                          sx={{ fontWeight: 600 }}
                          onClick={() => handleSortingOptionChange('numberOfMeals')}
                          value="numberOfMeals"
                        >
                          Number of Meals{' '}
                        </MenuItem>
                      </Menu>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selectedMealsData.filter((meal) => (searchMealNameList ? meal.name.includes(searchMealNameList) : meal))
                    .length === 0 ? (
                    <TableRow sx={{ borderBottom: 0, borderColor: caloTheme.palette.neutral300 }}>
                      <StyledTableCell colSpan={5} style={{ textAlign: 'center', width: '100%' }}>
                        <Typography>No Meal Found</Typography>
                      </StyledTableCell>
                    </TableRow>
                  ) : (
                    selectedMealsData
                      .filter((meal) => (searchMealNameList ? meal.name.includes(searchMealNameList) : meal))
                      .map((meal) => (
                        <TableRow key={meal.id} sx={{ borderBottom: 2, borderColor: caloTheme.palette.neutral300 }}>
                          <StyledTableCell style={{ width: '300px' }}>
                            <Typography>{startCase(meal.name)}</Typography>
                          </StyledTableCell>
                          <StyledTableCell sx={{ width: '100px', display: 'table-cell', alignContent: 'end' }} />
                          <StyledTableCell sx={{ width: '200px', display: 'table-cell', position: 'relative' }}>
                            <TextField
                              select
                              key={meal.id}
                              type="text"
                              name="size"
                              label="Meal Size"
                              value={meal.size}
                              style={{
                                width: '100%',
                                alignContent: 'end'
                              }}
                              onChange={(data: any) => handleSelectedMealChanges(meal, data.target.value, 'size')}
                              InputProps={{
                                inputProps: { style: { borderRadius: 8, width: '100%' } },
                                style: { borderRadius: 8, width: '100%' }
                              }}
                            >
                              {allSelectedMealOptions
                                .filter((mealList) => mealList.name.en === meal.name)
                                .map((mealOption) => (
                                  <MenuItem
                                    disabled={!!selectedMealsData.find((x) => x.id === mealOption.id)}
                                    value={mealOption.size}
                                    key={mealOption.id}
                                  >
                                    {mealOption.size}
                                  </MenuItem>
                                ))}
                            </TextField>
                          </StyledTableCell>
                          <StyledTableCell sx={{ width: '200px', display: 'table-cell', position: 'relative' }}>
                            <InputMUI
                              debounce
                              name="Number of Meals"
                              label="Number of Meals"
                              value={meal.numberOfMeals}
                              style={{ width: '100%' }}
                              disabled={meal.size?.length === 0}
                              pattern={'[0-9]*'}
                              type="number"
                              onChange={(data: any) => handleSelectedMealChanges(meal, data.target.value, 'number')}
                              inputProps={{
                                inputProps: {
                                  style: {
                                    borderRadius: 8,
                                    width: '100%'
                                  }
                                },
                                style: { borderRadius: 8, width: '100%' }
                              }}
                            />
                          </StyledTableCell>
                          <StyledTableCell sx={{ width: '300px', height: '34px' }}>
                            <Button
                              sx={{
                                textAlign: 'center',
                                backgroundColor: caloTheme.palette.secondaryPink100,
                                width: '48px',
                                height: '48px',
                                borderRadius: '8px',
                                '&:hover': {
                                  backgroundColor: caloTheme.palette.secondaryPink300
                                }
                              }}
                              onClick={() => setSelectedMealsData((meals) => meals.filter((r) => r !== meal))}
                            >
                              <Icon name={'removeTrash'} size={7} className="mt-1 ml-1" />
                            </Button>
                          </StyledTableCell>
                        </TableRow>
                      ))
                  )}
                </TableBody>
              </Table>
            </Box>
          )}
        </Container>
        <Box display={'flex'} flexDirection={'row'} justifyContent={'center'}>
          <Button
            variant="contained"
            data-test="createDeliveryButton"
            sx={{
              fontFamily: 'Roboto',
              fontStyle: 'normal',
              textTransform: 'none',
              fontSize: '19px',
              fontWeight: 600,
              lineHeight: '23px',
              backgroundColor: caloTheme.palette.primary500,
              color: 'white',
              boxShadow: 'none',
              width: '185px',
              height: '54px',
              '&:hover': {
                backgroundColor: caloTheme.palette.primary600,
                boxShadow: 'none'
              }
            }}
            disabled={selectedMealsData.length === 0}
            onClick={() => handleCreateOrder()}
          >
            Create Delivery
          </Button>
        </Box>
      </Popup>
    </Card>
  );
};
export default AccountDeliveiresCard;
