import { Kitchen } from '@calo/types';
import { Autocomplete, FormControl, InputLabel, MenuItem, Select, Stack, TextField } from '@mui/material';
import DatePicker from 'components/DatePicker';
import FilterButtons from 'components/FilterButtons';
import FilterItemsContainer from 'components/FilterItemsContainer';
import { PaymentStatus } from 'lib/enums';
import { useUserKitchens } from 'lib/hooks';
import { CateringOrder, CateringOrderFilters } from 'lib/interfaces';
import { capitalize, sortBy, uniqBy } from 'lodash-es';
import { useMemo, useState } from 'react';
import { getDate, getDefaultFilters } from '../../helpers';

export interface CateringFilterItemsProps {
  filters: CateringOrderFilters;
  setFilters: React.Dispatch<React.SetStateAction<CateringOrderFilters>>;
  toggleFiltersDrawer: (bool: boolean) => void;
  businessOrders: CateringOrder[];
  isLoading: boolean;
}

export default function CateringFilterItems({
  filters,
  setFilters,
  toggleFiltersDrawer,
  businessOrders,
  isLoading
}: CateringFilterItemsProps) {
  const [userId, setUserId] = useState(filters.userId);
  const [referenceId, setReferenceId] = useState(filters.referenceId);
  const [kitchen, setKitchen] = useState<Kitchen>(filters.kitchen);
  const [status, setStatus] = useState<PaymentStatus | undefined>(filters.status);
  const [orderDate, setOrderDate] = useState<Date | null>(filters.orderDate ? new Date(filters.orderDate) : null);
  const [deliveryDate, setDeliveryDate] = useState<Date | null>(filters.deliveryDate ? new Date(filters.deliveryDate) : null);

  const userKitchens: string[] = useUserKitchens();

  const options = useMemo(() => {
    const sortedOrders = sortBy(businessOrders, (f) => `${f.user.companyName}`);
    const uniqueOrders = uniqBy(sortedOrders, (order) => order.user.id);
    return uniqueOrders.map((order) => ({
      name: order.user.companyName,
      value: order.user.id,
      label: `${order.user.companyName}`
    }));
  }, [businessOrders]);

  return (
    <FilterItemsContainer
      filterItems={
        <Stack direction={'column'} gap={3.5}>
          <Autocomplete
            options={options}
            loading={isLoading}
            getOptionLabel={(option) => (typeof option === 'string' ? option : option.label)}
            value={options.find((option) => option.value === userId) || null}
            onChange={(_, newValue) => {
              const valueToSend = typeof newValue === 'string' ? newValue : newValue?.value;
              setUserId(valueToSend);
            }}
            ListboxProps={{
              id: 'searchBusinessListBox'
            }}
            data-test="searchBusinessAutoComplete"
            renderInput={(params) => <TextField {...params} placeholder="Enter Business Name" sx={{ width: '100%' }} />}
          />
          <TextField
            label="Reference ID"
            placeholder="Enter Reference ID"
            value={referenceId}
            onChange={(e) => setReferenceId(e.target.value)}
          ></TextField>
          <FormControl sx={{ width: '100%' }}>
            <Select
              value={kitchen}
              onChange={(event) => setKitchen(event.target.value as Kitchen)}
              inputProps={{ 'aria-label': 'Without label' }}
            >
              {userKitchens.map((value) => (
                <MenuItem key={value} value={value}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <DatePicker date={orderDate} onChange={(newDate) => newDate && setOrderDate(newDate)} label={'Order Date'} />
          <DatePicker date={deliveryDate} onChange={(newDate) => newDate && setDeliveryDate(newDate)} label={'Delivery Date'} />
          <FormControl sx={{ width: '100%' }}>
            <InputLabel sx={{ color: 'black' }}>Status</InputLabel>
            <Select
              label="Status"
              value={status}
              onChange={(event) => setStatus(event.target.value as PaymentStatus)}
              inputProps={{ 'aria-label': 'Without label' }}
            >
              {Object.values(PaymentStatus).map((value) => (
                <MenuItem key={value} value={value}>
                  {capitalize(value)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
      }
      filterButtons={
        <FilterButtons
          onApplyFilters={() => {
            toggleFiltersDrawer(false);
            setFilters({
              userId,
              kitchen,
              referenceId,
              status,
              orderDate: orderDate ? getDate(orderDate) : undefined,
              deliveryDate: deliveryDate ? getDate(deliveryDate) : undefined
            });
          }}
          onClearFilters={() => {
            toggleFiltersDrawer(false);
            setFilters(getDefaultFilters());
          }}
        />
      }
    />
  );
}
