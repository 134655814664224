import { ThemeProvider, createTheme } from '@mui/material';

const InputThemeProvider = ({ children }: { children: React.ReactNode }) => {
  const theme = createTheme({
    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: '#57ae7f !important' // Focused border color
            }
          },
          notchedOutline: {
            borderColor: '#ccc' // Default border color
          },
          input: {
            '&:-webkit-autofill': {
              transitionDelay: '9999s',
              transitionProperty: 'background-color, color'
            }
          }
        }
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            borderRadius: '4px !important' // Rounded corners
          }
        }
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            color: '#ccc', // Default label color
            '&.Mui-focused': {
              color: '#57ae7f !important' // Label color when focused
            }
          }
        }
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            '&:hover': {
              backgroundColor: '#24A1700A !important' // Hover color for options
            },
            '&.Mui-selected': {
              backgroundColor: '#24A1700A !important', // Background color for selected option
              '&:hover': {
                backgroundColor: '#24A1700A !important' // Ensure hover doesn't override selected color
              }
            }
          }
        }
      },
      MuiAutocomplete: {
        styleOverrides: {
          paper: {
            '& .MuiAutocomplete-option': {
              backgroundColor: '#fff' // Default option background color
            },
            '& .MuiAutocomplete-option:hover': {
              backgroundColor: '#24A1700A !important' // Hover color
            },
            '& .MuiAutocomplete-option[aria-selected="true"]': {
              backgroundColor: '#24A1700A !important' // Selected color
            }
          }
        }
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            color: '#ccc', // Default checkbox color
            '&.Mui-checked': {
              color: '#24A170 !important' // Green color for checked state
            }
          }
        }
      }
    }
  });

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default InputThemeProvider;
