import { FoodDietType, FoodType } from '@calo/types';
import PhoneDatePicker from 'components/PhoneDatePicker';
import ScrollableSection from 'components/ScrollableSection';
import ToggleButtonGroup from 'components/ToggleButtonGroup';
import { MenuFilterType } from 'lib/enums';

interface FilteredFood {
  id: string;
  name: string;
  tags: (FoodDietType | string)[];
  macros: {
    cal: number;
    carbs: number;
    fat: number;
    protein: number;
    fiber: number;
  };
  type: FoodType[];
}

interface FilteredMenu {
  id: string;
  food: FilteredFood[];
}

interface ModalHeaderProps {
  weeklyMenu: FilteredMenu[];
  handleDateSelect: (selectedDate: string) => void;
  selectedMealType: MenuFilterType;
  handleMealTypeToggle: (mealType: MenuFilterType) => void;
}

const ModalHeader = ({ weeklyMenu, handleDateSelect, selectedMealType, handleMealTypeToggle }: ModalHeaderProps) => {
  return (
    <>
      <ScrollableSection xScrollable scrollSpeed={1.5}>
        <PhoneDatePicker dates={weeklyMenu.map((menu) => menu.id)} onDateSelect={handleDateSelect} />
      </ScrollableSection>
      <ToggleButtonGroup
        label1={MenuFilterType.Breakfast}
        label2={MenuFilterType.LunchDinner}
        label3={MenuFilterType.Snack}
        selectedValue={selectedMealType}
        onChange={(value) => handleMealTypeToggle(value as MenuFilterType)}
      />
    </>
  );
};

export default ModalHeader;
