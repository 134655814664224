import { createPrototypeIngredient } from 'actions';
import { FormOperation, Routes } from 'lib/enums';
import { useEffect } from 'react';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import useIngredientForm from './useIngredientForm';

import { Permission } from '@calo/dashboard-types';
import { Disclaimer } from 'components';
import { AllergenCategoryCard, CostCard, IngredientHeaderCard, MeasurementGuideAndMacrosCard } from 'components/IngredientShared';
import { calculateCalories } from 'lib/helpers';
import { useUserRoles } from 'lib/hooks';
import { IngredientFormData } from 'lib/interfaces';
import IngredientInformationCard from 'views/Food/NewIngredient/IngredientInformationCard';

const NewIngredient = () => {
  const history = useHistory();
  const roles = useUserRoles();
  const { mutateAsync: createMutation } = useMutation(createPrototypeIngredient);

  const handleNewIngredient = async (values: IngredientFormData) => {
    const ingredient = await createMutation(values);
    history.replace(Routes.playgroundIngredient.replace(':id', ingredient.id));
  };

  const { values, isSubmitting, isValid, dirty, touched, errors, handleBlur, handleSubmit, handleChange, setFieldValue } =
    useIngredientForm(handleNewIngredient);

  useEffect(() => {
    setFieldValue('macros.cal', calculateCalories(values.macros));
  }, [values.macros.carbs, values.macros.fat, values.macros.protein]);

  return (
    <>
      <IngredientHeaderCard
        disabled={!roles.includes(Permission.CREATE_PROTOTYPE_INGREDIENT) || !dirty || !isValid || isSubmitting}
        operation={FormOperation.create}
        handleSubmit={handleSubmit}
      />
      <Disclaimer type="ingredient" sx={{ marginTop: '20px', marginBottom: 0 }} />
      <IngredientInformationCard
        disabled={!roles.includes(Permission.CREATE_PROTOTYPE_INGREDIENT)}
        errors={errors}
        values={values}
        touched={touched}
        handleBlur={handleBlur}
        handleChange={handleChange}
        setFieldValue={setFieldValue}
      />
      <AllergenCategoryCard values={values} setFieldValue={setFieldValue} />
      <MeasurementGuideAndMacrosCard
        disabled={!roles.includes(Permission.CREATE_PROTOTYPE_INGREDIENT)}
        values={values}
        errors={errors}
        handleBlur={handleBlur}
        handleChange={handleChange}
        setFieldValue={setFieldValue}
      />
      <CostCard values={values} errors={errors} operation={FormOperation.create} handleChange={handleChange} />
    </>
  );
};

export default NewIngredient;
