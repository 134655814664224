import { Brand, RetailBranch } from '@calo/types';
import { getListWithParams } from 'actions/index';
import { simpleMutation } from 'actions/mutation';
import { updateRetailOrderItems, updateRetailOrderStatus } from 'actions/retail';
import { format } from 'date-fns';
import { Routes } from 'lib/enums';
import { useUserData } from 'lib/hooks';
import queryClient from 'lib/queryClient';
import debounce from 'lodash/debounce';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { generatePath } from 'react-router-dom';
import { baristaEmailMapping, notificationActions } from 'views/Retail/constant';
import notificationSound from '../../../../assets/audio/notification-sound.mp3';
import { PickUpStatus, RetailFilter } from '../../types';

const audio = new Audio(notificationSound);
audio.loop = true;

const initialCarHopDetails = {
  orderId: '',
  name: '',
  phoneNumber: '',
  vehiclePlateNumber: '',
  pickUpAt: '',
  pickUpId: ''
};

interface CarHopDetails {
  orderId: string;
  name: string;
  phoneNumber: string;
  vehiclePlateNumber: string;
  pickUpAt: string;
  pickUpId: string;
}

interface UseRetailOrderProps {
  history: {
    push: (path: string | { pathname: string; search: string }) => void;
  };
  location: any;
}

export const useRetailOrder = ({ history, location }: UseRetailOrderProps) => {
  const searchParams = new URLSearchParams(location.search);
  const user = useUserData();
  const [filters, setFilters] = useState<RetailFilter>({
    brand: Brand.CALO,
    branch: baristaEmailMapping[user.email]?.[0] ?? RetailBranch.SEEF,
    ...JSON.parse(searchParams.get('filters') || '{}')
  });

  const [notification, setNotification] = useState<{ open: boolean; title: string }>({
    open: false,
    title: ''
  });
  const [isPlaying, setIsPlaying] = useState(false);
  const [isUserInteracted, setIsUserInteracted] = useState(false);
  const [soundTest, setSoundTest] = useState(false);
  const [needReEnableAudio, setNeedReEnableAudio] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [loadingItemIds, setLoadingItemIds] = useState<string[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const ordersPerPage = 10;
  const [order, setOrder] = useState<'asc' | 'desc'>('desc');
  const [orderBy, setOrderBy] = useState<'createdAt' | 'pickUpAt'>('createdAt');
  const [dateError, setDateError] = useState(false);
  const [carHopDetails, setCarHopDetails] = useState<CarHopDetails>(initialCarHopDetails);

  useEffect(() => {
    const path = generatePath(Routes.retailOrderList, { brand: filters.brand, branch: filters.branch });
    history.push(path);
    searchParams.set('filters', JSON.stringify(filters));
    history.push({
      pathname: location.pathname,
      search: searchParams.toString()
    });
  }, [filters.brand, filters.branch, history, location.pathname]);

  const handleUserInteraction = () => {
    setIsUserInteracted(true);
    setSoundTest(true);
    setNeedReEnableAudio(false);

    audio
      .play()
      .then(() => {
        setIsPlaying(true);
      })
      .catch((error) => {
        console.error('Error playing sound:', error);
      });
  };

  const startPlayingSound = () => {
    if (!isPlaying) {
      audio
        .play()
        .then(() => {
          setIsPlaying(true);
        })
        .catch((error) => {
          console.error('Error playing sound:', error);
          setNotification((prev) => ({ ...prev, open: true }));
        });
    }
  };

  const stopPlayingSound = () => {
    if (isPlaying) {
      audio.pause();
      audio.currentTime = 0;
      setIsPlaying(false);
    }
  };
  const resetBranchData = () => {
    setCurrentBranch(filters.branch);
    setCurrentPage(0);
    setOrderList([]);
    setPaginationToken(null);
    refetch();
  };

  const handleServiceWorkerMessage = (event: any) => {
    const { data } = event;
    if (data.action === notificationActions.CUSTOMER_ARRIVED) {
      showCarHopOrderPopup(data.body);
    } else if (data.action === notificationActions.NEW_RETAIL_ORDER) {
      startPlayingSound();
      resetBranchData();
      setNotification({ title: data.title || '', open: true });
    }
  };

  const showCarHopOrderPopup = (orderId: string) => {
    const order = orderList.find((order) => order.orderId === orderId);
    if (!order) return;

    const { name, phoneNumber } = order.user;
    const { vehiclePlateNumber, pickUpAt, id } = order.pickUp;
    const carHopOrder = { orderId, name, phoneNumber, vehiclePlateNumber, pickUpAt, pickUpId: id };
    startPlayingSound();
    setCarHopDetails(carHopOrder);
  };

  const resetCarHopDetails = () => {
    setCarHopDetails(initialCarHopDetails);
    stopPlayingSound();
  };

  useEffect(() => {
    const script1 = document.createElement('script');
    script1.src = 'https://cdn.onesignal.com/sdks/web/v16/OneSignalSDK.page.js';
    script1.defer = true;
    document.body.appendChild(script1);

    const script2 = document.createElement('script');
    script2.innerHTML = `
      window.OneSignalDeferred = window.OneSignalDeferred || [];
      window.OneSignalInitialized = window.OneSignalInitialized || false;
      OneSignalDeferred.push(async function(OneSignal) {
        const filters = ${JSON.stringify(filters)};
        if (!window.OneSignalInitialized) {
          await OneSignal.init({
            appId: '${process.env.REACT_APP_ONE_SIGNAL_APP_ID}'
          });
          window.OneSignalInitialized = true;
        }
        OneSignal.User.addTags({ ['retail-staff-' + filters.branch]: 'true' });
      });
    `;
    document.body.appendChild(script2);

    navigator.serviceWorker.addEventListener('message', handleServiceWorkerMessage);

    return () => {
      document.body.removeChild(script1);
      document.body.removeChild(script2);
      navigator.serviceWorker.removeEventListener('message', handleServiceWorkerMessage);
    };
  }, [filters.branch, handleServiceWorkerMessage]);

  const [paginationToken, setPaginationToken] = useState<string | null>(null);
  const { isLoading, refetch, isFetching, error } = useQuery<any, Error, { pickUps: any[]; token?: string }>(
    ['/retail/orders', { branch: filters.branch, token: paginationToken }],
    getListWithParams,
    {
      suspense: false,
      initialData: { pickUps: [], token: null },
      onSuccess: (newData) => {
        if (newData?.token && newData?.token !== paginationToken) {
          setPaginationToken(newData?.token);
        }
        if (newData.pickUps.length > 0) {
          setOrderList((prevOrderList) => [...prevOrderList, ...newData.pickUps]);
        } else {
          const data = queryClient.getQueryData(['/retail/orders', { branch: filters.branch, token: paginationToken }]) as {
            pickUps: any[];
            token: string;
          };
          console.log({ data });
          setOrderList((prevOrderList) => [...prevOrderList, ...data.pickUps]);
        }
      }
    }
  );
  const isUnauthorized = (error as any)?.response?.status === 401;

  const [orderList, setOrderList] = useState<any[]>([]);

  const handleChangeStatus = async (pickUpId: string, status: PickUpStatus, reason?: string) => {
    if (orderList && orderList.length > 0 && !loadingItemIds.includes(pickUpId)) {
      const oldList = orderList?.filter((order) => order.pickUp.id !== pickUpId) ?? [];
      const currentItem = orderList?.find((order) => order.pickUp.id === pickUpId);
      try {
        setLoadingItemIds((prev) => [...prev, pickUpId]);
        const newList = [
          ...oldList,
          { ...currentItem, pickUp: { ...currentItem.pickUp, status, cancellationReason: reason } }
        ].sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
        simpleMutation(['/retail/orders', { branch: filters.branch }], newList);
        setOrderList(newList);
        await updateRetailOrderStatus(pickUpId, status, reason);
      } catch (error) {
        console.log(error);
        const newList = [...oldList, currentItem].sort(
          (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        );
        simpleMutation(['/retail/orders', { branch: filters.branch }], newList);
        setOrderList(newList);
      } finally {
        setLoadingItemIds((prev) => prev.filter((id) => id !== pickUpId));
      }
    }
  };

  const handleItemUpdate = async ({ orderId, items, orderNo }: { orderId: string; items: any; orderNo: string }) => {
    try {
      const updatedOrder = await updateRetailOrderItems({ orderId, items, orderNo });
      const newOrderList = orderList.map((order) => (order.id === orderId ? { ...order, items: updatedOrder.items } : order));
      setOrderList(newOrderList);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSoundTestConfirmation = () => {
    stopPlayingSound();
    setSoundTest(false);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const searchOrder = useMemo(
    () => (order: any, query: string) => {
      if (!query) return true;

      const searchTerm = query.toLowerCase();

      if (order.orderId.toLowerCase().includes(searchTerm)) return true;
      if (order.user.name.toLowerCase().includes(searchTerm)) return true;
      if (order.user.phoneNumber.includes(searchTerm)) return true;
      if (
        order.items.some(
          (item: any) =>
            item.name.en.toLowerCase().includes(searchTerm) || (item.notes && item.notes.toLowerCase().includes(searchTerm))
        )
      )
        return true;
      if (order.notes && order.notes.toLowerCase().includes(searchTerm)) return true;

      return false;
    },
    []
  );

  const handleSearchChange = useMemo(
    () =>
      debounce((value: string) => {
        setSearchQuery(value);
      }, 300),
    []
  );

  const filterOrdersByStatus = useCallback(
    (status: PickUpStatus) => {
      return (
        orderList?.filter(
          (order) =>
            order.pickUp.status === status &&
            (!selectedDate || format(new Date(order.createdAt), 'yyyy-MM-dd') === format(selectedDate, 'yyyy-MM-dd')) &&
            searchOrder(order, searchQuery)
        ) || []
      );
    },
    [orderList, selectedDate, searchOrder, searchQuery]
  );

  const handleClearFilters = useCallback(() => {
    const defaultFilters: RetailFilter = {
      country: filters.country,
      brand: filters.brand,
      branch: RetailBranch.SEEF
    };
    setFilters(defaultFilters);
    setSelectedDate(null);
  }, [filters.country, filters.brand]);

  useEffect(() => {
    return () => {
      handleSearchChange.cancel();
    };
  }, [handleSearchChange]);

  const handleRequestSort = (property: 'createdAt' | 'pickUpAt') => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleDateChange = (date: Date | null) => {
    if (date && isNaN(date.getTime())) {
      setDateError(true);
    } else {
      setDateError(false);
      setSelectedDate(date);
    }
  };

  const getOrdersForCurrentTab = () => {
    switch (selectedTab) {
      case 0:
        return filterOrdersByStatus(PickUpStatus.CREATED);
      case 1:
        return [...filterOrdersByStatus(PickUpStatus.READY_FOR_PICK_UP), ...filterOrdersByStatus(PickUpStatus.BARISTA_EN_ROUTE)];
      case 2:
        return filterOrdersByStatus(PickUpStatus.PICKED_UP);
      case 3:
        return filterOrdersByStatus(PickUpStatus.CANCELLED);
      default:
        return [];
    }
  };

  const orders = getOrdersForCurrentTab();

  const sortedOrders = orders.sort((a, b) => {
    const orderMultiplier = order === 'asc' ? 1 : -1;
    if (orderBy === 'createdAt') {
      return orderMultiplier * (new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());
    }
    if (orderBy === 'pickUpAt') {
      return orderMultiplier * (new Date(a.pickUp.pickUpAt).getTime() - new Date(b.pickUp.pickUpAt).getTime());
    }
    return 0;
  });

  const getOrdersForCurrentPage = () => {
    const startIndex = currentPage * ordersPerPage;
    const endIndex = startIndex + ordersPerPage;
    return sortedOrders.slice(startIndex, endIndex);
  };

  const paginatedOrders = getOrdersForCurrentPage();

  const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    setCurrentPage(page - 1);
  };

  const [currentBranch, setCurrentBranch] = useState(filters.branch);

  useEffect(() => {
    if (currentBranch !== filters.branch) {
      resetBranchData();
    }
  }, [currentBranch, filters.branch]);

  return {
    filters,
    setFilters,
    notification,
    setNotification,
    isUserInteracted,
    soundTest,
    needReEnableAudio,
    selectedTab,
    selectedDate,
    setSelectedDate,
    orderList,
    isLoading,
    isFetching,
    isUnauthorized,
    loadingItemIds,
    handleUserInteraction,
    stopPlayingSound,
    handleChangeStatus,
    handleItemUpdate,
    handleSoundTestConfirmation,
    handleTabChange,
    filterOrdersByStatus,
    handleClearFilters,
    searchQuery,
    handleSearchChange,
    currentPage,
    setCurrentPage,
    order,
    setOrder,
    orderBy,
    setOrderBy,
    handleRequestSort,
    paginatedOrders,
    handlePageChange,
    handleDateChange,
    dateError,
    carHopDetails,
    resetCarHopDetails
  };
};
