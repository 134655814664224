import { Box, LinearProgress, Stack, Typography } from '@mui/material-v6';
import { caloTheme } from 'assets/images/theme/calo';
import { Icon } from '..';

const CaloLoader = () => (
  <Box display="flex" flexDirection="row" my={3} justifyContent="center">
    <Stack>
      <Typography display="flex" flexDirection="column" sx={{ width: '120px', height: '48px' }}>
        <Icon name="calo" size={12} className="w-64 -ml-24 " />
      </Typography>
      <LinearProgress
        color="inherit"
        value={80}
        sx={{ mt: 2 }}
        style={{ color: caloTheme.palette.primary500, backgroundColor: caloTheme.palette.primary50 }}
      />
    </Stack>
  </Box>
);

export default CaloLoader;
