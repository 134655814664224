import { useEffect, useMemo, useState } from 'react';

import { differenceInDays } from 'date-fns';
import { format, parseISO } from 'date-fns/fp';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useInfiniteQuery } from 'react-query';
import { useLocation } from 'react-router-dom';

import { GetRefundsReq, Permission, RefundFilters } from '@calo/dashboard-types';
import { Country } from '@calo/types';

import { exportRefundsV2 } from 'actions';
import { Button } from 'components';
import client from 'lib/client';
import { refundReasonsHelper } from 'lib/helpers/refundReasonsHelper';
import history from 'lib/history';
import { useUserRoles } from 'lib/hooks';
import Settings from '../Refunds/Settings';
import { PaymentTransaction } from 'lib/calo-types';

const getRefundReaonsValue = (reason: string) => {
  const refundReasonObj = refundReasonsHelper.getObject();
  return refundReasonObj[reason] || reason;
};

const Refunds = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [filters, setFilters] = useState<RefundFilters>({
    country: Country.BH,
    ...JSON.parse(searchParams.get('filters') || `{}`)
  });
  const [exportDate, setExportDate] = useState<boolean>(false);

  const roles = useUserRoles();

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading } = useInfiniteQuery<{
    data: PaymentTransaction[];
    meta: GetRefundsReq;
  }>(
    ['/v2/refunds', filters],
    async ({ pageParam, queryKey }) => {
      const { data } = await client.get(queryKey[0] as string, {
        params: {
          ...(pageParam && {
            cursor: pageParam
          }),
          filters: queryKey[1]
        }
      });
      return data;
    },
    {
      getNextPageParam: (data) => data.meta?.cursor,
      onSuccess: () => {
        searchParams.set('filters', JSON.stringify(filters));
        history.push({
          pathname: location.pathname,
          search: searchParams.toString()
        });
      }
    }
  );

  const refunds = useMemo(
    () =>
      (data?.pages || []).reduce<PaymentTransaction[]>((res, r) => {
        res = [...res, ...(r.data || [])];
        return res;
      }, []),
    [data]
  );

  useEffect(() => {
    if (
      filters.day &&
      (!isNaN(filters.day.gte) || !isNaN(filters.day.gte)) &&
      differenceInDays(parseISO(format('yyyy-MM-dd')(filters?.day!.lte)), parseISO(format('yyyy-MM-dd')(filters?.day!.gte))) <= 31
    ) {
      setExportDate(true);
    } else {
      setExportDate(false);
    }
  }, [filters.day]);

  return (
    <InfiniteScroll
      dataLength={refunds.length || 0}
      next={fetchNextPage}
      hasMore={!!hasNextPage}
      loader={null}
      scrollableTarget="scrollable"
    >
      <section className="section is-title-bar">
        <div className="level">
          <div className="level-left">
            <div className="level-item">
              <ul>
                <li>Refunds</li>
              </ul>
            </div>
          </div>
          <div className="level-right">
            <div className="level-item">
              {roles.includes(Permission.EXPORT_REFUND_LIST) && (
                <span className="mr-4">
                  <Button
                    onClick={() => exportRefundsV2(filters)}
                    disabled={!filters.day || !exportDate || refunds?.length === 0}
                    icon="fas fa-file-export"
                    className=" has-tooltip-danger has-tooltip-left"
                    data-tooltip={!filters.day || !exportDate ? 'Date should be maximum 31 Days' : undefined}
                  />
                </span>
              )}
            </div>
          </div>
        </div>
      </section>
      <section>
        {refunds && refunds.length === 0 && !isLoading ? (
          <span className="absolute w-full text-3xl mt-4 text-center font-bold text-gray-400 ">NO REFUNDS</span>
        ) : (
          <>
            <div className="card has-table has-table-container-upper-radius">
              <div className="card-content">
                <div className="table-container overflow-y-auto">
                  <table className="table is-fullwidth is-striped is-hoverable is-sortable">
                    <thead>
                      <tr className="bg-black">
                        <th style={{ color: 'white' }}>ID</th>
                        <th style={{ color: 'white' }}>Status</th>
                        <th style={{ color: 'white' }}>Name</th>
                        <th style={{ color: 'white' }}>Phone Number</th>
                        <th style={{ color: 'white' }}>CreatedBy</th>
                        <th style={{ color: 'white' }}>Amount</th>
                        <th style={{ color: 'white' }}>Currency</th>
                        <th style={{ color: 'white' }}>Kitchen</th>
                        <th style={{ color: 'white' }}>Created at</th>
                        <th style={{ color: 'white' }}>Refunded at</th>
                        <th style={{ color: 'white' }}>User reason</th>
                        <th style={{ color: 'white' }}>Internal</th>
                        <th style={{ color: 'white' }}>Payment Provider</th>
                      </tr>
                    </thead>
                    <tbody>
                      {refunds.map((refund) => (
                        <tr key={refund.id}>
                          <td>{refund.pspRefundId || refund.pspChargeId || refund.metadata?.pspRefundId}</td>
                          <td>{refund.status}</td>
                          <td>{refund.name}</td>
                          <td>{refund.phoneNumber}</td>
                          <td>{refund.actor}</td>
                          <td>{refund.amount}</td>
                          <td>{refund.currency}</td>
                          <td>{String(refund.kitchen)}</td>
                          <td>{format('yyyy-MM-dd')(parseISO(refund.createdAt))}</td>
                          <td>{refund.createdAt && format('yyyy-MM-dd')(parseISO(refund.createdAt))}</td>
                          <td>{refund.notes}</td>
                          <td>{getRefundReaonsValue(refund.action)}</td>
                          <td>{refund.paymentProvider}</td>
                        </tr>
                      ))}
                    </tbody>
                    {refunds.length >= 25 && (
                      <tfoot>
                        <th>ID</th>
                        <th>Status</th>
                        <th>Name</th>
                        <th>Phone Number</th>
                        <th>CreatedBy</th>
                        <th>Amount</th>
                        <th>Currency</th>
                        <th>Kitchen</th>
                        <th>Created at</th>
                        <th>Refunded at</th>
                        <th>User reason</th>
                        <th>Internal</th>
                        <th>Payment Provider</th>
                      </tfoot>
                    )}
                  </table>
                  {!!hasNextPage && (
                    <div className="flex justify-center pb-3">
                      <Button onClick={() => fetchNextPage()} content="Load more" loading={isFetchingNextPage} />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
        <Settings onFilter={setFilters} filters={filters} />
      </section>
    </InfiniteScroll>
  );
};

export default Refunds;
