import { Brand, Country, Kitchen } from '@calo/types';
import { format, getWeek } from 'date-fns';
import { getYearBasedOnWeek, resolveCountryFromKitchen } from 'lib';
import { MenuPresentationType } from 'lib/enums';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { MenuListFilters, MenuPathUpdateFunctions } from '../../types';

export const useMenuFilters = (): [MenuListFilters, MenuPathUpdateFunctions] => {
  const history = useHistory();

  const { brand, country, kitchen, menuPresentation, day } = useParams<{
    brand: string;
    country: string;
    kitchen: string;
    menuPresentation: MenuPresentationType;
    day: string;
  }>();

  const defaultWeek = menuPresentation === MenuPresentationType.daily ? getWeek(new Date(), { weekStartsOn: 0 }) : Number(day);
  const defaultYear = getYearBasedOnWeek(defaultWeek);
  const [selectedWeek, setSelectedWeek] = useState(defaultWeek);
  const [selectedYear, setSelectedYear] = useState(defaultYear);
  const [selectedDay, setSelectedDay] = useState(format(new Date(), 'yyyy-MM-dd'));

  useEffect(() => {
    const dayOrWeek = menuPresentation === MenuPresentationType.daily ? format(new Date(), 'yyyy-MM-dd') : getWeek(new Date());
    if (typeof dayOrWeek === 'string') {
      setSelectedDay(dayOrWeek);
    } else {
      setSelectedWeek(Number(dayOrWeek));
      setSelectedYear(getYearBasedOnWeek(dayOrWeek));
    }
  }, [menuPresentation]);

  useEffect(() => {
    if (Number.isNaN(Number(day))) {
      setSelectedDay(day);
    } else {
      setSelectedWeek(Number(day));
      setSelectedYear(getYearBasedOnWeek(Number(day)));
    }
  }, [day]);

  useEffect(() => {
    if (Number.isNaN(Number(day))) {
      setSelectedDay(day);
    } else {
      setSelectedWeek(Number(day));
      setSelectedYear(getYearBasedOnWeek(Number(day)));
    }
  }, [day]);

  // Derive filters directly from URL parameters
  const filters: MenuListFilters = {
    country: (country ?? resolveCountryFromKitchen(kitchen as Kitchen)) as Country,
    brand: (brand as Brand) ?? Brand.CALO,
    kitchen: (kitchen as Kitchen) ?? Kitchen.BH1,
    menuPresentation: MenuPresentationType.daily,
    selectedDay: format(new Date(), 'yyyy-MM-dd'),
    selectedWeek: getWeek(new Date(), { weekStartsOn: 0 }),
    selectedYear
  };

  // Functions to update URL path parameters
  const setBrand = (newBrand: Brand) => {
    history.push(`/${newBrand}/${filters.country}/${filters.kitchen}/menu/${menuPresentation}/${day}`);
  };

  const setKitchen = (newKitchen: Kitchen) => {
    const newCountry = resolveCountryFromKitchen(newKitchen);
    history.push(`/${filters.brand}/${newCountry}/${newKitchen}/menu/${menuPresentation}/${day}`);
  };

  const setMenuPresentation = (newPresentation: MenuPresentationType) => {
    const dayOrWeek =
      newPresentation === MenuPresentationType.daily
        ? format(new Date(), 'yyyy-MM-dd')
        : getWeek(new Date(), { weekStartsOn: 0 });

    history.push(`/${brand}/${country}/${kitchen}/menu/${newPresentation}/${dayOrWeek}`);
  };

  const setDay = (newDay: string) => {
    history.push(`/${brand}/${country}/${kitchen}/menu/${menuPresentation}/${newDay}`);
  };

  const setWeek = (newWeek: number) => {
    history.push(`/${brand}/${country}/${kitchen}/menu/${menuPresentation}/${newWeek}`);
  };

  const setYear = (newYear: number) => {
    setSelectedYear(newYear);
  };

  return [
    {
      brand: filters.brand,
      country: filters.country,
      kitchen: filters.kitchen,
      menuPresentation: menuPresentation,
      selectedDay: selectedDay,
      selectedWeek: selectedWeek,
      selectedYear
    },
    { setBrand, setKitchen, setMenuPresentation, setDay, setWeek, setYear }
  ];
};
