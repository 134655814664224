import { useEffect, useRef, useState } from 'react';

import { FormikErrors } from 'formik';

import { CustomMealCategory, FoodComponentType } from '@calo/types';
import AddIcon from '@mui/icons-material/Add';
import {
  Button,
  Card,
  Divider,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';

import { ModalRef } from '../..';
import { caloTheme } from '../../../assets/images/theme/calo';
import { BaseOmit, FoodComponent } from '../../../lib/interfaces';
import InputThemeProvider from '../../MUI/InputThemeProvider';
import AddSectionPopup from './AddSectionPopup';
import CustomSectionRow from './CustomSectionRow';

interface CustomSectionProps {
  isDisabled?: boolean;
  values: Omit<FoodComponent, BaseOmit>;
  errors: FormikErrors<Omit<FoodComponent, BaseOmit>>;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<FormikErrors<Omit<FoodComponent, BaseOmit>>> | Promise<any>;
}

const CustomSectionCard = ({ values, errors, isDisabled, setFieldValue }: CustomSectionProps) => {
  const addSectionRef = useRef<ModalRef>();

  const [selectedCategories, setSelectedCategories] = useState(values.sections?.map((section) => section.category) || []);

  const handleClosePopup = () => {
    addSectionRef.current?.close();
    setSelectedCategories(values.sections?.map((section) => section.category) ?? []);
  };

  const removeSectionHandler = (category: CustomMealCategory) => {
    if (!values.sections) {
      return;
    }

    const newSections = values.sections.filter((section) => section.category !== category);
    setFieldValue('sections', newSections);
  };

  const toggleSectionAvailability = (category: CustomMealCategory) => {
    if (!values.sections) {
      return;
    }

    const newSections = values.sections.map((section) => {
      if (section.category === category) {
        return { ...section, availability: !section.availability };
      }
      return section;
    });

    setFieldValue('sections', newSections);
  };

  const updateQuantityHandler = (category: CustomMealCategory, quantity: number) => {
    if (!values.sections) {
      return;
    }

    const newSections = values.sections.map((section) => {
      if (section.category === category) {
        return { ...section, quantity };
      }
      return section;
    });

    setFieldValue('sections', newSections);
  };

  const selectTagHandler = (category: CustomMealCategory, tag: FoodComponentType) => {
    if (!values.sections) {
      return;
    }

    const newSections = values.sections.map((section) => {
      if (section.category === category) {
        return { ...section, tag };
      }
      return section;
    });

    setFieldValue('sections', newSections);
  };

  useEffect(() => {
    setSelectedCategories(values.sections?.map((section) => section.category) ?? []);
  }, [values.sections]);

  return (
    <>
      <Card
        variant="outlined"
        sx={{
          marginTop: '16px',
          border: 'none',
          borderRadius: '8px',
          boxShadow: 2
        }}
      >
        <Stack
          sx={{ padding: '16px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}
        >
          <Typography sx={{ fontSize: '24px', fontWeight: 400, lineHeight: '150%' }}>Multi - Custom Meals</Typography>
          <Button
            data-test="addSectionButton"
            startIcon={<AddIcon />}
            onClick={() => addSectionRef.current?.open()}
            sx={{
              textTransform: 'none',
              color: caloTheme.palette.neutral900,
              fontWeight: 600,
              fontSize: '16px',
              '&:hover': {
                backgroundColor: 'transparent'
              }
            }}
          >
            Add Section
          </Button>
        </Stack>
        <Divider sx={{ color: caloTheme.palette.divider, height: '1px', width: '100%' }} />
        <InputThemeProvider>
          <Paper sx={{ padding: '24px 16px 16px 16px', width: '100%', mt: 0, boxShadow: 'none' }}>
            <TableContainer sx={{ overflow: 'hidden' }}>
              <Table sx={{ width: '100%' }}>
                <TableHead sx={{ backgroundColor: caloTheme.palette.neutral50, border: 0 }}>
                  <TableRow>
                    <TableCell
                      sx={{
                        border: 0,
                        fontWeight: 600,
                        lineHeight: '14px',
                        fontSize: '12px',
                        minWidth: '20%',
                        width: '25%',
                        borderRadius: '8px 0 0 8px'
                      }}
                    >
                      Section
                    </TableCell>
                    <TableCell
                      sx={{ border: 0, fontWeight: 600, lineHeight: '14px', fontSize: '12px', minWidth: '30%', width: '25%' }}
                    >{`Quantity (per measurement unit)`}</TableCell>
                    <TableCell
                      sx={{ border: 0, fontWeight: 600, lineHeight: '14px', fontSize: '12px', minWidth: '30%', width: '40%' }}
                    >
                      {`Tag`}
                    </TableCell>
                    <TableCell
                      sx={{
                        border: 0,
                        fontWeight: 600,
                        lineHeight: '14px',
                        fontSize: '12px',
                        minWidth: '3%',
                        width: '3%',
                        paddingLeft: 0
                      }}
                    >
                      Availability
                    </TableCell>
                    <TableCell
                      sx={{
                        border: 0,
                        fontWeight: 600,
                        lineHeight: '14px',
                        fontSize: '12px',
                        minWidth: '2%',
                        width: '2%',
                        borderRadius: '0 8px 8px 0'
                      }}
                    ></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {values.sections?.map((section) => (
                    <CustomSectionRow
                      key={section.category}
                      section={section}
                      isDisabled={isDisabled}
                      isValid={!errors[section.category]}
                      updateQuantity={updateQuantityHandler}
                      removeSection={removeSectionHandler}
                      selectTag={selectTagHandler}
                      toggleSectionAvailability={toggleSectionAvailability}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </InputThemeProvider>
      </Card>
      <AddSectionPopup
        addSectionRef={addSectionRef}
        values={values}
        isDisabled={isDisabled}
        selectedCategories={selectedCategories}
        setSelectedCategories={setSelectedCategories}
        setFieldValue={setFieldValue}
        handleClosePopup={handleClosePopup}
      />
    </>
  );
};

export default CustomSectionCard;
