import { Stack } from '@mui/material';
import { ReactNode } from 'react';

export interface FilterItemsContainerProps {
  filterItems: ReactNode;
  filterButtons: ReactNode;
}

export default function FilterItemsContainer({ filterButtons, filterItems }: FilterItemsContainerProps) {
  return (
    <Stack sx={{ height: '100%' }} direction={'column'} justifyContent={'space-between'}>
      {filterItems}
      {filterButtons}
    </Stack>
  );
}
