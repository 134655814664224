import { FoodDietType } from '@calo/types';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';
import Balanced from 'assets/images/emojis/Balanced.svg';
import ChefsPick from 'assets/images/emojis/ChefsPick.svg';
import EmojiBag from 'assets/images/emojis/EmojiBag.svg';
import HighProtein from 'assets/images/emojis/HighProtein.svg';
import LowCarb from 'assets/images/emojis/LowCarb.svg';
import Vegetarian from 'assets/images/emojis/Vegetarian.svg';
import PhoneCardList from 'components/PhoneCardList';
import PhoneMealPlanOptions from 'components/PhoneMealPlanOptions';
import ScrollableSection from 'components/ScrollableSection';
import React from 'react';

interface PhoneCardComponentProps {
  id: string;
  name: string;
  tags: (FoodDietType | string)[];
  macros: {
    cal: number;
    carbs: number;
    fat: number;
    protein: number;
    fiber: number;
  };
}

interface ModalContentCardProps {
  cards: PhoneCardComponentProps[];
  selectedPlan: string;
  handlePlanSelect: (plan: string) => void;
}

const mealPlanOptions = [
  { icon: EmojiBag, label: 'All plans' },
  { icon: Balanced, label: 'Balanced' },
  { icon: ChefsPick, label: "Chef's picks" },
  { icon: HighProtein, label: 'High protein' },
  { icon: LowCarb, label: 'Low Carb' },
  { icon: Vegetarian, label: 'Vegetarian' }
];

const planOrder = [
  FoodDietType.balanced,
  FoodDietType.chefPicks,
  FoodDietType.highProtein,
  FoodDietType.lowCarb,
  FoodDietType.vegetarian
];

const planDisplayNames: {
  [FoodDietType.chefPicks]: string;
  [FoodDietType.highProtein]: string;
  [FoodDietType.vegetarian]: string;
  [FoodDietType.lowCarb]: string;
  [FoodDietType.balanced]: string;
} = {
  [FoodDietType.balanced]: 'Balanced',
  [FoodDietType.chefPicks]: "Chef's picks",
  [FoodDietType.highProtein]: 'High protein',
  [FoodDietType.lowCarb]: 'Low Carb',
  [FoodDietType.vegetarian]: 'Vegetarian'
};

const StyledContent = styled(Box)({
  flexGrow: 1,
  display: 'block',
  overflow: 'hidden',
  backgroundColor: '#FAFAFA',
  height: '100%',
  borderBottomRightRadius: '8px',
  borderBottomLeftRadius: '8px'
});

const ScrollableYContainer = styled(Box)({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  paddingLeft: '16px',
  paddingRight: '16px',
  paddingBottom: '120px',
  marginTop: '28px'
});

const EmptyStateContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  padding: '16px'
});

const PlanSectionTitle = styled(Typography)({
  fontWeight: 700,
  fontSize: '20px',
  lineHeight: '28px'
});

const ModalContentCard = ({ cards, selectedPlan, handlePlanSelect }: ModalContentCardProps) => {
  const getFilterType = (plan: string): FoodDietType | 'all' => {
    switch (plan.toLowerCase()) {
      case 'balanced':
        return FoodDietType.balanced;
      case "chef's picks":
        return FoodDietType.chefPicks;
      case 'high protein':
        return FoodDietType.highProtein;
      case 'low carb':
        return FoodDietType.lowCarb;
      case 'vegetarian':
        return FoodDietType.vegetarian;
      default:
        return 'all';
    }
  };

  const filterType = getFilterType(selectedPlan);

  const filteredCards =
    filterType === 'all'
      ? cards
      : filterType === FoodDietType.chefPicks
        ? cards.filter((card) => card.tags.includes(filterType) || card.tags.includes('gourmet'))
        : cards.filter((card) => card.tags.includes(filterType));

  const groupedCards =
    filterType === 'all'
      ? planOrder.reduce(
          (acc, plan) => {
            acc[plan] = cards.filter(
              (card) => card.tags.includes(plan) || (plan === FoodDietType.chefPicks && card.tags.includes('gourmet'))
            );
            return acc;
          },
          {} as Record<FoodDietType, PhoneCardComponentProps[]>
        )
      : filterType === FoodDietType.chefPicks
        ? {
            [filterType]: cards.filter((card) => card.tags.includes(filterType) || card.tags.includes('gourmet'))
          }
        : { [filterType]: filteredCards };

  return (
    <StyledContent>
      <ScrollableSection xScrollable scrollSpeed={2}>
        <PhoneMealPlanOptions options={mealPlanOptions} selectedPlan={selectedPlan} handlePlanSelect={handlePlanSelect} />
      </ScrollableSection>
      <ScrollableYContainer>
        <ScrollableSection yScrollable scrollSpeed={2}>
          {filteredCards.length === 0 ? (
            <EmptyStateContainer>
              <Typography variant="h6">No Menu for this Day</Typography>
            </EmptyStateContainer>
          ) : (
            Object.entries(groupedCards).map(
              ([plan, planCards]) =>
                planCards.length > 0 && (
                  <React.Fragment key={plan}>
                    <PlanSectionTitle>{planDisplayNames[plan as FoodDietType] || plan}</PlanSectionTitle>
                    <PhoneCardList cards={planCards} />
                  </React.Fragment>
                )
            )
          )}
        </ScrollableSection>
      </ScrollableYContainer>
    </StyledContent>
  );
};

export default ModalContentCard;
