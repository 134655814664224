import {
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { caloTheme } from 'assets/images/theme/calo';
import { CateringOrder } from 'lib/interfaces';
import CateringOrderListRow from '../CateringOrderListRow';

interface CateringOrdersListProps {
  data: CateringOrder[];
  isLoading: boolean;
}

export default function CateringOrdersList({ data, isLoading }: CateringOrdersListProps) {
  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      fontSize: '14px',
      fontWeight: 600,
      color: caloTheme.palette.neutral900,
      fontFamily: caloTheme.typography.fontFamily
    },
    [`&.${tableCellClasses.body}`]: {
      border: 'none',
      justifyContent: 'space-between'
    }
  }));

  return (
    <TableContainer component={Paper}>
      <Table
        sx={{
          overflow: 'auto',
          width: '100%',
          [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
            flexDirection: 'column'
          }
        }}
      >
        <TableHead
          sx={{
            backgroundColor: caloTheme.palette.neutral100,
            color: 'black'
          }}
        >
          <TableRow
            sx={{
              backgroundColor: caloTheme.palette.neutral100,
              color: 'black',
              width: '100%'
            }}
          >
            <StyledTableCell sx={{ maxWidth: '90px', width: '90px', minWidth: '90px' }}>Order ID</StyledTableCell>
            <StyledTableCell>User Name</StyledTableCell>
            <StyledTableCell>Quantity</StyledTableCell>
            <StyledTableCell>Price</StyledTableCell>
            <StyledTableCell>Address</StyledTableCell>
            <StyledTableCell>Order Date</StyledTableCell>
            <StyledTableCell>Delivery Date</StyledTableCell>
            <StyledTableCell>Delivery Time</StyledTableCell>
            <StyledTableCell>Status</StyledTableCell>
            <StyledTableCell sx={{ maxWidth: '220px', width: '220px', minWidth: '220px' }}>Notes</StyledTableCell>
          </TableRow>
        </TableHead>
        {data.length ? (
          <TableBody>
            {data.map((order) => (
              <CateringOrderListRow key={order.id} order={order} isLoading={isLoading} />
            ))}
          </TableBody>
        ) : (
          <StyledTableCell style={{ border: 0 }} colSpan={10}>
            <Typography
              sx={{
                textAlign: 'center',
                width: 'full',
                fontSize: '24px',
                color: caloTheme.palette.neutral400
              }}
            >
              No Catering Orders for the selected Date
            </Typography>
          </StyledTableCell>
        )}
      </Table>
    </TableContainer>
  );
}
