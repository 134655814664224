import { Button, Stack, TextField } from '@mui/material';
import { updateBusinessAccountDelivery } from 'actions/businessAccount';
import { useUserData } from 'lib/hooks';
import { CateringOrder } from 'lib/interfaces';
import { useState } from 'react';
import { useMutation } from 'react-query';
import { v4 as uuidv4 } from 'uuid';
import { InformationCard } from '../../common';
import CateringOrderNote from '../CateringOrderNote/CateringOrderNote';

export interface CateringOrderNotesProps {
  cateringOrder: CateringOrder;
}

export default function CateringOrderNotes({ cateringOrder }: CateringOrderNotesProps) {
  const user = useUserData();
  const [cateringOrderNotes, setCateringOrderNotes] = useState(cateringOrder.notes);
  const [newNote, setNewNote] = useState('');

  const { mutateAsync: updateMutation, isLoading: actionLoading } = useMutation(updateBusinessAccountDelivery);

  const deleteNote = async (id: string) => {
    let allNotes = cateringOrderNotes ?? [];
    allNotes = allNotes.filter((note) => note.id !== id);
    setCateringOrderNotes(allNotes);
    await updateMutation({
      ddId: cateringOrder.id,
      data: {
        notes: allNotes
      }
    });
  };

  const createNote = async (note: string) => {
    const allNotes = cateringOrderNotes ?? [];
    const newNote = {
      id: uuidv4(),
      name: user.name,
      note,
      createdAt: new Date().toISOString()
    };
    allNotes.push(newNote);
    setCateringOrderNotes(allNotes);

    await updateMutation({
      ddId: cateringOrder.id,
      data: {
        notes: allNotes
      }
    });
  };

  return (
    <InformationCard heading="Notes">
      <Stack rowGap={3}>
        <Stack rowGap={2}>
          {(cateringOrderNotes ?? []).map((note) => {
            return <CateringOrderNote key={note.id} note={note} deleteNote={deleteNote} actionLoading={actionLoading} />;
          })}
        </Stack>
        <Stack direction={'row'} columnGap={2.5}>
          <TextField
            sx={{ flex: 1 }}
            inputProps={{ style: { padding: '8px 10px' } }}
            placeholder="New Note"
            value={newNote}
            onChange={(e) => setNewNote(e.target.value)}
          />
          <Button
            variant="contained"
            disabled={!newNote || actionLoading}
            sx={{
              px: 4,
              color: 'white'
            }}
            onClick={() => {
              createNote(newNote);
              setNewNote('');
            }}
          >
            Add
          </Button>
        </Stack>
      </Stack>
    </InformationCard>
  );
}
