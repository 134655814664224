import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material-v6';
import { styles } from './styles';

interface ConfirmDeleteModalProps {
  isConfirmModalOpen: boolean;
  setIsConfirmModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selected: any;
  setSelected: (value: any) => void;
  handleDelete: (step: any) => void;
}

const ConfirmDeleteModal: React.FC<ConfirmDeleteModalProps> = ({
  isConfirmModalOpen,
  setIsConfirmModalOpen,
  selected,
  setSelected,
  handleDelete
}) => {
  const handleDeleteStep = () => {
    handleDelete(selected);
    setIsConfirmModalOpen(false);
  };

  const handleCancel = () => {
    setSelected('');
    setIsConfirmModalOpen(false);
  };

  return (
    <Dialog sx={styles.dialogContainer} open={isConfirmModalOpen} onClose={() => setIsConfirmModalOpen(false)}>
      <DialogTitle sx={styles.dialogTitle}>Confirm deletion</DialogTitle>
      <DialogContent>
        <Typography sx={styles.dialogContent}>Are you sure you want to delete the step as well as the image attached?</Typography>
      </DialogContent>
      <DialogActions>
        <Button data-test="sectionTagsConfirmButton" variant="contained" color="error" size="large" onClick={handleDeleteStep}>
          Delete
        </Button>
        <Button variant="text" color="inherit" size="medium" onClick={handleCancel}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDeleteModal;
