import { Box, Button, Card, Dialog, DialogActions, DialogContent, Divider, Typography } from '@mui/material';
import { format } from 'date-fns/fp';
import React from 'react';

interface CarHopOrderPopupProps {
  onClose: () => void;
  orderId: string;
  pickUpId: string;
  pickupTime: string;
  customerName: string;
  customerPhone: string;
  carDetails: string;
  handleOnMyWay: (id: string) => void;
}

const formattedTime = (pickUpAt) => {
  return pickUpAt ? format('hh:mm a')(new Date(pickUpAt)) : '--:--';
};

const CarHopOrderPopup: React.FC<CarHopOrderPopupProps> = ({
  onClose,
  orderId,
  pickupTime,
  customerName,
  customerPhone,
  carDetails,
  pickUpId,
  handleOnMyWay
}) => {
  const handleOnMyWayClick = () => {
    handleOnMyWay(pickUpId);
    onClose();
  };

  return (
    <Dialog open={Boolean(pickUpId)} onClose={onClose}>
      <DialogContent sx={{ width: '510px' }}>
        <Box>
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
            Car-hop order
          </Typography>
          <Typography sx={{ mb: 2 }} variant="body2">
            Customer is here to pick up their car-hop order.
          </Typography>
          <Card sx={{ padding: 2, backgroundColor: '#f5f5f5', boxShadow: '0px 0px 0px 0px ', borderRadius: 2 }}>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
              <Box display="flex">
                <Typography variant="body1" color="error" sx={{ fontWeight: 'bold', mr: 1 }}>
                  ●{' '}
                </Typography>
                <Typography variant="body1" sx={{ fontWeight: 'bold', color: 'black' }}>
                  Order #{orderId} customer is here!
                </Typography>
              </Box>
              <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center' }}>
                <i className="fas fa-car text-gray-500" style={{ marginRight: 4 }}></i>
                <b>Carhop</b>
              </Typography>
            </Box>
            <Divider sx={{ mt: 2, mb: 3, mx: -2 }} />
            <Typography variant="body2" sx={{ mb: 2 }}>
              Pickup time {formattedTime(pickupTime)}, {customerName}, {customerPhone}
            </Typography>
            <Typography variant="body2" sx={{ mb: 1, color: 'gray' }}>
              Car details
            </Typography>
            <Typography variant="body2" sx={{ mb: 2, fontWeight: 'bold' }}>
              {carDetails}
            </Typography>
          </Card>
        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'flex-end', mx: 2, mb: 2 }}>
        <Button onClick={onClose} size="small" sx={{ color: 'black' }}>
          Go Back
        </Button>
        <Button onClick={handleOnMyWayClick} size="small" sx={{ color: 'white' }} color="primary" variant="contained">
          On My Way
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CarHopOrderPopup;
