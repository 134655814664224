import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

import { Kitchen } from '@calo/types';
import { syncFoodComponent } from 'actions';
import SelectSyncOptions from './SelectSyncOptions';
import SuccessBox from './SuccessBox';
import ValidationResult from './ValidationResult';
import useSyncComponentForm from './useSyncComponentForm';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material-v6';
import { styles } from './styles';
import LoadingButton from '@mui/lab/LoadingButton';

interface SyncComponentPopupProps {
  id: string;
  componentName: string;
  currentKitchen: Kitchen;
  closePopup: () => void;
  showSyncComponentPopup: boolean;
}

const SyncComponentPopup = ({
  id,
  componentName,
  currentKitchen,
  closePopup,
  showSyncComponentPopup
}: SyncComponentPopupProps) => {
  const [showValidation, setShowValidation] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const { mutateAsync: syncComponents, data: syncComponentResult, isLoading } = useMutation(syncFoodComponent);

  const { handleSubmit, values, setFieldValue, setValues } = useSyncComponentForm((data) => syncComponents({ id, ...data }));

  useEffect(() => {
    if (syncComponentResult?.isSuccess) {
      setIsSuccess(true);
      toast('Component Updated Successfully', { type: 'success', delay: 3 });
    }
    if (syncComponentResult) {
      setShowValidation(true);
    }
    if (syncComponentResult?.data.length === 0) {
      setFieldValue('validate', false);
    }
  }, [syncComponentResult]);

  const handleClosePopup = () => {
    setValues({ validate: true, kitchens: [], attributes: [] });
    closePopup();
    setShowValidation(false);
    setIsSuccess(false);
  };

  const isSyncDisabled = isLoading || values.kitchens.length === 0 || values.attributes.length === 0;
  const isValidationDisabled = (syncComponentResult?.data ?? []).length !== 0;

  return (
    <Dialog sx={styles.dialogContainer} open={showSyncComponentPopup} onClose={handleClosePopup}>
      <DialogTitle sx={styles.title}>{showValidation ? 'Component status' : 'Update components across MPs'}</DialogTitle>
      <DialogContent sx={styles.dialogContentContainer(isSuccess)}>
        {isSuccess ? (
          <SuccessBox componentName={componentName} kitchens={values.kitchens} />
        ) : showValidation ? (
          <ValidationResult validationData={syncComponentResult?.data ?? []} formValues={values} />
        ) : (
          <SelectSyncOptions values={values} setFieldValue={setFieldValue} currentKitchen={currentKitchen} />
        )}
      </DialogContent>
      <DialogActions>
        {!isSuccess && (
          <LoadingButton
            variant="outlined"
            color="primary"
            size="medium"
            onClick={() => {
              handleSubmit();
            }}
            disabled={showValidation ? isValidationDisabled : isSyncDisabled}
            loading={isLoading}
          >
            {showValidation ? 'Proceed' : 'Check component status'}
          </LoadingButton>
        )}
        <Button
          variant="text"
          color={isSuccess ? 'primary' : 'inherit'}
          size="medium"
          onClick={handleClosePopup}
          disabled={isLoading}
        >
          {isSuccess ? 'Okay' : 'Close'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SyncComponentPopup;
