import { caloThemeV2 } from 'assets/themev2';
import { addonsWithinThisWeek } from 'lib/helpers';
import { MenuUsedFoodItem } from 'lib/interfaces';
import { differenceInDays, parseISO } from 'date-fns';
import { format } from 'date-fns/fp';

export const styles = {
  dialogContainer: {
    '& .MuiDialog-paper': {
      width: '30.4%',
      maxWidth: '640px'
    }
  },
  title: {
    fontSize: '20px',
    fontWeight: 500,
    lineHeight: '150%'
  },
  description: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '150%'
  },
  dialogContentContainer: {
    borderTop: `1px solid ${caloThemeV2.palette.divider}`,
    borderBottom: `1px solid ${caloThemeV2.palette.divider}`,
    display: 'flex',
    flexDirection: 'column'
  },
  mealUsedItemContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    marginTop: '16px'
  },
  mealUsedItemHeader: (menuFoodItem: MenuUsedFoodItem) => {
    let color = '';

    if (menuFoodItem.type === 'addonsMenu' && menuFoodItem.week && !addonsWithinThisWeek(menuFoodItem.week)) {
      color = caloThemeV2.palette.secondaryPurple400;
    } else if (
      (menuFoodItem.type === 'addonsMenu' && menuFoodItem.week && addonsWithinThisWeek(menuFoodItem.week)) ||
      (menuFoodItem.type === 'mainMenu' &&
        menuFoodItem.date &&
        differenceInDays(parseISO(menuFoodItem.date), parseISO(format('yyyy-MM-dd')(Date.now()))) <= 1)
    ) {
      color = caloThemeV2.palette.red100;
    } else if (
      menuFoodItem.type === 'mainMenu' &&
      menuFoodItem.date &&
      differenceInDays(parseISO(menuFoodItem.date), parseISO(format('yyyy-MM-dd')(Date.now()))) > 1
    ) {
      color = caloThemeV2.palette.yellow50;
    } else {
      color = caloThemeV2.palette.primary.main;
    }
    return {
      backgroundColor: color,
      borderColor: color,
      color: 'black',
      fontWeight: 600,
      fontSize: '14px',
      py: '10px',
      justifyContent: 'space-between',
      '&:hover': {
        backgroundColor: color,
        borderColor: color
      }
    };
  },
  mealUsedItemDescriptionContainer: {
    border: '2px solid ' + caloThemeV2.palette.grey[300],
    borderRadius: '4px',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    padding: '16px'
  },
  mealUsedItemDescription: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '150%'
  }
};
