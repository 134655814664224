import { Permission } from '@calo/dashboard-types';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { caloTheme } from 'assets/images/theme/calo';
import { Routes } from 'lib/enums';
import { useUserRoles } from 'lib/hooks';
import { FoodComponent } from 'lib/interfaces';
import { Link } from 'react-router-dom';
import { styles } from './styles';
import { CardMedia, Chip, Paper, Stack } from '@mui/material-v6';
import noImagePlaceholder from 'assets/images/noImagePlaceholder.png';
import { filterAndSortUsedOnMenu } from 'views/Food/ExactFoodComponent/helpers';

const FoodComponentListCard = ({ components }: { components: FoodComponent[] | undefined }) => {
  const userRoles = useUserRoles();

  return (
    <Paper sx={styles.tableContainer}>
      <TableContainer>
        <Table sx={{ width: '100%' }}>
          <TableHead sx={styles.tableHead}>
            <TableRow>
              <TableCell sx={styles.tableHeadText}>Component</TableCell>
              <TableCell sx={styles.tableHeadText}>Cooked weight (g)</TableCell>
              <TableCell sx={styles.tableHeadText}>Calories</TableCell>
              <TableCell sx={styles.tableHeadText}>Status</TableCell>
              <TableCell sx={styles.tableHeadText}>Precoro Cost</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {components?.map((component, index) => {
              const displayImage = `${process.env.REACT_APP_BUCKET_URL}/food-component/${component.id}/square@1x.jpg`;
              const filteredFoodUsed = filterAndSortUsedOnMenu(component);
              return (
                <TableRow key={component.id} sx={{ borderBottom: '1px solid' + caloTheme.palette.neutral100 }}>
                  <TableCell sx={{ fontSize: '14px' }}>
                    <Stack sx={styles.cardStack}>
                      <CardMedia
                        component="img"
                        image={displayImage}
                        alt="placeholder"
                        sx={styles.componentImage}
                        onError={(e: any) => {
                          e.target.src = noImagePlaceholder;
                        }}
                      />
                      {userRoles.includes(Permission.VIEW_INGREDIENT) ? (
                        <Link data-test={`foodComponentRow#${index}`} to={Routes.foodComponent.replace(':id', component.id)}>
                          <Typography sx={styles.componentName}>
                            {component.name?.en}{' '}
                            {component.name?.en === component.internalName ? '' : `(${component.internalName})`}
                          </Typography>
                        </Link>
                      ) : (
                        <Typography sx={styles.componentName}>
                          {`${component.name?.en} ${component.name?.en === component.internalName ? '' : `(${component.internalName})`}`}
                        </Typography>
                      )}
                    </Stack>
                  </TableCell>
                  <TableCell sx={styles.tableBodyCellText}>{component.weight}</TableCell>
                  <TableCell sx={styles.tableBodyCellText}>{component.macros.cal.toFixed(4)}</TableCell>
                  <TableCell>
                    <Chip
                      label={filteredFoodUsed?.length > 0 ? 'On the menu' : 'Off menu'}
                      color={filteredFoodUsed?.length > 0 ? 'primary' : 'warning'}
                      size="medium"
                      variant="outlined"
                    />
                  </TableCell>
                  <TableCell sx={styles.tableBodyCellText}>{component?.purchasingCost?.toFixed(4) ?? ''}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default FoodComponentListCard;
