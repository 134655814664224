import { FoodTagsKeys } from '@calo/dashboard-types';
import { styled, TableCell, tableCellClasses, TableRow } from '@mui/material';
import { caloTheme } from 'assets/images/theme/calo';
import { Food } from 'lib/interfaces';
import { round } from 'lodash-es';

export interface OrderedMealsRowProps {
  food: Food;
  price: number;
  quantity: number;
}

export default function OrderedMealsRow({ food, quantity, price }: OrderedMealsRowProps) {
  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.body}`]: {
      fontSize: '14px',
      fontWeight: 500,
      color: caloTheme.palette.neutral900,
      fontFamily: caloTheme.typography.fontFamily,
      border: 'none',
      verticalAlign: 'sub'
    }
  }));

  return (
    <TableRow>
      <StyledTableCell>{food.name.en}</StyledTableCell>
      <StyledTableCell>{quantity}</StyledTableCell>
      <StyledTableCell>{food.type?.join(', ')}</StyledTableCell>
      <StyledTableCell>
        {round(price ?? 0, 2)} {food.currency}
      </StyledTableCell>
      <StyledTableCell>{food.macros.cal} kcal</StyledTableCell>
      <StyledTableCell>{food.macros.protein} g</StyledTableCell>
      <StyledTableCell>{food.macros.carbs} g</StyledTableCell>
      <StyledTableCell>{food.macros.fat} g</StyledTableCell>
      <StyledTableCell>{food.foodTags?.find(({ key }) => key === FoodTagsKeys.convenience)?.value ?? 'None'}</StyledTableCell>
    </TableRow>
  );
}
