import { getListWithParams } from 'actions';
import { addDays, endOfWeek, format as formatDate, startOfWeek } from 'date-fns';
import { format } from 'date-fns/fp';
import { Menu } from 'lib/interfaces';
import { omit } from 'lodash-es';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';

const useDuplicateWeeklyMenu = (weekDates: Date[], filters: any, updateWeeklyMenuList: (newMenuList: Menu[]) => void) => {
  const [duplicateMenuDate, setDuplicateMenuDate] = useState<Date | null>(null);

  const {
    refetch: refetchWeeklyMenuList,
    isSuccess,
    isFetching: isDuplicateMenuLoading
  } = useQuery<any, Error, Menu[]>(
    [
      'menu',
      {
        brand: filters.brand,
        kitchen: filters.kitchen || undefined,
        date: {
          gte: duplicateMenuDate ? formatDate(startOfWeek(duplicateMenuDate, { weekStartsOn: 0 }), 'yyyy-MM-dd') : undefined,
          lte: duplicateMenuDate ? formatDate(endOfWeek(duplicateMenuDate, { weekStartsOn: 0 }), 'yyyy-MM-dd') : undefined
        }
      }
    ],
    getListWithParams,
    {
      enabled: false,
      onSuccess: (data) => {
        let updatedMenu: any[] = [];
        if (data.length === 0) {
          toast('Menu is empty', { type: 'error' });
          return;
        }

        for (const [i, menu] of data.entries()) {
          const newDay = format('yyyy-MM-dd')(weekDates[i % 7]);
          const updateDays = {
            ...omit(menu, 'uuid'),
            day: newDay,
            id: newDay
          };
          updatedMenu = [...updatedMenu, updateDays];
        }
        toast('Menu duplicated successfully', { type: 'success' });
        updateWeeklyMenuList(updatedMenu || []);
      },
      onError: (e) => {
        console.error('Failed to fetch weekly menu list:', e);
      }
    }
  );

  const duplicateMenu = (date: Date) => {
    setDuplicateMenuDate(date);
  };

  useEffect(() => {
    if (duplicateMenuDate) {
      refetchWeeklyMenuList();
    }
  }, [duplicateMenuDate]);

  const duplicateWeeklyMenu = (weekNumber: number, year: number) => {
    const startOfYear = new Date(year, 0, 1);
    const startOfWeekDate = addDays(startOfYear, (weekNumber - 1) * 7);
    const startOfWeekAdjusted = startOfWeek(startOfWeekDate, { weekStartsOn: 0 });
    const weekDates = Array.from({ length: 7 }, (_, i) => addDays(startOfWeekAdjusted, i));
    duplicateMenu(weekDates[0]);
  };

  return { duplicateWeeklyMenu, isLoading: isDuplicateMenuLoading, isSuccess };
};

export default useDuplicateWeeklyMenu;
