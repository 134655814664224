import { caloTheme } from 'assets/images/theme/calo';

export const styles = {
  foodName: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '150%',
    color: caloTheme.palette.primaryMain
  },
  foodImage: {
    width: 40,
    height: 40,
    objectFit: 'cover',
    borderRadius: '8px'
  },
  comment: {
    fontSize: '14px',
    display: '-webkit-box',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical'
  },
  actionButton: {
    flexDirection: 'row',
    gap: '24px'
  },
  cardStack: {
    flexDirection: 'row',
    gap: '8px',
    alignItems: 'center'
  },
  tableCellStack: {
    display: 'flex',
    flexDirection: 'row',
    gap: '4px'
  }
};
