import { parseISO } from 'date-fns';
import { format } from 'date-fns/fp';
import { flatten, unionBy, uniq, uniqBy } from 'lodash-es';
import { useEffect, useRef, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { generatePath, useParams } from 'react-router';
import { toast } from 'react-toastify';

import { Permission, UpdateMenuReq } from '@calo/dashboard-types';
import { Brand, Country, Kitchen, MenuTagValue } from '@calo/types';
import { Box, Button, Card, CircularProgress, Link, Stack, Typography } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';

import { generateMenu, getRecordWithParams, updateMenu } from 'actions';
import { FoodPicker, MenuTable, ModalRef, TrackMealsPopup } from 'components';
import AddMealsByFilterPopup from 'components/AddMealsByFilterPopup';
import Popup from 'components/Popup';
import { useFoodListByFilters } from 'hooks';
import useAddingTopRatedMeals from 'hooks/useAddingTopRatedMeals';
import useFoodList from 'hooks/useFoodList';
import { mealTracking, resolveCountry, updateFoodTags } from 'lib';
import { MenuPresentationType, Routes } from 'lib/enums';
import history from 'lib/history';
import { useUserRoles } from 'lib/hooks';
import { Food, Menu, MenuDraft, MenuFood } from 'lib/interfaces';
import { styles } from './styles';
import useMenuForm from './useMenuForm';

// to be deleted
const ExactMenu = () => {
  const { id, country, brand, kitchen } = useParams<{ id: string; country: Country; brand: Brand; kitchen: Kitchen }>();
  const { data } = useQuery([`menu`, id, { brand, kitchen: kitchen || Kitchen.BH1 }], getRecordWithParams, {
    suspense: true
  });
  const menu = data as any;
  const addMealsByFiltersRef = useRef<ModalRef>();
  const [filterName, setFilterName] = useState<string>();
  const [topRatedClicked, setTopRatedClicked] = useState(false);
  const [selectedFoods, setSelectedFoods] = useState<Food[]>([]);
  const [foodToReplace, setFoodToReplace] = useState<{ food: MenuFood }>();
  const [selectedMenuFilters, setSelectedMenuFilters] = useState({
    type: '',
    plan: '',
    protein: '',
    taste: '',
    sandwich: undefined,
    lastUsed: '',
    base: '',
    category: '',
    mealName: '',
    menuLabel: ''
  });
  const [filtersOn, setFiltersOn] = useState<boolean>(false);
  const trackMealsRef = useRef<ModalRef>();
  const [mealDraftOptions, setMealDraftOptions] = useState<MenuDraft>(menu.draft || mealTracking);

  const { foodList, foodLoading } = useFoodList({ country, brand, kitchen: menu.kitchen || kitchen, filterName });
  const { topRatedMeals, isLoadingTopRated } = useAddingTopRatedMeals({
    country,
    brand,
    kitchen,
    menuDate: id,
    clicked: topRatedClicked
  });
  const { foodListFiltersData, foodFiltersLoading, hasNextPage, fetchNextPage, remove, isLoadingFetchNextPage } =
    useFoodListByFilters({
      selectedMenuFilters,
      country,
      kitchen,
      brand,
      filtersOn
    });

  const { mutateAsync: updateMutation } = useMutation(updateMenu);
  const { mutateAsync: generateMutation } = useMutation(generateMenu);

  const path = generatePath(Routes.menuList, {
    country: menu.country,
    brand: menu.brand,
    kitchen: menu.kitchen || kitchen,
    menuPresentation: MenuPresentationType.daily,
    day: format(new Date(), 'yyyy-MM-dd')
  });
  const [generatedAt, _setGeneratedAt] = useState(menu.generatedAt);
  const [generateLoading, setGenerateLoading] = useState<boolean>(false);

  const onSubmit = async (values: UpdateMenuReq) => {
    await updateMutation(
      {
        ...values,
        id,
        mKitchen: menu.kitchen || kitchen,
        mBrand: menu.brand,
        draft: mealDraftOptions as any
      },
      {
        onSuccess: (data) => {
          const path = generatePath(Routes.menu, { brand: data.brand, kitchen: data.kitchen, id: data.id });
          history.push(path);
        }
      }
    );
  };

  const handleGenerateMenu = (values: Menu) => {
    setGenerateLoading(true);
    generateMutation(
      {
        ...values,
        id,
        kitchen: menu.kitchen ? menu.kitchen : Kitchen.BH1,
        brand: menu.brand
      },
      {
        onSuccess: () => {
          setGenerateLoading(false);
        },

        onError: (error: any) => {
          setGenerateLoading(false);
          if (error.response.status && error.response.status === 504) {
            toast('It is taking too much time, please report to the engineering team', {
              type: 'error'
            });
          }
        }
      }
    );
  };

  const { values, setFieldValue } = useMenuForm(menu!, onSubmit);
  const roles = useUserRoles();

  useEffect(() => {
    if (menu.food) {
      setSelectedFoods(menu.food);
      setFieldValue(
        'food',
        menu.food.map((r: any) => r.id)
      );
    }
  }, [menu]);

  useEffect(() => {
    if (foodToReplace) {
      addMealsByFiltersRef.current?.open();
    } else {
      addMealsByFiltersRef.current?.close();
    }
  }, [foodToReplace]);

  const handleFoodChanges = (food: Food[], tags: any) => {
    setSelectedFoods((old) => unionBy(old, food, 'id'));
    const menuDay = new Date(id).getTime();
    const updatedTags = updateFoodTags(food, tags, menuDay);
    const allFood = uniq([...food.map((f) => f.id), ...(values.food ?? [])]);
    const allTags = updatedTags.length > 0 ? uniq([...updatedTags, ...(values.tags ?? [])]) : values.tags;
    setFieldValue('food', allFood);
    setFieldValue('tags', allTags);
  };

  const handleRemoveFood = (removedIds: string[]) => {
    setSelectedFoods(selectedFoods.filter((selectedFood) => !removedIds.includes(selectedFood.id)));
    const foodList = values.food?.filter((foodId) => !removedIds.includes(foodId));
    const tagList = values.tags?.filter((tag) => tag !== null && !removedIds.includes(tag.foodId));
    setFieldValue('food', foodList);
    if (values.tags && values.tags.length > 0) {
      setFieldValue('tags', tagList);
    }
  };

  const handleReplaceFood = (selectedFood: Food[]) => {
    const menuDay = new Date(id).getTime();
    const replacedFoodSize = selectedFoods.filter((food) => food.name.en === foodToReplace?.food.name.en);
    setSelectedFoods(selectedFoods.filter((selectedFood) => !replacedFoodSize.map((r) => r.id).includes(selectedFood.id)));
    const allFood = uniq([...selectedFood.map((f) => f.id), ...(values.food ?? [])]);
    setSelectedFoods((old) => unionBy(old, selectedFood, 'id'));
    const updatedTags = values.tags?.filter((tag) => !replacedFoodSize.map((f) => f.id).includes(tag.foodId)) ?? [];
    const newTags = updateFoodTags(selectedFood, values.tags, menuDay);
    const allTags = uniq([...updatedTags, ...newTags]);
    setFieldValue('food', allFood);
    setFieldValue('tags', allTags);
    handleClosePopup();
  };

  const handleAddTopRatedMeals = async () => {
    if (isLoadingTopRated) {
      return;
    }
    if (topRatedMeals && topRatedMeals.some((food) => ['XS', 'S', 'M', 'L'].includes(food.size))) {
      const allFood = topRatedMeals.filter((food) => ['XS', 'S', 'M', 'L'].includes(food.size));
      const tags = uniqBy(
        allFood.map((f) => ({ foodId: f.id, value: [MenuTagValue.TOP_RATED] })),
        'foodId'
      );
      handleFoodChanges(allFood as any, flatten(tags));
      toast('Top rated meals successfully added!', { type: 'success', autoClose: 2000 });
      setTopRatedClicked(false);
    } else {
      toast(`No top rated meals available for ${values.country}-${values.kitchen}`, { type: 'error', autoClose: 2000 });
      setTopRatedClicked(false);
    }
  };

  useEffect(() => {
    if (topRatedClicked && topRatedMeals && !isLoadingTopRated) {
      handleAddTopRatedMeals();
    }
  }, [topRatedClicked, topRatedMeals, isLoadingTopRated]);

  const handleClosePopup = () => {
    remove();
    addMealsByFiltersRef.current?.close();
    setSelectedMenuFilters({
      type: '',
      plan: '',
      protein: '',
      taste: '',
      sandwich: undefined,
      lastUsed: '',
      base: '',
      category: '',
      mealName: '',
      menuLabel: ''
    });
    setFiltersOn(false);
    setFoodToReplace(undefined);
  };

  useEffect(() => {
    if (selectedFoods.length > 0 || (values.food && values.food?.length > 0)) {
      const menuDay = values.day ? new Date(values.day).getTime() : Date.now();
      const updatedTags = updateFoodTags(selectedFoods, values.tags, menuDay);
      const allTags = uniq([...updatedTags, ...(values.tags ?? [])]);
      setFieldValue('tags', allTags);
    }
  }, [selectedFoods]);

  return (
    <>
      <Card variant="outlined" sx={styles.card}>
        <Box sx={styles.box}>
          <Stack sx={styles.stack}>
            <Typography variant="h5" sx={styles.typography}>
              <Link href={path} style={{ textDecoration: 'none' }}>
                Menu
              </Link>{' '}
              / {menu.id}
            </Typography>
          </Stack>
          <Stack sx={styles.actionStack}>
            <Button
              variant="outlined"
              aria-label="new-subscription-cancel"
              sx={styles.outlinedButton}
              onClick={() => trackMealsRef.current?.open()}
            >
              Track Meals
            </Button>
            <Button
              variant="outlined"
              aria-label="new-subscription-cancel"
              sx={styles.outlinedButton}
              onClick={() => addMealsByFiltersRef.current?.open()}
            >
              Add Meals By Filters
            </Button>
            <Tooltip
              title="There are no top rated meals"
              placement="top"
              arrow
              disableHoverListener={
                topRatedMeals ? topRatedMeals.some((food) => ['XS', 'S', 'M', 'L'].includes(food.size)) : false
              }
            >
              <span>
                <Button
                  variant="outlined"
                  aria-label="new-subscription-cancel"
                  sx={styles.tooltipButton}
                  disabled={isLoadingTopRated}
                  onClick={() => setTopRatedClicked(true)}
                  endIcon={isLoadingTopRated ? <CircularProgress size={20} color="inherit" /> : null}
                >
                  Add Top Rated meals
                </Button>
              </span>
            </Tooltip>
            {roles.includes(Permission.GENERATE_DAY_MENU) && (
              <Stack display={'flex'} flexDirection={'column'}>
                <Button
                  variant="outlined"
                  data-test="menu-exact-generate-button"
                  sx={styles.generateButton}
                  disabled={generateLoading}
                  onClick={() => handleGenerateMenu(menu)}
                >
                  {menu.generatedAt ? 'Regenerate this day menu' : 'Generate this day menu'}
                </Button>
                {generatedAt && (
                  <p className="text-gray-300 text-xs">{`Last generated: ${format('dd-MM-yyyy')(parseISO(generatedAt))}`}</p>
                )}
              </Stack>
            )}

            <Button
              variant="contained"
              data-test="menu-exact-save-button"
              sx={styles.saveButton}
              onClick={() => onSubmit(values)}
              disabled={isLoadingTopRated}
            >
              Save
            </Button>
          </Stack>
        </Box>
      </Card>
      <Card variant="outlined" sx={styles.formCard}>
        <Stack sx={styles.headerStack}>
          <Typography variant="h5" sx={styles.headerTypography}>
            {menu!.id}
          </Typography>
          <Typography variant="h5" sx={styles.headerTypography} style={{ textAlign: 'end' }}>
            {values.brand?.toUpperCase()}-{resolveCountry(values?.country).toUpperCase()}-{values?.kitchen?.toUpperCase()}
          </Typography>
        </Stack>
        <Box component="form" sx={styles.formBox}>
          <Stack>
            {values.country && values.brand && (
              <Box sx={{ margin: '16px' }}>
                <label className="label mb-4">Meal</label>
                <FoodPicker
                  disabled={false}
                  createMenu={false}
                  showPopulateDate={false}
                  foodLoading={foodLoading}
                  value={values.food || []}
                  setFilterName={setFilterName}
                  populateSearchDate={values.day!}
                  setPopulateSearchDate={() => null}
                  foodList={(foodList?.data || []).filter((f) => !f.deletedAt)}
                  onChange={(food, tags) => handleFoodChanges(food as Food[], tags)}
                />
              </Box>
            )}
          </Stack>
        </Box>
      </Card>
      <Card>
        <Box sx={styles.tableBox}>
          <MenuTable
            brand={menu.brand}
            kitchen={menu.kitchen}
            selectedDate={menu.id}
            foodLabel={values.tags}
            foodList={selectedFoods}
            draftTrack={mealDraftOptions}
            setFoodToReplace={setFoodToReplace}
            removeFood={(removed) => handleRemoveFood(removed)}
          />
        </Box>
      </Card>

      <Popup
        maxWidth="desktop"
        // title={foodToReplace.open ? `Swap Meal ${foodToReplace.name}` : 'Update Menu'}
        ref={addMealsByFiltersRef}
        onClose={handleClosePopup}
        info={`${values.brand?.toUpperCase()}-${resolveCountry(values?.country).toUpperCase()}-${values?.kitchen?.toUpperCase()}`}
      >
        <AddMealsByFilterPopup
          values={values}
          filtersOn={filtersOn}
          foodIds={values.food || []}
          foodToReplace={foodToReplace}
          // setFiltersOn={setFiltersOn}
          isLoading={foodFiltersLoading}
          fetchNextPage={fetchNextPage}
          hasNextPage={hasNextPage || false}
          handleClosePopup={handleClosePopup}
          handleReplaceFood={handleReplaceFood}
          selectedMenuFilters={selectedMenuFilters}
          isFetchingNextPage={isLoadingFetchNextPage}
          addMealsByFiltersRef={addMealsByFiltersRef}
          setSelectedMenuFilters={setSelectedMenuFilters}
          handleFoodChanges={(food, tags) => handleFoodChanges(food, tags)}
          foodListFiltersData={(foodListFiltersData || []).filter((f) => !f.deletedAt)}
        />
      </Popup>

      <Popup maxWidth="xl" fullWidth title="Track Meals" ref={trackMealsRef} onClose={() => trackMealsRef.current?.close()}>
        <Box sx={{ flexDirection: 'column', width: '100%' }}>
          <Stack display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
            <TrackMealsPopup mealOptions={mealDraftOptions} setOptions={setMealDraftOptions} trackMealsRef={trackMealsRef} />
          </Stack>
        </Box>
      </Popup>
    </>
  );
};
export default ExactMenu;
