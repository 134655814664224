import { Stack } from '@mui/material';
import { getRecord } from 'actions/index';
import CaloLoader from 'components/CaloLoader';
import { CateringOrder as ICateringOrder } from 'lib/interfaces';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import CateringOrderHeader from './CateringOrderHeader';
import CateringOrderNotes from './CateringOrderNotes';
import ContactInfo from './ContactInfo';
import OrderInformation from './OrderInformation';
import OrderedMeals from './OrderedMealsTable';

export default function CateringOrder() {
  const { id } = useParams<{ id: string }>();

  const { data, isLoading } = useQuery<any, Error, ICateringOrder>(['business/deliveries', id], getRecord);

  return (
    <Stack px={2.5} rowGap={3}>
      {data && !isLoading ? (
        <>
          <CateringOrderHeader cateringOrder={data} />
          <OrderInformation cateringOrder={data} />
          <ContactInfo cateringOrder={data} />
          <OrderedMeals cateringOrder={data} />
          <CateringOrderNotes cateringOrder={data} />
        </>
      ) : (
        <Stack sx={{ width: '100%', justifyContent: 'center' }}>
          <CaloLoader />
        </Stack>
      )}
    </Stack>
  );
}
