import { forwardRef, useEffect, useState } from 'react';

import { FormikErrors } from 'formik';
import { compact, isEqual, keyBy } from 'lodash-es';
import { useQuery } from 'react-query';

import { LinkedComponent, Permission, ProcessingStage } from '@calo/dashboard-types';
import { Macros, Micronutrients } from '@calo/types';
import { Card, Divider, Typography } from '@mui/material-v6';

import { getListWithParams, getListWithParamsPost } from 'actions';
import NewFoodComponentPicker from 'components/MUI/NewFoodComponentPicker';
import { calculateMacrosFromIngredients, calculatePurchasingCost } from 'lib/helpers';
import { useUserRoles } from 'lib/hooks';
import { BaseOmit, FoodComponent } from 'lib/interfaces';
import { styles } from './styles';

interface ChildComponentsProps {
  prototype?: boolean;
  values: Omit<FoodComponent, BaseOmit>;
  childComponents: FoodComponent[];
  setChildComponents: React.Dispatch<React.SetStateAction<FoodComponent[]>>;
  setIsCalculatedMacrosDifferent: React.Dispatch<React.SetStateAction<boolean>>;
  setCalculatedMacrosFromIngredients: React.Dispatch<React.SetStateAction<Macros | undefined>>;
  setCalculatedMicronutrientsFromIngredients: React.Dispatch<React.SetStateAction<Micronutrients | undefined>>;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<void> | Promise<FormikErrors<Omit<FoodComponent, BaseOmit>>>;
  setValues: (
    values: React.SetStateAction<Omit<FoodComponent, BaseOmit>>,
    shouldValidate?: boolean | undefined
  ) => Promise<FormikErrors<Omit<FoodComponent, BaseOmit>>> | Promise<any>;
}

const ChildComponentsCard = forwardRef<HTMLDivElement, ChildComponentsProps>(
  (
    {
      values,
      prototype,
      childComponents,
      setChildComponents,
      setValues,
      setFieldValue,
      setIsCalculatedMacrosDifferent,
      setCalculatedMicronutrientsFromIngredients,
      setCalculatedMacrosFromIngredients
    }: ChildComponentsProps,
    ref
  ) => {
    const userRoles = useUserRoles();

    const [foodComponentName, setFoodComponentName] = useState('');

    const { data: foodComponentList, isLoading: foodComponentLoading } = useQuery<any, Error, { data: FoodComponent[] }>(
      [
        'food-components/list',
        {
          filters: {
            name: foodComponentName,
            country: values.country,
            brand: values.brand,
            kitchen: values.kitchen,
            stage: ProcessingStage.approved,
            prototype: false
          }
        }
      ],
      getListWithParamsPost,
      {
        suspense: false,
        enabled: !!foodComponentName
      }
    );

    const { data: prototypeComponentSearchResult, isLoading: prototypeSearchLoading } = useQuery<
      any,
      Error,
      { data: FoodComponent[] }
    >(
      [
        'food-components/prototype',
        { filters: { name: foodComponentName, country: values.country, brand: values.brand, kitchen: values.kitchen } }
      ],
      getListWithParams,
      {
        suspense: false,
        enabled: !!foodComponentName && userRoles.includes(Permission.VIEW_PROTOTYPE_COMPONENT_LIST) && !!prototype
      }
    );

    const handleChildComponents = (components: LinkedComponent[]) => {
      const allComponents = [
        ...childComponents,
        ...(foodComponentList?.data ?? []),
        ...(prototypeComponentSearchResult?.data ?? [])
      ];
      setChildComponents(compact(components.map((component) => allComponents.find((comp) => comp.id === component.id))));

      setValues({
        ...values,
        childComponents: components.map((row) => ({ id: row.id, quantity: row.quantity }))
      });
    };

    useEffect(() => {
      const childrenWithQuantity = childComponents.map((child) => ({
        ...child,
        quantity: (values.childComponents ?? []).find((cc) => cc.id === child.id)?.quantity ?? 0
      }));
      const response = calculateMacrosFromIngredients(values.ingredients, childrenWithQuantity, values.cookedRawFactor || 1);
      if (response) {
        setCalculatedMacrosFromIngredients(response.macros);
        setCalculatedMicronutrientsFromIngredients(response.micronutrients);
        setIsCalculatedMacrosDifferent(
          !(isEqual(response.micronutrients, values.micronutrients) && isEqual(response.macros, values.macros))
        );
      }
    }, [values, childComponents]);

    useEffect(() => {
      setValues({
        ...values,
        purchasingCost: +calculatePurchasingCost(values.ingredients, [], values.cookedRawFactor || 0)
      });
    }, []);

    return (
      <Card variant="outlined" sx={styles.card} ref={ref}>
        <Typography sx={styles.title}>Child Components</Typography>
        <Divider sx={styles.divider} />
        <NewFoodComponentPicker
          isChild={true}
          name={foodComponentName}
          allComponents={keyBy(childComponents, 'id')}
          setFieldValue={setFieldValue}
          setName={setFoodComponentName}
          isLoading={foodComponentLoading || prototypeSearchLoading}
          onChange={handleChildComponents}
          selectedComponents={values.childComponents || []}
          searchComponentList={[...(foodComponentList?.data || []), ...(prototypeComponentSearchResult?.data ?? [])]}
          isDisabled={!!(values.parentComponentIds && values.parentComponentIds.length > 0)}
        />
      </Card>
    );
  }
);

export default ChildComponentsCard;
