import { ProcessingStage } from '@calo/dashboard-types';
import AddCommentIcon from '@mui/icons-material/AddComment';
import ShareIcon from '@mui/icons-material/Share';
import { CardMedia, IconButton, Stack, TableCell, TableRow, Tooltip, Typography } from '@mui/material';
import noImagePlaceholder from 'assets/images/noImagePlaceholder.png';
import { caloTheme } from 'assets/images/theme/calo';
import { FoodStatus, Permission, PrototypeAction, Routes } from 'lib/enums';
import { getHostName } from 'lib/helpers';
import { useUserRoles } from 'lib/hooks';
import { Food, PrototypeFood } from 'lib/interfaces';
import { maxBy } from 'lodash-es';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { FoodRowStatusBox } from 'views/ChefPlayground/Shared/FoodRowStatusBox/FoodRowStatusBox';

const styles = {
  foodName: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '150%',
    color: caloTheme.palette.primaryMain
  },
  foodImage: {
    width: 40,
    height: 40,
    objectFit: 'cover',
    borderRadius: '8px'
  },
  comment: {
    fontSize: '14px',
    display: '-webkit-box',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical'
  },
  actionButton: {
    flexDirection: 'row',
    gap: '24px'
  },
  cardStack: {
    flexDirection: 'row',
    gap: '8px',
    alignItems: 'center'
  },
  tableCellStack: {
    display: 'flex',
    flexDirection: 'row',
    gap: '4px'
  }
};

interface FoodRowProps {
  food: PrototypeFood;
  commentClickHandler: (food: Food) => void;
  changeRequestClickHandler: (food: PrototypeFood) => void;
  handleMealApprovalDialogOpen: (food: PrototypeFood) => void;
}

const FoodRow = ({ food, commentClickHandler, handleMealApprovalDialogOpen, changeRequestClickHandler }: FoodRowProps) => {
  const roles = useUserRoles();

  const handleFoodApprovalPermission = (status: FoodStatus | ProcessingStage): boolean => {
    switch (status) {
      case FoodStatus.draft:
        return roles.includes(Permission.CREATE_ACTION_PROTOTYPE_FOOD);
      case FoodStatus.awaitingNPDApproval:
        return roles.includes(Permission.APPROVE_STAGE_1_PROTOTYPE_FOOD);
      case FoodStatus.awaitingOpsApproval:
        return roles.includes(Permission.APPROVE_STAGE_2_PROTOTYPE_FOOD);
      default:
        return false;
    }
  };

  const [tooltipOpen, setTooltipOpen] = useState(false);

  const comments =
    food.prototypeActions?.filter(
      (action) => action.type === PrototypeAction.comment || action.type === PrototypeAction.requestChanges
    ) ?? [];

  let lastComment = '';
  if (comments.length > 0) {
    const lastCommentAction = maxBy(comments, (comment) => new Date(comment.createdAt));

    lastComment =
      lastCommentAction?.type === PrototypeAction.comment || lastCommentAction?.type === PrototypeAction.requestChanges
        ? lastCommentAction.message
        : '';
  }

  const shareButtonClickHandler = async () => {
    const hostName = getHostName();
    const link = `${hostName}${Routes.playgroundFoodSlug.replace(':slug', food.slug)}`;

    try {
      await navigator.clipboard.writeText(link);
      setTooltipOpen(true);
      setTimeout(() => {
        setTooltipOpen(false);
      }, 1000);
    } catch (error) {
      console.error('Failed to copy text: ', error);
    }
  };

  const displayImage = `${process.env.REACT_APP_BUCKET_URL}/food-component/${food.id}/square@1x.jpg`;

  const renderCommentCell = () => {
    if (lastComment) {
      return <Typography sx={styles.comment}>{lastComment}</Typography>;
    } else {
      return (
        <IconButton onClick={() => commentClickHandler(food as Food)}>
          <AddCommentIcon sx={{ color: '#212121' }} />
        </IconButton>
      );
    }
  };

  return (
    <TableRow>
      <TableCell>
        <Stack sx={styles.cardStack}>
          <CardMedia
            component="img"
            image={displayImage}
            alt="placeholder"
            sx={styles.foodImage}
            onError={(e: any) => {
              e.target.src = noImagePlaceholder;
            }}
          />
          <Link to={Routes.playgroundFoodSlug.replace(':slug', food.slug)}>
            <Typography sx={styles.foodName}>{food.name.en}</Typography>
          </Link>
        </Stack>
      </TableCell>
      <TableCell>
        <Stack sx={styles.tableCellStack}>
          <Typography sx={{ fontSize: '14px' }}>
            {food.prototypeActions?.find((action) => action.type === PrototypeAction.create)?.actor.name}
          </Typography>
        </Stack>
      </TableCell>
      <TableCell>
        <Stack sx={styles.tableCellStack}>
          <FoodRowStatusBox
            item={food}
            status={food.status}
            handleMealApprovalDialogOpen={handleMealApprovalDialogOpen}
            commentClickHandler={changeRequestClickHandler}
            hasPermission={handleFoodApprovalPermission}
          />
        </Stack>
      </TableCell>
      <TableCell>
        <Stack sx={styles.tableCellStack}>{renderCommentCell()}</Stack>
      </TableCell>
      <TableCell>
        <Stack sx={styles.tableCellStack}>
          <Tooltip
            title="Link Copied"
            open={tooltipOpen}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            onClose={() => setTooltipOpen(false)}
          >
            <IconButton onClick={shareButtonClickHandler}>
              <ShareIcon sx={{ color: '#212121' }} />
            </IconButton>
          </Tooltip>
        </Stack>
      </TableCell>
    </TableRow>
  );
};

export default FoodRow;
