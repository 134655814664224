import { useFormik } from 'formik';
import { UpdateAddonsMenuReq } from 'lib/interfaces';

export default (addons: any, onSubmit: (values: UpdateAddonsMenuReq) => Promise<void>) =>
  useFormik<UpdateAddonsMenuReq>({
    enableReinitialize: true,
    initialValues: {
      food:
        addons?.food.map((f: any) => ({
          id: f.id,
          priority: +f.priority,
          menuLabel: f.menuLabel || []
        })) || [],
      tags: addons?.tags || []
    },

    validate: (values: UpdateAddonsMenuReq) => {
      const errors: any = {};

      if (values.food.length === 0) {
        errors.food = true;
      }

      return errors;
    },
    onSubmit: async (values) => {
      try {
        console.log(values);
        await onSubmit(values);
      } catch (error) {
        console.log(error);
      }
    }
  });
