import { Stack, Typography } from '@mui/material';

interface ContactInfoCard {
  name: string;
  value: string;
}

export default function ContactInfoCard({ name, value }: ContactInfoCard) {
  return (
    <Stack
      direction={'column'}
      justifyContent={'space-between'}
      rowGap={'8px'}
      sx={{
        flex: 1,
        p: 1.25
      }}
    >
      <Typography
        sx={{
          fontSize: '14px',
          fontWeight: 500
        }}
      >
        {name}
      </Typography>
      <Typography
        sx={{
          fontSize: '18px',
          fontWeight: 600,
          wordWrap: 'break-word'
        }}
      >
        {value}
      </Typography>
    </Stack>
  );
}
