import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Button,
  Card,
  IconButton,
  InputAdornment,
  Pagination,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { toggleUISettings } from 'actions';
import { caloTheme } from 'assets/images/theme/calo';
import { CaloLoader } from 'components';
import { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { PickUpStatus } from '../types';
import { getPickUpStatusFromTab } from '../utils';
import Settings from './Settings';
import CarHopOrderPopup from './components/CarHopOrderPopup';
import { EnableNotificationsCard, NewOrderCard, ReEnableAudioCard, SoundTestCard } from './components/NotificationCards';
import { OrderTable } from './components/OrderTable';
import { useRetailOrder } from './hooks/useRetailOrder';

interface RetailOrderMenuProps {
  history: {
    push: (path: string | { pathname: string; search: string }) => void;
  };
  location: any;
}

const RetailOrderMenu = ({ history, location }: RetailOrderMenuProps) => {
  const componentRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const {
    filters,
    setFilters,
    notification,
    setNotification,
    isUserInteracted,
    soundTest,
    needReEnableAudio,
    selectedTab,
    selectedDate,
    setSelectedDate,
    isLoading,
    isUnauthorized,
    loadingItemIds,
    handleUserInteraction,
    stopPlayingSound,
    handleChangeStatus,
    handleItemUpdate,
    handleSoundTestConfirmation,
    handleTabChange,
    filterOrdersByStatus,
    handleSearchChange,
    currentPage,
    order,
    orderBy,
    handleRequestSort,
    paginatedOrders,
    handlePageChange,
    handleDateChange,
    dateError,
    isFetching,
    carHopDetails,
    resetCarHopDetails
  } = useRetailOrder({ history, location });

  return (
    <div className="retail-order-menu -mx-8" ref={componentRef}>
      <Card
        variant="outlined"
        sx={{
          border: 'none',
          borderRadius: '0px',
          mt: '-36px',
          paddingBottom: '0px',
          mr: '-36px',
          [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
            flexDirection: 'column'
          }
        }}
      >
        <Box
          display={'flex'}
          flexDirection="row"
          justifyContent={'space-between'}
          sx={{
            padding: 2,
            ml: '16px',
            [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
              flexDirection: 'column'
            }
          }}
          width="100%"
        >
          <Stack width={'50%'} display={'flex'} flexDirection={'column'} alignItems="start" justifyContent={'space-between'}>
            <Typography
              sx={{
                mt: 'auto',
                fontWeight: 500,
                fontSize: '16px',
                mb: '8px',
                color: caloTheme.palette.neutral700,
                fontFamily: caloTheme.typography.fontFamily
              }}
            >
              Retail <ChevronRightIcon sx={{ fontSize: '16px', mx: '8px' }} />
              <span style={{ color: caloTheme.palette.neutral900 }}>Retail Orders</span>
            </Typography>
            <Typography
              variant="h3"
              sx={{
                mt: 'auto',
                fontWeight: 600,
                fontSize: '33px',
                color: caloTheme.palette.neutral900,
                fontFamily: caloTheme.typography.fontFamily
              }}
            >
              Retail Orders
              {(isLoading || isFetching) && <CircularProgress size={16} sx={{ ml: 1 }} />}
            </Typography>
          </Stack>
        </Box>
        <Box sx={{ mt: '12px', ml: '32px', display: 'flex', gap: 2, alignItems: 'center' }}>
          <Button
            variant="outlined"
            onClick={() => dispatch(toggleUISettings())}
            startIcon={<i className="fas fa-filter" id="filter" style={{ fontSize: '16px' }}></i>}
            sx={{
              color: 'text.primary',
              borderColor: 'grey.400',
              fontWeight: '500',
              textTransform: 'none',
              '&:hover': {
                borderColor: 'grey.600',
                backgroundColor: 'grey.50'
              },
              py: '6.5px'
            }}
          >
            Filters
          </Button>

          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              inputFormat="yyyy-MM-dd"
              value={selectedDate}
              onChange={handleDateChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  placeholder="Select date"
                  sx={{ width: 200, mb: dateError ? '-24px' : 0 }}
                  error={dateError}
                  helperText={dateError ? 'Invalid date' : ''}
                  inputProps={{
                    ...params.inputProps,
                    placeholder: 'Select date',
                    readOnly: true
                  }}
                  InputProps={{
                    startAdornment: (
                      <>
                        {params.InputProps?.endAdornment && (
                          <Box sx={{ ml: '-16px', mr: '16px' }}>{params.InputProps.endAdornment}</Box>
                        )}
                      </>
                    ),
                    endAdornment: (
                      <>
                        {selectedDate && (
                          <IconButton onClick={() => setSelectedDate(null)} sx={{ width: 30, height: 30 }}>
                            <ClearIcon />
                          </IconButton>
                        )}
                      </>
                    )
                  }}
                />
              )}
            />
          </LocalizationProvider>

          <TextField
            placeholder="Search"
            size="small"
            onChange={(e) => handleSearchChange(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon color="action" />
                </InputAdornment>
              )
            }}
          />
        </Box>
        <Box overflow="auto" width="100%" sx={{ paddingInline: '36px', mt: '12px' }}>
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            aria-label="order status tabs"
            sx={{
              borderBottom: '3px solid var(--divider, rgba(0, 0, 0, 0.12))',
              marginInline: '-36px',
              paddingInline: '36px'
            }}
          >
            <Tab
              label={`NEW ORDERS (${filterOrdersByStatus(PickUpStatus.CREATED).length})`}
              sx={{ color: selectedTab === 0 ? caloTheme.palette.primary500 : 'gray' }}
            />
            <Tab
              label={`READY FOR PICKUP (${filterOrdersByStatus(PickUpStatus.READY_FOR_PICK_UP).length})`}
              sx={{ color: selectedTab === 1 ? caloTheme.palette.primary500 : 'gray' }}
            />
            <Tab
              label={`COMPLETED (${filterOrdersByStatus(PickUpStatus.PICKED_UP).length})`}
              sx={{ color: selectedTab === 2 ? caloTheme.palette.primary500 : 'gray' }}
            />
            <Tab
              label={`CANCELLED (${filterOrdersByStatus(PickUpStatus.CANCELLED).length})`}
              sx={{ color: selectedTab === 3 ? caloTheme.palette.primary500 : 'gray' }}
            />
          </Tabs>
        </Box>
      </Card>

      {!isUserInteracted && <EnableNotificationsCard onConfirm={handleUserInteraction} />}
      {soundTest && <SoundTestCard onConfirm={handleSoundTestConfirmation} />}
      {notification.open && (
        <NewOrderCard
          title={notification.title}
          onConfirm={() => {
            stopPlayingSound();
            setNotification({ ...notification, open: false });
          }}
        />
      )}
      {needReEnableAudio && <ReEnableAudioCard onConfirm={handleUserInteraction} />}

      <Box sx={{ width: '100%' }}>
        {isUnauthorized ? (
          <Typography variant="h6" sx={{ textAlign: 'center', marginY: 2 }}>
            You are not authorized to view this Branch
          </Typography>
        ) : isLoading ? (
          <Stack sx={{ width: '100%', justifyContent: 'center' }}>
            <CaloLoader />
          </Stack>
        ) : (
          <OrderTable
            orders={paginatedOrders}
            loadingItemIds={loadingItemIds}
            isFetching={isLoading}
            isLoading={isLoading}
            selectedTabStatus={getPickUpStatusFromTab(selectedTab)}
            onChangeStatus={handleChangeStatus}
            handleItemUpdate={handleItemUpdate}
            onRequestSort={handleRequestSort}
            order={order}
            orderBy={orderBy}
          />
        )}
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
        <Pagination
          count={Math.ceil(filterOrdersByStatus(getPickUpStatusFromTab(selectedTab) as PickUpStatus).length / 10)}
          page={currentPage + 1}
          onChange={handlePageChange}
          variant="text"
          size="large"
          shape="circular"
          showLastButton
          showFirstButton
        />
      </Box>

      <Settings onFilter={setFilters} filters={filters} />

      <CarHopOrderPopup
        onClose={resetCarHopDetails}
        orderId={carHopDetails.orderId}
        pickupTime={carHopDetails.pickUpAt}
        handleOnMyWay={(id) => handleChangeStatus(id, PickUpStatus.BARISTA_EN_ROUTE)}
        customerName={carHopDetails.name}
        customerPhone={carHopDetails.phoneNumber}
        pickUpId={carHopDetails.pickUpId}
        carDetails={carHopDetails.vehiclePlateNumber}
      />
    </div>
  );
};

export default RetailOrderMenu;
