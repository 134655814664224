import { CustomMealCategory, DeliveryInstruction, FoodComponentType, WeekDay } from '@calo/types';

export enum Routes {
  root = '/',
  driversMetric = '/drivers-metric',
  driverMetric = '/drivers-metric/:id',
  deliveryPlanner = '/delivery-panner/:kitchen',
  coolerBags = '/cooler-bags',
  user = '/users/:id',
  usersList = '/users',
  newUser = '/user/new',

  newMenu = '/menu/new/:country/:kitchen/:type/:day',
  menuList = '/:brand/:country/:kitchen/menu/:menuPresentation/:day',
  menu = '/:brand/:kitchen/menu/:id',
  deliveries = '/deliveries',
  delivery = '/deliveries/:id',

  subscriptionUsers = '/subscription-users',

  subscriptions = '/subscriptions',
  subscription = '/subscriptions/:id',
  newSubscription = '/subscriptions/new',

  plansCalculator = '/plans/calculator',

  playgroundFoodList = '/chef-playground/food',
  playgroundFood = '/chef-playground/food/:id',
  playgroundFoodSlug = '/chef-playground/slug/:slug',
  playgroundNewFood = '/chef-playground/food/new',
  playgroundComponentList = '/chef-playground/food-components',
  playgroundComponent = '/chef-playground/food-components/:id',
  playgroundNewComponent = '/chef-playground/food-components/new',
  playgroundIngredientList = '/chef-plaground/ingredients',
  playgroundIngredient = '/chef-playground/ingredients/:id',
  playgroundNewIngredient = '/chef-playground/ingredients/new',

  foodList = '/food',
  food = '/food/:id',
  foodFeedback = '/food/feedback/:slug',
  foodSlug = '/food/slug/:slug',
  newFood = '/food/new/:type',
  foodComponentList = '/food-components',
  foodComponent = '/food-components/:id',
  newFoodComponent = '/food-components/new',
  kitchenFoodStats = '/food-stats',
  kitchenPortionStats = '/portion-stats',
  kitchenIngredientStats = '/ingredient-stats',
  packagingStats = '/packaging-stats',
  kitchenList = '/kitchens',
  kitchen = '/kitchens/:id',
  ingredients = '/ingredients',
  ingredient = '/ingredients/:id',
  newIngredient = '/ingredients/new',
  refunds = '/refunds',
  couponsList = '/coupons',
  coupon = '/coupons/:id',
  newCoupon = '/coupons/new',
  packagesList = '/food-packages',
  package = '/food-packages/:id',
  newPackage = '/food-packages/new',
  packageElementList = '/food-package-element',
  packageElement = '/food-package-element/:id',
  newPackageElement = '/food-package-element/new',
  addonsList = '/:brand/:country/addons-menu',
  addons = '/:country/:kitchen/:brand/addons-menu/:id',
  newAddons = '/addons-menu/new',
  addonsSubscriptionList = '/:brand/:country/addons-subscription-menu',
  addonsSubscription = '/:country/:kitchen/:brand/addons-subscription-menu',
  newAddonsSubscription = '/addons-subscription-menu/new',
  permissions = '/permissions',
  waitingListMap = '/kitchens/:id/waiting-list/:waitingListType/:isAllowed?',
  payment = '/payments',
  invitationStats = '/invitation-stats',
  drivers = '/drivers',
  feedback = '/feedback',
  tickets = '/tickets',
  kdsUser = '/kds-users/:id',
  kdsUsersList = '/kds-users',
  newKdsUser = '/kds-user/new',
  foodLogs = '/food-logs',
  logisticChange = '/delivery-address-change',
  operationsChanges = '/operations-changes',
  gifts = '/gifts',

  supplyChainUsersList = '/supply-chain-users',
  supplyChainUser = '/supply-chain-users/:id',
  newSupplyChainUser = '/supply-chain-user/new',
  accountUsersList = '/account-users',
  accountUser = '/account-users/:id',
  newAccounts = '/accounts/new',
  orders = '/orders',
  cateringOrders = '/catering-orders',
  cateringOrder = '/catering-orders/:id',
  supplyChainReportsList = '/supply-chain-reports-list',
  supplyChainMRPReport = '/supply-chain-mrp-report/:kitchen/:day',
  supplyChainMovingCostReport = '/supply-chain-moving-cost-report/:kitchen',
  nutritionists = '/nutritionists',
  complaints = '/complaints',
  specialRequest = '/special-request',
  affiliateProfilesList = '/affiliate-profiles',
  affiliateTransactionsList = '/affiliate-profiles/:id',
  employeePerks = '/employee-perks',
  kdsPermissions = '/kds-permissions',
  retailMenu = '/:brand/:branch/retail-menu',
  retailNewMenu = '/:branch/:brand/retail-menu/new/:id',
  retailOrderList = '/retail/order',
  retailOrderItem = '/retail/order/:id',
  aiDashboardLogistics = '/ai-dashboard/logistics',
  aiDashboardPromptTuning = '/ai-dashboard/prompt-tuning',
  supplyCapZones = '/supply-cap-zones/:kitchen'
}

export enum Action {
  USER_LIST_SET = 'USER_LIST_SET',
  USER_SET = 'USER_SET',

  UI_TOGGLE_VIEW_MODE = 'UI_TOGGLE_VIEW_MODE',
  UI_SET_VIEW_MODE = 'UI_SET_VIEW_MODE',
  UI_TOGGLE_SETTINGS = 'UI_TOGGLE_SETTINGS',
  UI_SET_SETTINGS_VISILE = 'UI_SET_SETTINGS_VISILE',
  UI_TOGGLE_KITCHEN_SETTINGS = 'UI_TOGGLE_KITCHEN_SETTINGS',
  UI_SET_KITCHEN_SETTINGS_VISILE = 'UI_SET_KITCHEN_SETTINGS_VISILE',
  UI_TOGGLE_COUPON_SETTINGS = 'UI_TOGGLE_COUPON_SETTINGS',
  UI_SET_COUPON_SETTINGS_VISILE = 'UI_SET_COUPON_SETTINGS_VISILE'
}

export enum AreaColors {
  COLOR_1 = 'rgba(228, 100, 90, 0.5)',
  COLOR_2 = 'rgba(6, 23, 85, 0.5)',
  COLOR_3 = 'rgba(222, 224, 106, 0.5)',
  COLOR_4 = 'rgba(210, 125, 46, 0.5)',
  COLOR_5 = 'rgba(0, 0, 0, 0.5)',
  COLOR_6 = 'rgba(105, 67, 215, 0.5)'
}

export enum AreaColors2 {
  COLOR_1 = '#89C6A5',
  COLOR_2 = '#4F89A3',
  COLOR_3 = '#7466A2',
  COLOR_4 = '#E4C159',
  COLOR_5 = '#008080',
  COLOR_6 = '#343434',
  COLOR_7 = '#AEAEAE'
}

export enum KitchenLogType {
  food = 'food',
  foodComponent = 'foodComponent',
  ingredient = 'ingredient'
}

export enum MacrosOffset {
  protein = 4,
  carbs = 4,
  fat = 9
}

export enum ViewMode {
  map = 'map',
  list = 'list'
}

export enum AddressViewMode {
  map = 'map',
  form = 'form'
}

export enum PackageType {
  regular = 'regular',
  sauce = 'sauce'
}

export enum SubscriptionPages {
  userInfo = 'User Information',
  deliveries = 'Deliveries',
  plan = 'Plan',
  wallet = 'Wallet',
  transactions = 'Transactions',
  logs = 'Logs',
  feedback = 'Feedback',
  loyalty = 'Loyalty',
  referral = 'Referral'
}

export enum FoodFeedbackPages {
  feedback = 'Feedback',
  complaints = 'Complaints'
}

export enum OperationType {
  mealSwap = 'mealSwap',
  manualDelivery = 'manualDelivery',
  other = 'other'
}

export enum MenuPopulateType {
  breakfast = 'Breakfast',
  lunchAndDinner = 'Lunch & Dinner',
  snacks = 'Snacks',
  caloKids = 'Calo Kids',
  dessert = 'Dessert Boxes',
  salad = 'Salad'
}

export enum AccountPages {
  userInfo = 'User Information',
  deliveries = 'Deliveries'
}

export enum loyaltyTransactionLogStatus {
  added = 'Earned',
  expired = 'Expired',
  added_by_calo = 'Added By Calo',
  redeemed = 'Claimed'
}

export enum ComplaintReason {
  HEALTH_RELATED_CONCERNS_COMPLAINTS = 'Health Related Concerns',
  COLD_SECTION_RELATED_ERRORS = 'Cold Section Related Errors',
  SPILLED_LIQUIDS_OR_FOOD_COMPLAINTS = 'Spilled Liquids or Food',
  DISPATCH_RELATED_ERRORS = 'Dispatch Related Errors',
  QUALITY_RELATED_COMPLAINTS = 'Quality Related Errors',
  FOREIGN_OBJECTS_FOUND = 'Foreign Objects Found',
  FOOD_RELATED_FOREIGN_OBJECTS_FOUND = 'Food Related Foreign Objects',
  NON_FOOD_RELATED_FOREIGN_OBJECTS_FOUND = 'Non-Food Related Foreign Objects'
}

export enum OrderItemType {
  beverage = 'beverage',
  food = 'food',
  merchandise = 'merchandise'
}

export enum SyncAttributesLabels {
  name = 'Arabic Name',
  cookedRawFactor = 'Cooked Raw Factor',
  weight = 'Cooked Weight',
  cups = 'cups',
  cookingStation = 'Stations',
  ingredients = 'Ingredients',
  macros = 'Macros',
  micronutrients = 'Micronutrients',
  measurementUnit = 'Measurement Unit',
  method = 'Method',
  tags = 'Tags',
  image = 'Image',
  standards = 'Standards',
  internalName = 'Internal Name',
  sections = 'Custom Meal Sections'
}

export enum MenuPresentationType {
  daily = 'daily',
  weekly = 'weekly'
}

export enum MenuCategory {
  breakfastBalancedFixed = 'Breakfast Balanced Fixed',
  breakfastBalancedRotational = 'Breakfast Balanced Rotational',
  breakfastLowCarbFixed = 'Breakfast Low Carb Fixed',
  breakfastLowCarbRotational = 'Breakfast Low Carb Rotational',
  breakfastHighProtein = 'Breakfast High Protein',
  breakfastVegetarian = 'Breakfast Vegetarian',
  breakfastWeightLoss = 'Breakfast Weight-Loss',
  breakfastGourmet = 'Breakfast Gourmet',
  lunchAndDinnerBalancedFixed = 'Lunch & Dinner Balanced Fixed',
  lunchAndDinnerBalancedRotational = 'Lunch & Dinner Balanced Rotational',
  lunchAndDinnerLowCarbFixed = 'Lunch & Dinner Low Carb Fixed',
  lunchAndDinnerLowCarbRotational = 'Lunch & Dinner Low Carb Rotational',
  lunchAndDinnerHighProtein = 'Lunch & Dinner High Protein',
  lunchAndDinnerVegetarian = 'Lunch & Dinner Vegetarian',
  lunchAndDinnerGourmet = 'Lunch & Dinner Gourmet',
  lunchAndDinnerWeightLoss = 'Lunch & Dinner Weight-Loss',
  snackBalancedFixed = 'Snacks Balanced Fixed',
  snackBalancedRotational = 'Snacks Balanced Rotational',
  snackLowCarbFixed = 'Snacks Low Carb Fixed',
  snackLowCarbRotational = 'Snacks Low Carb Rotational',
  snackHighProtein = 'Snacks High Protein',
  snackGourmet = 'Snacks Gourmet',
  caloKids = 'Calo Kids',
  dessert = 'Dessert Boxes',
  salad = 'Salad'
}

export enum HealthConditions {
  PREGNANCY_BREASTFEEDING = 'PREGNANCY_BREASTFEEDING',
  HYPERTENSION = 'HYPERTENSION',
  HIGH_CHOLESTEROL = 'HIGH_CHOLESTEROL',
  GERD = 'GERD',
  IBS = 'IBS',
  THYROID = 'THYROID',
  HIGH_URIC_ACID = 'HIGH_URIC_ACID',
  ANEMIA = 'ANEMIA',
  G6PD = 'G6PD'
}

export enum MealTabs {
  mealInformation = 'Meal Information',
  components = 'Components',
  nutritionalInformation = 'Nutritional Information',
  ingredients = 'Ingredients',
  packagesInformation = 'Packages Information',
  notes = 'Notes'
}

export const DeliveryInstructionsLabels: {
  [key in DeliveryInstruction]: string;
} = {
  [DeliveryInstruction.CALL_ME_WHEN_YOU_REACH]: 'Call me when you reach',
  [DeliveryInstruction.LEAVE_AT_RECEPTION]: 'Leave at reception',
  [DeliveryInstruction.LEAVE_AT_THE_DOOR]: 'Leave at the door',
  [DeliveryInstruction.RING_MY_DOORBELL]: 'Ring my doorbell'
};

export const dayMappings = {
  [WeekDay.SUNDAY]: 'Sun',
  [WeekDay.MONDAY]: 'Mon',
  [WeekDay.TUESDAY]: 'Tue',
  [WeekDay.WEDNESDAY]: 'Wed',
  [WeekDay.THURSDAY]: 'Thu',
  [WeekDay.FRIDAY]: 'Fri',
  [WeekDay.SATURDAY]: 'Sat'
};

export enum WeekDayIndex {
  Sunday = 0,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6
}

export enum ImageType {
  FOOD = 'food',
  COMPONENT = 'food-component',
  PACKAGE = 'food-package',
  INGREDIENT = 'ingredients'
}

export enum InputValueRestrictionType {
  macros = 'macros',
  quantity = 'quantity'
}

export const InputValueRestrictions = {
  [InputValueRestrictionType.macros]: {
    before: 4,
    after: 6
  },
  [InputValueRestrictionType.quantity]: {
    before: 5,
    after: 5
  }
};

export enum PerksStatus {
  pending = 'pending',
  done = 'done'
}

export enum PaymentStatus {
  all = 'all',
  paid = 'paid',
  pending = 'pending'
}

export enum EmployeePerksStatus {
  success = 'success',
  pending = 'pending',
  failed = 'failed'
}

export const ComponentCountByCustomMealType = {
  [CustomMealCategory.meal]: {
    [FoodComponentType.base]: 1,
    [FoodComponentType.protein]: 1,
    [FoodComponentType.topping]: 3,
    [FoodComponentType.side]: 1,
    [FoodComponentType.sauce]: 1
  },
  [CustomMealCategory.salad]: {
    [FoodComponentType.base]: 2,
    [FoodComponentType.protein]: 1,
    [FoodComponentType.topping]: 1,
    [FoodComponentType.side]: 2,
    [FoodComponentType.sauce]: 1
  },
  [CustomMealCategory.rice]: {
    [FoodComponentType.base]: 1,
    [FoodComponentType.protein]: 1,
    [FoodComponentType.topping]: 1,
    [FoodComponentType.side]: 3,
    [FoodComponentType.sauce]: 1
  },
  [CustomMealCategory.sandwich]: {
    [FoodComponentType.base]: 1,
    [FoodComponentType.protein]: 1,
    [FoodComponentType.topping]: 3,
    [FoodComponentType.side]: 1,
    [FoodComponentType.sauce]: 1
  }
};

export enum FormOperation {
  create = 'CREATE',
  update = 'UPDATE'
}

export enum FoodTagKeys {
  new = 'new',
  glutenFree = 'glutenFree',
  dairyFree = 'dairyFree'
}

export enum DocsType {
  invoice = 'invoice',
  frontLabel = 'frontLabel',
  backLabel = 'backLabel',
  portioningGuidelines = 'portioningGuidelines',
  portioningGuidelinesStats = 'portioningGuidelinesStats'
}

export enum FoodStatsType {
  subscriptions = 'subscriptions',
  businesses = 'businesses',
  charity = 'charity'
}

export enum Province {
  WP = 'WP',
  EP = 'EP',
  RH = 'RH',
  QM = 'QM',
  BH = 'BH',
  QA = 'QA',
  AE = 'AE',
  KW = 'KW'
}

export enum BaseFoodTags {
  rice = 'rice',
  noodles = 'noodles',
  lettuce = 'lettuce',
  egg = 'egg',
  pasta = 'pasta',
  sandwich = 'sandwich',
  oats = 'oats ',
  pancake = 'pancake',
  parfait = 'parfait',
  salad = 'salad',
  pudding = 'pudding',
  potato = 'potato',
  cake = 'cake',
  savory = 'savory',
  soup = 'soup',
  dates = 'dates',
  cookies = 'cookies',
  bites = 'bites',
  dips = 'dips',
  grains = 'grains',
  veggies = 'veggies',
  fruit = 'fruit',
  mash = 'mash'
}

export enum PrototypeAction {
  create = 'create',
  comment = 'comment',
  pend = 'pend', // old value
  submitForNpdApproval = 'submitForNpdApproval',
  submitForOpsApproval = 'submitForOpsApproval',
  requestChanges = 'requestChanges',
  approve = 'approve'
}

export enum FoodStatus {
  draft = 'draft',
  pending = 'pending', // old value
  awaitingNPDApproval = 'awaitingNPDApproval',
  awaitingOpsApproval = 'awaitingOpsApproval',
  approved = 'approved'
}

export enum Permission {
  SEND_KITCHEN_EMAIL = 'a:ske',
  VIEW_USER_PERMISSIONS_LIST = 'v:upl',
  CREATE_USER_PERMISSIONS = 'c:up',
  DELETE_USER_PERMISSIONS = 'd:up',
  UPDATE_USER_PERMISSIONS = 'u:up',
  VIEW_DELIVERY_LIST = 'v:dl',
  EXPORT_DELIVERY_LIST = 'a:edl',
  VIEW_DELIVERY = 'v:d',
  UPDATE_DELIVERY = 'u:d',
  SKIP_DELIVERY = 'a:sd',
  UNSKIP_DELIVERY = 'a:ud',
  GET_DELIVERY_INVOICE = 'a:gdi',
  ADD_FOOD_DELIVERY_MENU = 'a:dm',
  REMOVE_COMPONENT_FROM_DELIVERY_FOOD = 'a:rc',
  DELETE_FOOD_DELIVERY_MENU = 'd:dm',
  REPLACE_FOOD_DELIVERY_MENU = 'a:rdm',
  REPLACE_ADDON_SUBSCRIPTION_DELIVERY_MENU = 'u:asdm',
  VIEW_ADDON_SUBSCRIPTION_MENU = 'v:asm',
  ADD_ADDON_SUBSCRIPTION = 'a:asm',
  REMOVE_ADDON_SUBSCRIPTION = 'd:asm',
  VIEW_ADDON_SUBSCRIPTION_LIST = 'v:asl',
  REGENERATE_DELIVERY_MENU = 'a:rgm',
  DELIVERY_APPLY_PAYMENT = 'a:dap',
  CREATE_ADDONS_DELIVERY_MENU = 'c:adm',
  DELETE_ADDONS_DELIVERY_MENU = 'd:adm',
  CANCEL_DELIVERY = 'a:cd',
  UNCANCEL_DELIVERY = 'a:ucd',
  DELIVERY_ADDONS_MENU = 'v:dam',
  DELIVERY_ADDONS_SUBSCRIPTION_MENU = 'v:dasm',
  VIEW_DELIVERY_SUBSCRIPTION = 'v:ds',
  UPDATE_DELIVERY_CUSTOM_FOOD = 'u:dcf',
  VIEW_USER_LIST = 'v:ul',
  VIEW_USER = 'v:u',
  CREATE_USER = 'c:u',
  UPDATE_USER = 'a:uu',
  RESET_USER_PASSWORD = 'a:rup',
  DELETE_USER = 'd:u',
  VIEW_RATING_LIST = 'v:ral',
  EXPORT_RATING_LIST = 'a:erl',
  VIEW_SUBSCRIPTION_LIST = 'v:sl',
  VIEW_SUBSCRIPTION = 'v:s',
  UPDATE_SUBSCRIPTION = 'u:s',
  CREATE_SUBSCRIPTION = 'c:s',
  PAUSE_SUBSCRIPTION = 'a:ps',
  RESUME_SUBSCRIPTION = 'a:rs',
  CLEAR_SUBSCRIPTION_PAUSE = 'a:csp',
  EXPORT_SUBSCRIPTION_LIST = 'a:se',
  SEARCH_SUBSCRIPTION = 'v:ss',
  REMOVE_SUBSCRIPTION_SUSPENSION = 'a:rss',
  CREATE_SUBSCRIPTION_ADDRESS = 'c:sa',
  UPDATE_SUBSCRIPTION_ADDRESS = 'u:sa',
  DELETE_SUBSCRIPTION_ADDRESS = 'd:sa',
  BULK_UPDATE_SUBSCRIPTION_ADDRESS = 'bu:sa',
  CREATE_SUBSCRIPTION_WALLET = 'c:sw',
  CREATE_SUBSCRIPTION_DELIVERY = 'c:sd',
  UPDATE_SUBSCRIPTION_DELIVERY_PREFERENCES = 'u:sdp',
  VIEW_SUBSCRIPTION_DELIVERY_LIST = 'v:sdl',
  VIEW_FOOD_PREFERENCE = 'v:fp',
  UPDATE_FOOD_PREFERENCE = 'u:fp',
  UPDATE_SUBSCRIPTION_PLAN = 'u:sp',
  VIEW_SUBSCRIPTION_WALLET = 'v:sw',
  VIEW_WALLET_LIST = 'v:wal',
  EXPORT_WALLET_LIST = 'a:wal',
  VIEW_SUBSCRIPTION_LOGS = 'v:slo',
  VIEW_ROUTE_PLAN_LIST = 'v:rpl',
  UPDATE_SUBSCRIPTION_CUSTOM_DELIVERY_TIME = 'u:uscdt',
  EXPORT_DRIVER_COMPLETION_RATE = 'e:dcr',
  VIEW_ROUTE_PLAN = 'v:rp',
  REGENERATE_ROUTE = 'a:rr',
  CREATE_MAP = 'c:m',
  UPDATE_MAP = 'u:m',
  VIEW_MAP = 'v:m',
  GET_DRIVER_USERS = 'a:gdu',
  VIEW_MAP_DELIVERIES_LIST = 'v:mdl',
  VIEW_LIVE_TRACKING = 'v:lt',
  VIEW_KITCHEN_LIST = 'v:kl',
  VIEW_KITCHEN = 'v:k',
  UPDATE_KITCHEN = 'u:uk',
  VIEW_KITCHEN_STATS = 'v:ks',
  VIEW_COMPONENT_SCALED_RECIPE = 'v:csr',
  VIEW_FOOD_STATS = 'v:fs',
  EXPORT_FOOD_STATS = 'a:efs',
  VIEW_INGREDIENT_STATS = 'v:is',
  EXPORT_INGREDIENT_STATS = 'a:eis',
  VIEW_PORTION_STATS = 'v:ps',
  EXPORT_PORTION_STATS = 'a:eps',
  VIEW_PACKAGE_ELEMENTS_STATS = 'v:pes',
  VIEW_DRIVER_STATS = 'v:drs',
  VIEW_DELIVERY_STATS = 'v:dst',
  VIEW_CUTLERY_AND_COOLER_BAG_STATS = 'v:cacbs',
  VIEW_DRIVER_COOLER_BAG_STATS = 'v:dcs',
  VIEW_MENU_LIST = 'v:ml',
  UPDATE_MENU = 'u:me',
  VIEW_MENU = 'v:me',
  GENERATE_DAY_MENU = 'a:gdm',
  CREATE_MENU = 'c:me',
  DESIGN_MENU = 'a:me',
  MENU_GENERATION_LIST = 'v:mgl',
  VIEW_ADDONS_LIST = 'v:al',
  VIEW_ADDONS = 'v:a',
  UPDATE_ADDONS = 'u:a',
  CREATE_ADDONS = 'c:a',
  VIEW_ADDONS_SUBSCRIPTION_LIST = 'v:asl',
  VIEW_ADDONS_SUBSCRIPTION = 'v:as',
  UPDATE_ADDONS_SUBSCRIPTION = 'u:as',
  CREATE_ADDONS_SUBSCRIPTION = 'c:as',
  VIEW_FOOD_LIST = 'v:fl',
  VIEW_FOOD_LIST_BY_SLUG = 'v:fls',
  CREATE_FOOD = 'c:f',
  UPDATE_FOOD = 'u:f',
  BATCH_UPDATE_FOOD = 'u:bf',
  VIEW_FOOD = 'v:f',
  DELETE_FOOD = 'd:f',
  VIEW_PROTOTYPE_FOOD_LIST = 'v:pfl',
  VIEW_PROTOTYPE_FOOD_LIST_BY_SLUG = 'v:pfls',
  CREATE_PROTOTYPE_FOOD = 'c:pf',
  UPDATE_PROTOTYPE_FOOD = 'u:pf',
  VIEW_PROTOTYPE_FOOD = 'v:pf',
  CREATE_ACTION_PROTOTYPE_FOOD = 'c:apf',
  APPROVE_PROTOTYPE_FOOD = 'a:pf',
  DELETE_PROTOTYPE_FOOD = 'd:pf',
  VIEW_PROTOTYPE_COMPONENT_LIST = 'v:pcl',
  CREATE_PROTOTYPE_COMPONENT = 'c:pc',
  UPDATE_PROTOTYPE_COMPONENT = 'u:pc',
  VIEW_PROTOTYPE_COMPONENT = 'v:pc',
  CREATE_ACTION_PROTOTYPE_COMPONENT = 'c:apc',
  APPROVE_PROTOTYPE_COMPONENT = 'a:pc',
  DELETE_PROTOTYPE_COMPONENT = 'd:pc',
  VIEW_PROTOTYPE_INGREDIENT_LIST = 'v:pil',
  CREATE_PROTOTYPE_INGREDIENT = 'c:pi',
  UPDATE_PROTOTYPE_INGREDIENT = 'u:pi',
  VIEW_PROTOTYPE_INGREDIENT = 'v:pi',
  CREATE_ACTION_PROTOTYPE_INGREDIENT = 'c:api',
  APPROVE_PROTOTYPE_INGREDIENT = 'a:pi',
  DELETE_PROTOTYPE_INGREDIENT = 'd:pi',
  BATCH_UPDATE_PROTOTYPE_FOOD = 'u:bpf',
  EXPORT_FOOD_LIST = 'a:efl',
  VIEW_FOOD_COMPONENTS_LIST = 'v:fcl',
  VIEW_FOOD_COMPONENTS_CUSTOM_FOOD = 'v:fcc',
  CREATE_FOOD_COMPONENTS = 'c:fc',
  UPDATE_FOOD_COMPONENTS = 'u:fc',
  VIEW_FOOD_COMPONENTS = 'v:fc',
  EXPORT_FOOD_COMPONENTS_LIST = 'a:efcl',
  UPLOAD_IMAGE = 'a:ufi',
  CREATE_LOGS_ON_UPDATE_IMAGE = 'c:cloui',
  VIEW_INGREDIENT_LIST = 'v:il',
  VIEW_INGREDIENT = 'v:i',
  UPDATE_INGREDIENT = 'u:i',
  CREATE_INGREDIENT = 'c:i',
  EXPORT_INGREDIENT_LIST = 'a:eil',
  VIEW_REFUND_LIST = 'v:rl',
  CREATE_REFUND = 'c:r',
  EXPORT_REFUND_LIST = 'a:erfl',
  VIEW_SEARCH_BAR = 'v:sb',
  VIEW_COUPON_LIST = 'v:cl',
  VIEW_COUPON_USAGE_LIST = 'v:cul',
  VIEW_COUPON = 'v:c',
  CREATE_COUPON = 'c:c',
  UPDATE_COUPON = 'u:c',
  DELETE_COUPON = 'd:c',
  VIEW_DRIVER_METRIC_LIST = 'v:dml',
  VIEW_DRIVER_METRIC = 'v:dm',
  UPDATE_DRIVER_METRIC = 'u:dm',
  BATCH_ALLOW_DRIVERS_TO_START_SHIFT = 'a:badtss',
  VIEW_WAITING_LIST = 'v:wl',
  ALLOW_WAITING_LIST = 'a:awl',
  BATCH_ALLOW_WAITING_LIST = 'a:bawl',
  SEND_INVITATION_CODE_WAITING_LIST = 'a:wlc',
  DELETE_WAITING_LIST = 'd:wl',
  BATCH_REMOVE_FROM_PRE_LAUNCH_WAITING_LIST = 'a:brwl',
  VIEW_INVITATION_CODE_STATS = 'v:cs',
  VIEW_INVITATION_CODE_USER_STATS = 'v:cus',
  CREATE_INVITATION_CODE = 'c:ic',
  CREATE_BATCH_INVITATION_CODE = 'c:icb',
  VIEW_FOOD_PACKAGE_ELEMENT_LIST = 'v:pel',
  CREATE_FOOD_PACKAGE_ELEMENT = 'c:pe',
  UPDATE_FOOD_PACKAGE_ELEMENT = 'u:pe',
  VIEW_FOOD_PACKAGE_ELEMENT = 'v:pe',
  DELETE_FOOD_PACKAGE_ELEMENT = 'd:pe',
  EXPORT_FOOD_PACKAGE_ELEMENT = 'a:efpe',
  VIEW_FOOD_PACKAGE_LIST = 'v:pl',
  CREATE_FOOD_PACKAGE = 'c:p',
  UPDATE_FOOD_PACKAGE = 'u:p',
  VIEW_FOOD_PACKAGE = 'v:p',
  DELETE_FOOD_PACKAGE = 'd:p',
  EXPORT_FOOD_PACKAGE = 'a:efp',
  VIEW_DRIVER_LIST = 'v:drl',
  CREATE_DRIVER = 'c:dr',
  UPDATE_DRIVER = 'u:dr',
  VIEW_DRIVER = 'v:dr',
  DELETE_DRIVER = 'd:dr',
  RESET_DRIVER_PASSWORD = 'a:rdp',
  CREATE_TICKET = 'c:t',
  VIEW_TICKET_LIST = 'v:tl',
  UPDATE_TICKET = 'u:t',
  VIEW_COMPLETE_ROUTE_PATH = 'v:crp',
  CREATE_KDS_USER = 'c:kds',
  VIEW_KDS_USER = 'v:kds',
  UPDATE_KDS_USER = 'u:kds',
  DELETE_KDS_USER = 'd:kds',
  VIEW_KDS_USER_LIST = 'v:kdsl',
  RESET_KDS_USER_PASSWORD = 'a:rkds',
  VIEW_KITCHEN_LOGS_LIST = 'v:kll',
  VIEW_USER_CUSTOM_FOOD = 'v:ucf',
  CREATE_USER_CUSTOM_FOOD = 'c:ucf',
  CREATE_GROUP_GIFT = 'c:gg',
  AUTO_CREATE_GROUP_GIFT = 'c:agg',
  APPLY_GROUP_GIFT = 'a:gg',
  AUTO_APPLY_GROUP_GIFT = 'a:agg',
  DOWNLOAD_GROUP_GIFT_LISTS = 'v:ggl',
  LIST_GROUP_GIFT = 'v:gg',
  UPDATE_GROUP_GIFT = 'u:gg',
  VALIDATE_GROUP_GIFT = 'a:vgg',
  LIST_GROUP_GIFT_ITEM = 'v:ggi',
  DELETE_GROUP_GIFT_ITEM = 'd:ggi',
  CREATE_DELIVERY_TICKET = 'c:dmc',
  LIST_DELIVERY_TICKET = 'v:dmc',
  LIST_EXTRA_MEALS = 'v:exm',
  CREATE_SUPPLY_CHAIN_USER = 'c:scu',
  VIEW_SUPPLY_CHAIN_USER = 'v:scu',
  UPDATE_SUPPLY_CHAIN_USER = 'u:scu',
  DELETE_SUPPLY_CHAIN_USER = 'd:scu',
  VIEW_SUPPLY_CHAIN_USER_LIST = 'v:scul',
  RESET_SUPPLY_CHAIN_USER_PASSWORD = 'a:rscu',
  VIEW_INVENTORY_CONSUMPTION_REPORT = 'v:vicr',
  VIEW_MATERIAL_RESOURCE_PLANING_REPORT = 'v:mrpr',
  EXPORT_MATERIAL_RESOURCE_PLANING_REPORT = 'a:emrpr',
  VIEW_SUPPLY_CHAIN_REPORTS_LIST = 'v:vscr',
  SET_DELIVERY_FOOD_MENU = 'a:dfm',
  VIEW_MOVING_COST_REPORT = 'v:mcr',
  VIEW_SUBSCRIPTION_TRANSACTIONS = 'v:stxn',
  VIEW_LOYALTY_POINTS = 'v:vlp',
  CREATE_LOYALTY_POINTS = 'c:clp',
  LIST_APP_COGNITO_USERS = 'v:acu',
  UPDATE_APP_COGNITO_USERS = 'u:acu',
  VIEW_REFERRAL = 'v:ref',
  WITHDRAW_REFERRAL_BALANCE = 'c:refb',
  SYNC_SUBSCRIPTION_BALANCE = 'c:sbal',
  VIEW_BUSINESS_USER_LIST = 'v:bul',
  VIEW_BUSINESS_USER = 'v:bu',
  CREATE_BUSINESS_USER = 'c:bu',
  UPDATE_BUSINESS_USER = 'u:bu',
  VIEW_BUSINESS_DELIVERY_LIST = 'v:bdl',
  VIEW_BUSINESS_DELIVERY = 'v:bd',
  CREATE_BUSINESS_DELIVERY = 'c:bd',
  UPDATE_BUSINESS_DELIVERY = 'u:bd',
  UPDATE_BUSINESS_DELIVERY_ADDRESS = 'u:bda',
  DELETE_BUSINESS_DELIVERY = 'd:bd',
  CREATE_CUSTOMER_COMPLAINT = 'c:cc',
  LIST_CUSTOMER_COMPLAINT = 'v:cc',
  UPDATE_CUSTOMER_COMPLAINT = 'u:cc',
  EXPORT_COMPLAINT_LIST = 'a:ec',
  HEALTH_CONDITIONS_PLAN_MANAGEMENT = 'a:hcpm',
  REFERRAL_PROGRAM = 'v:rfl',
  VIEW_AFFILIATE_PROFILE = 'v:aff',
  CREATE_AFFILIATE_PROFILE = 'c:aff',
  PAY_AFFILIATE_COMMISSION = 'p:aff',
  LIST_GIFTS = 'v:g',
  CREATE_GIFT = 'c:g',
  UPDATE_GIFT = 'u:g',
  DELETE_GIFT = 'd:g',
  EXPORT_GIFTS_LIST = 'a:g',
  VIEW_SUBSCRIPTION_DAY_PROGRESS = 'v:sdp',
  UPDATE_SUBSCRIPTION_DAY_PROGRESS = 'u:sdb',
  EXPORT_BUSINESS_ORDERS = 'a:ebo',
  VIEW_DEBUG_OPTIONS = 'v:do',
  VIEW_CUSTOM_MACROS_DELIVERY_DETAILS = 'v:cmdd',
  UPDATE_EMPLOYEE_PERKS = 'u:ep',
  VIEW_EMPLOYEE_PERKS = 'v:ep',
  EXPORT_EMPLOYEE_PERKS = 'a:ep',
  DELETE_CUSTOM_FOOD = 'd:cf',
  VIEW_KDS_PERMISSIONS_LIST = 'v:kpl',
  CREATE_KDS_PERMISSIONS = 'c:kp',
  DELETE_KDS_PERMISSIONS = 'd:kp',
  UPDATE_KDS_PERMISSIONS = 'u:kp',
  DELETE_FOOD_COMPONENT = 'd:fc',
  DELETE_INGREDIENT = 'd:i',
  VIEW_SUPPLY_CAP_ZONES = 'v:scz',
  CREATE_SUPPLY_CAP_ZONE = 'c:scz',
  UPDATE_SUPPLY_CAP_ZONE = 'u:scz',
  DELETE_SUPPLY_CAP_ZONE = 'd:scz',
  CREATE_RETAIL_MENU_ITEMS = 'c:rmi',
  VIEW_RETAIL_MENU_ITEMS = 'v:rmi',
  UPDATE_RETAIL_MENU_ITEMS = 'u:rmi',
  DELETE_RETAIL_MENU_ITEMS = 'd:rmi',
  CREATE_RETAIL_ORDER = 'c:rm',
  VIEW_RETAIL_ORDER = 'v:rm',
  UPDATE_RETAIL_ORDER = 'u:rm',
  DELETE_RETAIL_ORDER = 'd:rm',
  GET_DOCUMENTS = 'a:gd',
  UPDATE_AI_LOGISTICS = 'u:ail',
  UPDATE_AI_PROMPTS = 'u:aip',
  UPDATE_AI_DISCOUNTS = 'u:aid',
  UPDATE_AI_CONTROLS = 'u:aic',
  APPROVE_STAGE_1_PROTOTYPE_FOOD = 'a:pf1',
  APPROVE_STAGE_2_PROTOTYPE_FOOD = 'a:pf2',
  APPROVE_STAGE_1_PROTOTYPE_FOOD_COMPONENT = 'a:pc1',
  APPROVE_STAGE_2_PROTOTYPE_FOOD_COMPONENT = 'a:pc2'
}

export enum RetailMenuItemTag {
  FROYO = 'FROYO',
  HOT_DRINK = 'HOT_DRINK',
  MATCHA = 'MATCHA',
  SMOOTHY = 'SMOOTHY',
  SALAD = 'SALAD',
  COLD_DRINK = 'COLD_DRINK',
  RICE_MEAL = 'RICE_MEAL',
  SANDWICH = 'SANDWICH',
  CALO_MEAL = 'CALO_MEAL',
  SWEET = 'SWEET',
  WRAP = 'WRAP',
  SNACK = 'SNACK',
  BONE_BROTH = 'BONE_BROTH'
}

export enum DeliveryTime {
  evening = 'evening',
  morning = 'morning',
  earlyMorning = 'earlyMorning'
}

export enum MealRestrictionTag {
  NONE = 'None',
  HAVING_DIABETES_1 = 'Having Diabetes 1',
  HAVING_DIABETES_2 = 'Having Diabetes 2',
  HAVING_LACTOSE_INTOLERANCE = 'Having Lactose Intolerance',
  HAVING_HIGH_BLOOD_PRESSURE = 'Having High Blood Pressure',
  HAVING_HIGH_CHOLESTEROL = 'Having High Cholestrol',
  HAVING_DIGESTIVE_ISSUES = 'Having Digestive Issues',
  HAVING_KIDNEY_PROBLEMS = 'Having Kidney Problems',
  HAVING_HEART_CONDITION = 'Having Heart Condition'
}

export enum HealthBenefitTag {
  NONE = 'None',
  GOOD_FOR_BONES = 'Good For Bones',
  GOOD_FOR_BRAIN = 'Good For Brain',
  GOOD_FOR_DIGESTION = 'Good For Digestion',
  GOOD_FOR_EYES = 'Good For Eyes',
  GOOD_FOR_HEART = 'Good For Heart',
  GOOD_FOR_IMMUNE_SYSTEM = 'Good For Immune System',
  GOOD_FOR_MUSCLES = 'Good For Muscles',
  ENERGY_BOOSTING = 'Energy Boosting',
  GOOD_FOR_SKIN = 'Good For Skin',
  REDUCES_INFLAMMATION = 'Reduces Inflammation',
  GOOD_FOR_METABOLISM = 'Good For Metabolism',
  GOOD_FOR_BLOOD = 'Good For Blood',
  ANTIOXIDANT = 'Antioxidant',
  GOOD_FOR_HYDRATION = 'Good For Hydration',
  IMPROVES_IRON_LEVELS = 'Improves Iron Levels',
  GOOD_FOR_BOWELS = 'Good For Bowels',
  GOOD_FOR_TEETH = 'Good For Teeth',
  GOOD_FOR_MOOD = 'Good For Mood',
  GOOD_FOR_FOCUS = 'Good For Focus',
  GOOD_FOR_JOINTS = 'Good For Joints',
  GOOD_FOR_DIABETICS = 'Good For Diabetics',
  GOOD_FOR_DETOXIFICATION = 'Good For Detoxification',
  LOWERS_CHOLESTEROL = 'Lowers Cholesterol',
  GOOD_FOR_INDULGENCE = 'Good For Indulgence',
  GOOD_FOR_NERVOUS_SYSTEM = 'Good For Nervous System',
  IMPROVE_SLEEP = 'Improve Sleep',
  GOOD_FOR_LIVER = 'Good For Liver',
  GOOD_FOR_HAEMOGLOBIN_LEVEL = 'Good For Haemoglobin Level'
}

export enum MealFormTag {
  LIQUID = 'Liquid',
  MUSHY = 'Mushy',
  SOLID = 'Solid'
}

export enum ThicknessTag {
  REGULAR = 'Regular (Meal)',
  EASY_TO_CHEW = 'Easy To Chew (Meal)',
  SOFT_BITE_SIZED = 'Soft Bite Sized (Meal)',
  MINCED_MOIST = 'Minced Moist (Meal)',
  PURED = 'Pured (Meal)',
  LIQUIDISED = 'Liquidised (Meal)',
  EXTREMELY_THICK = 'Extremely Thick (Drink)',
  MODERATELY_THICK = 'Moderately Thick (Drink)',
  MILDLY_THICK = 'Mildly Thick (Drink)',
  SLIGHTLY_THICK = 'Slightly Thick (Drink)',
  THIN = 'Thin (Drink)'
}

export enum FoodTagsKeys {
  protein = 'protein',
  base = 'base',
  rating = 'rating',
  taste = 'taste',
  snacktype = 'snacktype',
  addonCategory = 'addonCategory',
  category = 'category',
  cuisine = 'cuisine',
  flavorsProfile = 'flavorsProfile',
  hintFlavors = 'hintFlavors',
  texture = 'texture',
  eatingExperience = 'eatingExperience',
  spicyLevel = 'spicyLevel',
  convenience = 'convenience',
  mealRestriction = 'mealRestriction',
  healthBenefit = 'healthBenefit',
  mealForm = 'mealForm',
  thickness = 'thickness'
}

export enum MenuFilterType { //Menu preview filter section
  All = 'All',
  Breakfast = 'Breakfast',
  LunchDinner = 'Lunch & Dinner',
  Snack = 'Snack'
}

export enum ComponentTabs {
  information = 'Information',
  region = 'Region',
  ingredients = 'Ingredients',
  childComponents = 'Child Components',
  macros = 'Macros',
  additionalInformation = 'Additional Information',
  multiCustomMeals = 'multi-custom meals',
  method = 'Method',
  standards = 'Standards'
}
