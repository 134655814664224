import { useEffect, useState } from 'react';

import { getImageUploadLink } from 'actions/ui';
import { useUserRoles } from 'lib/hooks';
import { isNil } from 'lodash-es';
import { useParams } from 'react-router-dom';
import { v4 as uuid } from 'uuid';

import { CreateFoodComponentReq, FoodComponentQualityStandard, Permission, UpdateFoodComponentReq } from '@calo/dashboard-types';
import InfoIcon from '@mui/icons-material/Info';
import { Button, Stack, Typography } from '@mui/material-v6';

import ImageDropZone from '../ImageDropZone';
import InputMUI from '../InputMUI';
import InputThemeProvider from '../InputThemeProvider';
import { styles } from './styles';

interface ComponentStandardsProps {
  closePopup: () => void;
  values: UpdateFoodComponentReq | CreateFoodComponentReq;
  setValues: (v: any) => void;
  activeStandardId?: string | null;
  setActiveStandardId: (value: string | null) => void;
  allowUploadImages?: boolean;
}

const ComponentStandardsPopup = ({
  closePopup,
  values,
  setValues,
  activeStandardId,
  setActiveStandardId,
  allowUploadImages
}: ComponentStandardsProps) => {
  const { id } = useParams<{ id: string }>();
  const userRoles = useUserRoles();
  const standard = values.standards?.find((s) => s.id === activeStandardId) ?? undefined;
  const [standardState, setStandardState] = useState<FoodComponentQualityStandard>({
    id: uuid(),
    description: '',
    name: '',
    imageURLs: [],
    ...standard
  });
  const handleAddStandard = () => {
    setValues({
      ...values,
      standards: values.standards
        ? [
            ...values.standards,
            {
              id: standardState.id,
              name: standardState.name,
              description: standardState.description,
              imageURLs: standardState.imageURLs
            }
          ]
        : [
            {
              id: standardState.id,
              name: standardState.name,
              description: standardState.description,
              imageURLs: standardState.imageURLs
            }
          ]
    });
    handleClose();
  };
  const handleUpdateStandard = () => {
    const standards = [...(values.standards ?? [])];
    const updatedStandards = standards.map((s) => {
      if (s.id === activeStandardId) {
        return {
          ...s,
          name: standardState.name,
          description: standardState.description,
          imageURLs: standardState.imageURLs
        };
      }
      return s;
    });
    setValues({ ...values, standards: updatedStandards });
    handleClose();
  };
  const handleClose = () => {
    setStandardState({ id: uuid(), description: '', name: '', imageURLs: [] });
    setActiveStandardId(null);
    closePopup();
  };

  useEffect(() => {
    if (activeStandardId && (values.standards ?? []).length > 0) {
      const standard = (values.standards ?? []).find((s) => s.id === activeStandardId) ?? undefined;
      setStandardState({
        id: standard?.id ?? uuid(),
        description: standard?.description ?? '',
        name: standard?.name ?? '',
        imageURLs: standard?.imageURLs ?? []
      });
    }
  }, [activeStandardId, values.standards]);

  return (
    <Stack direction="column" justifyContent="center" alignItems="flex-start" spacing={2} width={'100%'} mt={2}>
      <InputThemeProvider>
        <InputMUI
          id="Standard name"
          name="Standard name"
          label="Standard name"
          placeholder="eg. Color"
          value={standardState?.name ?? ''}
          sx={{ width: '100%' }}
          debounce={true}
          onChange={(e) => setStandardState({ ...standardState, name: e.target.value })}
        />
        <InputMUI
          id="Description"
          name="Description"
          label="Description"
          multiline={true}
          rows={4}
          value={standardState?.description ?? ''}
          sx={{ width: '100%' }}
          debounce={true}
          onChange={(e) => setStandardState({ ...standardState, description: e.target.value })}
        />
      </InputThemeProvider>
      {allowUploadImages ? (
        <ImageDropZone
          initialURL={`food-component/${id}/standards/${activeStandardId ?? standardState.id}`}
          uploadLink={() => getImageUploadLink(`food-component/${id}/standards/${activeStandardId ?? standardState.id}/`)}
          disabled={userRoles.includes(Permission.UPLOAD_IMAGE)}
          imageURLs={standardState.imageURLs ?? []}
          setImageURLs={(imageURLs) => setStandardState({ ...standardState, imageURLs })}
        />
      ) : (
        <Stack direction="row" alignItems="center" spacing={1}>
          <InfoIcon sx={{ color: '#0000008F' }} />
          <Typography sx={styles.imageWarning}>You can add images after creating the component.</Typography>
        </Stack>
      )}
      <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={'8px'} width={'100%'}>
        <Button
          variant="text"
          color="primary"
          size="medium"
          disabled={!standardState.name}
          onClick={() => {
            if (isNil(activeStandardId)) {
              handleAddStandard();
            } else {
              handleUpdateStandard();
            }
          }}
        >
          {isNil(activeStandardId) ? 'Add' : 'Update'}
        </Button>
        <Button variant="text" color="inherit" size="medium" onClick={handleClose}>
          Cancel
        </Button>
      </Stack>
    </Stack>
  );
};
export default ComponentStandardsPopup;
