import { TableCell, TableRow } from '@mui/material';
import { startCase } from 'lodash-es';

const styles = {
  tableHeadText: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '24px'
  },
  tableCellType: {
    borderRadius: '8px 0 0 0',
    border: 0,
    width: '20%'
  },
  tableCellChef: {
    width: '20%'
  },
  tableCellStatus: {
    width: '20%'
  },
  tableCellComments: {
    width: '30%'
  },
  tableCellActions: {
    width: '10%',
    borderRadius: '0 8px 0 0'
  }
};

const TableHeaderRow = ({ type }: { type: 'ingredient' | 'component' }) => {
  return (
    <TableRow>
      <TableCell sx={{ ...styles.tableHeadText, ...styles.tableCellType }}>{startCase(type)}</TableCell>
      <TableCell
        sx={{
          ...styles.tableHeadText,
          ...styles.tableCellChef
        }}
      >
        Chef
      </TableCell>
      <TableCell sx={{ ...styles.tableHeadText, ...styles.tableCellStatus }}>Status</TableCell>
      <TableCell
        sx={{
          ...styles.tableHeadText,
          ...styles.tableCellComments
        }}
      >
        Comments
      </TableCell>
      <TableCell
        sx={{
          ...styles.tableHeadText,
          ...styles.tableCellComments
        }}
      >
        Share
      </TableCell>
    </TableRow>
  );
};

export default TableHeaderRow;
