import { SxProps, Theme } from '@mui/material';
import { caloTheme } from 'assets/images/theme/calo';

export const styles: { [key: string]: SxProps<Theme> | undefined } = {
  card: {
    width: 'full',
    border: 'none',
    boxShadow: 2,
    borderRadius: '8px',
    [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
      flexDirection: 'column'
    }
  },
  createInfoStack: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px'
  },
  createInfoInnerStack: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    gap: '4px'
  },
  componentInfoText: {
    fontSize: '24px',
    fontWeight: 400,
    lineHeight: '150%'
  },
  createdDateText: {
    color: caloTheme.palette.neutral900,
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '14px'
  },
  createdByText: {
    color: caloTheme.palette.neutral900,
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '14px'
  },
  componentTextFieldContainer: {
    padding: '24px 16px 16px 16px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  textFieldStack: {
    width: '80%',
    mx: 2
  },
  nameInputStack: {
    mt: 3,
    mb: 2,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: 2
  },
  textField: {
    width: '50%'
  },
  internalName: {
    width: '100%',
    mb: 2
  },
  textFieldInputProps: {
    inputProps: { style: { borderRadius: '16px' } },
    style: { borderRadius: '16px' }
  },
  allergensStack: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    mt: '12px'
  },
  ingredientChips: {
    fontWeight: 600
  },
  ingredientChip: {
    color: caloTheme.palette.red,
    backgroundColor: caloTheme.palette.secondaryPink100,
    fontSize: '14px',
    mb: '15px'
  }
};
