import { SxProps, Theme } from '@mui/material-v6';
import { DatePicker, DatePickerProps, LocalizationProvider } from '@mui/x-date-pickers-v6';
import { AdapterDayjs } from '@mui/x-date-pickers-v6/AdapterDayjs';
import { format } from 'date-fns';
import dayjs from 'dayjs';
import CustomDay from './CustomDay';
interface DailyMenuDatePickerProps {
  selectedDay?: Date | string | null;
  enabledDays?: string[];
  disabled?: boolean;
  placeholder?: string;
  sx?: SxProps<Theme>;
  shouldDisableDate?: (day: Date) => boolean;
  setSelectedDay: (day: Date) => void;
}

const DailyMenuDatePicker = ({
  selectedDay = null,
  disabled,
  placeholder,
  sx = {},
  enabledDays = [],
  shouldDisableDate,
  setSelectedDay
}: DailyMenuDatePickerProps) => {
  const shouldDisable = (day: dayjs.Dayjs) => {
    if (enabledDays?.length === 0) {
      return shouldDisableDate ? shouldDisableDate(day.toDate()) : false;
    }
    if (enabledDays.includes(format(day.toDate(), 'yyyy-MM-dd'))) {
      return false;
    }
    return true;
  };

  const valueProp: DatePickerProps<dayjs.Dayjs> & React.RefAttributes<HTMLDivElement> = {};

  // Only pass value if selectedDay is defined and not null
  if (selectedDay !== undefined && selectedDay !== null && selectedDay !== '') {
    valueProp.value = dayjs(selectedDay);
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        onChange={(day) => {
          if (day) {
            setSelectedDay(day.toDate());
          }
        }}
        sx={sx as any}
        disabled={disabled}
        shouldDisableDate={(day: dayjs.Dayjs) => shouldDisable(day)}
        slots={{
          day: CustomDay
        }}
        label={placeholder}
        {...valueProp}
      />
    </LocalizationProvider>
  );
};

export default DailyMenuDatePicker;
