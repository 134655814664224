import { ChevronRight, FilterAltRounded } from '@mui/icons-material';
import { Breadcrumbs, Button, Pagination, Stack, Typography, useTheme } from '@mui/material';
import { getListWithParams } from 'actions';
import { caloTheme } from 'assets/images/theme/calo';
import CaloLoader from 'components/CaloLoader';
import FiltersDrawer from 'components/FiltersDrawer';
import { CateringOrder, PaginatedBusinessAccountDeliveries } from 'lib/interfaces';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import { convertStatus, getDefaultFilters } from '../helpers';
import CateringFilterItems from './CateringFilterItems';
import CateringOrdersListTable from './CateringOrdersListTable';

interface OrdersListProps {
  history: any;
}

export default function CateringOrdersList({ history }: OrdersListProps) {
  const location = useLocation();
  const theme = useTheme();
  const [page, setPage] = useState(0);
  const searchParams = new URLSearchParams(location.search);
  const [filters, setFilters] = useState(getDefaultFilters());
  const [isFilterOpened, setIsFilterOpened] = useState(false);

  const rightButtonsStyling = {
    color: theme.palette.black,
    borderColor: theme.palette.black,
    borderRadius: '3px',
    paddingX: '20px',
    paddingY: '8px',
    '&:hover': {
      backgroundColor: theme.palette.grey[200],
      borderColor: theme.palette.black
    }
  };

  const { data, isLoading, isFetching } = useQuery<any, Error, PaginatedBusinessAccountDeliveries<CateringOrder>>(
    [
      'business/deliveries',
      {
        page,
        filters: {
          kitchen: filters.kitchen,
          status: convertStatus(filters.status),
          date: { lte: filters.deliveryDate, gte: filters.deliveryDate },
          createdAt: { lte: filters.orderDate, gte: filters.orderDate },
          referenceId: filters.referenceId?.replace('CI-', ''),
          userId: filters.userId,
          userType: 'catering'
        },
        limit: 10
      }
    ],
    getListWithParams,
    {
      keepPreviousData: true,
      onSuccess: () => {
        searchParams.set('filters', JSON.stringify(filters));
        history.push({
          pathname: location.pathname,
          search: searchParams.toString()
        });
      }
    }
  );

  return (
    <Stack px={2.5} rowGap={2.5} alignItems={'flex-start'}>
      <FiltersDrawer isFilterOpened={isFilterOpened} setIsFilterOpened={setIsFilterOpened}>
        <CateringFilterItems
          filters={filters}
          setFilters={setFilters}
          toggleFiltersDrawer={(showFiltersDrawer) => setIsFilterOpened(showFiltersDrawer)}
          businessOrders={data?.data ?? []}
          isLoading={isLoading}
        />
      </FiltersDrawer>
      <Breadcrumbs separator={<ChevronRight scale={0.5} />} aria-label="breadcrumb">
        <Typography key={'1'}>Business</Typography>
        <Typography key={'2'} sx={{ color: 'black' }}>
          Catering
        </Typography>
      </Breadcrumbs>
      <Typography
        variant="h5"
        sx={{
          my: 'auto',
          fontWeight: 600,
          fontSize: '28px',
          lineHeight: '34px',
          fontFamily: 'Roboto',
          color: caloTheme.palette.neutral900
        }}
      >
        Catering
      </Typography>
      <Button
        startIcon={<FilterAltRounded />}
        variant="outlined"
        onClick={() => setIsFilterOpened(true)}
        sx={{ ...rightButtonsStyling }}
      >
        <Typography fontWeight="600" fontSize="15px">
          Filter
        </Typography>
      </Button>
      {isLoading ? (
        <Stack sx={{ width: '100%', justifyContent: 'center' }}>
          <CaloLoader />
        </Stack>
      ) : (
        <CateringOrdersListTable data={data?.data ?? []} isLoading={isFetching} />
      )}
      <Pagination
        onChange={(_, newPage) => setPage(newPage - 1)}
        sx={{
          alignSelf: 'center'
        }}
        count={Math.ceil((data?.meta.total ?? 0) / 10)}
        showFirstButton
        showLastButton
      />
    </Stack>
  );
}
