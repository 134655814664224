import { startCase } from 'lodash-es';

import { Brand, MenuTagValue } from '@calo/types';
import CloseIcon from '@mui/icons-material/Close';
import DoDisturbAltIcon from '@mui/icons-material/DoDisturbAlt';
import HeartRounded from '@mui/icons-material/Favorite';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import StarSharp from '@mui/icons-material/StarSharp';

import { Box, Card, IconButton, Stack, Tooltip, Typography, colors } from '@mui/material-v6';

import { Repeat } from '@mui/icons-material';
import { caloTheme } from 'assets/images/theme/calo';
import { caloThemeV2 } from 'assets/themev2';
import { getFoodAggregatedRatingsForPeriod, menuTagLabelColor } from 'lib/helpers';
import { LastUsedOnMenuReq, MenuFood } from 'lib/interfaces';
import MenuFoodDietTags from '../MenuFoodDietTags';

interface MenuTableCardProps {
  brand: Brand;
  food: MenuFood;
  foodLabel: any;
  foodCost: number;
  setFoodToReplace: React.Dispatch<
    React.SetStateAction<
      | {
          food: MenuFood;
          menuId?: string;
        }
      | undefined
    >
  >;
  isEditable: boolean;
  removeFood: ((ids: string[]) => void) | undefined;
  isMealUsedInWeekRange: (lastUsedDate?: LastUsedOnMenuReq) => boolean;
  handleSelectMealInfo: (food: any) => void;
  handleRemoveAllSizes: (food: MenuFood) => void;
}

const MenuTableCard = ({
  food,
  handleSelectMealInfo,
  isMealUsedInWeekRange,
  foodLabel,
  isEditable,
  removeFood,
  setFoodToReplace,
  foodCost,
  handleRemoveAllSizes
}: MenuTableCardProps) => {
  const foodLabelIcon = (label: any) => {
    const backgroundColor = Object.keys(menuTagLabelColor).find((key) => label.value.includes(key))
      ? menuTagLabelColor[label.value]
      : caloTheme.palette.white;

    if (label && !label.value.includes('NONE')) {
      return (
        <Box
          display={'flex'}
          flexDirection={'row'}
          justifyContent="center"
          sx={{
            minWidth: '45px',
            color: 'white',
            mt: '2px',
            padding: '4px',
            borderRadius: '4px',
            backgroundColor: backgroundColor
          }}
        >
          <Typography
            display={label.value.length > 0 ? '' : 'none'}
            variant="caption"
            sx={{
              fontWeight: 600,
              fontSize: '10px',
              lineHeight: '12px',
              textAlign: 'center'
            }}
          >
            {label.value.includes(MenuTagValue.UPGRADED) ? 'IMPROVED' : startCase(label.value)}
          </Typography>
        </Box>
      );
    }
  };

  const aggregatedRatings = getFoodAggregatedRatingsForPeriod(food, 90);
  return (
    <Card
      variant="outlined"
      sx={{
        padding: 1,
        marginTop: 1,
        borderRadius: '4px',
        width: '100%',
        zIndex: 0,
        overflow: 'visible',
        borderColor: caloThemeV2.palette.grey[300]
      }}
      key={food.id}
    >
      <Box display={'flex'} flexDirection={'column'}>
        {!!(foodLabel?.find((r: any) => r.foodId === food.id)?.value.length > 0) && (
          <Stack sx={{ marginTop: '-16px', mb: '2px', zIndex: 10, overflow: 'visible', flexDirection: 'row' }}>
            <Typography>{foodLabelIcon(foodLabel?.find((r: any) => r.foodId === food.id))}</Typography>
          </Stack>
        )}
        <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} width={'100%'}>
          <Stack display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
            <Typography
              variant="h6"
              sx={{
                fontFamily: caloTheme.typography.fontFamily,
                fontWeight: 400,
                fontSize: '16px',
                lineHeight: '19px',
                color: '#000000',
                textTransform: 'capitalize',
                mt: '2px',
                mr: '4px'
              }}
            >
              {`${food.name.en}`}
            </Typography>
          </Stack>
          <Stack display={isEditable ? 'flex' : 'none'} flexDirection={'row'} justifyContent={'end'}>
            <Typography
              sx={{
                fontFamily: caloTheme.typography.fontFamily,
                fontWeight: 600,
                fontSize: '16px',
                lineHeight: '19px',
                color: foodCost > 26 ? caloTheme.palette.red : caloTheme.palette.black,
                mt: '2px'
              }}
              variant={'h6'}
            >
              {foodCost || 0}%
            </Typography>
          </Stack>
        </Box>

        <Stack sx={{ flexDirection: 'row', alignItems: 'center', gap: 1, marginTop: 1 }}>
          <Stack
            sx={{
              flexDirection: 'row',
              padding: '4px',
              px: 1,
              gap: 1,
              backgroundColor: colors.amber[50],
              borderRadius: 2,
              alignItems: 'center'
            }}
          >
            <StarSharp sx={{ width: '16px', color: colors.amber[500] }} />
            <Typography sx={{ fontSize: '14px', fontWeight: 400, lineHeight: '14px', textAlign: 'start', my: 'auto' }}>
              {!!food?.totalRating && !!food?.numberOfRatings ? (food?.totalRating / food?.numberOfRatings).toFixed(2) : 0} (
              {food?.numberOfRatings ? food?.numberOfRatings : 0})
            </Typography>
          </Stack>
          <Stack
            sx={{
              flexDirection: 'row',
              padding: '4px',
              px: 1,
              gap: 1,
              backgroundColor: colors.amber[50],
              borderRadius: 2,
              alignItems: 'center'
            }}
          >
            <StarSharp sx={{ width: '16px', color: colors.amber[500] }} />
            <Typography sx={{ fontSize: '14px', fontWeight: 400, lineHeight: '14px', textAlign: 'start', my: 'auto' }}>
              {aggregatedRatings && aggregatedRatings.average && aggregatedRatings.count
                ? `${aggregatedRatings.average} (${aggregatedRatings.count})`
                : `0 (0)`}
            </Typography>
          </Stack>
        </Stack>

        <Stack sx={{ flexDirection: 'row', gap: 1, marginTop: 1 }}>
          <Stack
            sx={{
              mt: '1px',
              py: '4px',
              px: '8px',
              backgroundColor: colors.red[100],
              flexDirection: 'row',
              borderRadius: 2,
              alignItems: 'center'
            }}
          >
            <HeartRounded sx={{ fontSize: 16, color: colors.red[800] }} />
            <Typography
              sx={{ fontSize: '14px', fontWeight: 400, lineHeight: '14px', textAlign: 'start', my: 'auto', padding: '4px' }}
            >
              {food?.totalNumberOfFavorites ?? 0}
            </Typography>
          </Stack>
          <Stack
            sx={{
              mt: '1px',
              py: '4px',
              px: '8px',
              flexDirection: 'row',
              backgroundColor: colors.red[100],
              borderRadius: 2,
              alignItems: 'center'
            }}
          >
            <DoDisturbAltIcon sx={{ fontSize: 16, color: colors.red[800] }} />
            <Typography
              sx={{ fontSize: '14px', fontWeight: 400, lineHeight: '14px', textAlign: 'start', my: 'auto', padding: '4px' }}
            >
              {food?.totalNumberOfBlocks ?? 0}
            </Typography>
          </Stack>
        </Stack>
        <Stack
          sx={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            mt: 1,
            mb: '-4px'
          }}
        >
          <Typography sx={{ display: 'flex', mt: '3px', flexDirection: 'row', justifyContent: 'space-evenly' }}>
            {food.tags?.map((r) => <MenuFoodDietTags key={r} tag={r} />)}
          </Typography>

          <Typography>
            {isEditable && isMealUsedInWeekRange(food.lastUsedOnMenu) && (
              <Tooltip
                title={
                  <span>
                    Last Used on: <br />
                    {food.usedOnMenu?.slice(-3)?.map((a, index) => (
                      <span key={index}>
                        {a.date}
                        {index < (food.usedOnMenu?.length ?? 0) - 1 && <br />}
                      </span>
                    ))}
                  </span>
                }
              >
                <Typography
                  sx={{
                    borderRadius: '37px',
                    padding: '8px 12px 8px 12px',
                    gap: '8px',
                    fontSize: '14px',
                    fontWeight: 600,
                    color: caloThemeV2.palette.error.contrastText,
                    backgroundColor: caloThemeV2.palette.error.dark
                  }}
                >
                  Used
                </Typography>
              </Tooltip>
            )}
          </Typography>
        </Stack>

        <Stack display={'flex'} flexDirection={'row'} justifyContent={'space-between'} sx={{ mt: 2 }}>
          <InfoOutlinedIcon
            sx={{ cursor: 'pointer', color: caloThemeV2.palette.action.active }}
            onClick={() => handleSelectMealInfo(food)}
          />
          {isEditable && (
            <>
              <IconButton
                sx={{ width: '24px', height: '24px', cursor: 'pointer' }}
                onClick={() => setFoodToReplace({ food: food })}
              >
                <Repeat sx={{ width: '24px', height: '24px', color: caloThemeV2.palette.action.active }} />
              </IconButton>
              <CloseIcon
                sx={{ cursor: 'pointer', color: caloThemeV2.palette.action.active }}
                onClick={() => removeFood && handleRemoveAllSizes(food)}
              />
            </>
          )}
        </Stack>
      </Box>
    </Card>
  );
};
export default MenuTableCard;
