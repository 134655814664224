import { useEffect, useRef, useState } from 'react';

import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid2,
  Radio,
  RadioGroup,
  Stack,
  Typography
} from '@mui/material-v6';

import { Country, Subscription } from '@calo/types';
import { caloTheme } from 'assets/images/theme/calo';
import { InputMUI } from 'components/MUI';
import SelectMUI from 'components/MUI/SelectMUI';
import { getLabelFromValue, gymNameOptions, heardFromOptions } from './GymMembershipOptions';
import { GymMembershipSelectCustomStyles } from 'lib/componentStyles';
import { useMutation } from 'react-query';
import { postGymMembershipCreated } from 'actions/subscription';
import useGymMembership from './useGymMembership';

interface GymMembershipCardProps {
  subscription: Subscription;
}

const GymMembershipCard = ({ subscription }: GymMembershipCardProps) => {
  const [isActiveGymMembership, setIsActiveGymMembership] = useState<boolean>(false);
  const [selectedHeardFrom, setSelectedHeardFrom] = useState<string>('');
  const [selectedGym, setSelectedGym] = useState<string>('');
  const [otherHeardFrom, setOtherHeardFrom] = useState<string | undefined>();
  const otherHeardFromInputRef = useRef<HTMLInputElement | null>(null);
  const [hasSubmitted, setHasSubmitted] = useState<boolean>(false);
  const { mutate: createGymMembershipMutation } = useMutation(postGymMembershipCreated);

  const { gymMembershipExistingData, isLoading } = useGymMembership(subscription.id);

  useEffect(() => {
    if (gymMembershipExistingData) {
      setIsActiveGymMembership(gymMembershipExistingData.isActiveGymMembership);
      setSelectedHeardFrom(getLabelFromValue(heardFromOptions, gymMembershipExistingData.heardFrom || ''));
      setSelectedGym(getLabelFromValue(gymNameOptions, gymMembershipExistingData.gymName || ''));
      setOtherHeardFrom(gymMembershipExistingData.otherHeardFrom || undefined);
    }
  }, [gymMembershipExistingData]);

  const handleSelect = (event) => {
    setIsActiveGymMembership(event.target.value === 'true');
  };

  const validInput = () => {
    if (!isActiveGymMembership) {
      return false;
    }
    if (selectedHeardFrom === '' || selectedGym === '') {
      return false;
    }
    if (selectedHeardFrom === 'other' && otherHeardFrom === '') {
      return false;
    }
    return true;
  };

  const handleSubmit = () => {
    createGymMembershipMutation({
      isActiveGymMembership,
      heardFrom: selectedHeardFrom,
      otherHeardFrom,
      gymName: selectedGym,
      id: subscription.id
    });
    setHasSubmitted(true);
  };

  if (subscription.country !== Country.SA) {
    return <></>;
  }

  return (
    <div style={{ padding: '15px' }}>
      <Box
        sx={{
          display: 'flex',
          direction: 'row',
          width: 'full',
          justifyContent: 'space-between',
          padding: '12px',
          backgroundColor: caloTheme.palette.neutral50,
          borderRadius: 2
        }}
      >
        <Typography
          variant="h6"
          sx={{
            fontSize: '19px',
            lineHeight: '23px',
            my: 'auto',
            fontFamily: caloTheme.typography.fontFamily,
            fontWeight: 600
          }}
        >
          Gym Membership
        </Typography>
      </Box>
      <Stack
        sx={{
          display: 'flex',
          direction: 'row',
          width: 'full',
          justifyContent: 'space-between',
          paddingTop: '24px',
          paddingBottom: '24px',
          paddingLeft: '16px',
          paddingRight: '16px',
          gap: '24px'
        }}
      >
        <FormControl>
          <FormLabel
            sx={{
              textAlign: 'left',
              fontSize: '16px',
              lineHeight: '24px',
              fontFamily: caloTheme.typography.fontFamily,
              fontWeight: 400,
              color: caloTheme.palette.black,
              '&.Mui-focused': { color: caloTheme.palette.black }
            }}
          >
            Does this user have an active gym membership?*
          </FormLabel>
          <RadioGroup row name="gym-membership-active" defaultValue={'false'} value={isActiveGymMembership ? 'true' : 'false'}>
            <FormControlLabel
              label="Yes"
              labelPlacement="end"
              value={'true'}
              control={
                <Radio
                  checked={isActiveGymMembership}
                  disabled={isLoading || hasSubmitted || gymMembershipExistingData?.isActiveGymMembership}
                  onChange={handleSelect}
                />
              }
              sx={{
                fontSize: '16px',
                lineHeight: '24px',
                fontFamily: caloTheme.typography.fontFamily,
                fontWeight: 400,
                letterSpacing: '0.15px'
              }}
            />
            <FormControlLabel
              label="No"
              labelPlacement="end"
              value={'false'}
              control={
                <Radio
                  checked={!isActiveGymMembership}
                  disabled={isLoading || hasSubmitted || gymMembershipExistingData?.isActiveGymMembership}
                  onChange={handleSelect}
                />
              }
              sx={{
                fontSize: '16px',
                lineHeight: '24px',
                fontFamily: caloTheme.typography.fontFamily,
                fontWeight: 400,
                letterSpacing: '0.15px'
              }}
            />
          </RadioGroup>
        </FormControl>
        {isActiveGymMembership && (
          <Grid2 container spacing={2}>
            <Grid2 size={{ xs: 12, sm: 6 }}>
              <SelectMUI
                label="How did they hear about the gym offer?"
                placeholder={selectedHeardFrom ?? 'Select option'}
                isRequiredField
                options={heardFromOptions}
                customStyles={GymMembershipSelectCustomStyles}
                value={selectedHeardFrom}
                onChange={(data: any) => {
                  setSelectedHeardFrom(data.value);
                  if (data.value !== 'other') {
                    setOtherHeardFrom(undefined);
                    if (otherHeardFromInputRef.current) {
                      otherHeardFromInputRef.current.value = '';
                    }
                  }
                }}
                isDisabled={isLoading || gymMembershipExistingData?.isActiveGymMembership || hasSubmitted}
              />
            </Grid2>

            <Grid2 size={{ xs: 12, sm: 6 }} alignContent={'flex-end'}>
              <InputMUI
                ref={otherHeardFromInputRef}
                label="Other (optional)"
                placeholder="Enter how they heard about the gym offer"
                sx={{ width: '100%' }}
                name="other"
                InputLabelProps={{
                  shrink: true
                }}
                disabled={
                  selectedHeardFrom !== 'other' || isLoading || gymMembershipExistingData?.isActiveGymMembership || hasSubmitted
                }
                debounce
                value={otherHeardFrom ?? ''}
                onChange={(data: any) => {
                  setOtherHeardFrom(data.target.value);
                }}
              />
            </Grid2>

            <Grid2 size={{ xs: 12, sm: 6 }}>
              <SelectMUI
                label="Which gym are they selecting?"
                placeholder={selectedGym ?? 'Select option'}
                isRequiredField
                options={gymNameOptions}
                customStyles={GymMembershipSelectCustomStyles}
                value={selectedGym}
                onChange={(data: any) => {
                  setSelectedGym(data.value);
                }}
                isDisabled={isLoading || gymMembershipExistingData?.isActiveGymMembership || hasSubmitted}
              />
            </Grid2>

            <Grid2 size={{ xs: 12, sm: 6 }}>
              <SelectMUI
                label="Which branch?"
                placeholder="Select a branch"
                customStyles={GymMembershipSelectCustomStyles}
                isDisabled={isLoading || gymMembershipExistingData?.isActiveGymMembership || hasSubmitted}
              />
            </Grid2>
          </Grid2>
        )}
        <Button
          variant="contained"
          color={validInput() ? 'primary' : 'inherit'}
          size="large"
          disabled={
            !validInput() ||
            !isActiveGymMembership ||
            isLoading ||
            gymMembershipExistingData?.isActiveGymMembership ||
            hasSubmitted
          }
          sx={{ width: '159px', fontSize: '15px' }}
          onClick={() => handleSubmit()}
        >
          Save Changes
        </Button>
      </Stack>
    </div>
  );
};

export default GymMembershipCard;
