import DateFnsAdapter from '@date-io/date-fns';
import { TextField, TextFieldProps } from '@mui/material';
import { Button, Stack, Typography } from '@mui/material-v6';
import { LocalizationProvider, PickersDay, StaticDatePicker, pickersDayClasses } from '@mui/x-date-pickers';
import { caloTheme } from 'assets/images/theme/calo';
import { caloThemeV2 } from 'assets/themev2';
import InputThemeProvider from 'components/MUI/InputThemeProvider';
import { ModalRef } from 'components/Modal';
import Popup from 'components/Popup';
import { format, getWeekYear, startOfWeek } from 'date-fns';
import { findStartAndEndDateForWeek } from 'lib/helpers';
import { useEffect, useRef, useState } from 'react';
import PaperContent from '../WeeklyMenuActionsCard/PaperContent';

interface DuplicateWeeklyMenuCalendarPopupProps {
  duplicateCalendarRef: React.MutableRefObject<ModalRef | undefined>;
  duplicateWeek: number;
  isSuccess: boolean;
  hasMenu?: boolean;
  duplicateMenuLoading: boolean;
  setDuplicateWeek: React.Dispatch<React.SetStateAction<number>>;
  duplicateMenuHandler: (week: number, year: number) => void;
}

const DuplicateWeeklyMenuCalendarPopup = ({
  duplicateCalendarRef,
  duplicateMenuLoading,
  isSuccess,
  hasMenu,
  duplicateMenuHandler,
  duplicateWeek,
  setDuplicateWeek
}: DuplicateWeeklyMenuCalendarPopupProps) => {
  const confirmationRef = useRef<ModalRef>();
  const [day, setDay] = useState(new Date());

  const { startOfWeekDate, endOfWeekDate } = findStartAndEndDateForWeek(duplicateWeek);

  useEffect(() => {
    if (isSuccess && !duplicateMenuLoading) {
      duplicateCalendarRef.current?.close();
      confirmationRef.current?.close();
    }
  }, [isSuccess, duplicateMenuLoading]);

  const handleRenderDateInput = (params: TextFieldProps) => {
    const { inputProps, ...rest } = params;
    const updatedInputProps = {
      ...inputProps,
      value: `Week ${duplicateWeek} (${format(startOfWeekDate, 'dd/MM')} - ${format(endOfWeekDate, 'dd/MM')})`
    };

    return (
      <InputThemeProvider>
        <TextField
          {...rest}
          inputProps={updatedInputProps}
          sx={{
            mr: 2,
            width: '20%',
            minWidth: '240px',
            [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
              mt: 6,
              width: '100%',
              display: 'flex',
              borderRadius: '8px',
              paddingLeft: '30px',
              flexDirection: 'column',
              justifyContent: 'center'
            }
          }}
          error={false}
        />
      </InputThemeProvider>
    );
  };

  const handleRenderDay = (day: Date, DayComponentProps: any) => {
    if (
      format(day, 'yyyy-MM-dd') < format(startOfWeekDate, 'yyyy-MM-dd') ||
      format(day, 'yyyy-MM-dd') > format(endOfWeekDate, 'yyyy-MM-dd')
    ) {
      return <PickersDay sx={{ backgroundColor: 'none' }} {...DayComponentProps} />;
    }
    return (
      <PickersDay
        {...DayComponentProps}
        onClick={() => null}
        sx={{
          color: 'black',
          backgroundColor: caloTheme.palette.primary100,
          ':hover': { color: 'black', backgroundColor: caloTheme.palette.neutral100 },
          [`&&.${pickersDayClasses.selected}`]: {
            border: 1,
            color: 'black',
            backgroundColor: caloTheme.palette.neutral100,
            borderColor: caloTheme.palette.primary600
          }
        }}
      />
    );
  };

  const handleDoneClick = () => {
    const year = getWeekYear(startOfWeek(new Date(day), { weekStartsOn: 0 }));
    duplicateMenuHandler(duplicateWeek, year);
  };

  return (
    <>
      <Popup maxWidth="sm" ref={duplicateCalendarRef} onClose={() => duplicateCalendarRef.current?.close()}>
        <Typography sx={{ fontSize: '20px', fontWeight: 600 }}>Duplicate from</Typography>
        <LocalizationProvider dateAdapter={DateFnsAdapter}>
          <StaticDatePicker
            components={{
              // eslint-disable-next-line react/no-multi-comp
              PaperContent: (props) => (
                <PaperContent
                  {...props}
                  disableFutureWeeks={true}
                  selectedDate={day}
                  selectedWeek={duplicateWeek}
                  setSelectedWeek={setDuplicateWeek}
                  setSelectedYear={() => {}}
                />
              )
            }}
            label="Week"
            onChange={() => {}}
            value={day}
            displayStaticWrapperAs="desktop"
            renderInput={handleRenderDateInput}
            onMonthChange={(event) => setDay(new Date(event))}
            renderDay={(day, _value, DayComponentProps) => handleRenderDay(new Date(day), DayComponentProps)}
          />
        </LocalizationProvider>
        <Stack sx={{ flexDirection: 'row', gap: 2, alignItems: 'center', alignSelf: 'flex-end' }}>
          <Button
            variant="text"
            disabled={duplicateMenuLoading}
            onClick={() => {
              duplicateCalendarRef.current?.close();
              confirmationRef.current?.open();
            }}
          >
            {'Duplicate'}
          </Button>
          <Button
            variant="text"
            onClick={() => duplicateCalendarRef.current?.close()}
            disabled={duplicateMenuLoading}
            sx={{
              color: caloThemeV2.palette.text.primary
            }}
          >
            Cancel
          </Button>
        </Stack>
      </Popup>
      <Popup
        maxWidth="sm"
        ref={confirmationRef}
        onClose={() => {
          duplicateCalendarRef.current?.close();
          confirmationRef.current?.close();
        }}
      >
        <Stack sx={{ width: '500px' }}>
          <Typography sx={{ fontSize: '20px', fontWeight: 600 }}>Confirm duplication</Typography>
          <Stack>
            <Typography sx={{ marginTop: 2 }}>
              Duplicate the menu for week {duplicateWeek} ({format(startOfWeekDate, 'yyyy-MM-dd')} -{' '}
              {format(endOfWeekDate, 'yyyy-MM-dd')})
            </Typography>
            {hasMenu && <Typography>This will override the existing menu</Typography>}
          </Stack>
          <Stack sx={{ flexDirection: 'row', gap: 2, alignItems: 'center', alignSelf: 'flex-end', marginTop: 3 }}>
            <Button variant="outlined" disabled={duplicateMenuLoading} onClick={handleDoneClick}>
              Duplicate
            </Button>
            <Button
              variant="text"
              onClick={() => confirmationRef.current?.close()}
              disabled={duplicateMenuLoading}
              sx={{
                color: caloThemeV2.palette.text.primary
              }}
            >
              Cancel
            </Button>
          </Stack>
        </Stack>
      </Popup>
    </>
  );
};

export default DuplicateWeeklyMenuCalendarPopup;
