import { SyncFoodComponentReq } from '@calo/dashboard-types';
import { Box } from '@mui/material';
import { SyncFoodComponentMPErrors } from 'lib/interfaces';
import KitchenValidation from './KitchenValidation';

interface ValidationResultProps {
  validationData: SyncFoodComponentMPErrors[];
  formValues: SyncFoodComponentReq;
}

const ValidationResult = ({ validationData, formValues }: ValidationResultProps) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', py: '14px', gap: '32px', height: '100%' }}>
      {validationData &&
        formValues.kitchens.map((kitchen) => (
          <KitchenValidation
            key={kitchen}
            kitchen={kitchen}
            validationData={validationData.find((res) => res.kitchen === kitchen)?.errors}
          />
        ))}
    </Box>
  );
};

export default ValidationResult;
