import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Stack, Typography } from '@mui/material';
import { caloTheme } from 'assets/images/theme/calo';
import { format } from 'date-fns';
import { PrototypeAction } from 'lib/enums';
import { ExtendedFoodPrototypeAction } from 'lib/interfaces';

interface ActivityLogItemProps {
  type: 'meal' | 'component' | 'ingredient';
  action: ExtendedFoodPrototypeAction;
  previousStage?: PrototypeAction;
}

const actionHeaders: Record<PrototypeAction, Record<string, string>> = {
  [PrototypeAction.create]: {
    meal: 'Meal Created',
    component: 'Component Created',
    ingredient: 'Ingredient Created'
  },
  [PrototypeAction.comment]: {
    meal: 'Comment Added to Meal',
    component: 'Comment Added to Component',
    ingredient: 'Comment Added to Ingredient'
  },
  [PrototypeAction.pend]: {
    meal: 'Meal Ready to be Reviewed',
    component: 'Component Ready to be Reviewed',
    ingredient: 'Ingredient Ready to be Reviewed'
  },
  [PrototypeAction.submitForNpdApproval]: {
    meal: 'Meal sent for NPD approval',
    component: 'Component sent for NPD approval',
    ingredient: 'Ingredient sent for NPD approval'
  },
  [PrototypeAction.submitForOpsApproval]: {
    meal: 'Meal sent for Food Ops approval',
    component: 'Component sent for Food Ops approval',
    ingredient: 'Ingredient sent for Food Ops approval'
  },
  [PrototypeAction.requestChanges]: {
    meal: 'Change requested',
    component: 'Change requested',
    ingredient: 'Change requested'
  },
  [PrototypeAction.approve]: {
    meal: 'Meal Approved',
    component: 'Component Approved',
    ingredient: 'Ingredient Approved'
  }
};

const getActionHeader = (
  type: 'meal' | 'component' | 'ingredient',
  actionType: PrototypeAction,
  previousStage?: PrototypeAction
): string => {
  if (actionType === PrototypeAction.requestChanges) {
    const reviewer =
      previousStage === PrototypeAction.submitForNpdApproval
        ? ' by NPD'
        : previousStage === PrototypeAction.submitForOpsApproval
          ? ' by Food Ops'
          : '';
    return `${actionHeaders[actionType][type]}${reviewer}`;
  }

  return actionHeaders[actionType][type];
};

const ActivityLogItem = ({ type, action, previousStage }: ActivityLogItemProps) => {
  const actionHeader = getActionHeader(type, action.type, previousStage);

  return (
    <Stack sx={{ flexDirection: 'row', alignItems: 'flex-start', gap: '20px' }}>
      <Stack sx={{ backgroundColor: caloTheme.palette.primary100, borderRadius: '28px', padding: '14px' }}>
        <AccessTimeIcon color="primary" fontSize="medium" />
      </Stack>
      <Stack sx={{ gap: '6px', width: '70%', alignSelf: 'center' }}>
        <Typography sx={{ fontWeight: 600 }}>{actionHeader}</Typography>
        {(action.type === PrototypeAction.comment || action.type === PrototypeAction.requestChanges) && (
          <Typography>{action.message}</Typography>
        )}
      </Stack>
      <Stack sx={{ marginLeft: 'auto', textAlign: 'right' }}>
        <Typography>{format(new Date(action.createdAt), 'dd/MM/yyyy hh:mm a')}</Typography>
        <Typography>Added by {action.actor.name}</Typography>
      </Stack>
    </Stack>
  );
};

export default ActivityLogItem;
