import { KDSUserGroup } from 'lib/interfaces';
import { useState } from 'react';

import { Checkbox, TableCell } from '@mui/material';
import { KDSPermission } from 'lib/calo-dashboard-types';

interface TableHeadCellProps {
  setSelectedRole: React.Dispatch<React.SetStateAction<KDSUserGroup>>;
  selectedRole: KDSUserGroup | null;
  role: KDSUserGroup;
  permission: string;
}

const CheckboxCell = ({ role, setSelectedRole, selectedRole, permission }: TableHeadCellProps) => {
  const [checked, setChecked] = useState(role.permissions.includes(KDSPermission[permission]));
  const handleSelect = () => {
    if (selectedRole) {
      setChecked((old) => !old);
      setSelectedRole((old) => ({
        ...old,
        permissions: old.permissions.includes(KDSPermission[permission])
          ? old.permissions.filter((p) => p !== KDSPermission[permission])
          : [...old.permissions, KDSPermission[permission]]
      }));
    }
  };
  return (
    <TableCell>
      <Checkbox disabled={!selectedRole || selectedRole.id !== role.id} checked={checked} onClick={handleSelect} />
    </TableCell>
  );
};

export default CheckboxCell;
