import { FoodMenuLabelsTags } from '@calo/dashboard-types';
import { FoodDietType, FoodType, MenuTagValue } from '@calo/types';
import { caloTheme } from 'assets/images/theme/calo';

export const getFoodTypeColor = (type: FoodType) => {
  switch (type) {
    case FoodType.breakfast:
      return '#E4C159';
    case FoodType.lunch:
    case FoodType.dinner:
      return '#57AE7F';
    case FoodType.coffee:
    case FoodType.juice:
      return '#7466A2';
    case FoodType.snack:
      return '#4F89A3';
    case FoodType.dessert:
    case FoodType.salad:
      return '#000000';
    case FoodType.caloKids:
      return '#1c2fba';
    case FoodType.kidsLunchBox24:
      return '#A0D035';
    case FoodType.kidsLunchBox58:
      return '#FFD600';
    case FoodType.kidsLunchBox912:
      return '#873E8B';
    default:
      return '#D1D5DB';
  }
};

export const getTagColor = (tag: FoodDietType) => {
  switch (tag) {
    case FoodDietType.balanced:
      return '#10B981';
    case FoodDietType.vegetarian:
      return '#8B5CF6';
    case FoodDietType.lowCarb:
      return '#3B82F6';
    case FoodDietType.highProtein:
      return '#E2665C';
    case FoodDietType.gourmet:
      return '#FF7518';
    case FoodDietType.worldCup:
      return caloTheme.palette.secondaryPink500;
    case FoodDietType.newMealClub:
      return caloTheme.palette.secondaryPurple500;
    case FoodDietType.preBuiltCustom:
      return caloTheme.palette.secondaryYellow500;
    case FoodDietType.weightLoss:
      return caloTheme.palette.secondaryYellow600;
    case FoodDietType.customMacros:
      return caloTheme.palette.secondaryTeal500;
    default:
      return '#D1D5DB';
  }
};

export const menuTagLabelColor: Record<string, string> = {
  [FoodMenuLabelsTags.UPGRADED]: caloTheme.palette.secondaryOrange500,
  [FoodMenuLabelsTags.NEW]: caloTheme.palette.red,
  [MenuTagValue.DAIRY_FREE]: caloTheme.palette.secondaryBlue400,
  [FoodMenuLabelsTags.SEASONAL]: caloTheme.palette.secondaryPurple400,
  [MenuTagValue.CUSTOMIZABLE]: caloTheme.palette.secondaryPurple500,
  [FoodMenuLabelsTags.TOP_RATED]: caloTheme.palette.primary500,
  [FoodMenuLabelsTags.SUMMER]: '#FCC23B',
  [FoodMenuLabelsTags.RAMADAN]: caloTheme.palette.secondaryPurple500
};
