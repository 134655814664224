export const heardFromOptions = [
  { label: 'App', value: 'app' },
  { label: 'Instagram', value: 'instagram' },
  { label: 'Tiktok', value: 'tiktok' },
  { label: 'Facebook', value: 'facebook' },
  { label: 'Blog', value: 'blog' },
  { label: 'Family & Friends', value: 'family_and_friends' },
  { label: 'Other', value: 'other' }
];

export const gymNameOptions = [{ label: 'Fitness Time', value: 'fitness_time' }];

export const getLabelFromValue = <T extends { value: string; label: string }>(options: T[], value: string): string => {
  const option = options.find((opt) => opt.value === value);
  return option ? option.label : '';
};
