import { Button, Divider, Stack, Tab, Tabs, Typography } from '@mui/material-v6';
import { caloThemeV2 } from 'assets/themev2';
import { ModalRef } from 'components/Modal';
import Popup from 'components/Popup';
import { format } from 'date-fns';
import { FoodDiff } from 'lib/interfaces';
import { useState } from 'react';
import FoodDiffItem from './FoodDiffItem';

interface ValidateComponentPopupProps {
  confirmUpdateComponentRef: React.RefObject<ModalRef>;
  foodDifferences: FoodDiff[];
  isSubmitting: boolean;
  handleSubmit: () => void;
}

const ValidateComponentPopup = ({
  confirmUpdateComponentRef,
  foodDifferences,
  isSubmitting,
  handleSubmit
}: ValidateComponentPopupProps) => {
  const [selectedTab, setSelectedTab] = useState(0);

  const usedOnMenuFoodDiffs = foodDifferences.filter((foodDiff) => foodDiff.lastUsed >= format(new Date(), 'yyyy-MM-dd'));
  const unPublishedFoodDiffs = foodDifferences.filter(
    (foodDiff) => !foodDiff.lastUsed || foodDiff.lastUsed < format(new Date(), 'yyyy-MM-dd')
  );

  return (
    <Popup ref={confirmUpdateComponentRef}>
      <Stack sx={{ width: '600px' }}>
        <Typography variant="h6" sx={{}}>
          Confirm Updates
        </Typography>
        <Typography variant="body2" sx={{ color: caloThemeV2.palette.text.secondary }}>
          Your changes may impact multiple meals. Do you want to update them all?
        </Typography>
        <Tabs
          value={selectedTab}
          onChange={(event, newValue: number) => setSelectedTab(newValue)}
          TabIndicatorProps={{
            style: {
              backgroundColor: caloThemeV2.palette.primary.main
            }
          }}
          sx={{
            marginTop: 2,
            '& .MuiTab-root.Mui-selected': {
              color: caloThemeV2.palette.primary.main
            }
          }}
        >
          <Tab label="ON THE MENU" />
          <Tab label="UNPUBLISHED" />
        </Tabs>
        <Divider sx={{ mx: -3, color: '#0000001F', marginTop: 0, marginBottom: 3 }} />
        <Typography variant="subtitle2" sx={{ marginBottom: 1 }}>
          Meals
        </Typography>
        <Stack
          sx={{
            maxHeight: '600px',
            overflowY: 'auto',
            '::-webkit-scrollbar': {
              width: '10px'
            },
            '::-webkit-scrollbar-track': {
              backgroundColor: '#EEEEEE' // Track color
            },
            '::-webkit-scrollbar-thumb': {
              backgroundColor: '#999',
              borderRadius: '10px'
            },
            '::-webkit-scrollbar-thumb:hover': {
              backgroundColor: '#666'
            }
          }}
        >
          {(selectedTab === 0 ? usedOnMenuFoodDiffs : unPublishedFoodDiffs).map((diff: FoodDiff) => (
            <FoodDiffItem key={diff.id} foodDiff={diff} />
          ))}
        </Stack>
        <Divider sx={{ marginTop: 4, marginBottom: 2, marginX: -3 }} />
        <Stack sx={{ flexDirection: 'row', gap: 2, alignSelf: 'flex-end' }}>
          <Button disabled={isSubmitting} onClick={() => handleSubmit()}>
            Update All
          </Button>
          <Button disabled={isSubmitting} onClick={() => confirmUpdateComponentRef.current?.close()}>
            Cancel
          </Button>
        </Stack>
      </Stack>
    </Popup>
  );
};

export default ValidateComponentPopup;
