export enum Kitchen {
  // XX000 -> for pre-launch
  BH0 = 'BH000',
  BH1 = 'BH001',
  SA0 = 'SA000',
  SA1 = 'SA001',
  SA2 = 'SA002',
  KW0 = 'KW000',
  KW1 = 'KW001',
  QA0 = 'QA000',
  QA1 = 'QA001',
  AE0 = 'AE000',
  AE1 = 'AE001',
  OM0 = 'OM000',
  OM1 = 'OM001',
  GB0 = 'GB000',
  GB1 = 'GB001',
  // Any other country
  NA0 = 'NA000'
}

export enum Goal {
  loseWeight = 'loseWeight',
  gainWeight = 'gainWeight',
  maintainWeight = 'maintainWeight',
  lifeStyle = 'lifeStyle',
  buildMuscle = 'buildMuscle'
}

export enum MacrosPreset {
  recommended = 'recommended',
  highProtein = 'highProtein',
  highFat = 'highFat',
  highCarbs = 'highCarbs',
  evenSplit = 'evenSplit'
}

export enum TicketType {
  UPDATE_LOCATION = 'UPDATE_LOCATION'
}

export enum PaymentMethod {
  cash = 'cash',
  cc = 'cc',
  benefit = 'benefit',
  mada = 'mada',
  applePay = 'applePay',
  knet = 'knet',
  naps = 'naps',
  bnpl = 'bnpl', // buy now pay later
  tamara = 'tamara',
  tabby = 'tabby'
}

export enum PaymentProviderType {
  TAP = 'TAP',
  CHECKOUT = 'CHECKOUT',
  TAMARA = 'TAMARA',
  TABBY = 'TABBY'
}

export enum SubscriptionStatus {
  ongoing = 'ongoing',
  paused = 'paused',
  cancelled = 'cancelled',
  suspended = 'suspended'
}

export enum SubscriptionFrequency {
  weekly = 'weekly',
  monthly = 'monthly',
  quarterly = 'quarterly'
}

export enum DeliveryStatus {
  upcoming = 'upcoming',
  paymentRequired = 'paymentRequired',
  paused = 'paused',
  cancelled = 'cancelled',
  suspended = 'suspended'
}

export enum DDeliveryStatus {
  delivering = 'delivering',
  delivered = 'delivered',
  outForDelivery = 'outForDelivery', //left kitchen,
  unableToDeliver = 'unableToDeliver' //customer not answering
}

export enum FoodType {
  breakfast = 'breakfast',
  dinner = 'dinner',
  lunch = 'lunch',
  snack = 'snack',
  dessert = 'dessert',
  caloKids = 'caloKids',
  coffee = 'coffee',
  juice = 'juice',
  salad = 'salad',
  kidsLunchBox24 = 'kidsLunchBox24',
  kidsLunchBox58 = 'kidsLunchBox58',
  kidsLunchBox912 = 'kidsLunchBox912'
}

export enum LargestPortionSizeFoodType {
  breakfast = 'breakfast',
  // lunch for now includes lunch and dinner
  lunch = 'lunch',
  snack = 'snack'
}

export enum PlanType {
  full = 'full',
  custom = 'custom',
  skipBreakfast = 'skipBreakfast',
  skipDinner = 'skipDinner',
  ramadan = 'ramadan',
  businessLunch = 'businessLunch',
  futoor = 'futoor',
  dessert = 'dessert',
  caloKids = 'caloKids',
  weightLossChallenge = 'weightLossChallenge',
  lightWeightLossChallenge = 'lightWeightLossChallenge'
}

export enum SubscriptionTier {
  standard = 'standard',
  personalized = 'personalized',
  pricingKSAVatInclusiveApril2024 = 'pricingKSAVatInclusiveApril2024',
  pricingCaloClubOct2024 = 'pricingCaloClubOct2024'
}

export enum DietType {
  balanced = 'balanced',
  vegetarian = 'vegetarian',
  lowCarb = 'low-carb',
  keto = 'keto',
  highProtein = 'high-protein',
  weightLoss = 'weight-loss',
  chefPicks = 'chef-picks',
  customMacros = 'custom-macros'
}

export enum FoodDietType {
  balanced = 'balanced',
  vegetarian = 'vegetarian',
  lowCarb = 'low-carb',
  keto = 'keto',
  highProtein = 'high-protein',
  gourmet = 'gourmet',
  worldCup = 'world-cup',
  newMealClub = 'newMealClub',
  preBuiltCustom = 'preBuiltCustom',
  weightLoss = 'weight-loss',
  chefPicks = 'chef-picks',
  customMacros = 'custom-macros'
}

export enum PortionSize {
  tooSmall = 'tooSmall',
  normal = 'normal',
  tooBig = 'tooBig'
}

export enum RatingFactor {
  FLAVOR = 'FLAVOR',
  PORTION_SIZE = 'PORTION_SIZE',
  TEXTURE = 'TEXTURE',
  FOOD_QUALITY = 'FOOD_QUALITY',
  PACKAGING = 'PACKAGING',
  DELIVERY = 'DELIVERY',
  OTHER = 'OTHER'
}

export enum Action {
  API = 'API',
  UI_SET = 'UI_SET',
  AUTH_SET_AUTH = 'AUTH_SET_AUTH',
  AUTH_SET_VERIFICATION = 'AUTH_SET_VERIFICATION',
  AUTH_SET_ME = 'AUTH_SET_ME',

  NEW_SUBSCRIPTION_SET = 'NEW_SUBSCRIPTION_SET',
  NEW_SUBSCRIPTION_SET_MACROS_DATA = 'NEW_SUBSCRIPTION_UPDATE_MACROS_DATA',

  FOOD_SET = 'FOOD_SET',
  FOOD_DAY_SET = 'FOOD_DAY_SET',

  DELIVERIES_SET = 'DELIVERIES_SET',
  DELIVERY_SET_MENU = 'DELIVERY_SET_MENU',

  DELIVERY_SET = 'DELIVERY_SET',
  DELIVERY_SET_SELECTED = 'DELIVERY_SET_SELECTED',

  SUBSCRIPTION_SET = 'SUBSCRIPTION_SET',
  SUBSCRIPTION_UNSET_KEY = 'SUBSCRIPTION_UNSET_KEY',
  SUBSCRIPTION_SET_CARDS = 'SUBSCRIPTION_SET_CARDS',
  SUBSCRIPTION_DELETE_CARD = 'SUBSCRIPTION_DELETE_CARD',
  SUBSCRIPTION_RESET_CARDS = 'SUBSCRIPTION_RESET_CARDS',
  SUBSCRIPTION_SET_CODES = 'SUBSCRIPTION_SET_CODES',
  SUBSCRIPTION_INC_AVATAR_VERSION = 'SUBSCRIPTION_INC_AVATAR_VERSION',

  LOG_LIST_SET = 'LOGS_SET',

  SUBSCRIPTION_CREATED_EVENT = 'SUBSCRIPTION_CREATED_EVENT',
  SUBSCRIPTION_PAUSED_EVENT = 'SUBSCRIPTION_PAUSED_EVENT',
  SUBSCRIPTION_RESUMED_EVENT = 'SUBSCRIPTION_RESUMED_EVENT',
  SUBSCRIPTION_AUTORENEW_ON_EVENT = 'SUBSCRIPTION_AUTORENEW_ON_EVENT',
  SUBSCRIPTION_AUTORENEW_OFF_EVENT = 'SUBSCRIPTION_AUTORENEW_OFF_EVENT',
  SUBSCRIPTION_ADDRESS_CHANGED_EVENT = 'SUBSCRIPTION_ADDRESS_CHANGED_EVENT',
  SUBSCRIPTION_PLAN_CHANGED_EVENT = 'SUBSCRIPTION_PLAN_CHANGED_EVENT',
  SUBSCRIPTION_EXTRAS_ADDED_EVENT = 'SUBSCRIPTION_EXTRAS_ADDED_EVENT',
  SUBSCRIPTION_EXTRAS_REMOVED_EVENT = 'SUBSCRIPTION_EXTRAS_REMOVED_EVENT',

  DELIVERY_SKIPPED_EVENT = 'DELIVERY_SKIPPED_EVENT',
  DELIVERY_UNSKIPPED_EVENT = 'DELIVERY_UNSKIPPED_EVENT',
  DELIVERY_ADDRESS_CHANGED_EVENT = 'DELIVERY_ADDRESS_CHANGED_EVENT',
  DELIVERY_FOOD_REPLACED_EVENT = 'DELIVERY_FOOD_REPLACED_EVENT',
  DELIVERY_EXTRAS_ADDED_EVENT = 'DELIVERY_EXTRAS_ADDED_EVENT',
  DELIVERY_DAY_CHANGED_EVENT = 'DELIVERY_FOOD_REPLACED_EVENT',

  SIGNED_IN_EVENT = 'SIGNED_IN_EVENT',
  SIGNED_UP_EVENT = 'SIGNED_UP_EVENT',
  SIGNED_OUT_EVENT = 'SIGNED_OUT_EVENT',

  RESET_STATE = 'RESET_STATE',

  BIOMETRIC_LIST_SET = 'BIOMETRIC_LIST_SET',
  BIOMETRIC_SET = 'BIOMETRIC_SET',

  SERVICE_STATUS_SET = 'SERVICE_STATUS_SET',

  WAITING_LIST_SET = 'WAITING_LIST_SET',
  WAITING_LIST_RESET = 'WAITING_LIST_RESET',

  HEALTH_TRACKER_SET_TRACKER = 'HEALTH_TRACKER_SET_TRACKER'
}

// export enum AnalyticsEvent {
//   SIGN_IN = 'SIGN_IN',
//   SIGN_UP = 'SIGN_UP',
//   SIGN_OUT = 'SIGN_OUT',

//   PAGE_VIEW = 'PAGE_VIEW',
//   CODE_VERIFICATION = 'CODE_VERIFICATION',

//   DELIVERY_SKIPPED = 'DELIVERY_SKIPPED',
//   DELIVERY_UNSKIPPED = 'DELIVERY_UNSKIPPED',
//   DELIVERY_ADDRESS_CHANGED = 'DELIVERY_ADDRESS_CHANGED',
//   DELIVERY_FOOD_REPLACED = 'DELIVERY_FOOD_REPLACED',
//   DELIVERY_EXTRAS_ADDED = 'DELIVERY_EXTRAS_ADDED',
//   DELIVERY_DAY_CHANGED = 'DELIVERY_DAY_CHANGED',

//   SUBSCRIPTION_CREATED = 'SUBSCRIPTION_CREATED',
//   SUBSCRIPTION_PAUSED = 'SUBSCRIPTION_PAUSED',
//   SUBSCRIPTION_UNPAUSED = 'SUBSCRIPTION_UNPAUSED',
//   SUBSCRIPTION_ADDRESS_CHANGED = 'SUBSCRIPTION_ADDRESS_CHANGED',
//   SUBSCRIPTION_PLAN_CHANGED = 'SUBSCRIPTION_PLAN_CHANGED',
//   SUBSCRIPTION_AUTORENEW_ON_EVENT = 'SUBSCRIPTION_AUTORENEW_ON_EVENT',
//   SUBSCRIPTION_AUTORENEW_OFF_EVENT = 'SUBSCRIPTION_AUTORENEW_OFF_EVENT',
//   SUBSCRIPTION_EXTRAS_ADDED = 'SUBSCRIPTION_EXTRAS_ADDED',
//   SUBSCRIPTION_EXTRAS_REMOVED = 'SUBSCRIPTION_EXTRAS_REMOVED',
// }

export enum LogType {
  DELIVERY_SKIPPED = 'DELIVERY_SKIPPED',
  DELIVERY_PLAN_CHANGED = 'DELIVERY_PLAN_CHANGED',
  DELIVERY_EXTRAS_ADDED = 'DELIVERY_EXTRAS_ADDED',
  DELIVERY_UNSKIPPED = 'DELIVERY_UNSKIPPED',
  DELIVERY_ADDRESS_CHANGED = 'DELIVERY_ADDRESS_CHANGED',
  DELIVERY_TIME_CHANGED = 'DELIVERY_TIME_CHANGED',
  DELIVERY_FOOD_REPLACED = 'DELIVERY_FOOD_REPLACED',
  DELIVERY_DAY_CHANGED = 'DELIVERY_DAY_CHANGED',
  DELIVERY_ADDED = 'DELIVERY_ADDED',
  SUBSCRIPTION_PAUSED = 'SUBSCRIPTION_PAUSED',
  SUBSCRIPTION_UNPAUSED = 'SUBSCRIPTION_UNPAUSED',
  SUBSCRIPTION_ADDRESS_CREATED = 'SUBSCRIPTION_ADDRESS_CREATED',
  SUBSCRIPTION_ADDRESS_CHANGED = 'SUBSCRIPTION_ADDRESS_CHANGED',
  SUBSCRIPTION_ADDRESS_DELETED = 'SUBSCRIPTION_ADDRESS_DELETED',
  SUBSCRIPTION_TIME_CHANGED = 'SUBSCRIPTION_TIME_CHANGED',
  SUBSCRIPTION_PLAN_CHANGED = 'SUBSCRIPTION_PLAN_CHANGED',
  SUBSCRIPTION_FREQUENCY_CHANGED = 'SUBSCRIPTION_FREQUENCY_CHANGED',
  SUBSCRIPTION_DIET_TYPE_CHANGED = 'SUBSCRIPTION_DIET_TYPE_CHANGED',
  SUBSCRIPTION_PLAN_TYPE_CHANED = 'SUBSCRIPTION_PLAN_TYPE_CHANED',
  SUBSCRIPTION_RENEWED = 'SUBSCRIPTION_RENEWED',
  SUBSCRIPTION_PAYMENT = 'SUBSCRIPTION_PAYMENT',
  SUBSCRIPTION_EXTRAS_ADDED = 'SUBSCRIPTION_EXTRAS_ADDED',
  SUBSCRIPTION_EXTRAS_REMOVED = 'SUBSCRIPTION_EXTRAS_REMOVED',
  GIFTED_SUBSCRIPTION_REDEEMED = 'GIFTED_SUBSCRIPTION_REDEEMED',
  CASHBACK_CREDIT_ADDED = 'CASHBACK_CREDIT_ADDED',
  DELIVERY_FOOD_COMPONENTS_MODIFIED = 'DELIVERY_FOOD_COMPONENTS_MODIFIED',
  RENEW_SUBSCRIPTION = 'RENEW SUBSCRIPTION',
  SUBSCRIPTION_AUTO_RENEW_STATUS_CHANGED = 'SUBSCRIPTION_AUTO_RENEW_STATUS_CHANGED',
  SUBSCRIPTION_MACROS_TYPE_CHANGED = 'SUBSCRIPTION_MACROS_TYPE_CHANGED'
}

export enum Period {
  week = 'week',
  month = 'month',
  quarter = 'quarter'
}

export enum RequestError {
  OUT_OF_CAPACITY = 'OUT_OF_CAPACITY',
  PAYMENT_DECLINED = 'PAYMENT_DECLINED',
  EXPIRED_CARD = 'EXPIRED_CARD',
  INSUFFICIENT_FUNDS = 'INSUFFICIENT_FUNDS'
}

export enum WaitingListAction {
  CREATE_SUBSCRIPTION = 'CREATE_SUBSCRIPTION',
  RESUME_SUBSCRIPTION = 'RESUME_SUBSCRIPTION',
  CHANGE_COUNTRY = 'CHANGE_COUNTRY'
}

export enum WaitingListType {
  PRE_LAUNCH = 'PRE_LAUNCH',
  SOFT_LAUNCH = 'SOFT_LAUNCH',
  SUPPLY_CAP = 'SUPPLY_CAP'
}

export enum CountryPhoneCode {
  AE = '+971',
  BH = '+973',
  SA = '+966',
  KW = '+965',
  OM = '+968',
  QA = '+974',
  US = '+1',
  GB = '+44'

  // AD = "+376",
  // AF = "+93",
  // AG = "+1268",
  // AI = "+1264",
  // AL = "+355",
  // AM = "+374",
  // AO = "+244",
  // AR = "+54",
  // AS = "+1684",
  // AT = "+43",
  // AU = "+61",
  // AW = "+297",
  // AX = "+35818",
  // AZ = "+994",
  // BA = "+387",
  // BB = "+1246",
  // BD = "+880",
  // BE = "+32",
  // BF = "+226",
  // BG = "+359",
  // BI = "+257",
  // BJ = "+229",
  // BL = "+590",
  // BM = "+1441",
  // BN = "+673",
  // BO = "+591",
  // BQ = "+599",
  // BR = "+55",
  // BS = "+1242",
  // BT = "+975",
  // BW = "+267",
  // BY = "+375",
  // BZ = "+501",
  // CA = "+1",
  // CC = "+61",
  // CD = "+243",
  // CF = "+236",
  // CG = "+242",
  // CH = "+41",
  // CI = "+225",
  // CK = "+682",
  // CL = "+56",
  // CM = "+237",
  // CN = "+86",
  // CO = "+57",
  // CR = "+506",
  // CU = "+53",
  // CV = "+238",
  // CW = "+599",
  // CX = "+61",
  // CY = "+357",
  // CZ = "+420",
  // DE = "+49",
  // DJ = "+253",
  // DK = "+45",
  // DM = "+1767",
  // DO = "+1809",
  // DZ = "+213",
  // EC = "+593",
  // EE = "+372",
  // EG = "+20",
  // EH = "+212",
  // ER = "+291",
  // ES = "+34",
  // ET = "+251",
  // FI = "+358",
  // FJ = "+679",
  // FK = "+500",
  // FM = "+691",
  // FO = "+298",
  // FR = "+33",
  // GA = "+241",
  // GB = "+44",
  // GD = "+1473",
  // GE = "+995",
  // GF = "+594",
  // GG = "+441481",
  // GH = "+233",
  // GI = "+350",
  // GL = "+299",
  // GM = "+220",
  // GN = "+224",
  // GP = "+590",
  // GQ = "+240",
  // GR = "+30",
  // GT = "+502",
  // GU = "+1671",
  // GW = "+245",
  // GY = "+592",
  // HK = "+852",
  // HN = "+504",
  // HR = "+385",
  // HT = "+509",
  // HU = "+36",
  // ID = "+62",
  // IE = "+353",
  // IL = "+972",
  // IM = "+441624",
  // IN = "+91",
  // IO = "+246",
  // IQ = "+964",
  // IR = "+98",
  // IS = "+354",
  // IT = "+39",
  // JE = "+441534",
  // JM = "+1876",
  // JO = "+962",
  // JP = "+81",
  // KE = "+254",
  // KG = "+996",
  // KH = "+855",
  // KI = "+686",
  // KM = "+269",
  // KN = "+1869",
  // KP = "+850",
  // KR = "+82",
  // KY = "+1345",
  // KZ = "+7",
  // LA = "+856",
  // LB = "+961",
  // LC = "+1758",
  // LI = "+423",
  // LK = "+94",
  // LR = "+231",
  // LS = "+266",
  // LT = "+370",
  // LU = "+352",
  // LV = "+371",
  // LY = "+218",
  // MA = "+212",
  // MC = "+377",
  // MD = "+373",
  // ME = "+382",
  // MF = "+590",
  // MG = "+261",
  // MH = "+692",
  // MK = "+389",
  // ML = "+223",
  // MM = "+95",
  // MN = "+976",
  // MO = "+853",
  // MP = "+1670",
  // MQ = "+596",
  // MR = "+222",
  // MS = "+1664",
  // MT = "+356",
  // MU = "+230",
  // MV = "+960",
  // MW = "+265",
  // MX = "+52",
  // MY = "+60",
  // MZ = "+258",
  // NA = "+264",
  // NC = "+687",
  // NE = "+227",
  // NF = "+672",
  // NG = "+234",
  // NI = "+505",
  // NL = "+31",
  // NO = "+47",
  // NP = "+977",
  // NR = "+674",
  // NU = "+683",
  // NZ = "+64",
  // PA = "+507",
  // PE = "+51",
  // PF = "+689",
  // PG = "+675",
  // PH = "+63",
  // PK = "+92",
  // PL = "+48",
  // PM = "+508",
  // PN = "+870",
  // PR = "+1787",
  // PS = "+970",
  // PT = "+351",
  // PW = "+680",
  // PY = "+595",
  // RE = "+262",
  // RO = "+40",
  // RS = "+381",
  // RU = "+7",
  // RW = "+250",
  // SB = "+677",
  // SC = "+248",
  // SD = "+249",
  // SE = "+46",
  // SG = "+65",
  // SH = "+290",
  // SI = "+386",
  // SJ = "+47",
  // SK = "+421",
  // SL = "+232",
  // SM = "+378",
  // SN = "+221",
  // SO = "+252",
  // SR = "+597",
  // SS = "+211",
  // ST = "+239",
  // SV = "+503",
  // SX = "+599",
  // SY = "+963",
  // SZ = "+268",
  // TC = "+1649",
  // TD = "+235",
  // TG = "+228",
  // TH = "+66",
  // TJ = "+992",
  // TK = "+690",
  // TL = "+670",
  // TM = "+993",
  // TN = "+216",
  // TO = "+676",
  // TR = "+90",
  // TT = "+1868",
  // TV = "+688",
  // TW = "+886",
  // TZ = "+255",
  // UA = "+380",
  // UG = "+256",
  // UM = "+1",
  // UY = "+598",
  // UZ = "+998",
  // VA = "+379",
  // VC = "+1784",
  // VE = "+58",
  // VG = "+1284",
  // VI = "+1340",
  // VN = "+84",
  // VU = "+678",
  // WF = "+681",
  // WS = "+685",
  // YE = "+967",
  // YT = "+262",
  // ZA = "+27",
  // ZM = "+260",
  // ZW = "+263",
}

export enum IngredientCategory {
  fish = 'fish',
  beef = 'beef',
  shellfish = 'shellfish',
  chicken = 'chicken',
  turkey = 'turkey',
  lamb = 'lamb',
  spicyMeals = 'spicyMeals',
  treeNuts = 'treeNuts',
  dairy = 'dairy',
  eggs = 'eggs',
  soy = 'soy',
  gluten = 'gluten',
  beans = 'beans',
  sesame = 'sesame',
  peanut = 'peanut',
  psylliumHusk = 'psylliumHusk',
  citric = 'citric',
  caffeine = 'caffeine',
  legumes = 'legumes',
  coriander = 'coriander',
  other = 'other'
}

export enum DislikeIngredientCategory {
  tomato = 'tomato',
  onion = 'onion',
  garlic = 'garlic',
  spicyMeals = 'spicyMeals',
  mushrooms = 'mushrooms',
  ginger = 'ginger',
  beans = 'beans',
  shellfish = 'shellfish',
  chicken = 'chicken',
  beef = 'beef',
  raisins = 'raisins',
  pickles = 'pickles',
  celery = 'celery'
}

export enum CouponType {
  FIXED = 'FIXED',
  PERCENTAGE = 'PERCENTAGE'
}

export enum FoodComponentType {
  base = 'base',
  protein = 'protein',
  side = 'side',
  topping = 'topping',
  sauce = 'sauce'
}

export enum FoodComponentIngredientAction {
  ALLUMETTE = 'Allumette 1/16" X 1/16" X 1/16"',
  BATON_STICKS = 'Baton Sticks',
  BRUNOISE = 'Brunoise 1/16" X 1/16" X 1/16"',
  CHOPPED = 'Chopped',
  CONCASSE = 'Concasse, Roughly Chopped',
  DESEEDED = 'Deseeded',
  GRATED = 'Grated',
  JUICED = 'Juiced',
  JULIENNE = 'Julienne 1/8" X 1/4" X 2"',
  LARGE_DICE = 'Large Dice 3/4" X 3/4" X 3/4"',
  MEDIUM_DICE = 'Medium Dice 1/2" X 1/2" X 1/2"',
  MINCED = 'Minced, Chopped into fine pieces',
  PEELED = 'Peeled',
  SHERD = 'Sherd, Cut into thin strips',
  SHREDDED = 'Shredded',
  SLICED = 'Sliced',
  SMALL_DICE = 'Small Dice 1/4" X 1/4" X 1/4"',
  SPIRALIZED = 'Spiralized',
  WEDGED = 'Wedged'
}

export enum InvitationCodeType {
  HEALTHY_FOOD_LOVER = 'HEALTHY_FOOD_LOVER',
  TOO_BUSY = 'TOO_BUSY',
  NOT_SURE = 'NOT_SURE',
  FITNESS_FREAK = 'FITNESS_FREAK',
  LOSING_WEIGHT = 'LOOSING_WEIGHT'
}

export enum UserAction {
  CREATE_SUBSCRIPTION = 'CREATE_SUBSCRIPTION',
  CREATE_SUBSCRIPTION_WEB = 'CREATE_SUBSCRIPTION_WEB',
  ADD_SUBSCRIPTION_ADDONS = 'ADD_SUBSCRIPTION_ADDONS',
  ADD_DELIVERY_ADDONS = 'ADD_DELIVERY_ADDONS',
  REPLACE_FOOD = 'REPLACE_FOOD',
  RESUME_SUBSCRIPTION = 'RESUME_SUBSCRIPTION',
  PAY_PENDING_AMOUNT = 'PAY_PENDING_AMOUNT',
  RENEW_SUBSCRIPTION = 'RENEW_SUBSCRIPTION',
  PURCHASE_CUSTOM_FOOD = 'PURCHASE_CUSTOM_FOOD',
  PAY_NON_CONFIRMED_SUBSCRIPTION = 'PAY_NON_CONFIRMED_SUBSCRIPTION',
  ADD_FOOD = 'ADD_FOOD',
  DONATE_RAMADAN_MEAL = 'DONATE_RAMADAN_MEAL',
  PURCHASE_ADDONS_FOR_DELIVERY = 'PURCHASE_ADDONS_FOR_DELIVERY',
  PURCHASE_ADDON_SUBSCRIPTION = 'PURCHASE_ADDON_SUBSCRIPTION',
  AUTO_RENEW_SUBSCRIPTION = 'AUTO_RENEW_SUBSCRIPTION',
  RENEW_ADDON_SUBSCRIPTION = 'RENEW_ADDON_SUBSCRIPTION',
  RETAIL_PURCHASE = 'RETAIL_PURCHASE',
  RETAIL_APP_PURCHASE = 'RETAIL_APP_PURCHASE',
  PURCHASE_COACHING_SESSION = 'PURCHASE_COACHING_SESSION',
  RETAIL_SIGN_UP = 'RETAIL_SIGN_UP',
  CHANGE_PLAN = 'CHANGE_PLAN',
  PURCHASE_CLUB_MEMBERSHIP = 'PURCHASE_CLUB_MEMBERSHIP'
}

export enum HealthTracker {
  GOOGLE_FIT = 'GOOGLE_FIT',
  HEALTH_KIT = 'HEALTH_KIT',
  HEALTH_CONNECT = 'HEALTH_CONNECT',
  MANUAL = 'MANUAL'
}

export enum HealthTrackerActivity {
  AEROBICS = 'AEROBICS',
  AMERICAN_FOOTBALL = 'AMERICAN_FOOTBALL',
  ARCHERY = 'ARCHERY',
  AUSTRALIAN_FOOTBALL = 'AUSTRALIAN_FOOTBALL',
  BACK_COUNTRY_SKIING = 'BACK_COUNTRY_SKIING',
  BADMINTON = 'BADMINTON',
  BASEBALL = 'BASEBALL',
  BASKETBALL = 'BASKETBALL',
  BIATHLON = 'BIATHLON',
  BIKING = 'BIKING',
  BOWLING = 'BOWLING',
  BOXING = 'BOXING',
  BARRE = 'BARRE',
  CORE_TRAINING = 'CORE_TRAINING',
  CALISTHENICS = 'CALISTHENICS',
  CIRCUIT_TRAINING = 'CIRCUIT_TRAINING',
  CLIMBING = 'CLIMBING',
  CRICKET = 'CRICKET',
  CROSSFIT = 'CROSSFIT',
  CROSS_TRAINING = 'CROSS_TRAINING',
  CROSS_SKATING = 'CROSS_SKATING',
  CROSS_COUNTRY_SKIING = 'CROSS_COUNTRY_SKIING',
  CURLING = 'CURLING',
  CYCLING = 'CYCLING',
  DANCE = 'DANCE',
  DIVING = 'DIVING',
  DOWNHILL_SKIING = 'DOWNHILL_SKIING',
  ELLIPTICAL = 'ELLIPTICAL',
  ELEVATOR = 'ELEVATOR',
  ESCALATOR = 'ESCALATOR',
  ERGOMETER = 'ERGOMETER',
  EQUESTRIAN_SPORTS = 'EQUESTRIAN_SPORTS',
  FENCING = 'FENCING',
  FISHING = 'FISHING',
  FRISBEE = 'FRISBEE',
  FLEXIBILITY = 'FLEXIBILITY',
  FUNCTIONAL_STRENGTH_TRANING = 'FUNCTIONAL_STRENGTH_TRANING',
  FOOTBALL = 'FOOTBALL',
  GOLF = 'GOLF',
  GARDENING = 'GARDENING',
  GUIDED_BREATHING = 'GUIDED_BREATHING',
  GYMNASTICS = 'GYMNASTICS',
  HAND_CYCLING = 'HAND_CYCLING',
  HANDBALL = 'HANDBALL',
  HAND_BIKING = 'HAND_BIKING',
  HIKING = 'HIKING',
  HIIT = 'HIIT',
  HIGH_INTENSITY_INTERVAL_TRAINING = 'HIGH_INTENSITY_INTERVAL_TRAINING',
  HOCKEY = 'HOCKEY',
  HORSEBACK_RIDING = 'HORSEBACK_RIDING',
  HOUSEWORK = 'HOUSEWORK',
  HUNTING = 'HUNTING',
  ICE_SKATING = 'ICE_SKATING',
  INDOOR_SKATING = 'INDOOR_SKATING',
  IN_VECHICLE = 'IN_VECHICLE',
  INLINE_SKATING_ROLLERBLADING = 'INLINE_SKATING_ROLLERBLADING',
  INTERVAL_TRAINING = 'INTERVAL_TRAINING',
  JUMP_ROPE = 'JUMP_ROPE',
  JOGGING = 'JOGGING',
  KAYAKING = 'KAYAKING',
  KETTLEBELL_TRAINING = 'KETTLEBELL_TRAINING',
  KICKBOXING = 'KICKBOXING',
  KITESURFING = 'KITESURFING',
  KITE_SKIING = 'KITE_SKIING',
  LACROSSE = 'LACROSSE',
  MARTIAL_ARTS = 'MARTIAL_ARTS',
  MEDITATION = 'MEDITATION',
  MOUNTAIN_BIKING = 'MOUNTAIN_BIKING',
  MIND_AND_BODY = 'MIND_AND_BODY',
  MIXED_CARDIO = 'MIXED_CARDIO',
  MIXED_MARTIAL_ARTS = 'MIXED_MARTIAL_ARTS',
  OTHER = 'OTHER',
  PADDLE_SPORTS = 'PADDLE_SPORTS',
  PARAGLIDING = 'PARAGLIDING',
  PLAY = 'PLAY',
  PILATES = 'PILATES',
  PREPARATION_AND_RECOVERY = 'PREPARATION_AND_RECOVERY',
  POLO = 'POLO',
  P90X_EXERCISES = 'P90X_EXERCISES',
  RACQUETBALL = 'RACQUETBALL',
  ROWING = 'ROWING',
  ROWING_MACHINE = 'ROWING_MACHINE',
  ROAD_BIKING = 'ROAD_BIKING',
  ROLLER_SKIING = 'ROLLER_SKIING',
  RROCK_CLIMBING = 'ROCK_CLIMBING',
  RUGBY = 'RUGBY',
  RUNNING = 'RUNNING',
  RUNNING_ON_SAND = 'RUNNING_ON_SAND',
  RUNNING_ON_TREADMILL = 'RUNNING_ON_TREADMILL',
  SAILING = 'SAILING',
  SCUBA_DIVING = 'SCUBA_DIVING',
  SKATING = 'SKATING',
  SKATING_SPORTS = 'SKATING_SPORTS',
  SKATEBOARDING = 'SKATEBOARDING',
  SLEDDING = 'SLEDDING',
  SNOW_SPORTS = 'SNOW_SPORTS',
  SNOW_MOBILE = 'SNOW_MOBILE',
  SNOW_SHOEING = 'SNOW_SHOEING',
  SOCCER = 'SOCCER',
  SOFTBALL = 'SOFTBALL',
  SPINNING = 'SPINNING',
  SQUASH = 'SQUASH',
  STAND_UP_PADDLE_BOARDING = 'STAND_UP_PADDLE_BOARDING',
  STAIR_CLIMBING = 'STAIR_CLIMBING',
  STAIR_CLIMBING_MACHINE = 'STAIR_CLIMBING_MACHINE',
  STATIONARY_BIKING = 'STATIONARY_BIKING',
  STRENGTH_TRAINING = 'STRENGTH_TRAINING',
  STILL = 'STILL',
  SNOWBOARDING = 'SNOWBOARDING',
  STAIRS = 'STAIRS',
  STEP_TRAINING = 'STEP_TRAINING',
  SURFING = 'SURFING',
  SURFING_SPORTS = 'SURFING_SPORTS',
  SWIMMING = 'SWIMMING',
  SWIMMING_OPEN_WATER = 'SWIMMING_OPEN_WATER',
  SWIMMING_POOL = 'SWIMMING_POOL',
  TABLE_TENNIS = 'TABLE_TENNIS',
  TENNIS = 'TENNIS',
  TEAM_SPORTS = 'TEAM_SPORTS',
  TILTING = 'TILTING',
  TRACK_AND_FIELD = 'TRACK_AND_FIELD',
  TRADITIONAL_STRENGTH_TRAINING = 'TRADITIONAL_STRENGTH_TRAINING',
  TAI_CHI = 'TAI_CHI',
  UTILITY_BIKING = 'UTILITY_BIKING',
  UNKNOWN = 'UNKNOWN',
  VOLLEYBALL = 'VOLLEYBALL',
  VOLLEYBALL_BEACH = 'VOLLEYBALL_BEACH',
  WAKEBOARDING = 'WAKEBOARDING',
  WALKING = 'WALKING',
  WALKING_STROLLER = 'WALKING_STROLLER',
  WALKING_ON_TREADMILL = 'WALKING_ON_TREADMILL',
  WATER_FITNESS = 'WATER_FITNESS',
  WATERPOLO = 'WATERPOLO',
  WATER_SPORTS = 'WATER_SPORTS',
  WRESTLING = 'WRESLING',
  WEIGHTLIFTING = 'WEIGHTLIFTING',
  WHEEL_CHAIR_WALK_PACE = 'WHEEL_CHAIR_WALK_PACE',
  WHEEL_CHAIR_RUN_PACE = 'WHEEL_CHAIR_RUN_PACE',
  WINDSURFING = 'WINDSURFING',
  YOGA = 'YOGA',
  ZUMBA = 'ZUMBA'
}

export enum MenuTagValue {
  NEW = 'NEW',
  DAIRY_FREE = 'DAIRY_FREE',
  UPGRADED = 'UPGRADED',
  TOP_RATED = 'TOP_RATED',
  SEASONAL = 'SEASONAL',
  GLUTEN_FREE = 'GLUTEN_FREE',
  PROTEIN_RICH = 'PROTEIN_RICH',
  CUSTOMIZABLE = 'CUSTOMIZABLE',
  SUMMER = 'SUMMER'
}

export enum WalletReason {
  CALO_TEAM_PERKS = 'CALO_TEAM_PERKS',
  CASH_OR_BENEFIT_PAYMENT = 'CASH_OR_BENEFIT_PAYMENT',
  COMPENSATION = 'COMPENSATION',
  MANUAL_DISCOUNT = 'MANUAL_DISCOUNT',
  CX_GIFT = 'CX_GIFT',
  MARKETING_GIFT_AND_EXPERIMENTS = 'MARKETING_GIFT_AND_EXPERIMENTS',
  CALO_BUSINESS = 'CALO_BUSINESS',
  TRANSFERRED_CREDITS = 'TRANSFERRED_CREDITS',
  CUSTOMERS_INFLUENCERS = 'CUSTOMERS_INFLUENCERS',
  CUSTOMERS_CALO_EXPERIMENTS = 'CUSTOMERS_CALO_EXPERIMENTS',
  CUSTOMERS_FRIENDS_REFERRAL = 'CUSTOMERS_FRIENDS_REFERRAL',
  COMPLEMENTARY_CARD = 'COMPLEMENTARY_CARD',
  INVAILID_ENTRY = 'INVAILID_ENTRY',
  DOUBLE_ENTRY = 'DOUBLE_ENTRY',
  REFUNDED_MANUALLY = 'REFUNDED_MANUALLY',
  FIXING_BALANCE = 'FIXING_BALANCE',
  CREDIT_TRANSFER = 'CREDIT_TRANSFER',
  REMOVING_GIFTS = 'REMOVING_GIFTS',
  REMOVING_DISCOUNT_CODE_CREDITS = 'REMOVING_DISCOUNT_CODE_CREDITS',
  CASHBACK_CARD = 'CASHBACK_CARD',
  DELIVERY_DEDUCTION = 'DELIVERY_DEDUCTION',
  DELIVERY_CANCELLATION = 'DELIVERY_CANCELLATION',
  OTHER = 'OTHER'
}

export enum RefundReason {
  ALLERGIC_CONDITIONS = 'ALLERGIC_CONDITIONS',
  DOUBLE_CHARGE = 'DOUBLE_CHARGE',
  BUG = 'BUG',
  FOOD_POISONING = 'FOOD_POISONING',
  FOREIGN_OBJECT = 'FOREIGN_OBJECT',
  LEAVING_THE_COUNTRY = 'LEAVING_THE_COUNTRY',
  MEDICAL_CONDITION = 'MEDICAL_CONDITION',
  PARTIAL_REFUND = 'PARTIAL_REFUND',
  PERSONAL_PREFERENCE = 'PERSONAL_PREFERENCE',
  PERSONAL_REASONS = 'PERSONAL_REASONS',
  QUALITY_OF_THE_MEALS = 'QUALITY_OF_THE_MEALS',
  UNAVAILABLE_DIETARY_PLANS = 'UNAVAILABLE_DIETARY_PLANS',
  UNINTENTIONAL_RENEWAL = 'UNINTENTIONAL_RENEWAL',
  UNPLEASED_WITH_THE_DELIVERY = 'UNPLEASED_WITH_THE_DELIVERY',
  UNABLE_TO_CONTROL_MACROS_OR_CALORIES = 'UNABLE_TO_CONTROL_MACROS_OR_CALORIES',
  DISPATCH_ERROR = 'DISPATCH_ERROR'
}

export enum PaymentError {
  PAYMENT_FAILED = 'PAYMENT_FAILED',
  PAYMENT_REQUIRED = 'PAYMENT_REQUIRED',
  PAYMENT_METHOD_NOT_SUPPORTED = 'PAYMENT_METHOD_NOT_SUPPORTED',
  PAYMENT_DECLINED = 'PAYMENT_DECLINED',
  EXPIRED_CARD = 'EXPIRED_CARD',
  INSUFFICIENT_FUNDS = 'INSUFFICIENT_FUNDS',
  NON_3D_SECURE_TRANSACTIONS_NOT_ALLOWED = 'NON_3D_SECURE_TRANSACTIONS_NOT_ALLOWED',
  PAYMENT_PROVIDER_INTERNAL_SERVER_ERROR = 'PAYMENT_PROVIDER_INTERNAL_SERVER_ERROR',
  CARD_ISSUER_ISSUE = 'CARD_ISSUER_ISSUE',
  CARD_DECLINED_BY_ISSUER = 'CARD_DECLINED_BY_ISSUER',
  CARD_DECLINED_BY_PAYMENT_PROVIDER = 'CARD_DECLINED_BY_PAYMENT_PROVIDER',
  TRANSACTION_TYPE_NOT_SUPPORTED = 'TRANSACTION_TYPE_NOT_SUPPORTED',
  INCORRECT_SECURITY_CODE = 'INCORRECT_SECURITY_CODE',
  INVALID_CARD_NUMBER = 'INVALID_CARD_NUMBER',
  INVALID_EXPIRY_DATE = 'INVALID_EXPIRY_DATE',
  INVALID_REQUIRED_PARAMETER = 'INVALID_REQUIRED_PARAMETER',
  UNSPECIFIED_FAILURE = 'UNSPECIFIED_FAILURE',
  DUPLICATE = 'DUPLICATE',
  RESTRICTED = 'RESTRICTED',
  RESTRICTED_RETRY_LIMIT_EXCEEDED = 'RESTRICTED_RETRY_LIMIT_EXCEEDED',
  RESTRICTED_BY_CARD_ISSUER = 'RESTRICTED_BY_CARD_ISSUER',
  RESTRICTED_BY_PAYMENT_PROVIDER = 'RESTRICTED_BY_PAYMENT_PROVIDER'
}

export enum FoodActionType {
  add = 'add',
  remove = 'remove',
  swap = 'swap',
  customize = 'customize',
  resize = 'resize'
}

export enum HeatingInstruction {
  HEATING_WITH_SAUCE = 'HEATING_WITH_SAUCE',
  HEATING_NO_SAUCE = 'HEATING_NO_SAUCE',
  COLD = 'COLD',
  HEATING_SANDWICH = 'HEATING_SANDWICH',
  HEATING_SNACK = 'HEATING_SNACK',
  NONE = 'NONE'
}

export enum BalanceUpdateType {
  CREDIT = 'CREDIT',
  DEBIT = 'DEBIT'
}

export enum TransactionHistoryType {
  CREDIT = 'CREDIT',
  DEBIT = 'DEBIT'
}

export enum TransactionHistorySource {
  REFUND = 'REFUND',
  PAYMENT = 'PAYMENT',
  MANUAL_ADDITION = 'MANUAL_ADDITION',
  MANUAL_DEDUCTION = 'MANUAL_DEDUCTION'
}

export enum TransactionHistoryStatus {
  PENDING = 'PENDING',
  COMPLETED = 'COMPLETED'
}

export enum ActionSource {
  WEB = 'WEB',
  APP = 'APP',
  DASHBOARD = 'DASHBOARD'
}

export enum EventSessionStatus {
  initiated = 'initiated',
  pending = 'pending',
  completed = 'completed',
  failed = 'failed'
}

export enum AddonCategory {
  RAMADAN = 'RAMADAN',
  CALO_KIDS = 'CALO_KIDS',
  CALO_KIDS_DINNER = 'CALO_KIDS_DINNER',
  BEVERAGES = 'BEVERAGES',
  COFFEE_AND_TEA = 'COFFEE_AND_TEA',
  SHARING_BOXES = 'SHARING_BOXES',
  FRUITS_AND_VEGGIES = 'FRUITS_AND_VEGGIES',
  PROTEIN = 'PROTEIN',
  SALADS = 'SALADS',
  SOUPS = 'SOUPS',
  GIFT_CARDS = 'GIFT_CARDS',
  SAVORY_SNACKS = 'SAVORY_SNACKS',
  CONDIMENTS = 'CONDIMENTS',
  SUPPLEMENTS = 'SUPPLEMENTS',
  SPECIALS = 'SPECIALS',
  BREAKFAST_ITEMS = 'BREAKFAST_ITEMS',
  SWEET_TOOTH = 'SWEET_TOOTH',
  PANTRY = 'PANTRY',
  EXTRA_PROTEIN = 'EXTRA_PROTEIN',
  EID = 'EID',
  MERCH = 'MERCH',
  BONE_BROTH = 'BONE_BROTH',
  PRE_WORKOUT = 'PRE_WORKOUT',
  POST_WORKOUT = 'POST_WORKOUT',
  KIDS_LUNCHBOX_24 = 'KIDS_LUNCHBOX_24',
  KIDS_LUNCHBOX_58 = 'KIDS_LUNCHBOX_58',
  KIDS_LUNCHBOX_912 = 'KIDS_LUNCHBOX_912'
}

export enum NotificationCategory {
  PROGRESS = 'PROGRESS',
  PROMOTION = 'PROMOTION',
  SUBSCRIPTION = 'SUBSCRIPTION',
  PAYMENTS = 'PAYMENTS',
  DELIVERIES = 'DELIVERIES',
  WAITING_LIST = 'WAITING_LIST'
}

export enum NotificationType {
  WATER_TRACKER_REMINDER = 'WATER_TRACKER_REMINDER',
  HABIT_TRACKER_REMINDER = 'HABIT_TRACKER_REMINDER',
  WEIGHT_TRACKER_REMINDER = 'WEIGHT_TRACKER_REMINDER',
  NUTRITION_TRACKER_REMINDER = 'NUTRITION_TRACKER_REMINDER',
  ACTIVITY_TRACKER_REMINDER = 'ACTIVITY_TRACKER_REMINDER',
  ADDONS_PURCHASED = 'ADDONS_PURCHASED',
  CUSTOM_FOOD_PURCHASED = 'CUSTOM_FOOD_PURCHASED',
  REPLACE_FOOD_PURCHASED = 'REPLACE_FOOD_PURCHASED',
  DELIVERY_DELIVERED = 'DELIVERY_DELIVERED',
  AUTO_RENEW_FAILED = 'AUTO_RENEW_FAILED',
  SECOND_AUTO_RENEW_FAILED = 'SECOND_AUTO_RENEW_FAILED',
  SUBSCRIPTION_PAYMENT = 'SUBSCRIPTION_PAYMENT',
  PENDING_SUBSCRIPTION_PAYMENT = 'PENDING_SUBSCRIPTION_PAYMENT',
  SUBSCRIPTION_RENEWED = 'SUBSCRIPTION_RENEWED',
  TRIAL_SUBSCRIPTION_CREATED = 'TRIAL_SUBSCRIPTION_CREATED',
  APP_FEATURES = 'APP_FEATURES',
  PRODUCT_UPDATE = 'PRODUCT_UPDATE',
  PROMOTIONS = 'PROMOTIONS',
  MENU_UPDATES = 'MENU_UPDATES',
  MENU_AND_MEAL_SELECTION = 'MENU_AND_MEAL_SELECTION'
}

export enum NotificationChannel {
  sms = 'sms',
  email = 'email',
  push = 'push'
}

export enum NotificationFrequency {
  yearly = 'yearly',
  monthly = 'monthly',
  weekly = 'weekly',
  daily = 'daily',
  once = 'once'
}

export enum WeekDay {
  SUNDAY = 'SUNDAY',
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY'
}

export enum WightTrackerProgressChangeStatus {
  increase = 'increase',
  decrease = 'decrease',
  same = 'same'
}

export enum DayProgressUserFoodSource {
  search = 'search',
  barcode = 'barcode',
  manual = 'manual'
}

export enum DayProgressFoodServingUnit {
  gram = 'gram',
  ml = 'ml',
  cup = 'cup'
}

export enum AppMessageName {
  LOYALTY_POINTS_EARNED = 'LOYALTY_POINTS_EARNED',
  RETAIL_PURCHASE_MADE = 'RETAIL_PURCHASE_MADE',
  RETAIL_REWARD_SCANNED = 'RETAIL_REWARD_SCANNED',
  RETAIL_REWARD_REDEEMED = 'RETAIL_REWARD_REDEEMED',
  SUBSCRIPTION_BALANCE_UPDATED = 'SUBSCRIPTION_BALANCE_UPDATED',
  SESSION_COMPLETED = 'SESSION_COMPLETED',
  SESSION_FAILED = 'SESSION_FAILED',
  FEEDBACK_REQUESTED = 'FEEDBACK_REQUESTED',
  CLUB_MEMBERSHIP_ACTIVATED = 'CLUB_MEMBERSHIP_ACTIVATED',
  RETAIL_APP_PURCHASE_MADE = 'RETAIL_APP_PURCHASE_MADE',
  RETAIL_ORDER_STATUS_UPDATED = 'RETAIL_ORDER_STATUS_UPDATED'
}

export enum RetailOrderStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  READY_FOR_PICKUP = 'READY_FOR_PICKUP',
  PICKED_UP = 'PICKED_UP'
}

export enum DiscountCampaignType {
  coupon = 'coupon'
}

export enum LoyaltyTransactionAction {
  POINTS_ADDED = 'POINTS_ADDED',
  POINTS_REDEEMED = 'POINTS_REDEEMED',
  NEW_SUBSCRIPTION = 'NEW_SUBSCRIPTION',
  SUBSCRIPTION_RENEWED = 'SUBSCRIPTION_RENEWED',
  ADDON_PURCHASE = 'ADDON_PURCHASE',
  PENDING_AMOUNT = 'PENDING_AMOUNT',
  REPLACE_MEAL = 'REPLACE_MEAL',
  CUSTOM_MEAL = 'CUSTOM_MEAL',
  DONATION = 'DONATION',
  EXPERIMENT = 'EXPERIMENT',
  SPIN_THE_WHEEL = 'SPIN_THE_WHEEL',
  OTHERS = 'OTHERS',
  RETAIL_PURCHASE = 'RETAIL_PURCHASE',
  RETAIL_SIGN_UP = 'RETAIL_SIGN_UP',
  RETAIL_REWARD_REDEEMED = 'RETAIL_REWARD_REDEEMED',
  POINTS_EXPIRED = 'POINTS_EXPIRED'
}

export enum LoyaltyPointsType {
  EARNED = 'EARNED',
  REDEEMED = 'REDEEMED',
  EXPIRED = 'EXPIRED'
}

export enum RenewalType {
  PRE_RENEW = 'preRenew',
  USER_RENEW = 'userRenew',
  AUTO_RENEW = 'autoRenew'
}

export enum PauseSubscriptionReasonType {
  SICK = 'SICK',
  TRAVELING = 'TRAVELING',
  ON_LEAVE = 'ON_LEAVE',
  CHEAT_DAY = 'CHEAT_DAY',
  RAMADAN = 'RAMADAN',
  OTHER = 'OTHER'
}

export enum HealthConditions {
  PREGNANCY_BREASTFEEDING = 'PREGNANCY_BREASTFEEDING',
  HYPERTENSION = 'HYPERTENSION',
  HIGH_CHOLESTEROL = 'HIGH_CHOLESTEROL',
  GERD = 'GERD',
  IBS = 'IBS',
  THYROID = 'THYROID',
  HIGH_URIC_ACID = 'HIGH_URIC_ACID',
  ANEMIA = 'ANEMIA',
  G6PD = 'G6PD'
}

export enum SeasonalityEvents {
  RAMADAN = 'RAMADAN'
}

export enum FeatureFlags {
  SHOW_RAMADAN_BANNER = 'SHOW_RAMADAN_BANNER',
  SHOW_RAMADAN_COUNTDOWN = 'SHOW_RAMADAN_COUNTDOWN',
  SHOW_RAMADAN_DELIVERY_TIME_PICKER = 'SHOW_RAMADAN_DELIVERY_TIME_PICKER',
  SHOW_RAMADAN_PACKAGES = 'SHOW_RAMADAN_PACKAGES',
  SHOW_DONATION_BANNER = 'SHOW_DONATION_BANNER',
  FOUR_WEEKS_UNTIL_RAMADAN = 'FOUR_WEEKS_UNTIL_RAMADAN',
  THREE_WEEKS_UNTIL_RAMADAN = 'THREE_WEEKS_UNTIL_RAMADAN',
  TWO_WEEKS_UNTIL_RAMADAN = 'TWO_WEEKS_UNTIL_RAMADAN',
  ONE_WEEK_UNTIL_RAMADAN = 'ONE_WEEK_UNTIL_RAMADAN',
  SHOW_PRE_RAMADAN_CONTENT = 'SHOW_PRE_RAMADAN_CONTENT',
  SHOW_DURING_RAMADAN_CONTENT = 'SHOW_DURING_RAMADAN_CONTENT'
}

export enum MealSizePreferences {
  MIXED = 'MIXED',
  LARGE = 'LARGE',
  MEDIUM = 'MEDIUM',
  SMALL = 'SMALL',
  XS = 'XS',
  XL = 'XL'
}

export enum CustomMealCategory {
  meal = 'meal',
  salad = 'salad',
  sandwich = 'sandwich',
  pasta = 'pasta',
  rice = 'rice',
  caloKids = 'caloKids',
  athlete = 'athlete'
}

export enum LoyaltyPointsExpiryDuration {
  threeDays = 3,
  oneMonth = 30
}

export enum SpiceLevel {
  MEDIUM = 'MEDIUM',
  MILD = 'MILD',
  NONE = 'NONE',
  HOT = 'HOT',
  NOT_SPICY = 'NOT_SPICY',
  VERY_HOT = 'VERY_HOT',
  VERY_MILD = 'VERY_MILD',
  NOT_APPLICABLE = 'NOT_APPLICABLE',
  NOT_SPECIFIED = 'NOT_SPECIFIED',
  LOW = 'LOW',
  NO_SPICE = 'NO_SPICE'
}

export enum FlavorsProfile {
  SALTY = 'SALTY',
  SAVOURY = 'SAVOURY',
  TANGY = 'TANGY',
  HERBACEOUS = 'HERBACEOUS',
  FRESH = 'FRESH',
  NUTTY = 'NUTTY',
  SWEET = 'SWEET',
  CHEESY = 'CHEESY',
  SPICED = 'SPICED',
  SAVORY = 'SAVORY',
  CREAMY = 'CREAMY',
  UMAMI = 'UMAMI',
  SPICY = 'SPICY',
  BUTTERY = 'BUTTERY',
  CINNAMON = 'CINNAMON',
  SOUR = 'SOUR',
  HERBAL = 'HERBAL',
  RICH = 'RICH',
  TART = 'TART',
  REFRESHING = 'REFRESHING',
  GARLICKY = 'GARLICKY',
  HERBY = 'HERBY',
  BITTER = 'BITTER',
  TOMATOEY = 'TOMATOEY',
  CHOCOLATEY = 'CHOCOLATEY',
  CRISPY = 'CRISPY',
  FRUITY = 'FRUITY',
  SEAFOOD = 'SEAFOOD',
  TROPICAL = 'TROPICAL',
  HEARTY = 'HEARTY',
  COCONUT = 'COCONUT',
  EGGY = 'EGGY',
  VANILLA = 'VANILLA',
  EARTHY = 'EARTHY',
  AROMATIC = 'AROMATIC',
  CITRUSY = 'CITRUSY',
  SMOKY = 'SMOKY',
  ZESTY = 'ZESTY',
  FLORAL = 'FLORAL',
  CITRIC = 'CITRIC',
  PEPPERY = 'PEPPERY',
  CITRUS = 'CITRUS',
  COCONUTTY = 'COCONUTTY',
  ROASTED = 'ROASTED',
  EGG = 'EGG',
  COFFEE = 'COFFEE',
  GINGER = 'GINGER',
  CHOCOLATE = 'CHOCOLATE',
  HONEY = 'HONEY',
  PISTACHIO = 'PISTACHIO',
  LEMON = 'LEMON',
  TOMATO = 'TOMATO',
  WARM = 'WARM',
  CARAMELIZED = 'CARAMELIZED',
  EARTHLY = 'EARTHLY',
  BANANA = 'BANANA',
  STRAWBERRY = 'STRAWBERRY',
  SUNFLOWER_SEEDS = 'SUNFLOWER_SEEDS',
  HERBED = 'HERBED',
  DAIRY = 'DAIRY',
  RICH_CREAMY = 'RICH CREAMY',
  TAMARIND = 'TAMARIND',
  BERRY = 'BERRY',
  CHOCOLATY = 'CHOCOLATY',
  BUTTER = 'BUTTER',
  SAFFRON = 'SAFFRON',
  GARLIC = 'GARLIC',
  SUNDRIED_TOMATO = 'SUNDRIED_TOMATO',
  CHEDDAR = 'CHEDDAR',
  VEGETAL = 'VEGETAL',
  MEATY = 'MEATY',
  SLIGHTLY_SWEET = 'SLIGHTLY_SWEET',
  SESAME = 'SESAME',
  MILD = 'MILD',
  JUICY = 'JUICY',
  HERB = 'HERB',
  MINTY = 'MINTY',
  NEUTRAL = 'NEUTRAL',
  SAFFRON_INFUSED = 'SAFFRON_INFUSED',
  DECADENT = 'DECADENT',
  CINNAMONY = 'CINNAMONY',
  FRAGRANT = 'FRAGRANT',
  PEANUT = 'PEANUT',
  CRUNCHY = 'CRUNCHY',
  CARAMEL = 'CARAMEL',
  PEANUTTY = 'PEANUTTY',
  CARAMELLY = 'CARAMELLY',
  SLIGHTLY_NUTTY = 'SLIGHTLY_NUTTY',
  FIERY = 'FIERY',
  FERMENTED = 'FERMENTED',
  COMFORTING = 'COMFORTING',
  SLIGHTLY_TANGY = 'SLIGHTLY_TANGY',
  PICKLED = 'PICKLED',
  BARBEQUE = 'BARBEQUE',
  HOT = 'HOT',
  COCOA = 'COCOA',
  CAFFEINATED = 'CAFFEINATED',
  SLIGHTLY_SPICY = 'SLIGHTLY_SPICY',
  APPLE = 'APPLE',
  SEEDY = 'SEEDY',
  CRISP = 'CRISP',
  SOY = 'SOY',
  MAPLE = 'MAPLE',
  GREEN = 'GREEN',
  BITTERSWEET = 'BITTERSWEET',
  OATY = 'OATY',
  ROBUST = 'ROBUST',
  SMOOTH = 'SMOOTH',
  MUSHROOM = 'MUSHROOM',
  ROASTED_CAULIFLOWER = 'ROASTED_CAULIFLOWER',
  LEMON_HERB = 'LEMON HERB',
  SHAWARMA_CHICKEN = 'SHAWARMA_CHICKEN',
  FAJITA_CHICKEN_BREAST = 'FAJITA_CHICKEN_BREAST',
  MEXICAN_CORN = 'MEXICAN_CORN',
  RANCH = 'RANCH',
  BEEF_MEATBALLS = 'BEEF_MEATBALLS',
  MASHED_POTATO = 'MASHED_POTATO',
  GRAVY = 'GRAVY',
  TIKKA_PANEER = 'TIKKA_PANEER',
  QUINOA_COOKED = 'QUINOA_COOKED',
  PICO = 'PICO',
  CREAMY_AVOCADO = 'CREAMY_AVOCADO',
  PEANUT_BUTTER = 'PEANUT_BUTTER',
  TOASTY = 'TOASTY',
  BRINY = 'BRINY',
  SIMPLE = 'SIMPLE',
  LIGHT = 'LIGHT'
}

export enum HintFlavors {
  SWEET = 'SWEET',
  SPICY = 'SPICY',
  SAVORY = 'SAVORY',
  CITRUS = 'CITRUS',
  HERBAL = 'HERBAL',
  TANGY = 'TANGY',
  SMOKY = 'SMOKY',
  UMAMI = 'UMAMI',
  AROMATIC = 'AROMATIC',
  CREAMY = 'CREAMY',
  RICH = 'RICH'
}

export enum Texture {
  NONE = 'NONE',
  CRISP = 'CRISP',
  CRUNCHY = 'CRUNCHY',
  CHEWY = 'CHEWY',
  CREAMY = 'CREAMY',
  SOFT = 'SOFT',
  TENDER = 'TENDER',
  JUICY = 'JUICY',
  CRISPY = 'CRISPY',
  BUTTERY = 'BUTTERY',
  MOIST = 'MOIST',
  SMOOTH = 'SMOOTH',
  STICKY = 'STICKY',
  CRUMBLY = 'CRUMBLY',
  GRAINY = 'GRAINY',
  LIGHT = 'LIGHT',
  MELTY = 'MELTY',
  FLUFFY = 'FLUFFY',
  FLAKY = 'FLAKY',
  SILKY = 'SILKY',
  GOOEY = 'GOOEY',
  SEEDY = 'SEEDY',
  SAUCY = 'SAUCY',
  AROMATIC = 'AROMATIC',
  REFRESHING = 'REFRESHING',
  RICH = 'RICH',
  WARM = 'WARM',
  SAVOURY = 'SAVOURY',
  FRUITY = 'FRUITY',
  BROTHY = 'BROTHY',
  CHUNKY = 'CHUNKY',
  SATISFYING = 'SATISFYING',
  DECADENT = 'DECADENT',
  NUTTY = 'NUTTY',
  SAVORY = 'SAVORY',
  FRESH = 'FRESH',
  SPONGY = 'SPONGY',
  CHEESY = 'CHEESY',
  ZESTY = 'ZESTY',
  AL_DENTE = 'AL_DENTE',
  FRAGRANT = 'FRAGRANT',
  LEAFY = 'LEAFY',
  HEARTY = 'HEARTY',
  SPICY = 'SPICY',
  SWEET = 'SWEET',
  GRITTY = 'GRITTY',
  ROASTED = 'ROASTED',
  BAKED = 'BAKED',
  BUTTERED = 'BUTTERED',
  GRILLED = 'GRILLED',
  MELT_IN_YOUR_MOUTH = 'MELT_IN_YOUR_MOUTH',
  FLAVORFUL = 'FLAVORFUL',
  VELVETY = 'VELVETY',
  LIQUID = 'LIQUID',
  COMFORTING = 'COMFORTING',
  SUCCULENT = 'SUCCULENT',
  MEATY = 'MEATY',
  SLIPPERY = 'SLIPPERY',
  SPICED = 'SPICED',
  CHOPPED = 'CHOPPED',
  TANGY = 'TANGY',
  SMOKEY = 'SMOKEY',
  MASHED = 'MASHED',
  FLAVOURFUL = 'FLAVOURFUL',
  INDULGENT = 'INDULGENT',
  FERMENTED = 'FERMENTED',
  FUDGY = 'FUDGY',
  FIRM = 'FIRM',
  EGG_Y = 'EGG_Y',
  SLIGHTLY_CHEWY = 'SLIGHTLY_CHEWY',
  MELTS_IN_THE_MOUTH = 'MELTS_IN_THE_MOUTH',
  TENDER_BEEF = 'TENDER_BEEF',
  CRISPY_VEGETABLES = 'CRISPY_VEGETABLES',
  CREAMY_SAUCE = 'CREAMY_SAUCE',
  HOT = 'HOT',
  SOUR = 'SOUR',
  ICY = 'ICY',
  GELATINOUS = 'GELATINOUS',
  COLD = 'COLD',
  SALTY = 'SALTY',
  EARTHY = 'EARTHY',
  VISCOUS = 'VISCOUS',
  POWDERY = 'POWDERY',
  THIN = 'THIN',
  SYRUPY = 'SYRUPY',
  WATERY = 'WATERY',
  TART = 'TART',
  DELICATE = 'DELICATE'
}

export enum Convenience {
  NONE = 'NONE',
  HEAT_AND_EAT = 'HEAT_AND_EAT',
  QUICK_AND_EASY = 'QUICK_AND_EASY',
  READY_TO_EAT = 'READY_TO_EAT',
  READY_TO_DRINK = 'READY_TO_DRINK',
  REQUIRES_COOKING = 'REQUIRES_COOKING',
  PREP_AND_COOK = 'PREP_AND_COOK',
  PREP_REQUIRED = 'PREP_REQUIRED',
  BAKE_AND_SERVE = 'BAKE_AND_SERVE',
  INDULGENT = 'INDULGENT',
  STOVETOP_COOKING = 'STOVETOP_COOKING',
  EASY_ASSEMBLY = 'EASY_ASSEMBLY',
  ROAST_AND_SERVE = 'ROAST_AND_SERVE',
  COOK_AND_ASSEMBLE = 'COOK_AND_ASSEMBLE',
  WRAP_AND_SERVE = 'WRAP_AND_SERVE',
  BAKE_AND_ENJOY = 'BAKE_AND_ENJOY',
  SLOW_COOKED = 'SLOW_COOKED',
  PREP_AND_COOK_REQUIRED = 'PREP_AND_COOK_REQUIRED',
  QUICK_AND_HEALTHY = 'QUICK_AND_HEALTHY',
  OVEN_BAKE = 'OVEN_BAKE',
  TIME_CONSUMING = 'TIME_CONSUMING',
  DECADENT = 'DECADENT',
  OVEN_READY = 'OVEN_READY',
  PREPARATION_REQUIRED = 'PREPARATION_REQUIRED',
  MODERATE_PREPARATION = 'MODERATE_PREPARATION',
  REQUIRES_BAKING = 'REQUIRES_BAKING',
  REQUIRES_ASSEMBLY = 'REQUIRES_ASSEMBLY',
  NOT_SPECIFIED = 'NOT_SPECIFIED',
  MIX_AND_EAT = 'MIX_AND_EAT',
  HEAT_AND_SERVE = 'HEAT_AND_SERVE',
  EASY_TO_PREPARE = 'EASY_TO_PREPARE',
  SPECIAL_OCCASION = 'SPECIAL_OCCASION',
  PREPARE_AND_COOK = 'PREPARE_AND_COOK',
  GRILL_AND_SERVE = 'GRILL_AND_SERVE',
  EAT_ON_THE_GO = 'EAT_ON_THE_GO',
  INDULGENT_TREAT = 'INDULGENT_TREAT',
  COMPLEX = 'COMPLEX',
  MODERATE = 'MODERATE',
  EASY = 'EASY',
  CUSTOMIZABLE_TOPPINGS = 'CUSTOMIZABLE_TOPPINGS',
  QUICK_BREAKFAST = 'QUICK_BREAKFAST',
  PROTEIN_PACKED_MEAL = 'PROTEIN_PACKED_MEAL',
  SIMPLE_SNACK = 'SIMPLE_SNACK',
  MEDIUM_EFFORT = 'MEDIUM_EFFORT',
  PREP_AND_EAT = 'PREP_AND_EAT',
  QUICK_ASSEMBLY = 'QUICK_ASSEMBLY',
  HOMEMADE = 'HOMEMADE',
  COOKED = 'COOKED',
  COOKED_MEAL = 'COOKED_MEAL',
  SNACK = 'SNACK',
  PRE_MARINATED = 'PRE_MARINATED'
}

export enum EatingExperience {
  JUICY = 'JUICY',
  FLAVORFUL = 'FLAVORFUL',
  ZESTY = 'ZESTY',
  REFRESHING = 'REFRESHING',
  CRUNCHY = 'CRUNCHY',
  COMFORTING = 'COMFORTING',
  RICH = 'RICH',
  SATISFYING = 'SATISFYING',
  CRUMBLY = 'CRUMBLY',
  DECADENT = 'DECADENT',
  HEARTY = 'HEARTY',
  AROMATIC = 'AROMATIC',
  BRIGHT = 'BRIGHT',
  FRESH = 'FRESH',
  WARM = 'WARM',
  INDULGENT = 'INDULGENT',
  MELT_IN_YOUR_MOUTH = 'MELT_IN_YOUR_MOUTH',
  LIGHT = 'LIGHT',
  FILLING = 'FILLING',
  TENDER = 'TENDER',
  STRONG = 'STRONG',
  DELICIOUS = 'DELICIOUS',
  BOLD = 'BOLD',
  MOIST = 'MOIST',
  DELIGHTFUL = 'DELIGHTFUL',
  SMOOTH = 'SMOOTH',
  EXCITING = 'EXCITING',
  TANGY = 'TANGY',
  SWEET = 'SWEET',
  SILKY = 'SILKY',
  NUTTY = 'NUTTY',
  SPICY = 'SPICY',
  FULFILLING = 'FULFILLING',
  FIERY = 'FIERY',
  FIZZY = 'FIZZY',
  SAVOURY = 'SAVOURY',
  SOFT = 'SOFT',
  VIBRANT = 'VIBRANT',
  BALANCED = 'BALANCED',
  FRAGRANT = 'FRAGRANT',
  CREAMY = 'CREAMY',
  NUTRITIOUS = 'NUTRITIOUS',
  CRISPY = 'CRISPY',
  FRUITY = 'FRUITY',
  CHEWY = 'CHEWY',
  CHEESY = 'CHEESY',
  SAVORY = 'SAVORY',
  DELICATE = 'DELICATE',
  LUSCIOUS = 'LUSCIOUS',
  ROBUST = 'ROBUST',
  BUTTERY = 'BUTTERY',
  CITRUSY = 'CITRUSY',
  FLAVOURFUL = 'FLAVOURFUL',
  SUCCULENT = 'SUCCULENT',
  SAUCY = 'SAUCY',
  RICH_AND_VELVETY = 'RICH_AND_VELVETY',
  TEXTURED = 'TEXTURED',
  WHOLESOME = 'WHOLESOME',
  GOOEY = 'GOOEY',
  COLORFUL = 'COLORFUL',
  MEATY = 'MEATY',
  MELTY = 'MELTY',
  FLUFFY = 'FLUFFY',
  UMAMI = 'UMAMI',
  CRISPY_COATING = 'CRISPY_COATING',
  TENDER_CHICKEN_FILLING = 'TENDER_CHICKEN_FILLING',
  JUICY_CHICKEN_KOFTA = 'JUICY_CHICKEN_KOFTA',
  CRUNCHY_VEGETABLES = 'CRUNCHY_VEGETABLES',
  SOFT_PANCAKE_WITH_FRUITY_SAUCE = 'SOFT_PANCAKE_WITH_FRUITY_SAUCE',
  RICH_AND_CHEESY_BEEF_PASTA = 'RICH_AND_CHEESY_BEEF_PASTA',
  SWEET_AND_CRUNCHY_DATE_FILLED_PASTRY = 'SWEET_AND_CRUNCHY_DATE_FILLED_PASTRY',
  HERB_INFUSED = 'HERB_INFUSED',
  UMAMI_RICH = 'UMAMI_RICH',
  HERBACEOUS = 'HERBACEOUS',
  STICKY = 'STICKY',
  EXOTIC = 'EXOTIC',
  FESTIVE = 'FESTIVE',
  NOURISHING = 'NOURISHING',
  ADDICTIVE = 'ADDICTIVE',
  PROTEIN_PACKED = 'PROTEIN_PACKED',
  JAMMY = 'JAMMY',
  SHARP = 'SHARP',
  SMOKY = 'SMOKY',
  UNIQUE = 'UNIQUE',
  VELVETY = 'VELVETY',
  MELTING = 'MELTING',
  HERBY = 'HERBY',
  CRISP = 'CRISP',
  EGGY = 'EGGY',
  SALTY = 'SALTY',
  TEXTURAL = 'TEXTURAL',
  FLAKY = 'FLAKY',
  SLIGHTLY_CHEWY = 'SLIGHTLY_CHEWY',
  SMOKEY = 'SMOKEY',
  SEEDY = 'SEEDY',
  HOMELY = 'HOMELY',
  SOUR = 'SOUR',
  MELLOW = 'MELLOW',
  ENERGETIC = 'ENERGETIC',
  LUXURIOUS = 'LUXURIOUS',
  INVIGORATING = 'INVIGORATING',
  FLAVOR_PACKED = 'FLAVOR_PACKED',
  REVITALIZING = 'REVITALIZING',
  CLEAN = 'CLEAN',
  COOL = 'COOL',
  HERBED = 'HERBED',
  CHILLED = 'CHILLED',
  HYDRATING = 'HYDRATING',
  MILDLY_SPICY = 'MILDLY_SPICY',
  CINNAMONY = 'CINNAMONY',
  TRADITIONAL = 'TRADITIONAL',
  SOFT_BOILED = 'SOFT_BOILED',
  SIMPLE = 'SIMPLE',
  SOOTHING = 'SOOTHING',
  ZINGY = 'ZINGY',
  LAYERED = 'LAYERED',
  COMPLEX = 'COMPLEX',
  ENERGIZING = 'ENERGIZING',
  CLASSIC = 'CLASSIC',
  TROPICAL = 'TROPICAL',
  PICKLED = 'PICKLED',
  SPICED = 'SPICED',
  JUICY_CHICKEN = 'JUICY_CHICKEN',
  CREAMY_FILLING = 'CREAMY_FILLING',
  CRUNCHY_COATING = 'CRUNCHY_COATING',
  TENDER_CHICKEN = 'TENDER_CHICKEN',
  HERBAL = 'HERBAL',
  COOLING = 'COOLING',
  INVITING = 'INVITING',
  CLEANSING = 'CLEANSING',
  VARIETY = 'VARIETY',
  EGG_CITING = 'EGG_CITING',
  SMOOTH_AND_CREAMY = 'SMOOTH_AND_CREAMY',
  SAVOURY_AND_FRESH = 'SAVOURY_AND_FRESH',
  FRUITY_AND_CREAMY = 'FRUITY_AND_CREAMY',
  RICH_AND_CREAMY = 'RICH_AND_CREAMY',
  ZESTY_AND_SPICY = 'ZESTY_AND_SPICY',
  COCONUTTY = 'COCONUTTY',
  SATIFYING = 'SATIFYING',
  RICH_UMAMI = 'RICH_UMAMI',
  CRISPY_CHICKEN = 'CRISPY_CHICKEN',
  SAVOURY_CURRY_SAUCE = 'SAVOURY_CURRY_SAUCE',
  BROTHY = 'BROTHY',
  MILD = 'MILD',
  AUTUMNAL = 'AUTUMNAL',
  WARMING = 'WARMING',
  FUN = 'FUN',
  ENERGY_BOOSTING = 'ENERGY_BOOSTING',
  MESSY = 'MESSY',
  VEGETAL = 'VEGETAL',
  SUBSTANTIAL = 'SUBSTANTIAL',
  MOUTHWATERING = 'MOUTHWATERING',
  HEARTY_AND_SATISFYING = 'HEARTY_AND_SATISFYING',
  SPICY_AND_SATISFYING = 'SPICY_AND_SATISFYING',
  EGGLICIOUS = 'EGGLICIOUS',
  SATISFYING_AND_RICH = 'SATISFYING_AND_RICH',
  MEATY_DELIGHT = 'MEATY_DELIGHT',
  DECADENTLY_SWEET = 'DECADENTLY_SWEET',
  INDULGENT_DELIGHT = 'INDULGENT_DELIGHT',
  HEALTHY = 'HEALTHY',
  BURSTING = 'BURSTING',
  NATURAL = 'NATURAL',
  NUTRIENT_RICH = 'NUTRIENT_RICH',
  GOURMET = 'GOURMET',
  MOIST_AND_DECADENT = 'MOIST_AND_DECADENT',
  JUICY_AND_AROMATIC = 'JUICY_AND_AROMATIC',
  SATISFYING_AND_FRESH = 'SATISFYING_AND_FRESH',
  LIGHT_AND_ZESTY = 'LIGHT AND ZESTY',
  SATISFYING_AND_UMAMI_RICH = 'SATISFYING_AND_UMAMI_RICH'
}

export enum Cuisine {
  BRAZIL = 'BRAZIL',
  KUWAITI = 'KUWAITI',
  AMERICAN = 'AMERICAN',
  BRITISH = 'BRITISH',
  SCANDINAVIAN = 'SCANDINAVIAN',
  GERMAN = 'GERMAN',
  ITALIAN = 'ITALIAN',
  MIDDLE_EASTERN_FUSION = 'MIDDLE_EASTERN_FUSION',
  MOROCCAN = 'MOROCCAN',
  SAUDI_ARABIAN = 'SAUDI_ARABIAN',
  ARGENTINIAN = 'ARGENTINIAN',
  MEDITERRANEAN = 'MEDITERRANEAN',
  IRANIAN = 'IRANIAN',
  ASIAN = 'ASIAN',
  CHINESE_FUSION = 'CHINESE_FUSION',
  AMERICAN_FUSION = 'AMERICAN_FUSION',
  MEXICAN_FUSION = 'MEXICAN_FUSION',
  MIDDLE_EASTERN = 'MIDDLE_EASTERN',
  BAHRAIN = 'BAHRAIN',
  GREEK_ITALIAN_FUSION = 'GREEK_ITALIAN_FUSION',
  TURKEY = 'TURKEY',
  BAHRAIN_KUWAIT = 'BAHRAIN_KUWAIT',
  YEMENI = 'YEMENI',
  VIETNAMESE = 'VIETNAMESE',
  EGYPTIAN = 'EGYPTIAN',
  INDIAN = 'INDIAN',
  INDIAN_FUSION = 'INDIAN_FUSION',
  AMERICAN_ASIAN_FUSION = 'AMERICAN_ASIAN_FUSION',
  SPANISH = 'SPANISH',
  ALGERIAN = 'ALGERIAN',
  EUROPEAN = 'EUROPEAN',
  JAPANESE_FUSION = 'JAPANESE_FUSION',
  LEBANESE = 'LEBANESE',
  LEVANTINE = 'LEVANTINE',
  MEXICAN = 'MEXICAN',
  LOCAL_BAHRAIN = 'LOCAL_BAHRAIN',
  KUWAITI_SAUDI_ARABIAN = 'KUWAITI_SAUDI_ARABIAN',
  ITALIAN_FUSION = 'ITALIAN_FUSION',
  FRENCH_ASIAN_FUSION = 'FRENCH_ASIAN_FUSION',
  CHINESE = 'CHINESE',
  RUSSIAN = 'RUSSIAN',
  BAHRAINI = 'BAHRAINI',
  SWISS = 'SWISS',
  FRENCH_FUSION = 'FRENCH_FUSION',
  BRAZILIAN = 'BRAZILIAN',
  FRENCH = 'FRENCH',
  BRITISH_FUSION = 'BRITISH_FUSION',
  INDIA = 'INDIA',
  BELGIUM = 'BELGIUM',
  LOCAL_KSA = 'LOCAL_KSA',
  KOREAN = 'KOREAN',
  MEDITERRANEAN_FUSION = 'MEDITERRANEAN_FUSION',
  CAJUN = 'CAJUN',
  LOUISIANA_CREOLE_FUSION = 'LOUISIANA_CREOLE_FUSION',
  JAPANESE = 'JAPANESE',
  CANADIAN = 'CANADIAN',
  INDIAN_BRITISH_FUSION = 'INDIAN_BRITISH_FUSION',
  FRANCE = 'FRANCE',
  IRANIAN_FUSION = 'IRANIAN_FUSION',
  THAI = 'THAI',
  SENEGALESE = 'SENEGALESE',
  SCANDINAVIAN_FUSION = 'SCANDINAVIAN_FUSION',
  INDONESIAN = 'INDONESIAN',
  GCC = 'GCC',
  GHANAIAN = 'GHANAIAN',
  PORTUGUESE_FUSION = 'PORTUGUESE_FUSION',
  THAI_FUSION = 'THAI_FUSION',
  SOUTH_AMERICA = 'SOUTH_AMERICA',
  SWEDISH = 'SWEDISH',
  EGYPT_MIDDLE_EASTERN = 'EGYPT_MIDDLE_EASTERN',
  POLAND = 'POLAND',
  LEVNATINE = 'LEVNATINE',
  DUTCH = 'DUTCH',
  CUBA = 'CUBA',
  GCC_FUSION = 'GCC_FUSION',
  JAMAICAN_FUSION = 'JAMAICAN_FUSION',
  SAUDI_ARABIA = 'SAUDI_ARABIA',
  KOREAN_FUSION = 'KOREAN_FUSION',
  TEX_MEX = 'TEX_MEX',
  JORDANIAN = 'JORDANIAN',
  INTERNATIONAL = 'INTERNATIONAL',
  PERSIAN = 'PERSIAN',
  ITALIAN_AMERICAN = 'ITALIAN_AMERICAN',
  PORTUGESE = 'PORTUGESE',
  AUSTRALIAN = 'AUSTRALIAN',
  SWEDISH_FUSION = 'SWEDISH_FUSION',
  ADD_ONS = 'ADD_ONS'
}

export enum MealBlockType {
  PERMANENT = 'PERMANENT',
  REMINDER = 'REMINDER'
}

export enum FoodTagsKeys {
  protein = 'protein',
  base = 'base',
  rating = 'rating',
  taste = 'taste',
  snacktype = 'snacktype',
  addonCategory = 'addonCategory',
  category = 'category',
  cuisine = 'cuisine',
  flavorsProfile = 'flavorsProfile',
  hintFlavors = 'hintFlavors',
  texture = 'texture',
  eatingExperience = 'eatingExperience',
  spicyLevel = 'spicyLevel',
  convenience = 'convenience'
}

export enum RetailBranch {
  BOXAT = 'BOXAT',
  HITTEN = 'HITTEN',
  ROSHAN = 'ROSHAN',
  SEDRA = 'SEDRA',
  SEEF = 'SEEF'
}

export enum RetailMenuItemTag {
  FROYO = 'FROYO',
  HOT_DRINK = 'HOT_DRINK',
  MATCHA = 'MATCHA',
  SMOOTHY = 'SMOOTHY',
  SALAD = 'SALAD',
  COLD_DRINK = 'COLD_DRINK',
  RICE_MEAL = 'RICE_MEAL',
  SANDWICH = 'SANDWICH',
  CALO_MEAL = 'CALO_MEAL',
  SWEET = 'SWEET',
  WRAP = 'WRAP',
  SNACK = 'SNACK'
}

export enum FoodMenuLabelsTags {
  NEW = 'NEW',
  UPGRADED = 'UPGRADED',
  TOP_RATED = 'TOP_RATED',
  SEASONAL = 'SEASONAL',
  SUMMER = 'SUMMER',
  RAMADAN = 'RAMADAN'
}

export enum AlgorithmConfigOptions {
  MEET_TARGET_CALORIES = 'MEET_TARGET_CALORIES',
  MEET_TARGET_PROTEIN = 'MEET_TARGET_PROTEIN',
  REDUCE_FAT = 'REDUCE_FAT',
  DELICIOUS_AND_ENJOYABLE_MEALS = 'DELICIOUS_AND_ENJOYABLE_MEALS'
}
