import { FormikErrors } from 'formik';
import { CustomMealCategory, FoodComponentType } from '@calo/types';
import { Button } from '@mui/material';
import { BaseOmit, FoodComponent } from '../../../lib/interfaces';
import {
  Box,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select
} from '@mui/material-v6';
import { styles } from './styles';
import { startCase } from 'lodash';
import { handleCategoryText } from './helpers';
import InputThemeProvider from 'components/MUI/InputThemeProvider';

interface AddSectionPopup {
  isDisabled?: boolean;
  isAddSectionPopupOpen: boolean;
  values: Omit<FoodComponent, BaseOmit>;
  selectedCategories: CustomMealCategory[];
  setSelectedCategories: React.Dispatch<React.SetStateAction<CustomMealCategory[]>>;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<FormikErrors<Omit<FoodComponent, BaseOmit>>> | Promise<any>;
  handleClosePopup: () => void;
}

const AddSectionPopup = ({
  isDisabled,
  isAddSectionPopupOpen,
  values,
  selectedCategories,
  setFieldValue,
  setSelectedCategories,
  handleClosePopup
}: AddSectionPopup) => {
  const confirmSectionsHandler = () => {
    if (selectedCategories.length === 0) {
      return;
    }
    const existingCategories = values.sections?.map((section) => section.category) || [];

    const newSections = [...(values.sections ?? [])];
    for (const section of selectedCategories) {
      if (!existingCategories.includes(section)) {
        newSections.push({
          category: section,
          quantity: 0,
          availability: false,
          tag: FoodComponentType.base
        });
      }
    }

    setFieldValue('sections', newSections);
    handleClosePopup();
  };

  const handleDelete = (valueToDelete: string) => {
    setSelectedCategories((prev) => prev.filter((value) => value !== valueToDelete));
  };

  return (
    <Dialog
      sx={styles.dialogContainer}
      open={isAddSectionPopupOpen}
      onClose={handleClosePopup}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle sx={styles.dialogTitle}>Add section</DialogTitle>
      <DialogContent sx={styles.dialogContentContainer}>
        <InputThemeProvider>
          <FormControl fullWidth sx={{ marginTop: '24px' }}>
            <InputLabel id="multi-select-label">Section Name</InputLabel>
            <Select
              labelId="multi-select-label"
              data-test="component-section-tags"
              disabled={isDisabled}
              multiple
              value={selectedCategories}
              onChange={(event) => setSelectedCategories(event.target.value as CustomMealCategory[])}
              input={<OutlinedInput label="Select Options" />}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {(selected as string[]).map((value) => (
                    <Chip
                      key={value}
                      label={startCase(handleCategoryText(value as CustomMealCategory))}
                      onDelete={() => handleDelete(value)}
                      onMouseDown={(event) => {
                        event.stopPropagation();
                      }}
                    />
                  ))}
                </Box>
              )}
            >
              {Object.values(CustomMealCategory).map((value) => (
                <MenuItem key={value} value={value}>
                  {startCase(handleCategoryText(value))}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </InputThemeProvider>
      </DialogContent>
      <DialogActions>
        <Button
          data-test="sectionTagsConfirmButton"
          variant="outlined"
          color="primary"
          size="medium"
          onClick={confirmSectionsHandler}
        >
          Confirm
        </Button>
        <Button variant="text" color="inherit" size="medium" onClick={handleClosePopup}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddSectionPopup;
