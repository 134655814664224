import React from 'react';
import { Breadcrumbs, Typography } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Link } from 'react-router-dom';
import { styles } from './styles';
import { caloTheme } from 'assets/images/theme/calo';
import { BreadcrumbItem } from 'lib/interfaces';

interface RouteBreadCrumbProps {
  items: BreadcrumbItem[];
}

const RouteBreadCrumb: React.FC<RouteBreadCrumbProps> = ({ items }) => {
  const breadcrumbs = items.map((item, index) => {
    const isLast = index === items.length - 1;

    return item.url ? (
      <Link to={item.url} key={item.text}>
        <Typography
          sx={{
            ...styles.breadcrumbText,
            '&:hover': {
              textDecoration: 'underline',
              color: caloTheme.palette.textPrimary
            }
          }}
        >
          {item.text}
        </Typography>
      </Link>
    ) : (
      <Typography key={item.text} sx={isLast ? styles.breadcrumbActiveText : styles.breadcrumbText}>
        {item.text}
      </Typography>
    );
  });

  return (
    <Breadcrumbs separator={<ChevronRightIcon sx={{ fontSize: '15px' }} />} aria-label="breadcrumb">
      {breadcrumbs}
    </Breadcrumbs>
  );
};

export default RouteBreadCrumb;
