import { Permission } from '@calo/dashboard-types';
import { Stack } from '@mui/material';
import { Button, Typography } from '@mui/material-v6';
import { toggleUISettings } from 'actions/ui';
import { caloThemeV2 } from 'assets/themev2';
import { ModalRef } from 'components/Modal';
import Popup from 'components/Popup';
import { format } from 'date-fns';
import { MenuPresentationType } from 'lib/enums';
import { useUserRoles } from 'lib/hooks';
import { startCase } from 'lodash-es';
import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { FilterButton, MenuPresentationField } from '../../components';
import CreateDailyMenuActions from './CreateDailyMenuActions';
import CreateWeeklyMenuActions from './CreateWeeklyMenuActions';

interface MenuPageActionsProps {
  disabled?: boolean;
  isEditable: boolean;
  generatedAt: string;
  selectedMenuDay: string;
  menuPresentation: MenuPresentationType;
  isGenerateMenuLoading: boolean;
  generateMenuHandler: () => void;
  setMenuPresentation: (newPresentation: MenuPresentationType) => void;
}

const MenuPageActions = ({
  disabled,
  isEditable,
  generatedAt,
  selectedMenuDay,
  menuPresentation,
  isGenerateMenuLoading,
  generateMenuHandler,
  setMenuPresentation
}: MenuPageActionsProps) => {
  const dispatch = useDispatch();
  const userRoles = useUserRoles();
  const debounceRef = useRef<NodeJS.Timeout | null>(null);
  const publishMenuRef = useRef<ModalRef>();

  useEffect(() => {
    return () => {
      if (debounceRef.current) {
        clearTimeout(debounceRef.current);
      }
    };
  });

  useEffect(() => {
    if (!isGenerateMenuLoading) {
      publishMenuRef.current?.close();
    }
  }, [isGenerateMenuLoading]);

  return (
    <>
      <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
        <FilterButton onClick={() => dispatch(toggleUISettings())} disabled={disabled} />
        <Stack sx={{ flexDirection: 'row', gap: 2, alignItems: 'center' }}>
          <MenuPresentationField
            menuPresentation={menuPresentation}
            setMenuPresentation={setMenuPresentation}
            disabled={disabled}
          />
          {menuPresentation === MenuPresentationType.daily && userRoles.includes(Permission.GENERATE_DAY_MENU) && isEditable && (
            <>
              <Button
                variant="contained"
                color="primary"
                size="large"
                disabled={isGenerateMenuLoading}
                sx={{ color: caloThemeV2.palette.primary.contrastText, position: 'relative' }}
                onClick={() => publishMenuRef.current?.open()}
              >
                Publish Menu
                {
                  <Typography
                    sx={{
                      position: 'absolute',
                      width: '100%',
                      textAlign: 'start',
                      bottom: -20,
                      fontSize: '12px',
                      color: caloThemeV2.palette.text.disabled,
                      textTransform: 'none'
                    }}
                  >
                    {startCase('Last generated:')} {generatedAt ? format(new Date(generatedAt), 'yyyy-MM-dd') : 'N/A'}
                  </Typography>
                }
              </Button>
              <Popup maxWidth="lg" ref={publishMenuRef} onClose={() => publishMenuRef.current?.close()}>
                <Stack sx={{ width: '506px' }}>
                  <Typography sx={{ fontSize: '20px', fontWeight: 500 }}>Confirm menu publication?</Typography>
                  <Typography sx={{ marginTop: 2, fontSize: '16px' }}>
                    Are you sure you want to publish this menu for {selectedMenuDay}?{' '}
                  </Typography>
                  <Typography sx={{ fontSize: '16px' }}>Once published, it will be visible to all users.</Typography>

                  <Stack sx={{ flexDirection: 'row', gap: 2, alignItems: 'center', alignSelf: 'flex-end', marginTop: 4 }}>
                    <Button variant="outlined" disabled={isGenerateMenuLoading} onClick={generateMenuHandler}>
                      Publish
                    </Button>
                    <Button
                      variant="text"
                      onClick={() => publishMenuRef.current?.close()}
                      disabled={isGenerateMenuLoading}
                      sx={{
                        color: caloThemeV2.palette.text.primary
                      }}
                    >
                      Cancel
                    </Button>
                  </Stack>
                </Stack>
              </Popup>
            </>
          )}
          {menuPresentation === MenuPresentationType.daily && <CreateDailyMenuActions disabled={disabled} />}
          {menuPresentation === MenuPresentationType.weekly && <CreateWeeklyMenuActions disabled={disabled} />}
        </Stack>
      </Stack>
    </>
  );
};

export default MenuPageActions;
