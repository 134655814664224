import { Box, Chip } from '@mui/material-v6';
import { styled } from '@mui/system';
import { MenuFilterType } from 'lib/enums';

interface CustomChipToggleGroupProps {
  label1: string;
  label2: string;
  label3: string;
  selectedValue: MenuFilterType;
  onChange: (value: MenuFilterType) => void;
}

const StyledChip = styled(Chip)(({ theme }) => ({
  width: '152.5px',
  height: '32px',
  borderRadius: '8px',
  fontWeight: 400,
  fontSize: '13px',
  lineHeight: '18px',
  letter: '0.16px',
  padding: '4px',
  '&.MuiChip-filled': {
    backgroundColor: '#1C7E57',
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark
    }
  },
  '&.MuiChip-outlined': {
    backgroundColor: 'transparent',
    color: theme.palette.text.primary,
    borderColor: 'transparent',
    '&:hover': {
      backgroundColor: theme.palette.action.hover
    }
  },
  '& .MuiTouchRipple-root': {
    display: 'none'
  }
}));

const ChipContainer = styled(Box)({
  display: 'flex',
  backgroundColor: '#EEEEEE',
  borderRadius: '8px',
  padding: '8px 16px 8px 16px',
  justifyContent: 'center',
  width: '100%'
});

const ParentChipContainer = styled(Box)({
  display: 'flex',
  backgroundColor: 'transparent',
  padding: '8px 8px 0px 8px',
  justifyContent: 'center',
  width: '100%'
});

const CustomChipToggleGroup = ({ label1, label2, label3, selectedValue, onChange }: CustomChipToggleGroupProps) => {
  const handleClick = (clickedValue: MenuFilterType) => {
    onChange(clickedValue);
  };

  return (
    <ParentChipContainer>
      <ChipContainer>
        <StyledChip
          label={label1}
          onClick={() => handleClick(MenuFilterType.Breakfast)}
          color="primary"
          variant={selectedValue === MenuFilterType.Breakfast ? 'filled' : 'outlined'}
        />
        <StyledChip
          label={label2}
          onClick={() => handleClick(MenuFilterType.LunchDinner)}
          color="primary"
          variant={selectedValue === MenuFilterType.LunchDinner ? 'filled' : 'outlined'}
        />
        <StyledChip
          label={label3}
          onClick={() => handleClick(MenuFilterType.Snack)}
          color="primary"
          variant={selectedValue === MenuFilterType.Snack ? 'filled' : 'outlined'}
        />
      </ChipContainer>
    </ParentChipContainer>
  );
};

export default CustomChipToggleGroup;
