import { useEffect, useState } from 'react';
import { useUserRoles } from 'lib/hooks';
import { v4 as uuid } from 'uuid';
import AddIcon from '@mui/icons-material/Add';

import { CreateFoodComponentReq, Permission, UpdateFoodComponentReq } from '@calo/dashboard-types';
import {
  Button,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material-v6';

import ComponentStandardsPopup from './ComponentStandardsPopup';
import ComponentStandardsRow from './ComponentStandardsRow';
import { styles } from './styles';
import { Dialog, DialogContent, DialogTitle, Divider } from '@mui/material-v6';

const defaultStandards = [
  {
    id: uuid(),
    name: 'Color'
  },
  {
    id: uuid(),
    name: 'Taste'
  },
  {
    id: uuid(),
    name: 'Appearance'
  },
  {
    id: uuid(),
    name: 'Texture'
  },
  {
    id: uuid(),
    name: 'Smell'
  }
];

interface ComponentStandardsProps {
  values: UpdateFoodComponentReq | CreateFoodComponentReq;
  setValues: (v: any) => void;
  isDisabled: boolean;
  allowUploadImages?: boolean;
}

const ComponentStandards = ({ values, setValues, isDisabled, allowUploadImages }: ComponentStandardsProps) => {
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const userRoles = useUserRoles();
  const [activeStandardId, setActiveStandardId] = useState<string | null>(null);
  const openEditModal = (id: string) => {
    setActiveStandardId(id);
    setIsAddModalOpen(true);
  };
  const handleDelete = (id: string) => {
    const standards = [...(values.standards ?? [])];
    const filteredStandards = standards.filter((s) => s.id !== id);
    setValues({ ...values, standards: filteredStandards });
  };

  useEffect(() => {
    if (!values.standards || values.standards.length === 0) {
      setValues({ ...values, standards: defaultStandards });
    }
  }, [values]);

  return (
    <>
      <Stack sx={styles.headerContainer}>
        <Typography sx={styles.title}>Standards</Typography>
        {values.standards && values.standards.length > 0 && (
          <Button
            data-test="addStepButton"
            variant="text"
            color="primary"
            size="large"
            startIcon={<AddIcon />}
            onClick={() => {
              setIsAddModalOpen(true);
            }}
            disabled={!userRoles.includes(Permission.UPDATE_FOOD_COMPONENTS) || Boolean(isDisabled)}
          >
            Add
          </Button>
        )}
      </Stack>
      <Divider sx={styles.divider} />
      <Stack direction="row" justifyContent="space-evenly" alignItems="baseline" spacing={2} width={'100%'}>
        {values.standards && values.standards?.length > 0 ? (
          <Paper sx={styles.tableContainer}>
            <TableContainer>
              <Table sx={{ width: '100%' }}>
                <TableHead sx={styles.tableHead}>
                  <TableRow>
                    <TableCell sx={{ width: '21.7%' }}>Standard</TableCell>
                    <TableCell sx={{ width: '67.4%' }}>Description</TableCell>
                    <TableCell sx={{ width: '10.9%' }}></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {values.standards?.map((standard) => (
                    <ComponentStandardsRow
                      key={standard.id}
                      standard={standard}
                      openEditModal={openEditModal}
                      handleDelete={handleDelete}
                      isDisabled={!userRoles.includes(Permission.UPDATE_FOOD_COMPONENTS) || Boolean(isDisabled)}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        ) : (
          <Button
            data-test="addStepButton"
            variant="outlined"
            sx={{
              marginBottom: 2,
              lineHeight: '17px',
              fontWeight: 600,
              fontSize: '16px',
              borderRadius: '8px',
              textTransform: 'none',
              paddingY: '10px',
              paddingX: '16px'
            }}
            onClick={() => {
              setIsAddModalOpen(true);
            }}
            disabled={!userRoles.includes(Permission.UPDATE_FOOD_COMPONENTS) || Boolean(isDisabled)}
          >
            Add
          </Button>
        )}
        <Dialog
          sx={styles.dialogContainer}
          open={isAddModalOpen}
          onClose={() => {
            setIsAddModalOpen(false);
            setActiveStandardId(null);
          }}
        >
          <DialogTitle sx={styles.dialogTitle}>{activeStandardId ? 'Edit Standard' : 'Add Standard'}</DialogTitle>
          <DialogContent>
            <ComponentStandardsPopup
              closePopup={() => setIsAddModalOpen(false)}
              values={values}
              setValues={(v) => setValues(v)}
              activeStandardId={activeStandardId}
              setActiveStandardId={(id) => setActiveStandardId(id)}
              allowUploadImages={allowUploadImages}
            />
          </DialogContent>
        </Dialog>
      </Stack>
    </>
  );
};
export default ComponentStandards;
