import { Permission } from '@calo/dashboard-types';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { Box, MenuItem, Stack, TextField, Typography, colors } from '@mui/material-v6';
import { updateFoodPreference } from 'actions/subscription';
import { caloThemeV2 } from 'assets/themev2';
import { Icon } from 'components';
import { AlgorithmConfigOptions } from 'lib/calo-types';
import { useUserRoles } from 'lib/hooks';
import { Subscription } from 'lib/interfaces';
import { startCase } from 'lodash-es';
import { useState } from 'react';
import { useMutation } from 'react-query';
import useMealPrioritiesForm from './useMealPrioritiesForm';

interface MealPrioritiesCardProps {
  subscription: Subscription;
  refetchSubscription: () => void;
}

const MealPrioritiesCard = ({ subscription, refetchSubscription }: MealPrioritiesCardProps) => {
  const roles = useUserRoles();
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const { mutateAsync } = useMutation(updateFoodPreference);

  const onSubmit = async (values: { algorithmConfig: AlgorithmConfigOptions }) => {
    await mutateAsync({
      subscriptionId: subscription.id,
      algorithmConfig: values.algorithmConfig
    });
    refetchSubscription();
  };

  const algorithmConfig = subscription.foodPreference?.algorithmConfig || AlgorithmConfigOptions.MEET_TARGET_CALORIES;

  const { values, handleBlur, setFieldValue, setValues, initialValues } = useMealPrioritiesForm(algorithmConfig, onSubmit);

  const handleEditing = (value: boolean) => {
    if (!value) {
      onSubmit(values);
    }
    setIsEditing(value);
  };

  const handleReset = () => {
    setValues(initialValues);
    setIsEditing(false);
  };

  return (
    <>
      <Box
        sx={{
          margin: 2,
          padding: 2,
          backgroundColor: colors.grey[100],
          borderRadius: 2,
          [caloThemeV2.breakpoints.down(caloThemeV2.breakpoints.values.lg)]: {
            width: 'auto',
            display: 'flex',
            textAlign: 'center',
            flexDirection: 'column'
          }
        }}
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'space-between'}
      >
        <Typography
          sx={{
            textAlign: 'left',
            fontSize: '19px',
            lineHeight: '23px',
            fontFamily: caloThemeV2.typography.fontFamily,
            fontWeight: 600
          }}
        >
          Meal Priority
        </Typography>
        {isEditing ? (
          <Stack sx={{ justifyContent: 'end', flexDirection: 'row' }}>
            <CheckIcon sx={{ marginRight: 2 }} style={{ cursor: 'pointer' }} onClick={() => handleEditing(!isEditing)} />
            <ClearIcon style={{ cursor: 'pointer' }} onClick={handleReset} />
          </Stack>
        ) : (
          <Icon
            onClick={() => handleEditing(!isEditing)}
            name="edit2"
            size={6}
            style={{ cursor: 'pointer', width: '26px', height: '26px' }}
          />
        )}
      </Box>
      <Box
        component="form"
        autoComplete="off"
        sx={{
          width: 'full',
          mx: 2,
          '& .MuiTextField-root': { my: 2, mx: 2 },
          [caloThemeV2.breakpoints.down(caloThemeV2.breakpoints.values.lg)]: {
            width: 'full',
            display: 'flex',
            flexDirection: 'column',
            '& .MuiTextField-root': { my: 2, mx: 1, width: '100%', justifyContent: 'space-between' }
          }
        }}
      >
        <Stack display={'flex'} flexDirection={'column'} justifyContent={'space-between'} sx={{ width: '100%' }}>
          <TextField
            select
            label="Priority"
            onBlur={handleBlur}
            name="algorithmConfig"
            id="exact-subscription-meal-priority"
            value={values.algorithmConfig}
            onChange={(data) => {
              setFieldValue('algorithmConfig', data.target.value);
            }}
            disabled={!roles.includes(Permission.UPDATE_SUBSCRIPTION) || !isEditing}
            InputProps={{ inputProps: { style: { borderRadius: 8 } }, style: { borderRadius: 8 } }}
          >
            {Object.values(AlgorithmConfigOptions).map((option) => {
              return (
                <MenuItem key={option} value={option}>
                  {startCase(option.toLowerCase())}
                </MenuItem>
              );
            })}
          </TextField>
        </Stack>
      </Box>
    </>
  );
};

export default MealPrioritiesCard;
