import { Country, Kitchen } from '@calo/types';
import { Button, Divider, MenuItem, Stack, TextField, Typography } from '@mui/material-v6';
import { caloThemeV2 } from 'assets/themev2';
import { getAccessibleCountries } from 'lib/helpers';
import { useUserKitchens } from 'lib/hooks';
import { startCase } from 'lodash-es';
import { CreateParams } from '../../types';

interface CountryKitchenFormProps {
  params: CreateParams;
  closeHandler: () => void;
  setIsNext: React.Dispatch<React.SetStateAction<boolean>>;
  setParams: React.Dispatch<React.SetStateAction<CreateParams>>;
}

const CountryKitchenForm = ({ params, setParams, closeHandler, setIsNext }: CountryKitchenFormProps) => {
  const userKitchens: Kitchen[] = useUserKitchens();

  return (
    <Stack sx={{ width: '444px' }}>
      <Typography sx={{ fontSize: '20px', fontWeight: 600 }}>Select Region</Typography>
      <Divider sx={{ marginTop: '16px', marginLeft: '-20px', marginRight: '-20px' }} />
      <Stack sx={{ display: 'flex', flexDirection: 'column', gap: 4, marginTop: '20px' }}>
        <TextField
          select
          label="Country"
          placeholder="Select Country"
          value={params.country}
          onChange={(data) => {
            setParams((prev) => ({
              ...prev,
              country: data.target.value as Country,
              kitchen: userKitchens.find((kitchen) => kitchen.includes(data.target.value)) as Kitchen
            }));
          }}
        >
          {getAccessibleCountries(userKitchens).map((country) => (
            <MenuItem key={country} value={country}>
              {startCase(country)}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          select
          label="Kitchen"
          value={params.kitchen}
          placeholder="Select Kitchen"
          onChange={(data) => {
            setParams((prev) => ({
              ...prev,
              kitchen: data.target.value as Kitchen
            }));
          }}
        >
          {userKitchens
            .filter((kitchen) => kitchen.includes(params.country || Country.BH))
            .map((kitchen) => (
              <MenuItem key={kitchen} value={kitchen}>
                {kitchen}
              </MenuItem>
            ))}
        </TextField>
      </Stack>
      <Divider sx={{ marginTop: '20px', marginLeft: '-20px', marginRight: '-20px' }} />
      <Stack sx={{ marginTop: '16px', flexDirection: 'row', gap: 2, alignItems: 'center', alignSelf: 'flex-end' }}>
        <Button variant="text" disabled={!params.country || !params.kitchen} onClick={() => setIsNext(true)}>
          Continue
        </Button>
        <Button variant="text" onClick={closeHandler} sx={{ color: caloThemeV2.palette.text.primary }}>
          Cancel
        </Button>
      </Stack>
    </Stack>
  );
};

export default CountryKitchenForm;
