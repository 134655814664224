import InputThemeProvider from 'components/MUI/InputThemeProvider';
import { FormikErrors, FormikTouched } from 'formik';
import { BaseOmit, FoodComponent } from 'lib/interfaces';
import { Card, Divider, Stack, TextField, Typography } from '@mui/material-v6';
import { styles } from './styles';
import { forwardRef } from 'react';

interface ComponentNameAndImageProps {
  isSubmitting: boolean;
  values: Omit<FoodComponent, BaseOmit>;
  errors: FormikErrors<Omit<FoodComponent, BaseOmit>>;
  touched: FormikTouched<Omit<FoodComponent, BaseOmit>>;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<FormikErrors<Omit<FoodComponent, BaseOmit>>> | Promise<any>;
  handleBlur: { (e: React.FocusEvent<any, Element>): void };
  handleChange: { (e: React.ChangeEvent<any>): void };
}

const ComponentInformationCard = forwardRef<HTMLDivElement, ComponentNameAndImageProps>(
  ({ isSubmitting, values, errors, touched, handleChange, handleBlur }: ComponentNameAndImageProps, ref) => {
    return (
      <Card variant="outlined" sx={styles.card} ref={ref}>
        <Typography sx={styles.title}>Information</Typography>
        <Divider sx={styles.divider} />
        <InputThemeProvider>
          <Stack sx={styles.inputContainer}>
            <Stack direction={'row'} justifyContent={'space-between'} gap={'24px'}>
              <TextField
                fullWidth
                data-test="foodComponentEnNameTextField"
                label="Name (English)"
                name="name.en"
                id="name.en"
                value={values.name.en}
                disabled={isSubmitting}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.name?.en && !!errors.name?.en}
              />
              <TextField
                data-test="foodComponentArNameTextField"
                fullWidth
                label="Name (Arabic)"
                name="name.ar"
                id="name.ar"
                value={values.name.ar}
                disabled={isSubmitting}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.name?.ar && !!errors.name?.ar}
              />
            </Stack>
            <Stack sx={{ width: '100%' }}>
              <TextField
                label="Internal name"
                name="internalName"
                id="internalName"
                value={values.internalName}
                sx={{ width: '100%' }}
                disabled={isSubmitting}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.internalName && !!errors.internalName}
              />
            </Stack>
          </Stack>
        </InputThemeProvider>
      </Card>
    );
  }
);

export default ComponentInformationCard;
