import { forwardRef, useEffect, useState } from 'react';

import { omit } from 'lodash-es';

import { Brand, Country, Kitchen as KitchenType } from '@calo/types';

import { MenuFoodComponent } from '@calo/dashboard-types';
import { createFood } from 'actions';
import { Modal, Button as OButton, Select } from 'components';
import { getAvailableMealSizes } from 'lib/helpers';
import { Food } from 'lib/interfaces';

interface NewSizeModalProps {
  selectedFood: Food;
  foodWithAllSizes: Food[];
  setSelectedFood: (food: Food) => void;
  setFoodWithAllSizes: (food: Food[]) => void;
  close: () => void;
}

const formatComponents = (components?: MenuFoodComponent[]) => {
  return components?.map((comp) => ({
    id: comp.id,
    quantity: comp.quantity
  }));
};

const NewSizeModal = forwardRef(
  ({ selectedFood, foodWithAllSizes, setSelectedFood, setFoodWithAllSizes, close }: NewSizeModalProps, ref) => {
    const [inputSize, setInputSize] = useState('');
    const [selectedSize, setSelectedSize] = useState('');
    const [isDuplicate, setIsDuplicate] = useState(false);

    useEffect(() => {
      const sizeExists = foodWithAllSizes.some((food) => food.size === selectedSize.toUpperCase());
      setIsDuplicate(sizeExists);
    }, [selectedSize, foodWithAllSizes]);

    const handleDuplicatedFood = async () => {
      if (isDuplicate) return;

      const duplicateFood = omit(selectedFood, [
        'id',
        'createdAt',
        'dataType',
        'updatedAt',
        'lowercaseName',
        'slug',
        'fhk',
        'numberOfRatings',
        'totalRating',
        'usedOnMenu',
        'lastUsedOnMenu',
        'periodicAggregatedRatings',
        'totalNumberOfFavorites',
        'totalNumberOfBlocks',
        'componentPurchasingCost',
        'purchasingCost',
        'ingredients',
        'pCost'
      ]) as Food;
      duplicateFood.size = selectedSize.toUpperCase();
      duplicateFood.brand = duplicateFood.brand ? duplicateFood.brand : Brand.CALO;
      duplicateFood.country = duplicateFood.country ? duplicateFood.country : Country.BH;
      duplicateFood.kitchen = duplicateFood.kitchen ? duplicateFood.kitchen : KitchenType.BH1;
      duplicateFood.components = formatComponents(duplicateFood.components);

      const response = await createFood(duplicateFood as any);
      setFoodWithAllSizes([...foodWithAllSizes, response]);
      setSelectedFood(response);
      close();
      setSelectedSize('');
    };

    return (
      <Modal ref={ref}>
        <div className="flex-row justify-between items-center mb-3">
          <h1 className="text-3xl font-bold">Create New Meal Size</h1>
          <OButton icon="fas fa-times fa-lg" onClick={() => close()} className="ml-4 w-4 h-auto border-none" />
        </div>
        <div className="flex flex-col">
          <Select
            label="Choose the new meal size"
            value={selectedSize}
            onInputChange={(d: any) => {
              if (d) {
                setInputSize(d);
              }
            }}
            onChange={(data: any) => setSelectedSize(data.value)}
            options={getAvailableMealSizes(['XS', 'S', 'M', 'L', 'XL', inputSize], foodWithAllSizes).map((type) => ({
              value: type.toUpperCase(),
              label: type.toUpperCase()
            }))}
          />
          <OButton
            disabled={!selectedSize || isDuplicate}
            primary
            data-test="create_meal_size_button"
            content={isDuplicate ? 'Size Already Exists' : 'Create Meal Size'}
            onClick={handleDuplicatedFood}
          />
        </div>
      </Modal>
    );
  }
);

export default NewSizeModal;
