import { History } from 'history';
import { isEmpty } from 'lodash';
import { FoodComponentFilters, FoodFilters, IngredientsFilters } from '@calo/dashboard-types';
import { Button, Stack, Typography } from '@mui/material';
import { toggleUISettings } from 'actions/ui';
import { Food, FoodComponent, Ingredient } from 'lib/interfaces';
import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getAddRoute, handleExport } from './helper';
import { styles } from './style';
import { Box, Breadcrumbs, InputAdornment } from '@mui/material-v6';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import SearchIcon from '@mui/icons-material/Search';
import { InputMUI } from 'components/MUI';
import DownloadIcon from '@mui/icons-material/Download';
import LoadingButton from '@mui/lab/LoadingButton';
import { resolveCountry } from 'lib/helpers';
import { toast } from 'react-toastify';

interface SharedListHeaderCardProps<T extends IngredientsFilters | FoodComponentFilters | FoodFilters> {
  filters: T;
  setFilters: Dispatch<SetStateAction<T>>;
  history: History<unknown>;
  list: Ingredient[] | FoodComponent[] | Food[];
  isDisabled?: boolean;
  listType: 'food' | 'Components' | 'Ingredients';
}

const SharedListHeaderCard = <T extends IngredientsFilters | FoodComponentFilters | FoodFilters>({
  listType,
  filters,
  setFilters,
  history,
  list,
  isDisabled
}: SharedListHeaderCardProps<T>) => {
  const addRoute = useMemo(() => getAddRoute(listType), [listType]);
  const dispatch = useDispatch();
  const [exportLoading, setExportLoading] = useState(false);

  const handleAddClick = () => {
    if (addRoute) {
      history.push(addRoute);
    }
  };

  const handleExportClick = async () => {
    try {
      setExportLoading(true);
      await handleExport(listType, filters);
    } catch (error) {
      toast('Error occurred while exporting data', { type: 'error', autoClose: 2000 });
      console.error('Error occurred while exporting data: ', error);
    } finally {
      setExportLoading(false);
    }
  };

  const breadCrumbData = [
    <Typography variant="body1" key={1} sx={{ ...styles.breadcrumbInactive, ...styles.breadcrumbs }}>
      Food
    </Typography>,
    <Typography variant="body1" key={2} sx={{ ...styles.breadcrumbActive, ...styles.breadcrumbs }}>
      {listType}
    </Typography>
  ];

  return (
    <>
      <Box sx={styles.header}>
        <Box sx={styles.firstRow}>
          <Breadcrumbs separator={<ChevronRightIcon sx={{ fontSize: '15px' }} />} aria-label="breadcrumb">
            {breadCrumbData}
          </Breadcrumbs>
          <Typography key={1} sx={styles.countryKitchenText}>
            {resolveCountry(filters.country)} / {filters.kitchen}
          </Typography>
        </Box>
        <Typography variant="h4" sx={styles.title}>
          {listType}
        </Typography>
      </Box>
      <Stack sx={styles.actionRow}>
        <Box sx={styles.filterContainer}>
          <Button
            onClick={() => dispatch(toggleUISettings())}
            startIcon={<FilterAltIcon style={{ fontSize: '24px' }} />}
            variant="outlined"
            color="inherit"
            size="large"
          >
            FILTER
          </Button>
          <InputMUI
            data-test="nameInput"
            type="text"
            placeholder={'Search'}
            value={filters.name}
            onChange={(e) => setFilters({ ...filters, name: e.target.value })}
            debounce
            sx={styles.searchInput}
            inputProps={{
              style: styles.searchInputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon fontSize="medium" />
                </InputAdornment>
              )
            }}
          />
        </Box>
        <Box sx={styles.actionButtonContainer}>
          <LoadingButton
            disabled={isEmpty(list) || exportLoading}
            loading={exportLoading}
            onClick={handleExportClick}
            variant="outlined"
            color="inherit"
            size="large"
            startIcon={<DownloadIcon style={{ fontSize: '24px' }} />}
          >
            download
          </LoadingButton>
          <Button
            disabled={Boolean(isDisabled)}
            onClick={handleAddClick}
            variant="contained"
            color="primary"
            size="large"
            sx={{ color: 'white' }}
          >
            create
          </Button>
        </Box>
      </Stack>
    </>
  );
};

export default SharedListHeaderCard;
