export const styles = {
  textField: {
    width: '33%',
    '& .MuiOutlinedInput-root': {
      height: '40px'
    },
    '& .MuiOutlinedInput-input': {
      padding: '8px 14px'
    },
    '& .MuiInputLabel-root': {
      transform: 'translate(14px, 10px) scale(1)'
    },
    '& .MuiInputLabel-root.MuiInputLabel-shrink': {
      transform: 'translate(14px, -8px) scale(0.75)'
    }
  }
};
