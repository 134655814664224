import { InfoOutlined } from '@mui/icons-material';
import { Box, Typography } from '@mui/material-v6';
import { caloTheme } from 'assets/images/theme/calo';
import { toast } from 'react-toastify';

const orderDetailChangedToast = ({ orderNo = '' }: { orderNo?: string } = {}) => {
  toast(
    <Box display="flex" alignItems="start" justifyContent="space-between" sx={{ color: caloTheme.palette.errorMain }}>
      <InfoOutlined sx={{ marginRight: '16px', marginTop: '10px' }} />
      <Box display="flex" alignItems="center">
        <Box>
          <Typography variant="subtitle1" fontWeight="bold">
            Order details changed
          </Typography>
          <Typography variant="body2">Order details for the {orderNo} has been changed</Typography>
        </Box>
      </Box>
    </Box>,
    {
      autoClose: 5000,
      position: 'top-right',
      hideProgressBar: false,
      theme: 'colored',
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      closeButton: true,
      style: {
        backgroundColor: '#fdecea'
      }
    }
  );
};

export default orderDetailChangedToast;
