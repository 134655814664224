import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Box, Button as ButtonMUI, Tooltip, Typography } from '@mui/material';

import { caloTheme } from 'assets/images/theme/calo';
import { ModalRef } from '../';

interface ComponentProps {
  isCalculatedMacrosDifferent: boolean;
  calculatedMacrosRef: React.MutableRefObject<ModalRef | undefined>;
  applyCalculatedMacros: () => void;
  isDisabled?: boolean;
}

const FoodComponentMacrosCalculator = ({
  isCalculatedMacrosDifferent,
  applyCalculatedMacros,
  calculatedMacrosRef,
  isDisabled
}: ComponentProps) => {
  return (
    <Box p={'16px'} display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={'space-between'}>
      <Typography variant="h5" sx={{ fontSize: '24px', fontWeight: 400, lineHeight: '150%' }}>
        Macros
      </Typography>
      <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
        {isCalculatedMacrosDifferent && (
          <Tooltip
            onClick={() => calculatedMacrosRef.current?.open()}
            title="Calculated nutritional values are different, click for details."
            placement="top-end"
            sx={{
              backgroundColor: caloTheme.palette.secondaryRed50,
              borderRadius: 10,
              padding: 0.2,
              ':hover': { cursor: 'pointer' }
            }}
          >
            <WarningAmberIcon color={'error'} fontSize="medium" />
          </Tooltip>
        )}
        <ButtonMUI
          variant="outlined"
          disabled={isDisabled}
          data-test="calculate-macros-food-component-button"
          sx={{
            ml: 2,
            mr: 1,
            padding: 1,
            px: 2,
            fontWeight: 600,
            fontSize: '16px',
            borderRadius: '8px',
            textTransform: 'none',
            borderColor: caloTheme.palette.primary500,
            color: caloTheme.palette.primary500,
            '&:hover': {
              color: caloTheme.palette.primary600,
              borderColor: caloTheme.palette.primary600
            }
          }}
          onClick={applyCalculatedMacros}
        >
          Calculate
        </ButtonMUI>
      </Box>
    </Box>
  );
};

export default FoodComponentMacrosCalculator;
