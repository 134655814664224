import { CreateFoodComponentReq, FoodComponentMethodStep, UpdateFoodComponentReq } from '@calo/dashboard-types';
import { getImageUploadLink } from 'actions';
import { useState } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { useParams } from 'react-router';
import ComponentMethodRow from './ComponentMethodRow';
import cx from 'classnames';

interface ComponentMethodProps {
  values: UpdateFoodComponentReq | CreateFoodComponentReq;
  isEdit: boolean;
  disabled: boolean;
  uploadImages: boolean;
  setValues: (v: any) => void;
  setIsEdit: (value: boolean) => void;
  setFieldValue: (value: any) => void;
}

const ComponentMethod = ({
  isEdit,
  setIsEdit,
  setFieldValue,
  disabled,
  uploadImages,
  values,
  setValues
}: ComponentMethodProps) => {
  const { id } = useParams<{ id: string }>();
  const [selected, setSelected] = useState<FoodComponentMethodStep & { index: number }>({
    description: '',
    attachment: '',
    station: '',
    index: -1
  });

  const updateMethodStep = (description: string, index: number, attachment?: string, station?: string) => {
    const tempData = [...(values.method ?? [])];
    const step = tempData[index];
    if (step) {
      tempData[index] = {
        ...step,
        description: description,
        attachment: attachment,
        station: station
      };
      setValues({
        ...values,
        method: tempData
      });
      setSelected({ ...tempData[index], index });
      setIsEdit(true);
    } else {
      console.error('Step not found at index:', index);
    }
  };

  const handleDragEnd = (result: any) => {
    if (!result.destination) return;
    const reorderedItems = [...(values.method ?? [])];
    const [movedItem] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, movedItem);
    setValues({
      ...values,
      method: reorderedItems
    });
    setFieldValue(reorderedItems);

    if (selected.index === result.source.index) {
      setSelected({ ...movedItem, index: result.destination.index });
    }
  };

  const setActiveSelected = (r: FoodComponentMethodStep, index: number) => {
    const newSelected = {
      description: r.description,
      attachment: r.attachment,
      station: r.station,
      index: index
    };
    setSelected(newSelected);
    setIsEdit(true);
  };

  return (
    <div className={cx('card has-table', { 'p-4': values?.method?.length })}>
      <div className="card-content">
        <DragDropContext onDragEnd={handleDragEnd}>
          <table className="table is-fullwidth is-striped is-hoverable is-sortable">
            <Droppable droppableId="droppable">
              {(provider) => (
                <tbody className="text-capitalize" ref={provider.innerRef} {...provider.droppableProps}>
                  {values.method?.map((step: FoodComponentMethodStep, index) => (
                    <ComponentMethodRow
                      key={index}
                      isEdit={isEdit}
                      setIsEdit={setIsEdit}
                      values={values}
                      disabled={disabled}
                      selected={selected}
                      setValues={setValues}
                      updateMethodStep={updateMethodStep}
                      setSelected={setSelected}
                      step={{ ...step, index }}
                      uploadImages={uploadImages}
                      setActiveSelected={setActiveSelected}
                      uploadLink={() => getImageUploadLink(`food-component/${id}/method/step/${index}`)}
                      image={`${process.env.REACT_APP_BUCKET_URL}/food-component/${id}/method/step/${index}/square@1x.jpg`}
                    />
                  ))}
                </tbody>
              )}
            </Droppable>
          </table>
        </DragDropContext>
      </div>
    </div>
  );
};

export default ComponentMethod;
