import { ProcessingStage } from '@calo/dashboard-types';
import DeleteIcon from '@mui/icons-material/Delete';
import LoadingButton from '@mui/lab/LoadingButton';
import { Button, IconButton, Stack, Typography } from '@mui/material-v6';
import {
  addPrototypeComponentAction,
  approveStage1PrototypeComponent,
  approveStage2PrototypeComponent,
  deleteFoodComponent
} from 'actions';
import DeleteConfirmationPopup from 'components/DeleteConfirmationPopup';
import { ModalRef } from 'components/Modal';
import RouteBreadCrumb from 'components/RouteBreadCrumb';
import { FormOperation, Permission, PrototypeAction, Routes } from 'lib/enums';
import { formatKitchenText, resolveCountry } from 'lib/helpers';
import { getPrototypeActionApprovalButtonText } from 'lib/helpers/getPrototypeActionApprovalButtonText';
import { determineComponentOrIngredientAction } from 'lib/helpers/playgroundUtils';
import { useUserRoles } from 'lib/hooks';
import { BreadcrumbItem, FoodComponent, PrototypeFoodComponent } from 'lib/interfaces';
import { capitalize } from 'lodash';
import { useRef, useState } from 'react';
import { useMutation } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';
import { ApprovalActionMenu } from 'views/ChefPlayground/Shared/ApprovalActionMenu/ApprovalActionMenu';
import { ChangeRequestDialog } from 'views/ChefPlayground/Shared/ChangeRequestDialog/ChangeRequestDialog';
import { MealApprovalDialog } from 'views/ChefPlayground/Shared/MealApprovalDialog/MealApprovalDialog';
import ComponentNameAndUsage from './ComponentNameAndUsage';
import { styles } from './styles';

const generateBreadcrumbItems = (pathname: string, foodComponentName?: string): BreadcrumbItem[] => {
  const segments = pathname.split('/').filter(Boolean); // Removes empty segments

  return segments[0] === 'food-components'
    ? [
        { text: 'Food' },
        { text: 'Components', url: Routes.foodComponentList },
        { text: segments[segments.length - 1] === 'new' ? 'New Component' : foodComponentName || '' }
      ]
    : segments[0] === 'chef-playground'
      ? [
          { text: "Chef's Playground" },
          { text: 'Components', url: Routes.playgroundComponentList },
          { text: segments[segments.length - 1] === 'new' ? 'New Component' : foodComponentName || '' }
        ]
      : [];
};

type ComponentHeaderCardProps = {
  isValid: boolean;
  isSubmitting: boolean;
  isEdit: boolean;
  handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
  isDisabled?: boolean;
  operation: FormOperation;
  foodComponent?: PrototypeFoodComponent;
  syncComponentsPopupRef?: React.MutableRefObject<ModalRef | undefined>;
  filteredFoodUsed?: any[];
  componentFoodList?: any[];
  isFoodLoading?: boolean;
  refetch?: () => void;
};

const ComponentHeaderCard = ({
  isValid,
  isSubmitting,
  isEdit,
  handleSubmit,
  isDisabled,
  operation,
  foodComponent,
  syncComponentsPopupRef,
  filteredFoodUsed = [],
  componentFoodList = [],
  isFoodLoading = false,
  refetch
}: ComponentHeaderCardProps) => {
  const userRoles = useUserRoles();
  const history = useHistory();
  const location = useLocation();
  const deletionRef = useRef<ModalRef>(null);

  const [mealApprovalDialogOpen, setMealApprovalDialogOpen] = useState(false);
  const [changeRequestDialogOpen, setChangeRequestDialogOpen] = useState(false);

  const isNewlyCreated = foodComponent?.stage === ProcessingStage.draft;
  const isPendingFirstStageApproval = foodComponent?.stage === ProcessingStage.awaitingNPDApproval;
  const isPendingSecondStageApproval = foodComponent?.stage === ProcessingStage.awaitingOpsApproval;
  const canApproveStage1 = isPendingFirstStageApproval && userRoles.includes(Permission.APPROVE_STAGE_1_PROTOTYPE_FOOD_COMPONENT);
  const canApproveStage2 =
    isPendingSecondStageApproval && userRoles.includes(Permission.APPROVE_STAGE_2_PROTOTYPE_FOOD_COMPONENT);

  const shouldShowApprovalActionMenu = canApproveStage1 || canApproveStage2;
  const breadcrumbItems = generateBreadcrumbItems(location.pathname, foodComponent?.name?.en);

  const { mutateAsync: deleteMutation } = useMutation(deleteFoodComponent);

  const {
    mutate: createAction,
    mutateAsync: createActionAsync,
    isLoading: actionLoading
  } = useMutation(addPrototypeComponentAction, {
    onSuccess: () => {
      refetch?.();
    }
  });

  const { mutate: approveComponentStage1, isLoading: approveStage1Loading } = useMutation(approveStage1PrototypeComponent, {
    onSuccess: () => {
      toggleMealApprovalDialog();
      refetch?.();
    }
  });

  const { mutate: approveComponentStage2, isLoading: approveStage2Loading } = useMutation(approveStage2PrototypeComponent, {
    onSuccess: () => {
      toggleMealApprovalDialog();
      refetch?.();
    }
  });

  const handleDeleteComponent = async () => {
    if (operation === FormOperation.update && foodComponent) {
      await deleteMutation({ id: foodComponent.id });
      history.push(Routes.foodComponentList);
    }
  };

  const handleClosePopup = () => {
    deletionRef.current?.close();
  };

  const toggleMealApprovalDialog = () => {
    setMealApprovalDialogOpen((prev) => !prev);
  };

  const toggleChangeRequestDialog = () => {
    setChangeRequestDialogOpen((prev) => !prev);
  };

  const handleFoodComponentApprovalSubmit = () => {
    if (foodComponent && foodComponent.stage) {
      if (foodComponent.stage === ProcessingStage.awaitingNPDApproval) {
        approveComponentStage1(foodComponent.id);
      } else if (foodComponent.stage === ProcessingStage.awaitingOpsApproval) {
        approveComponentStage2(foodComponent.id);
      } else {
        const action = determineComponentOrIngredientAction(foodComponent.stage);

        createAction(
          {
            action,
            id: foodComponent.id
          },
          {
            onSuccess: () => {
              toggleMealApprovalDialog();
              refetch?.();
            }
          }
        );
      }
    }
  };

  const handleChangeRequestSubmit = async (comment: string) => {
    if (foodComponent && comment) {
      await createActionAsync(
        {
          action: PrototypeAction.requestChanges,
          message: comment,
          id: foodComponent.id
        },
        {
          onSuccess: () => {
            toggleChangeRequestDialog();
            refetch?.();
          }
        }
      );
    }
  };

  const renderPrototypeActions = () => (
    <>
      {isNewlyCreated && (
        <Button
          variant="outlined"
          disabled={!foodComponent || actionLoading || !userRoles.includes(Permission.CREATE_ACTION_PROTOTYPE_COMPONENT)}
          sx={styles.buttonOutlined}
          onClick={toggleMealApprovalDialog}
        >
          send for 1st approval
        </Button>
      )}
      {shouldShowApprovalActionMenu && (
        <ApprovalActionMenu
          type="component"
          buttonVariant={'outlined'}
          buttonSx={styles.buttonOutlined}
          item={foodComponent}
          actionButtonText={getPrototypeActionApprovalButtonText(foodComponent.stage)}
          handleApprove={toggleMealApprovalDialog}
          commentClickHandler={toggleChangeRequestDialog}
        />
      )}
    </>
  );

  const getPrototypeItems = () => {
    const ingredients =
      foodComponent?.ingredients
        ?.filter((fc) => fc.prototype)
        .map((ing) => ({ id: ing.id, name: ing.name.en, type: 'ingredient' })) || [];
    const childComponents =
      foodComponent?.childComponents
        ?.filter((fc) => fc.prototype)
        .map((cc) => ({ id: cc.id, name: cc.name.en, type: 'component' })) || [];
    return [...ingredients, ...childComponents];
  };

  return (
    <>
      <Stack sx={styles.headerStackStyles}>
        <RouteBreadCrumb items={breadcrumbItems} />
        {operation === FormOperation.update && (
          <Typography variant="h5" sx={styles.headerTextStyles}>
            {capitalize(foodComponent?.brand)} - {resolveCountry(foodComponent?.country)} -{' '}
            {formatKitchenText(foodComponent?.kitchen)}
          </Typography>
        )}
      </Stack>
      <Stack sx={styles.mainStackStyles}>
        {operation === FormOperation.update && (
          <ComponentNameAndUsage
            foodComponent={foodComponent as FoodComponent | undefined}
            filteredFoodUsed={filteredFoodUsed}
            isDisabled={!!isDisabled}
          />
        )}
        {operation === FormOperation.create && <Typography sx={styles.createHeaderStyles}>New Component</Typography>}
        <Stack sx={styles.actionButtonStackStyles}>
          {operation === FormOperation.update && userRoles.includes(Permission.DELETE_FOOD_COMPONENT) && (
            <IconButton
              disabled={isDisabled || isFoodLoading}
              sx={styles.deleteButtonStyles}
              onClick={() => deletionRef.current?.open()}
            >
              <DeleteIcon sx={{ color: 'red' }} />
            </IconButton>
          )}
          {operation === FormOperation.update && syncComponentsPopupRef && (
            <Button
              onClick={() => syncComponentsPopupRef?.current?.open()}
              disabled={isDisabled}
              variant="outlined"
              sx={styles.updateButtonStyles}
            >
              Update Components
            </Button>
          )}
          {operation === FormOperation.update && renderPrototypeActions()}
          <LoadingButton
            loading={isSubmitting}
            data-test="addComponentButton"
            variant="outlined"
            sx={styles.saveButtonStyles}
            disabled={isDisabled || !isValid || isSubmitting || isEdit}
            onClick={() => handleSubmit()}
          >
            {operation === FormOperation.create ? 'Add Component' : 'Save'}
          </LoadingButton>
        </Stack>
      </Stack>
      {operation === FormOperation.update && (
        <DeleteConfirmationPopup
          type="component"
          deletionRef={deletionRef}
          typeUsage={
            componentFoodList.map((meal) => ({
              name: meal.name.en,
              id: meal.id
            })) || []
          }
          isLoading={isFoodLoading}
          onClose={handleClosePopup}
          onDelete={handleDeleteComponent}
        />
      )}
      <MealApprovalDialog
        open={mealApprovalDialogOpen}
        handleClose={toggleMealApprovalDialog}
        handleSubmit={handleFoodComponentApprovalSubmit}
        isPositiveButtonLoading={actionLoading || approveStage1Loading || approveStage2Loading}
        prototypeItems={getPrototypeItems()}
        status={foodComponent?.stage}
        type="component"
      />
      <ChangeRequestDialog
        open={changeRequestDialogOpen}
        title="Add comments for changes"
        handleClose={toggleChangeRequestDialog}
        handleSubmit={handleChangeRequestSubmit}
        isPositiveButtonLoading={actionLoading}
      />
    </>
  );
};

export default ComponentHeaderCard;
