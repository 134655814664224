import { FoodPrototypeAction } from '@calo/dashboard-types';
import AddIcon from '@mui/icons-material/Add';
import { Button, Divider, Stack, Typography } from '@mui/material';
import { caloTheme } from 'assets/images/theme/calo';
import Pagination from 'components/Pagination';
import { orderBy } from 'lodash-es';
import { useState } from 'react';
import ActivityLogItem from './ActivityLogItem';

interface ActivityLogCardProps {
  type: 'meal' | 'component' | 'ingredient';
  actionLogs: FoodPrototypeAction[];
  addCommentClickHandler: () => void;
}

const ActivityLogCard = ({ type, actionLogs, addCommentClickHandler }: ActivityLogCardProps) => {
  const [page, setPage] = useState(0);
  const itemsPerPage = 5;

  const sortedLogs = orderBy(actionLogs, ['createdAt'], ['desc']);
  const paginatedLogs = sortedLogs.slice(page * itemsPerPage, (page + 1) * itemsPerPage);

  return (
    <Stack
      sx={{
        flexDirection: 'column',
        backgroundColor: caloTheme.palette.white,
        borderRadius: '8px',
        boxShadow: 2,
        marginTop: '16px'
      }}
    >
      <Stack sx={{ padding: '16px', flexDirection: 'row', justifyContent: 'space-between' }}>
        <Typography
          sx={{
            color: caloTheme.palette.neutral900,
            textTransform: 'capitalize',
            fontSize: '24px',
            fontWeight: 400,
            lineHeight: '150%'
          }}
        >
          Activity Log
        </Typography>
        <Button
          variant="text"
          startIcon={<AddIcon />}
          onClick={addCommentClickHandler}
          sx={{
            textTransform: 'none',
            fontSize: '16px',
            fontWeight: 600,
            color: caloTheme.palette.neutral900
          }}
        >
          Add Comment
        </Button>
      </Stack>
      <Divider sx={{ color: caloTheme.palette.divider, height: '1px', width: '100%' }} />
      <Stack sx={{ gap: '24px', padding: '24px 16px 16px 16px' }}>
        {paginatedLogs.map((action, index) => {
          // Calculate global index in the sortedLogs array
          const globalIndex = page * itemsPerPage + index;
          const previousActivityItem = sortedLogs[globalIndex + 1];
          const previousStage = previousActivityItem?.type;

          return (
            <ActivityLogItem
              key={`${action.type}-${action.actor}-${action.createdAt}`}
              action={action}
              type={type}
              previousStage={previousStage}
            />
          );
        })}
      </Stack>
      <Pagination
        isHidden={actionLogs.length <= itemsPerPage}
        limit={itemsPerPage}
        total={actionLogs.length}
        page={page}
        onChange={setPage}
      />
    </Stack>
  );
};

export default ActivityLogCard;
