import { useEffect, useRef, useState } from 'react';

import { createKDSUserPermission, updateKDSUserPermissions } from 'actions';
import { caloTheme } from 'assets/images/theme/calo';
import { useKDSPermissions } from 'hooks';
import { theme } from 'lib/componentStyles';
import { KDSUserGroup } from 'lib/interfaces';
import { useMutation } from 'react-query';

import { Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { KDSPermission } from 'lib/calo-dashboard-types';

import Header from './Header';
import TableBodyCell from './TableBodyCell';
import TableHeadCell from './TableHeadCell';

const KDSPermissionsPage = () => {
  const [selectedGroup, setSelectedGroup] = useState<KDSUserGroup | null>(null);
  const tableRef = useRef<HTMLTableElement>(null);

  const { mutateAsync: updateMutation } = useMutation(updateKDSUserPermissions);
  const { mutateAsync: createMutation } = useMutation(createKDSUserPermission);
  const kdsUserGroup = useKDSPermissions();

  const AddGroup = async () => {
    await createMutation({
      groupName: 'NewGroup',
      permissions: []
    });
  };

  const handleUpdateGroup = async () => {
    if (selectedGroup && !kdsUserGroup.includes(selectedGroup)) {
      await updateMutation(selectedGroup);
    }
    setSelectedGroup(null);
  };

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (selectedGroup && tableRef.current && !tableRef.current.contains(event.target) && !(event.target.id === 'scrollable')) {
        handleUpdateGroup();
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [tableRef, selectedGroup]);

  return (
    <Stack direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={2}>
      <Header AddGroup={AddGroup} />
      <TableContainer
        sx={{
          backgroundColor: theme.palette.common.white
        }}
      >
        <Table ref={tableRef}>
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  '&.MuiTableCell-head': {
                    backgroundColor: `${caloTheme.palette.neutral100} !important`,
                    color: theme.palette.common.black,
                    border: 'none',
                    fontSize: 14,
                    minWidth: 200,
                    borderTopLeftRadius: 8,
                    position: 'sticky',
                    left: 0,
                    zIndex: theme.zIndex.modal + 1
                  }
                }}
              ></TableCell>
              {kdsUserGroup.length > 0 &&
                kdsUserGroup.map((userGroup, index) => (
                  <TableHeadCell
                    key={index}
                    userGroup={userGroup}
                    setSelectedGroup={setSelectedGroup as any}
                    selectedGroup={selectedGroup}
                    isLast={index === kdsUserGroup.length - 1}
                  />
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(KDSPermission).map((permission, index) => (
              <TableBodyCell
                key={index}
                setSelectedGroup={setSelectedGroup as any}
                selectedGroup={selectedGroup}
                kdsUserGroup={kdsUserGroup}
                permission={permission}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
};

export default KDSPermissionsPage;
