import { caloThemeV2 } from 'assets/themev2';
export const styles = {
  card: {
    width: 'full',
    border: 'none',
    borderRadius: '8px',
    boxShadow: 2,
    [caloThemeV2.breakpoints.down(caloThemeV2.breakpoints.values.lg)]: {
      flexDirection: 'column'
    },
    mt: '24px'
  },
  headerContainer: {
    padding: '16px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  title: { fontSize: '24px', fontWeight: 400, lineHeight: '150%' },
  divider: { color: caloThemeV2.palette.divider, height: '1px', width: '100%' },
  inputContainer: {
    margin: '24px 16px',
    gap: '24px'
  },
  tableContainer: {
    width: '100%',
    boxShadow: 'none'
  },
  tableHead: {
    backgroundColor: caloThemeV2.palette.grey[100],
    border: 0
  },
  tableHeadText: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '24px'
  },
  tableCell: {
    width: '25%'
  },
  tableCellText: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '150%'
  },
  dialogContainer: {
    '& .MuiDialog-paper': {
      width: '30.4%',
      maxWidth: '640px'
    }
  },
  dialogTitle: {
    fontSize: '20px',
    fontWeight: 500,
    lineHeight: '150%'
  },
  dialogDescription: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '150%'
  },
  dialogContentContainer: {
    borderTop: `1px solid ${caloThemeV2.palette.divider}`,
    borderBottom: `1px solid ${caloThemeV2.palette.divider}`
  },
  cancelButton: {
    color: 'black'
  }
};
