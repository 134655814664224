import { ProcessingStage } from '@calo/dashboard-types';
import { FoodStatus } from 'lib/enums';

export const getPrototypeActionApprovalButtonText = (status?: ProcessingStage | FoodStatus) => {
  if (status === FoodStatus.awaitingNPDApproval) {
    return 'Approve & send';
  } else if (status === FoodStatus.awaitingOpsApproval) {
    return 'Approve & release';
  }
  return 'Approve';
};
