import { caloThemeV2 } from 'assets/themev2';
export const styles = {
  card: {
    width: 'full',
    border: 'none',
    borderRadius: '8px',
    boxShadow: 2,
    [caloThemeV2.breakpoints.down(caloThemeV2.breakpoints.values.lg)]: {
      flexDirection: 'column'
    },
    mt: '24px'
  }
};
