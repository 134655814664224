import { Button } from '@mui/material-v6';
import { caloThemeV2 } from 'assets/themev2';
import { ModalRef } from 'components/Modal';
import Popup from 'components/Popup';
import { addDays, format } from 'date-fns';
import { MenuPresentationType, Routes } from 'lib/enums';
import { useRef, useState } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { CountryKitchenForm } from 'views/Menu/MainMenu/components';
import { CreateParams } from 'views/Menu/MainMenu/types';
import DateForm from './DateForm';

interface CreateDailyMenuActionsProps {
  disabled?: boolean;
}

const defaultParams = { country: '', kitchen: '', day: format(addDays(new Date(), 1), 'yyyy-MM-dd') };

const CreateDailyMenuActions = ({ disabled }: CreateDailyMenuActionsProps) => {
  const countryKitchenFormPopupRef = useRef<ModalRef>();
  const history = useHistory();

  const [params, setParams] = useState<CreateParams>(defaultParams);
  const [isNext, setIsNext] = useState(false);

  const closeHandler = () => {
    setParams(defaultParams);
    setIsNext(false);
    countryKitchenFormPopupRef.current?.close();
  };

  const navigateToCreatePage = () => {
    const path = generatePath(Routes.newMenu, {
      kitchen: params.kitchen,
      country: params.country,
      type: MenuPresentationType.daily,
      day: params.day
    });
    history.push(path);
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        size="large"
        sx={{ color: caloThemeV2.palette.primary.contrastText }}
        disabled={disabled}
        onClick={() => {
          countryKitchenFormPopupRef.current?.open();
        }}
      >
        Create Menu
      </Button>
      <Popup maxWidth="sm" closeIcon onClose={closeHandler} ref={countryKitchenFormPopupRef}>
        {!isNext && (
          <CountryKitchenForm params={params} setIsNext={setIsNext} setParams={setParams} closeHandler={closeHandler} />
        )}
        {isNext && (
          <DateForm closeHandler={closeHandler} params={params} setParams={setParams} continueHandler={navigateToCreatePage} />
        )}
      </Popup>
    </>
  );
};

export default CreateDailyMenuActions;
