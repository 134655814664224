import { RefObject, useEffect, useRef, useState } from 'react';

import { FoodStatus, Permission, UpdateFoodReq } from '@calo/dashboard-types';
import { Stack } from '@mui/material';

import { caloTheme } from 'assets/images/theme/calo';
import { ConfirmationModal, ModalRef } from 'components';
import { MealTabs } from 'lib/enums';
import { useUserRoles } from 'lib/hooks';
import { Food, PrototypeFood } from 'lib/interfaces';
import Header from '../../Header';
import TabsComponent from './TabsComponent';

interface StickyHeaderProps {
  isSubmitting: boolean;
  dirty: boolean;
  isDisabled: boolean;
  handleSubmit: () => void;
  values: UpdateFoodReq;
  sectionRefs: RefObject<HTMLDivElement>[];
  isValid: boolean;
  selectedFood: PrototypeFood;
  foodWithAllSizes: Food[];
  setSelectedFood: React.Dispatch<React.SetStateAction<PrototypeFood | undefined>>;
  setFoodWithAllSizes: (food: Food[]) => void;
  filteredFoodUsed: {
    type: 'mainMenu' | 'addonsMenu';
    date?: string;
    week?: number;
  }[];
  refetch: any;
  usedOnMenuButtonColor: string[];
}

const StickyHeader = ({
  isSubmitting,
  dirty,
  refetch,
  setFoodWithAllSizes,
  usedOnMenuButtonColor,
  isDisabled,
  handleSubmit,
  filteredFoodUsed,
  foodWithAllSizes,
  values,
  sectionRefs,
  isValid,
  selectedFood,
  setSelectedFood
}: StickyHeaderProps) => {
  const roles = useUserRoles();

  const [tab, setTab] = useState(0);
  const isTabClickRef = useRef(false);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
    isTabClickRef.current = true;
    sectionRefs[newValue].current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (isTabClickRef.current) {
          isTabClickRef.current = false;
          return;
        }
        const visibleSection = entries.reduce(
          (max, entry) => (entry.intersectionRatio > max.intersectionRatio ? entry : max),
          entries[0]
        );
        const index = sectionRefs.findIndex((ref) => ref.current === visibleSection.target);
        if (index !== -1) {
          setTab(index);
        }
      },
      {
        rootMargin: '-50% 0px -50% 0px'
      }
    );
    for (const ref of sectionRefs) {
      if (ref.current) {
        observer.observe(ref.current);
      }
    }

    return () => {
      observer.disconnect();
    };
  }, [sectionRefs]);

  const confirmModalRef = useRef<ModalRef>();
  const handleConfirmation = () => {
    if (filteredFoodUsed?.length > 0) {
      confirmModalRef.current?.open();
    } else {
      handleSubmit();
    }
  };

  const isSaveDisabled = () => {
    const conditions = !dirty || !isValid || isSubmitting || isDisabled;
    if (selectedFood.status === FoodStatus.draft) {
      return conditions || !roles.includes(Permission.BATCH_UPDATE_PROTOTYPE_FOOD);
    }
    return (
      conditions || !(roles.includes(Permission.BATCH_UPDATE_PROTOTYPE_FOOD) && roles.includes(Permission.APPROVE_PROTOTYPE_FOOD))
    );
  };

  return (
    <Stack
      direction="column"
      justifyContent="space-between"
      spacing={2}
      sx={{
        backgroundColor: caloTheme.palette.white,
        p: 2
      }}
    >
      <Header
        refetch={refetch}
        selectedFood={selectedFood}
        foodWithAllSizes={foodWithAllSizes}
        setSelectedFood={setSelectedFood}
        setFoodWithAllSizes={setFoodWithAllSizes}
        filteredFoodUsed={filteredFoodUsed}
        usedOnMenuButtonColor={usedOnMenuButtonColor}
        isDisabled={isDisabled}
        isSubmitting={isSubmitting}
        isSaveDisabled={isSaveDisabled}
        handleConfirmation={handleConfirmation}
      />
      <Stack direction="row" justifyContent="space-between" spacing={2}>
        <TabsComponent
          tab={tab}
          handleTabChange={handleTabChange}
          tabLabels={Object.values(MealTabs).map((mealTab) => mealTab)}
        />

        <ConfirmationModal ref={confirmModalRef} values={values} action={handleSubmit}>
          <div className="mt-4 mb-4 ml-4">
            <span className="flex">
              <label className="label">The change will take effect on the next new generated menu </label>
            </span>
          </div>
        </ConfirmationModal>
      </Stack>
    </Stack>
  );
};

export default StickyHeader;
