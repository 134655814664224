import { useEffect, useRef, useState } from 'react';

import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router';

import { Permission, ProcessingStage, PrototypeAction } from '@calo/dashboard-types';
import { Button, IconButton } from '@mui/material';
import {
  addPrototypeIngredientAction,
  approvePrototypeIngredient,
  deletePrototypeIngredient,
  getListWithParams,
  getRecord,
  updatePrototypeIngredient
} from 'actions';
import { caloTheme } from 'assets/images/theme/calo';
import { DeleteConfirmationPopup } from 'components';
import Disclaimer from 'components/Disclaimer';
import { AllergenCategoryCard, CostCard, IngredientHeaderCard, MeasurementGuideAndMacrosCard } from 'components/IngredientShared';
import { ModalRef } from 'components/Modal';
import { FormOperation, Routes } from 'lib/enums';
import { calculateCalories } from 'lib/helpers';
import { getUsedOnMenuButtonColor } from 'lib/helpers/usedOnMenu';
import { useUserRoles } from 'lib/hooks';
import { FoodComponentQuery, IngredientFormData, IngredientType } from 'lib/interfaces';
import { omit } from 'lodash';
import { useHistory } from 'react-router-dom';
import ActivityLogCard from 'views/ChefPlayground/Shared/ActivityLogCard';
import IngredientCommentsPopup from 'views/ChefPlayground/Shared/IngredientCommentsPopup';
import IngredientInformationCard from './IngredientInformationCard';
import useIngredientForm from './useIngredientForm';
import DeleteIcon from '@mui/icons-material/Delete';
import { useLocation } from 'react-router-dom';

const styles = {
  deleteIcon: (isDisabled?: boolean) => ({
    fontSize: '18px',
    color: isDisabled ? caloTheme.palette.neutral500 : caloTheme.palette.red
  }),
  deleteButton: {
    padding: '10px',
    textTransform: 'none',
    fontSize: '6px',
    color: caloTheme.palette.errorMain,
    border: `1px solid ${caloTheme.palette.errorMain}`,
    borderRadius: '4px'
  },
  actionButton: {
    padding: '8px 22px',
    fontSize: '15px',
    fontWeight: 500,
    lineHeight: '125%',
    textTransform: 'uppercase',
    borderRadius: '4px',
    boxShadow: 'none',
    color: caloTheme.palette.textPrimary,
    borderColor: caloTheme.palette.textPrimary,
    backgroundColor: 'transparent',
    '&:hover': {
      borderColor: caloTheme.palette.textPrimary,
      backgroundColor: '#00000008'
    }
  }
};

const ExactIngredient = () => {
  const isDisabled = false;
  const roles = useUserRoles();
  const history = useHistory();
  const deletionRef = useRef<ModalRef>(null);

  const location = useLocation();
  const isChefPlayground = location.pathname.includes('chef-playground');

  const { id } = useParams<{ id: string }>();
  const commentsPopupRef = useRef<ModalRef>();

  const [usedOnMenuButtonColor, setUsedOnMenuButtonColor] = useState<string[]>([]);

  const { data: components, isLoading: loadingComponents } = useQuery<any, Error, FoodComponentQuery>(
    [
      'food-components/prototype',
      {
        page: 0,
        filters: {
          ingredientId: id
        },
        limit: 50
      }
    ],
    getListWithParams,
    {
      suspense: false,
      enabled: roles.includes(Permission.DELETE_INGREDIENT)
    }
  );

  const { mutateAsync: updateMutation } = useMutation(updatePrototypeIngredient);

  const { data, refetch } = useQuery(['ingredients/prototype', id], getRecord, {
    suspense: true
  });
  const ingredient = data as IngredientType;

  const handleUpdateIngredient = async (values: IngredientFormData) => {
    await updateMutation({ id, ...omit(values, ['order', 'prototype']) });
    ingredient.name.en = values.name.en;
  };

  const { values, errors, isValid, isSubmitting, dirty, handleSubmit, handleChange, handleBlur, setFieldValue } =
    useIngredientForm(ingredient, handleUpdateIngredient);

  useEffect(() => {
    setFieldValue('macros.cal', calculateCalories(values.macros));
  }, [values.macros.carbs, values.macros.fat, values.macros.protein]);

  useEffect(() => {
    const calValue = calculateCalories(values.macros);
    if (values.macros.cal !== calValue) {
      setFieldValue('macros.cal', calValue);
    }
  }, []);

  useEffect(() => {
    if (ingredient?.usedOnMenu) {
      for (const data of ingredient.usedOnMenu) {
        setUsedOnMenuButtonColor((old) => [...old, getUsedOnMenuButtonColor(data)]);
      }
    }
  }, [ingredient]);

  const isApproved = ingredient?.stage === ProcessingStage.approved;
  const isWaitingApproval = ingredient?.stage === ProcessingStage.pending;

  const { mutate: createAction, isLoading: actionLoading } = useMutation(addPrototypeIngredientAction, {
    onSuccess: () => {
      refetch();
    }
  });

  const { mutate: approveIngredient, isLoading: approveLoading } = useMutation(approvePrototypeIngredient, {
    onSuccess: () => {
      refetch();
    }
  });

  const { mutate: deleteIngredient, isLoading: deleteLoading } = useMutation(deletePrototypeIngredient, {
    onSuccess: () => {
      history.push(Routes.playgroundIngredientList);
    }
  });

  const addCommentSuccessHandler = (data: IngredientType) => {
    setFieldValue('prototypeActions', data.prototypeActions);
    refetch();
  };

  const handleCloseDeletePopup = () => {
    deletionRef.current?.close();
  };

  return (
    <>
      <IngredientHeaderCard
        operation={FormOperation.update}
        ingredient={ingredient}
        disabled={!roles.includes(Permission.UPDATE_PROTOTYPE_INGREDIENT) || !dirty || !isValid || isSubmitting}
        handleSubmit={handleSubmit}
        usedOnMenuButtonColor={usedOnMenuButtonColor}
      >
        <IconButton
          disabled={
            !ingredient ||
            actionLoading ||
            deleteLoading ||
            loadingComponents ||
            !roles.includes(Permission.DELETE_PROTOTYPE_INGREDIENT)
          }
          sx={styles.deleteButton}
          onClick={() => deletionRef.current?.open()}
        >
          <DeleteIcon sx={styles.deleteIcon(isDisabled)} />
        </IconButton>
        {!isChefPlayground && (
          <>
            {!isApproved && !isWaitingApproval && (
              <Button
                variant="outlined"
                disabled={
                  !ingredient || actionLoading || isApproved || !roles.includes(Permission.CREATE_ACTION_PROTOTYPE_INGREDIENT)
                }
                sx={styles.actionButton}
                onClick={() => createAction({ action: PrototypeAction.pend, id: ingredient.id })}
              >
                Send for Approval
              </Button>
            )}
            {isWaitingApproval && !isApproved && (
              <Button
                variant="outlined"
                disabled={
                  !ingredient ||
                  approveLoading ||
                  actionLoading ||
                  isApproved ||
                  !roles.includes(Permission.APPROVE_PROTOTYPE_COMPONENT)
                }
                sx={styles.actionButton}
                onClick={() => approveIngredient(ingredient.id)}
              >
                Approve
              </Button>
            )}
          </>
        )}
      </IngredientHeaderCard>
      <Disclaimer type="ingredient" sx={{ marginTop: '20px', marginBottom: 0 }} />
      <IngredientInformationCard
        id={ingredient.id}
        values={values}
        errors={errors}
        showImageUploader={true}
        handleChange={handleChange}
        handleBlur={handleBlur}
        setFieldValue={setFieldValue}
        isDisabled={isDisabled}
      />
      <AllergenCategoryCard values={values} setFieldValue={setFieldValue} isDisabled={isDisabled} />
      <MeasurementGuideAndMacrosCard
        values={values}
        errors={errors}
        handleBlur={handleBlur}
        handleChange={handleChange}
        setFieldValue={setFieldValue}
        disabled={isDisabled}
      />
      <CostCard
        operation={FormOperation.update}
        values={values}
        errors={errors}
        ingredient={ingredient}
        handleChange={handleChange}
      />
      <ActivityLogCard
        actionLogs={ingredient?.prototypeActions ?? []}
        type="ingredient"
        addCommentClickHandler={() => commentsPopupRef.current?.open()}
      />
      <IngredientCommentsPopup
        selectedIngredient={ingredient}
        ref={commentsPopupRef}
        closeCommentsPopup={() => commentsPopupRef.current?.close()}
        successHandler={addCommentSuccessHandler}
      />
      <DeleteConfirmationPopup
        type="ingredient"
        deletionRef={deletionRef}
        typeUsage={
          components?.data?.map((component) => ({
            name: component.name.en,
            id: component.id
          })) || []
        }
        isLoading={loadingComponents}
        onClose={handleCloseDeletePopup}
        onDelete={() => deleteIngredient(id)}
      />
    </>
  );
};

export default ExactIngredient;
