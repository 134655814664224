import { useEffect, useRef, useState } from 'react';

import { format } from 'date-fns/fp';
import { capitalize, compact, groupBy, keyBy, map, startCase } from 'lodash-es';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { v4 as uuid } from 'uuid';

import { Brand, Country, Kitchen } from '@calo/types';
import DateFnsAdapter from '@date-io/date-fns';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import ClearIcon from '@mui/icons-material/Clear';
import { Box, Button, IconButton, MenuItem, Stack, TextField, Typography } from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';

import { createComplaints, getListWithParams, quickSearch } from 'actions';
import { caloTheme } from 'assets/images/theme/calo';
import { AttachFile, Autocomplete, ModalRef } from 'components';
import Popup from 'components/Popup';
import { customStylesComplaints } from 'lib/componentStyles';
import { ComplaintReason, DeliveryTime } from 'lib/enums';
import { handleUploadImage } from 'lib/helpers';
import { complaintsReasonsHelper } from 'lib/helpers/complaintsReasons';
import { CreateComplaintReq, PaginatedDeliveries } from 'lib/interfaces';

export interface UserComplaints {
  id: string;
  name: string;
  email: string;
  phoneNumber: string;
  label: string;
  brand: Brand;
  kitchen: Kitchen;
  country: Country;
  driver?: {
    name: string;
    id: string;
  };
  deliveryTime: DeliveryTime;
  deliveryId: string;
}

interface CreateComplaintProps {
  refetchComplaintList: any;
  user: UserComplaints | undefined;
  setUser: (value: UserComplaints | undefined) => void;
}

const CreateComplaint = ({ refetchComplaintList, user, setUser }: CreateComplaintProps) => {
  const [loadingImage, setLoadingImage] = useState(false);

  const [availableMeals, setAvailableMeals] = useState<any[]>([]);
  const [uuIDCreate, setUuIDCreate] = useState<string>('');
  const [comment, setComment] = useState<string>('');
  const [complaint, setComplaint] = useState<string>('');
  const [selectedMeal, setSelectedMeal] = useState<string>('');
  const [displayImage, setDisplayImage] = useState<string[]>([]);
  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  const [complaintType, setComplaintType] = useState<string | null>('');

  const attachmentMethodImageRef = useRef<ModalRef>();

  const subComplaintOptions = complaintsReasonsHelper.getSelectableOptions();
  const complaintOptions = Object.entries(ComplaintReason)
    .filter(([_, value]) => value in subComplaintOptions)
    .map(([key, value]) => ({
      value: key,
      label: startCase(value)
    }));

  const { mutateAsync: createMutation } = useMutation(createComplaints);

  useEffect(() => {
    if (user) {
      setUuIDCreate(uuid());
    }
  }, [user]);

  useQuery<any, Error, PaginatedDeliveries>(
    [
      'deliveries',
      {
        page: 0,
        filters: {
          driverId: '',
          day: {
            lte: selectedDate,
            gte: selectedDate
          },
          status: [],
          skipped: false,
          brand: user?.brand,
          country: user?.country,
          phoneNumber: user?.phoneNumber
        }
      }
    ],
    getListWithParams,
    {
      keepPreviousData: false,
      enabled: !!user && !!selectedDate,
      onSuccess: (data) => {
        const index = data.data.findIndex((delivery) => delivery.day === selectedDate);
        if (index >= 0) {
          setUser({
            ...user!,
            kitchen: data.data[index]!.kitchen || Kitchen.BH1,
            country: data.data[index]!.country,
            driver: data.data[index]!.driver,
            deliveryTime: data.data[index]!.time ?? DeliveryTime.morning,
            deliveryId: data.data[index]!.id
          });

          setAvailableMeals([
            ...(data.data[index].food || []),
            ...(data.data[index].addons || []),
            ...(data.data[index].giftedItems?.meal || [])
          ]);
        } else {
          toast(`No delivery for the selected customer on ${selectedDate}`, { type: 'error', autoClose: 2000 });
        }
      }
    }
  );

  const handleClear = () => {
    setUser(undefined);
    setSelectedDate(null);
    setComplaintType(null);
    setComplaint('');
    setComment('');
    setDisplayImage([]);
    setAvailableMeals([]);
    setSelectedMeal('');
  };

  useEffect(() => {
    if (!user) {
      handleClear();
    }
  }, [user]);

  const handleCreateComplaints = async () => {
    const keyedMeals = keyBy(availableMeals, 'id');
    const createValues: CreateComplaintReq = {
      id: uuIDCreate,
      deliveryId: user!.deliveryId,
      kitchen: user!.kitchen,
      category: complaintType!,
      type: complaint,
      userId: user!.id,
      date: selectedDate!,
      comment: comment.length > 0 ? comment : undefined,
      attachment: !!displayImage,
      attachments: compact(displayImage.map((link) => link.split('/')[2])),
      meal:
        selectedMeal && keyedMeals[selectedMeal]
          ? {
              id: keyedMeals[selectedMeal].id,
              name: keyedMeals[selectedMeal].name?.en || '',
              size: keyedMeals[selectedMeal].size || '',
              slug: keyedMeals[selectedMeal].slug || ''
            }
          : undefined,
      deliveryTime: user!.deliveryTime,
      driver: user!.driver
    };
    const complaintsReq = await createMutation(createValues);
    if (complaintsReq) {
      handleClear();
    }
    refetchComplaintList();
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    handleUploadImage({
      disabled: !user,
      files,
      setDisplayImage,
      setLoadingImage,
      urlFile: 'complaints',
      uuIDCreate
    });
  };

  return (
    <>
      <Box
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'space-between'}
        sx={{
          padding: 2,
          [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
            flexDirection: 'column'
          }
        }}
        width="100%"
      >
        <Typography
          variant="h6"
          sx={{
            fontWeight: 600,
            fontSize: '19px',
            lineHeight: '23px',
            color: caloTheme.palette.neutral900,
            fontFamily: caloTheme.typography.fontFamily
          }}
        >
          Create Complaint
        </Typography>
      </Box>

      <Box
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'space-between'}
        sx={{
          padding: 2,
          [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
            flexDirection: 'column'
          }
        }}
        width="100%"
      >
        <Stack width={'90%'} sx={{ height: '49px', display: 'flex' }}>
          <Autocomplete
            onSearch={(text) =>
              quickSearch({
                limit: 10,
                query: text,
                page: 1
              })
            }
            transformer={(data: any[]) =>
              map(groupBy(data, 'type'), (group, key) => ({
                label: key,
                options: group.map(
                  (row: any) =>
                    row.type === 'subscription' && {
                      value: row.id,
                      type: row.type,
                      data: row,
                      phoneNumber: row.phoneNumber.replace('+', ''),
                      name: row.name,
                      label: `${row.name}, ${row.phoneNumber}, ${row.email}, ${row.brand ? row.brand : Brand.CALO}, ${row.country || Country.BH}, ${row.kitchen || Kitchen.BH1}`
                    }
                )
              }))
            }
            customStyles={customStylesComplaints}
            placeHolder={true}
            placeHolderValue={user ? user.label : 'Search'}
            onPick={(data: any) =>
              data === undefined
                ? handleClear()
                : setUser({
                    ...user,
                    name: data.name,
                    id: data.value,
                    email: data.data.email,
                    phoneNumber: data.phoneNumber,
                    country: data.country,
                    brand: data.brand || Brand.CALO,
                    label: data.label,
                    kitchen: data.data.kitchen || Kitchen.BH1,
                    deliveryTime: data.data.deliveryTime,
                    driver: data.data.driver
                  } as UserComplaints)
            }
          />
        </Stack>

        <Stack sx={{ mx: 2, width: 'full', maxHeight: '48px' }}>
          <LocalizationProvider dateAdapter={DateFnsAdapter}>
            <DesktopDatePicker
              inputFormat="dd/MM/yyyy"
              label="Select Date"
              value={selectedDate || null}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputProps={{
                    style: {
                      height: '51px'
                    },
                    endAdornment: (
                      <IconButton size="small" onClick={() => selectedDate && setSelectedDate(null)}>
                        {selectedDate ? <ClearIcon /> : params.InputProps?.endAdornment}
                      </IconButton>
                    )
                  }}
                />
              )}
              onChange={(endDate: any) => setSelectedDate(endDate === null ? null : format('yyyy-MM-dd')(endDate as Date))}
              InputProps={{ style: { height: '51px', borderRadius: '8px' } }}
            />
          </LocalizationProvider>
        </Stack>
      </Box>
      {user && user.id && (
        <Box width={'96%'} style={{ paddingBottom: '12px' }}>
          <Stack
            sx={{
              justifyContent: 'space-between',
              display: 'flex',
              flexDirection: 'row',
              px: 2,
              paddingTop: '12px',
              width: '100%',
              marginLeft: 2,
              backgroundColor: caloTheme.palette.primary50,
              height: '44px'
            }}
          >
            <Typography
              variant="h2"
              sx={{
                fontFamily: 'Roboto',
                fontSize: '16px',
                fontWeight: 400,
                lineHeight: '20px',
                textAlign: 'left',
                color: caloTheme.palette.neutral900
              }}
            >
              {user.label}
            </Typography>
            <IconButton
              onClick={() => setUser(undefined)}
              style={{
                width: '23px',
                height: '23px',
                textAlign: 'center',
                color: 'white',
                backgroundColor: caloTheme.palette.red
              }}
            >
              <ClearIcon sx={{ fontSize: '18px', mx: 'auto' }} />
            </IconButton>
          </Stack>
        </Box>
      )}
      <Box
        sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', px: 2, mb: 1, zIndex: 0 }}
      >
        <Stack width={'34%'} sx={{ mr: 2 }}>
          <TextField
            select
            name="ComplaintType"
            sx={{ width: '100%', zIndex: 0 }}
            label="Complaint Type"
            value={complaintType}
            disabled={!user}
            onChange={(data: any) => {
              setComplaintType(data.target.value);
            }}
            InputProps={{ inputProps: { style: { borderRadius: 8 } }, style: { borderRadius: 8 } }}
          >
            {complaintOptions.map((category) => (
              <MenuItem key={category.value} value={category.value}>
                {startCase(category.label)}
              </MenuItem>
            ))}
          </TextField>
        </Stack>
        <Stack width={'33%'} sx={{ mr: 2 }}>
          <TextField
            select
            name="Complaint"
            sx={{ width: '100%', zIndex: 0 }}
            label="Complaint"
            value={complaint}
            onChange={(data: any) => {
              setComplaint(data.target.value);
            }}
            disabled={!complaintType || complaintType.length === 0}
            InputProps={{ inputProps: { style: { borderRadius: 8 } }, style: { borderRadius: 8 } }}
          >
            {/* @ts-ignore */}
            {((complaintType && subComplaintOptions[ComplaintReason[complaintType]]) || []).map((complaint) => (
              <MenuItem key={complaint.value} value={complaint.value}>
                {capitalize(complaint.label)}
              </MenuItem>
            ))}
          </TextField>
        </Stack>
        <Stack width={'33%'} sx={{ mr: 2 }}>
          <TextField
            select
            name="Meal"
            sx={{ width: '100%', zIndex: 0 }}
            label="Meal"
            value={selectedMeal}
            onChange={(data: any) => {
              setSelectedMeal(data.target.value);
            }}
            disabled={!user && availableMeals.length === 0}
            InputProps={{ inputProps: { style: { borderRadius: 8 } }, style: { borderRadius: 8 } }}
          >
            {/* @ts-ignore */}
            {((availableMeals && availableMeals) || []).map((meal) => (
              <MenuItem key={meal.id} value={meal.id}>
                {startCase(meal.name?.en || '')}
              </MenuItem>
            ))}
          </TextField>
        </Stack>
      </Box>
      <Box
        sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', paddingX: 2, marginY: 2 }}
      >
        <Stack width={'65%'}>
          <TextField
            label="Comment"
            sx={{ zIndex: 0 }}
            name="comment"
            value={comment}
            placeholder="Add Comment"
            onChange={(data: any) => setComment(data.target.value)}
            inputProps={{ inputProps: { style: { borderRadius: 8, zIndex: 0 } }, style: { borderRadius: 8, zIndex: 0 } }}
          />
        </Stack>
        <Button
          variant="outlined"
          sx={{
            display: 'flex',
            height: '51px',
            fontWeight: 600,
            lineHeight: '23px',
            fontSize: '14px',
            borderRadius: '8px',
            fontFamily: 'Roboto',
            textTransform: 'none',
            boxShadow: 'none',
            color: caloTheme.palette.primary500,
            borderColor: caloTheme.palette.primary500,
            '&:hover': {
              boxShadow: 'none',
              color: caloTheme.palette.primary600,
              borderColor: caloTheme.palette.primary600
            },
            [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
              display: 'flex',
              justifyItems: 'center',
              m: 'auto',
              mb: 2,
              mt: '-4px',
              width: 'auto'
            }
          }}
          disabled={!user || loadingImage}
          startIcon={
            loadingImage ? 'loading' : displayImage.length > 0 ? <></> : <AttachFileIcon style={{ transform: 'rotate(45deg)' }} />
          }
          onClick={() => displayImage.length > 0 && attachmentMethodImageRef.current?.open()}
        >
          {displayImage.length === 0 ? (
            <>
              <input
                accept="image/*"
                type="file"
                multiple
                size={2}
                className="file-input fas fa-paperclip mt-2 cursor-pointer float-right "
                key={uuid()}
                onChange={handleFileChange}
              />
              {loadingImage ? '' : 'Attach File'}
            </>
          ) : (
            <>{loadingImage ? '' : 'View Attached File'}</>
          )}
        </Button>

        <Button
          variant="contained"
          sx={{
            mr: 2,
            display: 'flex',
            height: '51px',
            fontWeight: 600,
            lineHeight: '23px',
            fontSize: '14px',
            borderRadius: '8px',
            fontFamily: 'Roboto',
            textTransform: 'none',
            boxShadow: 'none',
            color: caloTheme.palette.white,
            backgroundColor: caloTheme.palette.primary500,
            '&:hover': {
              boxShadow: 'none',
              color: caloTheme.palette.white,
              backgroundColor: caloTheme.palette.primary600
            },
            [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
              display: 'flex',
              justifyItems: 'center',
              m: 'auto',
              mb: 2,
              mt: '-4px',
              width: 'auto'
            }
          }}
          onClick={() => handleCreateComplaints()}
          disabled={
            !user || !complaintType || complaintType.length === 0 || complaint.length === 0 || !selectedDate || loadingImage
          }
        >
          Create Complaint
        </Button>
      </Box>

      <Popup
        maxWidth="lg"
        fullWidth
        title="Attached File"
        ref={attachmentMethodImageRef}
        onClose={() => {
          attachmentMethodImageRef.current?.close();
        }}
        info={
          <Button disabled={loadingImage}>
            <input
              accept="image/*"
              type="file"
              multiple
              size={2}
              className="file-input fas fa-paperclip mt-2 cursor-pointer float-right "
              key={uuid()}
              onChange={handleFileChange}
            />
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: '16px',
                mt: 1,
                cursor: 'pointer',
                lineHeight: '19px',
                color: caloTheme.palette.neutral900
              }}
            >
              {loadingImage ? 'Loading...' : '+ Add File'}
            </Typography>
          </Button>
        }
      >
        <AttachFile
          viewType="create"
          key={uuIDCreate}
          urlFile={'complaints'}
          uuIDCreate={uuIDCreate}
          displayImage={displayImage}
          setDisplayImage={setDisplayImage}
        />
      </Popup>
    </>
  );
};
export default CreateComplaint;
