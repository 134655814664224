import { FormikErrors, FormikTouched } from 'formik';
import { camelCase } from 'lodash';
import { useState } from 'react';

import { CreateIngredientReq } from '@calo/dashboard-types';
import { Kitchen } from '@calo/types';
import { Box, Card, Divider, FormHelperText, MenuItem, Stack, TextField, Typography } from '@mui/material';
import { caloTheme } from 'assets/images/theme/calo';
import InputThemeProvider from 'components/MUI/InputThemeProvider';
import { getAccessibleCountries, getKitchenOptions, hasValidCharactersForLanguage } from 'lib/helpers';
import { useUserKitchens } from 'lib/hooks';
import { IngredientFormData } from 'lib/interfaces';

interface IngredientInformationProps {
  disabled?: boolean;
  values: IngredientFormData;
  errors: FormikErrors<IngredientFormData>;
  touched: FormikTouched<IngredientFormData>;
  handleChange: { (e: React.ChangeEvent<any>): void };
  handleBlur: { (e: React.FocusEvent<any, Element>): void };
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<void> | Promise<FormikErrors<CreateIngredientReq>>;
}

const IngredientInformation = ({
  values,
  errors,
  touched,
  disabled,
  handleChange,
  handleBlur,
  setFieldValue
}: IngredientInformationProps) => {
  const [langAlert, setLangAlert] = useState({ AR: false, EN: false, value: '' });

  const userKitchens: Kitchen[] = useUserKitchens();

  const handleNameFieldChange = (field: string, data: string, lang: 'AR' | 'EN') => {
    const allowSpecialChar = field.includes('description');
    setFieldValue(field, data);

    const hasValidCharacters = hasValidCharactersForLanguage(data, lang, allowSpecialChar) || data === '';
    setLangAlert({ ...langAlert, [`${lang}`]: !hasValidCharacters, value: field });

    if (lang === 'EN') {
      setFieldValue('key', camelCase(data));
    }
  };

  return (
    <InputThemeProvider>
      <Card
        variant="outlined"
        sx={{
          marginTop: '16px',
          border: 'none',
          borderRadius: '8px',
          boxShadow: 2
        }}
      >
        <Typography variant="h5" sx={{ fontSize: '24px', fontWeight: 400, lineHeight: '150%', padding: '16px' }}>
          Ingredient Information
        </Typography>
        <Divider sx={{ color: caloTheme.palette.divider, height: '1px', width: '100%' }} />
        <Box sx={{ padding: '24px 16px 16px 16px', display: 'flex', flexDirection: 'column', gap: '20px' }}>
          <TextField label="Key" name="key" id="key" placeholder="Key" value={values.key} sx={{ width: '100%' }} disabled />
          <Stack sx={{ display: 'flex', flexDirection: 'row', gap: '13px', width: '100%' }}>
            <Stack sx={{ width: '50%' }}>
              <TextField
                label="Name EN*"
                name="name.en"
                id="name.en"
                placeholder="Enter EN-Name"
                value={values.name!.en}
                sx={{ width: '100%' }}
                disabled={disabled}
                onChange={(data) => handleNameFieldChange('name.en', data.target.value, 'EN')}
                onBlur={handleBlur}
                error={touched.name?.en && !!errors.name?.en}
              />
              <FormHelperText id="name.en-error" sx={{ color: caloTheme.palette.red }}>
                {langAlert.EN && langAlert.value === 'name.en' && 'letters should be in English only'}
              </FormHelperText>
            </Stack>
            <Stack sx={{ width: '50%' }}>
              <TextField
                label="Name AR*"
                name="name.ar"
                id="name.ar"
                placeholder="Enter AR-Name"
                value={values.name!.ar}
                sx={{ width: '100%' }}
                disabled={disabled}
                onChange={(data) => handleNameFieldChange('name.ar', data.target.value, 'AR')}
                onBlur={handleBlur}
                error={touched.name?.ar && !!errors.name?.ar}
              />
              <FormHelperText id="name.en-error" sx={{ color: caloTheme.palette.red }}>
                {langAlert.AR && langAlert.value === 'name.ar' && 'letters should be in Arabic only'}
              </FormHelperText>
            </Stack>
          </Stack>
          <TextField
            label="Internal name"
            value={values.internalName}
            name="internalName"
            sx={{ width: '100%' }}
            onChange={handleChange}
            onBlur={handleBlur}
            error={!!errors.internalName}
          />
          <Stack sx={{ display: 'flex', flexDirection: 'row', gap: '13px', width: '100%' }}>
            <TextField
              select
              label="Country*"
              value={values.country}
              name="country"
              placeholder="Select Country"
              sx={{ width: '50%' }}
              disabled={disabled}
              onChange={(data) => {
                setFieldValue('country', data.target.value);
                setFieldValue('kitchen', '');
              }}
              error={!!errors.country}
            >
              {getAccessibleCountries(userKitchens).map((country) => (
                <MenuItem key={country} value={country}>
                  {country}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              select
              label="Kitchen*"
              name="kitchen"
              value={values.kitchen}
              placeholder="Select Kitchen"
              sx={{ width: '50%' }}
              disabled={disabled}
              onChange={handleChange}
              error={!!errors.kitchen}
            >
              {getKitchenOptions(userKitchens, values.country)
                .filter((option) => option.label !== 'Any')
                .map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
            </TextField>
          </Stack>
        </Box>
      </Card>
    </InputThemeProvider>
  );
};

export default IngredientInformation;
