import { Country } from '@calo/types';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Breadcrumbs, Chip, Stack, Typography } from '@mui/material';
import { addPrototypeFoodAction, approvePrototypeStage1Food, approvePrototypeStage2Food } from 'actions/food';
import { caloTheme } from 'assets/images/theme/calo';
import { ModalRef, UsedOnMenuIndicator } from 'components';
import { foodStatuslabelColorMapping, foodStatuslabelMapping } from 'lib/constants';
import { FoodStatus, Permission, PrototypeAction, Routes } from 'lib/enums';
import { resolveCountry } from 'lib/helpers';
import { getPrototypeActionApprovalButtonText } from 'lib/helpers/getPrototypeActionApprovalButtonText';
import { determineFoodAction } from 'lib/helpers/playgroundUtils';
import { useUserRoles } from 'lib/hooks';
import { Food, PrototypeFood } from 'lib/interfaces';
import { startCase } from 'lodash-es';
import { useRef, useState } from 'react';
import { useMutation } from 'react-query';
import { Link } from 'react-router-dom';
import { ApprovalActionMenu } from 'views/ChefPlayground/Shared/ApprovalActionMenu/ApprovalActionMenu';
import { ChangeRequestDialog } from 'views/ChefPlayground/Shared/ChangeRequestDialog/ChangeRequestDialog';
import { MealApprovalDialog } from 'views/ChefPlayground/Shared/MealApprovalDialog/MealApprovalDialog';
import { MealCreationActions } from 'views/ChefPlayground/Shared/MealCreationActions';
import DeleteSizeModal from './DeleteSizeModal';
import MealName from './MealName';
import NewSizeModal from './NewSizeModal';
import Ratings from './Ratings';
import { styles } from './styles';

const chefPlaygroundNewFoodBreadCurmb = [
  <Typography key={1} sx={styles.breadcrumbText}>
    Chef's Playground
  </Typography>,
  <Link to={Routes.playgroundFoodList} key={2}>
    <Typography
      sx={{
        ...styles.breadcrumbText,
        '&:hover': {
          textDecoration: 'underline',
          color: caloTheme.palette.textPrimary
        }
      }}
    >
      Meals
    </Typography>
  </Link>,
  <Typography key={3} sx={styles.breadcrumbTextActive}>
    Create meal
  </Typography>
];

interface HeaderProps {
  selectedFood: PrototypeFood;
  foodWithAllSizes: Food[];
  setSelectedFood: React.Dispatch<React.SetStateAction<PrototypeFood | undefined>>;
  setFoodWithAllSizes: (food: Food[]) => void;
  filteredFoodUsed: {
    type: 'mainMenu' | 'addonsMenu';
    date?: string | undefined;
    week?: number | undefined;
  }[];
  usedOnMenuButtonColor: string[];
  isDisabled?: boolean;
  refetch: any;
  isSubmitting: boolean;
  isSaveDisabled: () => boolean;
  handleConfirmation: () => void;
}

const Header = ({
  selectedFood,
  foodWithAllSizes,
  setSelectedFood,
  setFoodWithAllSizes,
  filteredFoodUsed,
  usedOnMenuButtonColor,
  isDisabled,
  refetch,
  isSubmitting,
  isSaveDisabled,
  handleConfirmation
}: HeaderProps) => {
  const [mealApprovalDialogOpen, setMealApprovalDialogOpen] = useState(false);
  const [changeRequestDialogOpen, setChangeRequestDialogOpen] = useState(false);
  const roles = useUserRoles();

  const isNewlyCreated = selectedFood.status === FoodStatus.draft;
  const isPendingFirstStageApproval = selectedFood.status === FoodStatus.awaitingNPDApproval;
  const isPendingSecondStageApproval = selectedFood.status === FoodStatus.awaitingOpsApproval;
  const canApproveStage1 = isPendingFirstStageApproval && roles.includes(Permission.APPROVE_STAGE_1_PROTOTYPE_FOOD);
  const canApproveStage2 = isPendingSecondStageApproval && roles.includes(Permission.APPROVE_STAGE_2_PROTOTYPE_FOOD);

  const shouldShowApprovalActionMenu = canApproveStage1 || canApproveStage2;

  const addMealsModalRef = useRef<ModalRef>();
  const deleteMealModalRef = useRef<ModalRef>();

  const toggleMealApprovalDialog = () => {
    setMealApprovalDialogOpen((prev) => !prev);
  };

  const toggleChangeRequestDialog = () => {
    setChangeRequestDialogOpen((prev) => !prev);
  };

  const {
    mutate: createAction,
    mutateAsync: createActionAsync,
    isLoading: actionLoading
  } = useMutation(addPrototypeFoodAction, {
    onSuccess: () => {
      refetch();
    }
  });

  const { mutate: approveFoodStage1, isLoading: approveStage1Loading } = useMutation(approvePrototypeStage1Food, {
    onSuccess: () => {
      toggleMealApprovalDialog();
      refetch();
    }
  });

  const { mutate: approveFoodStage2, isLoading: approveStage2Loading } = useMutation(approvePrototypeStage2Food, {
    onSuccess: () => {
      toggleMealApprovalDialog();
      refetch();
    }
  });

  const handleMealApprovalSubmit = () => {
    if (selectedFood) {
      if (selectedFood.status === FoodStatus.awaitingNPDApproval) {
        approveFoodStage1({ id: selectedFood.id, slug: selectedFood.slug });
      } else if (selectedFood.status === FoodStatus.awaitingOpsApproval) {
        approveFoodStage2({ id: selectedFood.id, slug: selectedFood.slug });
      } else {
        const action = determineFoodAction(selectedFood.status);
        createAction(
          {
            action,
            id: selectedFood.id,
            slug: selectedFood.slug
          },
          {
            onSuccess: () => {
              toggleMealApprovalDialog();
              refetch();
            }
          }
        );
      }
    }
  };

  const handleChangeRequestSubmit = async (comment: string) => {
    if (selectedFood && comment) {
      await createActionAsync(
        {
          action: PrototypeAction.requestChanges,
          message: comment,
          id: selectedFood.id,
          slug: selectedFood.slug
        },
        {
          onSuccess: () => {
            toggleChangeRequestDialog();
            refetch();
          }
        }
      );
    }
  };

  const renderFoodStatusChip = () => {
    const actionHistory = selectedFood.prototypeActions || [];
    const lastAction = actionHistory[actionHistory.length - 1] as { type: PrototypeAction };

    const isChangeRequested = lastAction?.type === PrototypeAction.requestChanges;
    if (isChangeRequested) {
      return <Chip label={'Changes requested'} color="warning" size="medium" variant="outlined" />;
    } else {
      return (
        <Chip
          label={startCase(foodStatuslabelMapping[selectedFood.status])}
          color={foodStatuslabelColorMapping[selectedFood.status]}
          size="medium"
          variant="outlined"
        />
      );
    }
  };

  return (
    <>
      <Stack sx={styles.container}>
        <Box sx={styles.headerBox}>
          <Breadcrumbs separator={<ChevronRightIcon sx={{ fontSize: '15px' }} />} aria-label="breadcrumb">
            {chefPlaygroundNewFoodBreadCurmb}
          </Breadcrumbs>
          <Typography key={1} sx={styles.countryText}>
            {resolveCountry(selectedFood.country || Country.BH)} - {selectedFood.kitchen}
          </Typography>
        </Box>
        <Stack direction="row" justifyContent="space-between" spacing={2} alignItems="center" mt={'8px'}>
          <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
            <MealName selectedFood={selectedFood as Food} />
            {renderFoodStatusChip()}
            <Ratings selectedFood={selectedFood as Food} foodWithAllSizes={foodWithAllSizes} />
            <UsedOnMenuIndicator filteredFoodUsed={filteredFoodUsed} usedOnMenuButtonColor={usedOnMenuButtonColor} type="food" />
          </Stack>
          <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
            {isNewlyCreated && roles.includes(Permission.CREATE_ACTION_PROTOTYPE_FOOD) && (
              <MealCreationActions
                isDisabled={isDisabled}
                buttonVariant={'outlined'}
                buttonSx={styles.actionButton}
                addMealsModalRef={addMealsModalRef}
                deleteMealModalRef={deleteMealModalRef}
                handleApprove={toggleMealApprovalDialog}
                commentClickHandler={toggleChangeRequestDialog}
                toggleMealApprovalDialog={toggleMealApprovalDialog}
              />
            )}

            {shouldShowApprovalActionMenu && (
              <ApprovalActionMenu
                type="meal"
                isEditable
                item={selectedFood}
                buttonVariant={'outlined'}
                buttonSx={styles.actionButton}
                isDisabled={isDisabled ?? false}
                addMealsModalRef={addMealsModalRef}
                deleteMealModalRef={deleteMealModalRef}
                handleApprove={toggleMealApprovalDialog}
                commentClickHandler={toggleChangeRequestDialog}
                actionButtonText={getPrototypeActionApprovalButtonText(selectedFood.status)}
              />
            )}

            <LoadingButton
              loadingPosition={'center'}
              id="saveButtonExactMeal"
              type="submit"
              loading={isSubmitting}
              disabled={isSaveDisabled()}
              onClick={() => handleConfirmation()}
              variant="outlined"
              sx={styles.actionButton}
            >
              save
            </LoadingButton>
          </Stack>

          <NewSizeModal
            refetch={refetch}
            ref={addMealsModalRef}
            setSelectedFood={setSelectedFood}
            selectedFood={selectedFood as Food}
            foodWithAllSizes={foodWithAllSizes}
            setFoodWithAllSizes={setFoodWithAllSizes}
            close={() => addMealsModalRef.current?.close()}
          />
          <DeleteSizeModal
            ref={deleteMealModalRef}
            foodWithAllSizes={foodWithAllSizes}
            onClose={() => deleteMealModalRef.current?.close()}
          />
        </Stack>
      </Stack>
      <MealApprovalDialog
        open={mealApprovalDialogOpen}
        handleClose={toggleMealApprovalDialog}
        handleSubmit={handleMealApprovalSubmit}
        isPositiveButtonLoading={actionLoading || approveStage1Loading || approveStage2Loading}
        prototypeItems={selectedFood?.components
          ?.filter((fc) => fc.prototype)
          .map((fc) => ({ id: fc.id, name: fc?.name?.en ?? '', type: 'component' }))}
        status={selectedFood.status}
        type="meal"
      />
      <ChangeRequestDialog
        open={changeRequestDialogOpen}
        title="Add comments for changes"
        handleClose={toggleChangeRequestDialog}
        handleSubmit={handleChangeRequestSubmit}
        isPositiveButtonLoading={actionLoading}
      />
    </>
  );
};

export default Header;
