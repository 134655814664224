import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material-v6';
import { styles } from './styles';
import MealUsedItem from './MealUsedItem';
import { MenuUsedFoodItem } from 'lib/interfaces';
import { startCase } from 'lodash-es';

interface MealUsedModalProps {
  filteredFoodUsed: MenuUsedFoodItem[];
  close: () => void;
  selectedFoodType: string;
  showFoodUsedModal: boolean;
}

const MealUsedModal = ({ filteredFoodUsed, close, selectedFoodType, showFoodUsedModal }: MealUsedModalProps) => {
  return (
    <Dialog sx={styles.dialogContainer} open={showFoodUsedModal} onClose={close}>
      <DialogTitle sx={styles.title}>{startCase(selectedFoodType)} is used in the following days</DialogTitle>
      <DialogContent sx={styles.dialogContentContainer}>
        {filteredFoodUsed.map((item, index) => (
          <MealUsedItem key={index} MenuFoodItem={item} selectedFoodType={selectedFoodType} />
        ))}
      </DialogContent>
      <DialogActions>
        <Button variant="text" color="inherit" size="medium" onClick={close}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MealUsedModal;
