import { useEffect, useRef, useState } from 'react';

import { isEmpty, uniqBy } from 'lodash-es';
import { useMutation, useQuery } from 'react-query';
import { generatePath, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { AddonsSubscriptionMenuFoodItem, UpdateAddonsSubscriptionMenuReq } from '@calo/dashboard-types';
import { Brand, Country, Kitchen } from '@calo/types';

import { createAddonsSubscription, getListWithParams, postRequest, updateAddonsSubscription } from 'actions';
import { AddonsSubscriptionPicker, Button, Card, Icon, ModalRef } from 'components';
import AddonsSubscriptionTable from 'components/AddonsSubscriptionTable';
import { FoodStatus, Routes } from 'lib/enums';
import history from 'lib/history';
import { Food } from 'lib/interfaces';
import useAddonsSubscriptionForm from './useAddonsSubscriptionForm';

const ExactAddonsSubscription = () => {
  const { country, brand, kitchen } = useParams<{ country: Country; brand: Brand; kitchen: Kitchen }>();
  const [filterName, setFilterName] = useState<string>();
  const [selectedAddons, setSelectedAddons] = useState<Food[]>([]);

  const { data } = useQuery<any, Error, any>(
    ['addons-subscription-menu', { brand: brand || Brand.CALO, kitchen: kitchen }],
    getListWithParams,
    {
      suspense: true
    }
  );

  const isAddonsSubscriptionMenuNotFound = isEmpty(data);
  const addons = isEmpty(data)
    ? {
        country,
        kitchen,
        brand,
        food: []
      }
    : (data as any);

  const { data: addonsList, isLoading } = useQuery<any, Error, { data: Food[] }>(
    [
      'food/list',
      {
        filters: {
          name: filterName ? filterName : undefined,
          country,
          brand,
          kitchen: kitchen,
          isDeleted: false,
          status: FoodStatus.approved
        },
        sort: {
          orderBy: 'name',
          orderMode: 'asc'
        },
        page: 0,
        limit: 20
      }
    ],
    postRequest,
    {
      suspense: false,
      enabled: !!filterName
    }
  );

  const { mutateAsync: updateMutation } = useMutation(updateAddonsSubscription);
  const { mutateAsync: creatMutation } = useMutation(createAddonsSubscription);

  const onSubmit = async (values: UpdateAddonsSubscriptionMenuReq) => {
    try {
      if (isAddonsSubscriptionMenuNotFound) {
        await creatMutation(
          {
            food: values.food,
            country: addons.country,
            brand: addons.brand,
            kitchen: addons.kitchen
          },
          {
            onSuccess: () => {
              const path = generatePath(Routes.addonsSubscriptionList, {
                brand: addons.brand,
                country: addons.country
              });
              history.push(path);
            }
          }
        );
      } else {
        await updateMutation(
          {
            food: values.food,
            country: addons.country,
            brand: addons.brand,
            kitchen: addons.kitchen
          },
          {
            onSuccess: () => {
              const path = generatePath(Routes.addonsSubscriptionList, {
                brand: addons.brand,
                country: addons.country
              });
              history.push(path);
            }
          }
        );
      }
    } catch {
      toast('error', { type: 'error', autoClose: 2000 });
    }
  };

  const { values, isSubmitting, isValid, dirty, handleSubmit, setFieldValue, setValues } = useAddonsSubscriptionForm(
    addons,
    brand,
    kitchen,
    onSubmit
  );

  const addonsRef = useRef<ModalRef>();

  const [addonsItem, setAddonsItem] = useState<AddonsSubscriptionMenuFoodItem>({
    id: undefined!,
    priority: undefined!,
    startDate: undefined!,
    endDate: undefined!
  });

  const AddToTable = (value: AddonsSubscriptionMenuFoodItem) => {
    setValues({
      food: [
        ...values.food,
        {
          id: value.id,
          priority: value.priority,
          startDate: value.startDate,
          endDate: value.endDate
        }
      ],
      kitchen,
      brand
    });
    addonsRef.current?.close();
    setAddonsItem({ id: undefined!, priority: undefined!, startDate: undefined!, endDate: undefined! });
  };

  const editRow = (value: AddonsSubscriptionMenuFoodItem) => {
    setAddonsItem({ id: value.id, priority: value.priority, startDate: value.startDate, endDate: value.endDate });
    addonsRef.current?.open();
  };

  useEffect(() => {
    if (addonsItem.id !== undefined) {
      setFieldValue(
        'food',
        values.food.filter((e) => e.id !== addonsItem.id)
      );
    }
  }, [addonsItem]);

  const handleSelectedAddons = (id: string) => {
    const newAddon = addonsList?.data.find((e) => e.id === id);
    if (newAddon) {
      setSelectedAddons((old) => [...old, newAddon]);
    }
  };

  return (
    <>
      <section className="section is-title-bar">
        <div className="level">
          <div className="level-left">
            <div className="level-item">
              <ul>
                <li>
                  <Link to={Routes.addonsSubscriptionList}>Addons Subscription</Link>
                </li>
                <li>Edit - {addons.kitchen}</li>
              </ul>
            </div>
          </div>
          <div className="level-right">
            <div className="level-item">
              {addons.brand && addons.brand === Brand.MEALO ? (
                <Icon name="mealo" size={28} className="w-24 -mb-24" />
              ) : (
                <Icon name="calo" size={8} className="w-32 -mb-4" />
              )}
            </div>
          </div>
        </div>
      </section>
      <div>
        <div className="mx-4">
          <div className="card w-full">
            <header className="card-header bg-black">
              <p className="card-header-title w-full text-white">
                <span className="icon">
                  <i className="mdi mdi-account-multiple"></i>
                </span>
                Info
              </p>
              <p className="card-header-title flex float-right text-white">
                <h1>{addons.kitchen}</h1>
              </p>
            </header>
            <div className="card-content">
              <form onSubmit={handleSubmit}>
                <AddonsSubscriptionPicker
                  isLoading={isLoading}
                  addonsRef={addonsRef}
                  AddToTable={AddToTable}
                  addonsItem={addonsItem}
                  existAddons={values.food}
                  setFilterName={setFilterName}
                  addonsList={(addonsList?.data || []).filter((f) => !f.deletedAt)}
                  handleSelectedAddons={handleSelectedAddons}
                />
                <Button
                  primary
                  type="submit"
                  content="Save"
                  loading={isSubmitting}
                  onClick={() => handleSubmit()}
                  disabled={!dirty || !isValid || isSubmitting}
                  data-test="addon-menu-exact-save-button"
                />
              </form>
              <Card title="" className="my-2">
                <div className="float-right -mt-8">
                  <Button icon="fas fa-plus" onClick={() => addonsRef.current?.open()} data-test="addon-menu-exact-plus-button" />
                </div>
                <AddonsSubscriptionTable
                  values={values}
                  setValues={setValues}
                  addonsValues={values.food}
                  editRow={(k) => editRow(k)}
                  addonsList={uniqBy(
                    [...(addonsList?.data || []).filter((f) => !f.deletedAt), ...addons.food, ...selectedAddons],
                    'id'
                  ).filter((selectedFood) => values.food.map((r) => r.id).includes(selectedFood.id))}
                />
              </Card>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ExactAddonsSubscription;
