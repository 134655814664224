import { forwardRef, useEffect, useState } from 'react';

import { FormikErrors } from 'formik';

import { CustomMealCategory, FoodComponentType } from '@calo/types';
import AddIcon from '@mui/icons-material/Add';
import {
  Button,
  Card,
  Divider,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material-v6';

import { BaseOmit, FoodComponent } from '../../../lib/interfaces';
import InputThemeProvider from '../../MUI/InputThemeProvider';
import AddSectionPopup from './AddSectionPopup';
import CustomSectionRow from './CustomSectionRow';
import { styles } from './styles';

interface CustomSectionProps {
  isDisabled?: boolean;
  values: Omit<FoodComponent, BaseOmit>;
  errors: FormikErrors<Omit<FoodComponent, BaseOmit>>;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<FormikErrors<Omit<FoodComponent, BaseOmit>>> | Promise<any>;
}

const CustomSectionCard = forwardRef<HTMLDivElement, CustomSectionProps>(
  ({ values, errors, isDisabled, setFieldValue }: CustomSectionProps, ref) => {
    const [isAddSectionPopupOpen, setIsAddSectionPopupOpen] = useState(false);

    const [selectedCategories, setSelectedCategories] = useState(values.sections?.map((section) => section.category) || []);

    const handleClosePopup = () => {
      setIsAddSectionPopupOpen(false);
      setSelectedCategories(values.sections?.map((section) => section.category) ?? []);
    };

    const removeSectionHandler = (category: CustomMealCategory) => {
      if (!values.sections) {
        return;
      }

      const newSections = values.sections.filter((section) => section.category !== category);
      setFieldValue('sections', newSections);
    };

    const toggleSectionAvailability = (category: CustomMealCategory) => {
      if (!values.sections) {
        return;
      }

      const newSections = values.sections.map((section) => {
        if (section.category === category) {
          return { ...section, availability: !section.availability };
        }
        return section;
      });

      setFieldValue('sections', newSections);
    };

    const updateQuantityHandler = (category: CustomMealCategory, quantity: number) => {
      if (!values.sections) {
        return;
      }

      const newSections = values.sections.map((section) => {
        if (section.category === category) {
          return { ...section, quantity };
        }
        return section;
      });

      setFieldValue('sections', newSections);
    };

    const selectTagHandler = (category: CustomMealCategory, tag: FoodComponentType) => {
      if (!values.sections) {
        return;
      }

      const newSections = values.sections.map((section) => {
        if (section.category === category) {
          return { ...section, tag };
        }
        return section;
      });

      setFieldValue('sections', newSections);
    };

    useEffect(() => {
      setSelectedCategories(values.sections?.map((section) => section.category) ?? []);
    }, [values.sections]);

    return (
      <>
        <Card variant="outlined" sx={styles.card} ref={ref}>
          <Stack sx={styles.headerContainer}>
            <Typography sx={styles.title}>Multi-Custom meals</Typography>
            <Button
              data-test="addSectionButton"
              startIcon={<AddIcon />}
              onClick={() => setIsAddSectionPopupOpen(true)}
              variant="text"
              color="primary"
              size="large"
            >
              Add Section
            </Button>
          </Stack>
          <Divider sx={styles.divider} />
          <InputThemeProvider>
            <Paper sx={styles.tableContainer}>
              <TableContainer>
                <Table sx={{ width: '100%' }}>
                  <TableHead sx={styles.tableHead}>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell sx={styles.tableCell}>Section</TableCell>
                      <TableCell sx={styles.tableCell}>{`Quantity (per measurement unit)`}</TableCell>
                      <TableCell sx={styles.tableCell}>{`Tag`}</TableCell>
                      <TableCell sx={styles.tableCell}>Availability</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {values.sections?.map((section) => (
                      <CustomSectionRow
                        key={section.category}
                        section={section}
                        isDisabled={isDisabled}
                        isValid={!errors[section.category]}
                        updateQuantity={updateQuantityHandler}
                        removeSection={removeSectionHandler}
                        selectTag={selectTagHandler}
                        toggleSectionAvailability={toggleSectionAvailability}
                      />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </InputThemeProvider>
        </Card>
        <AddSectionPopup
          isAddSectionPopupOpen={isAddSectionPopupOpen}
          values={values}
          isDisabled={isDisabled}
          selectedCategories={selectedCategories}
          setSelectedCategories={setSelectedCategories}
          setFieldValue={setFieldValue}
          handleClosePopup={handleClosePopup}
        />
      </>
    );
  }
);

export default CustomSectionCard;
