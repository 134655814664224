import { getListWithParams } from 'actions';
import { format } from 'date-fns';
import { findStartAndEndDateForWeek } from 'lib/helpers';
import { Menu } from 'lib/interfaces';
import { uniq } from 'lodash-es';
import { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { MenuListFilters } from '../../types';

export const useWeeklyMenu = (filters: MenuListFilters, selectedWeek: number, selectedYear: number, disabled?: boolean) => {
  const [isWeeklyMenuChanged, setIsWeeklyMenuChanged] = useState<boolean>(false);
  const [originalWeeklyMenuData, setOriginalWeeklyMenuData] = useState<Menu[]>([]);
  const [weeklyAverageCost, setWeeklyAverageCost] = useState<number>(0);
  const [weeklyMenuList, setWeeklyMenuList] = useState<Menu[]>([]);

  const { startOfWeekDate, endOfWeekDate } = findStartAndEndDateForWeek(selectedWeek, selectedYear);

  const {
    data: weeklyMenu,
    isFetching: isWeeklyMenuLoading,
    refetch
  } = useQuery<any, Error, Menu[]>(
    [
      'menu',
      {
        brand: filters.brand,
        kitchen: filters.kitchen || undefined,
        date: { gte: format(startOfWeekDate, 'yyyy-MM-dd'), lte: format(endOfWeekDate, 'yyyy-MM-dd') }
      }
    ],
    getListWithParams,
    {
      enabled: !disabled,
      onSuccess: (data) => {
        setWeeklyMenuList(data || []);
        setOriginalWeeklyMenuData(data);
      }
    }
  );

  const foodIds = useMemo(() => {
    if (!weeklyMenu) {
      return [];
    }
    return uniq(weeklyMenu.flatMap((menu) => menu.food.map((food) => food.id)));
  }, [weeklyMenu]);

  const updateWeeklyMenuList = (newMenuList: Menu[]) => {
    setWeeklyMenuList(newMenuList);
  };

  return {
    weeklyMenuList,
    isWeeklyMenuLoading,
    isWeeklyMenuChanged,
    refetch,
    setIsWeeklyMenuChanged,
    foodIds,
    setWeeklyMenuList,
    originalWeeklyMenuData,
    setOriginalWeeklyMenuData,
    weeklyAverageCost,
    setWeeklyAverageCost,
    updateWeeklyMenuList
  };
};
