import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button, colors, Divider, Menu, MenuItem, SxProps, Theme } from '@mui/material';
import { caloTheme } from 'assets/images/theme/calo';
import { Permission } from 'lib/enums';
import { useUserRoles } from 'lib/hooks';
import React from 'react';

interface ApprovalActionMenuProps<T> {
  item: T;
  commentClickHandler: (item: T) => void;
  handleApprove: (item: T) => void;
  buttonSx?: SxProps<Theme>;
  buttonVariant?: React.ComponentProps<typeof Button>['variant'];
  actionButtonText: string;
  addMealsModalRef?: any;
  isDisabled?: boolean;
  deleteMealModalRef?: any;
  type: 'meal' | 'component' | 'ingredient';
  isEditable?: boolean;
}

export const ApprovalActionMenu = <T,>({
  item,
  commentClickHandler,
  handleApprove,
  buttonSx,
  buttonVariant,
  actionButtonText,
  addMealsModalRef,
  isDisabled,
  deleteMealModalRef,
  type,
  isEditable = false
}: ApprovalActionMenuProps<T>) => {
  const roles = useUserRoles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        id="basic-button"
        aria-controls={menuOpen ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={menuOpen ? 'true' : undefined}
        onClick={handleMenuOpen}
        endIcon={<ExpandMoreIcon />}
        sx={{ ...buttonSx }}
        variant={buttonVariant}
      >
        Action&nbsp;
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
        <MenuItem
          sx={{
            '&:hover': {
              backgroundColor: caloTheme.palette.primaryMain200
            }
          }}
          onClick={() => {
            handleApprove(item);
            handleClose();
          }}
        >
          {actionButtonText}
        </MenuItem>
        <MenuItem
          sx={{
            '&:hover': {
              backgroundColor: caloTheme.palette.primaryMain200
            }
          }}
          onClick={() => {
            commentClickHandler(item);
            handleClose();
          }}
        >
          Request changes
        </MenuItem>
        {type === 'meal' && isEditable && (
          <>
            <Divider />
            <MenuItem
              sx={{
                '&:hover': {
                  backgroundColor: caloTheme.palette.primaryMain200
                }
              }}
              onClick={() => addMealsModalRef.current?.open()}
            >
              Create Meal Size
            </MenuItem>
            {roles.includes(Permission.DELETE_PROTOTYPE_FOOD) && (
              <MenuItem
                sx={{
                  color: colors.red[500],
                  '&:hover': {
                    backgroundColor: caloTheme.palette.primaryMain200
                  }
                }}
                disabled={isDisabled}
                onClick={() => deleteMealModalRef.current?.open()}
              >
                Delete Meal
              </MenuItem>
            )}
          </>
        )}
      </Menu>
    </>
  );
};
