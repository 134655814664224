import { FoodComponentFilters, Permission, ProcessingStage } from '@calo/dashboard-types';
import { Brand, Kitchen } from '@calo/types';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CreateIcon from '@mui/icons-material/Create';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Breadcrumbs,
  Button,
  InputAdornment,
  Paper,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Typography
} from '@mui/material';
import {
  addPrototypeComponentAction,
  approveStage1PrototypeComponent,
  approveStage2PrototypeComponent,
  getListWithParams,
  getRecord,
  toggleUISettings
} from 'actions/index';
import CaloLoader from 'components/CaloLoader';
import { ModalRef } from 'components/Modal';
import { InputMUI } from 'components/MUI';
import { PrototypeAction, Routes } from 'lib/enums';
import { resolveCountryFromKitchen } from 'lib/helpers';
import { determineComponentOrIngredientAction } from 'lib/helpers/playgroundUtils';
import { useUserKitchens, useUserRoles } from 'lib/hooks';
import { FoodComponent, FoodComponentQuery, PrototypeFoodComponent, SortingFood } from 'lib/interfaces';
import { useEffect, useRef, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import { ChangeRequestDialog } from 'views/ChefPlayground/Shared/ChangeRequestDialog/ChangeRequestDialog';
import ComponentCommentsPopup from 'views/ChefPlayground/Shared/ComponentCommentsPopup';
import { MealApprovalDialog } from 'views/ChefPlayground/Shared/MealApprovalDialog/MealApprovalDialog';
import TableHeaderRow from 'views/ChefPlayground/Shared/TableHeaderRow';
import ComponentRow from './ComponentRow';
import Settings from './Settings';
import { styles } from './styles';

const chefPlaygroundFoodlistBreadCurmb = [
  <Typography variant="body1" key={1} sx={{ ...styles.breadcrumbInactive, ...styles.breadcrumbs }}>
    Chef's Playground
  </Typography>,
  <Typography variant="body1" key={2} sx={{ ...styles.breadcrumbActive, ...styles.breadcrumbs }}>
    Components
  </Typography>
];

const ComponentList = () => {
  const roles = useUserRoles();
  const location = useLocation();
  const history = useHistory();
  const userKitchen = useUserKitchens();
  const commentsPopupRef = useRef<ModalRef>();
  const searchParams = new URLSearchParams(location.search);
  const dispatch = useDispatch();

  const [componentApprovalDialogOpen, setComponentApprovalDialogOpen] = useState(false);
  const [changeRequestDialogOpen, setChangeRequestDialogOpen] = useState(false);
  const [prototypeItems, setPrototypeItems] = useState<{ id: string; name: string; type: string }[]>([]);
  const [page, setPage] = useState<number>(0);
  const [componentList, setComponentList] = useState<FoodComponent[]>([]);
  const [selectedComponent, setSelectedComponent] = useState<PrototypeFoodComponent | undefined>();

  const sort: SortingFood = {
    orderBy: 'updatedAt',
    orderMode: 'desc'
  };

  const {
    mutate: createAction,
    mutateAsync: createActionAsync,
    isLoading: actionLoading
  } = useMutation(addPrototypeComponentAction, {
    onSuccess: () => {
      refetch();
      handleFoodComponentApprovalDialogClose();
    }
  });

  const handleFoodComponentApprovalDialogOpen = (foodComponent: PrototypeFoodComponent) => {
    setSelectedComponent(foodComponent);
    setComponentApprovalDialogOpen(true);
  };

  const handleFoodComponentApprovalDialogClose = () => {
    setComponentApprovalDialogOpen(false);
  };

  const handleChangeRequestDialogOpen = (foodComponent: PrototypeFoodComponent) => {
    setSelectedComponent(foodComponent);
    setChangeRequestDialogOpen(true);
  };

  const handleChangeRequestDialogClose = () => {
    setChangeRequestDialogOpen(false);
  };

  const handleChangeRequestSubmit = async (comment: string) => {
    if (selectedComponent && comment) {
      await createActionAsync(
        {
          action: PrototypeAction.requestChanges,
          message: comment,
          id: selectedComponent.id
        },
        {
          onSuccess: () => {
            handleChangeRequestDialogClose();
            refetch();
          }
        }
      );
    }
  };

  const [filters, setFilters] = useState<FoodComponentFilters>({
    country: resolveCountryFromKitchen((userKitchen && userKitchen[0]) || Kitchen.BH1),
    brand: Brand.CALO,
    kitchen: (userKitchen && userKitchen[0]) || Kitchen.BH1,
    ...JSON.parse(searchParams.get('filters') || `{}`)
  });

  useEffect(() => {
    setPage(0);
  }, [filters]);

  const { data, isLoading, refetch } = useQuery<any, Error, FoodComponentQuery>(
    [
      'food-components/prototype',
      {
        page,
        filters: {
          ...filters,
          name: filters.name ? filters.name : undefined,
          ingredientId: filters.ingredientId ? filters.ingredientId : undefined
        },
        sort,
        limit: 50
      }
    ],
    getListWithParams,
    {
      suspense: false,
      enabled: roles.includes(Permission.VIEW_PROTOTYPE_COMPONENT_LIST),
      onSuccess: () => {
        searchParams.set('filters', JSON.stringify(filters));
        history.push({
          pathname: location.pathname,
          search: searchParams.toString()
        });
      }
    }
  );

  const { mutate: approveComponentStage1, isLoading: approveStage1Loading } = useMutation(approveStage1PrototypeComponent, {
    onSuccess: () => {
      handleFoodComponentApprovalDialogClose();
      refetch();
    }
  });

  const { mutate: approveComponentStage2, isLoading: approveStage2Loading } = useMutation(approveStage2PrototypeComponent, {
    onSuccess: () => {
      handleFoodComponentApprovalDialogClose();
      refetch();
    }
  });

  const { isLoading: selectedComponentLoading, isFetching: selectedComponentFetching } = useQuery<
    any,
    Error,
    PrototypeFoodComponent
  >([`food-components/prototype/${selectedComponent?.id}`], getRecord, {
    enabled: !!selectedComponent,
    keepPreviousData: false,
    onSuccess: (data) => {
      if (data) {
        const ingredients =
          data?.ingredients?.filter((fc) => fc.prototype).map((ing) => ({ id: ing.id, name: ing.name.en, type: 'ingredient' })) ||
          [];
        const childComponents =
          data?.childComponents?.filter((fc) => fc.prototype).map((cc) => ({ id: cc.id, name: cc.name.en, type: 'component' })) ||
          [];
        setPrototypeItems([...ingredients, ...childComponents]);
      }
    }
  });

  useEffect(() => {
    if (!data?.data) {
      return;
    }
    setComponentList(data.data);
  }, [data]);

  const closeCommentsPopup = () => {
    commentsPopupRef.current?.close();
  };

  const commentClickHandler = (component: FoodComponent) => {
    setSelectedComponent(component as PrototypeFoodComponent);
    commentsPopupRef.current?.open();
  };

  const addCommentSuccessHandler = (data: FoodComponent) => {
    setSelectedComponent(data as PrototypeFoodComponent);
    setComponentList((prev) => {
      const componentIndex = prev.findIndex((component) => component.id === data.id);
      return [...prev.slice(0, componentIndex), data, ...prev.slice(componentIndex + 1)];
    });
  };

  const handleFoodComponentApprovalSubmit = () => {
    if (selectedComponent && selectedComponent.stage) {
      if (selectedComponent.stage === ProcessingStage.awaitingNPDApproval) {
        approveComponentStage1(selectedComponent.id);
      } else if (selectedComponent.stage === ProcessingStage.awaitingOpsApproval) {
        approveComponentStage2(selectedComponent.id);
      } else {
        const action = determineComponentOrIngredientAction(selectedComponent.stage);

        createAction(
          {
            action,
            id: selectedComponent.id
          },
          {
            onSuccess: () => {
              handleFoodComponentApprovalDialogClose();
              refetch();
            }
          }
        );
      }
    }
  };

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
        <Breadcrumbs separator={<ChevronRightIcon sx={{ fontSize: '15px' }} />} aria-label="breadcrumb">
          {chefPlaygroundFoodlistBreadCurmb}
        </Breadcrumbs>
        <Typography variant="h4" sx={styles.title}>
          Components
        </Typography>
      </Box>
      <Stack sx={{ borderRadius: '16px', py: '16px' }}>
        <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '24px' }}>
            <Button
              onClick={() => dispatch(toggleUISettings())}
              startIcon={<FilterAltIcon style={{ fontSize: '24px' }} />}
              sx={styles.filterButton}
            >
              FILTER
            </Button>
            <InputMUI
              data-test="nameInput"
              type="text"
              placeholder={'Search'}
              value={filters.name}
              onChange={(e) => setFilters({ ...filters, name: e.target.value })}
              debounce
              sx={styles.searchInput}
              inputProps={{
                style: styles.searchInputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon fontSize="medium" />
                  </InputAdornment>
                )
              }}
            />
          </Box>
          <Button
            onClick={() => history.push(Routes.playgroundNewComponent)}
            disabled={!roles.includes(Permission.CREATE_PROTOTYPE_COMPONENT)}
            startIcon={<CreateIcon style={{ fontSize: '24px' }} />}
            sx={styles.createButton}
          >
            create component
          </Button>
        </Stack>
        {isLoading ? (
          <CaloLoader />
        ) : (
          <Paper sx={styles.tableContainer}>
            <TableContainer>
              <Table sx={{ width: '100%' }}>
                <TableHead sx={styles.tableHead}>
                  <TableHeaderRow type="component" />
                </TableHead>
                <TableBody>
                  {componentList.map((component) => (
                    <ComponentRow
                      key={component.id}
                      component={component}
                      commentClickHandler={commentClickHandler}
                      changeRequestClickHandler={handleChangeRequestDialogOpen}
                      handleFoodComponentApprovalDialogOpen={handleFoodComponentApprovalDialogOpen}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        )}
      </Stack>
      <ComponentCommentsPopup
        selectedComponent={selectedComponent as FoodComponent}
        ref={commentsPopupRef}
        closeCommentsPopup={closeCommentsPopup}
        successHandler={addCommentSuccessHandler}
      />
      <Settings onFilter={setFilters} filters={filters} />
      <MealApprovalDialog
        open={componentApprovalDialogOpen}
        handleClose={handleFoodComponentApprovalDialogClose}
        handleSubmit={handleFoodComponentApprovalSubmit}
        isPositiveButtonLoading={actionLoading || approveStage1Loading || approveStage2Loading}
        isLoading={selectedComponentLoading || selectedComponentFetching}
        prototypeItems={prototypeItems}
        status={selectedComponent?.stage}
        type="component"
      />
      <ChangeRequestDialog
        open={changeRequestDialogOpen}
        title="Add comments for changes"
        handleClose={handleChangeRequestDialogClose}
        handleSubmit={handleChangeRequestSubmit}
        isPositiveButtonLoading={actionLoading}
      />
    </>
  );
};

export default ComponentList;
