import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box, Button, Stack, Typography } from '@mui/material';
import { differenceInDays, parseISO } from 'date-fns';
import { format } from 'date-fns/fp';
import { handleWeekFormat } from 'lib/helpers';
import { useState } from 'react';
import { styles } from './styles';
import { MenuUsedFoodItem } from 'lib/interfaces';

const getSelectedText = (selectedFoodType: string, type: string): string => {
  switch (selectedFoodType) {
    case 'food':
      return type === 'addonsMenu' ? 'meal size or different size' : 'Components for this meal size or different size';
    case 'component':
      return type === 'addonsMenu' ? 'This food component' : 'Components & ingredients for this component';
    case 'ingredient':
      return type === 'addonsMenu' ? 'This food ingredient' : 'Ingredient';
    default:
      return type === 'addonsMenu' ? 'meal size or different size' : 'Components for this meal size or different size';
  }
};

const getFormattedDateOrWeek = (date?: string, week?: number): string | null => {
  if (date) {
    return format('eee dd/MM/yyyy')(parseISO(date));
  }
  if (week !== undefined) {
    return handleWeekFormat(week);
  }
  return null;
};

const handleInfoText = (selectedFoodType: string, date: Date | null, type: string): string => {
  const selected = getSelectedText(selectedFoodType, type);

  if (type === 'addonsMenu') {
    return `This ${selected} is part of an addon menu, changes will not be applied before the next addon menu generation, please coordinate with the operations team.`;
  }

  if (date && differenceInDays(date, Date.now()) <= 2) {
    return `${selected} are prepped or cooked already, please communicate directly with the Operations team before doing any changes.`;
  }

  return `${selected} might have been purchased or prepped already, please double-check with the Operations team.`;
};

interface MealUsedItemProps {
  MenuFoodItem: MenuUsedFoodItem;
  selectedFoodType: string;
}

const MealUsedItem = ({ MenuFoodItem, selectedFoodType }: MealUsedItemProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const formattedDateOrWeek = getFormattedDateOrWeek(MenuFoodItem.date, MenuFoodItem.week);

  let infoText = '';
  if (MenuFoodItem.type === 'mainMenu') {
    if (
      MenuFoodItem.date &&
      (differenceInDays(parseISO(MenuFoodItem.date), parseISO(format('yyyy-MM-dd')(+Date.now()))) >= 0 ||
        differenceInDays(parseISO(MenuFoodItem.date), parseISO(format('yyyy-MM-dd')(+Date.now()))) <= 7)
    ) {
      infoText = handleInfoText(selectedFoodType, parseISO(MenuFoodItem.date), 'mainMenu');
    }
  } else {
    infoText = handleInfoText(selectedFoodType, null, 'addonsMenu');
  }

  return (
    <Stack sx={styles.mealUsedItemContainer}>
      <Button
        variant="outlined"
        endIcon={isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        onClick={() => setIsExpanded((prev) => !prev)}
        sx={styles.mealUsedItemHeader(MenuFoodItem)}
      >
        {formattedDateOrWeek || 'Invalid Date/Week'}
      </Button>

      {isExpanded && (
        <Box sx={styles.mealUsedItemDescriptionContainer}>
          <Typography sx={styles.mealUsedItemDescription}>{infoText}</Typography>
        </Box>
      )}
    </Stack>
  );
};

export default MealUsedItem;
