import { AddonsMenuFoodItem, CreateAddonsMenuReq, MenuFood, UpdateAddonsMenuReq } from 'lib/interfaces';
import { flatten, sortBy } from 'lodash';
import { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import FoodDietTypeTags from '../FoodDietTypeTags';
interface AddonsTableProps {
  addonsList: MenuFood[];
  values: CreateAddonsMenuReq | UpdateAddonsMenuReq;
  addonsValues: AddonsMenuFoodItem[];
  editRow: (values: AddonsMenuFoodItem) => void;
  setValues: any;
}

const AddonsTable = ({ setValues, addonsList, editRow, values }: AddonsTableProps) => {
  const [addonsListValues, setAddonsListValues] = useState(
    sortBy(addonsList, (f) => values.food.find((r) => r.id === f.id)!.priority)
  );

  useEffect(() => {
    if (addonsList.length !== addonsListValues.length) {
      setAddonsListValues(sortBy(addonsList, (f) => values.food.find((r) => r.id === f.id)!.priority));
    }
  }, [addonsList]);

  useEffect(() => {
    if (values.food.length > 0) {
      const tempData = [...addonsListValues];
      const [sourceData] = tempData.splice(0, 1);
      tempData.splice(0, 0, sourceData);
      const foodValues = tempData.map((f, index) => [
        { id: f.id, priority: (values.food.find((r) => r.id === f.id)!.priority = index + 1) }
      ]);
      setAddonsListValues(tempData);
      setValues({
        ...values,
        food: flatten(foodValues)
      });
    }
  }, []);

  const handleDragEnd = (e: any) => {
    if (!e.destination) return;

    const tempData = [...addonsListValues];
    const [sourceData] = tempData.splice(e.source.index, 1);
    tempData.splice(e.destination.index, 0, sourceData);
    const foodValues = tempData.map((f, index) => [
      { id: f.id, priority: (values.food.find((r) => r.id === f.id)!.priority = index + 1) }
    ]);
    setAddonsListValues(tempData);
    setValues({
      ...values,
      food: flatten(foodValues)
    });
  };

  const deleteRow = (row: AddonsMenuFoodItem) => {
    const tempData = [...addonsListValues];
    const tempFiltered = tempData.filter((e) => e.id !== row.id);
    const foodValues = tempFiltered.map((f, index) => [
      { id: f.id, priority: (values.food.find((r) => r.id === f.id)!.priority = index + 1) }
    ]);
    setValues({
      ...values,
      food: flatten(foodValues)
    });
  };

  const getLabelsForFood = (foodId: string) => {
    if (!values.tags) return [];
    return values.tags
      .filter((tag) => tag.foodId === foodId)
      .reduce((acc: string[], tag) => {
        for (const val of tag.value) {
          if (!acc.includes(val)) {
            acc.push(val);
          }
        }
        return acc;
      }, []);
  };

  return (
    <div className="table-container">
      <DragDropContext onDragEnd={handleDragEnd}>
        <table className="table mt-4 is-fullwidth is-striped is-hoverable is-sortable is-fullwidth">
          <thead>
            <tr>
              <th></th>
              <th>Name</th>
              <th>Food Type</th>
              <th>Size</th>
              <th>DietType</th>
              <th>Labels</th>
              <th>priority</th>
            </tr>
          </thead>
          <Droppable droppableId="droppable">
            {(provider) => (
              <tbody className="text-capitalize" ref={provider.innerRef} {...provider.droppableProps}>
                {addonsListValues.map((f: any, index) => (
                  <Draggable key={f.id} draggableId={f.id} index={index}>
                    {(provider) => (
                      <tr key={index} {...provider.draggableProps} ref={provider.innerRef}>
                        <td {...provider.dragHandleProps}> = </td>
                        <td>{f.name.en}</td>
                        <td>{f.type.join(', ')}</td>
                        <td>{f.size}</td>
                        <td>
                          <div key={index} className="mb-2 w-15 flex">
                            {f.tags?.map((r: any) => <FoodDietTypeTags key={r} tag={r} fullName />)}
                          </div>
                        </td>
                        <td>
                          <div className="mb-2 w-15 flex">{getLabelsForFood(f.id).join(', ')}</div>
                        </td>
                        <td>
                          <div key={index} className="mb-4 flex ">
                            {values.food.map((r) => (r.id === f.id ? r.priority : ''))}
                            <div key={index} className="ml-2">
                              <i
                                className="fas fa-edit"
                                onClick={() =>
                                  editRow({
                                    ...f,
                                    priority: (values.food.find((e) => f.id === e.id)!.priority = index + 1)
                                  })
                                }
                                data-test="addon-menu-table-edit-button"
                              />
                              <i
                                className="fas fa-trash mr-4 last:mr-0"
                                onClick={() =>
                                  deleteRow({ id: f.id, priority: +values.food.find((e) => f.id === e.id)!.priority })
                                }
                                data-test="addon-menu-table-delete-button"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                    )}
                  </Draggable>
                ))}
                {provider.placeholder}
              </tbody>
            )}
          </Droppable>
        </table>
      </DragDropContext>
    </div>
  );
};

export default AddonsTable;
