import { caloTheme } from 'assets/images/theme/calo';

export const styles = {
  header: { display: 'flex', flexDirection: 'column', gap: '8px' },
  breadcrumbs: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '150%'
  },
  breadcrumbActive: {
    color: caloTheme.palette.textPrimary
  },
  breadcrumbInactive: {
    color: caloTheme.palette.textSecondary
  },
  title: {
    fontSize: '34px',
    fontWeight: '400',
    lineHeight: '125%',
    color: caloTheme.palette.textPrimary
  },
  countryKitchenText: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '150%',
    color: caloTheme.palette.textPrimary,
    textTransform: 'uppercase'
  },
  firstRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  filterContainer: { display: 'flex', alignItems: 'center', gap: '16px' },
  filterButton: {
    padding: '8px 22px',
    textTransform: 'none',
    color: caloTheme.palette.neutral900,
    fontSize: '15px',
    fontWeight: 500,
    lineHeight: '125%',
    ':hover': {
      backgroundColor: 'transparent'
    },
    border: `1px solid ${caloTheme.palette.textPrimary}`,
    borderRadius: '4px'
  },
  searchInput: {
    width: '90%'
  },
  searchInputProps: {
    height: '40px',
    fontSize: '16px',
    backgroundColor: 'white'
  },
  actionRow: { flexDirection: 'row', justifyContent: 'space-between', marginTop: '16px' },
  actionButtonContainer: {
    display: 'flex',
    gap: '16px'
  }
};
