import { Brand, MenuTagValue } from '@calo/types';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ShuffleIcon from '@mui/icons-material/Shuffle';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import { Box, IconButton, Stack, Typography } from '@mui/material-v6';
import { caloTheme } from 'assets/images/theme/calo';
import { ModalRef } from 'components';
import MenuMealInfo from 'components/MenuMealInfo';
import { handleCalculateLastTime } from 'components/MenuTable/MenuTable';
import Popup from 'components/Popup';
import { format } from 'date-fns';
import { menuTagLabelColor } from 'lib/helpers';
import { Menu, MenuFood } from 'lib/interfaces';
import { startCase, uniqBy } from 'lodash-es';
import { useCallback, useMemo, useRef, useState } from 'react';
import FoodTagsChips from './FoodTagsChips';
import MealSize from './MealSize';
import { sortMeals } from './helper';
import { styles } from './styles';

interface MealListPerDayProps {
  menu: Menu;
  isEditable: boolean;
  setSelectedMenu: (value: Menu) => void;
  recurringFoodNames: string[];
  handleRemoveFood: (food: MenuFood, menuId: string) => void;
  setFoodToReplace: (value: { food: MenuFood }) => void;
  menuWithFoodCost?: { [name: string]: number };
}

const MealListPerDay = ({
  menu,
  isEditable,
  setSelectedMenu,
  recurringFoodNames,
  handleRemoveFood,
  setFoodToReplace,
  menuWithFoodCost
}: MealListPerDayProps) => {
  const infoMealRef = useRef<ModalRef>();
  const [selectedMeal, setSelectedMeal] = useState<MenuFood | undefined>();

  const handleSelectMealInfo = useCallback((food: MenuFood) => {
    setSelectedMeal(food);
    infoMealRef.current?.open();
  }, []);

  const sortedMeals = useMemo(() => {
    return uniqBy(sortMeals(menu.food), (food) => food.name.en);
  }, [menu.food]);

  const handleFoodSizes = (foodName: string): JSX.Element[] => MealSize({ foodName, menu });

  const foodLabelIcon = (label: any) => {
    const backgroundColor = Object.keys(menuTagLabelColor).find((key) => label.value.includes(key))
      ? menuTagLabelColor[label.value]
      : caloTheme.palette.white;

    if (label && !label.value.includes('NONE')) {
      return (
        <Box
          display={'flex'}
          flexDirection={'row'}
          justifyContent="center"
          sx={{
            minWidth: '45px',
            color: 'white',
            mt: '2px',
            padding: '4px',
            borderRadius: '4px',
            backgroundColor: backgroundColor
          }}
        >
          <Typography
            display={label.value.length > 0 ? '' : 'none'}
            variant="caption"
            sx={{
              fontWeight: 600,
              fontSize: '10px',
              lineHeight: '12px',
              textAlign: 'center'
            }}
          >
            {label.value.includes(MenuTagValue.UPGRADED) ? 'IMPROVED' : startCase(label.value)}
          </Typography>
        </Box>
      );
    }
  };
  return (
    <Box sx={styles.mealListBox} key={menu.id}>
      <Typography sx={styles.dateTypography}>
        {format(new Date(menu.id), 'eeee dd/MM')} ({sortedMeals.length})
      </Typography>
      <Stack sx={styles.mealStack}>
        {sortedMeals.map((food, index) => {
          const ratingWarning =
            food?.totalRating && food?.numberOfRatings ? +(food.totalRating / food.numberOfRatings).toFixed(2) : 0;
          const tags = menu.tags?.find((r: any) => r.foodId === food.id);
          return (
            <Box
              key={`${food.id}-${index}-${menu.id}`}
              sx={{
                ...styles.mealBox,
                borderWidth: 1,
                borderColor: recurringFoodNames.includes(food.name.en) ? caloTheme.palette.red500 : caloTheme.palette.grey[400]
              }}
            >
              {tags && tags.value.length > 0 && (
                <Stack sx={{ marginTop: '-20px', mb: '2px', zIndex: 10, overflow: 'visible', flexDirection: 'row' }}>
                  <Typography>{foodLabelIcon(menu.tags?.find((r: any) => r.foodId === food.id))}</Typography>
                </Stack>
              )}
              <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                <Typography sx={styles.text}>{food.name.en}</Typography>
                {ratingWarning < 3.8 && ratingWarning !== 0 && (
                  <IconButton sx={styles.warningContainer}>
                    <WarningAmberOutlinedIcon color="error" sx={styles.warningIcon as any} />
                  </IconButton>
                )}
              </Stack>
              <Typography sx={styles.costTypography}>{menuWithFoodCost ? menuWithFoodCost[food.name.en] : '--'} %</Typography>
              <Stack sx={styles.row}>
                <FoodTagsChips foodTags={food.foodTags} mealType={food.type} />
              </Stack>
              <Stack key={food.id} sx={styles.iconsContainer}>
                <InfoOutlinedIcon sx={styles.infoIcon as any} onClick={() => handleSelectMealInfo(food)} />
                {isEditable && (
                  <>
                    <ShuffleIcon
                      key={`${food.id}-${menu.id}-${index}`}
                      sx={styles.infoIcon as any}
                      onClick={() => {
                        setSelectedMenu(menu);
                        setFoodToReplace({ food: food });
                      }}
                    />
                    <CloseOutlinedIcon sx={styles.infoIcon as any} onClick={() => handleRemoveFood(food, menu.id)} />
                  </>
                )}
              </Stack>
            </Box>
          );
        })}
      </Stack>
      <Popup
        maxWidth="lg"
        ref={infoMealRef}
        onClose={() => {
          infoMealRef.current?.close();
          setSelectedMeal(undefined);
        }}
      >
        <MenuMealInfo
          key={selectedMeal?.id || ''}
          handleCalculateLastTime={handleCalculateLastTime}
          setSelectedMeal={setSelectedMeal}
          handleFoodSizes={handleFoodSizes}
          infoMealRef={infoMealRef}
          selectedMeal={selectedMeal}
          brand={menu?.brand || Brand.CALO}
        />
      </Popup>
    </Box>
  );
};

export default MealListPerDay;
