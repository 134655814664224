import { useCallback, useMemo, useRef, useState } from 'react';

import { uploadImage } from 'actions';
import noImagePlaceholder from 'assets/images/noImagePlaceholder.png';
import { caloTheme } from 'assets/images/theme/calo';
import { ModalRef } from 'components/Modal';
import client from 'lib/client';
import { ImageType } from 'lib/enums';
import { useUserRoles } from 'lib/hooks';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';

import { ImageUpdateLogReq, Permission } from '@calo/dashboard-types';
import { Box, LinearProgress, Stack } from '@mui/material';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';

import ImagePopup from './ImagePopup';

interface ImageUploaderProps {
  uploadLink: () => Promise<{ url: string; fields: Record<string, string | Blob> }>;
  image: string;
  disabled: boolean;
  values?: ImageUpdateLogReq;
  width?: number;
  maxHeight?: number;
  imageType?: ImageType;
  id?: string;
}

const ImageUploader = ({
  image,
  uploadLink,
  disabled,
  values,
  width = 240,
  maxHeight = 240,
  imageType,
  id
}: ImageUploaderProps) => {
  const [progress, setProgress] = useState(0);
  const [displayImage, setDisplayImage] = useState<string>(image);
  const [isPreviewDisabled, setIsPreviewDisabled] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [sizeWarning, setSizeWarning] = useState('');
  const [dimensionsWarning, setDimensionsWarning] = useState('');

  const originalImage = `${process.env.REACT_APP_BUCKET_URL}/${imageType}/${id}/original`;

  const viewImageRef = useRef<ModalRef>();

  const roles = useUserRoles();

  const onDrop = useCallback(
    async (files) => {
      if (!files) {
        return;
      }
      if (files && files[0].size > 62914560) {
        toast('Image size is too big', { type: 'error', autoClose: 2000 });
        return;
      }

      if (files && !files[0].type.includes('image')) {
        toast('Image only', { type: 'error', autoClose: 2000 });
        return;
      }

      const file = files[0];
      const image = new Image();
      image.src = URL.createObjectURL(file);

      image.addEventListener('load', async () => {
        if (image.width < 999 || image.height < 999) {
          setDimensionsWarning('Warning: Image dimensions are too small, minimum image dimensions are 999 x 999 pixels');
          toast('Warning: Image dimensions are too small, minimum image dimensions are 999 x 999 pixels', {
            type: 'warning',
            autoClose: 6000
          });
        }
        if (files && files[0].size < 10 * 1024) {
          setSizeWarning('Warning: Image size is too small, minimum image size is 10Kb');
          toast('Warning: Image size is too small, minimum image size is 10Kb', { type: 'warning', autoClose: 6000 });
          return;
        }
      });

      const { url, fields } = await uploadLink();

      const formData = new FormData();

      for (const key of Object.keys(fields)) {
        formData.append(key, fields[key]);
      }

      formData.append('file', file);

      setDisplayImage(URL.createObjectURL(file));

      const imageUpload = await uploadImage(url, formData, {
        onUploadProgress: (progressEvent) =>
          setProgress(Math.min(100, Math.round((progressEvent.loaded * 100) / progressEvent.total)))
      });

      if (imageUpload && values && roles.includes(Permission.CREATE_LOGS_ON_UPDATE_IMAGE)) {
        await client.post('image-change-log', values);
      }
      if (imageUpload) {
        setIsPreviewDisabled(false);
      }
    },
    [uploadLink]
  );

  const { getRootProps, getInputProps } = useDropzone({ onDrop });
  const previewImage = useMemo(() => displayImage.replace('square@1x.jpg', 'square@3x.jpg'), [displayImage]);

  return (
    <Card
      sx={{
        boxShadow: 'none',
        border: '1px solid ' + caloTheme.palette.neutral100,
        borderRadius: '4px',
        p: 2
      }}
    >
      <CardMedia
        component="img"
        image={displayImage}
        alt="placeholder"
        sx={{ width, maxHeight, height: 208, objectFit: 'cover', borderRadius: '4px' }}
        onLoad={() => !hasError && setIsPreviewDisabled(false)}
        onError={(e: any) => {
          setHasError(true);
          e.target.src = noImagePlaceholder;
        }}
      />
      <LinearProgress
        variant="determinate"
        value={progress}
        sx={{ mt: 1, visibility: progress > 0 && progress < 100 ? 'visible' : 'hidden' }}
      />
      <Stack sx={{ display: 'flex', flexDirection: 'row', gap: 2, justifyContent: 'center' }}>
        <Button
          data-test="previewImageButton"
          variant="text"
          sx={{
            color: caloTheme.palette.textPrimary,
            textTransform: 'uppercase',
            fontSize: '15px',
            fontWeight: 500,
            lineHeight: '125%',
            '&:hover': {
              color: caloTheme.palette.white,
              backgroundColor: caloTheme.palette.neutral900
            }
          }}
          disabled={isPreviewDisabled}
          onClick={() => viewImageRef.current?.open()}
        >
          Preview
        </Button>
        {disabled && (
          <>
            <Box sx={{ borderRight: '1px solid ' + caloTheme.palette.neutral100 }} />
            <Button
              variant="text"
              sx={{
                color: caloTheme.palette.textPrimary,
                textTransform: 'uppercase',
                fontSize: '15px',
                fontWeight: 500,
                lineHeight: '125%',
                '&:hover': {
                  color: caloTheme.palette.white,
                  backgroundColor: caloTheme.palette.neutral900
                }
              }}
              {...getRootProps()}
            >
              <input data-test="updateImageButton" {...getInputProps()} accept="image/*" />
              Update
            </Button>
          </>
        )}
      </Stack>
      <ImagePopup
        imageUrl={previewImage}
        mainRef={viewImageRef}
        canEdit={true}
        originalImage={originalImage}
        sizeWarning={sizeWarning}
        setSizeWarning={setSizeWarning}
        dimensionsWarning={dimensionsWarning}
        setDimensionsWarning={setDimensionsWarning}
      />
    </Card>
  );
};

export default ImageUploader;
