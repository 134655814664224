import { useState } from 'react';

import { FormikErrors } from 'formik';
import { camelCase } from 'lodash';

import { KitchenLogType, Permission } from '@calo/dashboard-types';
import { Card, Divider, FormHelperText, Stack, TextField, Typography } from '@mui/material';

import { getImageUploadLink } from 'actions/ui';
import { caloTheme } from 'assets/images/theme/calo';
import { ImageUploaderMUI } from 'components/MUI';
import InputThemeProvider from 'components/MUI/InputThemeProvider';
import { ImageType } from 'lib/enums';
import { hasValidCharactersForLanguage } from 'lib/helpers';
import { useUserRoles } from 'lib/hooks';
import { IngredientFormData } from 'lib/interfaces';

interface IngredientInformationProps {
  id: string;
  values: IngredientFormData;
  showImageUploader?: boolean;
  errors: FormikErrors<IngredientFormData>;
  handleChange: { (e: React.ChangeEvent<any>): void };
  handleBlur: { (e: React.FocusEvent<any, Element>): void };
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<void> | Promise<FormikErrors<IngredientFormData>>;
  isDisabled?: boolean;
}

const IngredientInformation = ({
  id,
  values,
  errors,
  showImageUploader,
  handleChange,
  handleBlur,
  setFieldValue,
  isDisabled
}: IngredientInformationProps) => {
  const userRoles = useUserRoles();

  const [langAlert, setLangAlert] = useState({ AR: false, EN: false, value: '' });

  const handleNameFieldChange = (field: string, data: string, lang: 'AR' | 'EN') => {
    const allowSpecialChar = field.includes('description');

    setFieldValue(field, data);

    const hasValidCharacters = hasValidCharactersForLanguage(data, lang, allowSpecialChar) || data === '';
    setLangAlert({ ...langAlert, [`${lang}`]: !hasValidCharacters, value: field });

    setFieldValue('key', camelCase(data));
  };

  return (
    <InputThemeProvider>
      <Card
        variant="outlined"
        sx={{
          marginTop: '16px',
          border: 'none',
          borderRadius: '8px',
          boxShadow: 2,
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <Typography variant="h5" sx={{ fontSize: '24px', fontWeight: 400, lineHeight: '150%', padding: '16px' }}>
          Ingredient Information
        </Typography>
        <Divider sx={{ color: caloTheme.palette.divider, height: '1px', width: '100%' }} />
        <Stack sx={{ flexDirection: 'row', width: '100%', gap: 1, padding: '24px 16px 16px 16px' }}>
          {showImageUploader && (
            <Stack>
              <ImageUploaderMUI
                key={id}
                values={{ name: values.name.en, id: id, type: KitchenLogType.ingredient }}
                uploadLink={() => getImageUploadLink(`ingredients/${id}`)}
                image={`${process.env.REACT_APP_BUCKET_URL}/ingredients/${id}/square@1x.jpg`}
                disabled={userRoles.includes(Permission.UPLOAD_IMAGE)}
                id={id}
                imageType={ImageType.INGREDIENT}
              />
            </Stack>
          )}
          <Stack sx={{ width: showImageUploader ? '80%' : '100%' }}>
            <Stack sx={{ display: 'flex', flexDirection: 'row', gap: '13px', width: '100%', marginBottom: '28px' }}>
              <Stack sx={{ width: '50%' }}>
                <TextField
                  label="Name EN*"
                  name="name.en"
                  id="name.en"
                  placeholder="Enter EN-Name"
                  value={values.name.en}
                  sx={{ width: '100%' }}
                  disabled={!userRoles.includes(Permission.UPDATE_INGREDIENT) || Boolean(isDisabled)}
                  onChange={(data) => handleNameFieldChange('name.en', data.target.value, 'EN')}
                  onBlur={handleBlur}
                  error={!!errors.name?.en}
                />
                <FormHelperText id="name.en-error" sx={{ color: caloTheme.palette.red }}>
                  {langAlert.EN && langAlert.value === 'name.en' && 'letters should be in English only'}
                </FormHelperText>
              </Stack>
              <Stack sx={{ width: '50%' }}>
                <TextField
                  label="Name AR*"
                  name="name.ar"
                  id="name.ar"
                  placeholder="Enter AR-Name"
                  value={values.name.ar}
                  sx={{ width: '100%' }}
                  onBlur={handleBlur}
                  error={!!errors.name?.ar}
                  disabled={!userRoles.includes(Permission.UPDATE_INGREDIENT) || Boolean(isDisabled)}
                  onChange={(data) => handleNameFieldChange('name.ar', data.target.value, 'AR')}
                />
                <FormHelperText id="name.en-error" sx={{ color: caloTheme.palette.red }}>
                  {langAlert.AR && langAlert.value === 'name.ar' && 'letters should be in Arabic only'}
                </FormHelperText>
              </Stack>
            </Stack>
            <TextField
              label="Internal name"
              value={values.internalName}
              name="internalName"
              sx={{ width: '100%' }}
              onChange={handleChange}
              onBlur={handleBlur}
              error={!!errors.internalName}
              disabled={!userRoles.includes(Permission.UPDATE_INGREDIENT) || Boolean(isDisabled)}
            />
          </Stack>
        </Stack>
      </Card>
    </InputThemeProvider>
  );
};

export default IngredientInformation;
