import { caloThemeV2 } from 'assets/themev2';

export const styles = {
  dialogContainer: {
    '& .MuiDialog-paper': {
      width: '30.4%',
      maxWidth: '640px'
    }
  },
  title: {
    fontSize: '20px',
    fontWeight: 500,
    lineHeight: '150%'
  },
  description: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '150%'
  },
  dialogContentContainer: (isSuccess: boolean) => ({
    borderTop: isSuccess ? 'none' : `1px solid ${caloThemeV2.palette.divider}`,
    borderBottom: isSuccess ? 'none' : `1px solid ${caloThemeV2.palette.divider}`
  }),
  syncInfoTitle: {
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '150%'
  },
  checkAttributesGrid: {
    marginTop: '16px',
    justifyContent: 'space-between'
  },
  validationResultText: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '150%'
  }
};
