import { SyncFoodComponentReq } from '@calo/dashboard-types';
import { Kitchen } from '@calo/types';
import { Box } from '@mui/material-v6';
import { FormikErrors } from 'formik';

import CheckAttributes from './CheckAttributes';
import KitchensDropdown from './KitchensDropdown';

interface SelectSyncOptionsProps {
  values: SyncFoodComponentReq;
  currentKitchen: Kitchen;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<void> | Promise<FormikErrors<SyncFoodComponentReq>>;
}

const SelectSyncOptions = ({ values, currentKitchen, setFieldValue }: SelectSyncOptionsProps) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', py: '14px' }}>
      <CheckAttributes values={values} setFieldValue={setFieldValue} />
      <KitchensDropdown values={values} setFieldValue={setFieldValue} currentKitchen={currentKitchen} />
    </Box>
  );
};

export default SelectSyncOptions;
