import { forwardRef, useEffect, useMemo, useState } from 'react';

import { FormikErrors } from 'formik';
import { compact, keyBy } from 'lodash';
import { useQuery } from 'react-query';

import AddIcon from '@mui/icons-material/Add';
import { Button, Card, Divider, Stack, Typography } from '@mui/material-v6';

import IngredientPickerMUI from 'components/MUI/IngredientPickerMUI';
import { ProcessingStage } from 'lib/calo-dashboard-types';
import { calculatePurchasingCost } from 'lib/helpers';
import { BaseOmit, FoodComponent, FoodComponentWithQuantity, Ingredient, IngredientHeaderItem } from 'lib/interfaces';
import { findNumberOfIngredientHeaders } from 'views/Food/ExactFoodComponent/helpers';
import { getListWithParams } from '../../../actions';
import { styles } from './styles';

interface IngredientListProps {
  values: Omit<FoodComponent, BaseOmit>;
  prototype?: boolean;
  childComponents: FoodComponent[];
  structuredIngredients: IngredientHeaderItem[];
  setValues: (
    values: React.SetStateAction<Omit<FoodComponent, BaseOmit>>,
    shouldValidate?: boolean | undefined
  ) => Promise<void> | Promise<any>;
  setStructuredIngredients: React.Dispatch<React.SetStateAction<IngredientHeaderItem[]>>;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<void> | Promise<FormikErrors<Omit<FoodComponent, BaseOmit>>>;
}

const IngredientListCard = forwardRef<HTMLDivElement, IngredientListProps>(
  (
    {
      values,
      prototype,
      childComponents,
      structuredIngredients,
      setFieldValue,
      setValues,
      setStructuredIngredients
    }: IngredientListProps,
    ref
  ) => {
    const [ingredientName, setIngredientName] = useState('');

    const { data: ingData } = useQuery<any, Error, { data: Ingredient[] }>(
      [
        'ingredients',
        {
          filters: {
            name: ingredientName,
            country: values.country,
            brand: values.brand,
            kitchen: values.kitchen,
            stage: ProcessingStage.approved
          }
        }
      ],
      getListWithParams,
      {
        enabled: !!ingredientName,
        keepPreviousData: true
      }
    );

    const { data: prototypeIngData } = useQuery<any, Error, { data: Ingredient[] }>(
      [
        'ingredients/prototype',
        { filters: { name: ingredientName, country: values.country, brand: values.brand, kitchen: values.kitchen } }
      ],
      getListWithParams,
      {
        enabled: !!prototype && !!ingredientName,
        keepPreviousData: true
      }
    );

    const newIngredient = useMemo(
      () => keyBy([...(ingData?.data ?? []), ...(prototypeIngData?.data ?? [])], 'id'),
      [ingData, prototypeIngData]
    );
    const existingIngredient = useMemo(() => keyBy(values?.ingredients, 'id'), [values?.ingredients]);

    const handleIngredientsChange = (rows: Ingredient[]) => {
      const childrenWithQuantity: FoodComponentWithQuantity[] = childComponents.map((child) => ({
        ...child,
        quantity: (values.childComponents ?? []).find((cc) => cc.id === child.id)?.quantity ?? 0
      }));
      setValues({
        ...values,
        ingredients: rows,
        purchasingCost: +calculatePurchasingCost(rows.length > 0 ? rows : [], childrenWithQuantity, values.cookedRawFactor || 1)
      });
    };

    const addHeaderClickHandler = () => {
      const numberOfHeaders = findNumberOfIngredientHeaders(structuredIngredients);
      setStructuredIngredients((prev) => [{ type: 'header', header: `Header  ${numberOfHeaders + 1}` }, ...prev]);
    };

    useEffect(() => {
      const ingredients = compact(structuredIngredients.map((ing) => ing.type === 'ingredient' && ing.ingredient));

      const childrenWithQuantity: FoodComponentWithQuantity[] = childComponents.map((child) => ({
        ...child,
        quantity: (values.childComponents ?? []).find((cc) => cc.id === child.id)?.quantity ?? 0
      }));
      setFieldValue(
        'purchasingCost',
        (values.weight ?? 1) *
          calculatePurchasingCost(ingredients.length > 0 ? ingredients : [], childrenWithQuantity, values.cookedRawFactor || 1)
      );
    }, [structuredIngredients, values.cookedRawFactor, values.childComponents, values.weight, childComponents]);

    return (
      <Card variant="outlined" sx={styles.card} ref={ref}>
        <Stack sx={styles.headingContainer}>
          <Typography sx={styles.title}>Ingredients</Typography>
          <Button startIcon={<AddIcon />} variant="text" color="primary" size="large" onClick={addHeaderClickHandler}>
            Add Header
          </Button>
        </Stack>
        <Divider sx={styles.divider} />
        <IngredientPickerMUI
          isDisabled={false}
          ingredients={values.ingredients}
          structuredIngredients={structuredIngredients}
          setStructuredIngredients={setStructuredIngredients}
          list={[...(ingData?.data ?? []), ...(prototypeIngData?.data ?? [])]}
          newIngredient={newIngredient}
          ingredientName={ingredientName}
          setFieldValue={setFieldValue}
          onChange={handleIngredientsChange}
          setIngredientName={setIngredientName}
          existingIngredient={existingIngredient}
        />
      </Card>
    );
  }
);

export default IngredientListCard;
