import { FormikBag, FormikProps, withFormik } from 'formik';

import { Box, Button, Stack, TextField, Typography } from '@mui/material';

import { caloTheme } from 'assets/images/theme/calo';
import { AddLoyaltyPointsRequest } from './types';

export interface AddLoyaltyPointsFormProps {
  onSubmit: (values: AddLoyaltyPointsRequest) => void;
  onCancel: () => void;
  userName: string;
  userId: string;
}

const AddLoyaltyPointsForm = ({
  values,
  submitForm,
  handleSubmit,
  handleChange,
  onCancel,
  isValid,
  dirty,
  userName
}: AddLoyaltyPointsFormProps & FormikProps<AddLoyaltyPointsRequest>) => {
  return (
    <Box>
      <Stack>
        <Typography fontSize={16} sx={{ marginBottom: '16px' }}>
          Enter the amount of loyalty points you want to give to <Typography fontWeight={700}>{userName}</Typography>
        </Typography>
      </Stack>
      <form onSubmit={handleSubmit}>
        <Stack>
          <TextField
            type="number"
            name="amount"
            label="Amount"
            value={values.amount}
            onChange={handleChange}
            id="extra-points-amount"
            required={true}
            sx={{
              marginY: '24px'
            }}
          />
        </Stack>
        <Stack flexDirection={'row'} justifyContent={'right'}>
          <Box display={'flex'} flexDirection={'row'} gap="8px" width={'250px'}>
            <Button
              variant="text"
              fullWidth
              aria-label="cancelAddLoyaltyPoints"
              sx={{
                fontWeight: 600,
                fontSize: '14px',
                backgroundColor: caloTheme.palette.white,
                color: caloTheme.palette.primary600,
                '&:hover': {
                  backgroundColor: caloTheme.palette.white
                }
              }}
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Button
              data-test="confirmButton"
              variant="contained"
              fullWidth
              size="medium"
              disabled={!dirty || !isValid}
              aria-label="confirmAddLoyaltyPoints"
              sx={{
                lineHeight: '17px',
                fontWeight: 600,
                fontSize: '14px',
                borderRadius: '8px',
                borderColor: '#24A170',
                backgroundColor: '#24A170',
                color: 'White'
              }}
              onClick={submitForm}
            >
              Add Points
            </Button>
          </Box>
        </Stack>
      </form>
    </Box>
  );
};

export default withFormik<AddLoyaltyPointsFormProps, AddLoyaltyPointsRequest>({
  mapPropsToValues: (props) => ({
    amount: 0,
    userId: props.userId
  }),
  validate: (values: AddLoyaltyPointsRequest) => {
    const errors: any = {};
    if (values.amount <= 0) {
      errors.amount = true;
    }
    return errors;
  },
  handleSubmit: async (values, { props }: FormikBag<AddLoyaltyPointsFormProps, AddLoyaltyPointsRequest>) => {
    try {
      await props.onSubmit(values);
    } catch (error) {
      console.error(error);
    }
  }
})(AddLoyaltyPointsForm);
