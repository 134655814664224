import { Kitchen } from '@calo/types';
import { Box, Button, Stack, Typography } from '@mui/material-v6';
import { DateCalendar, LocalizationProvider } from '@mui/x-date-pickers-v6';
import { AdapterDayjs } from '@mui/x-date-pickers-v6/AdapterDayjs';
import { caloThemeV2 } from 'assets/themev2';
import { ModalRef } from 'components/Modal';
import Popup from 'components/Popup';
import { subDays } from 'date-fns';
import { format } from 'date-fns/fp';
import dayjs from 'dayjs';
import React, { useEffect, useRef } from 'react';

interface DuplicateDailyMenuCalendarPopupProps {
  kitchen: Kitchen;
  showFoodWarning?: boolean;
  duplicateMenuDate: Date;
  disableFutureDays?: boolean;
  deletedDuplicatedMenuFood: string[];
  isDuplicateMenuLoading: boolean;
  duplicateCalendarRef: React.MutableRefObject<ModalRef | undefined>;
  duplicateHandler: () => void;
  handleClosePopup: () => void;
  setDuplicateMenuDate: React.Dispatch<React.SetStateAction<Date>>;
}

const shouldDisableDate = (day: Date, kitchen: Kitchen) => {
  let isDisabled = new Date() < day || day < subDays(new Date(), 30);
  switch (kitchen) {
    case Kitchen.OM1:
      isDisabled = true;
      break;
    default:
      isDisabled = false;
      break;
  }
  return isDisabled;
};

const DuplicateDailyMenuCalendarPopup = ({
  kitchen,
  duplicateMenuDate,
  showFoodWarning,
  disableFutureDays,
  duplicateCalendarRef,
  isDuplicateMenuLoading,
  deletedDuplicatedMenuFood,
  handleClosePopup,
  setDuplicateMenuDate,
  duplicateHandler
}: DuplicateDailyMenuCalendarPopupProps) => {
  const confirmDuplicateRef = useRef<ModalRef>();

  const duplicateMenuHandler = () => {
    duplicateHandler();
  };

  useEffect(() => {
    if (!isDuplicateMenuLoading && deletedDuplicatedMenuFood.length === 0) {
      duplicateCalendarRef.current?.close();
      confirmDuplicateRef.current?.close();
    }
  }, [isDuplicateMenuLoading]);

  return (
    <>
      <Popup maxWidth="sm" ref={duplicateCalendarRef} onClose={handleClosePopup}>
        <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>Duplicate from</Typography>
        <Stack sx={{ width: '320px' }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateCalendar
              value={dayjs(duplicateMenuDate)}
              shouldDisableDate={(day: dayjs.Dayjs) => {
                let disabled = false;
                if (disableFutureDays && day.toDate() > new Date()) {
                  disabled = true;
                }
                return shouldDisableDate(day.toDate(), kitchen) || disabled;
              }}
              onChange={(day: dayjs.Dayjs) => {
                setDuplicateMenuDate(day.toDate());
              }}
            />
          </LocalizationProvider>
          <Stack sx={{ flexDirection: 'row', alignItems: 'center', alignSelf: 'flex-end', gap: 2 }}>
            <Button
              variant="text"
              onClick={() => {
                duplicateCalendarRef.current?.close();
                confirmDuplicateRef.current?.open();
              }}
            >
              Select
            </Button>
            <Button sx={{ color: caloThemeV2.palette.text.primary }} onClick={handleClosePopup}>
              Cancel
            </Button>
          </Stack>
        </Stack>
      </Popup>
      <Popup
        maxWidth="lg"
        ref={confirmDuplicateRef}
        onClose={() => {
          if (!isDuplicateMenuLoading) {
            confirmDuplicateRef.current?.close();
          }
        }}
      >
        {deletedDuplicatedMenuFood.length === 0 && (
          <Box sx={{ display: 'flex', flexDirection: 'column', width: '700px' }}>
            <Stack sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <Typography variant="h6" sx={{ fontSize: '28px', fontWeight: 600 }}>
                Duplicating from {format('EEE dd/MM/yyyy', duplicateMenuDate)}
              </Typography>
            </Stack>
            <Typography sx={{ fontSize: '16px', marginTop: '10px' }}>
              You are duplicating the menu of {format('EEEE dd/MM/yyyy', duplicateMenuDate)}. Are you sure you want to proceed?
              <br />
              {showFoodWarning && 'This will clear your already selected Meals'}
            </Typography>
            <Stack sx={{ flexDirection: 'row', gap: 2, alignSelf: 'flex-end', marginTop: 2 }}>
              <Button
                variant="outlined"
                onClick={() => {
                  duplicateMenuHandler();
                }}
                disabled={isDuplicateMenuLoading}
              >
                Duplicate
              </Button>
              <Button
                sx={{ color: caloThemeV2.palette.text.primary }}
                disabled={isDuplicateMenuLoading}
                onClick={() => {
                  confirmDuplicateRef.current?.close();
                }}
              >
                Cancel
              </Button>
            </Stack>
          </Box>
        )}
        {deletedDuplicatedMenuFood.length > 0 && (
          <Stack sx={{ gap: 2 }}>
            <Typography sx={{ fontWeight: 500, fontSize: 20 }}>
              The following items have been removed and will no longer appear on the menu
            </Typography>
            {deletedDuplicatedMenuFood.map((food) => (
              <Typography sx={{ marginBottom: 3, marginLeft: 3 }}>{food}</Typography>
            ))}
            <Button
              sx={{ color: caloThemeV2.palette.text.primary }}
              disabled={isDuplicateMenuLoading}
              onClick={() => {
                handleClosePopup();
                duplicateCalendarRef.current?.close();
                confirmDuplicateRef.current?.close();
              }}
            >
              Okay
            </Button>
          </Stack>
        )}
      </Popup>
    </>
  );
};

export default DuplicateDailyMenuCalendarPopup;
