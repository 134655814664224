import { ProcessingStage } from '@calo/dashboard-types';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItem,
  Skeleton,
  Typography
} from '@mui/material';
import { caloTheme } from 'assets/images/theme/calo';
import { FoodStatus } from 'lib/enums';
import { startCase } from 'lodash-es';
import React from 'react';

const styles = {
  dialogContainer: {
    '& .MuiDialog-paper': {
      width: '30.4%',
      maxWidth: '640px'
    }
  },
  title: {
    fontSize: '20px',
    fontWeight: 500,
    lineHeight: '150%'
  },
  description: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '150%'
  },
  dialogContentContainer: (hasPrototypeComponents: boolean) => ({
    borderTop: hasPrototypeComponents ? `1px solid ${caloTheme.palette.divider}` : undefined,
    borderBottom: hasPrototypeComponents ? `1px solid ${caloTheme.palette.divider}` : undefined
  }),
  componentListItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    px: 0
  },
  componentTitle: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '150%',
    color: caloTheme.palette.textPrimary
  },
  componentType: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '150%',
    color: caloTheme.palette.textSecondary
  },
  cancelButton: {
    color: caloTheme.palette.black
  }
};

interface MealApprovalDialogProps {
  open: boolean;
  handleClose: () => void;
  handleSubmit: () => void;
  isPositiveButtonLoading?: boolean;
  prototypeItems?: { id: string; name: string; type: string }[];
  status?: ProcessingStage | FoodStatus;
  isLoading?: boolean;
  type: 'component' | 'meal' | 'ingredient';
}

export const MealApprovalDialog: React.FC<MealApprovalDialogProps> = ({
  open,
  handleClose,
  handleSubmit,
  isPositiveButtonLoading,
  prototypeItems,
  status,
  isLoading,
  type
}) => {
  const getDialogText = () => {
    switch (status) {
      case FoodStatus.draft:
        return {
          title: `Submit ${type} for approval`,
          description: 'You won’t be able to make changes to the recipe after submitting.'
        };
      case FoodStatus.awaitingNPDApproval:
        return {
          title: `Submit ${type} for final approval`,
          description: 'You won’t be able to make changes to the recipe after submitting.'
        };
      default:
        return {
          title: `Release it to the ${startCase(type)}'s dashboard?`,
          description:
            "This meal will move to Food database and will no longer exist in Chef's Playground. Are you sure you want to release it?"
        };
    }
  };

  const { title, description } = getDialogText();
  const hasPrototypeItems = !!prototypeItems?.length;
  const showProceedButton = !(hasPrototypeItems && status === FoodStatus.awaitingOpsApproval);

  return (
    <Dialog
      sx={styles.dialogContainer}
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle sx={styles.title}>
        {isLoading ? <Skeleton width="60%" /> : hasPrototypeItems ? `Items missing from ${startCase(type)}’s dashboard` : title}
      </DialogTitle>
      <DialogContent sx={styles.dialogContentContainer(hasPrototypeItems)}>
        {isLoading ? (
          <List>
            {Array.from({ length: 3 }).map((_, index) => (
              <ListItem key={index} sx={styles.componentListItem}>
                <Skeleton width="80%" />
                <Skeleton width="60%" />
              </ListItem>
            ))}
          </List>
        ) : hasPrototypeItems ? (
          <List>
            {prototypeItems.map((item) => (
              <ListItem key={item.id} sx={styles.componentListItem}>
                <Typography variant="body1" sx={styles.componentTitle}>
                  {item.name || 'Unnamed Item'}
                </Typography>
                <Typography variant="body2" sx={styles.componentType}>
                  {item.type}
                </Typography>
              </ListItem>
            ))}
          </List>
        ) : (
          <DialogContentText sx={styles.description}>{description}</DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        {showProceedButton && (
          <LoadingButton
            variant={hasPrototypeItems ? 'text' : 'outlined'}
            onClick={handleSubmit}
            autoFocus
            loading={isLoading || isPositiveButtonLoading}
          >
            {hasPrototypeItems ? 'Proceed Anyways' : 'SUBMIT'}
          </LoadingButton>
        )}
        <Button sx={styles.cancelButton} onClick={handleClose}>
          {hasPrototypeItems ? 'Close' : 'CANCEL'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
