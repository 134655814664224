import { useEffect, useState } from 'react';

import ComponentCostInfo from 'components/ComponentCostInfo';
import InputThemeProvider from 'components/MUI/InputThemeProvider';
import { FormikErrors } from 'formik';
import { getLastUpdatedPurchasingCostDate } from 'lib/helpers';
import { FoodComponent, FoodComponentWithQuantity } from 'lib/interfaces';
import { round } from 'lodash-es';
import { CostInfo, showCostInfoHandler } from 'views/Food/helpers';

import { FoodComponentStation, FoodComponentStatus, MeasurementUnit } from '@calo/dashboard-types';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Card, Divider, IconButton, InputAdornment, InputLabel, MenuItem, Stack, TextField, Typography } from '@mui/material';
import { FoodComponentWithSellingPrice } from 'views/Food/ExactFoodComponent/useFoodComponentForm';
import { styles } from './styles';

interface ComponentInformationProps {
  values: FoodComponentWithSellingPrice;
  errors: FormikErrors<FoodComponentWithSellingPrice>;
  childComponents: FoodComponent[];
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<FormikErrors<FoodComponentWithSellingPrice>> | Promise<any>;
  handleBlur: { (e: React.FocusEvent<any, Element>): void };
  handleChange: { (e: React.ChangeEvent<any>): void };
  handleChangeForNumRestrictions: (event: React.ChangeEvent<any>) => void;
  isDisabled?: boolean;
  handleSubmitSellingPrice?: (value: number) => void;
}

const ComponentInformationCard = ({
  values,
  errors,
  childComponents,
  setFieldValue,
  handleChange,
  handleBlur,
  handleChangeForNumRestrictions,
  isDisabled,
  handleSubmitSellingPrice
}: ComponentInformationProps) => {
  const [showCostInfo, setShowCostInfo] = useState(false);
  const [costType, setCostType] = useState(CostInfo.none);

  useEffect(() => {
    if (values.measurementUnit === MeasurementUnit.g) {
      setFieldValue('weight', 1);
    }
  }, [values.measurementUnit]);

  const handleLastUpdatedCost = () => {
    return getLastUpdatedPurchasingCostDate([values as FoodComponent]);
  };

  const costInfoText = () => {
    const childCompsWithQuantity: FoodComponentWithQuantity[] = childComponents.map((child) => ({
      ...child,
      quantity: values.childComponents?.find((comp) => comp.id === child.id)?.quantity ?? 0
    }));
    const info = showCostInfoHandler(
      costType,
      values.ingredients,
      childCompsWithQuantity,
      values.cookedRawFactor,
      values.weight ?? 1,
      values.measurementUnit
    );
    return info;
  };

  return (
    <Card variant="outlined" sx={styles.card}>
      <Typography sx={styles.title}>Additional Information</Typography>
      <Divider sx={styles.divider} />
      <InputThemeProvider>
        <Stack sx={styles.container}>
          <Stack sx={styles.columnContainer}>
            <Stack sx={styles.rowContainer}>
              <TextField
                data-test="costPerUnitInput"
                label="Cost per unit (Recipe Sheet)*"
                value={values.cost}
                name="cost"
                onChange={handleChangeForNumRestrictions}
                onBlur={handleBlur}
                type="number"
                disabled={isDisabled}
                error={!!errors.cost}
                sx={styles.textField}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => {
                          setShowCostInfo(true);
                          setCostType(CostInfo.cost);
                        }}
                      >
                        <InfoOutlinedIcon fontSize="small" />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              <Stack sx={styles.textFieldContainer}>
                <TextField
                  data-test="costPerUnitPrecoroInput"
                  label="Cost per unit (Precoro)"
                  value={round(values.purchasingCost ?? 0, 6)}
                  name="purchasingCost"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="number"
                  disabled
                  error={!!errors.purchasingCost}
                  sx={styles.textField}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => {
                            setShowCostInfo(true);
                            setCostType(CostInfo.purchasingCost);
                          }}
                          disabled={!values.purchasingCost}
                        >
                          <InfoOutlinedIcon fontSize="small" />
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
                <InputLabel sx={styles.inputLabel}>{`last updated at: ${handleLastUpdatedCost()}`}</InputLabel>
              </Stack>
              <TextField
                data-test="cookedWeightInput"
                label="Cooked Weight (g)"
                value={values.weight}
                name="weight"
                onChange={handleChange}
                onBlur={handleBlur}
                type="number"
                disabled={isDisabled || values.measurementUnit === MeasurementUnit.g}
                sx={styles.textField}
              />
              <TextField
                data-test="cookedRawFactorInput"
                label="Cooked > Raw Factor"
                value={values.cookedRawFactor}
                name="cookedRawFactor"
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={isDisabled}
                sx={styles.textField}
              />
              <TextField
                type="number"
                name="sellingPrice"
                label="Selling Price"
                value={values.sellingPrice}
                onChange={(e) => handleSubmitSellingPrice?.(Number(e.target.value))}
                disabled={isDisabled}
                sx={styles.textField}
                fullWidth
                error={!!errors.sellingPrice}
                helperText={errors.sellingPrice}
              />
            </Stack>
            {showCostInfo && (
              <ComponentCostInfo
                costInfo={costInfoText()}
                handleClose={() => {
                  setShowCostInfo(false);
                }}
              />
            )}
          </Stack>
          <Stack sx={styles.rowContainer}>
            <TextField
              data-test="measurementUnitSelector"
              select
              label="Measurement Unit"
              value={values.measurementUnit}
              placeholder="Select Unit"
              sx={styles.textField}
              disabled={isDisabled}
              onChange={(data: any) => setFieldValue('measurementUnit', data.target.value)}
              InputProps={{ inputProps: styles.inputProps, style: styles.inputStyle }}
            >
              {Object.values(MeasurementUnit).map((unit) => (
                <MenuItem key={unit} value={unit}>
                  {unit}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              data-test="stationSelector"
              select
              label="Station"
              value={values.cookingStation}
              SelectProps={{
                multiple: true,
                value: values.cookingStation,
                onChange: (e) => setFieldValue('cookingStation', e.target.value),
                renderValue: (selected) => {
                  return (selected as string[]).map((station) => FoodComponentStation[station]).join(', ');
                }
              }}
              sx={styles.textField}
              disabled={isDisabled}
              InputProps={{ inputProps: styles.inputProps, style: styles.inputStyle }}
            >
              {Object.entries(FoodComponentStation).map(([key, value]) => (
                <MenuItem key={key} value={key}>
                  {value}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              data-test="statusSelector"
              select
              name="status"
              label="Recipe Status"
              value={values.status}
              sx={styles.textField}
              disabled={isDisabled}
              onChange={(data: any) => setFieldValue('status', data.target.value)}
              InputProps={{ inputProps: styles.inputProps, style: styles.inputStyle }}
            >
              {Object.entries(FoodComponentStatus).map(([key, value]) => (
                <MenuItem key={key} value={key}>
                  {value}
                </MenuItem>
              ))}
            </TextField>
          </Stack>
        </Stack>
      </InputThemeProvider>
    </Card>
  );
};

export default ComponentInformationCard;
