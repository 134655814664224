import { Brand, Country, Kitchen } from '@calo/types';
import { format } from 'date-fns/fp';
import { useFormik } from 'formik';
import { mealTracking } from 'lib/helpers';
import { Menu } from 'lib/interfaces';
import { MenuListFilters } from '../types';

export default (onSubmit: (values: Menu) => Promise<void>, filters: MenuListFilters) =>
  useFormik<Menu>({
    enableReinitialize: true,
    initialValues: {
      id: format('yyyy-MM-dd')(Date.now()),
      food: [],
      day: filters.selectedDay ?? format('yyyy-MM-dd')(Date.now()),
      country: filters.country ?? Country.BH,
      brand: filters.brand ?? Brand.CALO,
      kitchen: filters.kitchen ?? Kitchen.BH1,
      draft: mealTracking as any
    },

    validate: (values: Menu) => {
      const errors: any = {};
      if (Object.values(values.food).length === 0) {
        errors.food = true;
      }

      if (!values.day) {
        errors.day = true;
      }
      if (!values.country) {
        errors.country = true;
      }

      return errors;
    },
    onSubmit: async (values) => {
      try {
        await onSubmit(values);
      } catch (error) {
        console.log(error);
      }
    }
  });
