import { Card, Typography } from '@mui/material';
import { caloTheme } from 'assets/images/theme/calo';

export interface OrderInformationCardProps {
  name: string;
  value: string | number;
}

export default function OrderInformationCard({ name, value }: OrderInformationCardProps) {
  return (
    <Card
      sx={{
        borderRadius: '12px',
        border: `1px solid ${caloTheme.palette.neutral300}`,
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: { xs: '100%', md: '260px' },
        height: '104px',
        flex: 1
      }}
    >
      <Typography
        sx={{
          fontSize: '14px',
          fontWeight: 600,
          color: caloTheme.palette.neutral800
        }}
      >
        {name}
      </Typography>
      <Typography
        sx={{
          fontSize: '24px',
          fontWeight: 400
        }}
      >
        {value}
      </Typography>
    </Card>
  );
}
