import { FoodType } from '@calo/types';
import SortIcon from '@mui/icons-material/Sort';
import { Stack } from '@mui/material-v6';
import { caloThemeV2 } from 'assets/themev2';
import { MenuFood } from 'lib/interfaces';
import { startCase } from 'lodash-es';

interface SectionHeaderProps {
  value: FoodType;
  groupByName: MenuFood[];
  headerOpen?: FoodType;
  handleFilterClose: (key: string, value: FoodType, k: string) => void;
  setHeaderOpen: React.Dispatch<React.SetStateAction<FoodType | undefined>>;
}

const SectionHeader = ({ value, headerOpen, groupByName, setHeaderOpen, handleFilterClose }: SectionHeaderProps) => {
  return (
    <header
      style={{
        height: value === headerOpen ? 'auto' : '',
        backgroundColor: caloThemeV2.palette.grey[200],
        color: caloThemeV2.palette.text.primary,
        borderRadius: '8px',
        cursor: 'pointer',
        padding: '10px 16px'
      }}
      onClick={() => (headerOpen === value ? (setHeaderOpen(undefined), handleFilterClose('', value, '')) : setHeaderOpen(value))}
    >
      <Stack
        sx={{
          width: '100%',
          flexDirection: 'row',
          gap: 2,
          fontSize: '14px',
          fontWeight: 500,
          alignItems: 'center'
        }}
      >
        {`${value === FoodType.lunch ? 'Lunch & Dinner' : startCase(value)} (${
          groupByName.filter((f) => f.type.includes(FoodType[value])).length
        })`}
        <SortIcon />
      </Stack>
    </header>
  );
};

export default SectionHeader;
