import { BaseFoodTags, FoodCategory, ProteinFoodTags, TasteFoodTags } from '@calo/dashboard-types';
import { FoodDietType, FoodMenuLabelsTags, FoodType } from '@calo/types';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button as MUIButton, MenuItem, Stack, TextField, Typography } from '@mui/material-v6';
import { caloTheme } from 'assets/images/theme/calo';
import { format } from 'date-fns';
import { startCase } from 'lodash-es';
import { useMemo } from 'react';
import { DailyMenuDatePicker } from 'views/Menu/MainMenu/components';
import { selectedMenuFilters } from './AddMealsInterfaces';
import { styles } from './styles';

interface FiltersSectionProps {
  selectedMenuFilters: selectedMenuFilters;
  setSelectedMenuFilters: (value: selectedMenuFilters) => void;
  searchTerm: string;
  setSearchTerm: (value: string) => void;
}

const FiltersSection = ({ selectedMenuFilters, setSelectedMenuFilters, searchTerm, setSearchTerm }: FiltersSectionProps) => {
  const foodTypeOptions = useMemo(() => Object.values(FoodType), []);
  const foodDietTypeOptions = useMemo(() => Object.values(FoodDietType), []);
  const proteinFoodTagsOptions = useMemo(() => Object.values(ProteinFoodTags), []);
  const tasteFoodTagsOptions = useMemo(() => Object.values(TasteFoodTags), []);
  const baseFoodTagsOptions = useMemo(() => Object.values(BaseFoodTags), []);
  const foodCategoryOptions = useMemo(() => ['Any', ...Object.values(FoodCategory)], []);

  const handleChange = (field: keyof selectedMenuFilters) => (event: any) => {
    const value = event.target.value === 'Any' ? undefined : event.target.value;
    setSelectedMenuFilters({ ...selectedMenuFilters, [field]: value });
  };

  return (
    <Box display="flex" flexDirection="column" sx={styles.mainContainer}>
      <Stack sx={{ flexDirection: 'row', gap: 2 }}>
        <TextField sx={styles.textField} select label="Type" onChange={handleChange('type')}>
          <MenuItem key="Any" value="Any">
            {startCase('Any')}
          </MenuItem>
          {foodTypeOptions.map((type) => (
            <MenuItem key={type} value={type}>
              {startCase(type)}
            </MenuItem>
          ))}
        </TextField>
        <TextField sx={styles.textField} select label="Plan" onChange={handleChange('plan')}>
          <MenuItem key="Any" value="Any">
            {startCase('Any')}
          </MenuItem>
          {foodDietTypeOptions.map((plan) => (
            <MenuItem key={plan} value={plan}>
              {startCase(plan)}
            </MenuItem>
          ))}
        </TextField>

        <TextField sx={styles.textField} select label="Category" onChange={handleChange('category')}>
          {foodCategoryOptions.map((plan) => (
            <MenuItem key={plan} value={plan}>
              {startCase(plan)}
            </MenuItem>
          ))}
        </TextField>

        <TextField sx={styles.textField} select label="Menu Label" onChange={handleChange('menuLabel')}>
          {Object.values(FoodMenuLabelsTags).map((plan) => (
            <MenuItem key={plan} value={plan}>
              {startCase(plan)}
            </MenuItem>
          ))}
        </TextField>
      </Stack>

      <Stack sx={{ flexDirection: 'row', gap: 2, marginTop: 2 }}>
        <TextField sx={styles.textField} select label="Protein" onChange={handleChange('protein')}>
          <MenuItem key="Any" value="Any">
            {startCase('Any')}
          </MenuItem>
          {proteinFoodTagsOptions.map((protein) => (
            <MenuItem key={protein} value={protein}>
              {startCase(protein)}
            </MenuItem>
          ))}
        </TextField>

        <TextField sx={styles.textField} select label="Taste" onChange={handleChange('taste')}>
          <MenuItem key="Any" value="Any">
            {startCase('Any')}
          </MenuItem>
          {tasteFoodTagsOptions.map((taste) => (
            <MenuItem key={taste} value={taste}>
              {startCase(taste)}
            </MenuItem>
          ))}
        </TextField>

        <TextField sx={styles.textField} select label="Base" onChange={handleChange('base')}>
          <MenuItem key="Any" value="Any">
            {startCase('Any')}
          </MenuItem>
          {baseFoodTagsOptions.map((base) => (
            <MenuItem key={base} value={base}>
              {startCase(base)}
            </MenuItem>
          ))}
        </TextField>

        <TextField sx={styles.textField} select label="Sandwich" onChange={handleChange('sandwich')}>
          {[
            { label: 'Any', value: 'Any' },
            { label: 'Yes', value: 'Yes' },
            { label: 'No', value: 'No' }
          ].map((sandwich) => (
            <MenuItem key={sandwich.label} value={sandwich.value}>
              {startCase(sandwich.label)}
            </MenuItem>
          ))}
        </TextField>
      </Stack>

      <Stack sx={{ width: '220px', marginTop: 2 }}>
        <DailyMenuDatePicker
          setSelectedDay={(date) => setSelectedMenuFilters({ ...selectedMenuFilters, lastUsed: format(date, 'yyyy-MM-dd') })}
          placeholder="Last used (optional)"
          selectedDay={selectedMenuFilters.lastUsed}
        />
      </Stack>

      {searchTerm && (
        <Stack direction="row" alignItems="center" sx={{ ...styles.searchTermStyle, width: searchTerm.length * 10 + 60 }}>
          <Typography>{searchTerm}</Typography>
          <MUIButton
            onClick={() => {
              setSelectedMenuFilters({ ...selectedMenuFilters, category: '' });
              setSearchTerm('');
            }}
            sx={{ paddingLeft: '0px' }}
          >
            <CloseIcon sx={{ color: caloTheme.palette.secondaryBlue700 }} />
          </MUIButton>
        </Stack>
      )}
    </Box>
  );
};

export default FiltersSection;
