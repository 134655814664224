import React, { useState } from 'react';
import { Box, InputLabel, MenuItem, Select, FormHelperText, FormControl, TextField, CircularProgress } from '@mui/material-v6';
import { SelectChangeEvent } from '@mui/material-v6';
import { Autocomplete } from '@mui/material-v6';
import debounce from 'lodash-es/debounce';
import { isArray } from 'lodash-es';
interface Option<T> {
  label: string;
  value: T;
}
interface SelectProps<T = string | undefined> {
  label?: string;
  placeholder?: string;
  value?: T | T[];
  hidden?: boolean;
  error?: boolean;
  allowDuplicates?: boolean;
  disabled?: boolean;
  multiSelect?: boolean;
  enableSearch?: boolean;
  required?: boolean;
  options: Option<T>[];
  onChange: (value: T | T[]) => void;
  fetchOptions?: (input: string) => Promise<void>;
}

const SelectMUI = <T extends string | undefined>({
  label,
  placeholder,
  value,
  options,
  hidden = false,
  error = false,
  allowDuplicates,
  disabled = false,
  multiSelect = false,
  enableSearch = false,
  required = false,
  fetchOptions,
  onChange
}: SelectProps<T>) => {
  const [loading, setLoading] = useState(false);

  const handleSelectChange = (event: SelectChangeEvent<T | T[]>) => {
    const selectedValue = event.target.value as T | T[];
    onChange(selectedValue);
  };
  const handleAutocompleteChange = (_: React.SyntheticEvent, newValue: Option<T>[] | Option<T> | null) => {
    if (multiSelect) {
      onChange((newValue as Option<T>[]).map((option) => option.value));
    } else {
      onChange((newValue as Option<T>)?.value || ('' as T));
    }
  };
  const handleInputChange = debounce(async (inputValue: string) => {
    if (fetchOptions && inputValue) {
      setLoading(true);
      try {
        await fetchOptions(inputValue);
      } catch (error) {
        console.error('Error fetching options:', error);
      } finally {
        setLoading(false);
      }
    }
  }, 500);
  const renderValue = (selected: T | T[]) => {
    if (!selected || (isArray(selected) && !selected.length)) {
      return placeholder;
    }
    if (isArray(selected)) {
      return selected
        .map((val) => options.find((opt) => opt.value === val)?.label)
        .filter(Boolean)
        .join(', ');
    }

    return options.find((opt) => opt.value === selected)?.label || '';
  };

  return (
    <Box sx={{ visibility: hidden ? 'hidden' : 'visible' }}>
      <FormControl fullWidth error={error}>
        {label && !enableSearch && (
          <InputLabel
            id={`${label}-label`}
            sx={{
              transform: value ? 'translate(14px, -6px) scale(0.75)' : 'translate(14px, 16px) scale(1)',
              transition: 'transform 0.2s ease-in-out',
              backgroundColor: 'white',
              padding: '0 4px',
              marginLeft: '-4px',
              zIndex: 1
            }}
            shrink={Boolean(value)}
          >
            {label}
            {required && <span>*</span>}
          </InputLabel>
        )}
        {enableSearch ? (
          <Autocomplete
            multiple={multiSelect}
            disabled={disabled}
            options={options}
            getOptionLabel={(option) => option.label}
            value={
              multiSelect
                ? options.filter((opt) => (value as T[])?.includes(opt.value))
                : options.find((opt) => opt.value === value) || null
            }
            onChange={handleAutocompleteChange}
            onInputChange={(_, newInputValue) => handleInputChange(newInputValue)}
            loading={loading}
            renderInput={(params) => (
              <TextField
                label={label}
                {...params}
                error={error}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loading && <CircularProgress size={24} />}
                      {params.InputProps.endAdornment}
                    </>
                  )
                }}
              />
            )}
            isOptionEqualToValue={(option, val) => option.value === val.value}
          />
        ) : (
          <Select
            labelId={`${label}-label`}
            multiple={multiSelect}
            disabled={disabled}
            value={allowDuplicates ? value : isArray(value) ? [...new Set(value)] : value || ''}
            onChange={handleSelectChange}
            renderValue={renderValue}
            MenuProps={{ PaperProps: { style: { maxHeight: 200 } } }}
            sx={{
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: error ? 'red' : undefined
              }
            }}
          >
            {options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        )}
        {error && <FormHelperText>Error occurred</FormHelperText>}
      </FormControl>
    </Box>
  );
};
export default SelectMUI;
