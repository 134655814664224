import { caloTheme } from 'assets/images/theme/calo';

export const styles = {
  tableContainer: {
    width: '100%',
    mt: '24px',
    boxShadow: 'none'
  },
  tableHead: {
    backgroundColor: caloTheme.palette.grey200,
    border: 0
  },
  tableHeadText: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '24px'
  },
  cardStack: {
    flexDirection: 'row',
    gap: '8px',
    alignItems: 'center'
  },
  componentImage: {
    width: 40,
    height: 40,
    objectFit: 'cover',
    borderRadius: '4px'
  },
  componentName: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '150%',
    color: caloTheme.palette.primaryMain
  },
  tableBodyCellText: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '150%'
  }
};
