import { ProcessingStage, RetailMenuFoodItem } from '@calo/dashboard-types';
import { FoodComponent } from '@calo/types';
import { getListWithParamsPost } from 'actions';
import { uniqBy } from 'lodash';
import { useMemo, useState } from 'react';
import { useQuery } from 'react-query';

interface UseFoodModifiersProps {
  currentRetailItem: RetailMenuFoodItem;
  setCurrentRetailItem: React.Dispatch<React.SetStateAction<RetailMenuFoodItem>>;
  currentFood: any;
}

export const useFoodModifiers = ({ currentRetailItem, setCurrentRetailItem, currentFood }: UseFoodModifiersProps) => {
  const [currentComponentName, setCurrentComponentName] = useState<string>('salad');
  const foodComponentSample = {
    name: 'salad',
    country: 'BH',
    brand: 'CALO',
    kitchen: 'BH001'
  };

  const {
    data: foodComponentData,
    isLoading: isLoadingFoodComponents,
    isFetching: isFetchingFoodComponents
  } = useQuery<any, Error>(
    [
      'food-components/list',
      {
        filters: {
          name: currentComponentName,
          country: foodComponentSample?.country,
          brand: foodComponentSample?.brand,
          kitchen: foodComponentSample?.kitchen,
          stage: ProcessingStage.approved,
          prototype: false
        },
        limit: 50
      }
    ],
    getListWithParamsPost,
    {
      enabled: !!foodComponentSample?.name,
      keepPreviousData: true
    }
  );

  const componentOptions = useMemo(
    () =>
      foodComponentData?.data
        ? foodComponentData.data.map((component) => ({
            value: component.id,
            label: component.name.en
          }))
        : [],
    [foodComponentData]
  );

  const { data: foodComponentList } = useQuery<any, Error, { data: FoodComponent[] }>(
    [
      'food-components/list',
      {
        filters: {
          ids: currentFood?.components.map((comp) => comp.id) || []
        }
      }
    ],
    getListWithParamsPost,
    {
      enabled: !!currentFood?.components,
      keepPreviousData: true
    }
  );

  const [currentModifierComponent, setCurrentModifierComponent] = useState<{
    modifier: any;
    quantity: number;
  }>({ modifier: null, quantity: 1 });

  const handleAddModifier = () => {
    setCurrentRetailItem((prevItem: any) => {
      if (prevItem) {
        const newModifier = {
          name: { en: '', ar: '' },
          type: '',
          componentOptions: [],
          otherOptions: []
        };
        const updatedModifiers = [...(prevItem.foodModifiers || []), newModifier];
        return { ...prevItem, foodModifiers: updatedModifiers };
      }
      return prevItem;
    });
  };

  const handleRemoveModifier = (index: number) => {
    setCurrentRetailItem((prevItem) => {
      if (prevItem) {
        const updatedModifiers = prevItem.foodModifiers?.filter((_, modIndex) => modIndex !== index) || [];
        return { ...prevItem, foodModifiers: updatedModifiers };
      }
      return prevItem;
    });
  };

  const handleComponentOptionsChange = (selectedOptions, modifier, action, quantity = 1) => {
    console.log({ selectedOptions, modifier, action, quantity });
    const restModifiers = currentRetailItem?.foodModifiers?.filter((mod) => mod.name.en !== modifier.name.en) || [];
    switch (action.action) {
      case 'select-option': {
        const newOptions = selectedOptions.map((option) => ({
          id: option.value,
          name: { en: option.label },
          quantity
        }));
        const componentOptionsSelect = uniqBy([...modifier.componentOptions, ...newOptions], 'id');
        setCurrentRetailItem({
          ...currentRetailItem,
          foodModifiers: [
            ...restModifiers,
            {
              ...modifier,
              componentOptions: componentOptionsSelect
            }
          ]
        });
        setCurrentModifierComponent({ modifier: null, quantity: 1 });
        break;
      }
      case 'remove-value': {
        const componentOptionsRemove = modifier.componentOptions.filter((option) => option.id !== action.removedValue.value);
        setCurrentRetailItem({
          ...currentRetailItem,
          foodModifiers: [
            ...restModifiers,
            {
              ...modifier,
              componentOptions: componentOptionsRemove
            }
          ]
        });
        break;
      }
      case 'clear': {
        setCurrentRetailItem({
          ...currentRetailItem,
          foodModifiers: [
            ...restModifiers,
            {
              ...modifier,
              componentOptions: []
            }
          ]
        });
        break;
      }
      default:
        break;
    }
  };

  return {
    componentOptions,
    foodComponentList,
    isLoadingFoodComponents: isLoadingFoodComponents || isFetchingFoodComponents,
    handleAddModifier,
    handleRemoveModifier,
    handleComponentOptionsChange,
    setCurrentComponentName,
    currentModifierComponent,
    setCurrentModifierComponent
  };
};
