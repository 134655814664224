import { forwardRef, useEffect, useState } from 'react';

import ComponentCostInfo from 'components/ComponentCostInfo';
import InputThemeProvider from 'components/MUI/InputThemeProvider';
import { FormikErrors } from 'formik';
import { useUserRoles } from 'lib/hooks';
import { BaseOmit, FoodComponent, FoodComponentWithQuantity } from 'lib/interfaces';
import { CostInfo, showCostInfoHandler } from 'views/Food/helpers';

import { FoodComponentStation, FoodComponentStatus, MeasurementUnit, Permission } from '@calo/dashboard-types';
import InfoIcon from '@mui/icons-material/Info';
import { Card, Divider, IconButton, InputAdornment, MenuItem, Stack, TextField, Typography } from '@mui/material-v6';
import { styles } from './styles';

interface ComponentInformationProps {
  prototype?: boolean;
  values: Omit<FoodComponent, BaseOmit>;
  errors: FormikErrors<Omit<FoodComponent, BaseOmit>>;
  childComponents: FoodComponent[];
  isDisabled?: boolean;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<FormikErrors<Omit<FoodComponent, BaseOmit>>> | Promise<any>;
  handleBlur: { (e: React.FocusEvent<any, Element>): void };
  handleChange: { (e: React.ChangeEvent<any>): void };
  handleChangeForNumRestrictions: (event: React.ChangeEvent<any>) => void;
}

const ComponentInformationCard = forwardRef<HTMLDivElement, ComponentInformationProps>(
  (
    {
      values,
      errors,
      prototype,
      childComponents,
      isDisabled,
      setFieldValue,
      handleChange,
      handleBlur
    }: ComponentInformationProps,
    ref
  ) => {
    const roles = useUserRoles();

    const [showCostInfo, setShowCostInfo] = useState(false);
    const [costType, setCostType] = useState(CostInfo.none);

    useEffect(() => {
      if (values.measurementUnit === MeasurementUnit.g) {
        setFieldValue('weight', 1);
      }
    }, [values.measurementUnit]);

    const costInfoText = () => {
      const childCompsWithQuantity: FoodComponentWithQuantity[] = childComponents.map((child) => ({
        ...child,
        quantity: values.childComponents?.find((comp) => comp.id === child.id)?.quantity ?? 0
      }));
      const info = showCostInfoHandler(
        costType,
        [...values.ingredients],
        childCompsWithQuantity,
        values.cookedRawFactor,
        values.weight ?? 1
      );
      return info;
    };

    const permission = prototype
      ? roles.includes(Permission.CREATE_PROTOTYPE_COMPONENT)
      : roles.includes(Permission.CREATE_FOOD_COMPONENTS);

    return (
      <Card variant="outlined" sx={styles.card} ref={ref}>
        <Typography sx={styles.title}>Additional Information</Typography>
        <Divider sx={styles.divider} />
        <InputThemeProvider>
          <Stack sx={styles.inputContainer}>
            <Stack
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                gap: 2
              }}
            >
              <TextField
                data-test="costPerUnitPrecoroInput"
                label="Cost per unit (Precoro)"
                value={values.purchasingCost}
                name="purchasingCost"
                onChange={handleChange}
                onBlur={handleBlur}
                type="number"
                disabled
                error={!!errors.purchasingCost}
                sx={{ width: '100%' }}
                inputProps={{ inputProps: { style: { borderRadius: 8, min: 0 } }, style: { borderRadius: 8 } }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => {
                          setShowCostInfo(true);
                          setCostType(CostInfo.purchasingCost);
                        }}
                        disabled={!values.purchasingCost}
                      >
                        <InfoIcon fontSize="small" />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              <TextField
                data-test="cookedWeightInput"
                label="Cooked Weight (g)"
                value={values.weight}
                name="weight"
                onChange={handleChange}
                onBlur={handleBlur}
                type="number"
                disabled={!permission || isDisabled || values.measurementUnit === MeasurementUnit.g}
                sx={{ width: '100%' }}
                inputProps={{ inputProps: { style: { borderRadius: 8, min: 0 } }, style: { borderRadius: 8 } }}
              />
              <TextField
                data-test="cookedRawFactorInput"
                label="Cooked > Raw Factor"
                value={values.cookedRawFactor}
                name="cookedRawFactor"
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={!permission || isDisabled}
                sx={{ width: '100%' }}
                inputProps={{ inputProps: { style: { borderRadius: 8, min: 0 } }, style: { borderRadius: 8 } }}
              />
            </Stack>
            {showCostInfo && <ComponentCostInfo costInfo={costInfoText()} handleClose={() => setShowCostInfo(false)} />}
            <Stack
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: 2
              }}
            >
              <TextField
                data-test="measurementUnitSelector"
                select
                label="Measurement Unit"
                value={values.measurementUnit}
                placeholder="Select Unit"
                sx={{ width: '100%' }}
                disabled={!permission || isDisabled}
                onChange={(data: any) => setFieldValue('measurementUnit', data.target.value)}
                InputProps={{ inputProps: { style: { borderRadius: 8 } }, style: { borderRadius: 8 } }}
              >
                {Object.values(MeasurementUnit).map((unit) => (
                  <MenuItem key={unit} value={unit}>
                    {unit}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                data-test="stationSelector"
                select
                label="Station"
                value={values.cookingStation}
                SelectProps={{
                  renderValue: (selected) => {
                    return (selected as string[]).map((station) => FoodComponentStation[station]).join(', ');
                  },
                  multiple: true,
                  value: values.cookingStation,
                  onChange: (e) => setFieldValue('cookingStation', e.target.value)
                }}
                sx={{ width: '100%' }}
                disabled={!permission || isDisabled}
                InputProps={{ inputProps: { style: { borderRadius: 8 } }, style: { borderRadius: 8 } }}
              >
                {Object.entries(FoodComponentStation).map(([key, value]) => (
                  <MenuItem key={key} value={key}>
                    {value}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                data-test="statusSelector"
                select
                name="status"
                label="Recipe Status"
                value={values.status}
                sx={{ width: '100%' }}
                disabled={!permission || isDisabled}
                onChange={(data: any) => setFieldValue('status', data.target.value)}
                InputProps={{ inputProps: { style: { borderRadius: 8 } }, style: { borderRadius: 8 } }}
              >
                {Object.entries(FoodComponentStatus).map(([key, value]) => (
                  <MenuItem key={key} value={key}>
                    {value}
                  </MenuItem>
                ))}
              </TextField>
            </Stack>
          </Stack>
        </InputThemeProvider>
      </Card>
    );
  }
);

export default ComponentInformationCard;
