import { caloTheme } from 'assets/images/theme/calo';

export const styles = {
  container: {
    backgroundColor: caloTheme.palette.white,
    flexDirection: 'column',
    justifyContent: 'space-between',
    spacing: 2
  },
  headerBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  breadcrumbText: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '150%',
    color: caloTheme.palette.textSecondary
  },
  breadcrumbTextActive: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '150%',
    color: caloTheme.palette.textPrimary
  },
  countryText: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '150%',
    color: caloTheme.palette.textPrimary
  },
  deleteButton: {
    padding: '10px',
    textTransform: 'none',
    fontSize: '6px',
    color: caloTheme.palette.errorMain,
    border: `1px solid ${caloTheme.palette.errorMain}`,
    borderRadius: '4px'
  },
  deleteIcon: (isDisabled?: boolean) => ({
    fontSize: '18px',
    color: isDisabled ? caloTheme.palette.neutral500 : caloTheme.palette.red
  }),
  actionButton: {
    padding: '8px 22px',
    fontSize: '15px',
    fontWeight: 500,
    lineHeight: '125%',
    textTransform: 'uppercase',
    borderRadius: '4px',
    boxShadow: 'none',
    color: caloTheme.palette.textPrimary,
    borderColor: caloTheme.palette.textPrimary,
    backgroundColor: 'transparent',
    '&:hover': {
      borderColor: caloTheme.palette.textPrimary,
      backgroundColor: '#00000008'
    }
  },
  /////////////////////////////////////////////////////////////
  newSizeModal: {
    '& .MuiDialog-paper': {
      overflow: 'visible'
    }
  },
  newSizeModalButton: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '24px',
    fontFamily: 'Roboto',
    color: caloTheme.palette.primary[500]
  },
  newSizeModalCloseButton: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '24px',
    fontFamily: 'Roboto',
    color: caloTheme.palette.grey[900]
  },
  newSizeModalDivider: {
    marginBottom: 2
  },
  modelContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    p: 2
  }
};
