import { Box, Drawer } from '@mui/material';
import { ReactElement, ReactNode, cloneElement, useEffect, useState } from 'react';

interface SideDrawerProps {
  trigger?: ReactElement;
  children: ReactNode;
  visible?: boolean;
  onClose?: () => void;
  className?: string;
}

const SideDrawer = ({ trigger, children, visible, onClose, className }: SideDrawerProps) => {
  const [localVisible, setLocalVisible] = useState(false);

  const handleLocalClose = () => {
    setLocalVisible(false);
    if (onClose) {
      onClose();
    }
  };

  useEffect(() => {
    setLocalVisible(visible || false);
  }, [visible]);

  return (
    <>
      {trigger &&
        cloneElement(trigger, {
          onClick: () => setLocalVisible(true)
        })}
      <Drawer
        anchor="right"
        open={localVisible}
        onClose={handleLocalClose}
        classes={{ paper: className }}
        PaperProps={{
          sx: {
            width: '25%',
            height: '100%',
            top: 'auto',
            bottom: 0,
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.08)',
            boxShadowTop: 'none',
            elevation: 0
          }
        }}
        ModalProps={{
          keepMounted: true,
          BackdropProps: {
            style: {
              backgroundColor: '#3A3A3A66'
            }
          }
        }}
      >
        <Box>{children}</Box>
      </Drawer>
    </>
  );
};

export default SideDrawer;
