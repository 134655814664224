import { Brand, Country, Kitchen } from '@calo/types';
import { format, getWeek, getYear } from 'date-fns';
import { getYearBasedOnWeek, resolveCountryFromKitchen } from 'lib';
import { MenuPresentationType } from 'lib/enums';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { MenuListFilters, MenuPathUpdateFunctions } from '../types';

export const useMenuFilters = (): [MenuListFilters, MenuPathUpdateFunctions] => {
  const history = useHistory();

  const { country, kitchen, type, day } = useParams<{
    country: string;
    kitchen: string;
    type: MenuPresentationType;
    day: string;
  }>();

  const defaultWeek = type === MenuPresentationType.daily ? getWeek(new Date(), { weekStartsOn: 0 }) : Number(day);
  const defaultDay = type === MenuPresentationType.daily ? day : format(new Date(), 'yyyy-MM-dd');
  const [selectedWeek, setSelectedWeek] = useState(defaultWeek);
  const [selectedYear, setSelectedYear] = useState(getYear(new Date()));
  const [selectedDay, setSelectedDay] = useState(defaultDay);

  useEffect(() => {
    if (Number.isNaN(Number(day))) {
      setSelectedDay(day);
    } else {
      setSelectedWeek(Number(day));
      setSelectedYear(getYearBasedOnWeek(Number(day)));
    }
  }, [day]);

  // Derive filters directly from URL parameters
  const filters: MenuListFilters = {
    country: (country ?? resolveCountryFromKitchen(kitchen as Kitchen)) as Country,
    kitchen: (kitchen as Kitchen) ?? Kitchen.BH1,
    brand: Brand.CALO,
    menuPresentation: type,
    selectedDay: selectedDay,
    selectedWeek: selectedWeek,
    selectedYear
  };

  // Functions to update URL path parameters
  const setBrand = (_: Brand) => {
    history.push(`/menu/new/${filters.country}/${filters.kitchen}/${type}/${day}`);
  };

  const setKitchen = (newKitchen: Kitchen) => {
    const newCountry = resolveCountryFromKitchen(newKitchen);
    history.push(`/menu/new/${newCountry}/${newKitchen}/${type}/${day}`);
  };

  const setMenuPresentation = (newPresentation: MenuPresentationType) => {
    const dayOrWeek = newPresentation === MenuPresentationType.daily ? selectedDay : selectedWeek;

    history.push(`/menu/new/${country}/${kitchen}/${newPresentation}/${dayOrWeek}`);
  };

  const setDay = (newDay: string) => {
    history.push(`/menu/new/${country}/${kitchen}/${type}/${newDay}`);
  };

  const setWeek = (newWeek: number) => {
    history.push(`/menu/new/${country}/${kitchen}/${type}/${newWeek}`);
  };

  const setYear = (newYear: number) => {
    setSelectedYear(newYear);
  };

  return [
    {
      country: filters.country,
      kitchen: filters.kitchen,
      menuPresentation: type,
      selectedDay: selectedDay,
      selectedWeek: selectedWeek,
      selectedYear: selectedYear,
      brand: Brand.CALO
    },
    { setBrand, setKitchen, setMenuPresentation, setDay, setWeek, setYear }
  ];
};
