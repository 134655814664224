import { Permission } from 'lib/enums';

export const GroupPermissions = {
  PERMISSIONS: [
    {
      name: 'VIEW_USER_PERMISSIONS_LIST',
      value: Permission.VIEW_USER_PERMISSIONS_LIST
    },
    {
      name: 'CREATE_USER_PERMISSIONS',
      value: Permission.CREATE_USER_PERMISSIONS
    },
    {
      name: 'UPDATE_USER_PERMISSIONS',
      value: Permission.UPDATE_USER_PERMISSIONS
    },
    {
      name: 'DELETE_USER_PERMISSIONS',
      value: Permission.DELETE_USER_PERMISSIONS
    }
  ],
  DASHBOARD: [
    { name: 'SEND_KITCHEN_EMAIL', value: Permission.SEND_KITCHEN_EMAIL },
    { name: 'VIEW_SEARCH_BAR', value: Permission.VIEW_SEARCH_BAR }
  ],
  AI_DASHBOARD: [
    { name: 'UPDATE_AI_DISCOUNTS', value: Permission.UPDATE_AI_DISCOUNTS },
    { name: 'UPDATE_AI_PROMPTS', value: Permission.UPDATE_AI_PROMPTS },
    { name: 'UPDATE_AI_LOGISTICS', value: Permission.UPDATE_AI_LOGISTICS }
  ],
  DELIVERY: [
    { name: 'VIEW_DELIVERY_LIST', value: Permission.VIEW_DELIVERY_LIST },
    { name: 'VIEW_DELIVERY_STATS', value: Permission.VIEW_DELIVERY_STATS },
    { name: 'VIEW_DELIVERY', value: Permission.VIEW_DELIVERY },
    { name: 'UPDATE_DELIVERY', value: Permission.UPDATE_DELIVERY },
    { name: 'SKIP_DELIVERY', value: Permission.SKIP_DELIVERY },
    { name: 'UNSKIP_DELIVERY', value: Permission.UNSKIP_DELIVERY },
    { name: 'UNCANCEL_DELIVERY', value: Permission.UNCANCEL_DELIVERY },
    { name: 'GET_DELIVERY_INVOICE', value: Permission.GET_DELIVERY_INVOICE },
    {
      name: 'DELETE_FOOD_DELIVERY_MENU',
      value: Permission.DELETE_FOOD_DELIVERY_MENU
    },
    {
      name: 'REPLACE_FOOD_DELIVERY_MENU',
      value: Permission.REPLACE_FOOD_DELIVERY_MENU
    },
    { name: 'REMOVE_COMPONENT_FROM_DELIVERY_FOOD', value: Permission.REMOVE_COMPONENT_FROM_DELIVERY_FOOD },
    {
      name: 'REGENERATE_DELIVERY_MENU',
      value: Permission.REGENERATE_DELIVERY_MENU
    },
    {
      name: 'DELIVERY_APPLY_PAYMENT',
      value: Permission.DELIVERY_APPLY_PAYMENT
    },
    {
      name: 'CREATE_ADDONS_DELIVERY_MENU',
      value: Permission.CREATE_ADDONS_DELIVERY_MENU
    },
    {
      name: 'DELETE_ADDONS_DELIVERY_MENU',
      value: Permission.DELETE_ADDONS_DELIVERY_MENU
    },
    { name: 'CANCEL_DELIVERY', value: Permission.CANCEL_DELIVERY },
    { name: 'DELIVERY_ADDONS_MENU', value: Permission.DELIVERY_ADDONS_MENU },
    {
      name: 'VIEW_DELIVERY_SUBSCRIPTION',
      value: Permission.VIEW_DELIVERY_SUBSCRIPTION
    },
    {
      name: 'ADD_FOOD_DELIVERY_MENU',
      value: Permission.ADD_FOOD_DELIVERY_MENU
    },
    {
      name: 'VIEW_FOOD_COMPONENTS_CUSTOM_FOOD',
      value: Permission.VIEW_FOOD_COMPONENTS_CUSTOM_FOOD
    },
    {
      name: 'UPDATE_DELIVERY_CUSTOM_FOOD',
      value: Permission.UPDATE_DELIVERY_CUSTOM_FOOD
    },
    { name: 'VIEW_USER_CUSTOM_FOOD', value: Permission.VIEW_USER_CUSTOM_FOOD },
    {
      name: 'CREATE_USER_CUSTOM_FOOD',
      value: Permission.CREATE_USER_CUSTOM_FOOD
    },
    { name: 'EXPORT_DELIVERY_LIST', value: Permission.EXPORT_DELIVERY_LIST },
    {
      name: 'VIEW_ADDON_SUBSCRIPTION_MENU',
      value: Permission.VIEW_ADDON_SUBSCRIPTION_MENU
    },
    {
      name: 'REPLACE_ADDON_SUBSCRIPTION_DELIVERY_MENU',
      value: Permission.REPLACE_ADDON_SUBSCRIPTION_DELIVERY_MENU
    },
    {
      name: 'VIEW_CUSTOM_MACROS_DELIVERY_DETAILS',
      value: Permission.VIEW_CUSTOM_MACROS_DELIVERY_DETAILS
    }
  ],
  SUBSCRIPTION: [
    {
      name: 'LIST_APP_COGNITO_USERS',
      value: Permission.LIST_APP_COGNITO_USERS
    },
    {
      name: 'UPDATE_APP_COGNITO_USERS',
      value: Permission.UPDATE_APP_COGNITO_USERS
    },
    {
      name: 'VIEW_SUBSCRIPTION_LIST',
      value: Permission.VIEW_SUBSCRIPTION_LIST
    },
    { name: 'VIEW_SUBSCRIPTION', value: Permission.VIEW_SUBSCRIPTION },
    { name: 'UPDATE_SUBSCRIPTION', value: Permission.UPDATE_SUBSCRIPTION },
    { name: 'CREATE_SUBSCRIPTION', value: Permission.CREATE_SUBSCRIPTION },
    { name: 'PAUSE_SUBSCRIPTION', value: Permission.PAUSE_SUBSCRIPTION },
    { name: 'RESUME_SUBSCRIPTION', value: Permission.RESUME_SUBSCRIPTION },
    {
      name: 'CLEAR_SUBSCRIPTION_PAUSE',
      value: Permission.CLEAR_SUBSCRIPTION_PAUSE
    },
    {
      name: 'REMOVE_SUBSCRIPTION_SUSPENSION',
      value: Permission.REMOVE_SUBSCRIPTION_SUSPENSION
    },
    {
      name: 'CREATE_SUBSCRIPTION_ADDRESS',
      value: Permission.CREATE_SUBSCRIPTION_ADDRESS
    },
    {
      name: 'UPDATE_SUBSCRIPTION_ADDRESS',
      value: Permission.UPDATE_SUBSCRIPTION_ADDRESS
    },
    {
      name: 'DELETE_SUBSCRIPTION_ADDRESS',
      value: Permission.DELETE_SUBSCRIPTION_ADDRESS
    },
    {
      name: 'CREATE_SUBSCRIPTION_WALLET',
      value: Permission.CREATE_SUBSCRIPTION_WALLET
    },
    {
      name: 'CREATE_SUBSCRIPTION_DELIVERY',
      value: Permission.CREATE_SUBSCRIPTION_DELIVERY
    },
    {
      name: 'VIEW_SUBSCRIPTION_DELIVERY_LIST',
      value: Permission.VIEW_SUBSCRIPTION_DELIVERY_LIST
    },
    {
      name: 'VIEW_SUBSCRIPTION_WALLET',
      value: Permission.VIEW_SUBSCRIPTION_WALLET
    },
    {
      name: 'VIEW_SUBSCRIPTION_LOGS',
      value: Permission.VIEW_SUBSCRIPTION_LOGS
    },
    {
      name: 'UPDATE_SUBSCRIPTION_PLAN',
      value: Permission.UPDATE_SUBSCRIPTION_PLAN
    },
    {
      name: 'EXPORT_SUBSCRIPTION_LIST',
      value: Permission.EXPORT_SUBSCRIPTION_LIST
    },
    { name: 'VIEW_FOOD_PREFERENCE', value: Permission.VIEW_FOOD_PREFERENCE },
    {
      name: 'UPDATE_FOOD_PREFERENCE',
      value: Permission.UPDATE_FOOD_PREFERENCE
    },
    {
      name: 'VIEW_SUBSCRIPTION_TRANSACTIONS',
      value: Permission.VIEW_SUBSCRIPTION_TRANSACTIONS
    },
    {
      name: 'VIEW_ADDON_SUBSCRIPTION_LIST',
      value: Permission.VIEW_ADDON_SUBSCRIPTION_LIST
    },
    {
      name: 'ADD_ADDON_SUBSCRIPTION',
      value: Permission.ADD_ADDON_SUBSCRIPTION
    },
    {
      name: 'REMOVE_ADDON_SUBSCRIPTION',
      value: Permission.REMOVE_ADDON_SUBSCRIPTION
    },
    {
      name: 'VIEW_SUBSCRIPTION_DAY_PROGRESS',
      value: Permission.VIEW_SUBSCRIPTION_DAY_PROGRESS
    },
    {
      name: 'UPDATE_SUBSCRIPTION_DAY_PROGRESS',
      value: Permission.UPDATE_SUBSCRIPTION_DAY_PROGRESS
    }
  ],
  MAP: [
    { name: 'VIEW_ROUTE_PLAN_LIST', value: Permission.VIEW_ROUTE_PLAN_LIST },
    { name: 'VIEW_ROUTE_PLAN', value: Permission.VIEW_ROUTE_PLAN },
    { name: 'REGENERATE_ROUTE', value: Permission.REGENERATE_ROUTE },
    { name: 'CREATE_MAP', value: Permission.CREATE_MAP },
    { name: 'UPDATE_MAP', value: Permission.UPDATE_MAP },
    { name: 'VIEW_MAP', value: Permission.VIEW_MAP },
    { name: 'GET_DRIVER_USERS', value: Permission.GET_DRIVER_USERS },
    {
      name: 'VIEW_MAP_DELIVERIES_LIST',
      value: Permission.VIEW_MAP_DELIVERIES_LIST
    },
    { name: 'VIEW_LIVE_TRACKING', value: Permission.VIEW_LIVE_TRACKING },
    {
      name: 'VIEW_COMPLETE_ROUTE_PATH',
      value: Permission.VIEW_COMPLETE_ROUTE_PATH
    }
  ],
  BUSINESS: [
    {
      name: 'VIEW_BUSINESS_USER_LIST',
      value: Permission.VIEW_BUSINESS_USER_LIST
    },
    { name: 'VIEW_BUSINESS_USER', value: Permission.VIEW_BUSINESS_USER },
    { name: 'CREATE_BUSINESS_USER', value: Permission.CREATE_BUSINESS_USER },
    { name: 'UPDATE_BUSINESS_USER', value: Permission.UPDATE_BUSINESS_USER },
    {
      name: 'VIEW_BUSINESS_DELIVERY_LIST',
      value: Permission.VIEW_BUSINESS_DELIVERY_LIST
    },
    {
      name: 'VIEW_BUSINESS_DELIVERY',
      value: Permission.VIEW_BUSINESS_DELIVERY
    },
    {
      name: 'CREATE_BUSINESS_DELIVERY',
      value: Permission.CREATE_BUSINESS_DELIVERY
    },
    {
      name: 'UPDATE_BUSINESS_DELIVERY',
      value: Permission.UPDATE_BUSINESS_DELIVERY
    },
    {
      name: 'UPDATE_BUSINESS_DELIVERY_ADDRESS',
      value: Permission.UPDATE_BUSINESS_DELIVERY_ADDRESS
    },
    {
      name: 'DELETE_BUSINESS_DELIVERY',
      value: Permission.DELETE_BUSINESS_DELIVERY
    },
    {
      name: 'EXPORT_BUSINESS_ORDERS',
      value: Permission.EXPORT_BUSINESS_ORDERS
    }
  ],
  DRIVER_METRIC: [
    {
      name: 'VIEW_DRIVER_METRIC_LIST',
      value: Permission.VIEW_DRIVER_METRIC_LIST
    },
    { name: 'VIEW_DRIVER_METRIC', value: Permission.VIEW_DRIVER_METRIC },
    { name: 'UPDATE_DRIVER_METRIC', value: Permission.UPDATE_DRIVER_METRIC },
    {
      name: 'UPDATE_SUBSCRIPTION_CUSTOM_DELIVERY_TIME',
      value: Permission.UPDATE_SUBSCRIPTION_CUSTOM_DELIVERY_TIME
    },
    {
      name: 'BATCH_ALLOW_DRIVERS_TO_START_SHIFT',
      value: Permission.BATCH_ALLOW_DRIVERS_TO_START_SHIFT
    },
    {
      name: 'VIEW_DRIVER_COOLER_BAG_STATS',
      value: Permission.VIEW_DRIVER_COOLER_BAG_STATS
    }
  ],
  DRIVER: [
    { name: 'VIEW_DRIVER_LIST', value: Permission.VIEW_DRIVER_LIST },
    { name: 'VIEW_DRIVER', value: Permission.VIEW_DRIVER },
    { name: 'CREATE_DRIVER', value: Permission.CREATE_DRIVER },
    { name: 'UPDATE_DRIVER', value: Permission.UPDATE_DRIVER },
    { name: 'DELETE_DRIVER', value: Permission.DELETE_DRIVER },
    { name: 'VIEW_DRIVER_STATS', value: Permission.VIEW_DRIVER_STATS },
    { name: 'RESET_DRIVER_PASSWORD', value: Permission.RESET_DRIVER_PASSWORD }
  ],
  KITCHEN: [
    { name: 'VIEW_KITCHEN_LIST', value: Permission.VIEW_KITCHEN_LIST },
    { name: 'VIEW_KITCHEN', value: Permission.VIEW_KITCHEN },
    { name: 'UPDATE_KITCHEN', value: Permission.UPDATE_KITCHEN },
    { name: 'VIEW_KITCHEN_STATS', value: Permission.VIEW_KITCHEN_STATS },
    { name: 'VIEW_WAITING_LIST', value: Permission.VIEW_WAITING_LIST },
    { name: 'ALLOW_WAITING_LIST', value: Permission.ALLOW_WAITING_LIST },
    { name: 'BATCH_ALLOW_WAITING_LIST', value: Permission.BATCH_ALLOW_WAITING_LIST },
    { name: 'DELETE_WAITING_LIST', value: Permission.DELETE_WAITING_LIST },
    {
      name: 'SEND_INVITATION_CODE_WAITING_LIST',
      value: Permission.SEND_INVITATION_CODE_WAITING_LIST
    },
    {
      name: 'BATCH_REMOVE_FROM_PRE_LAUNCH_WAITING_LIST',
      value: Permission.BATCH_REMOVE_FROM_PRE_LAUNCH_WAITING_LIST
    },
    { name: 'GET_DOCUMENTS', value: Permission.GET_DOCUMENTS }
  ],
  FOOD_STATS: [
    { name: 'VIEW_FOOD_STATS', value: Permission.VIEW_FOOD_STATS },
    { name: 'EXPORT_FOOD_STATS', value: Permission.EXPORT_FOOD_STATS }
  ],
  PORTION_STATS: [
    { name: 'VIEW_PORTION_STATS', value: Permission.VIEW_PORTION_STATS },
    { name: 'EXPORT_PORTION_STATS', value: Permission.EXPORT_PORTION_STATS }
  ],
  INGREDIENT_STATS: [
    { name: 'VIEW_INGREDIENT_STATS', value: Permission.VIEW_INGREDIENT_STATS },
    {
      name: 'EXPORT_INGREDIENT_STATS',
      value: Permission.EXPORT_INGREDIENT_STATS
    }
  ],
  PACKAGING_STATS: [
    {
      name: 'VIEW_PACKAGE_ELEMENTS_STATS',
      value: Permission.VIEW_PACKAGE_ELEMENTS_STATS
    },
    {
      name: 'VIEW_CUTLERY_AND_COOLER_BAG_STATS',
      value: Permission.VIEW_CUTLERY_AND_COOLER_BAG_STATS
    }
  ],
  MENU: [
    { name: 'VIEW_MENU_LIST', value: Permission.VIEW_MENU_LIST },
    { name: 'UPDATE_MENU', value: Permission.UPDATE_MENU },
    { name: 'VIEW_MENU', value: Permission.VIEW_MENU },
    { name: 'GENERATE_DAY_MENU', value: Permission.GENERATE_DAY_MENU },
    { name: 'CREATE_MENU', value: Permission.CREATE_MENU },
    { name: 'MENU_GENERATION_LIST', value: Permission.MENU_GENERATION_LIST },
    { name: 'POPULATE_MENU', value: Permission.DESIGN_MENU }
  ],
  ADDONS: [
    { name: 'VIEW_ADDONS_LIST', value: Permission.VIEW_ADDONS_LIST },
    { name: 'VIEW_ADDONS', value: Permission.VIEW_ADDONS },
    { name: 'UPDATE_ADDONS', value: Permission.UPDATE_ADDONS },
    { name: 'CREATE_ADDONS', value: Permission.CREATE_ADDONS }
  ],
  ADDONS_SUBSCRIPTION: [
    {
      name: 'VIEW_ADDONS_SUBSCRIPTION_LIST',
      value: Permission.VIEW_ADDONS_SUBSCRIPTION_LIST
    },
    {
      name: 'VIEW_ADDONS_SUBSCRIPTION',
      value: Permission.VIEW_ADDONS_SUBSCRIPTION
    },
    {
      name: 'UPDATE_ADDONS_SUBSCRIPTION',
      value: Permission.UPDATE_ADDONS_SUBSCRIPTION
    },
    {
      name: 'CREATE_ADDONS_SUBSCRIPTION',
      value: Permission.CREATE_ADDONS_SUBSCRIPTION
    }
  ],
  FOOD: [
    { name: 'VIEW_FOOD_LIST', value: Permission.VIEW_FOOD_LIST },
    { name: 'CREATE_FOOD', value: Permission.CREATE_FOOD },
    { name: 'UPDATE_FOOD', value: Permission.UPDATE_FOOD },
    { name: 'VIEW_FOOD', value: Permission.VIEW_FOOD },
    { name: 'VIEW_FOOD_ALL_SIZES', value: Permission.VIEW_FOOD_LIST_BY_SLUG },
    { name: 'DELETE_FOOD', value: Permission.DELETE_FOOD },
    { name: 'EXPORT_FOOD_LIST', value: Permission.EXPORT_FOOD_LIST },
    { name: 'UPLOAD_IMAGE', value: Permission.UPLOAD_IMAGE },
    { name: 'DELETE_CUSTOM_FOOD', value: Permission.DELETE_CUSTOM_FOOD },
    {
      name: 'VIEW_FOOD_PACKAGE_ELEMENT_LIST',
      value: Permission.VIEW_FOOD_PACKAGE_ELEMENT_LIST
    },
    {
      name: 'VIEW_FOOD_PACKAGE_ELEMENT',
      value: Permission.VIEW_FOOD_PACKAGE_ELEMENT
    },
    {
      name: 'CREATE_FOOD_PACKAGE_ELEMENT',
      value: Permission.CREATE_FOOD_PACKAGE_ELEMENT
    },
    {
      name: 'UPDATE_FOOD_PACKAGE_ELEMENT',
      value: Permission.UPDATE_FOOD_PACKAGE_ELEMENT
    },
    {
      name: 'DELETE_FOOD_PACKAGE_ELEMENT',
      value: Permission.DELETE_FOOD_PACKAGE_ELEMENT
    },
    {
      name: 'EXPORT_FOOD_PACKAGE_ELEMENT',
      value: Permission.EXPORT_FOOD_PACKAGE_ELEMENT
    },
    {
      name: 'VIEW_FOOD_PACKAGE_LIST',
      value: Permission.VIEW_FOOD_PACKAGE_LIST
    },
    { name: 'VIEW_FOOD_PACKAGE', value: Permission.VIEW_FOOD_PACKAGE },
    { name: 'UPDATE_FOOD_PACKAGE', value: Permission.UPDATE_FOOD_PACKAGE },
    { name: 'CREATE_FOOD_PACKAGE', value: Permission.CREATE_FOOD_PACKAGE },
    { name: 'DELETE_FOOD_PACKAGE', value: Permission.DELETE_FOOD_PACKAGE },
    { name: 'EXPORT_FOOD_PACKAGE', value: Permission.EXPORT_FOOD_PACKAGE },
    {
      name: 'VIEW_KITCHEN_LOGS_LIST',
      value: Permission.VIEW_KITCHEN_LOGS_LIST
    },
    { name: 'BATCH_UPDATE_FOOD', value: Permission.BATCH_UPDATE_FOOD }
  ],
  PLAYGROUND_FOOD: [
    { name: 'VIEW_PLAYGROUND_FOOD_LIST', value: Permission.VIEW_PROTOTYPE_FOOD_LIST },
    { name: 'VIEW_PLAYGROUND_FOOD_ALL_SIZES', value: Permission.VIEW_PROTOTYPE_FOOD_LIST_BY_SLUG },
    { name: 'CREATE_PLAYGROUND_FOOD', value: Permission.CREATE_PROTOTYPE_FOOD },
    { name: 'UPDATE_PLAYGROUND_FOOD', value: Permission.UPDATE_PROTOTYPE_FOOD },
    { name: 'BATCH_UPDATE_PLAYGROUND_FOOD', value: Permission.BATCH_UPDATE_PROTOTYPE_FOOD },
    { name: 'VIEW_PLAYGROUND_FOOD', value: Permission.VIEW_PROTOTYPE_FOOD },
    { name: 'CREATE_ACTION_PLAYGROUND_FOOD', value: Permission.CREATE_ACTION_PROTOTYPE_FOOD },
    { name: 'APPROVE_PLAYGROUND_FOOD_STAGE_1', value: Permission.APPROVE_STAGE_1_PROTOTYPE_FOOD },
    { name: 'APPROVE_PLAYGROUND_FOOD_STAGE_2', value: Permission.APPROVE_STAGE_2_PROTOTYPE_FOOD },
    { name: 'DELETE_PLAYGROUND_FOOD', value: Permission.DELETE_PROTOTYPE_FOOD }
  ],
  FOOD_COMPONENTS: [
    {
      name: 'CREATE_FOOD_COMPONENTS',
      value: Permission.CREATE_FOOD_COMPONENTS
    },
    {
      name: 'UPDATE_FOOD_COMPONENTS',
      value: Permission.UPDATE_FOOD_COMPONENTS
    },
    { name: 'DELETE_FOOD_COMPONENT', value: Permission.DELETE_FOOD_COMPONENT },
    { name: 'VIEW_FOOD_COMPONENTS', value: Permission.VIEW_FOOD_COMPONENTS },
    {
      name: 'VIEW_FOOD_COMPONENTS_LIST',
      value: Permission.VIEW_FOOD_COMPONENTS_LIST
    },
    {
      name: 'EXPORT_FOOD_COMPONENTS_LIST',
      value: Permission.EXPORT_FOOD_COMPONENTS_LIST
    }
  ],
  PLAYGROUND_COMPONENTS: [
    { name: 'CREATE_PLAYGROUND_COMPONENT', value: Permission.CREATE_PROTOTYPE_COMPONENT },
    { name: 'UPDATE_PLAYGROUND_COMPONENT', value: Permission.UPDATE_PROTOTYPE_COMPONENT },
    { name: 'VIEW_PLAYGROUND_COMPONENT', value: Permission.VIEW_PROTOTYPE_COMPONENT },
    { name: 'VIEW_PLAYGROUND_COMPONENT_LIST', value: Permission.VIEW_PROTOTYPE_COMPONENT_LIST },
    { name: 'CREATE_ACTION_PLAYGROUND_COMPONENT', value: Permission.CREATE_ACTION_PROTOTYPE_COMPONENT },
    { name: 'APPROVE_PLAYGROUND_COMPONENT_STAGE_1', value: Permission.APPROVE_STAGE_1_PROTOTYPE_FOOD_COMPONENT },
    { name: 'APPROVE_PLAYGROUND_COMPONENT_STAGE_2', value: Permission.APPROVE_STAGE_2_PROTOTYPE_FOOD_COMPONENT },
    { name: 'DELETE_PLAYGROUND_COMPONENT', value: Permission.DELETE_PROTOTYPE_COMPONENT }
  ],
  INGREDIENT: [
    { name: 'VIEW_INGREDIENT_LIST', value: Permission.VIEW_INGREDIENT_LIST },
    { name: 'VIEW_INGREDIENT', value: Permission.VIEW_INGREDIENT },
    { name: 'UPDATE_INGREDIENT', value: Permission.UPDATE_INGREDIENT },
    { name: 'CREATE_INGREDIENT', value: Permission.CREATE_INGREDIENT },
    { name: 'DELETE_INGREDIENT', value: Permission.DELETE_INGREDIENT },
    {
      name: 'EXPORT_INGREDIENT_LIST',
      value: Permission.EXPORT_INGREDIENT_LIST
    }
  ],

  PLAYGROUND_INGREDIENTS: [
    { name: 'CREATE_PLAYGROUND_INGREDIENT', value: Permission.CREATE_PROTOTYPE_INGREDIENT },
    { name: 'UPDATE_PLAYGROUND_INGREDIENT', value: Permission.UPDATE_PROTOTYPE_INGREDIENT },
    { name: 'VIEW_PLAYGROUND_INGREDIENT', value: Permission.VIEW_PROTOTYPE_INGREDIENT },
    { name: 'VIEW_PLAYGROUND_INGREDIENT_LIST', value: Permission.VIEW_PROTOTYPE_INGREDIENT_LIST },
    { name: 'CREATE_ACTION_PLAYGROUND_INGREDIENT', value: Permission.CREATE_ACTION_PROTOTYPE_INGREDIENT },
    { name: 'APPROVE_PLAYGROUND_INGREDIENT', value: Permission.APPROVE_PROTOTYPE_INGREDIENT },
    { name: 'DELETE_PLAYGROUND_INGREDIENT', value: Permission.DELETE_PROTOTYPE_INGREDIENT }
  ],
  USER: [
    { name: 'VIEW_USER_LIST', value: Permission.VIEW_USER_LIST },
    { name: 'VIEW_USER', value: Permission.VIEW_USER },
    { name: 'CREATE_USER', value: Permission.CREATE_USER },
    { name: 'UPDATE_USER', value: Permission.UPDATE_USER },
    { name: 'RESET_USER_PASSWORD', value: Permission.RESET_USER_PASSWORD },
    { name: 'DELETE_USER', value: Permission.DELETE_USER }
  ],
  KDS_USERS: [
    { name: 'VIEW_KDS_USER_LIST', value: Permission.VIEW_KDS_USER_LIST },
    { name: 'VIEW_KDS_USER', value: Permission.VIEW_KDS_USER },
    { name: 'CREATE_KDS_USER', value: Permission.CREATE_KDS_USER },
    { name: 'UPDATE_KDS_USER', value: Permission.UPDATE_KDS_USER },
    {
      name: 'RESET_KDS_USER_PASSWORD',
      value: Permission.RESET_KDS_USER_PASSWORD
    },
    { name: 'DELETE_KDS_USER', value: Permission.DELETE_KDS_USER }
  ],
  KDS_PERMISSIONS: [
    { name: 'VIEW_KDS_PERMISSIONS_LIST', value: Permission.VIEW_KDS_PERMISSIONS_LIST },
    { name: 'CREATE_KDS_PERMISSIONS', value: Permission.CREATE_KDS_PERMISSIONS },
    { name: 'UPDATE_KDS_PERMISSIONS', value: Permission.UPDATE_KDS_PERMISSIONS },
    { name: 'DELETE_KDS_PERMISSIONS', value: Permission.DELETE_KDS_PERMISSIONS }
  ],
  SUPPLY_CHAIN_USERS: [
    {
      name: 'VIEW_SUPPLY_CHAIN_USER_LIST',
      value: Permission.VIEW_SUPPLY_CHAIN_USER_LIST
    },
    {
      name: 'VIEW_SUPPLY_CHAIN_USER',
      value: Permission.VIEW_SUPPLY_CHAIN_USER
    },
    {
      name: 'CREATE_SUPPLY_CHAIN_USER',
      value: Permission.CREATE_SUPPLY_CHAIN_USER
    },
    {
      name: 'UPDATE_SUPPLY_CHAIN_USER',
      value: Permission.UPDATE_SUPPLY_CHAIN_USER
    },
    {
      name: 'RESET_SUPPLY_CHAIN_USER_PASSWORD',
      value: Permission.RESET_SUPPLY_CHAIN_USER_PASSWORD
    },
    {
      name: 'DELETE_SUPPLY_CHAIN_USER',
      value: Permission.DELETE_SUPPLY_CHAIN_USER
    }
  ],
  SUPPLY_CHAIN_REPORTS: [
    { name: 'VIEW_SUPPLY_CHAIN_REPORTS_LIST', value: Permission.VIEW_SUPPLY_CHAIN_REPORTS_LIST },
    { name: 'VIEW_MATERIAL_RESOURCE_PLANING_REPORT', value: Permission.VIEW_MATERIAL_RESOURCE_PLANING_REPORT },
    { name: 'EXPORT_MATERIAL_RESOURCE_PLANING_REPORT', value: Permission.EXPORT_MATERIAL_RESOURCE_PLANING_REPORT },
    { name: 'VIEW_MOVING_COST_REPORT', value: Permission.VIEW_MOVING_COST_REPORT }
  ],
  TICKETS: [
    { name: 'VIEW_TICKET_LIST', value: Permission.VIEW_TICKET_LIST },
    { name: 'UPDATE_TICKET', value: Permission.UPDATE_TICKET }
  ],
  REFUND: [
    { name: 'VIEW_REFUND_LIST', value: Permission.VIEW_REFUND_LIST },
    { name: 'CREATE_REFUND', value: Permission.CREATE_REFUND },
    { name: 'EXPORT_REFUND_LIST', value: Permission.EXPORT_REFUND_LIST }
  ],
  COUPONS: [
    { name: 'VIEW_COUPON_LIST', value: Permission.VIEW_COUPON_LIST },
    { name: 'VIEW_COUPON', value: Permission.VIEW_COUPON },
    {
      name: 'VIEW_COUPON_USAGE_LIST',
      value: Permission.VIEW_COUPON_USAGE_LIST
    },
    { name: 'CREATE_COUPON', value: Permission.CREATE_COUPON },
    { name: 'UPDATE_COUPON', value: Permission.UPDATE_COUPON },
    { name: 'SEARCH_SUBSCRIPTION', value: Permission.SEARCH_SUBSCRIPTION },
    { name: 'DELETE_COUPON', value: Permission.DELETE_COUPON }
  ],
  PAYMENTS: [
    { name: 'VIEW_WALLET_LIST', value: Permission.VIEW_WALLET_LIST },
    { name: 'EXPORT_WALLET_LIST', value: Permission.EXPORT_WALLET_LIST },
    {
      name: 'SYNC_SUBSCRIPTION_BALANCE',
      value: Permission.SYNC_SUBSCRIPTION_BALANCE
    }
  ],
  INVITATIONS_CODE: [
    {
      name: 'VIEW_INVITATION_CODE_STATS',
      value: Permission.VIEW_INVITATION_CODE_STATS
    },
    {
      name: 'VIEW_INVITATION_CODE_USER_STATS',
      value: Permission.VIEW_INVITATION_CODE_USER_STATS
    },
    {
      name: 'CREATE_INVITATION_CODE',
      value: Permission.CREATE_INVITATION_CODE
    },
    {
      name: 'CREATE_BATCH_INVITATION_CODE',
      value: Permission.CREATE_BATCH_INVITATION_CODE
    }
  ],
  FEEDBACK: [
    { name: 'VIEW_RATING_LIST', value: Permission.VIEW_RATING_LIST },
    { name: 'EXPORT_RATING_LIST', value: Permission.EXPORT_RATING_LIST }
  ],
  LOGS: [
    {
      name: 'CREATE_LOGS_ON_UPDATE_IMAGE',
      value: Permission.CREATE_LOGS_ON_UPDATE_IMAGE
    }
  ],
  GIFTS: [
    { name: 'CREATE_GROUP_GIFT', value: Permission.CREATE_GROUP_GIFT },
    {
      name: 'AUTO_CREATE_GROUP_GIFT',
      value: Permission.AUTO_CREATE_GROUP_GIFT
    },
    { name: 'APPLY_GROUP_GIFT', value: Permission.APPLY_GROUP_GIFT },
    { name: 'AUTO_APPLY_GROUP_GIFT', value: Permission.AUTO_APPLY_GROUP_GIFT },
    { name: 'LIST_GROUP_GIFT', value: Permission.LIST_GROUP_GIFT },
    { name: 'UPDATE_GROUP_GIFT', value: Permission.UPDATE_GROUP_GIFT },
    {
      name: 'DELETE_GROUP_GIFT_ITEM',
      value: Permission.DELETE_GROUP_GIFT_ITEM
    },
    { name: 'LIST_GROUP_GIFT_ITEM', value: Permission.LIST_GROUP_GIFT_ITEM },
    { name: 'VALIDATE_GROUP_GIFT', value: Permission.VALIDATE_GROUP_GIFT },
    {
      name: 'DOWNLOAD_GROUP_GIFT_LISTS',
      value: Permission.DOWNLOAD_GROUP_GIFT_LISTS
    }
  ],
  LAST_MINUTE_CHANGE: [
    { name: 'LIST_DELIVERY_TICKET', value: Permission.LIST_DELIVERY_TICKET },
    { name: 'LIST_EXTRA_MEALS', value: Permission.LIST_EXTRA_MEALS },
    {
      name: 'CREATE_DELIVERY_TICKET',
      value: Permission.CREATE_DELIVERY_TICKET
    },
    {
      name: 'SET_DELIVERY_FOOD_MENU',
      value: Permission.SET_DELIVERY_FOOD_MENU
    }
  ],
  LOYALTY: [
    { name: 'VIEW_LOYALTY_POINTS', value: Permission.VIEW_LOYALTY_POINTS },
    { name: 'CREATE_LOYALTY_POINTS', value: Permission.CREATE_LOYALTY_POINTS }
  ],
  REFERRAL: [
    { name: 'VIEW_REFERRAL', value: Permission.VIEW_REFERRAL },
    {
      name: 'WITHDRAW_REFERRAL_BALANCE',
      value: Permission.WITHDRAW_REFERRAL_BALANCE
    }
  ],
  Complaints: [
    {
      name: 'LIST_CUSTOMER_COMPLAINT',
      value: Permission.LIST_CUSTOMER_COMPLAINT
    },
    {
      name: 'CREATE_CUSTOMER_COMPLAINT',
      value: Permission.CREATE_CUSTOMER_COMPLAINT
    },
    {
      name: 'UPDATE_CUSTOMER_COMPLAINT',
      value: Permission.UPDATE_CUSTOMER_COMPLAINT
    },
    { name: 'EXPORT_COMPLAINT_LIST', value: Permission.EXPORT_COMPLAINT_LIST }
  ],
  Nutrition: [
    {
      name: 'HEALTH_CONDITIONS_PLAN_MANAGEMENT',
      value: Permission.HEALTH_CONDITIONS_PLAN_MANAGEMENT
    }
  ],
  Affiliates: [
    {
      name: 'VIEW_AFFILIATE_PROFILE',
      value: Permission.VIEW_AFFILIATE_PROFILE
    },
    {
      name: 'CREATE_AFFILIATE_PROFILE',
      value: Permission.CREATE_AFFILIATE_PROFILE
    },
    {
      name: 'PAY_AFFILIATE_COMMISSION',
      value: Permission.PAY_AFFILIATE_COMMISSION
    }
  ],
  SpecialRequest: [
    { name: 'LIST_GIFTS', value: Permission.LIST_GIFTS },
    { name: 'CREATE_GIFT', value: Permission.CREATE_GIFT },
    { name: 'UPDATE_GIFT', value: Permission.UPDATE_GIFT },
    { name: 'DELETE_GIFT', value: Permission.DELETE_GIFT },
    { name: 'EXPORT_GIFTS_LIST', value: Permission.EXPORT_GIFTS_LIST },
    { name: 'VIEW_EMPLOYEE_PERKS', value: Permission.VIEW_EMPLOYEE_PERKS },
    { name: 'UPDATE_EMPLOYEE_PERKS', value: Permission.UPDATE_EMPLOYEE_PERKS },
    { name: 'EXPORT_EMPLOYEE_PERKS', value: Permission.EXPORT_EMPLOYEE_PERKS }
  ],
  Retail: [
    { name: 'VIEW_RETAIL_MENU', value: Permission.VIEW_RETAIL_MENU_ITEMS },
    { name: 'CREATE_RETAIL_MENU', value: Permission.CREATE_RETAIL_MENU_ITEMS },
    { name: 'UPDATE_RETAIL_MENU', value: Permission.UPDATE_RETAIL_MENU_ITEMS },
    { name: 'VIEW_RETAIL_ORDER', value: Permission.VIEW_RETAIL_ORDER },
    { name: 'UPDATE_RETAIL_ORDER', value: Permission.UPDATE_RETAIL_ORDER }
  ],
  Dev_Debug: [{ name: 'DEBUG_OPTIONS', value: Permission.VIEW_DEBUG_OPTIONS }],
  SUPPLY_CAP_ZONES: [
    { name: 'VIEW_SUPPLY_CAP_ZONES', value: Permission.VIEW_SUPPLY_CAP_ZONES },
    { name: 'CREATE_SUPPLY_CAP_ZONE', value: Permission.CREATE_SUPPLY_CAP_ZONE },
    { name: 'UPDATE_SUPPLY_CAP_ZONE', value: Permission.UPDATE_SUPPLY_CAP_ZONE },
    { name: 'DELETE_SUPPLY_CAP_ZONE', value: Permission.DELETE_SUPPLY_CAP_ZONE }
  ]
};

export const PermissionsInheritance: Partial<Record<Permission, Permission[]>> = {
  [Permission.UPDATE_AI_DISCOUNTS]: [Permission.UPDATE_AI_DISCOUNTS],
  [Permission.UPDATE_AI_LOGISTICS]: [Permission.UPDATE_AI_LOGISTICS],
  [Permission.UPDATE_AI_PROMPTS]: [Permission.UPDATE_AI_PROMPTS],
  [Permission.VIEW_AFFILIATE_PROFILE]: [Permission.VIEW_AFFILIATE_PROFILE],
  [Permission.CREATE_AFFILIATE_PROFILE]: [Permission.VIEW_AFFILIATE_PROFILE, Permission.CREATE_AFFILIATE_PROFILE],
  [Permission.PAY_AFFILIATE_COMMISSION]: [Permission.VIEW_AFFILIATE_PROFILE, Permission.PAY_AFFILIATE_COMMISSION],

  [Permission.SEND_KITCHEN_EMAIL]: [Permission.SEND_KITCHEN_EMAIL],
  [Permission.VIEW_DRIVER_METRIC_LIST]: [Permission.VIEW_DRIVER_METRIC_LIST, Permission.VIEW_DRIVER_LIST],
  [Permission.BATCH_ALLOW_DRIVERS_TO_START_SHIFT]: [
    Permission.VIEW_DRIVER_METRIC_LIST,
    Permission.VIEW_DRIVER_LIST,
    Permission.BATCH_ALLOW_DRIVERS_TO_START_SHIFT
  ],
  [Permission.VIEW_DRIVER_METRIC]: [
    Permission.VIEW_DRIVER_METRIC_LIST,
    Permission.VIEW_DRIVER_METRIC,
    Permission.VIEW_DRIVER_LIST
  ],
  [Permission.VIEW_DRIVER_COOLER_BAG_STATS]: [Permission.VIEW_DRIVER_COOLER_BAG_STATS],
  [Permission.UPDATE_SUBSCRIPTION_CUSTOM_DELIVERY_TIME]: [Permission.UPDATE_SUBSCRIPTION_CUSTOM_DELIVERY_TIME],
  [Permission.UPDATE_DRIVER_METRIC]: [Permission.VIEW_DRIVER_METRIC_LIST, Permission.UPDATE_DRIVER_METRIC],
  [Permission.VIEW_SEARCH_BAR]: [Permission.VIEW_SEARCH_BAR],

  [Permission.VIEW_USER_PERMISSIONS_LIST]: [Permission.VIEW_USER_PERMISSIONS_LIST],
  [Permission.CREATE_USER_PERMISSIONS]: [Permission.VIEW_USER_PERMISSIONS_LIST, Permission.CREATE_USER_PERMISSIONS],
  [Permission.DELETE_USER_PERMISSIONS]: [Permission.VIEW_USER_PERMISSIONS_LIST, Permission.DELETE_USER_PERMISSIONS],
  [Permission.UPDATE_USER_PERMISSIONS]: [Permission.VIEW_USER_PERMISSIONS_LIST, Permission.UPDATE_USER_PERMISSIONS],

  [Permission.CREATE_LOGS_ON_UPDATE_IMAGE]: [Permission.CREATE_LOGS_ON_UPDATE_IMAGE],

  [Permission.VIEW_DELIVERY_LIST]: [Permission.VIEW_DELIVERY_LIST],
  [Permission.VIEW_DELIVERY_STATS]: [Permission.VIEW_DELIVERY_LIST, Permission.VIEW_DELIVERY_STATS],
  [Permission.VIEW_DELIVERY]: [Permission.VIEW_DELIVERY, Permission.VIEW_ADDONS, Permission.VIEW_MENU],
  [Permission.UPDATE_DELIVERY]: [Permission.VIEW_DELIVERY_LIST, Permission.VIEW_DELIVERY, Permission.UPDATE_DELIVERY],
  [Permission.REMOVE_COMPONENT_FROM_DELIVERY_FOOD]: [
    Permission.VIEW_DELIVERY_LIST,
    Permission.VIEW_DELIVERY,
    Permission.UPDATE_DELIVERY,
    Permission.REMOVE_COMPONENT_FROM_DELIVERY_FOOD
  ],
  [Permission.CANCEL_DELIVERY]: [Permission.VIEW_DELIVERY_LIST, Permission.VIEW_DELIVERY, Permission.CANCEL_DELIVERY],
  [Permission.UNCANCEL_DELIVERY]: [Permission.VIEW_DELIVERY_LIST, Permission.VIEW_DELIVERY, Permission.UNCANCEL_DELIVERY],
  [Permission.SKIP_DELIVERY]: [Permission.VIEW_DELIVERY_LIST, Permission.VIEW_DELIVERY, Permission.SKIP_DELIVERY],
  [Permission.UNSKIP_DELIVERY]: [Permission.VIEW_DELIVERY_LIST, Permission.VIEW_DELIVERY, Permission.UNSKIP_DELIVERY],
  [Permission.GET_DELIVERY_INVOICE]: [Permission.VIEW_DELIVERY_LIST, Permission.VIEW_DELIVERY, Permission.GET_DELIVERY_INVOICE],
  [Permission.DELETE_FOOD_DELIVERY_MENU]: [
    Permission.VIEW_DELIVERY_LIST,
    Permission.VIEW_DELIVERY,
    Permission.DELETE_FOOD_DELIVERY_MENU
  ],
  [Permission.REPLACE_FOOD_DELIVERY_MENU]: [Permission.VIEW_DELIVERY, Permission.REPLACE_FOOD_DELIVERY_MENU],
  [Permission.REGENERATE_DELIVERY_MENU]: [
    Permission.VIEW_DELIVERY_LIST,
    Permission.VIEW_DELIVERY,
    Permission.REGENERATE_DELIVERY_MENU
  ],
  [Permission.DELIVERY_APPLY_PAYMENT]: [
    Permission.VIEW_DELIVERY_LIST,
    Permission.VIEW_DELIVERY,
    Permission.DELIVERY_APPLY_PAYMENT
  ],
  [Permission.VIEW_CUSTOM_MACROS_DELIVERY_DETAILS]: [Permission.VIEW_CUSTOM_MACROS_DELIVERY_DETAILS],
  [Permission.CREATE_ADDONS_DELIVERY_MENU]: [
    Permission.VIEW_DELIVERY_LIST,
    Permission.VIEW_DELIVERY,
    Permission.CREATE_ADDONS_DELIVERY_MENU
  ],
  [Permission.DELETE_ADDONS_DELIVERY_MENU]: [
    Permission.VIEW_DELIVERY_LIST,
    Permission.VIEW_DELIVERY,
    Permission.DELETE_ADDONS_DELIVERY_MENU
  ],
  [Permission.DELIVERY_ADDONS_MENU]: [Permission.VIEW_DELIVERY_LIST, Permission.VIEW_DELIVERY, Permission.DELIVERY_ADDONS_MENU],
  [Permission.VIEW_DELIVERY_SUBSCRIPTION]: [
    Permission.VIEW_DELIVERY_LIST,
    Permission.VIEW_DELIVERY,
    Permission.VIEW_DELIVERY_SUBSCRIPTION
  ],
  [Permission.ADD_FOOD_DELIVERY_MENU]: [
    Permission.VIEW_DELIVERY_LIST,
    Permission.VIEW_DELIVERY,
    Permission.ADD_FOOD_DELIVERY_MENU
  ],
  [Permission.VIEW_FOOD_COMPONENTS_CUSTOM_FOOD]: [
    Permission.VIEW_DELIVERY_LIST,
    Permission.VIEW_DELIVERY,
    Permission.VIEW_FOOD_COMPONENTS_CUSTOM_FOOD
  ],
  [Permission.EXPORT_BUSINESS_ORDERS]: [Permission.VIEW_BUSINESS_DELIVERY_LIST, Permission.EXPORT_BUSINESS_ORDERS],
  [Permission.UPDATE_DELIVERY_CUSTOM_FOOD]: [
    Permission.VIEW_DELIVERY_LIST,
    Permission.VIEW_DELIVERY,
    Permission.VIEW_FOOD_COMPONENTS_CUSTOM_FOOD,
    Permission.UPDATE_DELIVERY_CUSTOM_FOOD
  ],
  [Permission.VIEW_USER_CUSTOM_FOOD]: [
    Permission.VIEW_DELIVERY_LIST,
    Permission.VIEW_DELIVERY,
    Permission.VIEW_FOOD_COMPONENTS_CUSTOM_FOOD,
    Permission.VIEW_USER_CUSTOM_FOOD
  ],
  [Permission.CREATE_USER_CUSTOM_FOOD]: [
    Permission.VIEW_DELIVERY_LIST,
    Permission.VIEW_DELIVERY,
    Permission.VIEW_FOOD_COMPONENTS_CUSTOM_FOOD,
    Permission.VIEW_USER_CUSTOM_FOOD,
    Permission.CREATE_USER_CUSTOM_FOOD
  ],

  [Permission.VIEW_ADDON_SUBSCRIPTION_MENU]: [Permission.VIEW_DELIVERY_LIST, Permission.VIEW_ADDON_SUBSCRIPTION_MENU],
  [Permission.REPLACE_ADDON_SUBSCRIPTION_DELIVERY_MENU]: [
    Permission.VIEW_DELIVERY_LIST,
    Permission.VIEW_ADDON_SUBSCRIPTION_MENU,
    Permission.REPLACE_ADDON_SUBSCRIPTION_DELIVERY_MENU
  ],

  [Permission.VIEW_USER_LIST]: [Permission.VIEW_USER_LIST],
  [Permission.VIEW_USER]: [Permission.VIEW_USER_LIST, Permission.VIEW_USER],
  [Permission.CREATE_USER]: [Permission.VIEW_USER_LIST, Permission.VIEW_USER, Permission.CREATE_USER],
  [Permission.UPDATE_USER]: [
    Permission.VIEW_USER_LIST,
    Permission.VIEW_USER,
    Permission.UPDATE_USER,
    Permission.VIEW_USER_PERMISSIONS_LIST
  ],
  [Permission.RESET_USER_PASSWORD]: [Permission.VIEW_USER_LIST, Permission.VIEW_USER, Permission.RESET_USER_PASSWORD],
  [Permission.DELETE_USER]: [Permission.VIEW_USER_LIST, Permission.VIEW_USER, Permission.DELETE_USER],

  [Permission.VIEW_KDS_USER_LIST]: [Permission.VIEW_KDS_USER_LIST],
  [Permission.VIEW_KDS_USER]: [Permission.VIEW_KDS_USER_LIST, Permission.VIEW_KDS_USER],
  [Permission.CREATE_KDS_USER]: [Permission.VIEW_KDS_USER_LIST, Permission.VIEW_KDS_USER, Permission.CREATE_KDS_USER],
  [Permission.UPDATE_KDS_USER]: [Permission.VIEW_KDS_USER_LIST, Permission.VIEW_KDS_USER, Permission.UPDATE_KDS_USER],
  [Permission.RESET_KDS_USER_PASSWORD]: [
    Permission.VIEW_KDS_USER_LIST,
    Permission.VIEW_KDS_USER,
    Permission.RESET_KDS_USER_PASSWORD
  ],
  [Permission.DELETE_KDS_USER]: [Permission.VIEW_KDS_USER_LIST, Permission.VIEW_KDS_USER, Permission.DELETE_KDS_USER],

  [Permission.VIEW_SUPPLY_CHAIN_USER_LIST]: [Permission.VIEW_SUPPLY_CHAIN_USER_LIST],
  [Permission.VIEW_SUPPLY_CHAIN_USER]: [Permission.VIEW_SUPPLY_CHAIN_USER_LIST, Permission.VIEW_SUPPLY_CHAIN_USER],
  [Permission.CREATE_SUPPLY_CHAIN_USER]: [
    Permission.VIEW_SUPPLY_CHAIN_USER_LIST,
    Permission.VIEW_SUPPLY_CHAIN_USER,
    Permission.CREATE_SUPPLY_CHAIN_USER
  ],
  [Permission.UPDATE_SUPPLY_CHAIN_USER]: [
    Permission.VIEW_SUPPLY_CHAIN_USER_LIST,
    Permission.VIEW_SUPPLY_CHAIN_USER,
    Permission.UPDATE_SUPPLY_CHAIN_USER
  ],
  [Permission.RESET_SUPPLY_CHAIN_USER_PASSWORD]: [
    Permission.VIEW_SUPPLY_CHAIN_USER_LIST,
    Permission.VIEW_SUPPLY_CHAIN_USER,
    Permission.RESET_SUPPLY_CHAIN_USER_PASSWORD
  ],
  [Permission.DELETE_SUPPLY_CHAIN_USER]: [
    Permission.VIEW_SUPPLY_CHAIN_USER_LIST,
    Permission.VIEW_SUPPLY_CHAIN_USER,
    Permission.DELETE_SUPPLY_CHAIN_USER
  ],
  [Permission.VIEW_SUPPLY_CHAIN_REPORTS_LIST]: [Permission.VIEW_SUPPLY_CHAIN_REPORTS_LIST],
  [Permission.VIEW_MATERIAL_RESOURCE_PLANING_REPORT]: [
    Permission.VIEW_SUPPLY_CHAIN_REPORTS_LIST,
    Permission.VIEW_MATERIAL_RESOURCE_PLANING_REPORT
  ],
  [Permission.VIEW_MOVING_COST_REPORT]: [Permission.VIEW_SUPPLY_CHAIN_REPORTS_LIST, Permission.VIEW_MOVING_COST_REPORT],

  [Permission.EXPORT_MATERIAL_RESOURCE_PLANING_REPORT]: [
    Permission.EXPORT_MATERIAL_RESOURCE_PLANING_REPORT,
    Permission.VIEW_SUPPLY_CHAIN_REPORTS_LIST,
    Permission.VIEW_MATERIAL_RESOURCE_PLANING_REPORT
  ],
  [Permission.LIST_APP_COGNITO_USERS]: [Permission.LIST_APP_COGNITO_USERS],
  [Permission.UPDATE_APP_COGNITO_USERS]: [Permission.LIST_APP_COGNITO_USERS, Permission.UPDATE_APP_COGNITO_USERS],

  [Permission.VIEW_SUBSCRIPTION_LIST]: [Permission.VIEW_SUBSCRIPTION_LIST],
  [Permission.VIEW_SUBSCRIPTION]: [
    Permission.VIEW_SUBSCRIPTION_LIST,
    Permission.VIEW_SUBSCRIPTION_DELIVERY_LIST,
    Permission.VIEW_SUBSCRIPTION,
    Permission.VIEW_SUBSCRIPTION_LOGS,
    Permission.VIEW_SUBSCRIPTION_WALLET
  ],
  [Permission.UPDATE_SUBSCRIPTION]: [
    Permission.VIEW_SUBSCRIPTION_LIST,
    Permission.VIEW_SUBSCRIPTION,
    Permission.UPDATE_SUBSCRIPTION
  ],
  [Permission.CREATE_SUBSCRIPTION]: [
    Permission.VIEW_SUBSCRIPTION_LIST,
    Permission.VIEW_SUBSCRIPTION,
    Permission.CREATE_SUBSCRIPTION
  ],
  [Permission.PAUSE_SUBSCRIPTION]: [
    Permission.VIEW_SUBSCRIPTION_LIST,
    Permission.VIEW_SUBSCRIPTION,
    Permission.PAUSE_SUBSCRIPTION
  ],
  [Permission.RESUME_SUBSCRIPTION]: [
    Permission.VIEW_SUBSCRIPTION_LIST,
    Permission.VIEW_SUBSCRIPTION,
    Permission.RESUME_SUBSCRIPTION
  ],
  [Permission.CLEAR_SUBSCRIPTION_PAUSE]: [
    Permission.VIEW_SUBSCRIPTION_LIST,
    Permission.VIEW_SUBSCRIPTION,
    Permission.CLEAR_SUBSCRIPTION_PAUSE
  ],
  [Permission.REMOVE_SUBSCRIPTION_SUSPENSION]: [
    Permission.VIEW_SUBSCRIPTION_LIST,
    Permission.VIEW_SUBSCRIPTION,
    Permission.REMOVE_SUBSCRIPTION_SUSPENSION
  ],
  [Permission.CREATE_SUBSCRIPTION_DELIVERY]: [
    Permission.VIEW_SUBSCRIPTION_LIST,
    Permission.VIEW_SUBSCRIPTION,
    Permission.CREATE_SUBSCRIPTION_DELIVERY
  ],
  [Permission.CREATE_SUBSCRIPTION_ADDRESS]: [
    Permission.VIEW_SUBSCRIPTION_LIST,
    Permission.VIEW_SUBSCRIPTION,
    Permission.CREATE_SUBSCRIPTION_ADDRESS
  ],
  [Permission.UPDATE_SUBSCRIPTION_ADDRESS]: [
    Permission.VIEW_SUBSCRIPTION_LIST,
    Permission.VIEW_SUBSCRIPTION,
    Permission.UPDATE_SUBSCRIPTION_ADDRESS
  ],
  [Permission.DELETE_SUBSCRIPTION_ADDRESS]: [
    Permission.VIEW_SUBSCRIPTION_LIST,
    Permission.VIEW_SUBSCRIPTION,
    Permission.DELETE_SUBSCRIPTION_ADDRESS
  ],
  [Permission.CREATE_SUBSCRIPTION_WALLET]: [
    Permission.VIEW_SUBSCRIPTION_LIST,
    Permission.VIEW_SUBSCRIPTION,
    Permission.CREATE_SUBSCRIPTION_WALLET
  ],
  [Permission.VIEW_SUBSCRIPTION_DELIVERY_LIST]: [
    Permission.VIEW_SUBSCRIPTION_LIST,
    Permission.VIEW_SUBSCRIPTION,
    Permission.VIEW_SUBSCRIPTION_DELIVERY_LIST
  ],
  [Permission.VIEW_SUBSCRIPTION_WALLET]: [
    Permission.VIEW_SUBSCRIPTION_LIST,
    Permission.VIEW_SUBSCRIPTION,
    Permission.VIEW_SUBSCRIPTION_WALLET
  ],
  [Permission.VIEW_SUBSCRIPTION_LOGS]: [
    Permission.VIEW_SUBSCRIPTION_LIST,
    Permission.VIEW_SUBSCRIPTION,
    Permission.VIEW_SUBSCRIPTION_LOGS
  ],
  [Permission.UPDATE_SUBSCRIPTION_PLAN]: [
    Permission.VIEW_SUBSCRIPTION_LIST,
    Permission.VIEW_SUBSCRIPTION,
    Permission.UPDATE_SUBSCRIPTION_PLAN
  ],
  [Permission.VIEW_FOOD_PREFERENCE]: [
    Permission.VIEW_SUBSCRIPTION_LIST,
    Permission.VIEW_SUBSCRIPTION,
    Permission.UPDATE_SUBSCRIPTION,
    Permission.VIEW_FOOD_PREFERENCE
  ],
  [Permission.UPDATE_FOOD_PREFERENCE]: [
    Permission.VIEW_SUBSCRIPTION_LIST,
    Permission.VIEW_SUBSCRIPTION,
    Permission.UPDATE_SUBSCRIPTION,
    Permission.UPDATE_FOOD_PREFERENCE
  ],

  [Permission.VIEW_ADDON_SUBSCRIPTION_LIST]: [Permission.VIEW_SUBSCRIPTION_LIST, Permission.VIEW_ADDON_SUBSCRIPTION_LIST],
  [Permission.ADD_ADDON_SUBSCRIPTION]: [
    Permission.VIEW_SUBSCRIPTION_LIST,
    Permission.VIEW_ADDON_SUBSCRIPTION_LIST,
    Permission.ADD_ADDON_SUBSCRIPTION
  ],
  [Permission.REMOVE_ADDON_SUBSCRIPTION]: [
    Permission.VIEW_SUBSCRIPTION_LIST,
    Permission.VIEW_ADDON_SUBSCRIPTION_LIST,
    Permission.REMOVE_ADDON_SUBSCRIPTION
  ],

  [Permission.VIEW_MAP]: [Permission.VIEW_MAP],
  [Permission.CREATE_MAP]: [Permission.VIEW_MAP, Permission.CREATE_MAP],
  [Permission.UPDATE_MAP]: [Permission.VIEW_MAP, Permission.UPDATE_MAP],
  [Permission.VIEW_SUPPLY_CAP_ZONES]: [Permission.VIEW_SUPPLY_CAP_ZONES],
  [Permission.UPDATE_SUPPLY_CAP_ZONE]: [Permission.VIEW_SUPPLY_CAP_ZONES, Permission.UPDATE_SUPPLY_CAP_ZONE],
  [Permission.CREATE_SUPPLY_CAP_ZONE]: [Permission.VIEW_SUPPLY_CAP_ZONES, Permission.CREATE_SUPPLY_CAP_ZONE],
  [Permission.DELETE_SUPPLY_CAP_ZONE]: [Permission.VIEW_SUPPLY_CAP_ZONES, Permission.DELETE_SUPPLY_CAP_ZONE],
  [Permission.REGENERATE_ROUTE]: [Permission.VIEW_MAP, Permission.REGENERATE_ROUTE],
  [Permission.VIEW_ROUTE_PLAN_LIST]: [Permission.VIEW_MAP, Permission.VIEW_ROUTE_PLAN_LIST, Permission.VIEW_ROUTE_PLAN],
  [Permission.VIEW_ROUTE_PLAN]: [Permission.VIEW_ROUTE_PLAN],
  [Permission.GET_DRIVER_USERS]: [Permission.VIEW_MAP, Permission.VIEW_ROUTE_PLAN_LIST, Permission.GET_DRIVER_USERS],
  [Permission.VIEW_COMPLETE_ROUTE_PATH]: [
    Permission.VIEW_MAP,
    Permission.VIEW_ROUTE_PLAN,
    Permission.VIEW_ROUTE_PLAN_LIST,
    Permission.VIEW_COMPLETE_ROUTE_PATH
  ],

  [Permission.VIEW_MAP_DELIVERIES_LIST]: [
    Permission.VIEW_MAP,
    Permission.VIEW_ROUTE_PLAN,
    Permission.VIEW_ROUTE_PLAN_LIST,
    Permission.GET_DRIVER_USERS,
    Permission.VIEW_MAP_DELIVERIES_LIST
  ],
  [Permission.VIEW_LIVE_TRACKING]: [
    Permission.VIEW_MAP,
    Permission.VIEW_ROUTE_PLAN,
    Permission.VIEW_ROUTE_PLAN_LIST,
    Permission.GET_DRIVER_USERS,
    Permission.VIEW_LIVE_TRACKING
  ],

  [Permission.VIEW_KITCHEN_LIST]: [Permission.VIEW_KITCHEN_LIST, Permission.VIEW_KITCHEN_STATS],
  [Permission.VIEW_KITCHEN]: [Permission.VIEW_KITCHEN_LIST, Permission.VIEW_KITCHEN],
  [Permission.UPDATE_KITCHEN]: [
    Permission.UPDATE_MAP,
    Permission.VIEW_KITCHEN_LIST,
    Permission.VIEW_KITCHEN,
    Permission.UPDATE_KITCHEN
  ],
  [Permission.VIEW_WAITING_LIST]: [Permission.VIEW_WAITING_LIST],
  [Permission.ALLOW_WAITING_LIST]: [Permission.ALLOW_WAITING_LIST],
  [Permission.BATCH_ALLOW_WAITING_LIST]: [Permission.BATCH_ALLOW_WAITING_LIST],
  [Permission.VIEW_KITCHEN_STATS]: [Permission.VIEW_KITCHEN_LIST, Permission.VIEW_KITCHEN_STATS],
  [Permission.DELETE_WAITING_LIST]: [Permission.VIEW_WAITING_LIST, Permission.DELETE_WAITING_LIST],
  [Permission.SEND_INVITATION_CODE_WAITING_LIST]: [Permission.VIEW_WAITING_LIST, Permission.SEND_INVITATION_CODE_WAITING_LIST],
  [Permission.BATCH_REMOVE_FROM_PRE_LAUNCH_WAITING_LIST]: [
    Permission.VIEW_WAITING_LIST,
    Permission.BATCH_REMOVE_FROM_PRE_LAUNCH_WAITING_LIST
  ],

  [Permission.VIEW_FOOD_STATS]: [Permission.VIEW_FOOD_STATS],
  [Permission.EXPORT_FOOD_STATS]: [Permission.VIEW_FOOD_STATS, Permission.EXPORT_FOOD_STATS],

  [Permission.VIEW_PORTION_STATS]: [Permission.VIEW_PORTION_STATS],
  [Permission.EXPORT_PORTION_STATS]: [Permission.VIEW_PORTION_STATS, Permission.EXPORT_PORTION_STATS],

  [Permission.VIEW_INGREDIENT_STATS]: [Permission.VIEW_INGREDIENT_STATS],
  [Permission.EXPORT_INGREDIENT_STATS]: [Permission.VIEW_INGREDIENT_STATS, Permission.EXPORT_INGREDIENT_STATS],

  [Permission.VIEW_PACKAGE_ELEMENTS_STATS]: [Permission.VIEW_PACKAGE_ELEMENTS_STATS],

  [Permission.VIEW_MENU_LIST]: [Permission.VIEW_MENU_LIST],
  [Permission.VIEW_MENU]: [Permission.VIEW_MENU_LIST, Permission.VIEW_MENU, Permission.VIEW_FOOD],
  [Permission.GENERATE_DAY_MENU]: [Permission.VIEW_MENU_LIST, Permission.GENERATE_DAY_MENU],
  [Permission.UPDATE_MENU]: [Permission.VIEW_MENU_LIST, Permission.VIEW_MENU, Permission.UPDATE_MENU, Permission.VIEW_FOOD],
  [Permission.CREATE_MENU]: [Permission.VIEW_MENU_LIST, Permission.VIEW_MENU, Permission.CREATE_MENU, Permission.VIEW_FOOD],
  [Permission.MENU_GENERATION_LIST]: [Permission.VIEW_MENU_LIST, Permission.VIEW_MENU, Permission.MENU_GENERATION_LIST],
  [Permission.DESIGN_MENU]: [Permission.VIEW_MENU_LIST, Permission.VIEW_MENU, Permission.VIEW_FOOD, Permission.DESIGN_MENU],

  [Permission.VIEW_ADDONS_LIST]: [Permission.VIEW_ADDONS_LIST],
  [Permission.VIEW_ADDONS]: [Permission.VIEW_ADDONS_LIST, Permission.VIEW_ADDONS, Permission.VIEW_FOOD],
  [Permission.UPDATE_ADDONS]: [
    Permission.VIEW_ADDONS_LIST,
    Permission.VIEW_ADDONS,
    Permission.UPDATE_ADDONS,
    Permission.VIEW_FOOD
  ],
  [Permission.CREATE_ADDONS]: [
    Permission.VIEW_ADDONS_LIST,
    Permission.VIEW_ADDONS,
    Permission.CREATE_ADDONS,
    Permission.VIEW_FOOD
  ],
  [Permission.VIEW_ADDONS_SUBSCRIPTION]: [
    Permission.VIEW_ADDONS_SUBSCRIPTION_LIST,
    Permission.VIEW_ADDONS_SUBSCRIPTION,
    Permission.VIEW_FOOD
  ],
  [Permission.UPDATE_ADDONS_SUBSCRIPTION]: [
    Permission.VIEW_ADDONS_SUBSCRIPTION_LIST,
    Permission.VIEW_ADDONS_SUBSCRIPTION,
    Permission.UPDATE_ADDONS_SUBSCRIPTION,
    Permission.VIEW_FOOD
  ],
  [Permission.CREATE_ADDONS_SUBSCRIPTION]: [
    Permission.VIEW_ADDONS_SUBSCRIPTION_LIST,
    Permission.CREATE_ADDONS_SUBSCRIPTION,
    Permission.VIEW_ADDONS_SUBSCRIPTION,
    Permission.UPDATE_ADDONS_SUBSCRIPTION,
    Permission.VIEW_FOOD
  ],

  [Permission.VIEW_FOOD_LIST]: [Permission.VIEW_FOOD_LIST],
  [Permission.VIEW_FOOD]: [Permission.VIEW_FOOD_LIST, Permission.VIEW_FOOD],
  [Permission.EXPORT_FOOD_LIST]: [Permission.VIEW_FOOD_LIST, Permission.EXPORT_FOOD_LIST],
  [Permission.CREATE_FOOD]: [Permission.VIEW_FOOD_LIST, Permission.CREATE_FOOD, Permission.VIEW_FOOD],
  [Permission.UPDATE_FOOD]: [Permission.VIEW_FOOD_LIST, Permission.VIEW_FOOD, Permission.UPDATE_FOOD],
  [Permission.BATCH_UPDATE_FOOD]: [
    Permission.VIEW_FOOD_LIST,
    Permission.VIEW_FOOD,
    Permission.VIEW_FOOD_LIST_BY_SLUG,
    Permission.BATCH_UPDATE_FOOD
  ],
  [Permission.DELETE_FOOD]: [
    Permission.VIEW_FOOD_LIST,
    Permission.VIEW_FOOD,
    Permission.DELETE_FOOD,
    Permission.DELETE_CUSTOM_FOOD
  ],
  [Permission.DELETE_CUSTOM_FOOD]: [Permission.DELETE_CUSTOM_FOOD],
  [Permission.UPLOAD_IMAGE]: [Permission.UPLOAD_IMAGE],
  [Permission.VIEW_FOOD_LIST_BY_SLUG]: [Permission.VIEW_FOOD_LIST, Permission.VIEW_FOOD, Permission.VIEW_FOOD_LIST_BY_SLUG],

  [Permission.VIEW_PROTOTYPE_FOOD_LIST]: [Permission.VIEW_PROTOTYPE_FOOD_LIST],
  [Permission.VIEW_PROTOTYPE_FOOD]: [Permission.VIEW_PROTOTYPE_FOOD],
  [Permission.VIEW_PROTOTYPE_FOOD_LIST_BY_SLUG]: [
    Permission.VIEW_PROTOTYPE_FOOD_LIST_BY_SLUG,
    Permission.VIEW_FOOD,
    Permission.VIEW_PROTOTYPE_FOOD_LIST
  ],
  [Permission.CREATE_PROTOTYPE_FOOD]: [
    Permission.CREATE_PROTOTYPE_FOOD,
    Permission.VIEW_PROTOTYPE_FOOD,
    Permission.VIEW_PROTOTYPE_FOOD_LIST,
    Permission.VIEW_PROTOTYPE_FOOD_LIST_BY_SLUG
  ],
  [Permission.UPDATE_PROTOTYPE_FOOD]: [
    Permission.UPDATE_PROTOTYPE_FOOD,
    Permission.VIEW_PROTOTYPE_FOOD,
    Permission.VIEW_PROTOTYPE_FOOD_LIST,
    Permission.VIEW_PROTOTYPE_FOOD_LIST_BY_SLUG
  ],
  [Permission.BATCH_UPDATE_PROTOTYPE_FOOD]: [
    Permission.BATCH_UPDATE_PROTOTYPE_FOOD,
    Permission.UPDATE_PROTOTYPE_FOOD,
    Permission.VIEW_PROTOTYPE_FOOD,
    Permission.VIEW_PROTOTYPE_FOOD_LIST,
    Permission.VIEW_PROTOTYPE_FOOD_LIST_BY_SLUG
  ],
  [Permission.DELETE_PROTOTYPE_FOOD]: [
    Permission.DELETE_PROTOTYPE_FOOD,
    Permission.VIEW_PROTOTYPE_FOOD,
    Permission.VIEW_PROTOTYPE_FOOD_LIST
  ],
  [Permission.CREATE_ACTION_PROTOTYPE_FOOD]: [
    Permission.CREATE_ACTION_PROTOTYPE_FOOD,
    Permission.VIEW_PROTOTYPE_FOOD,
    Permission.VIEW_PROTOTYPE_FOOD_LIST
  ],
  [Permission.APPROVE_STAGE_1_PROTOTYPE_FOOD]: [
    Permission.APPROVE_STAGE_1_PROTOTYPE_FOOD,
    Permission.VIEW_PROTOTYPE_FOOD,
    Permission.VIEW_PROTOTYPE_FOOD_LIST,
    Permission.VIEW_PROTOTYPE_FOOD_LIST_BY_SLUG
  ],

  [Permission.APPROVE_STAGE_2_PROTOTYPE_FOOD]: [
    Permission.APPROVE_STAGE_2_PROTOTYPE_FOOD,
    Permission.VIEW_PROTOTYPE_FOOD,
    Permission.VIEW_PROTOTYPE_FOOD_LIST,
    Permission.VIEW_PROTOTYPE_FOOD_LIST_BY_SLUG
  ],

  [Permission.VIEW_KITCHEN_LOGS_LIST]: [Permission.VIEW_KITCHEN_LOGS_LIST],
  [Permission.VIEW_FOOD_PACKAGE_LIST]: [Permission.VIEW_FOOD_PACKAGE_LIST],
  [Permission.VIEW_FOOD_PACKAGE]: [Permission.VIEW_FOOD_PACKAGE_LIST, Permission.VIEW_FOOD_PACKAGE],
  [Permission.CREATE_FOOD_PACKAGE]: [
    Permission.VIEW_FOOD_PACKAGE_LIST,
    Permission.CREATE_FOOD_PACKAGE,
    Permission.VIEW_FOOD_PACKAGE
  ],
  [Permission.DELETE_FOOD_PACKAGE]: [
    Permission.VIEW_FOOD_PACKAGE_LIST,
    Permission.VIEW_FOOD_PACKAGE,
    Permission.DELETE_FOOD_PACKAGE
  ],
  [Permission.UPDATE_FOOD_PACKAGE]: [
    Permission.VIEW_FOOD_PACKAGE_LIST,
    Permission.VIEW_FOOD_PACKAGE,
    Permission.UPDATE_FOOD_PACKAGE
  ],
  [Permission.EXPORT_FOOD_PACKAGE]: [
    Permission.EXPORT_FOOD_PACKAGE,
    Permission.VIEW_FOOD_PACKAGE_LIST,
    Permission.VIEW_FOOD_PACKAGE,
    Permission.VIEW_FOOD_PACKAGE_ELEMENT_LIST,
    Permission.VIEW_FOOD_PACKAGE_ELEMENT
  ],

  [Permission.VIEW_FOOD_PACKAGE_ELEMENT_LIST]: [Permission.VIEW_FOOD_PACKAGE_ELEMENT_LIST],
  [Permission.VIEW_FOOD_PACKAGE_ELEMENT]: [Permission.VIEW_FOOD_PACKAGE_ELEMENT, Permission.VIEW_FOOD_PACKAGE_ELEMENT_LIST],
  [Permission.UPDATE_FOOD_PACKAGE_ELEMENT]: [
    Permission.VIEW_FOOD_PACKAGE_ELEMENT_LIST,
    Permission.VIEW_FOOD_PACKAGE_ELEMENT,
    Permission.UPDATE_FOOD_PACKAGE_ELEMENT
  ],
  [Permission.DELETE_FOOD_PACKAGE_ELEMENT]: [
    Permission.VIEW_FOOD_PACKAGE_ELEMENT,
    Permission.VIEW_FOOD_PACKAGE_ELEMENT_LIST,
    Permission.DELETE_FOOD_PACKAGE_ELEMENT
  ],
  [Permission.CREATE_FOOD_PACKAGE_ELEMENT]: [
    Permission.VIEW_FOOD_PACKAGE_ELEMENT_LIST,
    Permission.CREATE_FOOD_PACKAGE_ELEMENT,
    Permission.VIEW_FOOD_PACKAGE_ELEMENT
  ],
  [Permission.EXPORT_FOOD_PACKAGE_ELEMENT]: [
    Permission.EXPORT_FOOD_PACKAGE_ELEMENT,
    Permission.VIEW_FOOD_PACKAGE_ELEMENT_LIST,
    Permission.VIEW_FOOD_PACKAGE_ELEMENT
  ],

  [Permission.VIEW_FOOD_COMPONENTS_LIST]: [Permission.VIEW_FOOD_COMPONENTS_LIST],
  [Permission.VIEW_FOOD_COMPONENTS]: [Permission.VIEW_FOOD_COMPONENTS_LIST, Permission.VIEW_FOOD_COMPONENTS],
  [Permission.UPDATE_FOOD_COMPONENTS]: [
    Permission.VIEW_FOOD_COMPONENTS_LIST,
    Permission.VIEW_FOOD_COMPONENTS,
    Permission.UPDATE_FOOD_COMPONENTS
  ],
  [Permission.DELETE_FOOD_COMPONENT]: [
    Permission.VIEW_FOOD_COMPONENTS_LIST,
    Permission.VIEW_FOOD_COMPONENTS,
    Permission.UPDATE_FOOD_COMPONENTS,
    Permission.DELETE_FOOD_COMPONENT
  ],
  [Permission.CREATE_FOOD_COMPONENTS]: [
    Permission.VIEW_FOOD_COMPONENTS_LIST,
    Permission.VIEW_FOOD_COMPONENTS,
    Permission.CREATE_FOOD_COMPONENTS
  ],
  [Permission.EXPORT_FOOD_COMPONENTS_LIST]: [Permission.VIEW_FOOD_COMPONENTS_LIST, Permission.EXPORT_FOOD_COMPONENTS_LIST],

  [Permission.VIEW_PROTOTYPE_COMPONENT_LIST]: [Permission.VIEW_PROTOTYPE_COMPONENT_LIST],
  [Permission.VIEW_PROTOTYPE_COMPONENT]: [Permission.VIEW_PROTOTYPE_COMPONENT],
  [Permission.CREATE_PROTOTYPE_COMPONENT]: [
    Permission.CREATE_PROTOTYPE_COMPONENT,
    Permission.VIEW_PROTOTYPE_COMPONENT,
    Permission.VIEW_PROTOTYPE_COMPONENT_LIST
  ],
  [Permission.UPDATE_PROTOTYPE_COMPONENT]: [
    Permission.UPDATE_PROTOTYPE_COMPONENT,
    Permission.VIEW_PROTOTYPE_COMPONENT,
    Permission.VIEW_PROTOTYPE_COMPONENT_LIST
  ],
  [Permission.DELETE_PROTOTYPE_COMPONENT]: [
    Permission.DELETE_PROTOTYPE_COMPONENT,
    Permission.VIEW_PROTOTYPE_COMPONENT,
    Permission.VIEW_PROTOTYPE_COMPONENT_LIST
  ],
  [Permission.CREATE_ACTION_PROTOTYPE_COMPONENT]: [
    Permission.CREATE_ACTION_PROTOTYPE_COMPONENT,
    Permission.VIEW_PROTOTYPE_COMPONENT,
    Permission.VIEW_PROTOTYPE_COMPONENT_LIST
  ],
  [Permission.APPROVE_STAGE_1_PROTOTYPE_FOOD_COMPONENT]: [
    Permission.APPROVE_STAGE_1_PROTOTYPE_FOOD_COMPONENT,
    Permission.VIEW_PROTOTYPE_COMPONENT,
    Permission.VIEW_PROTOTYPE_COMPONENT_LIST
  ],
  [Permission.APPROVE_STAGE_2_PROTOTYPE_FOOD_COMPONENT]: [
    Permission.APPROVE_STAGE_2_PROTOTYPE_FOOD_COMPONENT,
    Permission.VIEW_PROTOTYPE_COMPONENT,
    Permission.VIEW_PROTOTYPE_COMPONENT_LIST
  ],

  [Permission.VIEW_INGREDIENT_LIST]: [Permission.VIEW_INGREDIENT_LIST],
  [Permission.VIEW_INGREDIENT]: [Permission.VIEW_INGREDIENT_LIST, Permission.VIEW_INGREDIENT],
  [Permission.UPDATE_INGREDIENT]: [Permission.VIEW_INGREDIENT_LIST, Permission.UPDATE_INGREDIENT],
  [Permission.DELETE_INGREDIENT]: [
    Permission.VIEW_INGREDIENT_LIST,
    Permission.UPDATE_INGREDIENT,
    Permission.DELETE_INGREDIENT,
    Permission.VIEW_INGREDIENT
  ],
  [Permission.CREATE_INGREDIENT]: [Permission.VIEW_INGREDIENT_LIST, Permission.UPDATE_INGREDIENT, Permission.CREATE_INGREDIENT],
  [Permission.EXPORT_INGREDIENT_LIST]: [Permission.VIEW_INGREDIENT_LIST, Permission.EXPORT_INGREDIENT_LIST],

  [Permission.VIEW_PROTOTYPE_INGREDIENT_LIST]: [Permission.VIEW_PROTOTYPE_INGREDIENT_LIST],
  [Permission.VIEW_PROTOTYPE_INGREDIENT]: [Permission.VIEW_PROTOTYPE_INGREDIENT],
  [Permission.CREATE_PROTOTYPE_INGREDIENT]: [
    Permission.CREATE_PROTOTYPE_INGREDIENT,
    Permission.VIEW_PROTOTYPE_INGREDIENT,
    Permission.VIEW_PROTOTYPE_INGREDIENT_LIST
  ],
  [Permission.UPDATE_PROTOTYPE_INGREDIENT]: [
    Permission.UPDATE_PROTOTYPE_INGREDIENT,
    Permission.VIEW_PROTOTYPE_INGREDIENT,
    Permission.VIEW_PROTOTYPE_INGREDIENT_LIST
  ],
  [Permission.DELETE_PROTOTYPE_INGREDIENT]: [
    Permission.DELETE_PROTOTYPE_INGREDIENT,
    Permission.VIEW_PROTOTYPE_INGREDIENT,
    Permission.VIEW_PROTOTYPE_INGREDIENT_LIST
  ],
  [Permission.CREATE_ACTION_PROTOTYPE_INGREDIENT]: [
    Permission.CREATE_ACTION_PROTOTYPE_INGREDIENT,
    Permission.VIEW_PROTOTYPE_INGREDIENT,
    Permission.VIEW_PROTOTYPE_INGREDIENT_LIST
  ],
  [Permission.APPROVE_PROTOTYPE_INGREDIENT]: [
    Permission.APPROVE_PROTOTYPE_INGREDIENT,
    Permission.VIEW_PROTOTYPE_INGREDIENT,
    Permission.VIEW_PROTOTYPE_INGREDIENT_LIST
  ],

  [Permission.VIEW_REFUND_LIST]: [Permission.VIEW_REFUND_LIST],
  [Permission.CREATE_REFUND]: [Permission.VIEW_REFUND_LIST, Permission.CREATE_REFUND],
  [Permission.EXPORT_REFUND_LIST]: [Permission.VIEW_REFUND_LIST, Permission.EXPORT_REFUND_LIST],

  [Permission.VIEW_COUPON_LIST]: [Permission.VIEW_COUPON_LIST],
  [Permission.VIEW_COUPON]: [Permission.VIEW_COUPON_LIST, Permission.VIEW_COUPON],
  [Permission.VIEW_COUPON_USAGE_LIST]: [Permission.VIEW_COUPON_LIST, Permission.VIEW_COUPON, Permission.VIEW_COUPON_USAGE_LIST],
  [Permission.UPDATE_COUPON]: [Permission.VIEW_COUPON_LIST, Permission.VIEW_COUPON, Permission.UPDATE_COUPON],
  [Permission.CREATE_COUPON]: [Permission.VIEW_COUPON_LIST, Permission.VIEW_COUPON, Permission.CREATE_COUPON],
  [Permission.DELETE_COUPON]: [Permission.VIEW_COUPON_LIST, Permission.VIEW_COUPON, Permission.DELETE_COUPON],

  [Permission.VIEW_WALLET_LIST]: [Permission.VIEW_WALLET_LIST],
  [Permission.EXPORT_WALLET_LIST]: [Permission.EXPORT_WALLET_LIST],
  [Permission.SYNC_SUBSCRIPTION_BALANCE]: [Permission.SYNC_SUBSCRIPTION_BALANCE],

  [Permission.VIEW_SUBSCRIPTION_TRANSACTIONS]: [Permission.VIEW_SUBSCRIPTION_TRANSACTIONS],

  [Permission.VIEW_INVITATION_CODE_STATS]: [Permission.VIEW_INVITATION_CODE_STATS],
  [Permission.VIEW_INVITATION_CODE_USER_STATS]: [Permission.VIEW_INVITATION_CODE_USER_STATS],
  [Permission.CREATE_INVITATION_CODE]: [Permission.CREATE_INVITATION_CODE],
  [Permission.CREATE_BATCH_INVITATION_CODE]: [Permission.CREATE_BATCH_INVITATION_CODE],

  [Permission.VIEW_DRIVER_LIST]: [Permission.VIEW_DRIVER_LIST],
  [Permission.VIEW_DRIVER]: [Permission.VIEW_DRIVER_LIST, Permission.VIEW_DRIVER],
  [Permission.CREATE_DRIVER]: [Permission.CREATE_DRIVER, Permission.VIEW_DRIVER_LIST, Permission.VIEW_DRIVER],
  [Permission.UPDATE_DRIVER]: [Permission.VIEW_DRIVER_LIST, Permission.VIEW_DRIVER, Permission.UPDATE_DRIVER],
  [Permission.DELETE_DRIVER]: [Permission.VIEW_DRIVER_LIST, Permission.VIEW_DRIVER, Permission.DELETE_DRIVER],
  [Permission.VIEW_DRIVER_STATS]: [Permission.VIEW_DRIVER_STATS],
  [Permission.RESET_DRIVER_PASSWORD]: [Permission.VIEW_DRIVER_LIST, Permission.VIEW_DRIVER, Permission.RESET_DRIVER_PASSWORD],

  [Permission.EXPORT_SUBSCRIPTION_LIST]: [Permission.EXPORT_SUBSCRIPTION_LIST],
  [Permission.EXPORT_DELIVERY_LIST]: [Permission.EXPORT_DELIVERY_LIST],
  [Permission.SEARCH_SUBSCRIPTION]: [Permission.SEARCH_SUBSCRIPTION],
  [Permission.VIEW_RATING_LIST]: [Permission.VIEW_RATING_LIST],
  [Permission.EXPORT_RATING_LIST]: [Permission.VIEW_RATING_LIST, Permission.EXPORT_RATING_LIST],
  [Permission.VIEW_TICKET_LIST]: [Permission.VIEW_TICKET_LIST],
  [Permission.UPDATE_TICKET]: [Permission.VIEW_TICKET_LIST, Permission.UPDATE_TICKET],
  [Permission.VIEW_CUTLERY_AND_COOLER_BAG_STATS]: [Permission.VIEW_CUTLERY_AND_COOLER_BAG_STATS],

  [Permission.LIST_GROUP_GIFT]: [Permission.LIST_GROUP_GIFT],
  [Permission.DOWNLOAD_GROUP_GIFT_LISTS]: [Permission.LIST_GROUP_GIFT, Permission.DOWNLOAD_GROUP_GIFT_LISTS],
  [Permission.CREATE_GROUP_GIFT]: [Permission.LIST_GROUP_GIFT, Permission.CREATE_GROUP_GIFT],
  [Permission.AUTO_CREATE_GROUP_GIFT]: [Permission.LIST_GROUP_GIFT, Permission.AUTO_CREATE_GROUP_GIFT],
  [Permission.APPLY_GROUP_GIFT]: [Permission.CREATE_GROUP_GIFT, Permission.LIST_GROUP_GIFT, Permission.APPLY_GROUP_GIFT],
  [Permission.AUTO_APPLY_GROUP_GIFT]: [
    Permission.AUTO_CREATE_GROUP_GIFT,
    Permission.LIST_GROUP_GIFT,
    Permission.AUTO_APPLY_GROUP_GIFT
  ],
  [Permission.UPDATE_GROUP_GIFT]: [
    Permission.LIST_GROUP_GIFT,
    Permission.UPDATE_GROUP_GIFT,
    Permission.APPLY_GROUP_GIFT,
    Permission.CREATE_GROUP_GIFT
  ],
  [Permission.LIST_GROUP_GIFT_ITEM]: [
    Permission.LIST_GROUP_GIFT,
    Permission.UPDATE_GROUP_GIFT,
    Permission.APPLY_GROUP_GIFT,
    Permission.LIST_GROUP_GIFT_ITEM
  ],
  [Permission.VALIDATE_GROUP_GIFT]: [
    Permission.LIST_GROUP_GIFT,
    Permission.UPDATE_GROUP_GIFT,
    Permission.APPLY_GROUP_GIFT,
    Permission.VALIDATE_GROUP_GIFT,
    Permission.CREATE_GROUP_GIFT
  ],
  [Permission.DELETE_GROUP_GIFT_ITEM]: [
    Permission.LIST_GROUP_GIFT,
    Permission.UPDATE_GROUP_GIFT,
    Permission.APPLY_GROUP_GIFT,
    Permission.DELETE_GROUP_GIFT_ITEM,
    Permission.LIST_GROUP_GIFT_ITEM
  ],

  [Permission.LIST_DELIVERY_TICKET]: [Permission.LIST_DELIVERY_TICKET],
  [Permission.LIST_EXTRA_MEALS]: [Permission.LIST_DELIVERY_TICKET, Permission.LIST_EXTRA_MEALS],
  [Permission.CREATE_DELIVERY_TICKET]: [
    Permission.LIST_DELIVERY_TICKET,
    Permission.LIST_EXTRA_MEALS,
    Permission.CREATE_DELIVERY_TICKET
  ],
  [Permission.SET_DELIVERY_FOOD_MENU]: [
    Permission.LIST_DELIVERY_TICKET,
    Permission.LIST_EXTRA_MEALS,
    Permission.CREATE_DELIVERY_TICKET,
    Permission.SET_DELIVERY_FOOD_MENU
  ],

  [Permission.VIEW_LOYALTY_POINTS]: [Permission.VIEW_LOYALTY_POINTS],
  [Permission.CREATE_LOYALTY_POINTS]: [Permission.VIEW_LOYALTY_POINTS, Permission.CREATE_LOYALTY_POINTS],

  [Permission.VIEW_REFERRAL]: [Permission.VIEW_REFERRAL],
  [Permission.WITHDRAW_REFERRAL_BALANCE]: [Permission.VIEW_REFERRAL, Permission.WITHDRAW_REFERRAL_BALANCE],

  [Permission.VIEW_BUSINESS_USER_LIST]: [Permission.VIEW_BUSINESS_USER_LIST],
  [Permission.VIEW_BUSINESS_USER]: [Permission.VIEW_BUSINESS_USER_LIST, Permission.VIEW_BUSINESS_USER],
  [Permission.CREATE_BUSINESS_USER]: [
    Permission.VIEW_BUSINESS_USER_LIST,
    Permission.CREATE_BUSINESS_USER,
    Permission.VIEW_BUSINESS_USER
  ],
  [Permission.UPDATE_BUSINESS_USER]: [
    Permission.VIEW_BUSINESS_USER_LIST,
    Permission.UPDATE_BUSINESS_USER,
    Permission.VIEW_BUSINESS_USER
  ],

  [Permission.VIEW_BUSINESS_DELIVERY_LIST]: [Permission.VIEW_BUSINESS_DELIVERY_LIST],
  [Permission.VIEW_BUSINESS_DELIVERY]: [Permission.VIEW_BUSINESS_DELIVERY_LIST, Permission.VIEW_BUSINESS_DELIVERY],
  [Permission.CREATE_BUSINESS_DELIVERY]: [
    Permission.VIEW_BUSINESS_DELIVERY_LIST,
    Permission.VIEW_BUSINESS_DELIVERY,
    Permission.CREATE_BUSINESS_DELIVERY
  ],
  [Permission.UPDATE_BUSINESS_DELIVERY]: [
    Permission.VIEW_BUSINESS_DELIVERY_LIST,
    Permission.VIEW_BUSINESS_DELIVERY,
    Permission.UPDATE_BUSINESS_DELIVERY
  ],
  [Permission.UPDATE_BUSINESS_DELIVERY_ADDRESS]: [
    Permission.UPDATE_BUSINESS_DELIVERY_ADDRESS,
    Permission.VIEW_BUSINESS_DELIVERY_LIST,
    Permission.VIEW_BUSINESS_DELIVERY,
    Permission.UPDATE_BUSINESS_DELIVERY
  ],
  [Permission.DELETE_BUSINESS_DELIVERY]: [
    Permission.VIEW_BUSINESS_DELIVERY_LIST,
    Permission.VIEW_BUSINESS_DELIVERY,
    Permission.UPDATE_BUSINESS_DELIVERY,
    Permission.DELETE_BUSINESS_DELIVERY
  ],

  [Permission.LIST_CUSTOMER_COMPLAINT]: [Permission.LIST_CUSTOMER_COMPLAINT],
  [Permission.CREATE_CUSTOMER_COMPLAINT]: [
    Permission.CREATE_CUSTOMER_COMPLAINT,
    Permission.LIST_CUSTOMER_COMPLAINT,
    Permission.UPLOAD_IMAGE
  ],
  [Permission.UPDATE_CUSTOMER_COMPLAINT]: [
    Permission.UPDATE_CUSTOMER_COMPLAINT,
    Permission.LIST_CUSTOMER_COMPLAINT,
    Permission.CREATE_CUSTOMER_COMPLAINT,
    Permission.UPLOAD_IMAGE
  ],
  [Permission.EXPORT_COMPLAINT_LIST]: [Permission.EXPORT_COMPLAINT_LIST, Permission.LIST_CUSTOMER_COMPLAINT],
  [Permission.HEALTH_CONDITIONS_PLAN_MANAGEMENT]: [Permission.HEALTH_CONDITIONS_PLAN_MANAGEMENT],

  [Permission.LIST_GIFTS]: [Permission.LIST_GIFTS],
  [Permission.CREATE_GIFT]: [Permission.CREATE_GIFT, Permission.LIST_GIFTS, Permission.UPLOAD_IMAGE],
  [Permission.UPDATE_GIFT]: [Permission.UPDATE_GIFT, Permission.LIST_GIFTS, Permission.CREATE_GIFT, Permission.UPLOAD_IMAGE],
  [Permission.EXPORT_GIFTS_LIST]: [Permission.EXPORT_GIFTS_LIST, Permission.LIST_GIFTS],
  [Permission.DELETE_GIFT]: [Permission.DELETE_GIFT, Permission.LIST_GIFTS],
  [Permission.VIEW_SUBSCRIPTION_DAY_PROGRESS]: [
    Permission.VIEW_SUBSCRIPTION_LIST,
    Permission.VIEW_SUBSCRIPTION,
    Permission.VIEW_SUBSCRIPTION_DAY_PROGRESS
  ],
  [Permission.UPDATE_SUBSCRIPTION_DAY_PROGRESS]: [
    Permission.VIEW_SUBSCRIPTION_LIST,
    Permission.VIEW_SUBSCRIPTION,
    Permission.VIEW_SUBSCRIPTION_DAY_PROGRESS,
    Permission.UPDATE_SUBSCRIPTION_DAY_PROGRESS
  ],
  [Permission.VIEW_EMPLOYEE_PERKS]: [Permission.VIEW_EMPLOYEE_PERKS],
  [Permission.UPDATE_EMPLOYEE_PERKS]: [Permission.UPDATE_EMPLOYEE_PERKS, Permission.VIEW_EMPLOYEE_PERKS],
  [Permission.EXPORT_EMPLOYEE_PERKS]: [Permission.EXPORT_EMPLOYEE_PERKS, Permission.VIEW_EMPLOYEE_PERKS],
  [Permission.VIEW_DEBUG_OPTIONS]: [Permission.VIEW_DEBUG_OPTIONS],

  [Permission.VIEW_KDS_PERMISSIONS_LIST]: [Permission.VIEW_KDS_PERMISSIONS_LIST],
  [Permission.CREATE_KDS_PERMISSIONS]: [Permission.VIEW_KDS_PERMISSIONS_LIST, Permission.CREATE_KDS_PERMISSIONS],
  [Permission.UPDATE_KDS_PERMISSIONS]: [Permission.VIEW_KDS_PERMISSIONS_LIST, Permission.UPDATE_KDS_PERMISSIONS],
  [Permission.DELETE_KDS_PERMISSIONS]: [Permission.VIEW_KDS_PERMISSIONS_LIST, Permission.DELETE_KDS_PERMISSIONS],
  [Permission.VIEW_RETAIL_MENU_ITEMS]: [Permission.VIEW_RETAIL_MENU_ITEMS],
  [Permission.UPDATE_RETAIL_MENU_ITEMS]: [Permission.UPDATE_RETAIL_MENU_ITEMS],
  [Permission.CREATE_RETAIL_MENU_ITEMS]: [Permission.CREATE_RETAIL_MENU_ITEMS],
  [Permission.DELETE_RETAIL_MENU_ITEMS]: [Permission.DELETE_RETAIL_MENU_ITEMS],
  [Permission.VIEW_RETAIL_ORDER]: [Permission.VIEW_RETAIL_ORDER],
  [Permission.UPDATE_RETAIL_ORDER]: [Permission.UPDATE_RETAIL_ORDER],
  [Permission.GET_DOCUMENTS]: [Permission.GET_DOCUMENTS]
};
