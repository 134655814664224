import { Box, Card, Typography } from '@mui/material';
import { ComponentMethod } from 'components';
import { FormikErrors } from 'formik';
import { FormOperation } from 'lib/enums';
import { getComponentPermission } from 'lib/helpers/permissionUtils';
import { useUserRoles } from 'lib/hooks';
import { BaseOmit, FoodComponent } from 'lib/interfaces';

interface ComponentMethodCardProps {
  prototype?: boolean;
  operation: FormOperation;
  values: Omit<FoodComponent, BaseOmit>;
  errors: FormikErrors<Omit<FoodComponent, BaseOmit>>;
  isDisabled?: boolean;
  isEdit: boolean;
  setIsEdit: React.Dispatch<React.SetStateAction<boolean>>;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<FormikErrors<Omit<FoodComponent, BaseOmit>>> | Promise<any>;
  setValues: (
    values: React.SetStateAction<Omit<FoodComponent, BaseOmit>>,
    shouldValidate?: boolean | undefined
  ) => Promise<FormikErrors<Omit<FoodComponent, BaseOmit>>> | Promise<any>;
}

const ComponentMethodCard = ({
  operation,
  prototype,
  values,
  isDisabled,
  isEdit,
  setIsEdit,
  setValues,
  setFieldValue
}: ComponentMethodCardProps) => {
  const userRoles = useUserRoles();

  const permission = getComponentPermission(userRoles, operation, prototype);

  return (
    <Card
      variant="outlined"
      sx={{
        marginTop: '16px',
        border: 'none',
        borderRadius: '8px',
        boxShadow: 2
      }}
    >
      <Typography sx={{ fontSize: '24px', fontWeight: 400, lineHeight: '150%', padding: '16px' }}>Method</Typography>
      <Box sx={{ padding: '24px 16px 16px 16px' }}>
        <ComponentMethod
          values={values}
          isEdit={isEdit}
          uploadImages={true}
          setValues={(v) => setValues(v)}
          setIsEdit={(value) => setIsEdit(value)}
          disabled={!permission || !!isDisabled}
          setFieldValue={(data: any) => setFieldValue('method', data)}
        />
      </Box>
    </Card>
  );
};

export default ComponentMethodCard;
