import { Box, Stack, Typography } from '@mui/material';
import { unionBy } from 'lodash-es';
import { useEffect, useMemo, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { CreateMenuReq, UpdateMenuReq } from '@calo/dashboard-types';
import { Kitchen } from '@calo/types';
import { Button } from '@mui/material-v6';
import { caloTheme } from 'assets/images/theme/calo';
import { format, getYear } from 'date-fns';
import { findStartAndEndDateForWeek, getWeekDates, handleMealCost } from 'lib/helpers';
import { Food, MenuFood } from 'lib/interfaces';
import { toast } from 'react-toastify';
import { DailyMenuDatePicker, FilterButton, SearchMealsField } from 'views/Menu/MainMenu/components';
import { CaloLoader } from '..';
import { ModalRef } from '../Modal';
import Popup from '../Popup';
import { selectedMenuFilters } from './AddMealsInterfaces';
import ConfirmationPopup from './ConfirmationPopup';
import FilterSection from './FilterSection';
import FoodItemCard from './FoodItemCard';

export interface AddMealsByFiltersProps {
  foodIds: string[];
  filtersOn: boolean;
  selectedWeek?: number;
  isLoading: boolean;
  hasNextPage: boolean;
  isFetchingNextPage: boolean;
  selectedYear?: number;
  foodListFiltersData: Food[];
  addMealsByFiltersRef: React.MutableRefObject<ModalRef | undefined>;
  handleClosePopup: () => void;
  values: CreateMenuReq | UpdateMenuReq;
  selectedMenuFilters: selectedMenuFilters;
  handleReplaceFood: (food: Food[]) => void;
  foodToReplace?: { food: MenuFood; menuId?: string };
  handleFoodChanges: (food: Food[], selectedDay?: Date) => void;
  setSelectedMenuFilters: (value: selectedMenuFilters) => void;
  fetchNextPage: any;
  onDateSelected?: (selectedDate: Date) => void;
}

const AddMealsByFilters = ({
  hasNextPage,
  isFetchingNextPage,
  fetchNextPage,
  foodIds,
  handleReplaceFood,
  handleFoodChanges,
  handleClosePopup,
  foodListFiltersData,
  isLoading,
  values,
  foodToReplace,
  setSelectedMenuFilters,
  selectedMenuFilters,
  selectedWeek,
  onDateSelected,
  selectedYear = getYear(new Date())
}: AddMealsByFiltersProps) => {
  const replaceConfirmationRef = useRef<ModalRef>();
  const debounceRef = useRef<NodeJS.Timeout | null>(null);
  const observer = useRef<IntersectionObserver | null>(null);
  const lastFoodElementRef = useRef<HTMLDivElement | null>(null);

  const [searchTerm, setSearchTerm] = useState<string>('');
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [selectedFoodReplace, setSelectedFoodReplace] = useState<Food | undefined>(undefined);
  const [selectedFoodList, setSelectedFoodList] = useState<Food[]>([]);

  const [showFilters, setShowFilters] = useState(false);

  useEffect(() => {
    if (selectedWeek) {
      setSelectedDate(findStartAndEndDateForWeek(selectedWeek, selectedYear).startOfWeekDate);
    }
  }, [selectedWeek]);

  const allFoodList = useMemo(() => {
    const validSizes = ['XS', 'S', 'M', 'L'];
    const filteredFoodList = foodListFiltersData.filter((food) => validSizes.includes(food.size));

    const unifiedFoodList = unionBy(filteredFoodList, 'name.en');
    return unifiedFoodList.filter((food) => food.name.en.toLowerCase().includes(searchTerm.toLowerCase()));
  }, [foodListFiltersData, searchTerm]);

  const replaceFoodList = foodToReplace ? allFoodList.filter((food) => food.name.en !== foodToReplace.food.name.en) : allFoodList;
  let filteredList = replaceFoodList.filter((food) => !foodIds?.includes(food.id));

  if (selectedMenuFilters.menuLabel) {
    filteredList = filteredList.filter((food) => {
      const menuLabel = food.menuLabels?.find((label) => label.label === selectedMenuFilters.menuLabel);
      if (!menuLabel) {
        return true;
      }
      if (menuLabel.startDay <= format(selectedDate, 'yyyy-MM-dd') && menuLabel.endDay >= format(selectedDate, 'yyyy-MM-dd')) {
        return true;
      }
      return false;
    });
  }

  const handleCalculateMealCost = (meal: any) => handleMealCost(meal.name.en, foodListFiltersData, values.kitchen || Kitchen.BH1);

  const handleObserver = (entities: any) => {
    const target = entities[0];
    if (target?.isIntersecting && hasNextPage) {
      fetchNextPage();
    }
  };

  useEffect(() => {
    observer.current = new IntersectionObserver(handleObserver, {
      threshold: 1.0
    });
    if (lastFoodElementRef.current) {
      observer.current.observe(lastFoodElementRef.current);
    }
    return () => {
      if (lastFoodElementRef.current) {
        observer.current?.unobserve(lastFoodElementRef.current);
      }
    };
  }, [replaceFoodList.length]);

  const selectFoodItemHandler = (selectedFood: Food) => {
    if (foodToReplace) {
      setSelectedFoodReplace(selectedFood);
      replaceConfirmationRef.current?.open();
    } else {
      if (selectedFoodList.find((food) => food.id === selectedFood.id)) {
        setSelectedFoodList((prev) => prev.filter((food) => food.name.en !== selectedFood.name.en));
        return;
      }

      const selectedFoodSizes = foodListFiltersData.filter(
        (food) => food.name.en === selectedFood.name.en && ['XS', 'S', 'M', 'L'].includes(food.size)
      );

      const updatedFoodList = [...selectedFoodList, ...selectedFoodSizes];
      setSelectedFoodList(updatedFoodList);
    }
  };

  const addSelectedMealsHandler = () => {
    handleFoodChanges(selectedFoodList, selectedDate);
    setSelectedFoodList([]);
    closePopupHandler();
    toast('Food Added Successfully', { type: 'success' });
  };

  const handleSearchChange = (value: string) => {
    setSelectedMenuFilters({ ...selectedMenuFilters, mealName: value });
    setSearchTerm(value);
    if (debounceRef.current) {
      clearTimeout(debounceRef.current);
    }
    debounceRef.current = setTimeout(() => {
      setSelectedMenuFilters({ ...selectedMenuFilters, mealName: value });
    }, 500);
  };

  const closePopupHandler = () => {
    setSelectedFoodReplace(undefined);
    handleClosePopup();
  };

  const confirmReplaceHandler = () => {
    const foodSizes = foodListFiltersData.filter(
      (food) => selectedFoodReplace && food.name.en === selectedFoodReplace.name.en && ['XS', 'S', 'M', 'L'].includes(food.size)
    );
    handleReplaceFood(foodSizes);
    closePopupHandler();
    toast('Food Swapped Successfully', { type: 'success' });
  };

  useEffect(() => {
    if (!selectedWeek || !onDateSelected) {
      return;
    }
    const { startOfWeekDate } = findStartAndEndDateForWeek(selectedWeek);
    onDateSelected(startOfWeekDate);
  }, []);

  const resetFilters = () => {
    setSelectedMenuFilters({
      base: '',
      category: '',
      lastUsed: '',
      mealName: '',
      menuLabel: '',
      plan: '',
      protein: '',
      sandwich: '',
      taste: '',
      type: ''
    });
  };

  return (
    <Stack sx={{ width: '826px' }}>
      <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
        {!foodToReplace?.food.name && <Typography sx={{ fontSize: '24px' }}>Add Meals</Typography>}
        {foodToReplace?.food.name && <Typography sx={{ fontSize: '24px' }}>Swap {foodToReplace.food.name.en} with</Typography>}
      </Stack>
      <Stack sx={{ flexDirection: 'row', marginTop: 2, justifyContent: 'space-between', gap: 2 }}>
        <SearchMealsField value={searchTerm} width="100%" onSearch={handleSearchChange} />
        <Stack sx={{ gap: 2 }}>
          <FilterButton
            onClick={() => {
              resetFilters();
              setShowFilters((prev) => !prev);
            }}
            isActive={showFilters}
          />
        </Stack>
        {selectedWeek && !foodToReplace && (
          <DailyMenuDatePicker
            selectedDay={selectedDate}
            enabledDays={getWeekDates(selectedWeek, selectedYear).map((day) => format(day, 'yyyy-MM-dd'))}
            setSelectedDay={(day: Date) => {
              setSelectedDate(day);
              if (onDateSelected) {
                onDateSelected(day);
              }
            }}
            sx={{
              '& .MuiInputBase-root': {
                height: '42px'
              }
            }}
          />
        )}
      </Stack>
      {showFilters && (
        <FilterSection
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          selectedMenuFilters={selectedMenuFilters}
          setSelectedMenuFilters={setSelectedMenuFilters}
        />
      )}
      <InfiniteScroll
        next={fetchNextPage}
        hasMore={hasNextPage}
        scrollableTarget="scrollable"
        loader={isFetchingNextPage || isLoading}
        dataLength={filteredList.length}
      >
        {isLoading ? (
          <CaloLoader />
        ) : filteredList.length > 0 ? (
          <Box
            display="flex"
            flexDirection="column"
            sx={{
              marginTop: 2,
              overflow: 'auto',
              height: '600px',
              '&::-webkit-scrollbar': {
                width: '4px',
                height: '8px'
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: caloTheme.palette.neutral500,
                borderRadius: '4px'
              },
              '&::-webkit-scrollbar-thumb:hover': {
                backgroundColor: '#555'
              },
              '&::-webkit-scrollbar-track': {
                backgroundColor: '#f1f1f1'
              }
            }}
          >
            {filteredList.map((food, index) => (
              <FoodItemCard
                food={food}
                key={food.id}
                isLoading={isLoading}
                isSelected={!!selectedFoodList.find((f) => f.id === food.id)}
                handleAction={selectFoodItemHandler}
                lastFoodElementRef={lastFoodElementRef}
                handleCalculateMealCost={handleCalculateMealCost}
                isLast={index === replaceFoodList.filter((food) => !foodIds?.includes(food.id)).length - 1}
              />
            ))}
          </Box>
        ) : filteredList.length === 0 ? (
          <Box display="flex" flexDirection="row" my={3} justifyContent="center">
            <Typography variant="h5" sx={{}}>
              No Meals found for the selected filters
            </Typography>
          </Box>
        ) : (
          <></>
        )}
      </InfiniteScroll>

      <Box display="flex" flexDirection="row" m={2} justifyContent="center">
        {isFetchingNextPage && <Typography sx={{ alignSelf: 'center', fontSize: '20px' }}>Loading...</Typography>}
      </Box>
      {!foodToReplace && (
        <Button
          variant="contained"
          disabled={selectedFoodList.length === 0}
          sx={{ alignSelf: 'flex-end', width: '112px', color: 'white' }}
          onClick={addSelectedMealsHandler}
        >
          Add Meals
        </Button>
      )}
      <Popup
        maxWidth="sm"
        title="Confirm Swap"
        ref={replaceConfirmationRef}
        onClose={() => replaceConfirmationRef.current?.close()}
        fullWidth
      >
        <ConfirmationPopup
          foodToReplace={foodToReplace}
          confirmationRef={replaceConfirmationRef}
          selectedFoodReplace={selectedFoodReplace}
          confirmReplaceHandler={confirmReplaceHandler}
        />
      </Popup>
    </Stack>
  );
};
export default AddMealsByFilters;
