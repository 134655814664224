import { SyncFoodComponentAttributes, SyncFoodComponentReq } from '@calo/dashboard-types';
import { Checkbox, FormControlLabel, Grid, Stack, Typography } from '@mui/material';
import { FormikErrors } from 'formik';
import { SyncAttributesLabels } from 'lib/enums';
import { omit } from 'lodash-es';
import { styles } from '../styles';

interface CheckAttributesProps {
  values: SyncFoodComponentReq;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<void> | Promise<FormikErrors<SyncFoodComponentReq>>;
}

const CheckAttributes = ({ values, setFieldValue }: CheckAttributesProps) => {
  const getIsAllSelected = () => {
    const attributes = Object.values(SyncFoodComponentAttributes) as (keyof typeof SyncFoodComponentAttributes)[];
    return attributes.every((attr) => values.attributes.includes(attr as SyncFoodComponentAttributes));
  };

  const toggleSelectAll = () => {
    setFieldValue('attributes', getIsAllSelected() ? [] : Object.values(SyncFoodComponentAttributes));
  };

  const toggleAttribute = (attribute: SyncFoodComponentAttributes) => {
    let newValues = [...values.attributes, attribute];
    if (values.attributes.includes(attribute)) {
      newValues = values.attributes.filter((attr) => attr !== attribute);
    }
    setFieldValue('attributes', newValues);
  };

  const measUnitAndWeightCheckHandler = () => {
    if (
      values.attributes.includes(SyncFoodComponentAttributes.measurementUnit) &&
      values.attributes.includes(SyncFoodComponentAttributes.weight)
    ) {
      setFieldValue(
        'attributes',
        values.attributes.filter(
          (attr) => attr !== SyncFoodComponentAttributes.measurementUnit && attr !== SyncFoodComponentAttributes.weight
        )
      );
    } else {
      setFieldValue('attributes', [
        ...values.attributes,
        SyncFoodComponentAttributes.measurementUnit,
        SyncFoodComponentAttributes.weight
      ]);
    }
  };

  const microsMacrosCheckHandler = () => {
    if (
      values.attributes.includes(SyncFoodComponentAttributes.micronutrients) &&
      values.attributes.includes(SyncFoodComponentAttributes.macros)
    ) {
      setFieldValue(
        'attributes',
        values.attributes.filter(
          (attr) => attr !== SyncFoodComponentAttributes.micronutrients && attr !== SyncFoodComponentAttributes.macros
        )
      );
    } else {
      setFieldValue('attributes', [
        ...values.attributes,
        SyncFoodComponentAttributes.micronutrients,
        SyncFoodComponentAttributes.macros
      ]);
    }
  };

  return (
    <>
      <Stack sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography sx={styles.syncInfoTitle}>Select Fields to Sync</Typography>
        <FormControlLabel
          control={<Checkbox checked={getIsAllSelected()} onChange={toggleSelectAll} onClick={toggleSelectAll} />}
          label="Select All"
        />
      </Stack>
      <Grid container columnSpacing={12} rowSpacing={2} sx={styles.checkAttributesGrid}>
        {Object.entries(omit(SyncAttributesLabels, ['measurementUnit', 'weight', 'micronutrients', 'macros'])).map(
          ([key, value]) => (
            <Grid item key={key} xs={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={Boolean(values.attributes.includes(key as SyncFoodComponentAttributes))}
                    onChange={() => toggleAttribute(key as SyncFoodComponentAttributes)}
                  />
                }
                label={value}
              />
            </Grid>
          )
        )}
        <Grid item xs={6}>
          <FormControlLabel
            control={
              <Checkbox
                sx={{ fontSize: '16px' }}
                checked={Boolean(
                  values.attributes.includes(SyncFoodComponentAttributes.micronutrients) &&
                    values.attributes.includes(SyncFoodComponentAttributes.macros)
                )}
                onChange={microsMacrosCheckHandler}
              />
            }
            label="Macros and Micros"
          />
        </Grid>
        <Grid item xs={6}>
          <FormControlLabel
            control={
              <Checkbox
                checked={Boolean(
                  values.attributes.includes(SyncFoodComponentAttributes.measurementUnit) &&
                    values.attributes.includes(SyncFoodComponentAttributes.weight)
                )}
                onChange={measUnitAndWeightCheckHandler}
              />
            }
            label="Measurement Unit & Default Qty"
          />
        </Grid>
      </Grid>
    </>
  );
};

export default CheckAttributes;
