import { caloTheme } from 'assets/images/theme/calo';

export const styles = {
  containerStyles: {
    marginTop: '-35px',
    paddingBottom: 1
  },
  headerStackStyles: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  backButtonStackStyles: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
      flexDirection: 'column',
      textAlign: 'center',
      width: '100%'
    }
  },
  backButtonIconContainerStyles: {
    marginLeft: '30px'
  },
  backButtonLinkStyles: {
    display: 'flex',
    flexDirection: 'row',
    gap: '8px'
  },
  arrowBackIconStyles: {
    fontSize: '14px',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 'auto 0'
  },
  backButtonTextStyles: {
    fontSize: '16px',
    fontWeight: 600
  },
  headerTextStyles: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '150%',
    color: caloTheme.palette.textPrimary,
    textTransform: 'uppercase'
  },
  createHeaderStyles: {
    fontSize: '34px',
    fontWeight: 400,
    lineHeight: '125%'
  },
  dividerStyles: {
    marginX: 'auto',
    marginTop: '20px',
    marginBottom: '12px',
    width: '100%',
    height: '1px',
    backgroundColor: caloTheme.palette.neutral100
  },
  mainContainerStyles: {
    marginBottom: 2,
    paddingTop: 0,
    top: 0,
    mt: '8px'
  },
  mainStackStyles: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    mt: '8px'
  },
  actionButtonStackStyles: {
    display: 'flex',
    flexDirection: 'row',
    gap: '12px'
  },
  buttonOutlined: {
    padding: '8px 22px',
    fontSize: '15px',
    fontWeight: 500,
    lineHeight: '125%',
    textTransform: 'uppercase',
    borderRadius: '4px',
    boxShadow: 'none',
    color: caloTheme.palette.textPrimary,
    borderColor: caloTheme.palette.textPrimary,
    backgroundColor: 'transparent',
    '&:hover': {
      borderColor: caloTheme.palette.textPrimary,
      backgroundColor: '#00000008'
    }
  },
  buttonPrimary: {
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    borderRadius: '8px',
    boxShadow: 'none',
    color: caloTheme.palette.white,
    borderColor: caloTheme.palette.primary500,
    backgroundColor: caloTheme.palette.primary500,
    '&:hover': {
      borderColor: caloTheme.palette.primary500,
      backgroundColor: caloTheme.palette.primary700
    }
  },
  deleteButtonStyles: {
    padding: '10px',
    textTransform: 'none',
    fontSize: '6px',
    color: caloTheme.palette.errorMain,
    border: `1px solid ${caloTheme.palette.errorMain}`,
    borderRadius: '4px'
  },
  updateButtonStyles: {
    padding: '8px 22px',
    fontSize: '15px',
    fontWeight: 500,
    lineHeight: '125%',
    textTransform: 'uppercase',
    borderRadius: '4px',
    boxShadow: 'none',
    color: caloTheme.palette.textPrimary,
    borderColor: caloTheme.palette.textPrimary,
    backgroundColor: 'transparent',
    '&:hover': {
      borderColor: caloTheme.palette.textPrimary,
      backgroundColor: '#00000008'
    }
  },
  saveButtonStyles: {
    color: 'white'
  },
  breadcrumbText: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '150%',
    color: caloTheme.palette.textSecondary
  },
  breadcrumbActiveText: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '150%',
    color: caloTheme.palette.textPrimary
  },
  stickyHeader: {
    backgroundColor: 'white',
    width: 'calc(100% + 64px)',
    marginLeft: '-32px',
    marginRight: '-32px',
    marginTop: '-32px',
    borderRadius: 0,
    position: 'sticky',
    top: '-32px',
    paddingTop: '32px',
    marginBottom: '34px'
  },
  normalHeader: {
    all: 'unset',
    boxShadow: 'none',
    backgroundColor: 'transparent'
  }
};
