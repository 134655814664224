import { ProcessingStage } from '@calo/dashboard-types';
import AddCommentIcon from '@mui/icons-material/AddComment';
import ShareIcon from '@mui/icons-material/Share';
import { CardMedia, IconButton, Stack, TableCell, TableRow, Tooltip, Typography } from '@mui/material';
import noImagePlaceholder from 'assets/images/noImagePlaceholder.png';
import { FoodStatus, Permission, PrototypeAction, Routes } from 'lib/enums';
import { getHostName } from 'lib/helpers';
import { useUserRoles } from 'lib/hooks';
import { ExtendedFoodPrototypeAction, FoodComponent, PrototypeFoodComponent } from 'lib/interfaces';
import { maxBy } from 'lodash-es';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { FoodRowStatusBox } from 'views/ChefPlayground/Shared/FoodRowStatusBox/FoodRowStatusBox';
import { styles } from './styles';

interface ComponentRowProps {
  component: FoodComponent;
  commentClickHandler: (component: FoodComponent) => void;
  changeRequestClickHandler: (foodComponent: PrototypeFoodComponent) => void;
  handleFoodComponentApprovalDialogOpen: (foodComponent: PrototypeFoodComponent) => void;
}

const ComponentRow = ({
  component,
  commentClickHandler,
  handleFoodComponentApprovalDialogOpen,
  changeRequestClickHandler
}: ComponentRowProps) => {
  const roles = useUserRoles();
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const comments =
    (component.prototypeActions as ExtendedFoodPrototypeAction[])?.filter(
      (action) => action.type === PrototypeAction.comment || action.type === PrototypeAction.requestChanges
    ) ?? [];

  const handleFoodApprovalPermission = (status: FoodStatus | ProcessingStage): boolean => {
    switch (status) {
      case ProcessingStage.draft:
        return roles.includes(Permission.CREATE_ACTION_PROTOTYPE_COMPONENT);
      case ProcessingStage.awaitingNPDApproval:
        return roles.includes(Permission.APPROVE_STAGE_1_PROTOTYPE_FOOD_COMPONENT);
      case ProcessingStage.awaitingOpsApproval:
        return roles.includes(Permission.APPROVE_STAGE_2_PROTOTYPE_FOOD_COMPONENT);
      default:
        return false;
    }
  };

  let lastComment = '';
  if (comments.length > 0) {
    const lastCommentAction = maxBy(comments, (comment) => new Date(comment.createdAt));

    lastComment =
      lastCommentAction?.type === PrototypeAction.comment || lastCommentAction?.type === PrototypeAction.requestChanges
        ? lastCommentAction.message
        : '';
  }

  const shareButtonClickHandler = async () => {
    const hostName = getHostName();
    const link = `${hostName}${Routes.playgroundComponent.replace(':id', component.id)}`;

    try {
      await navigator.clipboard.writeText(link);
      setTooltipOpen(true);
      setTimeout(() => {
        setTooltipOpen(false);
      }, 1000); // Hide tooltip after 3 seconds
    } catch (error) {
      console.error('Failed to copy text: ', error);
    }
  };

  const displayImage = `${process.env.REACT_APP_BUCKET_URL}/food-component/${component.id}/square@1x.jpg`;

  const renderCommentCell = () => {
    if (lastComment) {
      return <Typography sx={styles.comment}>{lastComment}</Typography>;
    } else {
      return (
        <IconButton onClick={() => commentClickHandler(component)}>
          <AddCommentIcon sx={{ color: '#212121' }} />
        </IconButton>
      );
    }
  };

  return (
    <TableRow>
      <TableCell>
        <Stack sx={styles.cardStack}>
          <CardMedia
            component="img"
            image={displayImage}
            alt="placeholder"
            sx={styles.foodImage}
            onError={(e: any) => {
              e.target.src = noImagePlaceholder;
            }}
          />
          <Link to={Routes.playgroundComponent.replace(':id', component.id)}>
            <Typography sx={styles.foodName}>{component.name.en}</Typography>
          </Link>
        </Stack>
      </TableCell>
      <TableCell>
        <Stack sx={styles.tableCellStack}>
          <Typography sx={{ fontSize: '14px' }}>
            {component.prototypeActions?.find((action) => action.type === PrototypeAction.create)?.actor.name}
          </Typography>
        </Stack>
      </TableCell>
      <TableCell>
        <Stack sx={styles.tableCellStack}>
          <FoodRowStatusBox
            item={component as PrototypeFoodComponent}
            status={component.stage}
            handleMealApprovalDialogOpen={handleFoodComponentApprovalDialogOpen}
            commentClickHandler={changeRequestClickHandler}
            hasPermission={handleFoodApprovalPermission}
          />
        </Stack>
      </TableCell>
      <TableCell>
        <Stack sx={styles.tableCellStack}>{renderCommentCell()}</Stack>
      </TableCell>
      <TableCell>
        <Stack sx={styles.tableCellStack}>
          <Stack>
            <Tooltip
              title="Link Copied"
              open={tooltipOpen}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              onClose={() => setTooltipOpen(false)}
            >
              <IconButton onClick={shareButtonClickHandler}>
                <ShareIcon sx={{ color: '#212121' }} />
              </IconButton>
            </Tooltip>
          </Stack>
        </Stack>
      </TableCell>
    </TableRow>
  );
};

export default ComponentRow;
