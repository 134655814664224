import { FoodMenuLabelsTags } from '@calo/dashboard-types';
import { addWeeks, endOfWeek, startOfWeek } from 'date-fns';
import { Food } from 'lib/interfaces';
import { inRange, uniq } from 'lodash-es';

export const getDatesForWeek = (week: number, year: number): { start: Date; end: Date } => {
  const startOfYear = new Date(year, 0, 1);
  const firstWeekStart = startOfWeek(startOfYear, { weekStartsOn: 1 });
  const startOfGivenWeek = addWeeks(firstWeekStart, week - 1);
  const endOfGivenWeek = endOfWeek(startOfGivenWeek, { weekStartsOn: 1 });
  return { start: startOfGivenWeek, end: endOfGivenWeek };
};

export const getLabelToAdd = (
  menuDay: number,
  menuLabels: {
    label: FoodMenuLabelsTags;
    startDay: string;
    endDay: string;
  }[]
) => {
  return menuLabels.find((label) => {
    const start = new Date(label.startDay).getTime();
    const end = new Date(label.endDay).getTime();
    return inRange(menuDay, start, end + 1);
  });
};

export const updateFoodTags = (food: Food[], currentTags: any, menuDay: number): { foodId: string; value: string[] }[] => {
  return food.map((f) => {
    const foodTags = currentTags?.find((t: any) => t.id === f.id)?.value || [];
    const labelToAdd = getLabelToAdd(menuDay, f.menuLabels ?? []);
    return {
      foodId: f.id,
      value: labelToAdd ? uniq([...foodTags, labelToAdd.label]) : foodTags
    };
  });
};

export const getLabelToAddByWeek = (
  week: number,
  year: number,
  menuLabels: {
    label: FoodMenuLabelsTags;
    startDay: string;
    endDay: string;
  }[]
) => {
  const { start, end } = getDatesForWeek(week, year);

  return menuLabels.find((label) => {
    const startDay = new Date(label.startDay);
    const endDay = new Date(label.endDay);
    return (startDay >= start && startDay <= end) || (endDay >= start && endDay <= end) || (startDay <= start && endDay >= end);
  });
};

export const updateFoodTagsByWeekAndYear = (
  food: Food[],
  currentTags: any,
  week: number,
  year: number
): { foodId: string; value: any[] }[] | undefined => {
  const updatedTags = food
    .map((f) => {
      const foodTags = currentTags?.find((t: any) => t.id === f.id)?.value || [];
      const labelToAdd = getLabelToAddByWeek(week, year, f.menuLabels ?? []);
      const updatedValue = labelToAdd ? uniq([...foodTags, labelToAdd.label]) : foodTags;
      return {
        foodId: f.id,
        value: updatedValue
      };
    })
    .filter((item) => item.value.length > 0);

  return updatedTags.length > 0 ? updatedTags : undefined;
};

export const getMenuTagsFromFoodList = (food: Food[], menuDay: number) => {
  return food.map((f) => {
    const tags: FoodMenuLabelsTags[] = [];
    for (const label of f.menuLabels ?? []) {
      const start = new Date(label.startDay).getTime();
      const end = new Date(label.endDay).getTime();
      if (inRange(menuDay, start, end + 1)) {
        tags.push(label.label);
      }
    }
    return {
      foodId: f.id,
      value: uniq(tags)
    };
  });
};
