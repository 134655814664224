import { getGymMembership } from 'actions/subscription';
import { GymMembershipRes } from 'lib/calo-dashboard-types';
import { useQuery } from 'react-query';

const useGymMembership = (id: string) => {
  const { data: gymMembershipExistingData, isLoading } = useQuery<any, any, GymMembershipRes>(
    [id, 'gym-membership'],
    async () => {
      return getGymMembership(id);
    }
  );

  return {
    gymMembershipExistingData,
    isLoading
  };
};

export default useGymMembership;
