import { FoodDietType, FoodType, Nutrition } from '@calo/types';
import PhoneModal from 'components/PhoneModal';
import { MenuFilterType } from 'lib/enums';
import { mapDietTypeToTag } from 'lib/helpers';
import { Menu } from 'lib/interfaces';
import { uniqBy } from 'lodash-es';
import { useMemo, useState } from 'react';
import PhoneContent from './PhoneContent';
import PhoneHeader from './PhoneHeader';
import { FilteredMenu } from './types';

export interface PhoneMenuPreviewProps {
  weeklyMenuList: Menu[];
  open: boolean;
  closePhoneModal: () => void;
}

const getPlanFilterType = (plan: string): FoodDietType | 'all' => {
  let convertPlan = plan.toLowerCase();
  if (convertPlan === 'all plans') {
    return 'all';
  }

  switch (convertPlan) {
    case "chef's picks":
      convertPlan = 'chefPicks';
      break;
    case 'high protein':
      convertPlan = 'highProtein';
      break;
    case 'low carb':
      convertPlan = 'lowCarb';
      break;
  }

  const convertedPlan = mapDietTypeToTag(convertPlan);

  return convertedPlan as FoodDietType;
};

const PhoneMenuPreview = ({ weeklyMenuList, closePhoneModal, open }: PhoneMenuPreviewProps) => {
  const filteredWeeklyMenu: FilteredMenu[] = useMemo(() => {
    if (!weeklyMenuList || weeklyMenuList.length === 0) {
      return [];
    }
    return weeklyMenuList.map((menu) => ({
      id: menu.id,
      food: uniqBy(
        menu.food.map((food) => ({
          id: food.id,
          name: food.name.en,
          tags: food.tags,
          macros: {
            cal: food.macros?.cal || 1,
            [Nutrition.carbs]: food.macros?.[Nutrition.carbs] || 1,
            [Nutrition.fat]: food.macros?.[Nutrition.fat] || 1,
            [Nutrition.protein]: food.macros?.[Nutrition.protein] || 1,
            [Nutrition.fiber]: food.macros?.[Nutrition.fiber] || 1
          },
          type: food.type || []
        })),
        'name'
      )
    }));
  }, [weeklyMenuList]);

  const [selectedDate, setSelectedDate] = useState<string>(
    filteredWeeklyMenu.length > 0
      ? filteredWeeklyMenu.find((menu) => menu.id === new Date().toISOString().split('T')[0])?.id || filteredWeeklyMenu[0].id
      : ''
  );
  const [selectedMealType, setSelectedMealType] = useState<MenuFilterType>(MenuFilterType.Breakfast);
  const [selectedPlan, setSelectedPlan] = useState<string>('All plans');

  const handleDateSelect = (date: string) => {
    setSelectedDate(date);
  };

  const handleMealTypeToggle = (mealType: MenuFilterType) => {
    setSelectedMealType(mealType);
  };

  const handlePlanSelect = (plan: string) => {
    setSelectedPlan(plan);
  };

  const filteredMenu = useMemo(() => {
    if (filteredWeeklyMenu.length === 0) {
      return { id: '', food: [] };
    }
    const selectedMenu = filteredWeeklyMenu.find((menu) => menu.id === selectedDate) || filteredWeeklyMenu[0];
    const planFilterType = getPlanFilterType(selectedPlan);

    return {
      ...selectedMenu,
      food: selectedMenu.food.filter((food) => {
        const matchesMealType =
          selectedMealType === MenuFilterType.Breakfast
            ? food.type.includes(FoodType.breakfast)
            : selectedMealType === MenuFilterType.LunchDinner
              ? food.type.includes(FoodType.lunch) || food.type.includes(FoodType.dinner)
              : food.type.includes(FoodType.snack);

        const matchesPlan =
          planFilterType === 'all' ||
          (planFilterType === FoodDietType.chefPicks
            ? food.tags.includes(planFilterType) || food.tags.includes('gourmet')
            : food.tags.includes(planFilterType));

        return matchesMealType && matchesPlan;
      })
    };
  }, [filteredWeeklyMenu, selectedDate, selectedMealType, selectedPlan]);

  return (
    <>
      <PhoneModal
        open={open}
        onClose={closePhoneModal}
        header={
          filteredWeeklyMenu.length > 0 ? (
            <PhoneHeader
              weeklyMenu={filteredWeeklyMenu}
              handleDateSelect={handleDateSelect}
              selectedMealType={selectedMealType}
              handleMealTypeToggle={handleMealTypeToggle}
            />
          ) : null
        }
        headerTitle="Menu Preview"
        body={
          filteredWeeklyMenu.length > 0 ? (
            <PhoneContent cards={filteredMenu.food} selectedPlan={selectedPlan} handlePlanSelect={handlePlanSelect} />
          ) : (
            <div>No menu available</div>
          )
        }
      />
    </>
  );
};

export default PhoneMenuPreview;
