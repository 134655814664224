import { useState } from 'react';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import { Box, IconButton } from '@mui/material-v6';
import MealUsedModal from 'views/Food/ExactFood/Header/MealUsedModal';

interface UsedOnMenuIndicatorProps {
  filteredFoodUsed: {
    type: 'mainMenu' | 'addonsMenu';
    date?: string | undefined;
    week?: number | undefined;
  }[];
  usedOnMenuButtonColor?: string[];
  type: 'ingredient' | 'food' | 'component';
}

const UsedOnMenuIndicator = ({ filteredFoodUsed, usedOnMenuButtonColor, type }: UsedOnMenuIndicatorProps) => {
  const [showFoodUsedModal, setShowFoodUsedModal] = useState(false);

  const getIconColor = () => {
    if (usedOnMenuButtonColor?.includes('red')) {
      return 'error';
    }
    if (usedOnMenuButtonColor?.includes('yellow')) {
      return 'warning';
    }
    return 'info';
  };
  return (
    <Box>
      {filteredFoodUsed && filteredFoodUsed.length > 0 && (
        <IconButton onClick={() => setShowFoodUsedModal(true)}>
          <WarningRoundedIcon color={getIconColor()} fontSize="large" />
        </IconButton>
      )}
      <MealUsedModal
        selectedFoodType={type}
        filteredFoodUsed={filteredFoodUsed}
        showFoodUsedModal={showFoodUsedModal}
        close={() => setShowFoodUsedModal(false)}
      />
    </Box>
  );
};

export default UsedOnMenuIndicator;
