import DateFnsAdapter from '@date-io/date-fns';
import { TextField, TextFieldProps } from '@mui/material';
import { SxProps, Theme } from '@mui/material-v6';
import { DesktopDatePicker, LocalizationProvider, PickersDay, pickersDayClasses } from '@mui/x-date-pickers';
import { caloTheme } from 'assets/images/theme/calo';
import { format, getYear, parse } from 'date-fns';
import { findStartAndEndDateForWeek, getYearBasedOnWeek } from 'lib';
import { useState } from 'react';
import PaperContent from '../WeeklyMenuActionsCard/PaperContent';

export interface WeeklyMenusDatePickerProps {
  selectedWeek: number;
  disabled?: boolean;
  sx?: SxProps<Theme> | undefined;
  setSelectedYear: (v: number) => void;
  setSelectedWeek: (newWeek: number) => void;
}

const WeeklyMenusDatePicker = ({ selectedWeek, disabled, sx, setSelectedWeek, setSelectedYear }: WeeklyMenusDatePickerProps) => {
  const year = getYearBasedOnWeek(selectedWeek);
  const { startOfWeekDate } = findStartAndEndDateForWeek(selectedWeek, year);
  const [selectedDate, setSelectedDate] = useState(startOfWeekDate);

  const handleRenderDateInput = (params: TextFieldProps) => {
    const { inputProps, ...rest } = params;
    const dateValue = inputProps?.value;
    let year = getYear(selectedWeek);
    if (dateValue) {
      const parsedDate = parse(dateValue, 'dd-MM-yyyy', new Date());
      year = getYear(parsedDate);
    }
    const { startOfWeekDate, endOfWeekDate } = findStartAndEndDateForWeek(selectedWeek, year);

    const updatedInputProps = {
      ...inputProps,
      value: `Week ${selectedWeek} (${format(startOfWeekDate, 'dd/MM')} - ${format(endOfWeekDate, 'dd/MM')})`
    };

    return (
      <TextField
        {...rest}
        inputProps={updatedInputProps}
        sx={{
          width: '20%',
          minWidth: '240px',
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderRadius: 0
            }
          },
          [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
            mt: 6,
            width: '100%',
            display: 'flex',
            borderRadius: '4px',
            paddingLeft: '30px',
            flexDirection: 'column',
            justifyContent: 'center'
          },
          ...(sx as any)
        }}
        error={false}
      />
    );
  };

  const handleRenderDay = (day: Date, DayComponentProps: any) => {
    const { startOfWeekDate, endOfWeekDate } = findStartAndEndDateForWeek(selectedWeek);

    if (
      format(day, 'yyyy-MM-dd') < format(startOfWeekDate, 'yyyy-MM-dd') ||
      format(day, 'yyyy-MM-dd') > format(endOfWeekDate, 'yyyy-MM-dd')
    ) {
      return <PickersDay disabled={true} sx={{ backgroundColor: 'none' }} {...DayComponentProps} />;
    }

    return (
      <PickersDay
        {...DayComponentProps}
        onClick={() => null}
        sx={{
          color: 'black',
          backgroundColor: caloTheme.palette.primary100,
          ':hover': { color: 'black', backgroundColor: caloTheme.palette.neutral100 },
          [`&&.${pickersDayClasses.selected}`]: {
            border: 1,
            color: 'black',
            backgroundColor: caloTheme.palette.neutral100,
            borderColor: caloTheme.palette.primary600
          }
        }}
      />
    );
  };

  return (
    <LocalizationProvider dateAdapter={DateFnsAdapter} sx={{ mb: '4px' }}>
      <DesktopDatePicker
        components={{
          // eslint-disable-next-line react/no-multi-comp
          PaperContent: (props) => (
            <PaperContent
              {...props}
              selectedDate={selectedDate}
              selectedWeek={selectedWeek}
              setSelectedWeek={setSelectedWeek}
              setSelectedYear={setSelectedYear}
            />
          )
        }}
        showDaysOutsideCurrentMonth
        label="Week"
        inputFormat="dd-MM-yyyy"
        value={selectedDate}
        disabled={disabled}
        shouldDisableDate={() => true}
        renderInput={handleRenderDateInput}
        renderDay={(day, _value, DayComponentProps) => handleRenderDay(new Date(day), DayComponentProps)}
        onMonthChange={(event) => setSelectedDate(new Date(event))}
        onYearChange={(event) => {
          setSelectedDate(new Date(event));
        }}
        onChange={() => {}}
        minDate={format(Date.now(), 'yyyy-MM-dd')}
      />
    </LocalizationProvider>
  );
};

export default WeeklyMenusDatePicker;
