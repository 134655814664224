import { useFormik } from 'formik';
import { AlgorithmConfigOptions } from 'lib/calo-types';

export type FormData = { algorithmConfig: AlgorithmConfigOptions };

export default (defaultValue: AlgorithmConfigOptions, onSubmit: (values: FormData) => any) => {
  return useFormik<FormData>({
    enableReinitialize: true,
    initialValues: {
      algorithmConfig: defaultValue
    },

    validate: (_: FormData) => {
      const errors: any = {};
      return errors;
    },
    onSubmit: async (values) => {
      try {
        await onSubmit(values);
      } catch (error) {
        console.log(error);
      }
    }
  });
};
