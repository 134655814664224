import { caloTheme } from 'assets/images/theme/calo';

export const styles = {
  card: {
    marginBottom: caloTheme.spacing(2),
    width: '100%',
    border: 'none',
    borderRadius: '8px',
    [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
      flexDirection: 'column'
    }
  },
  noMenuText: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: caloTheme.spacing(2),
    color: caloTheme.palette.neutral400,
    fontWeight: 400,
    fontSize: '32px'
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginLeft: caloTheme.spacing(2),
    marginTop: caloTheme.spacing(3)
  },
  headerInfo: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginRight: caloTheme.spacing(3)
  },
  editIcon: {
    cursor: 'pointer',
    backgroundColor: 'white',
    borderColor: 'black',
    marginRight: caloTheme.spacing(6)
  },
  toggleIcon: {
    cursor: 'pointer',
    marginTop: '-4px',
    marginRight: caloTheme.spacing(2)
  },
  menuTable: {
    marginTop: caloTheme.spacing(4),
    minHeight: '20rem'
  },
  text: { fontFamily: caloTheme.typography.fontFamily, fontWeight: 600, fontSize: '20px', lineHeight: '24px' }
};
