export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export enum ActivityLevel {
  level1 = 'level1',
  level2 = 'level2',
  level3 = 'level3',
  level4 = 'level4',
  level5 = 'level5'
}

export enum AddressType {
  home = 'home',
  office = 'office'
}

export enum Brand {
  CALO = 'CALO',
  MEALO = 'MEALO'
}

export enum Country {
  AE = 'AE',
  BH = 'BH',
  KW = 'KW',
  OM = 'OM',
  QA = 'QA',
  SA = 'SA',
  US = 'US',
  GB = 'GB'
}

export enum Currency {
  AED = 'AED',
  BHD = 'BHD',
  KWD = 'KWD',
  OMR = 'OMR',
  QAR = 'QAR',
  SAR = 'SAR',
  GBP = 'GBP'
}

export enum DeliveryTime {
  evening = 'evening',
  morning = 'morning'
}

/**
 * @deprecated use Goal instead of FitnessGoal
 */
export enum FitnessGoal {
  gainWeight = 'gainWeight',
  loseWeight = 'loseWeight',
  maintainWeight = 'maintainWeight'
}

export enum Gender {
  female = 'female',
  male = 'male'
}

export enum Kitchen2 {
  AE000 = 'AE000',
  AE001 = 'AE001',
  BH000 = 'BH000',
  BH001 = 'BH001',
  KW000 = 'KW000',
  KW001 = 'KW001',
  NA000 = 'NA000',
  OM000 = 'OM000',
  OM001 = 'OM001',
  QA000 = 'QA000',
  QA001 = 'QA001',
  SA000 = 'SA000',
  SA001 = 'SA001',
  SA002 = 'SA002',
  GB000 = 'GB000',
  GB001 = 'GB001'
}

export enum Language {
  ar = 'ar',
  en = 'en'
}

export enum MacrosType {
  custom = 'custom',
  recommended = 'recommended',
  athlete = 'athlete'
}

export enum Nutrition {
  carbs = 'carbs',
  fat = 'fat',
  fiber = 'fiber',
  protein = 'protein'
}

export enum Micronutrient {
  saturatedFats = 'saturatedFats',
  transFats = 'transFats',
  sodium = 'sodium',
  cholesterol = 'cholesterol',
  addedSugar = 'addedSugar',
  totalSugar = 'totalSugar'
}

export enum DeliveryDayStatus {
  default = 'default',
  optional = 'optional',
  disabled = 'disabled'
}

export enum ChallengeType {
  weightLoss = 'weightLoss',
  gainMuscle = 'gainMuscle'
}

export enum ChallengeAchievmentStatus {
  achieved = 'achieved',
  failed = 'failed'
}

export enum ChallengeStatus {
  active = 'active',
  inactive = 'inactive'
}

export enum ChallengeDayAchievmentStatus {
  achieved = 'achieved',
  failed = 'failed'
}

export enum CalorieDeficitValue {
  FIVE_HUNDRED = 'FIVE_HUNDRED',
  ONE_THOUSAND = 'ONE_THOUSAND'
}

export enum DeliveryInstruction {
  CALL_ME_WHEN_YOU_REACH = 'CALL_ME_WHEN_YOU_REACH',
  LEAVE_AT_RECEPTION = 'LEAVE_AT_RECEPTION',
  RING_MY_DOORBELL = 'RING_MY_DOORBELL',
  LEAVE_AT_THE_DOOR = 'LEAVE_AT_THE_DOOR'
}

export enum NotificationBannerKeys {
  BANNER_DISMISSED_KEY = 'BANNER_DISMISSED_KEY',
  NOTIFICATION_DISABLED_KEY = 'NOTIFICATION_DISABLED_KEY',
  BOTTOM_SHEET_DISMISSED_KEY = 'BOTTOM_SHEET_DISMISSED_KEY',
  NOTIFICATION_PERMISSION_KEY = 'NOTIFICATION_PERMISSION_KEY'
}

export declare enum RetailModifierType {
  REPLACE = 'REPLACE',
  ADD_ONE = 'ADD',
  ADD_MANY = 'OPTION'
}
