import { FoodStatus } from './enums';
import { ChipPropsColorOverrides } from '@mui/material';
import { OverridableStringUnion } from '@mui/types';
import { ActivityLevel, Kitchen } from '@calo/types';
import { FoodTagsKeys, RetailMenuItemTag } from './enums';
import { CustomComponentsByCategory, MealPlanFoodState } from './interfaces';
import { DeliveryDayStatusProps } from './renderDayStyle';

export const mountainPeeksAverageCost: Partial<Record<Kitchen, Record<string, number>>> = {
  [Kitchen.SA1]: {
    s: 0.34,
    m: 0.49,
    l: 0.16
  },
  [Kitchen.SA2]: {
    s: 0.32,
    m: 0.52,
    l: 0.17
  },
  [Kitchen.BH1]: {
    s: 0.2,
    m: 0.48,
    l: 0.32
  },
  [Kitchen.QA1]: {
    s: 0.2,
    m: 0.48,
    l: 0.32
  },
  [Kitchen.KW1]: {
    s: 0.33,
    m: 0.45,
    l: 0.21
  },
  [Kitchen.AE1]: {
    s: 0.28,
    m: 0.5,
    l: 0.22
  },
  [Kitchen.GB1]: {
    s: 0.33,
    m: 0.33,
    l: 0.34
  }
};

export const macrosSortOptions = Object.freeze({
  any: 'any',
  highestToLowestProtein: 'highest to lowest protein',
  highestToLowestCarb: 'highest to Lowest carb',
  lowestToHighestFat: 'lowest to highest fat'
});

export const foodStatuslabelMapping = {
  [FoodStatus.draft]: 'Draft',
  [FoodStatus.pending]: 'Pending',
  [FoodStatus.awaitingNPDApproval]: '1st approval pending',
  [FoodStatus.awaitingOpsApproval]: '2nd approval pending',
  [FoodStatus.approved]: 'Released'
};

export const foodStatuslabelColorMapping: Record<
  FoodStatus,
  OverridableStringUnion<'info' | 'primary' | 'default' | 'secondary' | 'error' | 'success' | 'warning', ChipPropsColorOverrides>
> = {
  [FoodStatus.draft]: 'info',
  [FoodStatus.pending]: 'info',
  [FoodStatus.awaitingNPDApproval]: 'info',
  [FoodStatus.awaitingOpsApproval]: 'info',
  [FoodStatus.approved]: 'primary'
};
export const INITIAL_DELIVERY_FOOD_STATE: MealPlanFoodState = {
  breakfast: [],
  lunch: [],
  snack: [],
  caloKids: [],
  dessert: [],
  salad: [],
  juice: [],
  coffee: [],
  gifts: []
};

export const INITIAL_CUSTOM_COMPONENT_STATE: CustomComponentsByCategory = {
  rice: [],
  meal: [],
  salad: [],
  sandwich: []
};

export const INITIAL_DELIVERY_DAY_STATUS_STATE: DeliveryDayStatusProps = {
  skipped: [],
  delivered: [],
  cancelled: [],
  pending: [],
  paused: []
};

export const ACTIVITY_LEVELS = [
  { value: ActivityLevel.level1, label: `Little or no exercise` },
  { value: ActivityLevel.level2, label: `Light: 1-3 workouts/week` },
  { value: ActivityLevel.level3, label: `Moderate: 3-5 workouts/week` },
  { value: ActivityLevel.level4, label: `Active: 6-7 workouts/week` },
  { value: ActivityLevel.level5, label: `Very Active: 6-7 intense workouts/week` }
];

export const retailMenuDrinkItems = {
  [RetailMenuItemTag.COLD_DRINK]: RetailMenuItemTag.COLD_DRINK,
  [RetailMenuItemTag.HOT_DRINK]: RetailMenuItemTag.HOT_DRINK,
  [RetailMenuItemTag.SMOOTHY]: RetailMenuItemTag.SMOOTHY,
  [RetailMenuItemTag.BONE_BROTH]: RetailMenuItemTag.BONE_BROTH
};

export const FoodStatusFilterLabels: Record<FoodStatus, string> = {
  [FoodStatus.draft]: 'Draft',
  [FoodStatus.pending]: 'Pending',
  [FoodStatus.awaitingNPDApproval]: 'Awaiting NPD Approval',
  [FoodStatus.awaitingOpsApproval]: 'Awaiting Food Ops Approval',
  [FoodStatus.approved]: 'Approved'
};

export const requiredFoodTagKeys = [
  FoodTagsKeys.convenience,
  FoodTagsKeys.texture,
  FoodTagsKeys.spicyLevel,
  FoodTagsKeys.mealForm,
  FoodTagsKeys.thickness,
  FoodTagsKeys.mealRestriction,
  FoodTagsKeys.healthBenefit
];
