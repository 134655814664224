import AddMealsByFilterPopup from 'components/AddMealsByFilterPopup';
import { ModalRef } from 'components/Modal';
import Popup from 'components/Popup';
import useFoodListByFilters from 'hooks/useFoodListByFilters';
import { Food, Menu, MenuFood } from 'lib/interfaces';
import { useEffect, useRef, useState } from 'react';
import AddMealsButton from '../AddMealsButton';
import DuplicateDailyMenuCalendarPopup from '../DuplicateDailyMenuCalendarPopup';

interface AddMealsOptionsProps {
  disabled?: boolean;
  selectedMenu: Menu;
  foodToReplace?: { food: MenuFood; menuId?: string };
  duplicateMenuDate: Date;
  isDuplicateMenuLoading: boolean;
  deletedDuplicatedMenuFood: string[];
  handleReplaceFood: (selectedFoodSizes: Food[]) => void;
  fetchDuplicateMenu: () => void;
  handleFoodChanges: (food: Food[]) => void;
  setFoodToReplace: React.Dispatch<React.SetStateAction<{ food: MenuFood; menuId?: string } | undefined>>;
  setDuplicateMenuDate: React.Dispatch<React.SetStateAction<Date>>;
  setDeletedDuplicatedMenuFood: React.Dispatch<React.SetStateAction<string[]>>;
}

const AddMealsOptions = ({
  disabled,
  selectedMenu,
  foodToReplace,
  duplicateMenuDate,
  isDuplicateMenuLoading,
  deletedDuplicatedMenuFood,
  fetchDuplicateMenu,
  setDuplicateMenuDate,
  handleReplaceFood,
  handleFoodChanges,
  setFoodToReplace,
  setDeletedDuplicatedMenuFood
}: AddMealsOptionsProps) => {
  const addMealsByFiltersRef = useRef<ModalRef>();
  const duplicateCalendarRef = useRef<ModalRef>();

  const [filtersOn, setFiltersOn] = useState<boolean>(false);
  const [selectedMenuFilters, setSelectedMenuFilters] = useState({
    type: '',
    plan: '',
    protein: '',
    taste: '',
    sandwich: undefined,
    lastUsed: '',
    base: '',
    category: '',
    mealName: '',
    menuLabel: ''
  });

  const { foodListFiltersData, foodFiltersLoading, hasNextPage, fetchNextPage, remove, isLoadingFetchNextPage } =
    useFoodListByFilters({
      selectedMenuFilters,
      country: selectedMenu.country,
      kitchen: selectedMenu.kitchen,
      brand: selectedMenu.brand,
      filtersOn
    });

  const handleClosePopup = () => {
    remove();
    setFoodToReplace(undefined);
    setSelectedMenuFilters({
      type: '',
      plan: '',
      protein: '',
      taste: '',
      sandwich: undefined,
      lastUsed: '',
      base: '',
      category: '',
      mealName: '',
      menuLabel: ''
    });
    setFiltersOn(false);
    addMealsByFiltersRef.current?.close();
  };

  useEffect(() => {
    if (foodToReplace) {
      setFiltersOn(true);
      addMealsByFiltersRef.current?.open();
    }
  }, [foodToReplace]);

  const handleFoodIds = () => {
    return selectedMenu.food.map((food) => food.id);
  };

  return (
    <>
      <AddMealsButton
        addMealsByFiltersHandler={() => {
          setFiltersOn(true);
          addMealsByFiltersRef.current?.open();
        }}
        duplicateMenuHandler={() => duplicateCalendarRef.current?.open()}
        disabled={disabled}
      />
      <DuplicateDailyMenuCalendarPopup
        kitchen={selectedMenu.kitchen}
        disableFutureDays={true}
        duplicateMenuDate={duplicateMenuDate}
        duplicateCalendarRef={duplicateCalendarRef}
        showFoodWarning={selectedMenu.food.length > 0}
        deletedDuplicatedMenuFood={deletedDuplicatedMenuFood}
        isDuplicateMenuLoading={isDuplicateMenuLoading}
        duplicateHandler={fetchDuplicateMenu}
        setDuplicateMenuDate={setDuplicateMenuDate}
        handleClosePopup={() => {
          setDeletedDuplicatedMenuFood([]);
          duplicateCalendarRef.current?.close();
        }}
      />
      <Popup maxWidth="lg" ref={addMealsByFiltersRef} onClose={handleClosePopup}>
        <AddMealsByFilterPopup
          values={selectedMenu as any}
          filtersOn={filtersOn}
          foodToReplace={foodToReplace}
          isLoading={foodFiltersLoading}
          foodIds={handleFoodIds() || []}
          handleClosePopup={handleClosePopup}
          handleReplaceFood={handleReplaceFood}
          selectedMenuFilters={selectedMenuFilters}
          addMealsByFiltersRef={addMealsByFiltersRef}
          setSelectedMenuFilters={setSelectedMenuFilters}
          fetchNextPage={fetchNextPage}
          hasNextPage={hasNextPage || false}
          isFetchingNextPage={isLoadingFetchNextPage}
          handleFoodChanges={handleFoodChanges}
          foodListFiltersData={foodListFiltersData || []}
        />
      </Popup>
    </>
  );
};

export default AddMealsOptions;
