import { FoodPrototypeAction, Permission, PrototypeAction } from '@calo/dashboard-types';
import { addPrototypeFoodAction } from 'actions/food';
import { useUserRoles } from 'lib/hooks';
import { Food, PrototypeFood } from 'lib/interfaces';
import { forwardRef, useState } from 'react';
import { useMutation } from 'react-query';
import CommentsPopup from 'views/ChefPlayground/Shared/CommentsPopup';

interface CommentsPopupProps {
  selectedFood?: PrototypeFood;
  closeCommentsPopup: () => void;
  successHandler: (data: Food) => void;
}

const FoodCommentsPopup = forwardRef(({ selectedFood, closeCommentsPopup, successHandler }: CommentsPopupProps, ref) => {
  const userRoles = useUserRoles();

  const commentItems = selectedFood?.prototypeActions?.filter((action) => action.type === PrototypeAction.comment) ?? [];

  const [comment, setComment] = useState('');

  const { mutate: addPrototypeAction, isLoading } = useMutation(addPrototypeFoodAction, {
    onSuccess: (data: Food) => {
      if (!data) {
        return;
      }
      setComment('');
      successHandler(data);
      closeCommentsPopup();
    }
  });

  const submitCommentHandler = () => {
    if (!selectedFood?.id || !comment) {
      return;
    }
    addPrototypeAction({ id: selectedFood.id, slug: selectedFood.slug, action: PrototypeAction.comment, message: comment });
  };

  return (
    <CommentsPopup
      comment={comment}
      commentsPopupRef={ref as any}
      commentItems={commentItems as FoodPrototypeAction[]}
      commentDisabled={isLoading || !selectedFood?.id || !userRoles.includes(Permission.CREATE_ACTION_PROTOTYPE_FOOD)}
      submitDisabled={isLoading || !comment || !userRoles.includes(Permission.CREATE_ACTION_PROTOTYPE_FOOD)}
      setComment={setComment}
      closeHandler={closeCommentsPopup}
      submitCommentHandler={submitCommentHandler}
    />
  );
});

export default FoodCommentsPopup;
