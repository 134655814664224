import {
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow
} from '@mui/material';
import { caloTheme } from 'assets/images/theme/calo';
import client from 'lib/client';
import { CateringOrder, Food } from 'lib/interfaces';
import { useState } from 'react';
import { useQuery } from 'react-query';
import OrderedMealsRow from '../OrderedMealsRow';

export interface OrderedMealsProps {
  cateringOrder: CateringOrder;
}

export default function OrderedMeals({ cateringOrder }: OrderedMealsProps) {
  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      fontSize: '14px',
      fontWeight: 600,
      color: caloTheme.palette.neutral900,
      fontFamily: caloTheme.typography.fontFamily
    },
    [`&.${tableCellClasses.body}`]: {
      border: 'none',
      justifyContent: 'space-between'
    }
  }));

  const [page, setPage] = useState(0);
  const [limit] = useState(10);
  const [existingFoodIdList] = useState(cateringOrder.orders[0].items.map((r: any) => r.itemId));

  const { data: existingFood } = useQuery<any, Error, { data?: Food[]; meta?: any }>(
    [
      'food/list',
      {
        filters: {
          country: cateringOrder.country,
          brand: cateringOrder.brand,
          kitchen: cateringOrder.kitchen,
          ids: existingFoodIdList.length > 0 ? existingFoodIdList : undefined
        },
        sort: {
          orderBy: 'name',
          orderMode: 'asc'
        },
        page,
        limit
      }
    ],
    async ({ queryKey }) => {
      const { data } = await client.post(queryKey[0] as string, {
        filters: {
          name: undefined,
          country: cateringOrder.country,
          brand: cateringOrder.brand,
          kitchen: cateringOrder.kitchen,
          ids: existingFoodIdList.length > 0 ? existingFoodIdList : undefined
        },
        sort: {
          orderBy: 'name',
          orderMode: 'asc'
        },
        page: 0,
        limit
      });
      return data;
    },
    {
      enabled: existingFoodIdList.length > 0
    }
  );

  const getQuantity = (food: Food) => {
    return (
      cateringOrder.orders[0].items.find(({ itemId }) => {
        return itemId === food.id;
      })?.count ?? 0
    );
  };

  return (
    <Paper>
      <TableContainer>
        <Table
          sx={{
            overflow: 'auto',
            width: '100%',
            [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
              flexDirection: 'column'
            }
          }}
        >
          <TableHead
            sx={{
              backgroundColor: caloTheme.palette.neutral100,
              color: 'black'
            }}
          >
            <TableRow
              sx={{
                backgroundColor: caloTheme.palette.neutral100,
                color: 'black',
                width: '100%'
              }}
            >
              <StyledTableCell sx={{ maxWidth: '200px', minWidth: '200px' }}>Meal</StyledTableCell>
              <StyledTableCell>Quantity</StyledTableCell>
              <StyledTableCell>Type</StyledTableCell>
              <StyledTableCell>Price</StyledTableCell>
              <StyledTableCell>Calories</StyledTableCell>
              <StyledTableCell>Protein</StyledTableCell>
              <StyledTableCell>Carbs</StyledTableCell>
              <StyledTableCell>Fat</StyledTableCell>
              <StyledTableCell>Serving</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {existingFood?.data?.map((food) => (
              <OrderedMealsRow
                food={food}
                quantity={getQuantity(food)}
                price={cateringOrder.orders[0].items.find(({ itemId }) => itemId === food.id)?.itemCost ?? 0}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        rowsPerPageOptions={[limit]}
        count={existingFood?.meta?.total ?? 0}
        rowsPerPage={limit}
        page={page}
        onPageChange={(_, newPage) => setPage(newPage)}
      />
    </Paper>
  );
}
