import { Brand, Country, Kitchen } from '@calo/types';
import { MenuPresentationType } from 'lib/enums';
import { Menu } from 'lib/interfaces';

export type MenuListFilters = {
  country: Country;
  brand: Brand;
  kitchen: Kitchen;
  menuPresentation: MenuPresentationType;
  selectedWeek: number;
  selectedDay: string;
  selectedYear: number;
};

export type MenuPathUpdateFunctions = {
  setBrand: (newBrand: Brand) => void;
  setKitchen: (newKitchen: Kitchen) => void;
  setMenuPresentation: (newPresentation: MenuPresentationType) => void;
  setDay: (newDate: string) => void;
  setWeek: (newWeek: number) => void;
  setYear: (newYear: number) => void;
};

export interface WeekMenuListProps {
  foodIds: string[];
  isLoading: boolean;
  isEditable: boolean;
  selectedWeek: number;
  selectedYear: number;
  filters: MenuListFilters;
  selectedMenu?: Menu;
  weeklyMenuList: Menu[];
  isAddMealsPopupOpen: boolean;
  setWeeklyMenuList: React.Dispatch<React.SetStateAction<Menu[]>>;
  setSelectedMenu: React.Dispatch<React.SetStateAction<Menu | undefined>>;
  openAddMealsPopup: () => void;
  setWeeklyAverageCost: React.Dispatch<React.SetStateAction<number>>;
  setIsAddMealsPopupOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface menuPresentationOption {
  label: MenuPresentationType.daily | MenuPresentationType.weekly;
  value: MenuPresentationType.daily | MenuPresentationType.weekly;
}

export const menuPresentationOptions: menuPresentationOption[] = [
  {
    label: MenuPresentationType.daily,
    value: MenuPresentationType.daily
  },
  {
    label: MenuPresentationType.weekly,
    value: MenuPresentationType.weekly
  }
];

export type CreateParams = {
  kitchen: string;
  country: string;
  day: string | number;
};
