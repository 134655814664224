import { ProcessingStage } from '@calo/dashboard-types';
import CreateIcon from '@mui/icons-material/Create';
import LoadingButton from '@mui/lab/LoadingButton';
import { Chip, Stack, Typography } from '@mui/material';
import { caloTheme } from 'assets/images/theme/calo';
import { UsedOnMenuIndicator } from 'components';
import RouteBreadCrumb from 'components/RouteBreadCrumb';
import { foodStatuslabelColorMapping, foodStatuslabelMapping } from 'lib/constants';
import { FormOperation, PrototypeAction, Routes } from 'lib/enums';
import { formatKitchenText, resolveCountry } from 'lib/helpers';
import { BreadcrumbItem, IngredientOperationValues } from 'lib/interfaces';
import { capitalize } from 'lodash';
import { startCase } from 'lodash-es';
import { ReactNode } from 'react';
import { useLocation } from 'react-router-dom';

const styles = {
  breadcrumbText: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '150%',
    color: caloTheme.palette.textSecondary
  },
  breadcrumbActiveText: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '150%',
    color: caloTheme.palette.textPrimary
  },
  headerStack: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  countryText: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '150%',
    color: caloTheme.palette.textPrimary
  },
  backButtonContainer: {
    flexDirection: 'row',
    [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
      flexDirection: 'column',
      textAlign: 'center',
      width: '100%'
    }
  },
  backButton: {
    marginLeft: '30px',
    flexDirection: 'row',
    gap: '8px',
    alignItems: 'center'
  },
  backIcon: {
    fontSize: '14px'
  },
  backText: {
    fontSize: '16px',
    fontWeight: 600
  },
  ingredientTitle: {
    fontSize: '34px',
    fontWeight: 400,
    lineHeight: '125%',
    [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
      flexDirection: 'column',
      textAlign: 'center',
      width: '100%',
      marginTop: 1
    }
  },
  divider: {
    marginX: 'auto',
    marginTop: '20px',
    width: '100%',
    height: '1px',
    backgroundColor: caloTheme.palette.neutral100
  },
  stickyContainer: {
    marginTop: '-5px',
    paddingTop: '5px',
    top: 0
  },
  actionStack: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    mt: '8px'
  },
  titleStack: {
    flexDirection: 'row',
    gap: '8px',
    alignItems: 'center'
  },
  title: {
    fontSize: '20px',
    fontWeight: 600,
    marginLeft: 3
  },
  buttonStack: {
    flexDirection: 'row',
    gap: '12px'
  },
  submitButton: {
    padding: '8px 22px',
    fontSize: '15px',
    fontWeight: 500,
    lineHeight: '125%',
    textTransform: 'uppercase',
    borderRadius: '4px',
    boxShadow: 'none',
    color: caloTheme.palette.textPrimary,
    borderColor: caloTheme.palette.textPrimary,
    backgroundColor: 'transparent',
    '&:hover': {
      borderColor: caloTheme.palette.textPrimary,
      backgroundColor: '#00000008'
    }
  }
};

const generateBreadcrumbItems = (pathname: string, ingredientName?: string): BreadcrumbItem[] => {
  const segments = pathname.split('/').filter(Boolean); // Removes empty segments

  return segments[0] === 'ingredients'
    ? [
        { text: 'Food' },
        { text: 'Ingredients', url: Routes.ingredients },
        { text: segments[segments.length - 1] === 'new' ? 'New Ingredient' : ingredientName || '' }
      ]
    : segments[0] === 'chef-playground'
      ? [
          { text: "Chef's Playground" },
          { text: 'Ingredients', url: Routes.playgroundIngredientList },
          { text: segments[segments.length - 1] === 'new' ? 'New Ingredient' : ingredientName || '' }
        ]
      : [];
};

type IngredientHeaderCardProps = {
  handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
  disabled?: boolean;
  usedOnMenuButtonColor?: string[];
  children?: ReactNode;
} & IngredientOperationValues;

const IngredientHeaderCard = ({
  disabled,
  handleSubmit,
  usedOnMenuButtonColor,
  children,
  ...props
}: IngredientHeaderCardProps) => {
  const location = useLocation();
  const ingredient = props.operation === FormOperation.update && props.ingredient;
  const breadcrumbItems = generateBreadcrumbItems(location.pathname, ingredient ? ingredient.name?.en : '');

  const renderIngredientStatusChip = () => {
    const ingredient = props.operation === FormOperation.update && props.ingredient;
    if (ingredient && ingredient.stage) {
      const actionHistory = ingredient.prototypeActions || [];
      const lastAction = actionHistory[actionHistory.length - 1] as { type: PrototypeAction };

      const isChangeRequested = lastAction?.type === PrototypeAction.requestChanges;
      if (isChangeRequested) {
        return <Chip label={'Changes requested'} color="warning" size="medium" variant="outlined" />;
      } else if (ingredient.stage !== ProcessingStage.approved) {
        return (
          <Chip
            label={startCase(foodStatuslabelMapping[ingredient.stage])}
            color={foodStatuslabelColorMapping[ingredient.stage]}
            size="medium"
            variant="outlined"
          />
        );
      }
    }
  };
  return (
    <>
      <Stack sx={styles.headerStack}>
        <RouteBreadCrumb items={breadcrumbItems} />
        {props.operation === FormOperation.update && (
          <Typography variant="h5" sx={styles.countryText}>
            {props.operation === FormOperation.update
              ? `${capitalize(props.ingredient.brand)} - ${capitalize(resolveCountry(props.ingredient.country))} - ${formatKitchenText(props.ingredient.kitchen)}`
              : 'New Ingredient'}
          </Typography>
        )}
      </Stack>
      <Stack sx={styles.actionStack}>
        <Stack sx={styles.titleStack}>
          <Typography sx={styles.ingredientTitle}>
            {props.operation === FormOperation.update ? props.ingredient.name.en : 'New Ingredient'}
          </Typography>
          {renderIngredientStatusChip()}
          {props.operation === FormOperation.update && props.ingredient.usedOnMenu && (
            <UsedOnMenuIndicator
              filteredFoodUsed={props.ingredient.usedOnMenu}
              usedOnMenuButtonColor={usedOnMenuButtonColor}
              type="ingredient"
            />
          )}
        </Stack>
        <Stack sx={styles.buttonStack}>
          {children}
          <LoadingButton
            variant="outlined"
            disabled={disabled}
            sx={styles.submitButton}
            onClick={() => handleSubmit()}
            startIcon={<CreateIcon style={{ fontSize: '24px' }} />}
          >
            {props.operation === FormOperation.update ? 'Save Changes' : 'Add Ingredient'}
          </LoadingButton>
        </Stack>
      </Stack>
    </>
  );
};

export default IngredientHeaderCard;
