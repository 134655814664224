import { Brand, Country, Kitchen } from '@calo/types';
import { Card, Stack, Typography } from '@mui/material';
import { caloThemeV2 } from 'assets/themev2';
import { format, getWeek } from 'date-fns';
import { MenuPresentationType, Routes } from 'lib/enums';
import { resolveCountry } from 'lib/helpers';
import { upperCase } from 'lodash-es';
import { Link, generatePath } from 'react-router-dom';
import { styles } from './styles';

export type MenuPageHeaderCardProps = {
  country: Country;
  kitchen: Kitchen;
  create?: boolean;
  menuPresentation: MenuPresentationType;
};

const MenuHeaderCard = ({ country, kitchen, create, menuPresentation }: MenuPageHeaderCardProps) => {
  const path = generatePath(Routes.menuList, {
    brand: Brand.CALO,
    kitchen: Kitchen.BH1,
    country: Country.BH,
    day: menuPresentation === MenuPresentationType.daily ? format(new Date(), 'yyyy-MM-dd') : getWeek(new Date()),
    menuPresentation: menuPresentation
  });

  return (
    <>
      <Card variant="outlined" sx={styles.card}>
        <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'space-between' }}>
          <Stack sx={styles.stack}>
            <Stack sx={{ flexDirection: 'row', gap: 1 }}>
              <Typography sx={{ fontWeight: 400, opacity: 0.6, color: caloThemeV2.palette.text.secondary }}>Menu</Typography>
              <Typography sx={{ opacity: 0.6, color: caloThemeV2.palette.text.secondary }}>{'>'}</Typography>
              {create && (
                <Link to={path}>
                  <Typography
                    sx={{
                      fontWeight: 400,
                      opacity: 0.87,
                      color: caloThemeV2.palette.text.primary,
                      ...(create && {
                        '&:hover': {
                          textDecoration: 'underline'
                        }
                      })
                    }}
                  >
                    {menuPresentation === MenuPresentationType.daily ? 'Daily Menu' : 'Weekly Menu'}
                  </Typography>
                </Link>
              )}
              {!create && (
                <Typography
                  sx={{
                    fontWeight: 400,
                    opacity: 0.87,
                    color: caloThemeV2.palette.text.primary
                  }}
                >
                  {menuPresentation === MenuPresentationType.daily ? 'Daily Menu' : 'Weekly Menu'}
                </Typography>
              )}
              {create && (
                <>
                  <Typography sx={{ opacity: 0.6, color: caloThemeV2.palette.text.secondary }}>{'>'}</Typography>
                  <Typography sx={{ fontWeight: 400, opacity: 0.87, color: caloThemeV2.palette.text.primary }}>
                    Create Menu
                  </Typography>
                </>
              )}
            </Stack>
          </Stack>
          <Stack>
            <Typography>
              {upperCase(resolveCountry(country))} / {kitchen}
            </Typography>
          </Stack>
        </Stack>
        <Typography sx={{ fontSize: '34px', fontWeight: 400, opacity: 0.87, color: caloThemeV2.palette.text.primary }}>
          Menu
        </Typography>
      </Card>
    </>
  );
};

export default MenuHeaderCard;
