import React from 'react';
import { Button, Menu, MenuItem, SxProps, Theme } from '@mui/material-v6';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { caloTheme } from 'assets/images/theme/calo';
import { useUserRoles } from 'lib/hooks';
import { Permission } from 'lib/enums';

interface ApprovalActionMenuProps {
  handleDelete: () => void;
  handleUpdate: () => void;
  isDeleteDisabled: boolean;
  isUpdateDisabled: boolean;
  buttonSx?: SxProps<Theme>;
  buttonVariant?: React.ComponentProps<typeof Button>['variant'];
}

const ActionMenu = ({ handleDelete, handleUpdate, isDeleteDisabled, isUpdateDisabled }: ApprovalActionMenuProps) => {
  const userRoles = useUserRoles();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        size="large"
        endIcon={<ExpandMoreIcon />}
        id="basic-button"
        aria-controls={menuOpen ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={menuOpen ? 'true' : undefined}
        onClick={handleMenuOpen}
      >
        Actions
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
        <MenuItem
          sx={{
            '&:hover': {
              backgroundColor: caloTheme.palette.primaryMain200
            }
          }}
          onClick={() => {
            handleUpdate();
            handleClose();
          }}
          disabled={isUpdateDisabled}
        >
          Update component
        </MenuItem>
        {userRoles.includes(Permission.DELETE_FOOD_COMPONENT) && (
          <MenuItem
            sx={{
              '&:hover': {
                backgroundColor: caloTheme.palette.primaryMain200
              }
            }}
            onClick={() => {
              handleDelete();
              handleClose();
            }}
            disabled={isDeleteDisabled}
          >
            Delete component
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

export default ActionMenu;
