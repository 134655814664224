import { caloThemeV2 } from 'assets/themev2';

export const styles = {
  dialogContainer: {
    '& .MuiDialog-paper': {
      width: '30.4%',
      maxWidth: '640px'
    }
  },
  title: {
    fontSize: '20px',
    fontWeight: 500,
    lineHeight: '150%'
  },
  description: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '150%'
  },
  dialogContentContainer: (hasItems: boolean) => ({
    borderTop: hasItems ? `1px solid ${caloThemeV2.palette.divider}` : undefined,
    borderBottom: hasItems ? `1px solid ${caloThemeV2.palette.divider}` : undefined
  }),
  componentListItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    px: 0
  },
  componentTitle: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '150%',
    color: caloThemeV2.palette.text.primary
  },
  componentType: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '150%',
    color: caloThemeV2.palette.text.secondary
  },
  cancelButton: {
    color: 'black'
  }
};
