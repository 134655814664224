import { CreateMenuReq } from '@calo/dashboard-types';
import { FoodType } from '@calo/types';
import { Button, Stack, Typography } from '@mui/material-v6';
import { createMenu, getMenu } from 'actions/menu';
import { caloThemeV2 } from 'assets/themev2';
import { format, subDays } from 'date-fns';
import { FormikErrors } from 'formik';
import { MenuPresentationType, Routes } from 'lib/enums';
import { formatNumber, getMenuTagsFromFoodList, handleMealCost } from 'lib/helpers';
import { Food, Menu, MenuFood } from 'lib/interfaces';
import { ceil, sortBy, uniq, uniqBy } from 'lodash-es';
import { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { generatePath, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AddMealsOptionsDailyMenu } from '../../components';

interface FoodToReplace {
  food: MenuFood;
  menuId?: string;
}

interface DailyMenuActionsCardProps {
  menu: Menu;
  disabled?: boolean;
  foodToReplace?: FoodToReplace;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => Promise<void> | Promise<FormikErrors<Menu>>;
  setFoodToReplace: React.Dispatch<React.SetStateAction<FoodToReplace | undefined>>;
}

const DailyMenuActionsCard = ({ menu, disabled, foodToReplace, setFieldValue, setFoodToReplace }: DailyMenuActionsCardProps) => {
  const history = useHistory();

  const { mutateAsync: createMutation, isLoading: isCreateMenuLoading } = useMutation(createMenu);

  const [menuCost, setMenuCost] = useState<number>(0);
  const [duplicateMenuDate, setDuplicateMenuDate] = useState<Date>(subDays(new Date(), 1));
  const [deletedDuplicatedMenuFood, setDeletedDuplicatedMenuFood] = useState<string[]>([]);

  const filteredList = menu.food.filter((food) => ['XS', 'S', 'M', 'L'].includes(food.size));
  const sortedList = sortBy(filteredList, (f) => `${f.name.en}-${f.size}`);
  const groupByName = uniqBy(sortedList, 'name.en');

  useEffect(() => {
    const calculateMenuCost = () => {
      let cost = 0;
      let numberOfMeals = 0;
      for (const food of groupByName) {
        if (
          food.type.includes(FoodType.breakfast) ||
          food.type.includes(FoodType.lunch) ||
          food.type.includes(FoodType.dinner) ||
          food.type.includes(FoodType.snack)
        ) {
          const foodCost = handleMealCost(food.name.en, sortedList, menu.kitchen);
          cost += foodCost;
          numberOfMeals += 1;
        }
      }
      setMenuCost(ceil(+formatNumber(cost) / numberOfMeals));
    };
    calculateMenuCost();
  }, [groupByName, sortedList]);

  const handleCreateMenu = () => {
    onSubmit({
      day: menu.day,
      food: menu.food.map((food) => food.id),
      tags: menu.tags as any,
      draft: menu.draft as any,
      brand: menu.brand,
      country: menu.country,
      kitchen: menu.kitchen
    });
  };

  const onSubmit = async (values: CreateMenuReq) => {
    await createMutation(values, {
      onSuccess: (data: Menu) => {
        if (!data) {
          return;
        }
        const path = generatePath(Routes.menuList, {
          brand: menu.brand,
          country: menu.country,
          kitchen: menu.kitchen,
          day: menu.day,
          menuPresentation: MenuPresentationType.daily
        });
        history.push(path);
      }
    });
  };

  const { refetch: fetchDuplicateMenu, isFetching: isDuplicateMenuLoading } = useQuery<any, Error, Menu>(
    [`menu/${format(duplicateMenuDate, 'yyyy-MM-dd')}`, { brand: menu.brand, kitchen: menu.kitchen }],
    getMenu,
    {
      enabled: false,
      onSuccess: (data: Menu) => {
        const validFood = data.food.filter((food) => !food.deletedAt);
        const deletedFood = data.food.filter((food) => food.deletedAt);
        const newTags = getMenuTagsFromFoodList(validFood as Food[], new Date(menu.day).getTime());
        setDeletedDuplicatedMenuFood(uniq(deletedFood.map((food) => food.name.en)));
        setFieldValue('food', validFood);
        setFieldValue('tags', newTags);
        setFieldValue('draft', data.draft);
      }
    }
  );

  const handleReplaceFood = (selectedFoodSizes: Food[]) => {
    if (!menu) {
      return;
    }
    if (!foodToReplace) {
      toast('No food to replace with', { type: 'error' });
      return;
    }
    const menuTime = new Date(menu.day).getTime();

    const filteredFood = menu.food.filter((f) => f.name.en !== foodToReplace.food.name.en);
    const updatedFood = [...filteredFood, ...selectedFoodSizes.map((food) => ({ ...food, new: true }))];
    const updatedTags = getMenuTagsFromFoodList(selectedFoodSizes, menuTime);

    setFieldValue('tags', updatedTags);
    setFieldValue('food', updatedFood);
  };

  const handleAddFood = (selectedFoodSizes: Food[]) => {
    if (!menu) {
      return;
    }
    const menuTime = new Date(menu.day).getTime();
    const updatedFood = uniqBy([...menu.food, ...selectedFoodSizes.map((f) => ({ ...f, new: true }))], 'id');
    const updatedTags = getMenuTagsFromFoodList(updatedFood as Food[], menuTime);

    setFieldValue('tags', updatedTags);
    setFieldValue('food', updatedFood);
  };

  return (
    <>
      <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between' }}>
        <Stack sx={{ flexDirection: 'row', gap: 2 }}>
          {menu.food.length > 0 && (
            <>
              <Typography
                sx={{ fontSize: '20px', color: caloThemeV2.palette.text.primary, fontWeight: 600 }}
              >{`${uniqBy(menu.food, 'name.en').length} Meals`}</Typography>
              <Typography
                sx={{ fontSize: '20px', color: caloThemeV2.palette.text.primary, fontWeight: 600 }}
              >{`Daily Menu Cost: ${menuCost || 0}%`}</Typography>
            </>
          )}
        </Stack>
        <Stack sx={{ gap: 2, flexDirection: 'row' }}>
          <AddMealsOptionsDailyMenu
            selectedMenu={menu}
            disabled={disabled || isCreateMenuLoading}
            foodToReplace={foodToReplace}
            isDuplicateMenuLoading={isDuplicateMenuLoading}
            duplicateMenuDate={duplicateMenuDate}
            deletedDuplicatedMenuFood={deletedDuplicatedMenuFood}
            setDeletedDuplicatedMenuFood={setDeletedDuplicatedMenuFood}
            handleReplaceFood={handleReplaceFood}
            fetchDuplicateMenu={fetchDuplicateMenu}
            handleFoodChanges={handleAddFood}
            setFoodToReplace={setFoodToReplace}
            setDuplicateMenuDate={setDuplicateMenuDate}
          />
          {menu.food.length > 0 && (
            <Button
              variant="outlined"
              color="inherit"
              size="large"
              sx={{
                height: '42px',
                paddingX: '22px',
                fontSize: '15px',
                fontWeight: 500
              }}
              disabled={menu.food.length === 0 || disabled || isCreateMenuLoading}
              onClick={handleCreateMenu}
            >
              Save
            </Button>
          )}
        </Stack>
      </Stack>
    </>
  );
};

export default DailyMenuActionsCard;
