import { Box, InputLabel } from '@mui/material';
import { caloTheme } from 'assets/images/theme/calo';
import { SelectOptionString } from 'lib/interfaces';
import { isArray } from 'lodash-es';
import RSelect, { GroupBase, OptionsOrGroups, Props } from 'react-select';

export interface SelectProps extends Omit<Props<SelectOptionString>, 'value'> {
  label?: string;
  placeholder?: string;
  value?: any;
  hidden?: boolean;
  error?: boolean;
  areDuplicatesAllowed?: boolean;
  isDisabled?: boolean;
  isMulti?: boolean;
  customStyles?: any;
  isRequiredField?: boolean;
}

const flattenOptions = (options: OptionsOrGroups<SelectOptionString, GroupBase<SelectOptionString>>): SelectOptionString[] => {
  return options.reduce<SelectOptionString[]>((acc, option) => {
    if ('options' in option) {
      return [...acc, ...option.options];
    }
    return [...acc, option];
  }, []);
};

const mapValueToSVal = (options: OptionsOrGroups<SelectOptionString, GroupBase<SelectOptionString>>, value?: any) => {
  const flatOptions = flattenOptions(options);

  if (isArray(value)) {
    return flatOptions.filter((v) => value.includes(v.value));
  }

  return flatOptions.find((v) => v.value === value) || null;
};

const SelectMUI = ({
  error,
  label,
  placeholder,
  value,
  options,
  hidden,
  isMulti,
  customStyles,
  areDuplicatesAllowed,
  isDisabled = false,
  isRequiredField = false,
  ...rest
}: SelectProps) => (
  <Box sx={{ visibility: hidden ? 'hidden' : 'visible' }}>
    {label && (
      <InputLabel
        size="small"
        htmlFor={label}
        sx={{
          position: 'relative',
          top: '-7px',
          left: '12px',
          width: 'fit-content',
          zIndex: 1,
          fontSize: '12px',
          color: caloTheme.palette.neutral700,
          backgroundColor: isDisabled ? 'transparent' : caloTheme.palette.white,
          transition: 'top 0.1s, font-size 0.1s'
        }}
      >
        {label}
        {isRequiredField && <span>*</span>}
      </InputLabel>
    )}
    <RSelect
      inputId={rest['data-test']}
      id={label || Math.random().toString()}
      isDisabled={isDisabled}
      value={areDuplicatesAllowed ? value : mapValueToSVal(options ?? [], value)}
      options={options}
      isMulti={isMulti}
      blurInputOnSelect={false}
      menuPortalTarget={document.body}
      styles={{ ...customStyles, menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
      className={error ? 'border border-red-500' : undefined}
      placeholder={placeholder || 'Select...'}
      {...rest}
    />
  </Box>
);

export default SelectMUI;
