import React from 'react';

import { FormikErrors } from 'formik';

import { CreateFoodReq, UpdateFoodReq } from '@calo/dashboard-types';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import { IconButton, InputLabel, Stack, TextField, Tooltip, Typography } from '@mui/material';

import { Macros, Micronutrients } from '@calo/types';
import { caloTheme } from 'assets/images/theme/calo';
import { InputValueRestrictionType } from 'lib/enums';
import { isNumberInputValid } from 'lib/helpers';
import IOSSwitch from '../IOSSwitch';

interface FoodNutritionalFormProps {
  macros?: Macros;
  micronutrients?: Micronutrients;
  setFieldValue: any;
  handleChange: (e: React.ChangeEvent<any>) => void;
  handleBlur: (e: React.ChangeEvent<any>) => void;
  formType: 'create' | 'update';
  microNutrientsAuto: boolean;
  setMicroNutrientsAuto: (value: boolean) => void;
  displayMessageForMacrosLimits?: (macrosProp: 'carbs' | 'cal' | 'protein' | 'fat') => string;
  errors: FormikErrors<Omit<CreateFoodReq | UpdateFoodReq, 'id'>>;
}

const FoodNutritionalForm = ({
  microNutrientsAuto,
  setMicroNutrientsAuto,
  errors,
  macros,
  micronutrients,
  setFieldValue,
  handleChange,
  handleBlur,
  formType,
  displayMessageForMacrosLimits
}: FoodNutritionalFormProps) => {
  const handleChangeForNumRestrictions = (event: React.ChangeEvent<any>) => {
    const { name, value } = event.target;
    if (isNumberInputValid(value, InputValueRestrictionType.macros)) {
      setFieldValue(`${name}`, Number(value));
    }
  };

  return (
    <Stack direction="column" justifyContent="space-between" alignItems="stretch">
      <Stack direction="row" justifyContent="space-between" alignItems="stretch" spacing={2}>
        <Stack direction="column" justifyContent="flex-start" alignItems="flex-start" sx={{ width: '16.5%' }}>
          <TextField
            label="Calories*"
            value={macros?.cal}
            name="macros.cal"
            onChange={handleChangeForNumRestrictions}
            onBlur={handleBlur}
            type="number"
            sx={{ width: '100%' }}
            disabled={!microNutrientsAuto}
            error={!!(errors.macros as any)?.cal}
            InputProps={{ style: { borderRadius: 8 }, id: 'caloriesFoodInput' }}
          />
          {formType === 'update' && displayMessageForMacrosLimits && (
            <InputLabel sx={{ my: '2px', fontSize: '12px', fontWeight: 400, color: 'red', whiteSpace: 'normal' }}>
              {displayMessageForMacrosLimits('cal')}
            </InputLabel>
          )}
        </Stack>
        <Stack direction="column" justifyContent="flex-start" alignItems="flex-start" sx={{ width: '16.5%' }}>
          <TextField
            label="Protein*"
            value={macros?.protein}
            name="macros.protein"
            onChange={handleChangeForNumRestrictions}
            onBlur={handleBlur}
            type="number"
            disabled={!microNutrientsAuto}
            sx={{ width: '100%' }}
            error={!!(errors.macros as any)?.protein}
            InputProps={{ style: { borderRadius: 8 }, id: 'proteinFoodInput' }}
          />
          {formType === 'update' && displayMessageForMacrosLimits && (
            <InputLabel sx={{ my: '2px', fontSize: '12px', fontWeight: 400, color: 'red', whiteSpace: 'normal' }}>
              {displayMessageForMacrosLimits('protein')}
            </InputLabel>
          )}
        </Stack>
        <Stack direction="column" justifyContent="flex-start" alignItems="flex-start" sx={{ width: '16.5%' }}>
          <TextField
            label="Carbs*"
            value={macros?.carbs}
            name="macros.carbs"
            type="number"
            disabled={!microNutrientsAuto}
            onChange={handleChangeForNumRestrictions}
            onBlur={handleBlur}
            sx={{ width: '100%' }}
            error={!!(errors.macros as any)?.carbs}
            InputProps={{ style: { borderRadius: 8 }, id: 'carbsFoodInput' }}
          />
          {formType === 'update' && displayMessageForMacrosLimits && (
            <InputLabel sx={{ my: '2px', fontSize: '12px', fontWeight: 400, color: 'red', whiteSpace: 'normal' }}>
              {displayMessageForMacrosLimits('carbs')}
            </InputLabel>
          )}
        </Stack>
        <Stack direction="column" justifyContent="flex-start" alignItems="flex-start" sx={{ width: '16.5%' }}>
          <TextField
            label="Fat*"
            value={macros?.fat}
            name="macros.fat"
            onChange={handleChangeForNumRestrictions}
            onBlur={handleBlur}
            type="number"
            disabled={!microNutrientsAuto}
            sx={{ width: '100%' }}
            error={!!(errors.macros as any)?.fat}
            InputProps={{ style: { borderRadius: 8 }, id: 'fatFoodInput' }}
          />
          {formType === 'update' && displayMessageForMacrosLimits && (
            <InputLabel sx={{ my: '2px', fontSize: '12px', fontWeight: 400, color: 'red', whiteSpace: 'normal', width: '100%' }}>
              {displayMessageForMacrosLimits('fat')}
            </InputLabel>
          )}
        </Stack>
        <Stack direction="column" justifyContent="flex-start" alignItems="flex-start" sx={{ width: '16.5%' }}>
          <TextField
            label="Fiber"
            type="number"
            name="macros.fiber"
            onBlur={handleBlur}
            onChange={handleChangeForNumRestrictions}
            value={macros?.fiber}
            disabled={!microNutrientsAuto}
            sx={{ width: '100%' }}
            InputProps={{ style: { borderRadius: 8 }, id: 'fiberFoodInput' }}
          />
        </Stack>
        <Stack direction="column" justifyContent="flex-start" alignItems="center" spacing={1} sx={{ width: '16.5%' }}>
          <Typography sx={{ color: caloTheme.palette.neutral700, fontSize: '12px', lineHeight: '14px' }}>
            Manual Nutritional Values
          </Typography>
          <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={4}>
            <Tooltip title="By enabling this option, the meal nutritional values will be disconnected from the components level, and will instead remain constant with the values inserted for this meal">
              <IconButton
                sx={{
                  backgroundColor: caloTheme.palette.secondaryRed100,
                  borderRadius: '240px',
                  '&:hover': { backgroundColor: caloTheme.palette.secondaryRed100 }
                }}
              >
                <WarningAmberRoundedIcon style={{ color: '#DC2626' }} />
              </IconButton>
            </Tooltip>
            <IOSSwitch
              id="toggleManualNutritionInputOnFood"
              onChange={() => setMicroNutrientsAuto(!microNutrientsAuto)}
              sx={{ color: 'white' }}
              checked={microNutrientsAuto}
            />
          </Stack>
        </Stack>
      </Stack>
      <Stack direction="row" justifyContent="space-between" alignItems="stretch" spacing={2} sx={{ mt: 2 }}>
        <Stack direction="column" justifyContent="flex-start" alignItems="flex-start" sx={{ width: '16.5%' }}>
          <TextField
            label="Saturated Fats"
            value={micronutrients?.saturatedFats}
            name="micronutrients.saturatedFats"
            onChange={handleChange}
            onBlur={handleBlur}
            type="number"
            sx={{ width: '100%', mr: 1 }}
            disabled={!microNutrientsAuto}
            error={!!(errors.micronutrients as any)?.saturatedFats}
            InputProps={{ style: { borderRadius: 8 }, id: 'saturatedFatsFoodInput' }}
          />
        </Stack>
        <Stack direction="column" justifyContent="flex-start" alignItems="flex-start" sx={{ width: '16.5%' }}>
          <TextField
            label="Trans Fats"
            value={micronutrients?.transFats}
            name="micronutrients.transFats"
            onChange={handleChange}
            onBlur={handleBlur}
            type="number"
            disabled={!microNutrientsAuto}
            sx={{ width: '100%', mr: 1 }}
            error={!!(errors.micronutrients as any)?.transFats}
            InputProps={{ style: { borderRadius: 8 }, id: 'transFatsFoodInput' }}
          />
        </Stack>
        <Stack direction="column" justifyContent="flex-start" alignItems="flex-start" sx={{ width: '16.5%' }}>
          <TextField
            label="Sodium"
            type="number"
            onBlur={handleBlur}
            onChange={handleChange}
            name="micronutrients.sodium"
            disabled={!microNutrientsAuto}
            sx={{ width: '100%', mr: 1 }}
            value={micronutrients?.sodium}
            error={!!(errors.micronutrients as any)?.sodium}
            InputProps={{ style: { borderRadius: 8 }, id: 'sodiumFoodInput' }}
          />
        </Stack>
        <Stack direction="column" justifyContent="flex-start" alignItems="flex-start" sx={{ width: '16.5%' }}>
          <TextField
            label="Cholesterol"
            type="number"
            value={micronutrients?.cholesterol}
            name="micronutrients.cholesterol"
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={!microNutrientsAuto}
            sx={{ width: '100%', mr: 1 }}
            error={!!(errors.micronutrients as any)?.cholesterol}
            InputProps={{ style: { borderRadius: 8 }, id: 'cholesterolFoodInput' }}
          />
        </Stack>
        <Stack direction="column" justifyContent="flex-start" alignItems="flex-start" sx={{ width: '16.5%' }}>
          <TextField
            label="Added sugar"
            type="number"
            value={micronutrients?.addedSugar}
            name="micronutrients.addedSugar"
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={!microNutrientsAuto}
            sx={{ width: '100%', mr: 1 }}
            error={!!(errors.micronutrients as any)?.addedSugar}
            InputProps={{ style: { borderRadius: 8 }, id: 'addedSugarFoodInput' }}
          />
        </Stack>
        <Stack direction="column" justifyContent="flex-start" alignItems="flex-start" sx={{ width: '16.5%' }}>
          <TextField
            label="Total Sugar"
            type="number"
            value={micronutrients?.totalSugar}
            name="micronutrients.totalSugar"
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={!microNutrientsAuto}
            sx={{ width: '100%', mr: 1 }}
            error={!!(errors.micronutrients as any)?.totalSugar}
            InputProps={{ style: { borderRadius: 8 }, id: 'totalSugarFoodInput' }}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default FoodNutritionalForm;
