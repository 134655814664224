import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Box, Button, Stack, Typography } from '@mui/material-v6';
import { caloTheme } from 'assets/images/theme/calo';
import { caloThemeV2 } from 'assets/themev2';
import { Food, MenuFood } from 'lib/interfaces';
import { renderFoodDetails } from './helpers';

interface ConfirmationPopupProps {
  foodToReplace?: { food: MenuFood; menuId?: string };
  selectedFoodReplace?: Food;
  confirmationRef: any;
  confirmReplaceHandler: () => void;
}

const ConfirmationPopup = ({
  confirmationRef,
  selectedFoodReplace,
  foodToReplace,
  confirmReplaceHandler
}: ConfirmationPopupProps) => {
  return (
    <Box>
      <Stack sx={{ flexDirection: 'row', width: '100%', justifyContent: 'center', my: 1 }}>
        <Stack sx={styles.foodStack}>
          <Typography sx={styles.typographyTitle}>{foodToReplace?.food.name.en}</Typography>
          <Typography sx={styles.typographyDetails}>{renderFoodDetails(foodToReplace?.food)}</Typography>
        </Stack>
        <Typography sx={{ display: 'flex', justifyContent: 'center', my: 'auto', mx: 1 }}>
          {' '}
          <ChevronRightIcon />{' '}
        </Typography>
        <Stack sx={styles.foodStack}>
          <Typography sx={styles.typographyTitle}>{selectedFoodReplace?.name.en || ''}</Typography>
          <Typography sx={styles.typographyDetails}>{renderFoodDetails(selectedFoodReplace)}</Typography>
        </Stack>
      </Stack>
      <Stack sx={{ flexDirection: 'row', gap: 2, alignSelf: 'flex-end', justifyContent: 'flex-end', marginTop: 4 }}>
        <Button
          variant="outlined"
          sx={{
            width: '72px',
            height: '36px'
          }}
          disabled={!selectedFoodReplace}
          onClick={confirmReplaceHandler}
        >
          Swap
        </Button>
        <Button variant="text" sx={{ color: caloThemeV2.palette.text.primary }} onClick={() => confirmationRef.current?.close()}>
          Cancel
        </Button>
      </Stack>
    </Box>
  );
};

export default ConfirmationPopup;

const styles = {
  foodStack: {
    backgroundColor: caloTheme.palette.neutral50,
    padding: '8px 4px 8px 4px',
    minHeight: '68px',
    width: '44%',
    gap: '8px',
    borderRadius: '8px'
  },
  typographyTitle: {
    textTransform: 'capitalize',
    justifyContent: 'center',
    display: 'flex',
    color: caloTheme.palette.neutral900,
    fontWeight: 600,
    fontsize: '20px',
    lineHeight: '24px',
    alignContent: 'center',
    textAlign: 'center'
  },
  typographyDetails: {
    textTransform: 'capitalize',
    justifyContent: 'center',
    display: 'flex',
    flexWrap: 'wrap',
    color: caloTheme.palette.neutral900,
    fontWeight: 400,
    fontsize: '16px',
    lineHeight: '20px',
    alignContent: 'center',
    textAlign: 'center',
    width: '100%'
  },
  buttonStyle: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    textTransform: 'none',
    fontSize: '20px',
    fontWeight: 600,
    lineHeight: '24px',
    boxShadow: 'none',
    width: '153px',
    height: '44px',
    padding: '10px 20px 10px 20px',
    gap: '10px',
    borderRadius: '8px'
  }
};
