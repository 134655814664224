import { useMemo, useState } from 'react';
import { getListWithParams, getListWithParamsPost, toggleUISettings } from 'actions';
import { SideDrawer } from 'components';
import { getAccessibleCountries, getKitchenOptions, handleSearch } from 'lib/helpers';
import { useUserKitchens } from 'lib/hooks';
import { AppState, FoodComponent, Ingredient, Options } from 'lib/interfaces';
import { capitalize, isNull, sortBy } from 'lodash';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { FoodComponentFilters, FoodComponentStatus, ProcessingStage } from '@calo/dashboard-types';
import { Brand, Country, CustomMealCategory, Kitchen } from '@calo/types';
import { Autocomplete, Box, Button, CircularProgress, Divider, IconButton, TextField } from '@mui/material-v6';
import { styles } from './styles';
import { Typography } from '@mui/material-v6';
import CloseIcon from '@mui/icons-material/Close';
import SelectMUI2 from 'components/MUI/SelectMUI2';
import InputThemeProvider from 'components/MUI/InputThemeProvider';
import { caloThemeV2 } from 'assets/themev2';

interface SettingsProps {
  onFilter: (values: FoodComponentFilters) => void;
  filters: FoodComponentFilters;
}

const Settings = ({ filters, onFilter }: SettingsProps) => {
  const { settingsVisible } = useSelector((state: AppState) => ({
    settingsVisible: state.ui.settings
  }));

  const userKitchens: string[] = useUserKitchens();
  const dispatch = useDispatch();
  const [allIngredients, setAllIngredients] = useState<Ingredient[]>([]);
  const [filterIngredients, setFilterIngredients] = useState<string>();
  const [allChildComponents, setAllChildComponents] = useState<FoodComponent[]>([]);
  const [filterChildComponents, setFilterChildComponents] = useState<string>();

  const { isLoading: ingLoading } = useQuery<any, Error, { data: Ingredient[] }>(
    [
      'ingredients',
      {
        filters: {
          country: filters.country,
          brand: filters.brand || Brand.CALO,
          kitchen: filters.kitchen || Kitchen.BH1,
          name: filterIngredients || undefined,
          ids: !filterIngredients && filters.ingredientId ? filters.ingredientId : undefined,
          stage: ProcessingStage.approved
        }
      }
    ],
    getListWithParams,
    {
      enabled: !!filterIngredients || !filters.ingredientId,
      onSuccess: (allIngredientList) => {
        setAllIngredients(allIngredientList?.data);
      }
    }
  );

  const ingredientOptions: Options<Ingredient>[] = useMemo(
    () =>
      sortBy(allIngredients, (ingredient) => `${ingredient.name.en}`).map((ingredient) => ({
        value: ingredient.id,
        data: { ...ingredient },
        label: `${ingredient.name.en} (${ingredient.internalName || ''})`
      })),
    [allIngredients]
  );

  const { isLoading: foodComponentLoading } = useQuery<any, Error, { data: FoodComponent[] }>(
    [
      'food-components/list',
      {
        filters: {
          country: filters.country,
          brand: filters.brand || Brand.CALO,
          kitchen: filters.kitchen || Kitchen.BH1,
          name: filterChildComponents || undefined,
          ids: !filterChildComponents && filters.childComponentId ? filters.childComponentId : undefined,
          stage: ProcessingStage.approved
        }
      }
    ],
    getListWithParamsPost,
    {
      enabled: !!filterChildComponents || !filters.childComponentId,
      onSuccess: (allChildComponentsList) => {
        setAllChildComponents(allChildComponentsList?.data);
      }
    }
  );

  const ccOptions: Options<FoodComponent>[] = useMemo(
    () =>
      sortBy(allChildComponents, (cc) => `${cc.name.en}`).map((cc) => ({
        value: cc.id,
        data: { ...cc },
        label: `${cc.name.en} (${cc.internalName || ''})`
      })),
    [allChildComponents]
  );

  const handleClearFilter = () => {
    onFilter({ ...filters, ingredientId: undefined, status: undefined, customMealCategory: undefined });
  };

  const isClearFilterDisabled = !filters.ingredientId && !filters.status && !filters.customMealCategory;

  return (
    <SideDrawer visible={settingsVisible} onClose={() => dispatch(toggleUISettings())}>
      <Box sx={styles.main}>
        <Box width={'100%'}>
          <Box sx={styles.heading}>
            <Typography variant="h5" sx={styles.title}>
              Filters
            </Typography>
            <IconButton onClick={() => dispatch(toggleUISettings())}>
              <CloseIcon fontSize="medium" sx={styles.closeIcon} />
            </IconButton>
          </Box>
          <InputThemeProvider>
            <Box sx={styles.dropdownContainer}>
              <SelectMUI2
                data-test="countrySelect"
                label="Country"
                value={filters.country}
                onChange={(value) =>
                  onFilter({
                    ...filters,
                    country: value as Country,
                    kitchen: userKitchens.find((k) => k.includes(value as Country)) as Kitchen
                  })
                }
                options={getAccessibleCountries(userKitchens).map((c) => ({
                  value: c,
                  label: Country[c]
                }))}
              />
              <SelectMUI2
                data-test="kitchenSelect"
                label="Kitchen"
                value={filters.kitchen}
                onChange={(value) =>
                  onFilter({
                    ...filters,
                    kitchen: value as Kitchen
                  })
                }
                options={getKitchenOptions(userKitchens, filters.country as Country)}
                disabled={userKitchens.filter((r) => r.includes(filters.country as Country)).length === 0}
              />
            </Box>
            <Divider sx={styles.divider} />
            <Box sx={styles.dropdownContainer}>
              <Autocomplete
                data-test="ingredientSelect"
                options={ingredientOptions}
                loading={ingLoading}
                onChange={(event, ingredient) => {
                  if (isNull(ingredient)) {
                    onFilter({ ...filters, ingredientId: undefined });
                  } else {
                    onFilter({ ...filters, ingredientId: ingredient.value });
                  }
                }}
                onInputChange={(event, value) => {
                  handleSearch({ text: value, action: 'input-change', name: setFilterIngredients });
                }}
                getOptionLabel={(option) => option.label}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Ingredient"
                    variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {ingLoading ? <CircularProgress sx={{ color: caloThemeV2.palette.primary.main }} size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </>
                      )
                    }}
                  />
                )}
              />

              <SelectMUI2
                data-test="customMealCategorySelect"
                label="Custom Meal Category"
                value={filters.customMealCategory}
                options={[
                  {
                    value: undefined,
                    label: 'Any'
                  },
                  ...Object.keys(CustomMealCategory).map((value) => ({
                    value,
                    label: capitalize(value)
                  }))
                ]}
                onChange={(value) => {
                  const selectedCustomMealCategory = value as CustomMealCategory | undefined;
                  onFilter({
                    ...filters,
                    customMealCategory: selectedCustomMealCategory ? selectedCustomMealCategory : undefined
                  });
                }}
              />
              <SelectMUI2
                data-test="recipeStatusSelect"
                label="Recipe Status"
                value={filters.status}
                options={[
                  {
                    value: undefined,
                    label: 'Any'
                  },
                  ...Object.entries(FoodComponentStatus).map(([key, value]) => ({
                    value: key,
                    label: value
                  }))
                ]}
                onChange={(value) => {
                  const selectedFoodComponentStatus = value as FoodComponentStatus | undefined;
                  onFilter({ ...filters, status: selectedFoodComponentStatus ? selectedFoodComponentStatus : undefined });
                }}
              />

              <Autocomplete
                data-test="childComponentSelect"
                options={ccOptions}
                loading={foodComponentLoading}
                onChange={(event, cc) => {
                  if (isNull(cc)) {
                    onFilter({ ...filters, childComponentId: undefined });
                  } else {
                    onFilter({ ...filters, childComponentId: cc.value });
                  }
                }}
                onInputChange={(event, value) => {
                  handleSearch({ text: value, action: 'input-change', name: setFilterChildComponents });
                }}
                getOptionLabel={(option) => option.label}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Child component"
                    variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {foodComponentLoading ? (
                            <CircularProgress sx={{ color: caloThemeV2.palette.primary.main }} size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      )
                    }}
                  />
                )}
              />
            </Box>
          </InputThemeProvider>
        </Box>
        <Box sx={styles.buttonContainer}>
          <Button
            fullWidth
            disabled={isClearFilterDisabled}
            variant="outlined"
            color="primary"
            size="large"
            onClick={handleClearFilter}
          >
            clear filter
          </Button>
        </Box>
      </Box>
    </SideDrawer>
  );
};
export default Settings;
