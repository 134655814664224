import { Button, CircularProgress, FormControl, Stack, TextField, Typography } from '@mui/material';
import { caloTheme } from 'assets/images/theme/calo';
import { Modal, ModalRef } from 'components';
import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';

export const RemoveClubMembershipModal = ({
  isOpen,
  isLoading,
  handleRemoveClubMembership,
  onClose
}: {
  isOpen: boolean;
  isLoading: boolean;
  handleRemoveClubMembership: (reason: string) => void;
  onClose: () => void;
}) => {
  const modalRef = useRef<ModalRef>(null);
  const [reason, setReason] = useState('');

  const handleSave = () => {
    if (!reason) {
      toast('Please enter a reason', {
        type: 'info',
        autoClose: 2000
      });
      return;
    }
    handleRemoveClubMembership(reason);
  };

  useEffect(() => {
    if (isOpen) {
      modalRef.current?.open();
    } else {
      modalRef.current?.close();
    }
  }, [isOpen]);

  return (
    <Modal isNarrow ref={modalRef} onClose={onClose}>
      <Typography
        variant="h6"
        sx={{
          fontSize: '19px',
          lineHeight: '23px',
          fontFamily: caloTheme.typography.fontFamily,
          fontWeight: 600
        }}
      >
        Remove Club Membership
      </Typography>
      <FormControl sx={{ marginY: '15px', width: '100%' }}>
        <Typography>Reason</Typography>
        <TextField id="reason-input" value={reason} onChange={(e) => setReason(e.target.value)} fullWidth />
      </FormControl>
      {isLoading ? (
        <Stack flexDirection="row" justifyContent="center">
          <CircularProgress color="success" />
        </Stack>
      ) : (
        <Button
          onClick={() => handleSave()}
          variant="outlined"
          aria-label="remove-membership-button"
          sx={{
            float: 'right',
            margin: '4px',
            lineHeight: '17px',
            fontWeight: 600,
            fontSize: '14px',
            borderRadius: '8px',
            padding: '12px 20px 12px 20px',
            backgroundColor: caloTheme.palette.primary500,
            borderColor: caloTheme.palette.primary500,
            color: caloTheme.palette.white,
            '&:hover': {
              borderColor: caloTheme.palette.primary600,
              color: caloTheme.palette.primary500
            }
          }}
        >
          Save
        </Button>
      )}
    </Modal>
  );
};
