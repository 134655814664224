import { Permission } from '@calo/dashboard-types';
import { Brand, Kitchen } from '@calo/types';
import {
  Box,
  Breadcrumbs,
  Button,
  InputAdornment,
  Paper,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Typography
} from '@mui/material';
import { getListWithParams, toggleUISettings } from 'actions/index';
import { caloTheme } from 'assets/images/theme/calo';
import CaloLoader from 'components/CaloLoader';
import { ModalRef } from 'components/Modal';
import { Routes } from 'lib/enums';
import { resolveCountryFromKitchen } from 'lib/helpers';
import { useUserKitchens, useUserRoles } from 'lib/hooks';
import { IngredientType, IngredientsQuery } from 'lib/interfaces';
import { useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';
import IngredientCommentsPopup from 'views/ChefPlayground/Shared/IngredientCommentsPopup';
import TableHeaderRow from 'views/ChefPlayground/Shared/TableHeaderRow';
import IngredientRow from './IngredientRow';
import Settings from './Settings';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useDispatch } from 'react-redux';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { InputMUI } from 'components/MUI';
import SearchIcon from '@mui/icons-material/Search';
import CreateIcon from '@mui/icons-material/Create';

const styles = {
  breadcrumbs: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '150%'
  },
  breadcrumbActive: {
    color: caloTheme.palette.textPrimary
  },
  breadcrumbInactive: {
    color: caloTheme.palette.textSecondary
  },
  title: {
    fontSize: '34px',
    fontWeight: '400',
    lineHeight: '125%',
    color: caloTheme.palette.textPrimary
  },
  filterButton: {
    padding: '8px 22px',
    textTransform: 'none',
    color: caloTheme.palette.neutral900,
    fontSize: '15px',
    fontWeight: 500,
    lineHeight: '125%',
    ':hover': {
      backgroundColor: 'transparent'
    },
    border: `1px solid ${caloTheme.palette.textPrimary}`,
    borderRadius: '4px'
  },
  searchInput: {
    width: '90%'
  },
  searchInputProps: {
    height: '40px',
    fontSize: '16px',
    backgroundColor: 'white'
  },
  createButton: {
    padding: '8px 22px',
    textTransform: 'uppercase',
    color: caloTheme.palette.neutral900,
    fontSize: '15px',
    fontWeight: 500,
    lineHeight: '125%',
    ':hover': {
      backgroundColor: 'transparent'
    },
    border: `1px solid ${caloTheme.palette.textPrimary}`,
    borderRadius: '4px'
  },
  tableHead: {
    backgroundColor: caloTheme.palette.grey200,
    border: 0
  },
  stack: {
    borderRadius: '16px',
    py: '16px'
  },
  stackHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  filterBox: {
    display: 'flex',
    alignItems: 'center',
    gap: '24px'
  },
  tableContainer: {
    width: '100%',
    mt: '24px',
    boxShadow: 'none'
  }
};

const chefPlaygroundFoodlistBreadCurmb = [
  <Typography variant="body1" key={1} sx={{ ...styles.breadcrumbInactive, ...styles.breadcrumbs }}>
    Chef's Playground
  </Typography>,
  <Typography variant="body1" key={2} sx={{ ...styles.breadcrumbActive, ...styles.breadcrumbs }}>
    Ingredients
  </Typography>
];

const IngredientList = () => {
  const roles = useUserRoles();
  const location = useLocation();
  const history = useHistory();
  const userKitchen = useUserKitchens();
  const commentsPopupRef = useRef<ModalRef>();
  const searchParams = new URLSearchParams(location.search);
  const dispatch = useDispatch();

  const [page, setPage] = useState<number>(0);

  const [ingredientList, setIngredientList] = useState<IngredientType[]>([]);
  const [selectedIngredient, setSelectedIngredient] = useState<IngredientType | undefined>();

  const [filters, setFilters] = useState<any>({
    country: resolveCountryFromKitchen((userKitchen && userKitchen[0]) || Kitchen.BH1),
    brand: Brand.CALO,
    kitchen: (userKitchen && userKitchen[0]) || Kitchen.BH1,
    ...JSON.parse(searchParams.get('filters') || `{}`)
  });

  useEffect(() => {
    setPage(0);
  }, [filters]);

  const { data, isLoading } = useQuery<any, Error, IngredientsQuery>(
    [
      'ingredients/prototype',
      {
        page,
        filters: {
          ...filters,
          name: filters.name ? filters.name : undefined,
          ingredientId: filters.ingredientId ? filters.ingredientId : undefined
        },
        limit: 50
      }
    ],
    getListWithParams,
    {
      suspense: false,
      enabled: roles.includes(Permission.VIEW_PROTOTYPE_INGREDIENT_LIST),
      onSuccess: () => {
        searchParams.set('filters', JSON.stringify(filters));
        history.push({
          pathname: location.pathname,
          search: searchParams.toString()
        });
      }
    }
  );

  useEffect(() => {
    if (!data?.data) {
      return;
    }
    setIngredientList(data.data as IngredientType[]);
  }, [data]);

  const closeCommentsPopup = () => {
    commentsPopupRef.current?.close();
  };

  const commentClickHandler = (ingredient: IngredientType) => {
    setSelectedIngredient(ingredient);
    commentsPopupRef.current?.open();
  };

  const addCommentSuccessHandler = (data: any) => {
    setSelectedIngredient(data);
    setIngredientList((prev) => {
      const componentIndex = prev.findIndex((ingredient) => ingredient.id === data.id);
      return [...prev.slice(0, componentIndex), data, ...prev.slice(componentIndex + 1)];
    });
  };

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
        <Breadcrumbs separator={<ChevronRightIcon sx={{ fontSize: '15px' }} />} aria-label="breadcrumb">
          {chefPlaygroundFoodlistBreadCurmb}
        </Breadcrumbs>
        <Typography variant="h4" sx={styles.title}>
          Ingredients
        </Typography>
      </Box>
      <Stack sx={{ borderRadius: '16px', py: '16px' }}>
        <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '24px' }}>
            <Button
              onClick={() => dispatch(toggleUISettings())}
              startIcon={<FilterAltIcon style={{ fontSize: '24px' }} />}
              sx={styles.filterButton}
            >
              FILTER
            </Button>
            <InputMUI
              data-test="nameInput"
              type="text"
              placeholder={'Search'}
              value={filters.name}
              onChange={(e) => setFilters({ ...filters, name: e.target.value })}
              debounce
              sx={styles.searchInput}
              inputProps={{
                style: styles.searchInputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon fontSize="medium" />
                  </InputAdornment>
                )
              }}
            />
          </Box>
          <Button
            onClick={() => history.push(Routes.playgroundNewIngredient)}
            disabled={!roles.includes(Permission.CREATE_PROTOTYPE_INGREDIENT)}
            startIcon={<CreateIcon style={{ fontSize: '24px' }} />}
            sx={styles.createButton}
          >
            create ingredient
          </Button>
        </Stack>
        {isLoading ? (
          <CaloLoader />
        ) : (
          <Paper sx={styles.tableContainer}>
            <TableContainer>
              <Table sx={{ width: '100%' }}>
                <TableHead sx={styles.tableHead}>
                  <TableHeaderRow type="ingredient" />
                </TableHead>
                <TableBody>
                  {ingredientList.map((ingredient) => (
                    <IngredientRow key={ingredient.id} ingredient={ingredient} commentClickHandler={commentClickHandler} />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        )}
      </Stack>
      <IngredientCommentsPopup
        selectedIngredient={selectedIngredient}
        ref={commentsPopupRef}
        closeCommentsPopup={closeCommentsPopup}
        successHandler={addCommentSuccessHandler}
      />
      <Settings onFilter={setFilters} filters={filters} />
    </>
  );
};

export default IngredientList;
