import { useState } from 'react';

import { format, parseISO } from 'date-fns/fp';
import { startCase } from 'lodash-es';
import { useHistory } from 'react-router-dom';

import { PaymentSource, Permission, WalletAction } from '@calo/dashboard-types';
import { Button, TableCell, TableRow, Typography, styled, tableCellClasses } from '@mui/material';

import { caloTheme } from 'assets/images/theme/calo';
import { Routes } from 'lib/enums';
import { useUserRoles } from 'lib/hooks';
import { Wallet } from 'lib/interfaces';

interface WalletCardRowProps {
  row: Wallet;
  onRefund: (wallet: Wallet) => Promise<void> | void;
}
const WalletCardRow = ({ row, onRefund }: WalletCardRowProps) => {
  const history = useHistory();
  const roles = useUserRoles();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      width: '100%',
      px: 4,
      component: 'th',
      scope: 'row',
      color: caloTheme.palette.neutral900,
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '14px',
      variant: 'caption'
    },
    [`&.${tableCellClasses.body}`]: {
      px: 4,
      fontWeight: 400,
      fontSize: '12px',
      fontHeight: '14px',
      variant: 'caption',
      fontFamily: caloTheme.typography.fontFamily
    }
  }));
  return (
    <>
      <TableRow
        key={row.id}
        style={{
          backgroundColor: row.action.includes(WalletAction.REMOVE) ? caloTheme.palette.secondaryPurple50 : ''
        }}
      >
        <StyledTableCell>
          {row.amount}{' '}
          {row.action === WalletAction.ADD && row.source !== PaymentSource.credits && row.vat && <span>(-{row.vat} VAT)</span>}
        </StyledTableCell>
        <StyledTableCell>{row.currency}</StyledTableCell>
        <StyledTableCell>{row.source}</StyledTableCell>
        <StyledTableCell>{row.action}</StyledTableCell>
        <StyledTableCell>{row.actor?.name || '---'}</StyledTableCell>
        <StyledTableCell>{startCase(row.reason) || '---'}</StyledTableCell>
        <StyledTableCell>
          {row.notes ? (
            <Button
              variant="text"
              aria-label="Notes"
              sx={{
                height: '45px',
                fontWeight: 400,
                lineHeight: '14px',
                fontSize: '12px',
                borderRadius: '8px',
                fontFamily: caloTheme.typography.fontFamily,
                color: isOpen ? 'white' : caloTheme.palette.secondaryBlue900,
                backgroundColor: isOpen ? caloTheme.palette.secondaryBlue300 : caloTheme.palette.secondaryBlue50,
                '&:hover': {
                  color: isOpen ? 'white' : caloTheme.palette.secondaryBlue900,
                  backgroundColor: isOpen ? caloTheme.palette.secondaryBlue400 : caloTheme.palette.secondaryBlue100
                },
                [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                  display: 'flex',
                  justifyItems: 'center',
                  m: 'auto',
                  mb: 2,
                  mt: '-4px',
                  width: 'auto'
                }
              }}
              onClick={() => setIsOpen(!isOpen)}
            >
              {isOpen ? 'Hide Note' : 'View Note'}
            </Button>
          ) : (
            '---'
          )}
        </StyledTableCell>
        <StyledTableCell>
          {row.deliveryId ? (
            <Typography
              variant="h6"
              aria-label="delivery"
              sx={{
                width: 'full',
                fontWeight: 600,
                fontSize: '13px',
                cursor: 'pointer',
                textAlign: 'center',
                lineHeight: '19px',
                borderRadius: '8px',
                padding: 1,
                fontFamily: caloTheme.typography.fontFamily,
                color: caloTheme.palette.secondaryPurple500,
                '&:hover': {
                  color: caloTheme.palette.secondaryPurple500,
                  backgroundColor: caloTheme.palette.secondaryPurple100
                },
                [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                  display: 'flex',
                  justifyItems: 'center',
                  m: 'auto',
                  mb: 2,
                  mt: '-4px',
                  width: 'auto'
                }
              }}
              onClick={() => history.push(Routes.delivery.replace(':id', row.deliveryId!))}
            >
              View Delivery
            </Typography>
          ) : (
            '---'
          )}
        </StyledTableCell>
        <StyledTableCell>{format('Pp')(parseISO(row.createdAt))}</StyledTableCell>
        <StyledTableCell>
          {row.action === WalletAction.ADD &&
            (row.source === PaymentSource.online || (row.paymentProvider && row.paymentProvider !== 'CALO')) &&
            roles.includes(Permission.CREATE_REFUND) && (
              <Button
                variant="text"
                aria-label="Refund"
                sx={{
                  width: 'auto',
                  height: '45px',
                  fontWeight: 600,
                  lineHeight: '17px',
                  fontSize: '14px',
                  borderRadius: '8px',
                  padding: '14px 20px 14px 20px',
                  color: caloTheme.palette.primary500,
                  '&:hover': {
                    color: caloTheme.palette.primary500
                  },
                  [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                    display: 'flex',
                    justifyItems: 'center',
                    m: 'auto',
                    mb: 2,
                    mt: '-4px',
                    width: 'auto'
                  }
                }}
                onClick={() => onRefund(row)}
              >
                Refund
              </Button>
            )}
        </StyledTableCell>
      </TableRow>
      {isOpen && (
        <TableRow>
          <StyledTableCell
            colSpan={10}
            sx={{
              familyFont: caloTheme.typography.fontFamily,
              fontWeight: 400,
              fontSize: '12px',
              lineHeight: '14px',
              whiteSpace: 'pre-line',
              color: caloTheme.palette.neutral900,
              backgroundColor: caloTheme.palette.secondaryBlue50
            }}
          >
            {row.notes}
          </StyledTableCell>
        </TableRow>
      )}
    </>
  );
};
export default WalletCardRow;
