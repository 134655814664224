import { PickersDay, PickersDayProps } from '@mui/x-date-pickers-v6';
import { caloThemeV2 } from 'assets/themev2';
import dayjs from 'dayjs';
const CustomDay = (props: PickersDayProps<dayjs.Dayjs>) => {
  const { day, selected, disabled, ...other } = props;

  return (
    <PickersDay
      {...other}
      day={day}
      selected={selected}
      disabled={disabled}
      sx={{
        ...(disabled
          ? {}
          : {
              fontSize: '14px',
              '&.Mui-selected': {
                backgroundColor: caloThemeV2.palette.primary.main,
                color: caloThemeV2.palette.primary.contrastText,
                '&:hover': {
                  backgroundColor: caloThemeV2.palette.primary.main
                },
                '&.Mui-focusVisible': {
                  backgroundColor: caloThemeV2.palette.primary.main
                }
              }
            })
      }}
    />
  );
};

export default CustomDay;
