import { Stack } from '@mui/material';
import { checkCountryCurrency } from 'lib/helpers';
import { CateringOrder } from 'lib/interfaces';
import { round, sum } from 'lodash-es';
import OrderInformationCard from '../OrderInformationCard';

export interface OrderInformationProps {
  cateringOrder: CateringOrder;
}

export default function OrderInformation({ cateringOrder }: OrderInformationProps) {
  return (
    <Stack direction={{ md: 'row', xs: 'column' }} justifyContent={'space-between'} gap={2}>
      <OrderInformationCard name={'Order Ref'} value={`CI-${cateringOrder.referenceId}`} />
      <OrderInformationCard
        name={'Quantity'}
        value={sum(cateringOrder.orders.flatMap(({ items }) => items.map(({ count }) => count)))}
      />
      <OrderInformationCard
        name={'Price'}
        value={`${round(cateringOrder.cost, 2)} ${checkCountryCurrency(cateringOrder.deliveryAddress.country)}`}
      />
      <OrderInformationCard name={'Payment method'} value={cateringOrder.paymentMethod} />
    </Stack>
  );
}
