import { useMemo, useState } from 'react';

import { caloTheme } from 'assets/images/theme/calo';
import { MacrosOffset } from 'lib/enums';
import { getComponentWeight, handleSearch } from 'lib/helpers';
import { FoodComponent } from 'lib/interfaces';
import { round, sortBy, sumBy } from 'lodash-es';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useParams } from 'react-router';
import { ActionMeta } from 'react-select';

import { LinkedComponent } from '@calo/dashboard-types';
import { ComponentService } from '@calo/services';
import { CustomMealCategory, Dictionary, Nutrition } from '@calo/types';
import {
  Autocomplete,
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField
} from '@mui/material-v6';
import FoodComponentRow from './FoodComponentRow';
import { styles } from './styles';
import InputThemeProvider from '../InputThemeProvider';

interface FoodComponentPickerProps {
  name: string;
  isChild: boolean;
  isLoading: boolean;
  isDisabled: boolean;
  searchComponentList: FoodComponent[];
  selectedComponents: LinkedComponent[];
  allComponents: Dictionary<FoodComponent>;
  setName: (value: string) => void;
  onChange: (rows: LinkedComponent[]) => void;
  setFieldValue: (field: string, value: any) => void;
  isPreBuildCustom?: boolean;
}

const FoodComponentPickerMUI = (props: FoodComponentPickerProps) => {
  const {
    isLoading,
    isDisabled,
    isChild,
    selectedComponents,
    allComponents,
    searchComponentList,
    setFieldValue,
    setName,
    onChange,
    isPreBuildCustom
  } = props;

  const { id } = useParams<{ id: string }>();
  const [totalCost, setTotalCost] = useState(0);
  const [totalCostPerUnit, setTotalCostPerUnit] = useState(0);

  const checkExistence = (id: string) => selectedComponents.find((existcomponent) => existcomponent.id === id);
  const components = searchComponentList.filter((fc) => !checkExistence(fc.id));
  const filteredChild = isChild
    ? components.filter((fc) => (!fc.childComponents || fc.childComponents.length === 0) && fc.id !== id)
    : components;
  const options = useMemo(
    () =>
      sortBy(filteredChild, (f) => `${f.name.en}`).map((food) => ({
        value: food.id,
        label: food.name.en
      })),
    [components]
  );

  const modifyFoodComponents = (data: any, { action }: ActionMeta<any>) => {
    let newList: LinkedComponent[] = [...selectedComponents];
    const existing = newList.map((row) => row.id);
    const newValues = data.value;
    if (action === 'select-option' && !existing.includes(data.value)) {
      // add
      const customQuantity = (allComponents[data.value]?.sections ?? []).find(
        (section) => section.category === CustomMealCategory.meal
      )?.quantity;
      const newComponent = searchComponentList.find((comp) => comp.id === data.value);
      newList.push({
        ...newComponent,
        id: data.value,
        quantity: isPreBuildCustom ? (customQuantity ?? 1) : 1
      });
    } else {
      // remove
      newList = newList.filter((row) => newValues.includes(row.id));
    }
    onChange(newList);
  };

  const handleDragEnd = (e: any) => {
    if (!e.destination) return;
    const tempData = [...selectedComponents];
    const [sourceData] = tempData.splice(e.source.index, 1);
    tempData.splice(e.destination.index, 0, sourceData);

    const orderedComponents = tempData.map((component, index) => ({
      ...component,
      order: index
    }));

    setFieldValue('components', orderedComponents);
  };

  const handleSumMacrosData = (allComponents: Dictionary<FoodComponent>, macrosType: Nutrition) => {
    return round(
      sumBy(selectedComponents, (fc) => {
        const component = allComponents[fc.id];
        if (component) {
          const componentWeight = ComponentService.calculateComponentWeight(
            component.cups,
            component.measurementUnit,
            component.weight ?? 1,
            fc.quantity,
            6
          );
          return (component?.macros[macrosType] || 0) * componentWeight;
        }
        return 0;
      }),
      6
    );
  };

  const calculateMacrosPercentage = (totalWeight: number, totalCalories: number, offset: number) => {
    return round(((totalWeight * offset) / totalCalories) * 100, 1);
  };

  const totalCalories = handleSumMacrosData(allComponents, 'cal' as any);
  const totalProteinWeight = handleSumMacrosData(allComponents, Nutrition.protein);
  const totalCarbsWeight = handleSumMacrosData(allComponents, Nutrition.carbs);
  const totalFatWeight = handleSumMacrosData(allComponents, Nutrition.fat);

  const proteinPercentage = calculateMacrosPercentage(totalProteinWeight, totalCalories, MacrosOffset.protein);
  const carbsPercentage = calculateMacrosPercentage(totalCarbsWeight, totalCalories, MacrosOffset.carbs);
  const fatPercentage = calculateMacrosPercentage(totalFatWeight, totalCalories, MacrosOffset.fat);

  const getTotalWeight = () => {
    return sumBy(selectedComponents, (component) => getComponentWeight(allComponents, component));
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      {selectedComponents && selectedComponents.length > 0 ? (
        <Paper sx={styles.tableContainer}>
          <TableContainer>
            <Table sx={{ width: '100%' }}>
              <TableHead sx={styles.tableHead}>
                <TableRow>
                  <TableCell sx={{ minWidth: '52px' }}></TableCell>
                  <TableCell sx={{ minWidth: '176px' }}>Component Name</TableCell>
                  {isPreBuildCustom && <TableCell sx={{ minWidth: '128px' }}>Times Portioned</TableCell>}
                  <TableCell sx={{ minWidth: '128px' }}>Quantity</TableCell>
                  <TableCell sx={{ minWidth: '103px' }}>Unit</TableCell>
                  <TableCell sx={{ minWidth: '103px' }}>Weight</TableCell>
                  <TableCell sx={{ minWidth: '103px' }}>Calories</TableCell>
                  <TableCell sx={{ minWidth: '103px' }}>Proteins</TableCell>
                  <TableCell sx={{ minWidth: '103px' }}>Carbs</TableCell>
                  <TableCell sx={{ minWidth: '103px' }}>Fats</TableCell>
                  <TableCell sx={{ minWidth: '103px' }}>Fibers</TableCell>
                  <TableCell sx={{ minWidth: '115px' }}>Cost per Unit</TableCell>
                  <TableCell sx={{ minWidth: '115px' }}>Total Cost</TableCell>
                  <TableCell sx={{ minWidth: '103px' }}></TableCell>
                </TableRow>
              </TableHead>
              <Droppable droppableId="droppable">
                {(provider) => (
                  <TableBody ref={provider.innerRef} {...provider.droppableProps}>
                    {selectedComponents.map((row, index) => (
                      <Draggable key={row.id} draggableId={row.id} index={index} isDragDisabled={false}>
                        {(provider) => (
                          <TableRow
                            key={row.id}
                            {...provider.draggableProps}
                            ref={provider.innerRef}
                            {...provider.dragHandleProps}
                          >
                            <FoodComponentRow
                              isDisabled={isDisabled}
                              childComp={isChild}
                              selectedComponents={selectedComponents as any}
                              onChange={onChange}
                              allComponents={allComponents}
                              component={row as any}
                              isPreBuildCustom={isPreBuildCustom}
                              setTotalCost={setTotalCost}
                              setTotalCostPerUnit={setTotalCostPerUnit}
                              index={index}
                            />
                          </TableRow>
                        )}
                      </Draggable>
                    ))}
                  </TableBody>
                )}
              </Droppable>
              <TableFooter>
                <TableRow>
                  <TableCell
                    colSpan={isPreBuildCustom ? 5 : 4}
                    sx={{ borderBottom: 0, pb: 1, borderTop: '1px solid ' + caloTheme.palette.neutral100 }}
                  >
                    <InputThemeProvider>
                      <Autocomplete
                        id="componentInputSelector"
                        options={options}
                        disabled={isDisabled}
                        loading={isLoading}
                        onChange={(event, value) => {
                          if (value) {
                            modifyFoodComponents(value, { action: 'select-option' } as ActionMeta<any>);
                          }
                        }}
                        onInputChange={(event, value) => {
                          handleSearch({ text: value, action: 'input-change', name: setName });
                        }}
                        getOptionLabel={(option) => option.label}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Select Component"
                            variant="outlined"
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: <>{params.InputProps.endAdornment}</>
                            }}
                            data-test="selectComponentSelector"
                          />
                        )}
                      />
                    </InputThemeProvider>
                  </TableCell>
                  <TableCell
                    sx={{
                      borderBottom: 0,
                      pb: 1,
                      fontSize: '16px',
                      borderTop: '1px solid ' + caloTheme.palette.neutral100,
                      color: caloTheme.palette.black
                    }}
                  >
                    {getTotalWeight()}
                  </TableCell>
                  <TableCell
                    sx={{
                      borderBottom: 0,
                      pb: 1,
                      fontSize: '16px',
                      borderTop: '1px solid ' + caloTheme.palette.neutral100,
                      color: caloTheme.palette.black
                    }}
                  >
                    {totalCalories}
                  </TableCell>
                  <TableCell
                    sx={{
                      borderBottom: 0,
                      pb: 1,
                      fontSize: '16px',
                      borderTop: '1px solid ' + caloTheme.palette.neutral100,
                      color: caloTheme.palette.black
                    }}
                  >
                    {totalProteinWeight}
                    {!isChild && Boolean(proteinPercentage) && `(${proteinPercentage}%)`}
                  </TableCell>
                  <TableCell
                    sx={{
                      borderBottom: 0,
                      pb: 1,
                      fontSize: '16px',
                      borderTop: '1px solid ' + caloTheme.palette.neutral100,
                      color: caloTheme.palette.black
                    }}
                  >
                    {totalCarbsWeight}
                    {!isChild && Boolean(carbsPercentage) && `(${carbsPercentage}%)`}
                  </TableCell>
                  <TableCell
                    sx={{
                      borderBottom: 0,
                      pb: 1,
                      fontSize: '16px',
                      borderTop: '1px solid ' + caloTheme.palette.neutral100,
                      color: caloTheme.palette.black
                    }}
                  >
                    {totalFatWeight}
                    {!isChild && Boolean(fatPercentage) && `(${fatPercentage}%)`}
                  </TableCell>
                  <TableCell
                    sx={{
                      borderBottom: 0,
                      pb: 1,
                      fontSize: '16px',
                      borderTop: '1px solid ' + caloTheme.palette.neutral100,
                      color: caloTheme.palette.black
                    }}
                  >
                    {handleSumMacrosData(allComponents, Nutrition.fiber)}
                  </TableCell>
                  <TableCell
                    sx={{
                      borderBottom: 0,
                      pb: 1,
                      fontSize: '16px',
                      borderTop: '1px solid ' + caloTheme.palette.neutral100,
                      color: caloTheme.palette.black
                    }}
                  >
                    {round(totalCostPerUnit, 6)}
                  </TableCell>
                  <TableCell
                    sx={{
                      borderBottom: 0,
                      pb: 1,
                      fontSize: '16px',
                      borderTop: '1px solid ' + caloTheme.palette.neutral100,
                      color: caloTheme.palette.black
                    }}
                  >
                    {round(totalCost, 6)}
                  </TableCell>
                  <TableCell
                    sx={{
                      borderBottom: 0,
                      pb: 1,
                      fontSize: '16px',
                      borderTop: '1px solid ' + caloTheme.palette.neutral100,
                      color: caloTheme.palette.black
                    }}
                  ></TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Paper>
      ) : (
        <Box padding={'24px 16px 4px 16px'}>
          <InputThemeProvider>
            <Autocomplete
              id="componentInputSelector2"
              options={options}
              disabled={isDisabled}
              loading={isLoading}
              onChange={(event, value) => {
                if (value) {
                  modifyFoodComponents(value, { action: 'select-option' } as ActionMeta<any>);
                }
              }}
              onInputChange={(event, value) => {
                handleSearch({ text: value, action: 'input-change', name: setName });
              }}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Component"
                  variant="outlined"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: <>{params.InputProps.endAdornment}</>
                  }}
                  data-test="selectComponentSelector2"
                />
              )}
            />
          </InputThemeProvider>
        </Box>
      )}
    </DragDropContext>
  );
};

export default FoodComponentPickerMUI;
