import { FormikErrors } from 'formik';

import { UpdateFoodReq } from '@calo/dashboard-types';
import { Box, Divider, Stack, Typography } from '@mui/material';

import { FoodDietType } from '@calo/types';
import { caloTheme } from 'assets/images/theme/calo';
import FoodInformationForm from 'components/MUI/FoodInformationForm';
import { isWithinWeeks } from 'lib/helpers';
import { Food, UpdateFoodReqWithIndex } from 'lib/interfaces';
import { IOSSwitch } from '../MUI';

interface InformationProps {
  values: UpdateFoodReq;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  errors: FormikErrors<UpdateFoodReqWithIndex>;
  food: Food;
  isDisabled: boolean;
  allSizesFood: Food[];
}

const Information = ({ values, setFieldValue, errors, food, isDisabled, allSizesFood }: InformationProps) => {
  return (
    <Stack
      direction="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      sx={{ backgroundColor: caloTheme.palette.white, borderRadius: '8px', boxShadow: 2 }}
    >
      <Stack
        direction="row"
        width="100%"
        justifyContent="space-between"
        alignItems="center"
        sx={{ backgroundColor: caloTheme.palette.white, borderRadius: '16px', p: '16px' }}
      >
        <Typography
          sx={{
            fontSize: '24px',
            fontWeight: 400,
            lineHeight: '150%'
          }}
        >
          Information
        </Typography>
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2} sx={{ paddingRight: '10px' }}>
          <Typography sx={{ color: caloTheme.palette.neutral700, fontSize: '12px', lineHeight: '14px' }}>
            Assembly Required
          </Typography>
          <Box>
            <IOSSwitch
              id="assemblyRequiredToggleButton"
              onChange={() => setFieldValue('assemblyRequired', !values.assemblyRequired)}
              sx={{ color: 'white' }}
              checked={values.assemblyRequired}
            />
          </Box>
        </Stack>
      </Stack>
      <Divider sx={{ color: caloTheme.palette.divider, height: '1px', width: '100%' }} />
      <Box sx={{ padding: '24px 16px 16px 16px', width: '100%' }}>
        <FoodInformationForm
          errors={errors}
          values={values}
          formType="update"
          brand={food.brand}
          isNewFood={food.createdAt ? isWithinWeeks(new Date(food.createdAt), 4) : false}
          isDisabled={isDisabled}
          allSizesFood={allSizesFood}
          setFieldValue={setFieldValue}
          isPreBuildCustom={Boolean(values.tags?.includes(FoodDietType.preBuiltCustom))}
        />
      </Box>
    </Stack>
  );
};

export default Information;
