import { useFormik } from 'formik';
import { isNumber } from 'lodash';

import { CreateFoodReq, FoodStatus } from '@calo/dashboard-types';
import { Brand, Country, Currency, DietType, FoodDietType, FoodType, HeatingInstruction, Kitchen } from '@calo/types';
import { requiredFoodTagKeys } from 'lib/constants';

export default (onSubmit: (values: Omit<CreateFoodReq, 'id'>) => any, isPreBuildCustom: boolean) =>
  useFormik<Omit<CreateFoodReq, 'id'>>({
    initialValues: {
      name: {
        en: '',
        ar: ''
      },
      description: {
        en: '',
        ar: ''
      },
      longDescription: {
        en: '',
        ar: ''
      },
      macros: {
        cal: 0,
        carbs: 0,
        fat: 0,
        fiber: 0,
        protein: 0
      },
      micronutrients: {
        addedSugar: 0,
        cholesterol: 0,
        saturatedFats: 0,
        sodium: 0,
        totalSugar: 0,
        transFats: 0
      },
      country: Country.BH,
      brand: Brand.CALO,
      size: 'M',
      heatingInstruction: HeatingInstruction.NONE,
      kitchen: Kitchen.BH1,
      type: isPreBuildCustom ? [FoodType.lunch] : [FoodType.breakfast],
      tags: isPreBuildCustom ? [FoodDietType.preBuiltCustom] : [DietType.balanced],
      ingredients: [],
      components: [],
      currency: Currency.BHD,
      packagingIds: [],
      price: 0,
      isAddedByUser: false,
      status: FoodStatus.draft
    },
    validate: (values: Omit<CreateFoodReq, 'id'>) => {
      const errors: any = {};
      if (values.status === FoodStatus.approved) {
        if (
          !values.name.ar ||
          (values.name?.ar && values.name?.ar.length < 3) ||
          (values.name?.ar && values.name?.ar.length > 50)
        ) {
          errors.name = {
            ...errors.name,
            ar: true
          };
        }
        if (values.type?.length === 0) {
          errors.type = true;
        }
      }

      if (values.tags?.length === 0 || (isPreBuildCustom && !values.tags?.includes(FoodDietType.preBuiltCustom))) {
        errors.tags = true;
      }
      if (
        !values.name.en ||
        (values.name?.en && values.name?.en.length < 3) ||
        (values.name?.en && values.name?.en.length > 50)
      ) {
        errors.name = {
          ...errors.name,
          en: true
        };
      }
      if (!isNumber(values.macros.cal)) {
        errors.macros = {
          ...errors.macros,
          cal: true
        };
      }
      if (!isNumber(values.macros.protein)) {
        errors.macros = {
          ...errors.macros,
          protein: true
        };
      }
      if (!isNumber(values.macros.carbs)) {
        errors.macros = {
          ...errors.macros,
          carbs: true
        };
      }
      if (!isNumber(values.macros.fat)) {
        errors.macros = {
          ...errors.macros,
          fat: true
        };
      }
      if (!values.description.en) {
        errors.description = {
          ...errors.description,
          en: true
        };
      }
      if (!values.size && values.brand !== Brand.MEALO) {
        errors.size = true;
      }
      if (!values.country) {
        errors.country = true;
      }

      if (!values.brand) {
        errors.brand = true;
      }

      if (!values.kitchen) {
        errors.kitchen = true;
      }
      if (values.components?.some((component) => component?.quantity === 0)) {
        errors.components = true;
      }

      if (!values.isCustom) {
        for (const key of requiredFoodTagKeys) {
          const tag = values.foodTags?.find((tag: any) => tag.key.toLowerCase() === key.toLowerCase());
          if (!tag || !tag.value || tag.value.length === 0) {
            errors.foodTags = errors.foodTags || {};
            errors.foodTags[key] = true;
          }
        }
      }

      return errors;
    },
    onSubmit: async (values) => {
      try {
        await onSubmit(values);
      } catch (error) {
        console.log(error);
      }
    }
  });
