import { RetailMenuFoodItem } from '@calo/dashboard-types';
import { Brand, RetailMenuItemTag } from '@calo/types';
import { getListWithParams, postRequest, selectedFoodData, updateRetailMenu } from 'actions/index';
import { FoodStatus, Routes } from 'lib/enums';
import { Food } from 'lib/interfaces';
import { sortBy } from 'lodash-es';
import { useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useHistory, useLocation } from 'react-router';
import { getCountryFromBranch, getKitchenFromBranch } from '../retailHelpers';

enum extraRetailTag {
  SOUP = 'SOUP'
}
const brand = Brand.CALO;

const retailTagsOptions = [
  ...Object.values(RetailMenuItemTag).map((item) => ({ label: item, value: item })),
  ...Object.values(extraRetailTag).map((item) => ({ label: item, value: item }))
];

export const useExactRetailMenu = () => {
  const [currentRetailItem, setCurrentRetailItem] = useState<RetailMenuFoodItem>({
    id: '',
    priority: 0,
    foodModifiers: [],
    retailPrice: 0
  });
  const { data: currentFood, isLoading: isLoadingFood } = useQuery(
    ['currentComponentList', currentRetailItem?.id],
    () => selectedFoodData(currentRetailItem?.id),
    {
      enabled: !!currentRetailItem?.id
    }
  );
  const [filterName, setFilterName] = useState<string>();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const filters = {
    brand: brand,
    branch: location.pathname.split('/')[1],
    ...JSON.parse(searchParams.get('filters') || '{}')
  };

  const existRetail = [];

  const { data: foodList, isLoading } = useQuery<any, Error, { data: Food[] }>(
    [
      'food/list',
      {
        filters: {
          name: filterName ? filterName : undefined,
          country: getCountryFromBranch(filters.branch),
          brand: brand,
          isDeleted: false,
          kitchen: getKitchenFromBranch(filters.branch),
          status: FoodStatus.approved
        },
        sort: {
          orderBy: 'name',
          orderMode: 'asc'
        },
        page: 0,
        limit: 20
      }
    ],
    postRequest,
    {
      suspense: false,
      enabled: !!filterName
    }
  );

  const options = useMemo(
    () =>
      sortBy(foodList?.data, (f) => `${f.name.en}-${f.size}`).map((food) => ({
        name: food.name.en,
        value: food.id,
        label: `${food.name.en} (${food.size})`
      })),
    [foodList?.data, existRetail]
  );

  const { data } = useQuery<any>(
    ['retail-menu', { branch: filters.branch, brand }],
    // @ts-ignore
    getListWithParams,
    {
      suspense: false
    }
  );

  const retailFoodList = useMemo(() => {
    if (data?.food) {
      return data?.food.map((f) => ({
        id: f.id,
        priority: +f.priority || 1,
        retailPrice: f.retail.retailPrice,
        retailTagList: f.retail.retailTagList,
        foodModifiers: f.retail.foodModifiers.map((item) => ({
          ...item,
          componentOptions: item.componentOptions.map((option) => ({
            id: option.id,
            quantity: option.quantity
          }))
        })),
        disabled: !!f.retail.disabled,
        swapAvailable: !!f.retail.swapAvailable,
        startDate: f.retail.startDate,
        endDate: f.retail.endDate
      }));
    }
    return [];
  }, [data]);
  const history = useHistory();

  const currentItem = useMemo(() => {
    return {
      ...currentRetailItem,
      foodModifiers: currentRetailItem.foodModifiers?.map((modifier) => ({
        ...modifier,
        componentOptions: modifier.componentOptions?.map((option) => ({
          id: option.id,
          quantity: option.quantity
        }))
      }))
    };
  }, [currentRetailItem]);
  const [isSaving, setIsSaving] = useState(false);

  const handleAddItem = async () => {
    try {
      setIsSaving(true);
      await updateRetailMenu({
        brand: brand,
        branch: filters.branch,
        // @ts-ignore
        menuId: data?.id || '',
        country: getCountryFromBranch(filters.branch),
        food: [...retailFoodList, currentItem]
      });
      goBack();
    } catch (error) {
      console.log(error);
    } finally {
      setIsSaving(false);
    }
  };

  const handleEditItem = async () => {
    const filteredMenu = retailFoodList?.filter((item) => item.id !== currentRetailItem.id) || [];
    try {
      setIsSaving(true);
      await updateRetailMenu({
        brand: brand,
        branch: filters.branch,
        menuId: data?.id || '',
        country: getCountryFromBranch(filters.branch),
        food: [...filteredMenu, currentItem]
      });
      goBack();
    } catch (error) {
      console.log(error);
    } finally {
      setIsSaving(false);
    }
  };

  const goBack = () => {
    history.push(Routes.retailMenu.replace(':brand', filters.brand).replace(':branch', filters.branch));
  };

  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    const pathSegments = location.pathname.split('/');
    const lastSegment = pathSegments[pathSegments.length - 1];

    if (lastSegment !== ':id') {
      const retailItem = data?.food.find((item) => item.id === lastSegment);
      setIsEdit(true);
      if (retailItem) {
        setCurrentRetailItem({
          id: retailItem.id,
          priority: +retailItem.priority || 1,
          retailPrice: retailItem.retail.retailPrice,
          retailTagList: retailItem.retail.retailTagList,
          foodModifiers: retailItem.retail.foodModifiers.map((item) => ({
            ...item,
            componentOptions: item.componentOptions.map((option) => ({
              id: option.id,
              quantity: option.quantity,
              name: option.name
            }))
          })),
          disabled: !!retailItem.retail.disabled,
          swapAvailable: !!retailItem.retail.swapAvailable,
          startDate: retailItem.retail.startDate,
          endDate: retailItem.retail.endDate
        });
      }
    }
  }, [data, location.pathname]);

  return {
    isEdit,
    currentRetailItem,
    setCurrentRetailItem,
    currentFood,
    isLoadingFood,
    filterName,
    setFilterName,
    options,
    isLoading,
    retailTagsOptions,
    handleAddItem,
    handleEditItem,
    isSaving
  };
};
