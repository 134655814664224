import { ComponentMethod } from 'components';
import { FormikErrors } from 'formik';
import { FormOperation } from 'lib/enums';
import { getComponentPermission } from 'lib/helpers/permissionUtils';
import { useUserRoles } from 'lib/hooks';
import { BaseOmit, FoodComponent } from 'lib/interfaces';
import { forwardRef } from 'react';
import { styles } from './styles';
import { Box, Button, Divider, Stack, Card, Typography } from '@mui/material-v6';
import AddIcon from '@mui/icons-material/Add';
import { FoodComponentMethodStep } from '@calo/dashboard-types';

interface ComponentMethodCardProps {
  prototype?: boolean;
  operation: FormOperation;
  values: Omit<FoodComponent, BaseOmit>;
  errors: FormikErrors<Omit<FoodComponent, BaseOmit>>;
  isDisabled?: boolean;
  isEdit: boolean;
  setIsEdit: React.Dispatch<React.SetStateAction<boolean>>;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<FormikErrors<Omit<FoodComponent, BaseOmit>>> | Promise<any>;
  setValues: (
    values: React.SetStateAction<Omit<FoodComponent, BaseOmit>>,
    shouldValidate?: boolean | undefined
  ) => Promise<FormikErrors<Omit<FoodComponent, BaseOmit>>> | Promise<any>;
}

const ComponentMethodCard = forwardRef<HTMLDivElement, ComponentMethodCardProps>(
  ({ operation, prototype, values, isDisabled, isEdit, setIsEdit, setValues, setFieldValue }: ComponentMethodCardProps, ref) => {
    const userRoles = useUserRoles();

    const permission = getComponentPermission(userRoles, operation, prototype);

    const handleChangeMethod = (data: FoodComponentMethodStep) => {
      const currentMethod = values.method || [];
      const newMethod = [
        ...currentMethod,
        {
          description: data.description,
          attachment: data.attachment,
          station: data.station
        }
      ];
      setValues({ ...values, method: newMethod });
      setIsEdit(true);
    };

    const handleEditMethod = () => {
      const tempData = [...(values.method ?? [])];
      setValues({
        ...values,
        method: tempData
      });
      setIsEdit(false);
    };

    const doesMethodExist = !!values?.method?.length;

    return (
      <Card variant="outlined" sx={styles.card} ref={ref}>
        <Stack sx={styles.headerContainer}>
          <Typography sx={styles.title}>Method</Typography>
          <Box sx={styles.buttonContainer}>
            <Button
              data-test="addStepButton"
              variant="text"
              color="primary"
              size="large"
              startIcon={<AddIcon />}
              disabled={!permission || !!isDisabled}
              onClick={() => handleChangeMethod({ description: '', attachment: '', station: '' })}
            >
              Add Step
            </Button>
            {isEdit && (
              <Button
                data-test="doneStepButton"
                variant="contained"
                color="primary"
                onClick={handleEditMethod}
                disabled={!permission || !!isDisabled}
                sx={{ color: 'white' }}
                size="medium"
              >
                Done
              </Button>
            )}
          </Box>
        </Stack>
        {doesMethodExist && <Divider sx={styles.divider} />}
        <ComponentMethod
          values={values}
          isEdit={isEdit}
          uploadImages={true}
          setValues={(v) => setValues(v)}
          setIsEdit={(value) => setIsEdit(value)}
          disabled={!permission || !!isDisabled}
          setFieldValue={(data: any) => setFieldValue('method', data)}
        />
      </Card>
    );
  }
);

export default ComponentMethodCard;
