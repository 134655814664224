import { createUserPermission, deleteUserPermissions, getList, updateUserPermissions } from 'actions';
import cx from 'classnames';
import { Button, Input, OptionsDropdown } from 'components';
import { GroupPermissions } from 'lib/permissions';
import { useEffect, useRef, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import PermissionsRow from './PermissionsRow';
import { UserGroup } from 'lib/interfaces';

const Permissions = () => {
  const [selectedRole, setSelectedRole] = useState<UserGroup | null>(null);
  const [optionOpen, setOptionOpen] = useState<string | null>();

  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { mutateAsync: deleteMutation } = useMutation(deleteUserPermissions);
  const { mutateAsync: updateMutation } = useMutation(updateUserPermissions);
  const { mutateAsync: createMutation } = useMutation(createUserPermission);

  const { data } = useQuery<any, Error, { data: any }>('user-permissions', getList, {
    suspense: true
  });
  const permissionsAndRoles = data?.data as UserGroup[];

  const AddColumn = async () => {
    await createMutation({
      groupName: 'NewGroup',
      permissions: []
    });
  };

  const handleCloseOption = () => {
    if (isOpen) {
      setIsOpen(!isOpen);
      setOptionOpen(undefined);
    }
  };

  const handleEdit = (k: UserGroup) => {
    setOptionOpen(undefined);
    setSelectedRole(k);
  };

  const handleSave = async () => {
    if (selectedRole && !permissionsAndRoles.includes(selectedRole)) {
      await updateMutation(selectedRole);
    }
    setSelectedRole(null);
  };

  const handleRemove = async (role: UserGroup) => {
    setOptionOpen(undefined);
    setIsDisabled(true);
    await deleteMutation(role.id, {
      onSuccess: () => {
        setIsDisabled(false);
      }
    });
  };

  useEffect(() => {
    if (!selectedRole) {
      setOptionOpen(null);
    }
  }, [selectedRole]);

  // console.log(selectedRole, 'selectedRole');

  const ref = useRef<HTMLTableElement>(null);

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (selectedRole && ref.current && !ref.current.contains(event.target) && !(event.target.id === 'scrollable')) {
        handleSave();
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, selectedRole]);

  // console.log({ ...data }, 'DATA');

  return (
    <>
      <section className="section is-title-bar" onClick={() => handleCloseOption()}>
        <div className="level">
          <div className="level-left">
            <div className="level-item">
              <ul>
                <li>Roles and Permissions</li>
              </ul>
            </div>
          </div>
          <div>
            <div className="level-right">
              <div className="level-item">
                <Button onClick={AddColumn} content="+ Add Role" className="mr-4 bg-blue-500 text-white w-28" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section onClick={() => handleCloseOption()}>
        <div className="card has-table has-table-container-upper-radius">
          <div className="card-content">
            <div className="table-container overflow-y-auto h-screen">
              <table className="table is-fullwidth is-sortable" ref={ref}>
                <thead className="sticky top-0 z-50">
                  <tr className="bg-white">
                    <th className="w-24 sticky left-0 z-50 bg-white"></th>
                    {(permissionsAndRoles && permissionsAndRoles).map((role, index) => (
                      <th key={index}>
                        <div className=" h-13 flex">
                          <p className="flex flex-row text-gray-300 text-sm uppercase">Name :</p>
                          <span key={index}>
                            <button
                              className={cx('focus:outline-none relative hover:text-gray-500 -mt-2 left-0 px-3')}
                              onClick={() => {
                                setIsOpen(!isOpen);
                                setOptionOpen(role.id);
                                selectedRole !== role && handleSave();
                              }}
                            >
                              <i className="fas fa-ellipsis-v" />
                            </button>
                          </span>
                        </div>
                        <div className="flex flex-row h-auto w-32 mr-8" key={`${index}`}>
                          {selectedRole?.id === role.id ? (
                            <Input
                              value={selectedRole.groupName}
                              type="string"
                              onChange={(e) => setSelectedRole({ ...selectedRole, groupName: e.target.value })}
                              // onBlur={handleSave}
                              maxLength={18}
                            />
                          ) : (
                            <div className="w-18 h-4  whitespace-nowrap " key={`${role.groupName}`}>
                              {' '}
                              <p>{role.groupName} </p>{' '}
                            </div>
                          )}
                        </div>
                        <span className="relative">
                          <OptionsDropdown
                            visable={optionOpen === role.id && isOpen}
                            className="bg-white absolute mt-3 w-28  rounded"
                            buttonsDetails={[
                              { text: 'Edit', method: () => handleEdit(role), disabled: false },
                              {
                                text: 'Remove',
                                method: () => handleRemove(role),
                                disabled: isDisabled || role.groupName === 'Admin'
                              }
                            ]}
                          />
                        </span>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {Object.entries(GroupPermissions).map((row) => (
                    <PermissionsRow
                      key={row[0]}
                      row={row}
                      allRoles={permissionsAndRoles}
                      selectedRole={selectedRole}
                      setSelectedRole={setSelectedRole}
                      handleSave={() => handleSave()}
                    />
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Permissions;
