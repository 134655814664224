import { Box, Button, Divider, MenuItem, TextField, Typography } from '@mui/material';
import { caloTheme } from 'assets/images/theme/calo';
import { retailMenuDrinkItems } from 'lib/constants';
import { useState } from 'react';
import { cancellationReasons } from 'views/Retail/constant';

interface CancelOrderDialogProps {
  order: any | null;
  onClose: () => void;
  handleCancelOrder: (data: { reason: string }) => void;
}

export const CancelOrderDialog = ({ order, onClose, handleCancelOrder }: CancelOrderDialogProps) => {
  const [reason, setReason] = useState<string>('');

  const handleReasonChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReason(event.target.value);
  };

  return (
    <Box>
      <Typography variant="h6" component="h2">
        Cancel order
      </Typography>
      <Typography variant="body2" color="textSecondary" mb={3}>
        Are you sure you want to cancel? This action can not be undone.
      </Typography>
      <Box mb={2} sx={{ backgroundColor: caloTheme.palette.grey[100], borderRadius: '8px', p: 2 }}>
        <Typography variant="body2" sx={{ fontWeight: 700 }} mb={1}>
          ORDER DETAILS
        </Typography>
        <Divider />
        <Box mt={2}>
          {order.items.map((item: any) => {
            const isDrinkCategory = item.category ? retailMenuDrinkItems[item.category] : true;
            const itemSize = isDrinkCategory && item.size ? `(${item.size})` : '';
            return (
              <Box key={item.id} mb={1}>
                <Typography variant="body2">
                  {item.quantity} x {item.name.en} {itemSize}
                </Typography>
                {item.notes && (
                  <Typography variant="body2" color="textSecondary">
                    {item.notes}
                  </Typography>
                )}
              </Box>
            );
          })}
        </Box>
      </Box>
      <TextField
        select
        fullWidth
        label="Reason for cancellation"
        value={reason}
        onChange={handleReasonChange}
        variant="outlined"
        size="small"
      >
        {Object.values(cancellationReasons).map((reason) => (
          <MenuItem key={reason} value={reason}>
            {reason}
          </MenuItem>
        ))}
      </TextField>
      <Box mt={3} display="flex" justifyContent="flex-end" gap={2}>
        <Button sx={{ color: caloTheme.palette.grey[600] }} onClick={onClose}>
          Go Back
        </Button>
        <Button variant="outlined" color="error" disabled={!reason} onClick={() => handleCancelOrder({ reason })}>
          Cancel Order
        </Button>
      </Box>
    </Box>
  );
};
