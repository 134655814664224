import { Brand } from '@calo/types';
import { Button, Stack, Typography } from '@mui/material-v6';

import StarSharp from '@mui/icons-material/StarSharp';
import { Divider, colors } from '@mui/material-v6';
import { caloTheme } from 'assets/images/theme/calo';
import { ModalRef } from '../Modal';
import MenuInfoTypes from './MenuInfoTypes';

export interface MenuMealInfoProps {
  brand: Brand;
  selectedMeal: any;
  setSelectedMeal: React.Dispatch<any>;
  handleCalculateLastTime: (food: any) => JSX.Element | undefined;
  handleFoodSizes: (foodName: string) => JSX.Element[];
  infoMealRef: React.MutableRefObject<ModalRef | undefined>;
}
const MenuMealInfo = ({
  selectedMeal,
  infoMealRef,
  // handleFoodSizes,
  setSelectedMeal,
  handleCalculateLastTime
}: MenuMealInfoProps) => (
  <Stack sx={{ width: '618px' }}>
    <Stack sx={{ flexDirection: 'row', gap: 2, alignItems: 'center' }}>
      <Typography
        variant="h6"
        sx={{
          fontFamily: caloTheme.typography.fontFamily,
          fontSize: '24px',
          lineHeight: '19px',
          textTransform: 'none',
          mt: '2px'
        }}
      >{`${selectedMeal?.name.en}`}</Typography>
      <Stack display={'flex'} flexDirection={'row'} justifyContent={'space-evenly'}>
        <Stack
          sx={{
            flexDirection: 'row',
            padding: '4px',
            px: 1,
            gap: 1,
            backgroundColor: colors.amber[50],
            borderRadius: 2,
            alignItems: 'center'
          }}
        >
          <StarSharp sx={{ width: '16px', color: colors.amber[500] }} />
          <Typography sx={{ ml: '6px' }}>
            {!!selectedMeal?.totalRating && !!selectedMeal?.numberOfRatings
              ? (selectedMeal?.totalRating / selectedMeal?.numberOfRatings).toFixed(2)
              : 0}{' '}
            ({selectedMeal?.numberOfRatings ? selectedMeal?.numberOfRatings : 0})
          </Typography>
        </Stack>
        {selectedMeal && selectedMeal?.lastUsedOnMenu && handleCalculateLastTime(selectedMeal)}
      </Stack>
    </Stack>

    <MenuInfoTypes selectedMeal={selectedMeal} />
    <Divider sx={{ marginX: '-24px', marginTop: 4 }} />
    <Button
      variant="outlined"
      data-test="menu-new-modal-confirm-button"
      sx={{
        width: '76px',
        alignSelf: 'flex-end',
        marginTop: 2
      }}
      onClick={() => {
        infoMealRef.current?.close();
        setSelectedMeal(undefined);
      }}
    >
      Close
    </Button>
  </Stack>
);
export default MenuMealInfo;
