import { caloTheme } from 'assets/images/theme/calo';

export const styles = {
  breadcrumbText: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '150%',
    color: caloTheme.palette.textSecondary
  },
  breadcrumbActiveText: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '150%',
    color: caloTheme.palette.textPrimary
  }
};
