import { useMemo } from 'react';

import { caloTheme } from 'assets/images/theme/calo';
import { handleSearch, isNumberInputValid } from 'lib/helpers';
import { Ingredient, IngredientHeaderItem } from 'lib/interfaces';
import { round, sortBy, sumBy } from 'lodash-es';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import { Dictionary, FoodComponentIngredientAction } from '@calo/types';
import {
  Autocomplete,
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField
} from '@mui/material-v6';

import { InputValueRestrictionType } from 'lib/enums';
import HeaderRow from './HeaderRow';
import IngredientRow from './IngredientRow';
import { styles } from './styles';
import InputThemeProvider from '../InputThemeProvider';

interface IngredientPickerProps {
  ingredients: Ingredient[];
  structuredIngredients: IngredientHeaderItem[];
  isDisabled: boolean;
  list: Ingredient[];
  ingredientName: string;
  newIngredient: Dictionary<any>;
  existingIngredient: Dictionary<Ingredient>;
  onChange: (rows: any[]) => void;
  setIngredientName: (value: string) => void;
  setFieldValue: (field: string, value: any) => void;
  setStructuredIngredients: React.Dispatch<React.SetStateAction<IngredientHeaderItem[]>>;
}

const IngredientPickerMUI = ({
  isDisabled,
  ingredients,
  structuredIngredients,
  newIngredient,
  list,
  setIngredientName,
  setStructuredIngredients,
  existingIngredient,
  setFieldValue
}: IngredientPickerProps) => {
  const updateIngredients = (updatedStructuredIngredients: IngredientHeaderItem[]) => {
    const updatedIngredients: Ingredient[] = [];
    let tempHeaderName: string | null | undefined = null;
    for (const structuredIngredient of updatedStructuredIngredients) {
      if (structuredIngredient.type === 'header') {
        tempHeaderName = structuredIngredient.header;
      } else {
        updatedIngredients.push({ ...structuredIngredient.ingredient, header: tempHeaderName ?? undefined });
      }
    }
    setFieldValue('ingredients', updatedIngredients);
  };

  const handleChange = (id: string, index: number, quantity: number) => {
    if (!isNumberInputValid(quantity, InputValueRestrictionType.quantity)) {
      return;
    }

    const updatedStructuredIngredients = [...structuredIngredients];
    const sIngredient = structuredIngredients.find(
      (r, rIndex) => r.type === 'ingredient' && r.ingredient.id === id && index === rIndex
    );

    if (sIngredient?.type === 'ingredient') {
      sIngredient.ingredient = { ...sIngredient.ingredient, quantity };
    }
    updateIngredients(updatedStructuredIngredients);
    setStructuredIngredients(updatedStructuredIngredients);
  };

  const handleActionChange = (id: string, index: number, action: FoodComponentIngredientAction) => {
    const updatedStructuredIngredients = [...structuredIngredients];
    const sIngredient = structuredIngredients.find(
      (r, rIndex) => r.type === 'ingredient' && r.ingredient.id === id && index === rIndex
    );

    if (sIngredient?.type === 'ingredient') {
      sIngredient.ingredient = { ...sIngredient.ingredient, action };
    }
    updateIngredients(updatedStructuredIngredients);
    setStructuredIngredients(updatedStructuredIngredients);
  };

  const modifyIngredient = (data: any) => {
    const updatedStructuredIngredients: IngredientHeaderItem[] = [
      ...structuredIngredients,
      { type: 'ingredient', ingredient: { ...newIngredient[data.value], quantity: 1 } }
    ];

    updateIngredients(updatedStructuredIngredients);
    setStructuredIngredients(updatedStructuredIngredients);
  };

  const filteredList = list.filter((l) => l.macros);

  const options = useMemo(
    () =>
      sortBy(filteredList, (f) => `${f.name.en}`).map((fk) => ({
        value: fk.id,
        label: `${fk.name.en} (${fk.internalName || ''})`,
        name: fk.name.en,
        category: fk.category
      })),
    [list, ingredients, existingIngredient]
  );

  const handleRemoveIngredient = (index: number, removedId: string) => {
    console.log(removedId);
    const updatedStructuredIngredients = [...structuredIngredients];

    updatedStructuredIngredients.splice(index, 1);
    updateIngredients(updatedStructuredIngredients);
    setStructuredIngredients(updatedStructuredIngredients);
  };

  const handleDragEnd = (e: any) => {
    if (!e.destination) {
      return;
    }

    const updatedStructuredIngredients = [...structuredIngredients];
    const [sourceData] = updatedStructuredIngredients.splice(e.source.index, 1);
    updatedStructuredIngredients.splice(e.destination.index, 0, sourceData);
    updateIngredients(updatedStructuredIngredients);
    setStructuredIngredients(updatedStructuredIngredients);
  };

  const saveHeaderHandler = (index: number, newName?: string) => {
    const updatedStructuredIngredients = [...structuredIngredients];

    const sIngredient = updatedStructuredIngredients.find((sIng, i) => sIng.type === 'header' && index === i);

    if (sIngredient && sIngredient.type === 'header') {
      sIngredient.header = newName;
    }

    updateIngredients(updatedStructuredIngredients);
    setStructuredIngredients(updatedStructuredIngredients);
  };

  const deleteHeaderHandler = (header?: string) => {
    const updatedStructuredIngredients: IngredientHeaderItem[] = structuredIngredients.filter(
      (sIngredient) => sIngredient.type === 'ingredient' || (sIngredient.type === 'header' && sIngredient.header !== header)
    );

    updateIngredients(updatedStructuredIngredients);
    setStructuredIngredients(updatedStructuredIngredients);
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      {structuredIngredients && structuredIngredients.length > 0 ? (
        <Paper sx={styles.tableContainer}>
          <TableContainer>
            <Table sx={{ width: '100%' }}>
              <TableHead sx={styles.tableHead}>
                <TableRow>
                  <TableCell sx={{ minWidth: '52px' }}></TableCell>
                  <TableCell sx={{ minWidth: '176px' }}>Name</TableCell>
                  <TableCell sx={{ minWidth: '128px' }}>Quantity</TableCell>
                  <TableCell sx={{ minWidth: '103px' }}>Unit</TableCell>
                  <TableCell sx={{ minWidth: '103px' }}>Weight</TableCell>
                  <TableCell sx={{ minWidth: '103px' }}>Calories</TableCell>
                  <TableCell sx={{ minWidth: '103px' }}>Proteins</TableCell>
                  <TableCell sx={{ minWidth: '103px' }}>Carbs</TableCell>
                  <TableCell sx={{ minWidth: '103px' }}>Fats</TableCell>
                  <TableCell sx={{ minWidth: '103px' }}>Fibers</TableCell>
                  <TableCell sx={{ minWidth: '115px' }}>Cost per Unit</TableCell>
                  <TableCell sx={{ minWidth: '115px' }}>Total Cost</TableCell>
                  <TableCell sx={{ minWidth: '103px' }}></TableCell>
                </TableRow>
              </TableHead>
              <Droppable droppableId="droppable">
                {(provider) => (
                  <TableBody ref={provider.innerRef} {...provider.droppableProps}>
                    {structuredIngredients.map((row, index) => (
                      <Draggable
                        key={row.type === 'header' ? row.header : `row.ingredient.id-${index}`}
                        draggableId={row.type === 'header' ? `header-${row.header}` : `ingredient-${row.ingredient.id}-${index}`}
                        index={index}
                        isDragDisabled={isDisabled}
                      >
                        {(provider) => (
                          <TableRow
                            key={`${row.type === 'header' ? row.header : row.ingredient.id}-${index}`}
                            {...provider.draggableProps}
                            ref={provider.innerRef}
                            {...provider.dragHandleProps}
                          >
                            {row.type === 'ingredient' ? (
                              <IngredientRow
                                index={index}
                                isDisabled={isDisabled}
                                row={row.ingredient}
                                handleActionChange={handleActionChange}
                                handleChange={handleChange}
                                handleRemoveIngredient={handleRemoveIngredient}
                              />
                            ) : (
                              <HeaderRow
                                isDisabled={isDisabled}
                                row={row}
                                index={index}
                                saveHeaderHandler={saveHeaderHandler}
                                deleteHeaderHandler={deleteHeaderHandler}
                              />
                            )}
                          </TableRow>
                        )}
                      </Draggable>
                    ))}
                  </TableBody>
                )}
              </Droppable>
              <TableFooter>
                <TableRow>
                  <TableCell colSpan={3} sx={{ borderBottom: 0, pb: 1, borderTop: '1px solid ' + caloTheme.palette.neutral100 }}>
                    <InputThemeProvider>
                      <Autocomplete
                        options={options}
                        disabled={isDisabled}
                        loading={isDisabled}
                        onChange={(event, value) => {
                          if (value) {
                            modifyIngredient(value);
                          }
                        }}
                        onInputChange={(event, value) => {
                          handleSearch({ text: value, action: 'input-change', name: setIngredientName });
                        }}
                        getOptionLabel={(option) => option.label}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Select Ingredient"
                            variant="outlined"
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: <>{params.InputProps.endAdornment}</>
                            }}
                            data-test="selectIngredientSelector"
                          />
                        )}
                      />
                    </InputThemeProvider>
                  </TableCell>
                  <TableCell
                    sx={{
                      borderBottom: 0,
                      pb: 1,
                      fontSize: '16px',
                      borderTop: '1px solid' + caloTheme.palette.neutral100,
                      color: caloTheme.palette.black
                    }}
                  >
                    {round(
                      sumBy(ingredients, (fk) => (fk.weight || 1) * (fk.quantity || 0)),
                      6
                    )}
                  </TableCell>
                  <TableCell
                    sx={{
                      borderBottom: 0,
                      pb: 1,
                      fontSize: '16px',
                      borderTop: '1px solid' + caloTheme.palette.neutral100,
                      color: caloTheme.palette.black
                    }}
                  >
                    {round(
                      sumBy(ingredients, (fk) =>
                        existingIngredient[fk.id]?.macros?.cal && fk.quantity
                          ? existingIngredient[fk.id].macros!.cal * fk.quantity
                          : 0
                      ),
                      6
                    )}
                  </TableCell>
                  <TableCell
                    sx={{
                      borderBottom: 0,
                      pb: 1,
                      fontSize: '16px',
                      borderTop: '1px solid' + caloTheme.palette.neutral100,
                      color: caloTheme.palette.black
                    }}
                  >
                    {round(
                      sumBy(ingredients, (fk) =>
                        existingIngredient[fk.id]?.macros?.protein && fk.quantity
                          ? existingIngredient[fk.id]?.macros!.protein * fk.quantity
                          : 0
                      ),
                      6
                    )}
                  </TableCell>
                  <TableCell
                    sx={{
                      borderBottom: 0,
                      pb: 1,
                      fontSize: '16px',
                      borderTop: '1px solid' + caloTheme.palette.neutral100,
                      color: caloTheme.palette.black
                    }}
                  >
                    {round(
                      sumBy(ingredients, (fk) =>
                        existingIngredient[fk.id]?.macros?.carbs && fk.quantity
                          ? existingIngredient[fk.id]?.macros!.carbs * fk.quantity
                          : 0
                      ),
                      6
                    )}
                  </TableCell>
                  <TableCell
                    sx={{
                      borderBottom: 0,
                      pb: 1,
                      fontSize: '16px',
                      borderTop: '1px solid' + caloTheme.palette.neutral100,
                      color: caloTheme.palette.black
                    }}
                  >
                    {round(
                      sumBy(ingredients, (fk) =>
                        existingIngredient[fk.id]?.macros?.fat && fk.quantity
                          ? existingIngredient[fk.id]!.macros!.fat * fk.quantity
                          : 0
                      ),
                      6
                    )}
                  </TableCell>
                  <TableCell
                    sx={{
                      borderBottom: 0,
                      pb: 1,
                      fontSize: '16px',
                      borderTop: '1px solid' + caloTheme.palette.neutral100,
                      color: caloTheme.palette.black
                    }}
                  >
                    {round(
                      sumBy(ingredients, (fk) =>
                        existingIngredient[fk.id]?.macros?.fiber && fk.quantity
                          ? existingIngredient[fk.id]!.macros!.fiber || 0 * fk.quantity
                          : 0
                      ),
                      6
                    )}
                  </TableCell>
                  <TableCell
                    sx={{
                      borderBottom: 0,
                      pb: 1,
                      fontSize: '16px',
                      borderTop: '1px solid' + caloTheme.palette.neutral100,
                      color: caloTheme.palette.black
                    }}
                  >
                    {round(
                      sumBy(ingredients, (fk) => existingIngredient[fk.id]?.cost || 0),
                      6
                    )}
                  </TableCell>
                  <TableCell
                    sx={{
                      borderBottom: 0,
                      pb: 1,
                      fontSize: '16px',
                      borderTop: '1px solid' + caloTheme.palette.neutral100,
                      color: caloTheme.palette.black
                    }}
                  >
                    {round(
                      sumBy(ingredients, (fk) =>
                        existingIngredient[fk.id]
                          ? (existingIngredient[fk.id]!.cost || 0) *
                            (fk.quantity || 0) *
                            (existingIngredient[fk.id]?.wastage || 0)
                          : 0
                      ),
                      6
                    )}
                  </TableCell>
                  <TableCell
                    sx={{
                      borderBottom: 0,
                      pb: 1,
                      borderTop: '1px solid ' + caloTheme.palette.neutral100,
                      color: caloTheme.palette.black
                    }}
                  ></TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Paper>
      ) : (
        <Box padding={'24px 16px 4px 16px'}>
          <InputThemeProvider>
            <Autocomplete
              options={options}
              disabled={isDisabled}
              loading={isDisabled}
              onChange={(event, value) => {
                if (value) {
                  modifyIngredient(value);
                }
              }}
              onInputChange={(event, value) => {
                handleSearch({ text: value, action: 'input-change', name: setIngredientName });
              }}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Ingredient"
                  variant="outlined"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: <>{params.InputProps.endAdornment}</>
                  }}
                  data-test="selectIngredientSelector2"
                />
              )}
            />
          </InputThemeProvider>
        </Box>
      )}
    </DragDropContext>
  );
};

export default IngredientPickerMUI;
