import LoadingButton from '@mui/lab/LoadingButton';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Skeleton,
  Button,
  List,
  ListItem,
  ListItemText,
  Typography
} from '@mui/material-v6';
import { Routes } from 'lib/enums';
import { Link as RouterLink } from 'react-router-dom';
import { styles } from './styles';

type DeleteConfirmationPopupProps = {
  type: string;
  isDeleteConfirmationOpen: boolean;
  typeUsage: any[];
  isLoading: boolean;
  onClose: () => void;
  onDelete: () => void;
  isActionButtonLoading?: boolean;
};

const DeleteConfirmationPopup = ({
  type,
  typeUsage,
  isLoading,
  onClose,
  onDelete,
  isDeleteConfirmationOpen,
  isActionButtonLoading
}: DeleteConfirmationPopupProps) => {
  const routeLink = type === 'ingredient' ? Routes.foodComponent : Routes.food;
  const hasItems = !!typeUsage?.length;

  const renderDialongContent = () => {
    if (isLoading) {
      return (
        <List>
          {Array.from({ length: 3 }).map((_, index) => (
            <ListItem key={index} sx={styles.componentListItem}>
              <Skeleton width="80%" />
              <Skeleton width="60%" />
            </ListItem>
          ))}
        </List>
      );
    }
    if (hasItems) {
      return (
        <List>
          {typeUsage.map((typeUsageItem) => (
            <ListItem key={typeUsageItem.id}>
              <ListItemText
                primary={
                  <RouterLink
                    to={`${routeLink.replace(':id', typeUsageItem.id)}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: 'none', color: 'inherit' }}
                  >
                    {typeUsageItem.name}
                  </RouterLink>
                }
              />
            </ListItem>
          ))}
        </List>
      );
    }
    return (
      <Typography variant="body1">{`Are you sure you want to delete this ${type}? This action cannot be undone.`}</Typography>
    );
  };

  return (
    <Dialog sx={styles.dialogContainer} open={isDeleteConfirmationOpen} onClose={onClose}>
      <DialogTitle sx={styles.title}>
        {isLoading ? <Skeleton width="60%" /> : hasItems ? `Cannot delete ${type} until it is removed from` : 'Confirm deletion'}
      </DialogTitle>
      <DialogContent sx={styles.dialogContentContainer(hasItems)}>{renderDialongContent()}</DialogContent>
      <DialogActions>
        {hasItems ? (
          <Button variant="text" color="primary" size="medium" onClick={onClose} disabled={isLoading}>
            Okay
          </Button>
        ) : (
          <>
            <LoadingButton
              variant="contained"
              color="error"
              size="large"
              data-test="confirm_delete_meal_size"
              onClick={onDelete}
              disabled={isLoading}
              loading={isActionButtonLoading}
            >
              Delete
            </LoadingButton>
            <Button
              variant="text"
              color="inherit"
              size="medium"
              onClick={onClose}
              disabled={isLoading}
              data-test="cancel_delete_ingredient"
            >
              Close
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default DeleteConfirmationPopup;
