import client from 'lib/client';
import { ToastSuccessMsgs } from 'lib/helpers';

export const removeClubMembership = async ({ userId, reason }: { userId: string; reason: string }) => {
  const { status } = await client.post(`/loyalty/${userId}/cancel-club-membership`, { reason });
  ToastSuccessMsgs({
    status,
    action: 'Club membership removed successfully'
  });
};
