import { SearchOutlined } from '@mui/icons-material';
import { InputAdornment, TextField } from '@mui/material-v6';

interface SearchMealsFieldProps {
  value: string;
  width?: string;
  onSearch: (searchTerm: string) => void;
}

const SearchMealsField = ({ value, width = '264px', onSearch }: SearchMealsFieldProps) => {
  return (
    <TextField
      placeholder="Search Meals"
      variant="outlined"
      size="small"
      value={value}
      onChange={(data) => onSearch(data.target.value)}
      sx={{
        width: width
      }}
      slotProps={{
        input: {
          startAdornment: (
            <InputAdornment position="start">
              <SearchOutlined />
            </InputAdornment>
          )
        }
      }}
    />
  );
};

export default SearchMealsField;
