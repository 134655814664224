import { caloThemeV2 } from 'assets/themev2';

export const styles = {
  tableContainer: {
    width: '100%',
    boxShadow: 'none'
  },
  tableHead: {
    backgroundColor: caloThemeV2.palette.grey[100],
    border: 0
  },
  tableHeadText: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '24px'
  },
  componentImage: {
    width: 40,
    height: 40,
    objectFit: 'cover',
    borderRadius: '4px'
  }
};
