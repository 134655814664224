import { createTheme } from '@mui/material-v6/styles';

export const caloThemeFont = createTheme({
  typography: {
    fontFamily: 'Roboto'
  }
});

export const caloThemeV2 = createTheme({
  typography: {
    fontFamily: 'Roboto',
    fontSize: 14
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 640,
      md: 768,
      lg: 1026,
      xl: 1280
    }
  },
  palette: {
    primary: {
      main: '#24A170',
      contrastText: '#FFFFFF'
    },
    text: {
      primary: '#000000DE',
      secondary: '#00000099'
    }
  }
});
