import { MenuItem, TextField } from '@mui/material-v6';
import { MenuPresentationType } from 'lib/enums';
import { startCase } from 'lodash-es';
import { menuPresentationOptions } from '../../types';

interface MenuPresentationProps {
  disabled?: boolean;
  menuPresentation: MenuPresentationType;
  setMenuPresentation: (newPresentation: MenuPresentationType) => void;
}

const MenuPresentationField = ({ menuPresentation, disabled, setMenuPresentation }: MenuPresentationProps) => {
  return (
    <TextField
      sx={{
        width: '244px',
        '& .MuiOutlinedInput-root': {
          height: '40px'
        },
        '& .MuiOutlinedInput-input': {
          padding: '8px 14px'
        },
        '& .MuiInputLabel-root': {
          lineHeight: '40px'
        }
      }}
      select
      disabled={disabled}
      defaultValue={menuPresentation}
      onChange={(data: any) => {
        setMenuPresentation(data.target.value as MenuPresentationType);
      }}
    >
      {menuPresentationOptions.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {startCase(option.label)}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default MenuPresentationField;
