import { ChevronRight } from '@mui/icons-material';
import { Breadcrumbs, Button, Stack, Typography, useTheme } from '@mui/material';
import { getInvoiceForDelivery } from 'actions/delivery';
import { caloTheme } from 'assets/images/theme/calo';
import { createAutoDownloadLinkFromUrl } from 'lib/helpers';
import { CateringOrder } from 'lib/interfaces';
import { PaymentStatus } from '../../common';

interface CateringOrderHeaderProps {
  cateringOrder: CateringOrder;
}

export default function CateringOrderHeader({ cateringOrder }: CateringOrderHeaderProps) {
  const theme = useTheme();

  const getInvoiceAsPDF = async () => {
    const response = await getInvoiceForDelivery(cateringOrder.id);
    if (response.status === 200 && response.data.data && response.data.data.length > 0) {
      createAutoDownloadLinkFromUrl(response.data.data);
    } else {
      console.error('Failed to get invoice');
    }
  };

  const rightButtonsStyling = {
    color: theme.palette.black,
    borderColor: theme.palette.black,
    borderRadius: '3px',
    paddingX: '20px',
    paddingY: '8px',
    '&:hover': {
      backgroundColor: theme.palette.grey[200],
      borderColor: theme.palette.black
    }
  };

  return (
    <Stack gap={2}>
      <Breadcrumbs separator={<ChevronRight scale={0.5} />} aria-label="breadcrumb">
        <Typography key={'1'}>Business</Typography>
        <Typography key={'2'}>Catering</Typography>
        <Typography key={'3'} sx={{ color: 'black' }}>
          Order CI-{cateringOrder.referenceId}
        </Typography>
      </Breadcrumbs>
      <Stack direction={'row'} justifyContent={'space-between'} sx={{ width: '100%' }}>
        <Stack direction={'row'} gap={2}>
          <Typography
            variant="h5"
            sx={{
              my: 'auto',
              fontWeight: 600,
              fontSize: '28px',
              lineHeight: '34px',
              fontFamily: 'Roboto',
              color: caloTheme.palette.neutral900
            }}
          >
            CI-{cateringOrder.referenceId}
          </Typography>
          <PaymentStatus status={cateringOrder.status} />
        </Stack>
        <Button variant="outlined" onClick={getInvoiceAsPDF} sx={{ ...rightButtonsStyling }}>
          <Typography fontWeight="600" fontSize="14px">
            Download Invoice
          </Typography>
        </Button>
      </Stack>
    </Stack>
  );
}
