import { Brand, Country, Kitchen } from '@calo/types';
import { getWeek } from 'date-fns';
import { useFormik } from 'formik';
import { CreateAddonsMenuReq } from 'lib/interfaces';

const weekNumber = getWeek(new Date());

export default (onSubmit: (values: CreateAddonsMenuReq) => Promise<void>) =>
  useFormik<CreateAddonsMenuReq>({
    enableReinitialize: true,
    initialValues: {
      food: [],
      week: weekNumber,
      year: 0,
      country: Country.BH,
      brand: Brand.CALO,
      kitchen: Kitchen.BH1 as any,
      tags: []
    },

    validate: (values: CreateAddonsMenuReq) => {
      const errors: any = {};

      if (Object.values(values.food).length === 0) {
        errors.food = true;
      }

      if (!values.week) {
        errors.week = true;
      }

      if (!values.kitchen) {
        errors.kitchen = true;
      }

      if (!values.brand) {
        errors.brand = true;
      }

      return errors;
    },
    onSubmit: async (values) => {
      console.log(values);
      try {
        await onSubmit(values);
      } catch (error) {
        console.log(error);
      }
    }
  });
