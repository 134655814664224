import { Brand, Food, Localized, Macros, RetailBranch } from '@calo/types';
import { getListWithParams, updateRetailMenu } from 'actions/index';
import { Routes } from 'lib/enums';
import { useUserData } from 'lib/hooks';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { useHistory, useLocation } from 'react-router';
import { baristaEmailMapping } from '../constant';
import { getCountryFromBranch } from '../retailHelpers';
import { RetailFilter } from '../types';

interface RetailMenuDataFood extends Food {
  retail?: {
    priority: number;
    price: number;
    tags: string[];
    disabled: boolean;
    foodModifiers: {
      type: string;
      name: Localized;
      options: {
        id: string;
        option: Localized;
        macros: Macros;
        price: number;
      }[];
      otherOptions: string[];
    }[];
  };
}
export interface RetailMenuData {
  food?: RetailMenuDataFood[];
}

export const useRetailMenu = () => {
  const location = useLocation();
  const history = useHistory();
  const searchParams = new URLSearchParams(location.search);
  const user = useUserData();
  const branchFromPath = location.pathname.split('/')[2] as RetailBranch;

  const [filters, setFilters] = useState<RetailFilter>({
    brand: Brand.CALO,
    branch: RetailBranch[branchFromPath] ? branchFromPath : (baristaEmailMapping[user.email]?.[0] ?? RetailBranch.SEEF),
    ...JSON.parse(searchParams.get('filters') || `{}`)
  });

  const { data, isLoading, refetch, error } = useQuery<RetailMenuData, Error>(
    ['retail-menu', { brand: filters.brand, branch: filters.branch }],
    // @ts-ignore
    getListWithParams,
    {
      suspense: false,
      onSuccess: () => {
        searchParams.set('filters', JSON.stringify(filters));
        history.push({ pathname: location.pathname, search: searchParams.toString() });
      }
    }
  );
  const isUnauthorized = (error as any)?.response?.status === 401;

  const [retailMenu, setRetailMenu] = useState<RetailMenuData>({});
  const previousBranch = useRef(filters.branch);

  useEffect(() => {
    if (previousBranch.current === filters.branch) {
      setRetailMenu({
        // @ts-ignore
        food: data?.food?.filter((item) => {
          const matchesFoodCategory = filters.foodCategory?.length
            ? filters.foodCategory?.some((category) => item.retail?.retailTagList.includes(category))
            : true;
          const matchesStatus = filters.status
            ? filters.status === 'Active'
              ? !item.retail?.disabled
              : item.retail?.disabled
            : true;
          const matchesSize = filters.size ? item.size?.includes(filters.size) : true;
          const matchesStartDate = filters.startDate ? new Date(item.date) >= filters.startDate : true;
          const matchesEndDate = filters.endDate ? new Date(item.date) <= filters.endDate : true;

          return matchesFoodCategory && matchesStatus && matchesSize && matchesStartDate && matchesEndDate;
        })
      });
    } else {
      setRetailMenu(data || {});
      previousBranch.current = filters.branch;
    }
  }, [filters, data]);

  const handleEditClick = (itemId?: string) => {
    history.push(
      Routes.retailNewMenu
        .replace(':brand', filters.brand)
        .replace(':branch', filters.branch)
        .replace(':id', itemId || ':id')
    );
  };

  const totalItems = retailMenu.food?.length ?? 0;
  const activeItems = retailMenu.food?.filter((item) => !item.retail?.disabled).length ?? 0;

  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 300); // Debounce delay

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  const handleSearchChange = (term: string) => {
    setSearchTerm(term);
  };
  const filteredMenu = useMemo(() => {
    return retailMenu.food?.filter((item) => {
      const name = item.name?.en?.toLowerCase() || '';
      // @ts-ignore
      const tags = item.retail?.retailTagList?.map((tag) => tag.toLowerCase()) || [];
      const lowerCaseSearchTerm = debouncedSearchTerm.toLowerCase();

      return name.includes(lowerCaseSearchTerm) || tags.some((tag) => tag.includes(lowerCaseSearchTerm));
    });
  }, [retailMenu.food, debouncedSearchTerm]);

  const [isSaving, setIsSaving] = useState(false);

  const retailFoodList = useMemo(() => {
    // @ts-ignore
    if (data?.food) {
      // @ts-ignore
      return data?.food.map((f) => ({
        id: f.id,
        priority: +f.priority || 1,
        retailPrice: f.retail.retailPrice,
        retailTagList: f.retail.retailTagList,
        foodModifiers: f.retail.foodModifiers.map((item) => ({
          ...item,
          componentOptions: item.componentOptions.map((option) => ({
            id: option.id,
            quantity: option.quantity
          }))
        })),
        disabled: !!f.retail.disabled,
        swapAvailable: !!f.retail.swapAvailable,
        startDate: f.retail.startDate,
        endDate: f.retail.endDate
      }));
    }
    return [];
  }, [data]);

  const handleDeleteItem = async (id: string) => {
    const filteredMenu = retailFoodList?.filter((item) => item.id !== id) || [];
    try {
      setIsSaving(true);
      await updateRetailMenu({
        brand: filters.brand,
        branch: filters.branch,
        // @ts-ignore
        menuId: data?.id || '',
        country: getCountryFromBranch(filters.branch),
        // @ts-ignore
        food: filteredMenu
      });
      refetch();
    } catch (error) {
      console.log(error);
    } finally {
      setIsSaving(false);
    }
  };

  const toggleMenuItemVisibility = async (id: string) => {
    const updatedMenu = retailFoodList.map((item) => (item.id === id ? { ...item, disabled: !item.disabled } : item));
    try {
      setIsSaving(true);
      await updateRetailMenu({
        brand: filters.brand,
        branch: filters.branch,
        // @ts-ignore
        menuId: data?.id || '',
        country: getCountryFromBranch(filters.branch),
        food: updatedMenu
      });
      await refetch();
    } catch (error) {
      console.log(error);
    } finally {
      setIsSaving(false);
    }
  };

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value);
  };

  const paginatedMenu = useMemo(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return filteredMenu?.slice(startIndex, endIndex);
  }, [filteredMenu, currentPage]);

  return {
    filters,
    setFilters,
    retailMenu,
    isLoading,
    isUnauthorized,
    handleEditClick,
    totalItems,
    activeItems,
    searchTerm,
    setSearchTerm,
    debouncedSearchTerm,
    handleSearchChange,
    filteredMenu,
    handleDeleteItem,
    isSaving,
    paginatedMenu,
    currentPage,
    handlePageChange,
    itemsPerPage,
    toggleMenuItemVisibility
  };
};
