import { Kitchen } from '@calo/types';
import { format } from 'date-fns/fp';
import { PaymentStatus } from 'lib/enums';
import { CateringOrderFilters } from 'lib/interfaces';

export function getDefaultFilters(): CateringOrderFilters {
  return {
    kitchen: Kitchen.SA1,
    status: PaymentStatus.all
  };
}

export const getDate = (date: Date) => format('yyyy-MM-dd')(date);

export const convertStatus = (status?: PaymentStatus) => {
  switch (status) {
    case PaymentStatus.paid:
      return 'CONFIRMED';
    case PaymentStatus.pending:
      return 'PAYMENT_REQUIRED';
    default:
      return undefined;
  }
};

export const convertOrderStatus = (status: string): PaymentStatus => {
  switch (status) {
    case 'CONFIRMED':
      return PaymentStatus.paid;
    case 'PAYMENT_REQUIRED':
      return PaymentStatus.pending;
    default:
      return PaymentStatus.all;
  }
};
