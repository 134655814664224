import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Stack, Typography } from '@mui/material';
import { styles } from './styles';

interface ComponentCostInfoProps {
  costInfo: string;
  handleClose: () => void;
  marginTop?: string;
  marginBottom?: string;
  marginHorizontal?: string;
}

const ComponentCostInfo = ({ costInfo, handleClose, marginTop, marginBottom, marginHorizontal }: ComponentCostInfoProps) => {
  return (
    <Stack sx={styles.container(marginTop, marginBottom, marginHorizontal)}>
      <Stack>
        {costInfo.split(',').map((info, index) => (
          <Typography key={index} sx={styles.text}>
            {info}
          </Typography>
        ))}
      </Stack>
      <IconButton onClick={handleClose} sx={styles.icon}>
        <CloseIcon fontSize="medium" />
      </IconButton>
    </Stack>
  );
};

export default ComponentCostInfo;
