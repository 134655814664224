import { ProcessingStage, PrototypeAction } from '@calo/dashboard-types';
import AddCommentIcon from '@mui/icons-material/AddComment';
import ShareIcon from '@mui/icons-material/Share';
import { CardMedia, Chip, IconButton, Stack, TableCell, TableRow, Tooltip, Typography } from '@mui/material';
import noImagePlaceholder from 'assets/images/noImagePlaceholder.png';
import { caloTheme } from 'assets/images/theme/calo';
import { foodStatuslabelColorMapping, foodStatuslabelMapping } from 'lib/constants';
import { Routes } from 'lib/enums';
import { getHostName } from 'lib/helpers';
import { IngredientType } from 'lib/interfaces';
import { maxBy, startCase } from 'lodash-es';
import { useState } from 'react';
import { Link } from 'react-router-dom';

const styles = {
  foodName: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '150%',
    color: caloTheme.palette.primaryMain
  },
  foodImage: {
    width: 40,
    height: 40,
    objectFit: 'cover',
    borderRadius: '8px'
  },
  comment: {
    fontSize: '14px',
    display: '-webkit-box',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical'
  },
  actionButton: {
    flexDirection: 'row',
    gap: '24px'
  },
  cardStack: {
    flexDirection: 'row',
    gap: '8px',
    alignItems: 'center'
  },
  tableCellStack: {
    display: 'flex',
    flexDirection: 'row',
    gap: '4px'
  }
};

interface IngredientRowProps {
  ingredient: IngredientType;
  commentClickHandler: (ingredient: any) => void;
}

const IngredientRow = ({ ingredient, commentClickHandler }: IngredientRowProps) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const comments = ingredient.prototypeActions?.filter((action) => action.type === PrototypeAction.comment) ?? [];

  let lastComment = '';
  if (comments.length > 0) {
    const lastCommentAction: any = maxBy(comments, (comment) => new Date(comment.createdAt));
    lastComment = lastCommentAction.type === PrototypeAction.comment ? lastCommentAction.message : '';
  }

  const shareButtonClickHandler = async () => {
    const hostName = getHostName();
    const link = `${hostName}${Routes.playgroundIngredient.replace(':id', ingredient.id)}`;

    try {
      await navigator.clipboard.writeText(link);
      setTooltipOpen(true);
      setTimeout(() => {
        setTooltipOpen(false);
      }, 1000); // Hide tooltip after 3 seconds
    } catch (error) {
      console.error('Failed to copy text: ', error);
    }
  };

  const displayImage = `${process.env.REACT_APP_BUCKET_URL}/ingredients/${ingredient.id}/square@1x.jpg`;

  const renderCommentCell = () => {
    if (lastComment) {
      return <Typography sx={styles.comment}>{lastComment}</Typography>;
    } else {
      return (
        <IconButton onClick={() => commentClickHandler(ingredient)}>
          <AddCommentIcon sx={{ color: '#212121' }} />
        </IconButton>
      );
    }
  };

  return (
    <TableRow>
      <TableCell>
        <Stack sx={styles.cardStack}>
          <CardMedia
            component="img"
            image={displayImage}
            alt="placeholder"
            sx={styles.foodImage}
            onError={(e: any) => {
              e.target.src = noImagePlaceholder;
            }}
          />
          <Link to={Routes.playgroundIngredient.replace(':id', ingredient.id)}>
            <Typography sx={styles.foodName}>{ingredient.name.en}</Typography>
          </Link>
        </Stack>
      </TableCell>
      <TableCell>
        <Stack sx={styles.tableCellStack}>
          <Typography sx={{ fontSize: '14px' }}>
            {ingredient.prototypeActions?.find((action) => action.type === PrototypeAction.create)?.actor.name}
          </Typography>
        </Stack>
      </TableCell>
      <TableCell>
        <Stack sx={styles.tableCellStack}>
          <Chip
            label={startCase(foodStatuslabelMapping[ingredient?.stage || ProcessingStage.draft])}
            color={foodStatuslabelColorMapping[ingredient?.stage || ProcessingStage.draft]}
            size="medium"
            variant="outlined"
          />
        </Stack>
      </TableCell>
      <TableCell>
        <Stack sx={styles.tableCellStack}>{renderCommentCell()}</Stack>
      </TableCell>
      <TableCell>
        <Stack sx={styles.tableCellStack}>
          <Stack>
            <Tooltip
              title="Link Copied"
              open={tooltipOpen}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              onClose={() => setTooltipOpen(false)}
            >
              <IconButton onClick={shareButtonClickHandler}>
                <ShareIcon sx={{ color: '#212121' }} />
              </IconButton>
            </Tooltip>
          </Stack>
        </Stack>
      </TableCell>
    </TableRow>
  );
};

export default IngredientRow;
