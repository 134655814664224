import { Kitchen } from '@calo/types';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box, Button, Stack, Typography } from '@mui/material';
import { caloTheme } from 'assets/images/theme/calo';
import { getKitchenShortCut } from 'lib/helpers';
import { useState } from 'react';
import { styles } from '../styles';

interface KitchenValidationProps {
  kitchen: Kitchen;
  validationData: { type: string; value: string }[] | undefined;
}

const KitchenValidation = ({ kitchen, validationData }: KitchenValidationProps) => {
  const [showValidationResult, setShowValidationResult] = useState(false);

  const ingredientsMissing = validationData?.filter((data) => data.type === 'ingredient').map((data) => data.value);
  const isComponentMissing = validationData?.find((data) => data.type === 'component');

  return (
    <Stack sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
      <Button
        variant="outlined"
        endIcon={showValidationResult ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        onClick={() => setShowValidationResult((prev) => !prev)}
        sx={{
          backgroundColor: validationData ? '#FAEEEE' : caloTheme.palette.primary100,
          borderColor: validationData ? '#FAEEEE' : caloTheme.palette.primary100,
          color: 'black',
          fontWeight: 600,
          fontSize: '14px',
          py: '10px',
          justifyContent: 'space-between',
          '&:hover': {
            backgroundColor: validationData ? '#FAEEEE' : caloTheme.palette.primary100,
            borderColor: validationData ? '#FAEEEE' : caloTheme.palette.primary100
          }
        }}
      >
        {getKitchenShortCut(kitchen)}
      </Button>

      {showValidationResult && (
        <Box
          sx={{
            border: '2px solid ' + caloTheme.palette.neutral100,
            borderRadius: '4px',
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            padding: '16px'
          }}
        >
          {ingredientsMissing &&
            ingredientsMissing.map((ingredient) => (
              <Typography sx={styles.validationResultText} key={ingredient}>
                Ingredient "{ingredient}" does not exist. Please create ingredient for this MP to sync
              </Typography>
            ))}
          {isComponentMissing && (
            <Typography sx={styles.validationResultText}>
              This component "{isComponentMissing.value}" does not appear in MP database. Please create component in order to
              update it
            </Typography>
          )}
          {!validationData && (
            <>
              <Typography sx={styles.validationResultText}>
                The component name in this MP matches the component we are trying to update. We found all the ingredients in this
                MP
              </Typography>
            </>
          )}
        </Box>
      )}
    </Stack>
  );
};

export default KitchenValidation;
