import { Button, Stack, Typography } from '@mui/material-v6';
import { caloThemeV2 } from 'assets/themev2';
import { ModalRef } from 'components/Modal/Modal';

interface WeeklyPublishProps {
  weeklyMenuDates: string[];
  isGenerateMenuLoading: boolean;
  generateWeeklyMenuHandler: () => void;
  publishMenuRef: React.MutableRefObject<ModalRef | undefined>;
}

const WeeklyPublish = ({
  isGenerateMenuLoading,
  generateWeeklyMenuHandler,
  publishMenuRef,
  weeklyMenuDates
}: WeeklyPublishProps) => {
  return (
    <Stack sx={{ width: '506px' }}>
      <Typography sx={{ fontSize: '20px', fontWeight: 500 }}>Confirm menu publication?</Typography>
      <Typography sx={{ marginTop: 2, fontSize: '16px' }}>
        Are you sure you want to publish this menu from {weeklyMenuDates[0]} to {weeklyMenuDates[weeklyMenuDates.length - 1]}?{' '}
      </Typography>
      <Typography sx={{ fontSize: '16px' }}>Once published, it will be visible to all users.</Typography>

      <Stack sx={{ flexDirection: 'row', gap: 2, alignItems: 'center', alignSelf: 'flex-end', marginTop: 4 }}>
        <Button variant="outlined" disabled={isGenerateMenuLoading} onClick={generateWeeklyMenuHandler}>
          Publish
        </Button>
        <Button
          variant="text"
          onClick={() => publishMenuRef.current?.close()}
          disabled={isGenerateMenuLoading}
          sx={{
            color: caloThemeV2.palette.text.primary
          }}
        >
          Cancel
        </Button>
      </Stack>
    </Stack>
  );
};

export default WeeklyPublish;
