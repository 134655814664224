import { QueryFunction } from 'react-query';

import { AddLoyaltyPointsRequest } from 'components/AddLoyaltyPointsForm/types';
import client, { loyaltyClient } from 'lib/client';
import { ToastSuccessMsgs } from 'lib/helpers';

export const checkEligibilityForPoints = async (userId: string, amount: number) => {
  const { data } = await loyaltyClient.get(`/dashboard/eligible/${userId}/${amount}`);
  return data;
};

export const getTotalPoints = async (userId: string) => {
  const { data } = await loyaltyClient.get(`/dashboard/${userId}`);
  return { data };
};

export const getLoyaltyRecord: QueryFunction = async (context) => {
  const { data } = await loyaltyClient.get(context.queryKey.join('/'));

  return data;
};

export const syncBalance = async (userId: string) => {
  const { status } = await client.post(`/loyalty/${userId}/sync`);
  ToastSuccessMsgs({ status, action: 'Synced successfully, please refresh' });
};

export const addLoyaltyPoints = async (values: AddLoyaltyPointsRequest) => {
  const { data, status } = await client.post(`/loyalty/${values.userId}/add-points`, {
    userId: values.userId,
    amount: values.amount
  });

  ToastSuccessMsgs({ status, action: `${values.amount} points added, please refresh to see the updated points` });

  return data;
};
