import { FoodTagsKeys } from '@calo/dashboard-types';
import { FoodType } from '@calo/types';
import { Stack, Typography } from '@mui/material-v6';
import { caloThemeV2 } from 'assets/themev2';
import { MenuFood } from 'lib/interfaces';
import { MenuFoodDietTags } from '..';

const MealInfoTypes = ({ selectedMeal }: { selectedMeal: MenuFood }) => {
  const getMealType = () => {
    if (Array.isArray(selectedMeal?.type)) {
      return selectedMeal.type
        .map((r: FoodType) => {
          if (r === FoodType.dinner) {
            return null;
          }
          return r.includes(FoodType.lunch) ? 'Lunch & Dinner' : r;
        })
        .filter(Boolean)
        .join(', ');
    }
    return '';
  };

  const getFoodTagValue = (key: FoodTagsKeys) => {
    const tag = selectedMeal?.foodTags?.find((tag: any) => tag.key === key);
    return Array.isArray(tag?.value) ? tag?.value.join(', ') : tag?.value || '--';
  };

  return (
    <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between', marginTop: 4 }}>
      <Stack sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <Typography
          sx={{
            fontSize: '12px',
            color: caloThemeV2.palette.text.disabled,
            textTransform: 'uppercase'
          }}
        >
          Type
        </Typography>
        <Typography
          sx={{
            fontSize: '16px',
            textTransform: 'capitalize'
          }}
        >
          {getMealType()}
        </Typography>
      </Stack>

      <Stack sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <Typography
          sx={{
            fontSize: '12px',
            color: caloThemeV2.palette.text.disabled,
            textTransform: 'uppercase'
          }}
        >
          protein
        </Typography>
        <Typography
          sx={{
            fontSize: '16px',
            textTransform: 'capitalize'
          }}
        >
          {getFoodTagValue(FoodTagsKeys.protein)}
        </Typography>
      </Stack>

      <Stack sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <Typography
          sx={{
            fontSize: '12px',
            color: caloThemeV2.palette.text.disabled,
            textTransform: 'uppercase'
          }}
        >
          taste
        </Typography>
        <Typography
          sx={{
            fontSize: '16px',
            textTransform: 'capitalize'
          }}
        >
          {getFoodTagValue(FoodTagsKeys.taste)}
        </Typography>
      </Stack>

      <Stack sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <Typography
          sx={{
            fontSize: '12px',
            color: caloThemeV2.palette.text.disabled,
            textTransform: 'uppercase'
          }}
        >
          base
        </Typography>
        <Typography
          sx={{
            fontSize: '16px',
            textTransform: 'capitalize'
          }}
        >
          {getFoodTagValue(FoodTagsKeys.base)}
        </Typography>
      </Stack>

      <Stack sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <Typography
          sx={{
            fontSize: '12px',
            color: caloThemeV2.palette.text.disabled,
            textTransform: 'uppercase'
          }}
        >
          sandwich
        </Typography>
        <Typography
          sx={{
            fontSize: '16px',
            textTransform: 'capitalize'
          }}
        >
          {selectedMeal?.assemblyRequired ? 'Yes' : 'No'}
        </Typography>
      </Stack>
      <Stack sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <Typography
          sx={{
            fontSize: '12px',
            color: caloThemeV2.palette.text.disabled,
            textTransform: 'uppercase'
          }}
        >
          tags
        </Typography>
        <Stack display="flex" flexDirection="row" justifyContent="space-evenly">
          {Array.isArray(selectedMeal?.tags) ? selectedMeal.tags.map((r: any) => <MenuFoodDietTags key={r} tag={r} />) : null}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default MealInfoTypes;
