export enum WalletAction {
  ADD = 'ADD',
  REMOVE = 'REMOVE',
  REFUND = 'REFUND'
}

export enum MeasurementUnit {
  piece = 'piece',
  g = 'g',
  ml = 'ml',
  teaspoon = 'teaspoon',
  cup = 'cup',
  pinch = 'pinch',
  wedge = 'wedge'
}

export enum PaymentSource {
  online = 'online',
  offline = 'offline',
  credits = 'credits',
  wallet = 'wallet'
}

export enum DriverStatus {
  active = 'active',
  inactive = 'inactive'
}

export enum GroupGiftStatus {
  DRAFT = 'draft',
  RELEASED = 'released',
  PENDING = 'pending'
}

export enum GroupGiftItemStatus {
  PENDING = 'pending', // parent is still a draft
  ADDED = 'added', // meal added to delivery
  DELIVERED = 'delivered' // to be handled later by cronjob when delivery is delivered
}

export declare enum PickUpStatus {
  CREATED = 'CREATED',
  PICKED_UP = 'PICKED_UP',
  READY_FOR_PICK_UP = 'READY_FOR_PICK_UP'
}

export enum RetailOrderStatus {
  CANCELLED = 'CANCELLED',
  CREATED = 'CREATED',
  DONE = 'DONE',
  IN_PROGRESS = 'IN_PROGRESS',
  READY_FOR_PICKUP = 'READY_FOR_PICKUP'
}

export declare enum OrderItemType {
  ADDON = 'ADDON',
  ADDON_SUBSCRIPTION = 'ADDON_SUBSCRIPTION',
  CAKE = 'CAKE',
  FLOWERS = 'FLOWERS',
  FOOD = 'FOOD',
  FOOD_SUBSCRIPTION = 'FOOD_SUBSCRIPTION',
  LOYALTY = 'LOYALTY',
  MERCHANDISE = 'MERCHANDISE',
  OTHER = 'OTHER',
  RETAIL = 'RETAIL'
}
export enum LogType {
  ADDON_SUBSCRIPTION_ADDED = 'ADDON_SUBSCRIPTION_ADDED',
  ADDON_SUBSCRIPTION_REMOVED = 'ADDON_SUBSCRIPTION_REMOVED',
  ADDON_GIFT_CARD_SENT = 'ADDON_GIFT_CARD_SENT',
  DELIVERY_SKIPPED = 'DELIVERY_SKIPPED',
  DELIVERY_PLAN_CHANGED = 'DELIVERY_PLAN_CHANGED',
  DELIVERY_EXTRAS_ADDED = 'DELIVERY_EXTRAS_ADDED',
  DELIVERY_ADDON_ADDED_BY_CALO = 'DELIVERY_ADDON_ADDED_BY_CALO',
  DELIVERY_ADDON_REMOVED_BY_CALO = 'DELIVERY_ADDON_REMOVED_BY_CALO',
  DELIVERY_ADDON_REPLACED = 'DELIVERY_ADDON_REPLACED',
  DELIVERY_UNSKIPPED = 'DELIVERY_UNSKIPPED',
  DELIVERY_ADDRESS_CREATED = 'DELIVERY_ADDRESS_CREATED',
  DELIVERY_ADDRESS_CHANGED = 'DELIVERY_ADDRESS_CHANGED',
  DELIVERY_FOOD_REPLACED = 'DELIVERY_FOOD_REPLACED',
  DELIVERY_DAY_CHANGED = 'DELIVERY_DAY_CHANGED',
  DELIVERY_ADDED = 'DELIVERY_ADDED',
  DELIVERY_FOOD_SKIPPED = 'DELIVERY_FOOD_SKIPPED',
  DELIVERY_FOOD_UNSKIPPED = 'DELIVERY_FOOD_UNSKIPPED',
  DELIVERY_FOOD_MODIFIED = 'DELIVERY_FOOD_MODIFIED',
  DELIVERY_FOOD_ADDED_BY_CALO = 'DELIVERY_FOOD_ADDED_BY_CALO',
  DELIVERY_FOOD_REMOVED_BY_CALO = 'DELIVERY_FOOD_REMOVED_BY_CALO',
  DELIVERY_STATUS_MODIFIED = 'DELIVERY_STATUS_MODIFIED',
  DELIVERY_DELIVERED_STATUS_MODIFIED = 'DELIVERY_DELIVERED_STATUS_MODIFIED',
  SUBSCRIPTION_NAME_CHANGED = 'SUBSCRIPTION_NAME_CHANGED',
  SUBSCRIPTION_PHONE_NUMBER_CHANGED = 'SUBSCRIPTION_PHONE_NUMBER_CHANGED',
  SUBSCRIPTION_PAUSED = 'SUBSCRIPTION_PAUSED',
  SUBSCRIPTION_UNPAUSED = 'SUBSCRIPTION_UNPAUSED',
  SUBSCRIPTION_ADDRESS_CREATED = 'SUBSCRIPTION_ADDRESS_CREATED',
  SUBSCRIPTION_ADDRESS_CHANGED = 'SUBSCRIPTION_ADDRESS_CHANGED',
  SUBSCRIPTION_ADDRESS_DELETED = 'SUBSCRIPTION_ADDRESS_DELETED',
  SUBSCRIPTION_PLAN_CHANGED = 'SUBSCRIPTION_PLAN_CHANGED',
  SUBSCRIPTION_RENEWED = 'SUBSCRIPTION_RENEWED',
  SUBSCRIPTION_PAYMENT = 'SUBSCRIPTION_PAYMENT',
  SUBSCRIPTION_EXTRAS_ADDED = 'SUBSCRIPTION_EXTRAS_ADDED',
  SUBSCRIPTION_PENDING_CHANGED = 'SUBSCRIPTION_PENDING_CHANGED',
  SUBSCRIPTION_SUSPENDED = 'SUBSCRIPTION_SUSPENDED',
  SUBSCRIPTION_SUSPENSION_REMOVED = 'SUBSCRIPTION_SUSPENSION_REMOVED',
  SUBSCRIPTION_AUTO_RENEW_STATUS_CHANGED = 'SUBSCRIPTION_AUTO_RENEW_STATUS_CHANGED',
  SUBSCRIPTION_MACROS_TYPE_CHANGED = 'SUBSCRIPTION_MACROS_TYPE_CHANGED',
  SUBSCRIPTION_MACROS_CHANGED = 'SUBSCRIPTION_MACROS_CHANGED',
  SUBSCRIPTION_EMAIL_CHANGED = 'SUBSCRIPTION_EMAIL_CHANGED',
  SUBSCRIPTION_DIET_TYPE_CHANGED = 'SUBSCRIPTION_DIET_TYPE_CHANGED',
  SUBSCRIPTION_FREQUENCY_CHANGED = 'SUBSCRIPTION_FREQUENCY_CHANGED',
  SUBSCRIPTION_AUTO_RENEW_FAILED = 'SUBSCRIPTION_AUTO_RENEW_FAILED',
  SUBSCRIPTION_DELIVERY_PREFERENCES_CHANGED = 'SUBSCRIPTION_DELIVERY_PREFERENCES_CHANGED',
  ALLOWANCE_DAYS_CHANGED = 'ALLOWANCE_DAYS_CHANGED',
  DELIVERY_FOOD_COMPONENTS_MODIFIED = 'DELIVERY_FOOD_COMPONENTS_MODIFIED',
  RESUME_DAY_UPDATED = 'RESUME_DAY_UPDATED',
  SCHEDULED_SUBSCRIPTION_PAUSE = 'SCHEDULED_SUBSCRIPTION_PAUSE',
  SUBSCRIPTION_BIOMETRICS_CHANGED = 'SUBSCRIPTION_BIOMETRICS_CHANGED',
  BLOCK_MEAL_ADDED = 'BLOCK_MEAL_ADDED',
  BLOCK_MEAL_REMOVED = 'BLOCK_MEAL_REMOVED'
}

export enum ShiftActionType {
  STARTED_SHIFT = 'STARTED_SHIFT',
  STARTED_DELIVERING = 'STARTED_DELIVERING',
  FINISHED_SHIFT = 'FINISHED_SHIFT'
}

export enum Permission {
  SEND_KITCHEN_EMAIL = 'a:ske',
  VIEW_USER_PERMISSIONS_LIST = 'v:upl',
  CREATE_USER_PERMISSIONS = 'c:up',
  DELETE_USER_PERMISSIONS = 'd:up',
  UPDATE_USER_PERMISSIONS = 'u:up',
  VIEW_DELIVERY_LIST = 'v:dl',
  EXPORT_DELIVERY_LIST = 'a:edl',
  VIEW_DELIVERY = 'v:d',
  UPDATE_DELIVERY = 'u:d',
  SKIP_DELIVERY = 'a:sd',
  UNSKIP_DELIVERY = 'a:ud',
  GET_DELIVERY_INVOICE = 'a:gdi',
  ADD_FOOD_DELIVERY_MENU = 'a:dm',
  REMOVE_COMPONENT_FROM_DELIVERY_FOOD = 'a:rc',
  DELETE_FOOD_DELIVERY_MENU = 'd:dm',
  REPLACE_FOOD_DELIVERY_MENU = 'a:rdm',
  REPLACE_ADDON_SUBSCRIPTION_DELIVERY_MENU = 'u:asdm',
  VIEW_ADDON_SUBSCRIPTION_MENU = 'v:asm',
  ADD_ADDON_SUBSCRIPTION = 'a:asm',
  REMOVE_ADDON_SUBSCRIPTION = 'd:asm',
  VIEW_ADDON_SUBSCRIPTION_LIST = 'v:asl',
  REGENERATE_DELIVERY_MENU = 'a:rgm',
  DELIVERY_APPLY_PAYMENT = 'a:dap',
  CREATE_ADDONS_DELIVERY_MENU = 'c:adm',
  DELETE_ADDONS_DELIVERY_MENU = 'd:adm',
  CANCEL_DELIVERY = 'a:cd',
  UNCANCEL_DELIVERY = 'a:ucd',
  DELIVERY_ADDONS_MENU = 'v:dam',
  DELIVERY_ADDONS_SUBSCRIPTION_MENU = 'v:dasm',
  VIEW_DELIVERY_SUBSCRIPTION = 'v:ds',
  UPDATE_DELIVERY_CUSTOM_FOOD = 'u:dcf',
  VIEW_USER_LIST = 'v:ul',
  VIEW_USER = 'v:u',
  CREATE_USER = 'c:u',
  UPDATE_USER = 'a:uu',
  RESET_USER_PASSWORD = 'a:rup',
  DELETE_USER = 'd:u',
  VIEW_RATING_LIST = 'v:ral',
  EXPORT_RATING_LIST = 'a:erl',
  VIEW_SUBSCRIPTION_LIST = 'v:sl',
  VIEW_SUBSCRIPTION = 'v:s',
  UPDATE_SUBSCRIPTION = 'u:s',
  CREATE_SUBSCRIPTION = 'c:s',
  PAUSE_SUBSCRIPTION = 'a:ps',
  RESUME_SUBSCRIPTION = 'a:rs',
  CLEAR_SUBSCRIPTION_PAUSE = 'a:csp',
  EXPORT_SUBSCRIPTION_LIST = 'a:se',
  SEARCH_SUBSCRIPTION = 'v:ss',
  REMOVE_SUBSCRIPTION_SUSPENSION = 'a:rss',
  CREATE_SUBSCRIPTION_ADDRESS = 'c:sa',
  UPDATE_SUBSCRIPTION_ADDRESS = 'u:sa',
  DELETE_SUBSCRIPTION_ADDRESS = 'd:sa',
  BULK_UPDATE_SUBSCRIPTION_ADDRESS = 'bu:sa',
  CREATE_SUBSCRIPTION_WALLET = 'c:sw',
  CREATE_SUBSCRIPTION_DELIVERY = 'c:sd',
  UPDATE_SUBSCRIPTION_DELIVERY_PREFERENCES = 'u:sdp',
  VIEW_SUBSCRIPTION_DELIVERY_LIST = 'v:sdl',
  VIEW_FOOD_PREFERENCE = 'v:fp',
  UPDATE_FOOD_PREFERENCE = 'u:fp',
  UPDATE_SUBSCRIPTION_PLAN = 'u:sp',
  VIEW_SUBSCRIPTION_WALLET = 'v:sw',
  VIEW_WALLET_LIST = 'v:wal',
  EXPORT_WALLET_LIST = 'a:wal',
  VIEW_SUBSCRIPTION_LOGS = 'v:slo',
  VIEW_ROUTE_PLAN_LIST = 'v:rpl',
  UPDATE_SUBSCRIPTION_CUSTOM_DELIVERY_TIME = 'u:uscdt',
  EXPORT_DRIVER_COMPLETION_RATE = 'e:dcr',
  VIEW_ROUTE_PLAN = 'v:rp',
  REGENERATE_ROUTE = 'a:rr',
  CREATE_MAP = 'c:m',
  UPDATE_MAP = 'u:m',
  VIEW_MAP = 'v:m',
  GET_DRIVER_USERS = 'a:gdu',
  VIEW_MAP_DELIVERIES_LIST = 'v:mdl',
  VIEW_LIVE_TRACKING = 'v:lt',
  VIEW_KITCHEN_LIST = 'v:kl',
  VIEW_KITCHEN = 'v:k',
  UPDATE_KITCHEN = 'u:uk',
  VIEW_KITCHEN_STATS = 'v:ks',
  VIEW_COMPONENT_SCALED_RECIPE = 'v:csr',
  VIEW_FOOD_STATS = 'v:fs',
  EXPORT_FOOD_STATS = 'a:efs',
  VIEW_INGREDIENT_STATS = 'v:is',
  EXPORT_INGREDIENT_STATS = 'a:eis',
  VIEW_PORTION_STATS = 'v:ps',
  EXPORT_PORTION_STATS = 'a:eps',
  VIEW_PACKAGE_ELEMENTS_STATS = 'v:pes',
  VIEW_DRIVER_STATS = 'v:drs',
  VIEW_DELIVERY_STATS = 'v:dst',
  VIEW_CUTLERY_AND_COOLER_BAG_STATS = 'v:cacbs',
  VIEW_DRIVER_COOLER_BAG_STATS = 'v:dcs',
  VIEW_MENU_LIST = 'v:ml',
  UPDATE_MENU = 'u:me',
  VIEW_MENU = 'v:me',
  GENERATE_DAY_MENU = 'a:gdm',
  CREATE_MENU = 'c:me',
  DESIGN_MENU = 'a:me',
  MENU_GENERATION_LIST = 'v:mgl',
  VIEW_ADDONS_LIST = 'v:al',
  VIEW_ADDONS = 'v:a',
  UPDATE_ADDONS = 'u:a',
  CREATE_ADDONS = 'c:a',
  VIEW_ADDONS_SUBSCRIPTION_LIST = 'v:asl',
  VIEW_ADDONS_SUBSCRIPTION = 'v:as',
  UPDATE_ADDONS_SUBSCRIPTION = 'u:as',
  CREATE_ADDONS_SUBSCRIPTION = 'c:as',
  VIEW_FOOD_LIST = 'v:fl',
  VIEW_FOOD_LIST_BY_SLUG = 'v:fls',
  CREATE_FOOD = 'c:f',
  UPDATE_FOOD = 'u:f',
  BATCH_UPDATE_FOOD = 'u:bf',
  VIEW_FOOD = 'v:f',
  DELETE_FOOD = 'd:f',
  VIEW_PROTOTYPE_FOOD_LIST = 'v:pfl',
  VIEW_PROTOTYPE_FOOD_LIST_BY_SLUG = 'v:pfls',
  CREATE_PROTOTYPE_FOOD = 'c:pf',
  UPDATE_PROTOTYPE_FOOD = 'u:pf',
  VIEW_PROTOTYPE_FOOD = 'v:pf',
  CREATE_ACTION_PROTOTYPE_FOOD = 'c:apf',
  APPROVE_PROTOTYPE_FOOD = 'a:pf',
  DELETE_PROTOTYPE_FOOD = 'd:pf',
  VIEW_PROTOTYPE_COMPONENT_LIST = 'v:pcl',
  CREATE_PROTOTYPE_COMPONENT = 'c:pc',
  UPDATE_PROTOTYPE_COMPONENT = 'u:pc',
  VIEW_PROTOTYPE_COMPONENT = 'v:pc',
  CREATE_ACTION_PROTOTYPE_COMPONENT = 'c:apc',
  APPROVE_PROTOTYPE_COMPONENT = 'a:pc',
  DELETE_PROTOTYPE_COMPONENT = 'd:pc',
  VIEW_PROTOTYPE_INGREDIENT_LIST = 'v:pil',
  CREATE_PROTOTYPE_INGREDIENT = 'c:pi',
  UPDATE_PROTOTYPE_INGREDIENT = 'u:pi',
  VIEW_PROTOTYPE_INGREDIENT = 'v:pi',
  CREATE_ACTION_PROTOTYPE_INGREDIENT = 'c:api',
  APPROVE_PROTOTYPE_INGREDIENT = 'a:pi',
  DELETE_PROTOTYPE_INGREDIENT = 'd:pi',
  BATCH_UPDATE_PROTOTYPE_FOOD = 'u:bpf',
  EXPORT_FOOD_LIST = 'a:efl',
  VIEW_FOOD_COMPONENTS_LIST = 'v:fcl',
  VIEW_FOOD_COMPONENTS_CUSTOM_FOOD = 'v:fcc',
  CREATE_FOOD_COMPONENTS = 'c:fc',
  UPDATE_FOOD_COMPONENTS = 'u:fc',
  VIEW_FOOD_COMPONENTS = 'v:fc',
  EXPORT_FOOD_COMPONENTS_LIST = 'a:efcl',
  UPLOAD_IMAGE = 'a:ufi',
  CREATE_LOGS_ON_UPDATE_IMAGE = 'c:cloui',
  VIEW_INGREDIENT_LIST = 'v:il',
  VIEW_INGREDIENT = 'v:i',
  UPDATE_INGREDIENT = 'u:i',
  CREATE_INGREDIENT = 'c:i',
  EXPORT_INGREDIENT_LIST = 'a:eil',
  VIEW_REFUND_LIST = 'v:rl',
  CREATE_REFUND = 'c:r',
  EXPORT_REFUND_LIST = 'a:erfl',
  VIEW_SEARCH_BAR = 'v:sb',
  VIEW_COUPON_LIST = 'v:cl',
  VIEW_COUPON_USAGE_LIST = 'v:cul',
  VIEW_COUPON = 'v:c',
  CREATE_COUPON = 'c:c',
  UPDATE_COUPON = 'u:c',
  DELETE_COUPON = 'd:c',
  VIEW_DRIVER_METRIC_LIST = 'v:dml',
  VIEW_DRIVER_METRIC = 'v:dm',
  UPDATE_DRIVER_METRIC = 'u:dm',
  BATCH_ALLOW_DRIVERS_TO_START_SHIFT = 'a:badtss',
  VIEW_WAITING_LIST = 'v:wl',
  ALLOW_WAITING_LIST = 'a:awl',
  BATCH_ALLOW_WAITING_LIST = 'a:bawl',
  SEND_INVITATION_CODE_WAITING_LIST = 'a:wlc',
  DELETE_WAITING_LIST = 'd:wl',
  BATCH_REMOVE_FROM_PRE_LAUNCH_WAITING_LIST = 'a:brwl',
  VIEW_INVITATION_CODE_STATS = 'v:cs',
  VIEW_INVITATION_CODE_USER_STATS = 'v:cus',
  CREATE_INVITATION_CODE = 'c:ic',
  CREATE_BATCH_INVITATION_CODE = 'c:icb',
  VIEW_FOOD_PACKAGE_ELEMENT_LIST = 'v:pel',
  CREATE_FOOD_PACKAGE_ELEMENT = 'c:pe',
  UPDATE_FOOD_PACKAGE_ELEMENT = 'u:pe',
  VIEW_FOOD_PACKAGE_ELEMENT = 'v:pe',
  DELETE_FOOD_PACKAGE_ELEMENT = 'd:pe',
  EXPORT_FOOD_PACKAGE_ELEMENT = 'a:efpe',
  VIEW_FOOD_PACKAGE_LIST = 'v:pl',
  CREATE_FOOD_PACKAGE = 'c:p',
  UPDATE_FOOD_PACKAGE = 'u:p',
  VIEW_FOOD_PACKAGE = 'v:p',
  DELETE_FOOD_PACKAGE = 'd:p',
  EXPORT_FOOD_PACKAGE = 'a:efp',
  VIEW_DRIVER_LIST = 'v:drl',
  CREATE_DRIVER = 'c:dr',
  UPDATE_DRIVER = 'u:dr',
  VIEW_DRIVER = 'v:dr',
  DELETE_DRIVER = 'd:dr',
  RESET_DRIVER_PASSWORD = 'a:rdp',
  CREATE_TICKET = 'c:t',
  VIEW_TICKET_LIST = 'v:tl',
  UPDATE_TICKET = 'u:t',
  VIEW_COMPLETE_ROUTE_PATH = 'v:crp',
  CREATE_KDS_USER = 'c:kds',
  VIEW_KDS_USER = 'v:kds',
  UPDATE_KDS_USER = 'u:kds',
  DELETE_KDS_USER = 'd:kds',
  VIEW_KDS_USER_LIST = 'v:kdsl',
  RESET_KDS_USER_PASSWORD = 'a:rkds',
  VIEW_KITCHEN_LOGS_LIST = 'v:kll',
  VIEW_USER_CUSTOM_FOOD = 'v:ucf',
  CREATE_USER_CUSTOM_FOOD = 'c:ucf',
  CREATE_GROUP_GIFT = 'c:gg',
  AUTO_CREATE_GROUP_GIFT = 'c:agg',
  APPLY_GROUP_GIFT = 'a:gg',
  AUTO_APPLY_GROUP_GIFT = 'a:agg',
  DOWNLOAD_GROUP_GIFT_LISTS = 'v:ggl',
  LIST_GROUP_GIFT = 'v:gg',
  UPDATE_GROUP_GIFT = 'u:gg',
  VALIDATE_GROUP_GIFT = 'a:vgg',
  LIST_GROUP_GIFT_ITEM = 'v:ggi',
  DELETE_GROUP_GIFT_ITEM = 'd:ggi',
  CREATE_DELIVERY_TICKET = 'c:dmc',
  LIST_DELIVERY_TICKET = 'v:dmc',
  LIST_EXTRA_MEALS = 'v:exm',
  CREATE_SUPPLY_CHAIN_USER = 'c:scu',
  VIEW_SUPPLY_CHAIN_USER = 'v:scu',
  UPDATE_SUPPLY_CHAIN_USER = 'u:scu',
  DELETE_SUPPLY_CHAIN_USER = 'd:scu',
  VIEW_SUPPLY_CHAIN_USER_LIST = 'v:scul',
  RESET_SUPPLY_CHAIN_USER_PASSWORD = 'a:rscu',
  VIEW_INVENTORY_CONSUMPTION_REPORT = 'v:vicr',
  VIEW_MATERIAL_RESOURCE_PLANING_REPORT = 'v:mrpr',
  EXPORT_MATERIAL_RESOURCE_PLANING_REPORT = 'a:emrpr',
  VIEW_SUPPLY_CHAIN_REPORTS_LIST = 'v:vscr',
  SET_DELIVERY_FOOD_MENU = 'a:dfm',
  VIEW_MOVING_COST_REPORT = 'v:mcr',
  VIEW_SUBSCRIPTION_TRANSACTIONS = 'v:stxn',
  VIEW_LOYALTY_POINTS = 'v:vlp',
  CREATE_LOYALTY_POINTS = 'c:clp',
  LIST_APP_COGNITO_USERS = 'v:acu',
  UPDATE_APP_COGNITO_USERS = 'u:acu',
  VIEW_REFERRAL = 'v:ref',
  WITHDRAW_REFERRAL_BALANCE = 'c:refb',
  SYNC_SUBSCRIPTION_BALANCE = 'c:sbal',
  VIEW_BUSINESS_USER_LIST = 'v:bul',
  VIEW_BUSINESS_USER = 'v:bu',
  CREATE_BUSINESS_USER = 'c:bu',
  UPDATE_BUSINESS_USER = 'u:bu',
  VIEW_BUSINESS_DELIVERY_LIST = 'v:bdl',
  VIEW_BUSINESS_DELIVERY = 'v:bd',
  CREATE_BUSINESS_DELIVERY = 'c:bd',
  UPDATE_BUSINESS_DELIVERY = 'u:bd',
  UPDATE_BUSINESS_DELIVERY_ADDRESS = 'u:bda',
  DELETE_BUSINESS_DELIVERY = 'd:bd',
  CREATE_CUSTOMER_COMPLAINT = 'c:cc',
  LIST_CUSTOMER_COMPLAINT = 'v:cc',
  UPDATE_CUSTOMER_COMPLAINT = 'u:cc',
  EXPORT_COMPLAINT_LIST = 'a:ec',
  HEALTH_CONDITIONS_PLAN_MANAGEMENT = 'a:hcpm',
  REFERRAL_PROGRAM = 'v:rfl',
  VIEW_AFFILIATE_PROFILE = 'v:aff',
  CREATE_AFFILIATE_PROFILE = 'c:aff',
  PAY_AFFILIATE_COMMISSION = 'p:aff',
  LIST_GIFTS = 'v:g',
  CREATE_GIFT = 'c:g',
  UPDATE_GIFT = 'u:g',
  DELETE_GIFT = 'd:g',
  EXPORT_GIFTS_LIST = 'a:g',
  VIEW_SUBSCRIPTION_DAY_PROGRESS = 'v:sdp',
  UPDATE_SUBSCRIPTION_DAY_PROGRESS = 'u:sdb',
  EXPORT_BUSINESS_ORDERS = 'a:ebo',
  VIEW_DEBUG_OPTIONS = 'v:do',
  VIEW_CUSTOM_MACROS_DELIVERY_DETAILS = 'v:cmdd',
  UPDATE_EMPLOYEE_PERKS = 'u:ep',
  VIEW_EMPLOYEE_PERKS = 'v:ep',
  EXPORT_EMPLOYEE_PERKS = 'a:ep',
  DELETE_CUSTOM_FOOD = 'd:cf',
  VIEW_KDS_PERMISSIONS_LIST = 'v:kpl',
  CREATE_KDS_PERMISSIONS = 'c:kp',
  DELETE_KDS_PERMISSIONS = 'd:kp',
  UPDATE_KDS_PERMISSIONS = 'u:kp',
  DELETE_FOOD_COMPONENT = 'd:fc',
  DELETE_INGREDIENT = 'd:i',
  VIEW_SUPPLY_CAP_ZONES = 'v:scz',
  CREATE_SUPPLY_CAP_ZONE = 'c:scz',
  UPDATE_SUPPLY_CAP_ZONE = 'u:scz',
  DELETE_SUPPLY_CAP_ZONE = 'd:scz',
  CREATE_RETAIL_MENU_ITEMS = 'c:rmi',
  VIEW_RETAIL_MENU_ITEMS = 'v:rmi',
  UPDATE_RETAIL_MENU_ITEMS = 'u:rmi',
  DELETE_RETAIL_MENU_ITEMS = 'd:rmi',
  CREATE_RETAIL_ORDER = 'c:rm',
  VIEW_RETAIL_ORDER = 'v:rm',
  UPDATE_RETAIL_ORDER = 'u:rm',
  DELETE_RETAIL_ORDER = 'd:rm',
  GET_DOCUMENTS = 'a:gd',
  // AI DASHBOARD
  UPDATE_AI_LOGISTICS = 'u:ail',
  UPDATE_AI_PROMPTS = 'u:aip',
  UPDATE_AI_DISCOUNTS = 'u:aid',
  Update_AI_CONTROLS = 'u:aic'
}

export enum FoodComponentStation {
  hot = 'Hot',
  saucier = 'Saucier',
  bakeryAndPastry = 'Bakery & Pastry',
  commissary = 'Commissary',
  butchery = 'Butchery',
  roasting = 'Roasting',
  gardeManger = 'Garde Manger',
  merchandise = 'Merchandise',
  portioning = 'Portioning'
}

export enum KitchenLogType {
  menu = 'menu',
  food = 'food',
  foodComponent = 'foodComponent',
  ingredient = 'ingredient'
}

export enum AddonAddedAsType {
  meal = 'meal',
  addon = 'addon'
}

export enum FoodComponentStatus {
  IDEATION = 'Ideation',
  RD_TESTING = 'R&D Testing',
  OPS_TESTING = 'Ops Testing',
  RD_APPROVED = 'R&D Approved',
  APPROVED_SCALED_RECIPE = 'Approved Scaled Recipe',
  NOT_BEING_USED = 'Not Being Used'
}

export enum DeliveryTicketType {
  MEAL = 'meal',
  ADDRESS = 'address',
  CANCEL = 'cancel',
  MANUAL = 'manual',
  OTHER = 'other'
}

export enum DeliveryTicketStatus {
  PENDING = 'pending',
  APPROVED = 'approved',
  REJECTED = 'rejected'
}

export enum MovingCostReportInterval {
  daily = 'daily',
  weekly = 'weekly',
  monthly = 'monthly',
  quarterly = 'quarterly',
  yearly = 'yearly'
}

/* -------------------------- Food Tags [Start] -------------------------- */
export enum FoodTagsKeys {
  protein = 'protein',
  base = 'base',
  rating = 'rating',
  taste = 'taste',
  snacktype = 'snacktype',
  addonCategory = 'addonCategory',
  category = 'category',
  cuisine = 'cuisine',
  flavorsProfile = 'flavorsProfile',
  hintFlavors = 'hintFlavors',
  texture = 'texture',
  eatingExperience = 'eatingExperience',
  spicyLevel = 'spicyLevel',
  convenience = 'convenience'
}

export enum ProteinFoodTags {
  beef = 'beef',
  chicken = 'chicken',
  lamb = 'lamb',
  seafood = 'seafood',
  egg = 'egg',
  turkey = 'turkey'
}

export enum BaseFoodTags {
  rice = 'rice',
  noodles = 'noodles',
  lettuce = 'lettuce',
  egg = 'egg',
  pasta = 'pasta',
  sandwich = 'sandwich',
  oats = 'oats ',
  pancake = 'pancake',
  parfait = 'parfait',
  salad = 'salad'
}

export enum RatedFoodTags {
  top = 'top'
}

export enum NewRatedFoodTags {
  top_rated = 'top_rated',
  seasonal = 'seasonal',
  improved = 'improved',
  summer = 'summer'
}

export enum TasteFoodTags {
  savoury = 'savoury',
  sweet = 'sweet',
  spicy = 'spicy'
}

export enum SnackTypeTags {
  soup = 'soup',
  minisalad = 'minisalad'
}

export enum FoodCategory {
  bites = 'bites',
  wraps = 'wraps',
  stuffedDates = 'stuffedDates',
  sliders = 'sliders',
  soup = 'soup',
  quesadillas = 'quesadillas',
  minisalad = 'minisalad'
}

export enum FoodMenuLabelsTags {
  NEW = 'NEW',
  UPGRADED = 'UPGRADED',
  TOP_RATED = 'TOP_RATED',
  SEASONAL = 'SEASONAL',
  SUMMER = 'SUMMER',
  RAMADAN = 'RAMADAN'
}
/* -------------------------- Food Tags [End] -------------------------- */

export enum GroupGiftExportType {
  allergies = 'allergies',
  gifts = 'gifts'
}

/* -------------------------- Coupon -------------------------- */
export enum CouponRuleType {
  USAGE_LIMIT = 'USAGE_LIMIT',
  USAGE_PER_USER = 'USAGE_PER_USER',
  USER_ACTION = 'USER_ACTION',
  USER_COUNTRY = 'USER_COUNTRY',
  SUBSCRIPTION_FREQUENCY = 'SUBSCRIPTION_FREQUENCY',
  SUBSCRIPTION_PLAN = 'SUBSCRIPTION_PLAN',
  SUBSCRIPTION_TIER = 'SUBSCRIPTION_TIER',
  EMAIL_EXISTS = 'EMAIL_EXISTS',
  PHONE_EXISTS = 'PHONE_EXISTS',
  EMAIL_DOMAIN = 'EMAIL_DOMAIN',
  CREDIT_CARD_PREFIX = 'CREDIT_CARD_PREFIX',
  DELIVERY_PER_WEEK = 'DELIVERY_PER_WEEK',
  USER_KITCHEN = 'USER_KITCHEN',
  ADDON_SUBSCRIPTION_CATEGORY = 'ADDON_SUBSCRIPTION_CATEGORY'
}

export enum CouponCategory {
  promo = 'promo',
  referral = 'referral',
  reward = 'reward',
  affiliate = 'affiliate',
  gift_card = 'gift_card'
}

export enum CouponMode {
  CREDIT = 'CREDIT',
  DISCOUNT = 'DISCOUNT',
  POINT = 'POINT',
  TRACKER = 'TRACKER'
}

export enum CouponStatus {
  valid = 'valid',
  invalid = 'invalid',
  notDetermined = 'notDetermined'
}

export enum CouponAdditionalFailureReason {
  BRAND = 'BRAND',
  EXPIRED = 'EXPIRED',
  CLUB_DISCOUNT_CAP = 'CLUB_DISCOUNT_CAP'
}

export enum FoodStatus {
  draft = 'draft',
  pending = 'pending',
  approved = 'approved',
  awaitingNPDApproval = 'awaitingNPDApproval',
  awaitingOpsApproval = 'awaitingOpsApproval'
}

export enum ProcessingStage {
  draft = FoodStatus.draft,
  pending = FoodStatus.pending,
  approved = FoodStatus.approved,
  awaitingNPDApproval = FoodStatus.awaitingNPDApproval,
  awaitingOpsApproval = FoodStatus.awaitingOpsApproval
}

export enum CancelCoachingSessionRequestType {
  refund = 'refund',
  cancel = 'cancel'
}

export enum CoachingSessionStatus {
  upcoming = 'upcoming',
  cancelled = 'cancelled',
  completed = 'completed'
}

export enum CoachingRefundRequestStatus {
  pending = 'pending',
  processed = 'processed'
}

export enum SyncFoodComponentAttributes {
  name = 'name',
  weight = 'weight',
  cookedRawFactor = 'cookedRawFactor',
  ingredients = 'ingredients',
  method = 'method',
  macros = 'macros',
  micronutrients = 'micronutrients',
  measurementUnit = 'measurementUnit',
  cookingStation = 'cookingStation',
  cups = 'cups',
  image = 'image'
}

export enum TicketStatus {
  review = 'review',
  pending = 'pending',
  approved = 'approved',
  rejected = 'rejected'
}

export enum AffiliateTransactionType {
  payout = 'payout',
  redeemed = 'redeemed'
}

export enum AffiliateDiscountCodeAmount {
  fivePercent = 5,
  tenPercent = 10,
  fifteenPercent = 15,
  twentyPercent = 20
}

export enum AffiliateCommissionPercentage {
  fivePercent = 5,
  tenPercent = 10,
  fifteenPercent = 15,
  twentyPercent = 20
}

export enum AffiliatePayoutTransactionStatus {
  pending = 'pending',
  approved = 'approved'
}

export enum CompensationScheme {
  subscriptionCredit = 'Subscription Credit',
  payout = 'Payout'
}

export enum RequestItemType {
  meal = 'meal',
  flowers = 'flowers',
  cake = 'cake',
  merch = 'merch',
  other = 'other'
}

export enum GiftItemType {
  meal = 'meal',
  flowers = 'flowers',
  cake = 'cake',
  merch = 'merch',
  other = 'other'
}

export enum CodeChannel {
  INFLUENCER = 'INFLUENCER',
  PARTNERSHIP = 'PARTNERSHIP',
  CALO_PROMOS = 'CALO_PROMOS',
  OFFLINE = 'OFFLINE',
  CX = 'CX',
  OTHER = 'OTHER'
}

export enum CodeChannelCategory {
  CX_INFLUENCER = 'CX_INFLUENCER'
}

export enum BackLabelsConfigurationLanguage {
  EN = 'EN',
  AR = 'AR'
}

export enum BackLabelsConfigurationType {
  Meal = 'Meal',
  Sandwitch = 'Sandwich',
  Snack = 'Snack'
}

export enum BackLabelsConfigurationHeatingInstructions {
  Hot = 'Hot',
  Cold = 'Cold'
}

export enum BackLabelsConfigurationTemplate {
  Subscription = 'Subscription',
  Business = 'Business'
}

export enum BackLabelsConfigurationShelfLife {
  Custom = 'Custom'
}

export enum PrototypeAction {
  create = 'create',
  comment = 'comment',
  pend = 'pend',
  approve = 'approve'
}

export enum KDSPermission {
  VIEW_COMPONENTS_CHECK = 'v:cc',
  VIEW_PORTIONING_DISPLAY = 'v:pd',
  VIEW_MEALS_STATUS = 'v:ms',
  VIEW_WASTAGE_TRACKING = 'v:wt',
  VIEW_QUALITY_TRACKING = 'v:qt',
  VIEW_SHORTAGE_REPORTING = 'v:sr',
  VIEW_SHORTAGE_DISPLAY = 'v:sd',
  VIEW_ASSEMBLY_CHECK = 'v:ac',
  VIEW_PRE_DISPATCH_CHECK = 'v:pdc',
  VIEW_RECIPE_GUIDEBOOK = 'v:rg',
  VIEW_LOCK_TIME_CHANGES = 'v:ltc',
  VIEW_LOGISTICS_CHANGES = 'v:lc',
  VIEW_DRIVER_DISPATCH = 'v:dd',
  VIEW_CUSTOMER_COMPLAINTS = 'v:ccm',
  VIEW_FILE_MANAGEMENT = 'v:fm',
  VIEW_SPECIAL_REQUEST = 'v:srq',
  VIEW_QUALITY_METRICS = 'v:qm',
  VIEW_PRODUCTIVITY_METRICS = 'v:pm',
  VIEW_LEFTOVER_METRICS = 'v:lm'
}
