import { forwardRef } from 'react';

import { Permission, UpdateFoodReq } from '@calo/dashboard-types';
import { FoodDietType } from '@calo/types';
import { Box, Divider, Stack, Typography } from '@mui/material';

import { caloTheme } from 'assets/images/theme/calo';
import NewFoodComponentPicker from 'components/MUI/NewFoodComponentPicker';
import { useUserRoles } from 'lib/hooks';
import { FoodComponent } from 'lib/interfaces';
import { keyBy } from 'lodash-es';
import { getOrderedComponents } from './helpers';

interface ComponentsProps {
  name: string;
  foodComponents: FoodComponent[];
  isDisabled: boolean;
  values: UpdateFoodReq;
  isLoading: boolean;
  handleComponentsChange: (value: any) => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  setName: (value: string) => void;
}

const Components = forwardRef<HTMLDivElement, ComponentsProps>(
  (
    { values, isDisabled, foodComponents, isLoading, setFieldValue, handleComponentsChange, setName, name }: ComponentsProps,
    ref
  ) => {
    const roles = useUserRoles();

    const keyedComponents = keyBy(values.components, 'id');

    return (
      <>
        {roles.includes(Permission.VIEW_FOOD_COMPONENTS) && (
          <Stack
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            ref={ref}
            sx={{ backgroundColor: caloTheme.palette.white, borderRadius: '8px', boxShadow: 2 }}
          >
            <Typography
              sx={{
                fontSize: '24px',
                fontWeight: 400,
                lineHeight: '150%',
                padding: '16px'
              }}
            >
              Components
            </Typography>
            <Divider sx={{ color: caloTheme.palette.divider, height: '1px', width: '100%' }} />
            <Box sx={{ padding: '24px 16px 16px 16px', width: '100%' }}>
              <NewFoodComponentPicker
                name={name}
                setName={setName}
                isChild={false}
                isLoading={isLoading}
                searchComponentList={foodComponents}
                isDisabled={isDisabled}
                selectedComponents={getOrderedComponents(values.components!, (values.components as any) ?? [])}
                allComponents={keyedComponents as any}
                setFieldValue={setFieldValue}
                onChange={handleComponentsChange}
                isPreBuildCustom={Boolean(values.tags?.includes(FoodDietType.preBuiltCustom))}
              />
            </Box>
          </Stack>
        )}
      </>
    );
  }
);

export default Components;
