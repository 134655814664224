import { ModalRef } from 'components/Modal';
import { format, getWeek } from 'date-fns';
import useDuplicateMenu from 'hooks/useDuplicateMenu';
import { getWeekDates } from 'lib/helpers';
import { Menu, MenuFood } from 'lib/interfaces';
import { useRef, useState } from 'react';
import { MenuListFilters } from 'views/Menu/MainMenu/types';
import { AddMealsButton, DuplicateWeeklyMenuCalendarPopup } from '../..';

import AddMealsByFilterPopup from 'components/AddMealsByFilterPopup';
import Popup from 'components/Popup';
import useFoodListByFilters from 'hooks/useFoodListByFilters';

interface AddMealsOptionsProps {
  filters: MenuListFilters;
  disabled?: boolean;
  foodToReplace?: { food: MenuFood };
  handleReplaceFood: any;
  weeklyMenuList: Menu[];
  handleFoodChanges: (meals: MenuFood[], day: Date) => void;
  setFoodToReplace: (value?: { food: MenuFood }) => void;
  updateWeeklyMenuList: (newMenuList: Menu[]) => void;
}

const AddMealsOptions = ({
  filters,
  disabled,
  foodToReplace,
  weeklyMenuList,
  setFoodToReplace,
  handleFoodChanges,
  updateWeeklyMenuList,
  handleReplaceFood
}: AddMealsOptionsProps) => {
  const addMealsByFiltersRef = useRef<ModalRef>();
  const duplicateCalendarRef = useRef<ModalRef>();

  const [selectedDateFoodIds, setSelectedDateFoodIds] = useState<string[]>([]);
  const [duplicateWeek, setDuplicateWeek] = useState(getWeek(new Date()));
  // setDuplicateYear to be passed to DuplicateWeeklyMenuCalendarPopup

  const [filtersOn, setFiltersOn] = useState<boolean>(false);
  const [selectedMenuFilters, setSelectedMenuFilters] = useState({
    type: '',
    plan: '',
    protein: '',
    taste: '',
    sandwich: undefined,
    lastUsed: '',
    base: '',
    category: '',
    mealName: '',
    menuLabel: ''
  });

  const {
    duplicateWeeklyMenu,
    isLoading: duplicateMenuLoading,
    isSuccess
  } = useDuplicateMenu(getWeekDates(filters.selectedWeek, filters.selectedYear), filters, updateWeeklyMenuList);

  const { foodListFiltersData, foodFiltersLoading, hasNextPage, fetchNextPage, remove, isLoadingFetchNextPage } =
    useFoodListByFilters({
      selectedMenuFilters,
      country: filters.country,
      kitchen: filters.kitchen,
      brand: filters.brand,
      filtersOn
    });

  const handleClosePopup = () => {
    remove();
    setFoodToReplace(undefined);
    setSelectedMenuFilters({
      type: '',
      plan: '',
      protein: '',
      taste: '',
      sandwich: undefined,
      lastUsed: '',
      base: '',
      category: '',
      mealName: '',
      menuLabel: ''
    });
    setFiltersOn(false);
    addMealsByFiltersRef.current?.close();
  };

  const onDateSelected = (day: Date) => {
    const foodIds = weeklyMenuList.find((menu) => format(day, 'yyyy-MM-dd') === menu.day)?.food.map((f) => f.id) ?? [];
    setSelectedDateFoodIds(foodIds);
  };

  return (
    <>
      <AddMealsButton
        disabled={disabled}
        addMealsByFiltersHandler={() => {
          setFiltersOn(true);
          addMealsByFiltersRef.current?.open();
        }}
        duplicateMenuHandler={() => duplicateCalendarRef.current?.open()}
      />
      <DuplicateWeeklyMenuCalendarPopup
        duplicateCalendarRef={duplicateCalendarRef}
        duplicateWeek={duplicateWeek}
        isSuccess={isSuccess}
        hasMenu={weeklyMenuList.flatMap((menu) => menu.food).length !== 0}
        duplicateMenuLoading={duplicateMenuLoading}
        setDuplicateWeek={setDuplicateWeek}
        duplicateMenuHandler={duplicateWeeklyMenu}
      />
      <Popup maxWidth="lg" ref={addMealsByFiltersRef} onClose={handleClosePopup}>
        <AddMealsByFilterPopup
          values={filters}
          filtersOn={filtersOn}
          foodToReplace={foodToReplace}
          isLoading={foodFiltersLoading}
          hasNextPage={hasNextPage || false}
          selectedWeek={filters.selectedWeek}
          selectedYear={filters.selectedYear}
          handleReplaceFood={handleReplaceFood}
          foodIds={selectedDateFoodIds}
          selectedMenuFilters={selectedMenuFilters}
          addMealsByFiltersRef={addMealsByFiltersRef}
          isFetchingNextPage={isLoadingFetchNextPage}
          foodListFiltersData={foodListFiltersData || []}
          fetchNextPage={fetchNextPage}
          onDateSelected={onDateSelected}
          handleClosePopup={handleClosePopup}
          setSelectedMenuFilters={setSelectedMenuFilters}
          handleFoodChanges={(food, selectedDate) => handleFoodChanges(food, selectedDate!)}
        />
      </Popup>
    </>
  );
};

export default AddMealsOptions;
