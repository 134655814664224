import { caloThemeV2 } from 'assets/themev2';

export const styles = {
  container: (marginTop: string | undefined, marginBottom: string | undefined, marginHorizontal: string | undefined) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: caloThemeV2.palette.green50,
    borderRadius: '8px',
    paddingY: '21px',
    paddingX: '16px',
    marginTop: marginTop || '-4px',
    marginBottom: marginBottom || '10px',
    marginHorizontal: marginHorizontal || '0px'
  }),
  text: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '150%'
  },
  icon: {
    alignSelf: 'flex-start',
    padding: '0px'
  }
};
