import { Subscription } from '@calo/types';
import { Box, Card, Stack } from '@mui/material';

import { caloTheme } from 'assets/images/theme/calo';
import { AddonSubscription } from './AddonSubscription';
import CustomDeliveryTime from './CustomDeliveryTime';
import DeliveryAddressCard from './DeliveryAddressCard';
import DeliveryDaysCard from './DeliveryDaysCard';
import DeliveryPreferences from './DeliveryPreferencesCard';
import MealSizePrefrenceCard from './MealSizePrefrencesCard';
import SubscriptionDatesPlan from './SubscriptionDatesPlan';
import SubscriptionPlanDetails from './SubscriptionPlanDetails';
import GymMembershipCard from './GymMembershipCard';

interface PlanCardProps {
  setSelectedDate: (value: Date) => void;
  subscription: Subscription & { remainingDays: number; expectedLastDeliveryDay: string; isClubMember: boolean };
  refetchAllDeliveries: () => void;
  refetchSubscription?: () => void;
}

const PlanCard = ({ subscription, setSelectedDate, refetchAllDeliveries, refetchSubscription }: PlanCardProps) => {
  //Plan
  return (
    <>
      <Card variant="outlined" sx={{ width: '100%', border: 'none', borderRadius: '8px', mb: '14px' }}>
        <SubscriptionPlanDetails subscription={subscription} refetchSubscription={refetchSubscription} />
        <SubscriptionDatesPlan values={subscription} setSelectedDate={setSelectedDate} />
      </Card>

      <Box
        sx={{
          display: 'flex',
          width: '100%',
          direction: 'row',
          [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
            flexDirection: 'column',
            width: '100%'
          }
        }}
      >
        <Stack
          sx={{
            display: 'flex',
            width: '40%',
            direction: 'row',
            [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
              flexDirection: 'column',
              width: '100%'
            }
          }}
        >
          <Card
            variant="outlined"
            sx={{
              mr: 2,
              border: 'none',
              mb: '14px',
              borderRadius: '8px',
              [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                width: '100%',
                mr: 0
              }
            }}
          >
            <DeliveryDaysCard subscription={subscription} />
          </Card>

          <Card
            variant="outlined"
            sx={{
              mr: 2,
              border: 'none',
              borderRadius: '8px',
              [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                width: '100%',
                mr: 0,
                mb: '14px'
              }
            }}
          >
            <CustomDeliveryTime subscription={subscription} refetchAllDeliveries={refetchAllDeliveries} />
          </Card>
          <Card
            variant="outlined"
            sx={{
              mr: 2,
              border: 'none',
              mb: '14px',
              borderRadius: '8px',
              [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                width: '100%',
                mr: 0
              }
            }}
          >
            <MealSizePrefrenceCard subscription={subscription} />
          </Card>
        </Stack>
        <Stack flexDirection={'column'} width={'100%'}>
          <Card
            variant="outlined"
            sx={{
              border: 'none',
              width: '100%',
              borderRadius: '8px',
              [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                width: 'auto'
              }
            }}
          >
            <DeliveryAddressCard subscription={subscription} />
          </Card>
          <Card
            variant="outlined"
            sx={{
              border: 'none',
              marginTop: '20px',
              width: '100%',
              borderRadius: '8px',
              [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                width: 'auto'
              }
            }}
          >
            <AddonSubscription subscription={subscription} refetchSubscription={refetchSubscription} />
          </Card>
          <Card
            variant="outlined"
            sx={{
              border: 'none',
              marginTop: '20px',
              width: '100%',
              borderRadius: '8px',
              [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                width: 'auto'
              }
            }}
          >
            <DeliveryPreferences subscription={subscription} />
          </Card>
          <Card
            variant="outlined"
            sx={{
              border: 'none',
              marginTop: '20px',
              width: '100%',
              borderRadius: '8px',
              [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                width: 'auto'
              }
            }}
          >
            <GymMembershipCard subscription={subscription} />
          </Card>
        </Stack>
      </Box>
    </>
  );
};
export default PlanCard;
