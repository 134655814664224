import { Delete } from '@mui/icons-material';
import { IconButton, Stack, Typography } from '@mui/material';
import { caloTheme } from 'assets/images/theme/calo';
import { format } from 'date-fns/fp';
import { Note } from 'lib/interfaces';

export interface CateringOrderNoteProps {
  note: Note;
  deleteNote: (id: string) => void;
  actionLoading: boolean;
}

export default function CateringOrderNote({
  note: { id, note, createdAt, name },
  deleteNote,
  actionLoading
}: CateringOrderNoteProps) {
  return (
    <Stack
      sx={{
        p: 2,
        borderRadius: 1,
        border: `1px solid ${caloTheme.palette.grey[300]}`,
        backgroundColor: caloTheme.palette.grey[50]
      }}
    >
      <Stack direction={'row'} columnGap={'4px'} justifyContent={'space-between'}>
        <Typography
          sx={{
            fontSize: '16px',
            fontWeight: 600
          }}
        >
          {name}
        </Typography>
        <Typography
          sx={{
            fontSize: '14px',
            fontWeight: 500
          }}
        >
          {format('dd/MM/yyyy, HH:mm 	aa')(new Date(createdAt))}
        </Typography>
      </Stack>
      <Stack direction={'row'} columnGap={'4px'} alignItems={'center'} justifyContent={'space-between'}>
        <Typography
          sx={{
            fontSize: '16px',
            fontWeight: 400
          }}
        >
          {note}
        </Typography>
        <IconButton disabled={actionLoading} onClick={() => deleteNote(id)}>
          <Delete color="error" />
        </IconButton>
      </Stack>
    </Stack>
  );
}
