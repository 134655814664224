import { FoodComponent } from '@calo/types';
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField
} from '@mui/material';
import { handleSearch } from 'lib/helpers/handleSearch';
import React, { useCallback } from 'react';
import SelectMUI from '../MUI/SelectMUI';

interface ModifierComponentsProps {
  modifier: any;
  index: number;
  componentOptions: any[];
  isLoadingFoodComponents: boolean;
  foodComponentList: any;
  currentRetailItem: any;
  setCurrentRetailItem: React.Dispatch<React.SetStateAction<any>>;
  handleComponentOptionsChange: (selectedOptions: any, modifier: any, action: any, quantity: any) => void;
  setCurrentComponentName: React.Dispatch<React.SetStateAction<string>>;
  setCurrentModifierComponent: React.Dispatch<React.SetStateAction<any>>;
  currentModifierComponent: any;
}

const ModifierComponents: React.FC<ModifierComponentsProps> = ({
  modifier,
  index,
  componentOptions,
  isLoadingFoodComponents,
  foodComponentList,
  currentRetailItem,
  setCurrentRetailItem,
  handleComponentOptionsChange,
  setCurrentComponentName,
  setCurrentModifierComponent,
  currentModifierComponent
}) => {
  const handleSourceComponentChange = useCallback(
    (e) => {
      const updatedModifiers =
        currentRetailItem?.foodModifiers?.map((mod, modIndex) =>
          modIndex === index ? { ...mod, sourceComponentID: e.target.value } : mod
        ) || [];
      setCurrentRetailItem({ ...currentRetailItem, foodModifiers: updatedModifiers });
    },
    [currentRetailItem, index, setCurrentRetailItem]
  );

  const handleDeleteComponent = (componentId: string) => {
    const updatedComponents = modifier.componentOptions.filter((option) => option.id !== componentId);
    setCurrentRetailItem({
      ...currentRetailItem,
      foodModifiers: currentRetailItem.foodModifiers.map((mod, modIndex) =>
        modIndex === index ? { ...mod, componentOptions: updatedComponents } : mod
      )
    });
  };

  return (
    <>
      {modifier.type === 'REPLACE' && !!foodComponentList && (
        <div className="field mt-3">
          <FormControl fullWidth variant="outlined">
            <InputLabel>Source Component ID</InputLabel>
            <Select
              value={modifier.sourceComponentID || ''}
              onChange={handleSourceComponentChange}
              label="Source Component ID"
              disabled={isLoadingFoodComponents}
            >
              {foodComponentList?.data?.map((comp) => (
                <MenuItem key={comp.id} value={comp.id}>
                  {comp.name?.en || ''}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      )}

      {!!modifier.type && (
        <>
          <TableContainer className="my-3 px-4">
            <Table>
              <TableHead sx={{ backgroundColor: '#FAFAFA' }}>
                <TableRow>
                  <TableCell sx={{ fontWeight: 'bold', borderBottom: 'none' }}>Component Name</TableCell>
                  <TableCell sx={{ fontWeight: 'bold', borderBottom: 'none' }}>Quantity (g)</TableCell>
                  <TableCell sx={{ fontWeight: 'bold', borderBottom: 'none' }}>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {modifier?.componentOptions
                  ?.filter((option): option is FoodComponent => typeof option !== 'string')
                  .map((option) => (
                    <TableRow key={option.id}>
                      <TableCell>{option.name?.en || ''}</TableCell>
                      <TableCell>{option.quantity || 1}</TableCell>
                      <TableCell>
                        <Button variant="text" color="error" onClick={() => handleDeleteComponent(option.id)}>
                          <i className="fas fa-trash"></i>
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <div className="field mt-3 flex flex-row space-x-4 mx-4">
            <FormControl variant="outlined" sx={{ flex: 1 }}>
              <SelectMUI
                data-test="selectComponentSelector"
                isMulti
                placeholder={currentModifierComponent.modifier?.label}
                id="componentInputSelector"
                options={componentOptions}
                onInputChange={(data: any, action: any) => {
                  handleSearch({
                    text: data,
                    action,
                    name: setCurrentComponentName
                  });
                }}
                className="leading-10 flex-1"
                isLoading={isLoadingFoodComponents}
                onChange={(selectedOptions) => {
                  setCurrentModifierComponent({ modifier: selectedOptions![0], quantity: currentModifierComponent?.quantity });
                }}
              />
            </FormControl>
            <TextField
              type="number"
              placeholder="Quantity (g)"
              value={currentModifierComponent.quantity}
              sx={{ flex: 1 }}
              onChange={(e) => {
                const quantity = parseInt(e.target.value);
                setCurrentModifierComponent({ modifier: currentModifierComponent.modifier, quantity });
              }}
            />
          </div>
          <Box sx={{ display: 'flex', alignItems: 'flex-start', pt: 1, borderTop: '1px solid #E0E0E0', mt: '8px' }}>
            <Button
              onClick={() =>
                handleComponentOptionsChange(
                  [currentModifierComponent.modifier],
                  modifier,
                  { action: 'select-option' },
                  currentModifierComponent.quantity
                )
              }
              disabled={!currentModifierComponent.modifier}
              variant="text"
              color="inherit"
              startIcon={<i className="fas fa-plus" style={{ fontSize: '16px' }}></i>}
              sx={{ textTransform: 'none', fontWeight: 'bold', fontSize: '12px', mb: 2 }}
            >
              ADD COMPONENT
            </Button>
          </Box>
        </>
      )}
    </>
  );
};

export default ModifierComponents;
