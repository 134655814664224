import { Country, Kitchen } from '@calo/types';
import { Card, MenuItem, Stack, TextField } from '@mui/material-v6';
import { MenuPresentationType } from 'lib/enums';
import { getAccessibleCountries } from 'lib/helpers';
import { useUserKitchens } from 'lib/hooks';
import { startCase } from 'lodash-es';
import { DailyMenuDatePicker, WeeklyMenusDatePicker } from '../../components';
import { styles } from './styles';

type FilterCriteriaCardProps = {
  country: Country;
  kitchen: Kitchen;
  disabled?: boolean;
  setCountry: (country: Country) => void;
  setKitchen: (kitchen: Kitchen) => void;
} & (
  | {
      menuPresentation: MenuPresentationType.weekly;
      selectedWeek: number;
      setSelectedWeek: (newWeek: number) => void;
      setSelectedYear: (newYear: number) => void;
    }
  | {
      day: string;
      menuPresentation: MenuPresentationType.daily;
      setDay: (day: Date) => void;
    }
);

const FilterCriteriaCard = ({ country, kitchen, disabled, setCountry, setKitchen, ...props }: FilterCriteriaCardProps) => {
  const userKitchens: Kitchen[] = useUserKitchens();

  return (
    <Card
      variant="outlined"
      sx={{
        width: 'full',
        border: 'none',
        marginBottom: '16px',
        borderRadius: '8px',
        paddingBottom: '4px',
        marginTop: 4,
        padding: 2
      }}
    >
      <Stack sx={{ flexDirection: 'row', gap: 2 }}>
        <TextField
          select
          label="Country"
          disabled={disabled}
          placeholder="Select Country"
          value={country}
          sx={styles.textField}
          onChange={(data: any) => {
            setCountry(data.target.value);
          }}
        >
          {getAccessibleCountries(userKitchens).map((country) => (
            <MenuItem key={country} value={country}>
              {startCase(country)}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          select
          label="Kitchen"
          value={kitchen}
          disabled={disabled}
          sx={styles.textField}
          placeholder="Select Kitchen"
          onChange={(data: any) => setKitchen(data.target.value)}
        >
          {userKitchens
            .filter((kitchen) => kitchen.includes(country || Country.BH))
            .map((kitchen) => (
              <MenuItem key={kitchen} value={kitchen}>
                {kitchen}
              </MenuItem>
            ))}
        </TextField>
        {props.menuPresentation === MenuPresentationType.daily && (
          <DailyMenuDatePicker selectedDay={props.day} setSelectedDay={props.setDay} sx={styles.textField} disabled={disabled} />
        )}
        {props.menuPresentation === MenuPresentationType.weekly && (
          <WeeklyMenusDatePicker
            sx={styles.textField}
            disabled={disabled}
            selectedWeek={props.selectedWeek}
            setSelectedWeek={props.setSelectedWeek}
            setSelectedYear={props.setSelectedYear}
          />
        )}
      </Stack>
    </Card>
  );
};

export default FilterCriteriaCard;
