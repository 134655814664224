import { Tab, Tabs } from '@mui/material';

import { caloTheme } from 'assets/images/theme/calo';
import { MealTabs } from 'lib/enums';

const styles = {
  tabLabel: {
    color: caloTheme.palette.neutral600,
    textTransform: 'uppercase',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '150%'
  }
};

interface TabsComponentProps {
  tab: number;
  handleTabChange: (event: React.SyntheticEvent, newValue: number) => void;
  tabLabels: MealTabs[];
}

const TabsComponent = ({ tab, handleTabChange, tabLabels }: TabsComponentProps) => {
  return (
    <Tabs value={tab} onChange={handleTabChange} sx={{ borderBottom: '1px solid ' + caloTheme.palette.neutral50, flexGrow: 1 }}>
      {tabLabels.map((label) => (
        <Tab key={label} label={label} sx={styles.tabLabel} />
      ))}
      <Tab disabled />
    </Tabs>
  );
};

export default TabsComponent;
