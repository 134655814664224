import { Brand, Country, FoodComponentType, Kitchen } from '@calo/types';
import { Icon as Iconify } from '@iconify/react';
import { Box, Card, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { getListWithParamsPost } from 'actions';
import { caloTheme } from 'assets/images/theme/calo';
import { renderIngredients } from 'lib/helpers';
import { CustomComponentsByCategory, Delivery, FoodComponent, MenuFood } from 'lib/interfaces';
import { compact, isUndefined, uniq, uniqBy } from 'lodash-es';
import { useCallback, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import CaloLoader from '../CaloLoader';
import ComponentTotalInformationSection from '../ComponentTotalInformationSection';
import MenuFoodDietTags from '../MenuFoodDietTags';
import ModifyMealTotalInformationSection from '../ModifyMealTotalInformationSection';

interface ModifyMealPopupProps {
  selectedMeal: MenuFood & { positionIndex: number };
  selectedDelivery: Delivery | undefined;
  customFoodComponents: FoodComponent[];
  setCustomFoodComponents: React.Dispatch<React.SetStateAction<FoodComponent[]>>;
  selectedSwappedComponent: {
    oldId: string;
    newId: string;
  }[];
  setSelectedSwappedComponent: React.Dispatch<
    React.SetStateAction<
      {
        oldId: string;
        newId: string;
      }[]
    >
  >;
  setSelectedComponent: (value: React.SetStateAction<FoodComponent | undefined>) => void;
  selectedComponent: FoodComponent | undefined;
  selectedRemovedComponentIdList: string[];
  setSelectedRemovedComponentIdList: React.Dispatch<React.SetStateAction<string[]>>;
  allCustomFoodComponents: CustomComponentsByCategory;
  isPreBuiltMeal: boolean;
  allFoodComponentsData: any[];
  caloKidsComponent: FoodComponent[];
  isCaloKidsMeal: boolean;
}

const ModifyMealPopup = ({
  selectedMeal,
  selectedDelivery,
  caloKidsComponent,
  isCaloKidsMeal,
  customFoodComponents,
  setCustomFoodComponents,
  selectedSwappedComponent,
  setSelectedSwappedComponent,
  selectedComponent,
  setSelectedComponent,
  selectedRemovedComponentIdList,
  setSelectedRemovedComponentIdList,
  allCustomFoodComponents,
  isPreBuiltMeal,
  allFoodComponentsData
}: ModifyMealPopupProps) => {
  const componentsIds = selectedMeal?.components?.map((component) => component.id);
  const [selectedComponentIndex, setSelectedComponentIndex] = useState<number | undefined>(undefined);
  const [foodComponentsData, setFoodComponentsData] = useState<FoodComponent[] | undefined>(undefined);

  useEffect(() => {
    setSelectedComponentIndex(undefined);
  }, [selectedMeal, selectedDelivery]);

  const { data: foodCompData, isLoading: LoadingFoodComponent } = useQuery<any, Error, { data: any[] }>(
    [
      'food-components/list',
      {
        limit: selectedMeal?.components?.length,
        filters: {
          withChildComponents: false,
          ids: componentsIds,
          country: selectedDelivery?.country || Country.BH,
          brand: Brand.CALO,
          kitchen: selectedDelivery?.kitchen || Kitchen.BH1
        }
      }
    ],
    getListWithParamsPost,
    {
      retry: false,
      keepPreviousData: true,
      enabled: !!selectedMeal
    }
  );

  useEffect(() => {
    const updatedComponents = [...customFoodComponents];
    setCustomFoodComponents(uniqBy(updatedComponents, 'name.en'));
  }, [selectedSwappedComponent, selectedComponent, selectedRemovedComponentIdList]);

  useEffect(() => {
    if (!selectedSwappedComponent) return;
    if (!LoadingFoodComponent) {
      const swappedComponentIds = selectedSwappedComponent.map((comp) => comp.oldId);
      const updatedComponents = foodCompData?.data?.map((item) => {
        if (swappedComponentIds.includes(item.id) && !selectedRemovedComponentIdList.includes(item.id)) {
          const swappedAction = selectedSwappedComponent.find((comp) => comp.oldId === item.id);
          const customComp = allCustomFoodComponents.meal.find((custom) => custom.id === swappedAction?.newId);
          return customComp;
        } else {
          return item;
        }
      });
      setFoodComponentsData(compact(updatedComponents));
    }
  }, [selectedSwappedComponent, selectedRemovedComponentIdList, allCustomFoodComponents, foodCompData]);

  const handleRemove = (componentId: string) => {
    if (selectedRemovedComponentIdList.includes(componentId)) {
      const filteredRemovedIds = selectedRemovedComponentIdList.filter((id) => id !== componentId);
      setSelectedRemovedComponentIdList(uniq(filteredRemovedIds));
    } else {
      setSelectedRemovedComponentIdList(uniq([...selectedRemovedComponentIdList, componentId]));
    }
  };

  const handleRemoveComponents = useCallback(
    (componentId: string, index: number) => {
      if (index === undefined) return;
      const originalComponents = foodCompData?.data.map((component) => component.id);
      const selectedComponent = foodCompData?.data[index];
      const swappedAction = selectedSwappedComponent.find(
        (com) => componentId === com.newId && com.oldId === selectedComponent?.id
      );
      if (selectedComponent?.id !== componentId && swappedAction) {
        const updatedSwappedComponents = selectedSwappedComponent.filter(
          (item) => item.newId !== swappedAction.newId && selectedComponent?.id === swappedAction.oldId
        );
        handleRemove(swappedAction.oldId);
        setSelectedSwappedComponent(updatedSwappedComponents);
        const customData = foodCompData?.data.find((component) => component.id === swappedAction.oldId);
        setSelectedComponent(customData);
      } else if (originalComponents?.includes(componentId) && selectedComponent?.id === componentId) {
        if (selectedSwappedComponent.some((component) => component.oldId === componentId)) {
          const updatedSwappedComponents = selectedSwappedComponent.filter((item) => item.oldId !== componentId);
          setSelectedSwappedComponent(updatedSwappedComponents);
          const customData = foodCompData?.data.find((component, i) => component.id === componentId && i === index);
          setSelectedComponent(customData);
        }
        handleRemove(componentId);
      }
    },
    [selectedSwappedComponent, selectedRemovedComponentIdList, selectedComponentIndex, foodCompData]
  );

  const handleSwapComponent = useCallback(
    (oldId: string, newId: string) => {
      if (selectedComponentIndex === undefined) return;
      const currentComponentData = foodCompData?.data[selectedComponentIndex];
      const existingSwapped = selectedSwappedComponent.some(
        (swapped) => swapped.oldId === oldId && swapped.newId === newId && currentComponentData?.id === oldId
      );
      const undoSwap = selectedSwappedComponent.some(
        (swapped) => swapped.oldId === newId && swapped.newId === oldId && currentComponentData?.id === swapped.oldId
      );
      const swapSwapped = selectedSwappedComponent.some(
        (swapped) => swapped.newId === oldId && currentComponentData?.id !== newId
      );
      const swapOriginalDiff = selectedSwappedComponent.some(
        (swapped) => swapped.newId === oldId && currentComponentData?.id !== oldId
      );
      const originalRemoved = selectedRemovedComponentIdList.includes(oldId) && currentComponentData?.id === oldId;
      let updatedList = [...selectedSwappedComponent];
      let selectedSwapComponent = currentComponentData;

      if (!existingSwapped && !undoSwap && !swapSwapped && !originalRemoved) {
        updatedList.push({ oldId, newId });
        selectedSwapComponent = allCustomFoodComponents.meal.find((sauce) => sauce.id === newId)!;
      } else if (undoSwap && !swapSwapped) {
        updatedList = updatedList.filter((swapped) => swapped.oldId !== newId && swapped.newId !== oldId);
        selectedSwapComponent = foodCompData?.data.find((component) => component.id === newId)!;
      } else if (swapSwapped && !swapOriginalDiff && existingSwapped) {
        updatedList = updatedList.map((swapped) =>
          swapped.newId === oldId && swapped.oldId === currentComponentData?.id
            ? { oldId: currentComponentData?.id, newId }
            : swapped
        );
        selectedSwapComponent = allCustomFoodComponents.meal.find((sauce) => sauce.id === newId)!;
      } else if (swapOriginalDiff) {
        updatedList = updatedList.map((swapped) =>
          swapped.newId === oldId && currentComponentData?.id !== newId && currentComponentData?.id === swapped.oldId
            ? { oldId: currentComponentData?.id, newId }
            : swapped
        );
        selectedSwapComponent = allCustomFoodComponents.meal.find((component) => component.id === newId)!;
      } else {
        if (originalRemoved) {
          setSelectedRemovedComponentIdList(selectedRemovedComponentIdList.filter((id) => id !== oldId));
        }
        selectedSwapComponent = allCustomFoodComponents.meal.find((sauce) => sauce.id === newId)!;
        updatedList.push({ oldId, newId });
      }
      setSelectedSwappedComponent(updatedList);
      setSelectedComponent(selectedSwapComponent);
    },
    [selectedSwappedComponent, selectedComponent, selectedRemovedComponentIdList, selectedComponentIndex]
  );

  const handleOriginalComponent = (originalComponentId: string, index: number) => {
    if (selectedComponent?.id === originalComponentId && selectedRemovedComponentIdList.includes(originalComponentId)) {
      handleRemoveComponents(originalComponentId, index);
    } else if (selectedComponent?.id !== originalComponentId) {
      handleSwapComponent(selectedComponent!.id, originalComponentId);
    }
  };

  const handleAction = (index: number, compId: string) => {
    setSelectedComponentIndex(index);
    handleRemoveComponents(compId, index);
    setSelectedComponent(undefined);
  };

  const handleRenderCard = (component: any, index: number, componentId: string) => {
    return (
      <Box flexDirection={'row'} justifyContent={'space-between'} key={component.id}>
        <Stack flexDirection={'row'} justifyContent={'space-between'}>
          <Stack
            justifyContent={'space-between'}
            flexDirection={'row'}
            sx={{ opacity: selectedRemovedComponentIdList.includes(component?.id) ? 0.2 : 1 }}
          >
            <Stack flexDirection={'row'} sx={{ marginRight: 1 }}>
              <img
                alt={`${component.name?.en} Image`}
                width={80}
                height={80}
                style={{ maxHeight: '80px', maxWidth: '80px', borderRadius: '8px' }}
                className="object-cover"
                onError={(e: any) => (e.target.src = 'https://via.placeholder.com/120')}
                src={`${process.env.REACT_APP_BUCKET_URL}/food-component/${component.id}/square@1x.jpg`}
              />
            </Stack>
            <Stack sx={{ marginRight: 1 }}>
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: '14px',
                  lineHeight: '18px',
                  width: '100%'
                }}
              >
                {component?.name?.en}
              </Typography>
              <Stack display={'flex'} flexDirection={'row'} width={'full'} flexWrap={'wrap'} sx={{ marginY: '12px' }}>
                {renderIngredients(component, selectedDelivery, [...allFoodComponentsData, ...allCustomFoodComponents.meal]).map(
                  (i: any, index: number, array: any[]) => (
                    <Typography
                      key={index}
                      sx={{
                        fontSize: '12px',
                        fontWeight: 400,
                        lineHeight: '16px',
                        color: i.isAlergen ? 'red' : caloTheme.palette.neutral900,
                        whiteSpace: 'nowrap'
                      }}
                    >
                      {`${i.name}${index === array.length - 1 ? '' : ',\u00A0'}`}
                    </Typography>
                  )
                )}
              </Stack>
            </Stack>
          </Stack>
          <Stack sx={{ justifyContent: 'right' }}>
            {foodCompData?.data[index]?.id !== componentId &&
              !(selectedRemovedComponentIdList.includes(componentId) && foodCompData?.data[index]?.id === component?.id) && (
                <Typography
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    textAlign: 'end',
                    width: '100%',
                    color: caloTheme.palette.primary500,
                    fontWeight: 400,
                    fontSize: '14px',
                    lineHeight: '16px'
                  }}
                >
                  Swapped
                </Typography>
              )}
            {selectedRemovedComponentIdList?.includes(componentId) && foodCompData?.data[index].id === componentId && (
              <Typography
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  textAlign: 'end',
                  width: '100%',
                  color: caloTheme.palette.red,
                  fontWeight: 400,
                  fontSize: '14px',
                  lineHeight: '16px'
                }}
              >
                Removed
              </Typography>
            )}
          </Stack>
          <Stack>
            <IconButton
              sx={{
                backgroundColor: caloTheme.palette.neutral100,
                borderRadius: '4px',
                marginLeft: 1,
                marginBottom: 1
              }}
              onClick={() => handleAction(index, component.id)}
            >
              <Iconify
                icon={selectedRemovedComponentIdList.includes(component?.id) ? 'material-symbols-light:undo' : 'ph:trash'}
              />
            </IconButton>
            <IconButton
              sx={{
                backgroundColor: caloTheme.palette.neutral100,
                borderRadius: '4px',
                marginLeft: 1,
                marginBottom: 1
              }}
              disabled={
                (!isPreBuiltMeal && !component.tags?.includes(FoodComponentType.sauce) && !isCaloKidsMeal) ||
                selectedRemovedComponentIdList.includes(component?.id) ||
                (isCaloKidsMeal &&
                  caloKidsComponent
                    ?.filter((customComp) => customComp.tags?.some((tag: any) => component?.tags?.includes(tag)))
                    .filter((compData) => compData.name?.en !== component.name?.en).length === 0)
              }
              onClick={() =>
                selectedComponent === component
                  ? (setSelectedComponent(undefined), setSelectedComponentIndex(undefined))
                  : (setSelectedComponent(component), setSelectedComponentIndex(index))
              }
            >
              <Iconify icon={'tabler:refresh'} />
            </IconButton>
          </Stack>
        </Stack>
        <Stack sx={{ opacity: selectedRemovedComponentIdList.includes(component?.id) ? 0.2 : 1 }}>
          <ComponentTotalInformationSection
            key={selectedMeal.id}
            component={
              selectedSwappedComponent.some((swap) => swap.oldId === component.id)
                ? [...allCustomFoodComponents.meal, ...allFoodComponentsData].find(
                    (comp) => selectedSwappedComponent.find((swap) => swap.oldId === component.id)?.newId === comp.id
                  )
                : component
            }
            allFoodComponentsData={[...allFoodComponentsData, ...customFoodComponents, ...allCustomFoodComponents.meal]}
            meal={selectedMeal}
          />
        </Stack>
      </Box>
    );
  };

  return (
    <>
      <Box sx={{ width: '100%', flexDirection: 'row', display: 'flex' }} key={selectedMeal?.id}>
        <Stack flexDirection={'column'} width={'100%'} sx={{ marginY: 1 }}>
          <Stack flexDirection={'row'} sx={{ marginRight: 1 }}>
            <img
              className="object-cover"
              width={60}
              style={{ maxHeight: '60px', maxWidth: '60px', borderRadius: '8px' }}
              height={60}
              alt={selectedMeal.name.en}
              onError={(e: any) => (e.target.src = 'https://via.placeholder.com/120')}
              src={`${process.env.REACT_APP_BUCKET_URL}/food/${selectedMeal.id}/square@1x.jpg`}
            />
            <Stack justifyContent={'start'} flexDirection={'row'} sx={{ my: 'auto' }}>
              <Typography sx={{ ml: 1, fontSize: '16px', fontWeight: 600, lineHeight: '20px' }}>
                {selectedMeal?.name?.en}
              </Typography>
              <Stack sx={{ ml: 1, display: 'flex', flexDirection: 'row' }}>
                {selectedMeal.tags?.map((r: any) => <MenuFoodDietTags key={r} tag={r} />)}
              </Stack>
            </Stack>
          </Stack>
          <Stack flexDirection={'row'} justifyContent={'space-between'}>
            <Stack display={'flex'} flexDirection={'row'} width={'100%'} flexWrap={'wrap'} sx={{ my: 'auto' }}>
              {renderIngredients(
                selectedMeal,
                selectedDelivery,
                [...allFoodComponentsData, ...allCustomFoodComponents.meal],
                selectedRemovedComponentIdList,
                selectedSwappedComponent
              ).map((i: any, index: number, array: any[]) => (
                <Tooltip title={`${i.weightedIng}`} placement="top" arrow>
                  <Typography
                    key={index}
                    sx={{
                      fontSize: '12px',
                      fontWeight: 400,
                      lineHeight: '16px',
                      color: i.isAlergen ? 'red' : caloTheme.palette.neutral900,
                      whiteSpace: 'nowrap'
                    }}
                  >
                    {`${i.name}${index === array.length - 1 ? '' : ',\u00A0'}`}
                  </Typography>
                </Tooltip>
              ))}
            </Stack>
            <Stack width={'100%'} sx={{ mx: 'auto', mt: '-12px', mb: 1 }}>
              <ModifyMealTotalInformationSection
                meal={selectedMeal as any}
                selectedSwappedComponentIdList={selectedSwappedComponent}
                selectedRemovedComponentIdList={selectedRemovedComponentIdList}
                allFoodComponentsData={[...customFoodComponents, ...allCustomFoodComponents.meal, ...allFoodComponentsData]}
              />
            </Stack>
          </Stack>
        </Stack>
      </Box>
      <Box>
        <Stack>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: '16px',
              lineHeight: '20px'
            }}
          >
            Components
          </Typography>
        </Stack>
        <>
          {LoadingFoodComponent ? (
            <CaloLoader />
          ) : (
            <>
              <Stack sx={{ backgroundColor: caloTheme.palette.neutral200, flexDirection: 'row', height: '25vh', marginTop: 1 }}>
                <Stack flexDirection={'row'} sx={{ paddingY: 1, overflowX: 'auto' }}>
                  {(foodComponentsData ? foodComponentsData : []).map((component: FoodComponent, index) => (
                    <Card
                      variant="outlined"
                      sx={{
                        padding: 1,
                        borderRadius: '8px',
                        marginX: 1,
                        minWidth: '50vh'
                      }}
                    >
                      {handleRenderCard(component, index, component.id)}
                    </Card>
                  ))}
                </Stack>
              </Stack>
              <Stack display={selectedComponent ? 'flex' : 'none'}>
                <Stack sx={{ paddingY: 1 }}>
                  <Typography
                    sx={{
                      fontWeight: 600,
                      fontSize: '16px',
                      lineHeight: '20px'
                    }}
                  >{`Swap ${selectedComponent?.name?.en} with`}</Typography>
                </Stack>
                <Stack sx={{ padding: 1, overflowY: 'auto', maxHeight: '24vh' }}>
                  {foodCompData &&
                    selectedComponentIndex !== undefined &&
                    foodCompData.data &&
                    selectedComponent?.id !== foodCompData.data[selectedComponentIndex].id && (
                      <Box
                        sx={{
                          backgroundColor: 'white',
                          display: 'flex',
                          flexDirection: 'column',
                          borderBottomWidth: 1,
                          borderBottomColor: caloTheme.palette.neutral100,
                          padding: 2
                        }}
                        key={foodCompData.data[selectedComponentIndex]?.id}
                      >
                        <Stack sx={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                          <Stack>
                            <img
                              alt={`${foodCompData.data[selectedComponentIndex].name?.en} Image`}
                              width={75}
                              height={75}
                              style={{ maxHeight: '75px', maxWidth: '75px', borderRadius: '8px' }}
                              className="object-cover"
                              onError={(e: any) => (e.target.src = 'https://via.placeholder.com/120')}
                              src={`${process.env.REACT_APP_BUCKET_URL}/food-component/${foodCompData.data[selectedComponentIndex]?.id}/square@1x.jpg`}
                            />
                          </Stack>
                          <Stack sx={{ flexDirection: 'row', width: '100%', justifyContent: 'space-between' }}>
                            <Stack width={'35%'} sx={{ justifyContent: 'space-between', flexDirection: 'row' }}>
                              <Typography sx={{ width: '75%', ml: 2, fontWeight: 600, fontSize: '14px', lineHeight: '18px' }}>
                                {foodCompData.data[selectedComponentIndex]?.name?.en}
                              </Typography>
                              {foodCompData?.data[selectedComponentIndex]?.id ===
                                foodCompData.data[selectedComponentIndex]?.id && (
                                <Typography
                                  sx={{
                                    display: 'flex',
                                    width: '100%',
                                    textAlign: 'center',
                                    justifyContent: 'center',
                                    color: caloTheme.palette.primary500,
                                    fontWeight: 400,
                                    fontSize: '14px',
                                    lineHeight: '16px'
                                  }}
                                >
                                  Original
                                </Typography>
                              )}
                            </Stack>
                            <Stack sx={{ width: '100%' }}>
                              <ComponentTotalInformationSection
                                key={selectedMeal.id}
                                component={allFoodComponentsData.find(
                                  (compData) => compData.id === foodCompData?.data[selectedComponentIndex].id
                                )}
                                meal={selectedMeal}
                                allFoodComponentsData={[
                                  ...allCustomFoodComponents.meal,
                                  ...allFoodComponentsData,
                                  ...customFoodComponents
                                ]}
                              />
                            </Stack>
                          </Stack>
                          <Stack sx={{ textAlign: 'right', justifyContent: 'right', width: 'auto' }}>
                            <IconButton
                              sx={{
                                backgroundColor: caloTheme.palette.primary100,
                                borderRadius: '8px',
                                marginBottom: 1
                              }}
                              onClick={() =>
                                handleOriginalComponent(foodCompData.data[selectedComponentIndex]?.id, selectedComponentIndex)
                              }
                            >
                              <Iconify icon={'ph:check-bold'} color={caloTheme.palette.primary400} />
                            </IconButton>
                          </Stack>
                        </Stack>
                      </Box>
                    )}
                  {customFoodComponents &&
                    customFoodComponents.length > 0 &&
                    selectedComponent &&
                    !isUndefined(selectedComponentIndex) &&
                    (selectedComponent.tags?.includes(FoodComponentType.sauce) || isPreBuiltMeal || isCaloKidsMeal) &&
                    customFoodComponents
                      ?.filter(
                        (component) =>
                          foodCompData?.data[selectedComponentIndex].name.en !== component.name?.en &&
                          selectedComponent.id !== component.id
                      )
                      .map((customComp) => (
                        <Box
                          sx={{
                            backgroundColor: 'white',
                            display: 'flex',
                            flexDirection: 'column',
                            borderBottomWidth: 1,
                            borderBottomColor: caloTheme.palette.neutral100,
                            padding: 2
                          }}
                        >
                          <Stack sx={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                            <Stack>
                              <img
                                alt={`${customComp.name?.en} Image`}
                                width={75}
                                height={75}
                                style={{ maxHeight: '75px', maxWidth: '75px', borderRadius: '8px' }}
                                className="object-cover"
                                onError={(e: any) => (e.target.src = 'https://via.placeholder.com/120')}
                                src={`${process.env.REACT_APP_BUCKET_URL}/food-component/${customComp.id}/square@1x.jpg`}
                              />
                            </Stack>
                            <Stack sx={{ flexDirection: 'row', width: '100%', justifyContent: 'space-between' }}>
                              <Stack width={'35%'}>
                                <Typography sx={{ width: '75%', ml: 2, fontWeight: 600, fontSize: '14px', lineHeight: '18px' }}>
                                  {customComp.name?.en}
                                </Typography>
                              </Stack>
                              <Stack sx={{ width: '100%' }}>
                                <ComponentTotalInformationSection
                                  key={selectedMeal.id}
                                  component={customComp}
                                  meal={selectedMeal}
                                  allFoodComponentsData={[
                                    ...allCustomFoodComponents.meal,
                                    ...allFoodComponentsData,
                                    ...customFoodComponents
                                  ]}
                                />
                              </Stack>
                            </Stack>
                            <Stack sx={{ textAlign: 'right', justifyContent: 'right', width: 'auto' }}>
                              <IconButton
                                sx={{
                                  backgroundColor: caloTheme.palette.primary100,
                                  borderRadius: '8px',
                                  marginBottom: 1
                                }}
                                onClick={() => handleSwapComponent(selectedComponent.id, customComp.id)}
                              >
                                <Iconify icon={'ph:check-bold'} color={caloTheme.palette.primary400} />
                              </IconButton>
                            </Stack>
                          </Stack>
                        </Box>
                      ))}
                </Stack>
              </Stack>
            </>
          )}
        </>
      </Box>
    </>
  );
};
export default ModifyMealPopup;
