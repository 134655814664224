import { ProcessingStage } from '@calo/dashboard-types';
import { Chip, Stack, Typography } from '@mui/material';
import { UsedOnMenuIndicator } from 'components';
import { foodStatuslabelColorMapping, foodStatuslabelMapping } from 'lib/constants';
import { PrototypeAction } from 'lib/enums';
import { getUsedOnMenuButtonColor } from 'lib/helpers/usedOnMenu';
import { FoodComponent, UsedOnMenuReq } from 'lib/interfaces';
import { startCase } from 'lodash-es';
import { useEffect, useState } from 'react';

const styles = {
  componentName: { fontSize: '34px', fontWeight: 400, lineHeight: '125%' }
};

interface ComponentNameAndUsageProps {
  foodComponent: FoodComponent | undefined;
  filteredFoodUsed?: UsedOnMenuReq[];
  isDisabled?: boolean;
}

const ComponentNameAndUsage = ({ foodComponent, filteredFoodUsed = [] }: ComponentNameAndUsageProps) => {
  const [usedOnMenuButtonColor, setUsedOnMenuButtonColor] = useState<string[]>([]);

  useEffect(() => {
    setUsedOnMenuButtonColor([]);
    if (!foodComponent?.usedOnMenu) {
      return;
    }
    for (const data of foodComponent.usedOnMenu) {
      setUsedOnMenuButtonColor((old) => [...old, getUsedOnMenuButtonColor(data)]);
    }
  }, [foodComponent]);

  const renderComponentStatusChip = () => {
    if (foodComponent && foodComponent.stage) {
      const actionHistory = foodComponent.prototypeActions || [];
      const lastAction = actionHistory[actionHistory.length - 1] as { type: PrototypeAction };

      const isChangeRequested = lastAction?.type === PrototypeAction.requestChanges;
      if (isChangeRequested) {
        return <Chip label={'Changes requested'} color="warning" size="medium" variant="outlined" />;
      } else if (foodComponent.stage !== ProcessingStage.approved) {
        return (
          <Chip
            label={startCase(foodStatuslabelMapping[foodComponent.stage])}
            color={foodStatuslabelColorMapping[foodComponent.stage]}
            size="medium"
            variant="outlined"
          />
        );
      }
    }
  };

  return (
    <>
      <Stack sx={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center', justifyContent: 'center' }}>
        <Typography sx={styles.componentName}>{foodComponent?.name.en}</Typography>
        {filteredFoodUsed.length > 0 && (
          <UsedOnMenuIndicator
            filteredFoodUsed={filteredFoodUsed}
            usedOnMenuButtonColor={usedOnMenuButtonColor}
            type="component"
          />
        )}
        {renderComponentStatusChip()}
      </Stack>
    </>
  );
};

export default ComponentNameAndUsage;
