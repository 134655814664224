import {
  Box,
  Button,
  Checkbox,
  colors,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
  Typography
} from '@mui/material-v6';
import { deletePrototypeFood } from 'actions/food';
import { ModalRef } from 'components/Modal';
import Popup from 'components/Popup';
import { Routes } from 'lib/enums';
import history from 'lib/history';
import { Food } from 'lib/interfaces';
import React, { forwardRef, MutableRefObject, useState } from 'react';

interface DeleteMealSizesDialogProps {
  onClose: () => void;
  foodWithAllSizes: Food[];
}

const DeleteMealSizesDialog = forwardRef<ModalRef | undefined, DeleteMealSizesDialogProps>(
  ({ onClose, foodWithAllSizes }, ref) => {
    const [selectedSizes, setSelectedSizes] = useState<string[]>([]);
    const [isDeleting, setIsDeleting] = useState(false);

    const sizes = foodWithAllSizes?.map((food) => food.size) || [];
    const isAllSelected = selectedSizes.length === sizes.length;

    const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        setSelectedSizes(sizes);
      } else {
        setSelectedSizes([]);
      }
    };

    const handleSelectSize = (size: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        setSelectedSizes((prev) => [...prev, size]);
      } else {
        setSelectedSizes((prev) => prev.filter((s) => s !== size));
      }
    };

    const handleDeleteMeal = async () => {
      if (selectedSizes.length === 0) return;

      setIsDeleting(true);
      try {
        const selectedFoods = foodWithAllSizes.filter((food) => selectedSizes.includes(food.size));
        await Promise.all(selectedFoods.map((food) => deletePrototypeFood(food.id)));
        setSelectedSizes([]);
        onClose();
        history.push(Routes.playgroundFoodList);
      } catch (error) {
        console.error('Error deleting meal sizes:', error);
      } finally {
        setIsDeleting(false);
      }
    };

    return (
      <Popup ref={ref as MutableRefObject<ModalRef>} onClose={onClose} padding={0}>
        <DialogTitle>
          <Typography variant="h6" sx={{ fontSize: '20px', fontWeight: 500 }}>
            Select the meal sizes you wish to delete
          </Typography>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Stack spacing={1}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography>Select all</Typography>
              <Checkbox checked={isAllSelected} onChange={handleSelectAll} />
            </Box>
            {sizes.map((size) => (
              <Box key={size} display="flex" justifyContent="space-between" alignItems="center">
                <Typography>{size}</Typography>
                <Checkbox checked={selectedSizes.includes(size)} onChange={handleSelectSize(size)} />
              </Box>
            ))}
          </Stack>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button
            onClick={handleDeleteMeal}
            color="error"
            variant="contained"
            disabled={selectedSizes.length === 0 || isDeleting}
          >
            {isDeleting ? 'Deleting...' : 'Delete'}
          </Button>
          <Button onClick={onClose} style={{ color: colors.grey[900], fontWeight: 500 }}>
            Close
          </Button>
        </DialogActions>
      </Popup>
    );
  }
);

export default DeleteMealSizesDialog;
