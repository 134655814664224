import { Brand, Kitchen } from '@calo/types';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Box, TableCell, TableRow, Tooltip, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';
import { format, parseISO } from 'date-fns/fp';
import { Routes } from 'lib/enums';
import { slugify } from 'lib/helpers';
import { Link } from 'react-router-dom';

interface FoodItemProps {
  foodItem: any;
  toggleMenuItemVisibility: () => void;
  onEditClick: (itemId?: string) => void;
  onDeleteClick: (itemId?: string) => void;
  kitchen: Kitchen;
}

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    border: 'none'
  },
  [`&.${tableCellClasses.body}`]: {
    border: 'none',
    justifyContent: 'space-between'
  }
}));

const StatusPill = styled(Box)<{ active: boolean }>(({ active }) => ({
  display: 'inline-block',
  padding: '4px 12px',
  borderRadius: '16px',
  color: active ? '#4CAF50' : 'rgba(0, 0, 0, 0.8)',
  border: `1px solid ${active ? '#4CAF50' : 'rgba(189, 189, 189, 1)'}`,
  textAlign: 'center'
}));

const RetailMenuTableRow = ({ foodItem, toggleMenuItemVisibility, onEditClick, onDeleteClick, kitchen }: FoodItemProps) => {
  const truncatedName = foodItem.name.en.length > 15 ? `${foodItem.name.en.slice(0, 15)}...` : foodItem.name.en;

  return (
    <TableRow key={foodItem.id} sx={{ borderBottom: '1px solid #EEE' }}>
      <StyledTableCell>
        <Tooltip title={foodItem.name.en} disableHoverListener={foodItem.name.en.length < 10}>
          <Typography variant="body2" noWrap>
            <Link to={Routes.foodSlug.replace(':slug', slugify([foodItem.name.en, kitchen, Brand.CALO]))}>{truncatedName}</Link>
          </Typography>
        </Tooltip>
      </StyledTableCell>
      <StyledTableCell>
        <Typography variant="body2">{foodItem.retail.retailTagList?.join(', ')}</Typography>
      </StyledTableCell>
      <StyledTableCell>
        <Typography variant="body2">{foodItem.retail.retailPrice}</Typography>
      </StyledTableCell>
      <StyledTableCell>
        <Typography variant="body2">{foodItem.size}</Typography>
      </StyledTableCell>
      <StyledTableCell>
        <Typography variant="body2">
          {foodItem.retail.startDate ? format('yyyy-MM-dd')(parseISO(foodItem.retail.startDate)) : '-'}
        </Typography>
      </StyledTableCell>
      <StyledTableCell>
        <Typography variant="body2">
          {foodItem.retail.endDate ? format('yyyy-MM-dd')(parseISO(foodItem.retail.endDate)) : '-'}
        </Typography>
      </StyledTableCell>
      <StyledTableCell>
        <Box display="flex">
          <StatusPill as="button" active={!foodItem.retail.disabled} onClick={toggleMenuItemVisibility}>
            {foodItem.retail.disabled ? 'Disabled' : 'Active'}
          </StatusPill>
        </Box>
      </StyledTableCell>
      <StyledTableCell>
        <Typography variant="body2" align="center">
          {foodItem.retail.swapAvailable ? 'Yes' : 'No'}
        </Typography>
      </StyledTableCell>
      <StyledTableCell>
        <Box display="flex">
          <EditIcon
            sx={{ cursor: 'pointer', marginRight: '8px', color: 'rgba(0, 0, 0, 0.56)' }}
            onClick={() => onEditClick(foodItem.id)}
          />
          <DeleteIcon sx={{ cursor: 'pointer', color: 'rgba(0, 0, 0, 0.56)' }} onClick={() => onDeleteClick(foodItem.id)} />
        </Box>
      </StyledTableCell>
    </TableRow>
  );
};

export default RetailMenuTableRow;
