import { RetailMenuFoodItem } from '@calo/dashboard-types';
import { Box, FormControl, Button as MUIButton, TextField, Typography } from '@mui/material';
import { caloTheme } from 'assets/images/theme/calo';
import React from 'react';
import { useFoodModifiers } from '../../hooks/useFoodModifiers';
import SelectMUI from '../MUI/SelectMUI';
import ModifierComponents from './ModifierComponents';

interface FoodModifiersProps {
  currentRetailItem: RetailMenuFoodItem;
  setCurrentRetailItem: React.Dispatch<React.SetStateAction<RetailMenuFoodItem>>;
  currentFood: any;
}

const FoodModifiers: React.FC<FoodModifiersProps> = ({ currentRetailItem, setCurrentRetailItem, currentFood }) => {
  const {
    componentOptions,
    foodComponentList,
    isLoadingFoodComponents,
    handleAddModifier,
    handleRemoveModifier,
    handleComponentOptionsChange,
    setCurrentComponentName,
    setCurrentModifierComponent,
    currentModifierComponent
  } = useFoodModifiers({ currentRetailItem, setCurrentRetailItem, currentFood });

  return (
    <div className="">
      {currentRetailItem?.foodModifiers &&
        currentRetailItem.foodModifiers.length > 0 &&
        currentRetailItem.foodModifiers.map((modifier, index) => (
          <div key={modifier.id || index} className="mb-3 pb-3 border-b border-gray-300">
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                backgroundColor: '#F5F5F5',
                marginInline: -2,
                mb: 2,
                paddingBlock: 1
              }}
            >
              <Typography variant="subtitle2" gutterBottom sx={{ paddingInline: 2 }}>
                Modifier {index + 1}
              </Typography>
              <MUIButton
                variant="outlined"
                color="error"
                onClick={() => handleRemoveModifier(index)}
                data-test="remove-modifier-button"
                sx={{ marginInline: 2 }}
                size="small"
              >
                REMOVE
              </MUIButton>
            </Box>
            <div className="flex flex-row space-x-4">
              <div className="flex-1">
                <FormControl fullWidth variant="outlined">
                  <SelectMUI
                    value={modifier.type || ''}
                    label="Rule Type*"
                    onChange={(data: any) => {
                      const updatedModifiers =
                        currentRetailItem?.foodModifiers?.map((mod, modIndex) =>
                          modIndex === index ? { ...mod, type: data.value } : mod
                        ) || [];
                      setCurrentRetailItem({ ...currentRetailItem, foodModifiers: updatedModifiers });
                    }}
                    options={[
                      { value: 'ADD', label: 'Add' },
                      { value: 'REPLACE', label: 'Replace' }
                    ]}
                    className="leading-10 flex-1 "
                    placeholder="Select Rule Type*"
                  />
                </FormControl>
              </div>
              <div className="flex-1 mt-4">
                <TextField
                  variant="outlined"
                  label="Name EN*"
                  fullWidth
                  value={modifier.name?.en || ''}
                  onChange={(e) => {
                    const updatedModifiers =
                      currentRetailItem?.foodModifiers?.map((mod, modIndex) =>
                        modIndex === index ? { ...mod, name: { ...mod.name, en: e.target.value } } : mod
                      ) || [];
                    setCurrentRetailItem({ ...currentRetailItem, foodModifiers: updatedModifiers });
                  }}
                />
              </div>
              <div className="flex-1 mt-4">
                <TextField
                  variant="outlined"
                  label="Name AR*"
                  fullWidth
                  value={modifier.name?.ar || ''}
                  onChange={(e) => {
                    const updatedModifiers =
                      currentRetailItem?.foodModifiers?.map((mod, modIndex) =>
                        modIndex === index ? { ...mod, name: { ...mod.name, ar: e.target.value } } : mod
                      ) || [];
                    setCurrentRetailItem({ ...currentRetailItem, foodModifiers: updatedModifiers });
                  }}
                />
              </div>
            </div>
            <ModifierComponents
              modifier={modifier}
              index={index}
              componentOptions={componentOptions}
              isLoadingFoodComponents={isLoadingFoodComponents}
              foodComponentList={foodComponentList}
              currentRetailItem={currentRetailItem}
              setCurrentRetailItem={setCurrentRetailItem}
              handleComponentOptionsChange={handleComponentOptionsChange}
              setCurrentComponentName={setCurrentComponentName}
              setCurrentModifierComponent={setCurrentModifierComponent}
              currentModifierComponent={currentModifierComponent}
            />
          </div>
        ))}
      <MUIButton
        variant="outlined"
        onClick={handleAddModifier}
        startIcon={<i className="fas fa-plus" style={{ fontSize: '16px' }}></i>}
        sx={{ textTransform: 'none', color: caloTheme.palette.primary500, fontWeight: 'bold', fontSize: '12px' }}
      >
        ADD MODIFIER
      </MUIButton>
    </div>
  );
};

export default FoodModifiers;
