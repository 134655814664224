import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { IconButton, Stack, Typography } from '@mui/material-v6';
import { FoodDiff } from 'lib/interfaces';
import { useState } from 'react';

interface FoodDiffItemProps {
  foodDiff: FoodDiff;
}

const FoodDiffItem = ({ foodDiff }: FoodDiffItemProps) => {
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);

  return (
    <>
      <Stack
        sx={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderBottom: 1,
          borderColor: '#0000001F',
          paddingY: 2
        }}
      >
        <Stack sx={{ flexDirection: 'row', gap: 1, alignItems: 'center' }}>
          <img
            className="object-cover h-full rounded-s-md"
            width={40}
            height={40}
            // alt={food.name.en}
            onError={(e: any) => (e.target.src = 'https://via.placeholder.com/120')}
            src={`${process.env.REACT_APP_BUCKET_URL}/food/${foodDiff.id}/square@1x.jpg`}
          />
          <Typography variant="body1">
            {foodDiff.name} ({foodDiff.size})
          </Typography>
        </Stack>
        <IconButton onClick={() => setIsDetailsOpen((prev) => !prev)}>
          {!isDetailsOpen && <ExpandMoreIcon />}
          {isDetailsOpen && <ExpandLessIcon />}
        </IconButton>
      </Stack>
      {isDetailsOpen && (
        <Stack id="changes list">
          {Object.keys(foodDiff.oldMacros).map((nutrition) => (
            <Stack
              sx={{
                backgroundColor: '#24A1700A',
                borderTop: 1,
                borderColor: '#0000001F',
                height: '84px',
                justifyContent: 'center',
                paddingX: 2
              }}
            >
              <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                <Typography sx={{ width: '33%' }}>{nutrition}</Typography>
                <Typography sx={{ width: '33%' }}>From</Typography>
                <Typography sx={{ width: '33%' }}>To</Typography>
              </Stack>
              <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                <Typography sx={{ width: '33%' }} />
                <Typography sx={{ width: '33%' }}>{foodDiff.oldMacros[nutrition as any]}</Typography>
                <Typography sx={{ width: '33%' }}>{foodDiff.newMacros[nutrition as any]}</Typography>
              </Stack>
            </Stack>
          ))}
          {Object.keys(foodDiff.oldMicronutrients).map((micronutrient) => (
            <Stack
              sx={{
                backgroundColor: '#24A1700A',
                borderTop: 1,
                borderColor: '#0000001F',
                height: '84px',
                justifyContent: 'center',
                paddingX: 2
              }}
            >
              <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                <Typography sx={{ width: '33%' }}>{micronutrient}</Typography>
                <Typography sx={{ width: '33%' }}>From</Typography>
                <Typography sx={{ width: '33%' }}>To</Typography>
              </Stack>
              <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                <Typography sx={{ width: '33%' }} />
                <Typography sx={{ width: '33%' }}>{foodDiff.oldMicronutrients[micronutrient as any]}</Typography>
                <Typography sx={{ width: '33%' }}>{foodDiff.newMicronutrients[micronutrient as any]}</Typography>
              </Stack>
            </Stack>
          ))}
        </Stack>
      )}
    </>
  );
};

export default FoodDiffItem;
