import { caloTheme } from 'assets/images/theme/calo';

export const styles = {
  main: {
    height: '100vh',
    width: '100%',
    padding: '40px 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  heading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 40px'
  },
  title: {
    fontWeight: 700
  },
  closeIcon: {
    color: 'black'
  },
  dropdownContainer: {
    mt: '40px',
    display: 'flex',
    flexDirection: 'column',
    gap: '40px',
    padding: '0 40px'
  },
  divider: {
    mt: '40px',
    color: caloTheme.palette.divider,
    height: '1px',
    width: '100%'
  },
  buttonContainer: {
    mt: '40px',
    padding: '0 40px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
};
